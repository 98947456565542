export const validateCareType = (values) => {
	const errors = {};
	const err = 'Please Select a Care Type';

	console.log('validateCareType', values);

	if (!values.careType) {
		errors.careType = err;
	} else {
		if (values.careType === 'both') {
			if (
				values.live_in_rate === 0 ||
				values.live_in_rate === '' ||
				values.hourly_rate === 0 ||
				values.hourly_rate === ''
			) {
				errors.live_in_rate = 'Please add a rate';
				errors.hourly_rate = 'Please add a rate';
			}
		} else if (values.careType === 'live_in') {
			if (!values.live_in_rate) {
				errors.live_in_rate = 'Please add a rate';
			}
			if (values.live_in_rate === 0 || values.live_in_rate === '') {
				errors.live_in_rate = 'Please add a rate';
			}
		} else if (values.careType === 'hourly') {
			if (!values.hourly_rate) {
				errors.live_in_rate = 'Please add a rate';
			}
			if (values.hourly_rate === 0 || values.hourly_rate === '') {
				errors.hourly_rate = 'Please add a rate';
			}
		}
	}

	if (values.careType === 'ignore') {
		errors.careType = err;
	}

	return errors;
};

export const validate2 = (values) => {
	const errors = {};
	const err = 'Please Select a Care Type';

	return errors;
};

export const validate3 = (values) => {
	const errors = {};
	const err = 'Please Select a Care Type';

	if (!values.zip) {
		errors.zip = err;
	}

	return errors;
};

export const validateProviderInfo = (values) => {
	const errors = {};
	const err = 'Required';
	const numErr = 'Cannot contain numbers';

	if (!values.first_name) {
		errors.firstName = err;
	} else if (/\d/.test(values.firstName)) {
		errors.firstName = numErr;
	}

	if (!values.last_name) {
		errors.lastName = err;
	} else if (/\d/.test(values.lastName)) {
		errors.lastName = numErr;
	}

	if (!values.gender) {
		errors.gender = err;
	}

	if (!values.display_name) {
		errors.displayName = err;
	}
	return errors;
};

export const validatePw = (values) => {
	const errors = {};
	const err = 'Required';
	if (!values.email) {
		errors.email = err;
	}

	if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	if (values.phone_number) {
		// remove all non numbers - have to do this cause auto fill sucks
		const cleanedNumber = values.phone_number.replace(/\D/g, '');

		// Check if the cleaned number is 10 or 11 digits long
		if (
			cleanedNumber.length === 10 ||
			(cleanedNumber.length === 11 && cleanedNumber.startsWith('1'))
		) {
			// everything went well
		} else {
			errors.phone_number = 'Please add a valid phone number';
		}

		// ensure phone number only has numbers

		// if (`${values.phone_number}`.match(/\d/g).length !== 10) {
		// 	errors.phone_number = 'Please add a valid phone number';
		// }

		// // ensure country code is not included
		// if (values.phone_number.match(/^\d{11}$/)) {
		// 	errors.phone_number = 'Please only add USA phone numbers';
		// }

		// if (!values.phone_number.match(/^\d+$/)) {
		// 	errors.phone_number = 'Please only add numbers';
		// }
	} else {
		errors.phone_number = err;
	}

	console.log('validatePw', values);
	if (!values.password) {
		errors.password = err;
	}

	if (!values.confirm_password) {
		errors.confirm_password = err;
	}

	if (values.confirm_password !== values.password) {
		errors.confirm_password = 'Passwords are not identical';
	}

	const password = values.password;

	if (password) {
		//If password contains both lower and uppercase characters, increase strength value.
		if (!password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
			errors.password = 'Must contain both lower and uppercase characters';
		}

		//If it has numbers and characters, increase strength value.
		if (!password.match(/([0-9])/)) {
			errors.password = 'Must contain at least 1 number';
		}

		//If it has one special character, increase strength value.
		if (!password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
			errors.password = 'Must contain at least 1 special character';
		}
		if (password.length < 7) {
			errors.password = 'Must be at lest 8 character long';
		}
	}

	return errors;
};

export const validateAuth = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.auth_code) {
		errors.auth_code = err;
	}

	return errors;
};

export const validate7 = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.firstName) {
		errors.firstName = err;
	}

	if (!values.lastName) {
		errors.lastName = err;
	}

	if (!values.dob) {
		errors.dob = err;
	}

	if (!values.gender) {
		errors.gender = err;
	}
	return errors;
};

export const validate4 = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.firstName) {
		errors.firstName = err;
	}

	if (!values.lastName) {
		errors.lastName = err;
	}

	return errors;
};
