import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FormContainer, Input, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../FormWrapper';
import InputContainer from '../InputContainer';

import authCodeValidationSchema from './validation';

import { TextStyled } from '../../../../../corev2/Text';

const AuthCodeForm = (props) => {
	const { loading, initialValues, authCodeHandler, serverError } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => authCodeHandler(values),
		validationSchema: toFormikValidationSchema(authCodeValidationSchema),
		enableReinitialize: true,
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Input
						name='authCode'
						type='text'
						onChange={formik.handleChange}
						placeholder='Code'
						value={formik.values.authCode}
					/>

					{formik.errors.authCode && (
						<ErrorMessage> {formik.errors.authCode} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					{loading ? (
						<TextStyled
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							Loading...
						</TextStyled>
					) : (
						<PrimaryButton type='submit' size='small'>
							Next
						</PrimaryButton>
					)}

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default AuthCodeForm;
