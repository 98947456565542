import React, { useState } from 'react';
import styled from 'styled-components';
import { ColumnFlex, RowFlex, Avatar } from 'basicStyles';
import Colors from 'colors';
import useWindowSize from 'scripts/useWindowSize';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import {
	CARE_PLAN_ADLS,
	CarePlanActionSection,
	EMPTY_CARE_PLAN_ADLS,
} from '../../provider/Shift/ShiftViews';
import { Formik, Form, Field } from 'formik';

const FilterParentCon = styled(ColumnFlex)`
	height: fit-content;
	width: 100%;
	max-width: 400px;
	border-radius: 12px;
	padding: 20px 10px 10px 10px;
	background-color: white;
`;

const RadioCustom = (props) => {
	const { selected, label, value, onChange } = props;

	return (
		<RowFlex
			style={{
				cursor: 'pointer',
			}}
			onClick={() => onChange(value)}
			fullWidth
			alignCenter
		>
			<div
				style={{
					width: '20px',
					height: '20px',
					border: `2px solid ${Colors.primary.primary}`,
					borderRadius: '4px',
					marginRight: '12px',
					backgroundColor: selected ? Colors.primary.primary : 'white',
				}}
			/>

			<Text largeText>{label}</Text>
		</RowFlex>
	);
};
const CareProSearchFilterHandler = () => {
	const [careProType, setCareProType] = useState({
		hourly: true,
		liveIn: false,
	});
	const { width } = useWindowSize();

	const [gender, setGender] = useState({
		man: true,
		woman: true,
	});

	const onGenderChange = (key) => {
		setGender((prev) => {
			return {
				...prev,
				[key]: !prev[key],
			};
		});
	};

	const handleProviderTypeChange = (key) => {
		setCareProType((prev) => {
			return {
				...prev,
				[key]: !prev[key],
			};
		});
	};

	const renderSections = () => {
		const section = Object.keys(CARE_PLAN_ADLS)
			.filter((key) => {
				if (CARE_PLAN_ADLS[key].shiftTitle) {
					return true;
				} else {
					return false;
				}
			})
			.map((key) => CARE_PLAN_ADLS[key]);

		//console.log('isDisabled', isDisabled);
		return section.map((item, index) => {
			return <CarePlanActionSection {...item} key={index} />;
		});
	};

	return (
		<ColumnFlex gap='16px' margin={width <= 768 ? 'auto' : undefined}>
			<FilterParentCon
				style={{
					padding: '24px 20px',
					gap: '16px',
					borderRadius: '24px',
				}}
			>
				<Title subtitle color={Colors.greenLightTeal} padding='0 0px' fontWeight='700'>
					Search Filter
				</Title>
				<ColumnFlex
					fullWidth
					justifyStart
					style={{
						borderRadius: '8px',
						//backgroundColor: Colors.theme.gray_background,
					}}
				>
					<ColumnFlex
						style={{
							borderRadius: '16px',
							//backgroundColor: Colors.lightGrey,
						}}
						fullWidth
						alignCenter
						gap='24px'
					>
						<ColumnFlex fullWidth alignStart>
							<Text
								alignStart
								fontStyle='bold'
								color='#969696'
								fontWeight='100'
								mediumText
							>
								What Type of CarePro are you looking for?
							</Text>
							<ColumnFlex padding='8px 0 ' gap='6px'>
								<RadioCustom
									selected={careProType.hourly}
									label='Hourly'
									value='hourly'
									onChange={handleProviderTypeChange}
								/>
								<RadioCustom
									selected={careProType.liveIn}
									label='Live In'
									value='liveIn'
									onChange={handleProviderTypeChange}
								/>
							</ColumnFlex>
						</ColumnFlex>
						<ColumnFlex padding='8px 0 ' gap='6px' fullWidth alignStart>
							<Text
								alignStart
								fontStyle='bold'
								color='#969696'
								fontWeight='100'
								mediumText
							>
								Gender Preference
							</Text>
							<ColumnFlex padding='8px 0 ' gap='6px'>
								<RadioCustom
									selected={gender.man}
									label='Man'
									value='man'
									onChange={onGenderChange}
								/>
								<RadioCustom
									selected={gender.woman}
									label='Woman'
									value='woman'
									onChange={onGenderChange}
								/>
							</ColumnFlex>
						</ColumnFlex>
					</ColumnFlex>
				</ColumnFlex>
			</FilterParentCon>
			{/* <FilterParentCon>
				<SectionCollapse title='CarePlan Filter'>
					<ColumnFlex
						fullWidth
						justifyStart
						style={{
							borderRadius: '8px',
						}}
						padding='10px 10px'
					>
						<Formik>
							{(formikProps) => (
								<Form>
									{
										// Your form inputs here
									}
									<ColumnFlex padding='16px 0 ' fullWidth gap='10px'>
										{renderSections()}
									</ColumnFlex>

									{
										//<AutoSave onSave={handleSave} render={renderThing} />
									}
								</Form>
							)}
						</Formik>
					</ColumnFlex>
				</SectionCollapse>
			</FilterParentCon> */}
		</ColumnFlex>
	);
};

export default CareProSearchFilterHandler;
