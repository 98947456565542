import React, { useState, useEffect } from 'react';

import SortButton from './SortButton';

import {
	StyledTableContainer,
	StyledTable,
	StyledTableHeader,
	StyledTableHeaderCell,
	StyledTableHeaderCellWrapper,
	StyledTableBody,
	StyledTableRow,
	StyledTableBodyCell,
	StyledNoDataText,
} from './styles';

const Table = (props) => {
	const { headers, rows } = props;

	const [sortedRows, setSortedRows] = useState(rows);
	const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

	useEffect(() => {
		if (rows) setSortedRows(rows);
	}, [rows]);

	const sortHandler = (index) => {
		let direction = 'ascending';
		if (sortConfig.key === index && sortConfig.direction === 'ascending') {
			direction = 'descending';
		} else if (sortConfig.key === index && sortConfig.direction === 'descending') {
			direction = 'ascending';
		}

		setSortConfig({ key: index, direction });

		if (direction) {
			const sorted = [...rows].sort((a, b) => {
				if (a[index] < b[index]) {
					return direction === 'ascending' ? -1 : 1;
				}
				if (a[index] > b[index]) {
					return direction === 'ascending' ? 1 : -1;
				}
				return 0;
			});

			setSortedRows(sorted);
		} else {
			setSortedRows(rows);
		}
	};

	return (
		<StyledTableContainer>
			<StyledTable>
				<StyledTableHeader>
					<StyledTableRow>
						{headers.map((header, index) => (
							<StyledTableHeaderCell
								key={index}
								header={header}
								onClick={() => sortHandler(index)}
							>
								<StyledTableHeaderCellWrapper header={header}>
									{header}
									<SortButton
										direction={
											sortConfig.key === index ? sortConfig.direction : null
										}
									/>
								</StyledTableHeaderCellWrapper>
							</StyledTableHeaderCell>
						))}
					</StyledTableRow>
				</StyledTableHeader>

				<StyledTableBody>
					{sortedRows.map((row, rowIndex) => (
						<StyledTableRow key={rowIndex}>
							{row.map((cell, cellIndex) => (
								<StyledTableBodyCell key={cellIndex}>{cell}</StyledTableBodyCell>
							))}
						</StyledTableRow>
					))}
				</StyledTableBody>
			</StyledTable>

			{sortedRows.length === 0 && <StyledNoDataText>No data found.</StyledNoDataText>}
		</StyledTableContainer>
	);
};

export default Table;
