import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledHeadingSection = styled.div`
	width: 30%;
`;

export const StyledJobPostsContainer = styled.div`
	width: 70%;

	display: flex;
	flex-direction: column;
	gap: 20px;

	margin: auto;
`;

export const StyledSideContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 1rem;
`;

export const StyledJobPostsText = styled.p`
	font-size: 14px;
	color: ${colors.primary.primary};
	margin: auto;
`;
