import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import is from 'styled-is';

import { ColumnFlex, DropShadow, RowFlex } from 'basicStyles';
import Colors from 'colors';

import Text from '../Text';

const CustomRow = styled(RowFlex)`
	justify-content: ${({ justifyContent }) => justifyContent};
`;

const ChatBubble = styled.div`
	position: relative;
	margin-bottom: 20px;
	border-radius: 8px;
	padding: 12px;
	max-width: 360px;
	background-color: ${Colors.fullWhite};
	word-wrap: break-word;
	${DropShadow(2)}

	${is('admin')`
		background-color: ${Colors.greenLightTeal}
		color: ${Colors.fullWhite}
	`}
`;

// &:before {
// 	content: ' ';
// 	position: absolute;
// 	width: 0;
// 	height: 0;
// 	left: -40px;
// 	right: auto;
// 	top: -8px;
// 	bottom: auto;
// 	border: 8px solid;
// 	border-color: #666 transparent transparent transparent;
// }
//
// &:after{
// 	content: ' ';
// 	position: absolute;
// 	width: 0;
// 	height: 0;
// 	left: -20px;
// 	right: auto;
// 	top: 0px;
// 	bottom: auto;
// 	border: 6px solid;
// 	border-color: lightyellow transparent transparent transparent;
// }

const PatientChatBubble = (props) => {
	const { justifyContent, sent_by_admin, text } = props;

	return (
		<CustomRow justifyContent={justifyContent}>
			<ChatBubble admin={sent_by_admin === 1}>
				<Text nurseName>{text}</Text>
			</ChatBubble>
		</CustomRow>
	);
};

export default PatientChatBubble;
