import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TransparentButton } from '../../../../../../corev2/Buttons';
import PageHeading from '../../../../core/components/PageHeading';
import AccountInfoForm from '../../../components/AccountInfoForm';

import { usePlatformOnboarding } from '../../../hooks/usePlatformOnboarding';
import { useVerifyCareProPhoneMutation } from '../../../api/mutations/useVerifyCareProPhoneMutation';

import { authRoutes } from '../../../routes/constants';

import { StyledAccountInfoPage } from './styles';

const AccountInfoPage = () => {
	const history = useHistory();
	const [serverError, setServerError] = useState('');

	const { platformOnboardingState, mutatePlatformOnboardingState } = usePlatformOnboarding();
	const verifyCareProPhoneMutation = useVerifyCareProPhoneMutation();

	const accountInfoHandler = async (values) => {
		try {
			await verifyCareProPhoneMutation.mutateAsync(values);

			mutatePlatformOnboardingState('accountInfo', {
				...platformOnboardingState.accountInfo,
				data: {
					email: values.email,
					phone: values.phone,
					password: values.password,
				},
				isCompleted: true,
			});
		} catch (error) {
			console.error('Phone / email verification failed', error);
			setServerError(error[0].message);
		}
	};

	const backButtonHandler = () => {
		history.push(authRoutes.onboarding.platform.personalInfo);
	};

	return (
		<StyledAccountInfoPage>
			<PageHeading>Account Information</PageHeading>

			<AccountInfoForm
				initialValues={{
					email: platformOnboardingState.accountInfo.data.email,
					phone: platformOnboardingState.accountInfo.data.phone,
					password: platformOnboardingState.accountInfo.data.password,
					confirmPassword: '',
				}}
				accountInfoHandler={accountInfoHandler}
				serverError={serverError}
			/>

			<TransparentButton size='small' onClick={backButtonHandler}>
				Go Back
			</TransparentButton>
		</StyledAccountInfoPage>
	);
};

export default AccountInfoPage;
