import React, { useState, useEffect } from 'react';
import { ColumnFlex } from 'basicStyles';
import Colors from 'colors';
import { Formik, Form, Field } from 'formik';
import { isMobile } from 'react-device-detect';
import { STATES_OPTIONS } from 'constants';
import { validateConfirmTaxId } from 'constants/validation';

import Title from 'AppComp/Title';

import {
	MARGIN_TITLE,
	FormItemsLength,
	OBFormButtonText,
	OBBasicCon,
	OBSubText,
} from 'AppComp/provider/POnboard/OnboardViews';
import Text from 'AppComp/Text';

import StyledTextInput, {
	StyledTextSelectInputV2,
	StyledTextSelectInput,
} from 'AppComp/styled/StyledTextInput';
import Button from 'AppComp/Button';

import { CustomFormButton } from 'AppComp/patient/PatientCardCollection/PatientCardCollectionViews';

const FORM_ITEM_LENGTH = isMobile ? '320px' : '420px';

import PageLoaderView from 'AppComp/PageLoaderView';

const Pending = ({ updateProviderVerificationInfo, setIsLoading, isLoading }) => {
	const handleSubmit = (values, form) => {
		console.log('Personal - handleSubmit', values);
		setIsLoading(true);
		updateProviderVerificationInfo({
			tax_id: values.confirm_ssn,
			statement: 'FINDYOURINDEPENDENCE',
			id_number: values.confirm_ssn,
		});

		//"settings.payments.statement_descriptor"
	};

	return (
		<ColumnFlex style={{ maxWidth: FORM_ITEM_LENGTH }} center>
			<Title color={'black'} bold subtitle>
				Please fill these out to verify your identity.
			</Title>
			<ColumnFlex padding='0 0 12px 0' margin='20px auto 0 auto' center gap='12px'>
				<Text
					style={{
						textAlign: 'center',
					}}
				></Text>
				<Formik
					onSubmit={handleSubmit}
					validate={validateConfirmTaxId}
					autoComplete={'off'}
					render={(formProps) => {
						return (
							<Form noValidate>
								<ColumnFlex alignCenter justifyCenter>
									<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
										<Field
											name='confirm_ssn'
											type='text'
											component={StyledTextInput}
											label='Confirm Full SSN'
											backgroundColor={'white'}
										/>
									</FormItemsLength>

									{isLoading ? (
										<PageLoaderView errorMessage='Something went wrong plz contact dev monkeys' />
									) : (
										<CustomFormButton
											style={{
												borderRadius: '24px',
											}}
											valid={formProps.isValid}
											type='submit'
											margin='32px 8px 0 0px'
											backgroundColor={Colors.primary.primary}
											width={'240px'}
											height={isMobile ? '40px' : '60px'}
										>
											<Text
												style={{
													textAlign: 'center',
												}}
												bold
												color={
													formProps.isValid
														? 'white'
														: Colors.primary.primary
												}
												midLarge
											>
												Next
											</Text>
										</CustomFormButton>
									)}
								</ColumnFlex>
							</Form>
						);
					}}
				/>
			</ColumnFlex>
		</ColumnFlex>
	);
};

export default Pending;
