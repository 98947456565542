import styled from 'styled-components';

import { colors } from '../utils';

const fontSizeMap = {
	extraLarge: '18px',
	large: '16px',
	regular: '14px',
	small: '12px',
};

export const StyledText = styled.p`
	word-wrap: break-word;
	color: ${colors.primary.primary};
	line-height: 1.7;
	font-size: ${({ size }) => fontSizeMap[size] || fontSizeMap.regular};
`;
