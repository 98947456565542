import React from 'react';

import { StyledShiftNote } from './styles';

const ShiftNote = (props) => {
	const { children } = props;

	return <StyledShiftNote>{children}</StyledShiftNote>;
};

export default ShiftNote;
