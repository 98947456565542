import React, { Component, Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import styled from 'styled-components';

import Colors from 'colors';

import { RowFlex } from 'basicStyles';

import { weekData, WeekDayCon } from 'AppComp/admin/ManageDisputes/AdminDisputeSummaryView';

import Icon from 'AppComp/Icon';
import Text from 'AppComp/Text';
import { toast } from 'react-toastify';

const toastConfig = {
	containerId: 'basic',
	autoClose: true,
};
export default class BlockedTimeController extends Component {
	state = {
		selectedDaysOfWeek: [],
		startDate: '1200',
		endDate: '1200',
	};

	handleSubmit = () => {
		const { addBlockedTime, provider, handleExitClick } = this.props;
		const { selectedDaysOfWeek, startDate, endDate } = this.state;

		if (selectedDaysOfWeek.length === 0) {
			toast.warning('No Days were selected', toastConfig);
		} else {
			const addBlockedTimeParams = selectedDaysOfWeek.map((d, i) => {
				return {
					day_of_week: d - 1,
					start_time: startDate.replace(/:/g, ''),
					end_time: endDate.replace(/:/g, ''),
				};
			});

			addBlockedTime({
				blockedTimes: addBlockedTimeParams,
				providerId: provider.id,
				handleExitClick,
			});
		}
	};

	onChangeEndDate = (event) => {
		const { value } = event.target;

		this.setState({
			endDate: value,
		});
	};

	onChangeStartDate = (event) => {
		console.log(event.target.value);

		const { value } = event.target;
		//const formatedValuelue = value.format('hhmm')

		this.setState({
			startDate: value,
		});
	};

	//Handle updating the date selection of the time sheet,
	handleDateSelection = (e) => {
		this.setState({
			date: e.target.value,
		});
	};

	handleActiveDay = (id) => {
		const { selectedDaysOfWeek } = this.state;
		const newValues = [...selectedDaysOfWeek];

		var index = newValues.indexOf(id);

		if (index !== -1) {
			newValues.splice(index, 1);
		} else {
			newValues.push(id);
		}

		this.setState({
			selectedDaysOfWeek: newValues,
		});
	};

	renderWeekTiles = () => {
		const { selectedDaysOfWeek } = this.state;

		return weekData.map((d, i) => {
			let active = null;
			let first = null;
			let last = null;

			/*
				cheap way to handle making the border correct for the first and last child of DisputeSummaryWeekCon
			*/
			if (d.id === 1) first = true;
			if (d.id === 7) last = true;
			const currentDate = d.id;
			const currentDayIsActive = selectedDaysOfWeek.find((d, i) => d === currentDate);

			if (currentDayIsActive) {
				active = true;
			}

			return (
				<WeekDayCon
					first={first}
					last={last}
					key={'swv-' + d.id}
					active={active}
					available
					onClick={() => this.handleActiveDay(d.id)}
				>
					<Text>{d.title}</Text>
				</WeekDayCon>
			);
		});
	};
}
