import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import { toggleAdminOnboardOverlay } from 'reducers/admin';
import { toggleOnboardOverlay } from 'reducers/patient';
import { hideInfoModel } from 'reducers/environment';

import BasicOverlayWrapper from './BasicOverlayWrapper';
import Info from './Info';

import OnboardAdminHanlder from './admin/OnboardAdminHanlder';
import OnboardPatientHanlder from './patient/OnboardPatientHanlder';

const AppOverlay = (props) => {
	const {
		adminOboardOverlay,
		hideInfoModel,
		info,
		info: { show },
		toggleOnboardOverlay,
		toggleAdminOnboardOverlay,
		showOnboardOverlay,
	} = props;

	const handlePatientOnboardingExit = () => {
		toggleOnboardOverlay(false);
	};

	const handleAdminOboardExit = () => {
		toggleAdminOnboardOverlay(false);
	};

	return (
		<Fragment>
			<BasicOverlayWrapper
				status={showOnboardOverlay}
				classNames='basic-overlay'
				appear
				handleExitClick={handlePatientOnboardingExit}
			>
				<OnboardPatientHanlder handleExitClick={handlePatientOnboardingExit} />
			</BasicOverlayWrapper>
			<BasicOverlayWrapper
				status={adminOboardOverlay}
				classNames='basic-overlay'
				appear
				handleExitClick={handleAdminOboardExit}
			>
				<OnboardAdminHanlder />
			</BasicOverlayWrapper>
			<BasicOverlayWrapper
				status={show}
				classNames='basic-overlay'
				appear
				handleExitClick={hideInfoModel}
			>
				<Info {...info} handleExitClick={hideInfoModel} />
			</BasicOverlayWrapper>
		</Fragment>
	);
};

const mapStateToProps = ({ environment, patient, admin }) => {
	return {
		showOnboardOverlay: patient.showOnboardOverlay,
		adminOboardOverlay: admin.adminOboardOverlay,
		info: environment.info,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		toggleOnboardOverlay: (status) => dispatch(toggleOnboardOverlay(status)),
		toggleAdminOnboardOverlay: (status) => dispatch(toggleAdminOnboardOverlay(status)),
		hideInfoModel: () => dispatch(hideInfoModel()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AppOverlay);
