import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DashboardPage from '../pages/DashboardPage';

import { dashboardRoutes } from './constants';

const DashboardRoutes = () => {
	return (
		<Switch>
			<Route path={dashboardRoutes.home}>
				<DashboardPage />
			</Route>
		</Switch>
	);
};

export default DashboardRoutes;
