import React, { useEffect, useState } from 'react';
import { ParentContainer } from 'AppComp/provider/ProviderHomeDashboard';

import JobsList from './JobsList';
import JobViewHandler from './JobViewHandler';
const JobsHandler = (props) => {
	const [selectedJob, setSelectedJob] = useState(null);

	return (
		<ParentContainer
			style={{
				minHeight: '100vh',
				display: 'flex',
				flexDirection: 'row',
			}}
		>
			<JobsList />
			<JobViewHandler />
		</ParentContainer>
	);
};

export default JobsHandler;
