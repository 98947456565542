import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

import Colors from 'colors';
import api from 'scripts/api';
import { TOAST_ERROR_CONFIG } from 'constants';

import { fetchClientDocumentRequirement } from 'reducers/admin';
import {
	clientUploadDocument,
	fetchCarePlanUploadedDocuments,
	receiveUploadedDocuments,
} from 'reducers/patient';
import { DOCUMENT_MODEL } from 'reducers/graphqlModelTypes';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import { ColumnFlex, RowFlex } from 'AppComp/styled/Basic';
import Upload, { DisplayUploadedDocument } from 'AppComp/patient/Documents/Upload';
import { ParentContainer } from 'AppComp/provider/ProviderHomeDashboard/ProviderHomeDashboardView';
import { PHDColumn } from 'AppComp/provider/ProviderHomeDashboard';
import { OptionSectionCareProDoc } from 'AppComp/patient/Options/OptionsViews';
import useWindowSize from 'scripts/useWindowSize';

export const toastSuccessConfig = {
	containerId: 'basic',
	autoClose: true,
};

const ModelInnerCon = styled.div`
	background-color: white;
	border-radius: 8px;
	flex: 1;
	padding: 10px 20px 10px 20px;
`;

const DocumentHandler = ({
	carePlan,
	fetchCarePlanUploadedDocuments,
	clientDocumentRequirements,
	fetchClientDocumentRequirement,
	uploadedDocuments,
	patient,
	receiveUploadedDocuments,
}) => {
	const { width } = useWindowSize();

	useEffect(() => {
		fetchClientDocumentRequirement();
		if (carePlan) {
			fetchCarePlanUploadedDocuments(carePlan.id);
		}
	}, []);

	useEffect(() => {
		if (carePlan !== null) {
			fetchCarePlanUploadedDocuments(carePlan.id);
		}
	}, [carePlan]);

	if (carePlan === null) {
		return (
			<ColumnFlex fullWidth center>
				<Title bold nubtitle color={Colors.secondary.green}>
					Please Sign In to View Documents
				</Title>
			</ColumnFlex>
		);
	}

	const handleUploadCallBackParent = async (
		document_urls,
		document_name,
		setLoading,
		expiration_date
	) => {
		try {
			console.log('is this handleUploadCallBackParent running');
			const res = await api.graph({
				query: `mutation {
					clientUploadDocument(
						user_id: ${patient.id},
						document_urls: ${api.graphStringify(document_urls)},
						document_name: "${document_name}",
						care_plan_id: ${carePlan.id},
						${expiration_date ? `expiration_date: "${expiration_date}"` : ''}
					) {
						${DOCUMENT_MODEL}
					}
				}`,
			});

			console.log('clientUploadDocument', res);
			if (res.errors) {
				toast.error(res.errors[0].message, TOAST_ERROR_CONFIG);
			}

			if (res.clientUploadDocument) {
				toast.success('Document Uploaded Successfully', toastSuccessConfig);

				receiveUploadedDocuments(res.clientUploadDocument);
			}
			setLoading(false);
			return true;
		} catch (err) {
			console.log('clientUploadDocument err', err);

			toast.error('Something went wrong uploading, please try again', TOAST_ERROR_CONFIG);

			console.log(err);
		}
	};

	const displayNotUploadedDocs = () => {
		// so this is kinda tricky
		// we have two list  1) doc requirmetnns

		// so first we need to know which documents have not been uploaded

		// we have two list first list should be all the documents requirments that have not been uploaded

		const documentsNotUploaded = clientDocumentRequirements.filter((item) => {
			const found = uploadedDocuments.find((doc) => {
				if (doc.document_name === item.name) {
					if (doc.status === 'approved' || doc.status === 'pending') {
						return true;
					}
				}
				return false;
			});
			if (!found) {
				return true;
			}
		});
		console.log('documentsNotUploaded', documentsNotUploaded);
		// now this is a bit different - we want to always show the documents that are request

		// now we need to display all the documents that have been uploaded
		// we should proabbly do this in another function so we dont' have to mix up the componets
		return clientDocumentRequirements.map((item, index) => {
			// we need to check if there has been an upload for this requirment and if it's been approved to show as the status
			const approved = uploadedDocuments.filter((doc) => {
				if (doc.document_name === item.name) {
					if (doc.status === 'pending') {
						return true;
					}
				}
				return false;
			});

			const pending = uploadedDocuments.filter((doc) => {
				if (doc.document_name === item.name) {
					if (doc.status === 'pending') {
						return true;
					}
				}
				return false;
			});

			let status = 'not_uploaded';
			let message = '';
			if (pending.length > 0) {
				message = `${pending.length} ${
					pending.length > 1 ? 'Documents' : 'Document'
				} Pending Approval`;
				status = 'pending';
			} else if (approved.length > 0) {
				message = 'Uploaded Document Approved';
				status = 'approved';
			}

			console.log('item', item);
			const _params = {
				document_name: item.name,
				status: status,
				required: item.required,
				requires_expiration: item.requires_expiration ? true : false,
				approved: approved,
				pending: pending,
				message: message,
			};
			return (
				<OptionSectionCareProDoc
					key={`${index}-pendingItems::${pending.length}`}
					{..._params}
				>
					<RowFlex gap='16px'>
						<Upload
							document_name={item.name}
							handleUploadCallBackParent={handleUploadCallBackParent}
							uploaded={item}
							{..._params}
						/>
						<ModelInnerCon>
							<ColumnFlex>
								<Text bold>Required:</Text>
								<Text>{item.required ? 'Yes' : 'No'}</Text>

								<Text bold>Requires Expiration:</Text>
								<Text>{item.requires_expiration ? 'Yes' : 'No'}</Text>
							</ColumnFlex>
						</ModelInnerCon>
					</RowFlex>
				</OptionSectionCareProDoc>
			);
		});
	};

	const displayUploadedDocs = () => {
		const reverseTheOrder = [...uploadedDocuments].reverse();
		return reverseTheOrder.map((item, index) => {
			return (
				<OptionSectionCareProDoc key={index} {...item}>
					<RowFlex gap='16px'>
						{/* <Upload
							document_name={item.name}
							handleUploadCallBackParent={handleUploadCallBackParent}
							uploaded={item}
						/> */}
						<DisplayUploadedDocument uploaded={item} />
						<ModelInnerCon>
							<ColumnFlex>
								<Text bold>Uploaded:</Text>
								<Text>{item.uploaded_date}</Text>

								{item.rejection_notes && (
									<>
										<Text bold>Rejection Reason:</Text>
										<Text>{item.rejection_notes}</Text>
									</>
								)}
							</ColumnFlex>
						</ModelInnerCon>
					</RowFlex>
				</OptionSectionCareProDoc>
			);
		});
	};

	return (
		<ParentContainer
			style={{
				minHeight: '100vh',
				padding: isMobile ? '20px 10px' : '20px 32px 20px 32px',
				backgroundColor: Colors.theme.primary_background,
			}}
		>
			<RowFlex columnMobile fullWidth gap='16px'>
				<PHDColumn
					style={{
						height: 'fit-content',
						backgroundColor: Colors.fullWhite,
						width: width > 1000 ? '50%' : '100%',
					}}
				>
					<Title bold nubtitle color={Colors.secondary.green}>
						Agency Document Request
					</Title>

					<RowFlex wrap gap='12px' margin='20px 0 0 0' fullWidth>
						{displayNotUploadedDocs()}
					</RowFlex>
				</PHDColumn>
				<PHDColumn
					style={{
						height: 'fit-content',
						backgroundColor: Colors.fullWhite,
						width: width > 1000 ? '50%' : '100%',
					}}
				>
					<Title bold nubtitle color={Colors.secondary.green}>
						Uploaded Documents
					</Title>

					<RowFlex wrap gap='12px' margin='20px 0 0 0' fullWidth>
						{displayUploadedDocs()}
					</RowFlex>
				</PHDColumn>
			</RowFlex>
		</ParentContainer>
	);
};

const mapStateToProps = ({ patient, admin }, props) => {
	return {
		uploadedDocuments: patient.uploadedDocuments,
		clientDocumentRequirements: admin.clientDocumentRequirements,
		patient: patient.patient,
		carePlan: patient.carePlan,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchClientDocumentRequirement: () => dispatch(fetchClientDocumentRequirement()),
		fetchCarePlanUploadedDocuments: (carePlanId) =>
			dispatch(fetchCarePlanUploadedDocuments(carePlanId)),
		clientUploadDocument: (params) => dispatch(clientUploadDocument(params)),
		receiveUploadedDocuments: (docs) => dispatch(receiveUploadedDocuments(docs)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentHandler);
