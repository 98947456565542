import React, { useState, Component, Fragment } from 'react';
import styled from 'styled-components';
import changeCase from 'change-case';
import { faMars, faVenus } from '@fortawesome/free-solid-svg-icons';

import Colors from 'colors';

import { ColumnFlex, RowFlex } from 'AppComp/styled/Basic';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';

const ConversationTileContainer = styled(RowFlex)`
	width: 100%;
	padding: 10px 16px 0px 16px;
	border-radius: 8px;
	min-height: 90px;
	max-height: 90px;
	cursor: pointer;
	transition: all ease 0.2s;
	${({ active }) =>
		active &&
		`
		border: solid 2px ${Colors.greenLightTeal};
	`}
`;

const gender = 'MALE';
const JobTile = (props) => {
	return (
		<ConversationTileContainer fullWidth spaceBetween alignStart active={true}>
			<ColumnFlex fullWidth gap='12px'>
				<RowFlex alignCenter gap='16px' spaceBetween>
					<Text color={Colors.greenTeal} providerOnboard bold>
						$16{' '}
						<span
							style={{
								fontWeight: '400',

								fontSize: '12px',
							}}
						>
							(hr)
						</span>
					</Text>
					<RowFlex alignCenter gap='8px'>
						<RowFlex
							style={{
								alignItems: 'center',
							}}
							gap='4px'
						>
							{gender === 'MALE' ? (
								<Icon color={Colors.primary.primary} icon={faMars} size='2x' />
							) : (
								<Icon color={Colors.primary.primary} icon={faVenus} size='2x' />
							)}
							<span
								style={{
									fontWeight: '400',
									color: Colors.primary.primary,
									fontSize: '12px',
								}}
							>
								(M)
							</span>
						</RowFlex>
						<Text bold>
							65{' '}
							<span
								style={{
									fontWeight: '400',

									fontSize: '12px',
								}}
							>
								(years)
							</span>
						</Text>
					</RowFlex>
				</RowFlex>
				<Text
					style={{
						overflow: 'hidden',
						whiteSpace: 'nowrap',
						height: '40px',
						textOverflow: 'ellipsis',
						width: '260px',
						textAlign: 'left',
					}}
					color={Colors.green}
					fontWeight='200'
				>
					Bernardo Garciarivas y Rosales
				</Text>
			</ColumnFlex>
		</ConversationTileContainer>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;

	width: 340px;
	background-color: ${Colors.fullWhite};
	height: 90vh;
	margin: 20px 0;
	border-radius: 22px;
	padding: 20px 10px 10px 10px;
`;

const JobsList = (props) => {
	return (
		<Container>
			<Title bold nubtitle color={Colors.secondary.green}>
				Job Offers
			</Title>
			<ColumnFlex
				style={{
					overflow: 'hidden',
					overflowY: 'scroll',
				}}
				margin='16px 0 0 0'
				padding='16px 4px 0px 4px'
				fullWidth
				fullHeight
			>
				<JobTile />
			</ColumnFlex>
		</Container>
	);
};

export default JobsList;
