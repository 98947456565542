import styled from 'styled-components';
import { colors } from '../../../../../corev2/utils';

export const StyledPayoutMethodWrapper = styled.div`
	position: relative;
	display: inline-block;
	text-align: center;
	width: 100%;
	margin: auto;
`;

export const StyledPayoutMethod = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: ${({ isDefault }) => (isDefault ? colors.primary.primary : colors.white)};
	border: ${({ isDefault }) => (isDefault ? `none` : `1px solid ${colors.primary.primary}`)};
	color: ${({ isDefault }) => (isDefault ? colors.white : colors.primary.primary)};
	padding: 0 20px;
	border-radius: 10px;
	width: 350px;
	height: 150px;
	text-align: left;
	max-width: 300px;
	margin: auto;
	cursor: pointer;
	position: relative;
`;

export const StyledCardNumber = styled.div`
	font-size: 16px;
	letter-spacing: 2px;
	margin-bottom: 15px;
	font-weight: 500;
`;

export const StyledExpiryDate = styled.div`
	font-size: 12px;
	font-weight: 500;
	opacity: 0.9;
`;

export const StyledDeleteButton = styled.button`
	position: absolute;
	top: 20px;
	right: -40px;
	display: ${({ showDeleteButton }) => (showDeleteButton ? 'block' : 'none')};
	cursor: pointer;
	color: ${colors.primary.primary};
	background: white;
	padding: 5px;
	border: none;
`;
