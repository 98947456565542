import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Colors from 'colors';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import { TextArea } from 'AppComp/styled/StyledTextInput';

import { ColumnFlex } from 'basicStyles';
import useWindowSize from 'scripts/useWindowSize';

import StyledTextInput from 'AppComp/styled/StyledTextInput';

import { CARE_PLAN_ACTIONS } from './CarePlanParent';
import {
	FORM_ITEM_LENGTH,
	FormItemsLength,
	FormButtonStyled,
	CarePlanComponentsFormSubmitButton,
} from './CarePlanviews';

import { validateCarePersonal } from '../Onboard/validation';

const CarePersonal = (props) => {
	const { carePlan, carePlanSet, updateSetTraits, setCarePlanAction, loading } = props;
	const [initialValues, setInitialValues] = useState(null);
	const { width } = useWindowSize();

	useEffect(() => {
		renderInitialValues(carePlanSet);
	}, [carePlanSet]);

	const renderInitialValues = (carePlanSet) => {
		console.log('Initial values - careNeedsSet', carePlanSet);

		const lookup = carePlanSet.setData.reduce((acc, item) => {
			acc[item.id] = {
				trait_id: item.id,
				type: item.trait_type,
				value: item.value ? item.value.trait_value : null,
			};
			return acc;
		}, {});

		setInitialValues({
			jobStatement: lookup[67].value,
			weight: lookup[68].value,
			diagnosis: lookup[69].value,
			contact1Name: lookup[70].value,
			contact1Relationship: lookup[71].value,
			contact1Phone: lookup[72].value,
			contact2Name: lookup[73].value,
			contact2Relationship: lookup[74].value,
			contact2Phone: lookup[75].value,
		});
	};

	const handleSubmit = (values, form) => {
		const updateParams = [
			{ trait_id: 67, value: values.jobStatement },
			{ trait_id: 68, value: values.weight.toString() },
			{ trait_id: 69, value: values.diagnosis },
			{ trait_id: 70, value: values.contact1Name },
			{ trait_id: 71, value: values.contact1Relationship },
			{ trait_id: 72, value: values.contact1Phone },
			{ trait_id: 73, value: values.contact2Name },
			{ trait_id: 74, value: values.contact2Relationship },
			{ trait_id: 75, value: values.contact2Phone },
		];

		updateSetTraits(updateParams);
	};

	if (!initialValues && carePlanSet == null) {
		return <div>Loading...</div>;
	}

	return (
		<ColumnFlex
			style={{
				height: 'fit-content',
				backgroundColor: 'white',
				borderRadius: '12px',
			}}
			padding={width < 768 ? 0 : '60px 80px'}
			center
			gap='32px'
		>
			<ColumnFlex fullWidth center gap='8px'>
				<Title nubtitle fontWeight='700'>
					Tell us about the person who needs care
				</Title>
			</ColumnFlex>
			<ColumnFlex
				fullWidth
				style={{
					flex: 1,
					borderRadius: '24px',
				}}
				center
			>
				<Formik
					onSubmit={handleSubmit}
					initialValues={initialValues}
					enableReinitialize={true}
					autoComplete={'off'}
					validate={validateCarePersonal}
					render={(formProps) => (
						<Form noValidate>
							<ColumnFlex alignCenter justifyCenter fullWidth fullHeight>
								<FormItemsLength
									margin='30px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='jobStatement'
										component={TextArea}
										label='Job Statement'
										type='text'
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='weight'
										component={StyledTextInput}
										label='Weight'
										type='number'
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='diagnosis'
										component={StyledTextInput}
										label='Diagnosis'
										type='text'
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Emergency Contact 1 - Name'
										name='contact1Name'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Emergency Contact 1 - Relationship'
										name='contact1Relationship'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Emergency Contact 1 - Phone'
										name='contact1Phone'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Emergency Contact 2 - Name'
										name='contact2Name'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Emergency Contact 2 - Relationship'
										name='contact2Relationship'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Emergency Contact 2 - Phone'
										name='contact2Phone'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>

								<CarePlanComponentsFormSubmitButton
									isValid={formProps.isValid}
									loading={loading}
									carePlan={carePlan}
									handleBack={() =>
										setCarePlanAction(CARE_PLAN_ACTIONS.CARE_PLAN_HOME)
									}
								/>
							</ColumnFlex>
						</Form>
					)}
				/>
			</ColumnFlex>
		</ColumnFlex>
	);
};

export default CarePersonal;
