import styled from 'styled-components';

import Colors from 'colors';

export const StyledNavFilterContainer = styled.div`
	width: 100%;

	display: flex;
	background: ${Colors.fullWhite};
	border-radius: 20px;
`;

export const StyledNavItem = styled.div`
	display: flex;
	flex: 1;
	cursor: pointer;

	background: ${({ active }) => (active ? Colors.primary.primary : 'none')};
	border-radius: 20px;

	text-align: center;
	align-items: center;
	justify-content: center;

	padding: 8px 0;
`;

export const StyledItemText = styled.p`
	font-size: 0.9rem;
	font-weight: ${({ active }) => (active ? '700' : '500')};
	color: ${({ active }) => (active ? Colors.fullWhite : Colors.primary.primary)};
`;

export const StyledNavItemStat = styled.span`
	font-size: 14px;
	font-weight: ${({ active }) => (active ? '700' : '500')};
`;
