import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import {
	faCcVisa,
	faCcMastercard,
	faCcJcb,
	faCcDiscover,
	faCcDinersClub,
	faCcAmex,
} from '@fortawesome/free-brands-svg-icons';

import {
	StyledPaymentMethodWrapper,
	StyledPaymentMethod,
	StyledCardBrandContainer,
	StyledCardNumber,
	StyledExpiryDate,
	StyledCardBrand,
	StyledDeleteButton,
} from './styles';

const getCardBrandIcon = (brand) => {
	switch (brand.toLowerCase()) {
		case 'visa':
			return faCcVisa;
		case 'mastercard':
			return faCcMastercard;
		case 'jcb':
			return faCcJcb;
		case 'discover':
			return faCcDiscover;
		case 'amex':
			return faCcAmex;
		case 'diners':
			return faCcDinersClub;
		default:
			return faCcVisa;
	}
};

const PaymentMethod = ({
	id,
	last4,
	expiryMonth,
	expiryYear,
	cardBrand,
	isDefault,
	updateDefaultPaymentMethodHandler,
	deletePaymentMethodHandler,
}) => {
	const [showDeleteButton, setShowDeleteButton] = useState(false);

	const showDeleteButtonHandler = () => setShowDeleteButton(true);
	const hideDeleteButtonHandler = () => setShowDeleteButton(false);

	return (
		<StyledPaymentMethodWrapper
			onMouseEnter={showDeleteButtonHandler}
			onMouseLeave={hideDeleteButtonHandler}
		>
			<StyledPaymentMethod
				isDefault={isDefault}
				onClick={() => updateDefaultPaymentMethodHandler(id)}
			>
				<StyledCardBrandContainer>
					<StyledCardBrand isDefault={isDefault}>
						<FontAwesomeIcon size='xl' icon={getCardBrandIcon(cardBrand)} />
					</StyledCardBrand>
				</StyledCardBrandContainer>
				<StyledCardNumber>xxxx-xxxx-xxxx-{last4}</StyledCardNumber>
				<StyledExpiryDate>
					{expiryMonth}/{expiryYear}
				</StyledExpiryDate>
				<StyledDeleteButton
					showDeleteButton={showDeleteButton}
					onClick={() => deletePaymentMethodHandler(id)}
				>
					<FontAwesomeIcon icon={faTrash} size='sm' />
				</StyledDeleteButton>
			</StyledPaymentMethod>
		</StyledPaymentMethodWrapper>
	);
};

export default PaymentMethod;
