import React from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import PersonalInfoForm from '../../../components/PersonalInfoForm';

import { usePlatformOnboarding } from '../../../hooks/usePlatformOnboarding';

import { StyledPersonalInfoPage } from './styles';

const PersonalInfoPage = () => {
	const { platformOnboardingState, mutatePlatformOnboardingState } = usePlatformOnboarding();

	const personalInfoFormHandler = (values) => {
		mutatePlatformOnboardingState('personalInfo', {
			...platformOnboardingState.personalInfo,
			data: {
				firstName: values.firstName,
				lastName: values.lastName,
				displayName: values.displayName,
				gender: values.gender,
			},
			isCompleted: true,
		});
	};

	return (
		<StyledPersonalInfoPage>
			<PageHeading>Personal Information</PageHeading>
			<PersonalInfoForm
				initialValues={{
					firstName: platformOnboardingState.personalInfo.data.firstName,
					lastName: platformOnboardingState.personalInfo.data.lastName,
					displayName: platformOnboardingState.personalInfo.data.displayName,
					gender: platformOnboardingState.personalInfo.data.gender,
				}}
				personalInfoFormHandler={personalInfoFormHandler}
			/>
		</StyledPersonalInfoPage>
	);
};

export default PersonalInfoPage;
