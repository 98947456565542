import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Colors from 'colors';
import { Hearts } from 'react-loader-spinner';

import api from 'scripts/api';
import DocumentVerify from './DocumentVerify';
import ConfirmIdNumber from './ConfirmIdNumber';

import { STRIPE_STATUS_MODEL, CARE_PRO_REGISTRY_STATUS_MODEL } from 'reducers/graphqlModelTypes';
import { toastErrorConfig, toastSuccessConfig } from 'reducers/provider';

import { ColumnFlex, AppContainer, RowFlex, Avatar, BackDropBg } from 'basicStyles';

import {
	updateProviderVerificationInfo,
	createProviderStripeAccount,
	receiveVerification,
	receiveStripeVerificationInfo,
	handleProviderLogin,
	receiveProviderInfo,
	checkProviderSession,
} from 'reducers/provider';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const VerifyBanking = (props) => {
	const {
		checkProviderSession,
		provider,
		history,
		fetchingCareProSession,
		handleProviderLogin,
		receiveProviderInfo,
	} = props;

	const [isLoading, setIsLoading] = useState(false);

	const [careProBankOnboardStep, setCareProBankingOnboardStep] = useState(1);

	useEffect(() => {
		setIsLoading(true);
		// check if local storage has user token
		const token = localStorage.getItem('user-token');

		if (token) {
			// check session
			checkProviderSession();
		}
	}, []);

	useEffect(() => {
		determineStep();
	}, [provider]);

	const determineStep = () => {
		const { stripeStatus } = provider;
		if (stripeStatus) {
			const { stripe_data } = stripeStatus;

			const { currently_due } = stripe_data;

			const fullSsn = currently_due.filter((item) => item.includes('individual.id_number'));
			if (fullSsn.length > 0) {
				setIsLoading(false);
				setCareProBankingOnboardStep(1);

				return;
			}

			// check if we need to upload documents for verification
			const documentUpload = currently_due.filter((item) => item.includes('document'));

			if (documentUpload.length > 0) {
				setIsLoading(false);
				setCareProBankingOnboardStep(2);
				return;
			}

			// if we make it here we can take the user back to the home page since they don't need to take any action
			history.push('/carepro');
		}
	};

	const updateProviderVerificationInfo = async (inputs) => {
		console.log('updateProviderVerificationInfo - inputs', inputs);
		try {
			const res = await api.graph({
				query: `mutation {
					updateProviderStripeInformation(
						updateValues: ${api.graphStringify(inputs)},
					) {
            stripeStatus {
              ${STRIPE_STATUS_MODEL}
            }
            careProRegistryStatus {
              ${CARE_PRO_REGISTRY_STATUS_MODEL}
            }
					}
				}`,
			});

			const data = res.updateProviderStripeInformation;
			console.log('updateProviderVerificationInfo-data', data);
			if (data.errors && data.errors.length > 0) {
				toast.error(data.errors[0].message, toastErrorConfig);
			} else {
				// update the provider
				toast.success('Banking information updated successfully', toastSuccessConfig);
				receiveProviderInfo({
					...provider,
					stripeStatus: data.stripeStatus,
					careProRegistryStatus: data.careProRegistryStatus,
				});
			}

			setIsLoading(false);
		} catch (err) {
			console.log('err', err);
			setIsLoading(false);
			if (err[0] && err[0].message) {
				toast.error(err[0].message, toastErrorConfig);
			}

			console.log('updateProviderVerificationInfo - err', err);
		}
	};

	const renderView = () => {
		switch (careProBankOnboardStep) {
			case 1:
				return (
					<ConfirmIdNumber
						isLoading={isLoading}
						updateProviderVerificationInfo={updateProviderVerificationInfo}
						setIsLoading={setIsLoading}
					/>
				);
			case 2:
				return (
					<Elements stripe={stripePromise}>
						<DocumentVerify
							isLoading={isLoading}
							updateProviderVerificationInfo={updateProviderVerificationInfo}
							setIsLoading={setIsLoading}
							stripeStatus={provider.stripeStatus}
							provider={provider}
						/>
					</Elements>
				);
			default:
				return <>default</>;
		}
	};

	return (
		<ColumnFlex
			fullWidth
			padding='16px'
			style={{
				backgroundColor: Colors.theme.primary_background,
				minHeight: 'calc(100vh - 120px)',
			}}
			center
			gap='32px'
		>
			{isLoading ? <Hearts /> : <>{renderView()}</>}
		</ColumnFlex>
	);
};

const mapStateToProps = ({ environment, provider }, props) => {
	return {
		provider: provider.provider,
		fetchingCareProSession: provider.fetchingCareProSession,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createProviderStripeAccount: () => dispatch(createProviderStripeAccount()),

		handleProviderLogin: (data) => dispatch(handleProviderLogin(data)),
		receiveProviderInfo: (data) => dispatch(receiveProviderInfo(data)),
		checkProviderSession: () => dispatch(checkProviderSession()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyBanking);
