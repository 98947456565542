import moment from 'moment';
import { z } from 'zod';

export const calendarFormValidationSchema = z
	.object({
		start: z.string().min(1, 'Start time is required.'), // HH:mm or full datetime depending on frequency
		end: z.string().min(1, 'End time is required.'), // HH:mm or full datetime
		startDate: z.string().optional(), // Start date (only for recurring events)
		endDate: z.string().optional(), // End date (only for recurring events)
		repeatFrequency: z.string().min(1, 'Repeat frequency is required.'),
		selectedDays: z.array(z.string()).optional(), // Required for weekly
	})
	// Time validation for start and end times
	.refine(
		(data) => {
			const { start, end, repeatFrequency } = data;

			if (repeatFrequency === 'one_time') {
				// Full datetime comparison for one-time events
				const startTime = new Date(start);
				const endTime = new Date(end);
				return endTime > startTime; // Ensure end time is after start time
			} else {
				// Handle time-only comparison for recurring events (daily, weekly, etc.)
				const [startHours, startMinutes] = start.split(':');
				const [endHours, endMinutes] = end.split(':');

				const now = moment(); // Use current date
				const startTime = moment(now).set({
					hour: startHours,
					minute: startMinutes,
					second: 0,
				});
				const endTime = moment(now).set({
					hour: endHours,
					minute: endMinutes,
					second: 0,
				});

				return endTime.isAfter(startTime); // Ensure end time is after start time
			}
		},
		{
			message: 'End time must be after start time.',
			path: ['end'],
		}
	)
	// Validate that the start time is in the future for one-time events
	.refine(
		(data) => {
			if (data.repeatFrequency === 'one_time') {
				const startTime = new Date(data.start);
				const now = new Date();
				return startTime >= now; // Ensure start time is not in the past
			}
			return true;
		},
		{
			message: 'Start time cannot be in the past.',
			path: ['start'],
		}
	)
	// Ensure at least one day is selected for weekly events
	.refine(
		(data) => {
			if (data.repeatFrequency === 'weekly') {
				return data.selectedDays && data.selectedDays.length > 0;
			}
			return true;
		},
		{
			message: 'Please select at least one day for weekly events.',
			path: ['selectedDays'],
		}
	)
	.refine(
		(data) => {
			if (data.repeatFrequency !== 'one_time') {
				return data.startDate && moment(data.startDate, 'YYYY-MM-DD', true).isValid();
			}
			return true;
		},
		{
			message: 'Start date must be a valid date in YYYY-MM-DD format.',
			path: ['startDate'],
		}
	)
	// Validate endDate format if repeatFrequency is not "one_time"
	.refine(
		(data) => {
			if (data.repeatFrequency !== 'one_time') {
				return data.endDate && moment(data.endDate, 'YYYY-MM-DD', true).isValid();
			}
			return true;
		},
		{
			message: 'End date must be a valid date in YYYY-MM-DD format.',
			path: ['endDate'],
		}
	)
	// Ensure startDate is in the future if repeatFrequency is not "one_time"
	.refine(
		(data) => {
			if (data.repeatFrequency !== 'one_time' && data.startDate) {
				const start = moment(data.startDate, 'YYYY-MM-DD');
				return start.isAfter(moment(), 'day'); // Start date should be in the future
			}
			return true;
		},
		{
			message: 'Start date must be in the future.',
			path: ['startDate'],
		}
	)
	// Ensure endDate is at least one week after startDate if repeatFrequency is not "one_time"
	.refine(
		(data) => {
			if (data.repeatFrequency !== 'one_time' && data.startDate && data.endDate) {
				const start = moment(data.startDate, 'YYYY-MM-DD');
				const end = moment(data.endDate, 'YYYY-MM-DD');
				return end.isSameOrAfter(start.add(7, 'days'), 'day'); // End date should be a week after start date
			}
			return true;
		},
		{
			message: 'End date must be at least one week after the start date.',
			path: ['endDate'],
		}
	);
