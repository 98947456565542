import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { ColumnFlex, RowFlex, BackDropBg } from 'basicStyles';
import { AnimatePresence } from 'framer-motion';

import Colors from 'colors';

import Text from 'AppComp/Text';
import Button from 'AppComp/Button';

import { searchProvidersByCarePlan } from 'reducers/provider';
import { userContactCarePro } from 'reducers/patient';

import api from 'scripts/api';
import { TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG, APPLICATION_STATUS_ENUM } from 'constants';
import { CORE_JOB_POST_MODEL } from 'reducers/graphqlModelTypes';

import JobPostSearchFilter from './JobPostSearchFilter';
import PageLoaderView from '../../PageLoaderView';
import NavSelector, { navItemsModifier } from '../../core/NavSelector';
import Tiles from '../../core/Tiles';
import { StyledCareProTilesContainer } from './styles';

import JobPostSchedule from '../ClientSchedule/JobPostSchedule';

export const PARENT_NAV_ITEMS = {
	schedule: {
		text: 'Schedule',

		active: true,
	},
	carePros: {
		text: 'Find Care Pros',
		active: false,
	},
};
export const NAV_ITEMS = {
	search: {
		text: 'Search',
		stat: 0,
		active: true,
	},
	invited: {
		text: 'Invited',
		stat: 0,
		active: false,
	},
	applied: {
		text: 'Applied',
		stat: 0,
		active: false,
	},
	offered: {
		text: 'Offered',
		stat: 0,
		active: false,
	},
	rejected: {
		text: 'Rejected',
		stat: 0,
		active: false,
	},
	active: {
		text: 'Active Jobs',
		stat: 0,
		active: false,
	},
};

const framerSidebarBackground = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};

const JobPostHandler = (props) => {
	const {
		carePlan,
		match: { params },
		carePros,
		patient,
		userContactCarePro,
		push,
	} = props;

	const [searchJSON, setSearchJSON] = useState({});

	//state for nav items
	const [navItems, setNavItems] = useState(NAV_ITEMS);

	// state for parent nav items
	const [parentNavItems, setParentNavItems] = useState(PARENT_NAV_ITEMS);

	// current job post
	const [jobPost, setJobPost] = useState(null);

	// state to help manage the offer modal
	const [showSearchFilterModal, setShowSearchFilterModal] = useState(false);

	useEffect(() => {
		// ensure job post is present before fetching stats
		if (jobPost) {
			getJobPostStats();
		}
	}, [carePros, jobPost]);

	useEffect(() => {
		fetchJobPost();
	}, [params]);

	useEffect(() => {
		if (carePros.length === 0 && carePlan !== null) {
			props.searchProvidersByCarePlan({
				page: 1,
				carePlanId: carePlan.id,
			});
		}
	}, [carePlan, carePros]);

	useEffect(() => {
		//if (jobPost) editedFilterInfo();
	}, [searchJSON, jobPost]);

	useEffect(() => {
		if (jobPost && !jobPost.edited_filter) {
			setShowSearchFilterModal(true);
		}
	}, [jobPost]);

	const history = useHistory();

	const searchFilterModalHandler = () => {
		setShowSearchFilterModal((prevValue) => !prevValue);
	};

	const searchJSONHandler = (value) => {
		setSearchJSON(value);
	};

	const getJobPostStats = () => {
		if (jobPost) {
			const { applications, jobs } = jobPost;

			const invited = applications.filter(
				(app) => app.status === APPLICATION_STATUS_ENUM.INVITED
			);

			// total applied - all items in application that have status of applied
			const applied = applications
				.filter((app) => app.status === APPLICATION_STATUS_ENUM.APPLIED)
				.filter(
					(app) =>
						jobPost.jobs.find((job) => job.provider.id === app.provider.id) ===
						undefined
				);

			const offeredCarePros = jobPost.jobs.filter(
				(job) => job.status === 'offer' && job.offer_status === 'offered'
			);

			const careProWhoRejected = jobPost.jobs.filter(
				(job) => job.status === 'offer' && job.offer_status === 'rejected'
			);

			const activeJobs = jobPost.jobs.filter(
				(job) => job.status === 'job' && job.job_status === 'active'
			);
			const stats = {
				search: carePros.length,
				invited: invited.length,
				applied: applied.length,
				offered: offeredCarePros.length,
				rejected: careProWhoRejected.length,
				active: activeJobs.length,
			};

			const updatedNavItems = navItemsModifier(navItems, null, 'stat', stats);
			setNavItems(updatedNavItems);
		}
	};

	const navSelectorHandler = (item, modifier, value) => {
		const updatedNavItems = navItemsModifier(navItems, item, modifier, value);

		setNavItems(updatedNavItems);
	};

	const navParentSelector = (item, modifier, value) => {
		const updatedNavItems = navItemsModifier(parentNavItems, item, modifier, value);

		setParentNavItems(updatedNavItems);
	};
	/*
	 * needs to be plugged in
	 */
	const editedFilterInfo = async () => {
		try {
			const editedFilterInfoRes = await api.graph({
				query: `mutation {
					editedFilterInfo(job_post_id: ${jobPost.id}) {
						${CORE_JOB_POST_MODEL}
					}
				}`,
			});
			if (editedFilterInfoRes.editedFilterInfo) {
				//toast.success('Filter edited', TOAST_SUCCESS_CONFIG);
				setJobPost(editedFilterInfoRes.editedFilterInfo);
			}
		} catch (err) {
			console.log('err', err);
		}
	};

	// invite carePro to apply
	const inviteCarePro = async (providerId) => {
		try {
			const inviteCareProRes = await api.graph({
				query: `mutation {
					inviteProviderToJobPost(
						job_post_id: ${jobPost.id},
						provider_id: ${providerId}
					){
						${CORE_JOB_POST_MODEL}
					}
				}`,
			});

			if (inviteCareProRes.inviteProviderToJobPost) {
				toast.success('Care pro invited', TOAST_SUCCESS_CONFIG);
				setJobPost(inviteCareProRes.inviteProviderToJobPost);
			}
		} catch (err) {
			console.log('err', err);
			toast.error('Error inviting care pro', TOAST_ERROR_CONFIG);
		}
	};

	const fetchJobPost = async () => {
		try {
			if (params.jobPostId) {
				const fetchSingleJobPostRes = await api.graph({
					query: `{
            fetchSingleJobPost(job_post_id: ${params.jobPostId}) {
              ${CORE_JOB_POST_MODEL}
            }
          }`,
				});

				if (fetchSingleJobPostRes.fetchSingleJobPost) {
					setJobPost(fetchSingleJobPostRes.fetchSingleJobPost);
				}
			}
		} catch (err) {
			console.log('err', err);
			toast.error('Error fetching job post', TOAST_ERROR_CONFIG);

			//throw err;
		}
	};

	if (carePlan === null) {
		return (
			<ColumnFlex
				fullWidth
				padding='16px'
				style={{
					backgroundColor: Colors.theme.primary_background,
					minHeight: 'calc(100vh - 120px)',
				}}
				alignCenter
				justifyCenter
			>
				<PageLoaderView errorMessage='Error Care Plan' />
			</ColumnFlex>
		);
	}

	if (jobPost === null) {
		return (
			<ColumnFlex
				fullWidth
				padding='16px'
				style={{
					backgroundColor: Colors.theme.primary_background,
					minHeight: 'calc(100vh - 120px)',
				}}
				alignCenter
				justifyCenter
			>
				<PageLoaderView errorMessage='Error fetching job post' />
			</ColumnFlex>
		);
	}

	const careProClickHandler = (careProId) => {
		history.push('/jobPost/' + jobPost.id + '/carePro/' + careProId);
	};

	const careProOfferClickHandler = (careProId) => {
		history.push(`/jobPost/${jobPost.id}/carePro/${careProId}/offer`);
	};

	const renderCarePros = () => {
		// ensure no providers found in the invited tab are show in the search tab

		const invitedCareProIds = jobPost.applications
			.filter((app) => app.status === APPLICATION_STATUS_ENUM.INVITED)
			.map((app) => app.provider.id);

		// remove all carePros that have been invited from the search tab
		const searchCarePros = carePros.filter((carePro) => {
			return !invitedCareProIds.includes(carePro.id);
		});

		return filterCareProsBySelection(searchCarePros).map((carePro, index) => {
			return (
				<Tiles.CareProTile
					buttonText='Invite'
					key={carePro.id}
					{...carePro}
					onSelectCarePro={(careProId) => {
						inviteCarePro(careProId.id);
					}}
					careProClickHandler={careProClickHandler}
				/>
			);
		});
	};

	const displayInvitedCarePros = () => {
		const invitedCarePros = jobPost.applications
			.filter((app) => app.status === APPLICATION_STATUS_ENUM.INVITED)
			.map((app) => app.provider);

		return filterCareProsBySelection(invitedCarePros).map((carePro, index) => {
			return (
				<Tiles.CareProTile
					key={carePro.id}
					buttonText='Chat'
					onSelectCarePro={(carePro) => {
						handleLinkToChat(carePro.id);
					}}
					careProClickHandler={careProClickHandler}
					{...carePro}
				/>
			);
		});
	};

	const displayAppliedCarePros = () => {
		const appliedCarePros = jobPost.applications
			.filter((app) => app.status === APPLICATION_STATUS_ENUM.APPLIED)
			.filter(
				(app) =>
					jobPost.jobs.find((job) => job.provider.id === app.provider.id) === undefined
			)
			.map((app) => app.provider);

		return filterCareProsBySelection(appliedCarePros).map((carePro, index) => {
			return (
				<Tiles.CareProTile
					key={carePro.id}
					buttonText='Offer'
					onSelectCarePro={(carePro) => {
						careProOfferClickHandler(carePro.id);
					}}
					careProClickHandler={careProClickHandler}
					{...carePro}
				/>
			);
		});
	};

	const displayOfferedCarePros = () => {
		// kinda shitty but we need to add job to the CarePro tile to show how much was offered

		//const offeredCarePros = jobPost.jobs.map((job) => job.provider);

		// filter jobs that have been that are status offera nd offer status offered
		const offeredCarePros = jobPost.jobs.filter(
			(job) => job.status === 'offer' && job.offer_status === 'offered'
		);

		return offeredCarePros.map((job, index) => {
			return (
				<Tiles.CareProTile
					key={job.id}
					buttonText='Chat'
					onSelectCarePro={(carePro) => {
						handleLinkToChat(carePro.id);
					}}
					careProClickHandler={careProClickHandler}
					{...job.provider}
					job={job}
				/>
			);
		});
	};

	const displayActiveCarePros = () => {
		// kinda shitty but we need to add job to the CarePro tile to show how much was offered

		//const offeredCarePros = jobPost.jobs.map((job) => job.provider);

		// filter jobs that have been that are status offera nd offer status offered
		const offeredCarePros = jobPost.jobs.filter(
			(job) => job.status === 'job' && job.job_status === 'active'
		);

		return offeredCarePros.map((job, index) => {
			return (
				<Tiles.CareProTile
					key={job.id}
					buttonText='Re Offer'
					onSelectCarePro={(carePro) => {
						careProOfferClickHandler(carePro.id);
					}}
					careProClickHandler={careProClickHandler}
					{...job.provider}
					job={job}
				/>
			);
		});
	};

	const displayDeclinedCarePros = () => {
		// kinda shitty but we need to add job to the CarePro tile to show how much was offered

		//const offeredCarePros = jobPost.jobs.map((job) => job.provider);

		// filter jobs that have been that are status offera nd offer status offered
		const offeredCarePros = jobPost.jobs.filter(
			(job) => job.status === 'offer' && job.offer_status === 'rejected'
		);

		return offeredCarePros.map((job, index) => {
			return (
				<Tiles.CareProTile
					key={job.id}
					buttonText='Re Offer'
					onSelectCarePro={(carePro) => {
						careProOfferClickHandler(carePro.id);
					}}
					careProClickHandler={careProClickHandler}
					{...job.provider}
					job={job}
				/>
			);
		});
	};

	const handleLinkToChat = async (careProId) => {
		try {
			// this needs an extra handler since there is a chance the user won't have a conversation with the CarePro yet

			// check existing conversations and find the one with the carePro
			const conversation = props.conversations.find(
				(convo) => convo.provider && convo.provider.id === careProId
			);
			if (conversation) {
				push(conversation.id);
			} else {
				// ensure user (alias patient) is logged in
				if (patient === null) {
					return;
				}
				userContactCarePro({
					careProId: careProId,
					userId: patient.id,
				});
			}
		} catch (err) {
			console.log('err', err);
		}
	};

	const filterCareProsBySelection = (carePros) => {
		// we'll start with just the price filter for the time being
		const { budget_low, budget_high } = jobPost;

		console.log('filterCareProsBySelection', budget_low);
		const filteredCarePros = carePros.filter((carePro) => {
			const { hourly_rate } = carePro;
			const hourlyRate = hourly_rate / 100;
			return hourlyRate >= budget_low && hourlyRate <= budget_high;
		});

		/*
			WE'RE RETURNING ALL CAREPROS TO BY PASS THE OLD FILTER
		*/
		return carePros;

		// sort carePros by hourly rate in a ascending order
		return filteredCarePros.sort((a, b) => a.hourly_rate - b.hourly_rate);
	};

	const renderCareProsByTab = () => {
		let careProsToRender = [];

		if (navItems.search.active) {
			careProsToRender = renderCarePros();
		} else if (navItems.invited.active) {
			careProsToRender = displayInvitedCarePros();
		} else if (navItems.applied.active) {
			careProsToRender = displayAppliedCarePros();
		} else if (navItems.offered.active) {
			careProsToRender = displayOfferedCarePros();
		} else if (navItems.rejected.active) {
			careProsToRender = displayDeclinedCarePros();
		} else if (navItems.active.active) {
			careProsToRender = displayActiveCarePros();
		}

		if (careProsToRender.length === 0) {
			return (
				<ColumnFlex alignCenter fullWidth>
					<Text>No Care Pros found</Text>
				</ColumnFlex>
			);
		} else {
			return careProsToRender;
		}
	};

	return (
		<ColumnFlex
			fullWidth
			padding='16px'
			style={{
				backgroundColor: Colors.theme.primary_background,
				minHeight: 'calc(100vh - 120px)',
			}}
			alignCenter
			justifyCenter
		>
			<AnimatePresence>
				{showSearchFilterModal && (
					<>
						<BackDropBg
							{...framerSidebarBackground}
							onClick={searchFilterModalHandler}
							aria-hidden='true'
						></BackDropBg>
						<div style={{ position: 'fixed', top: '40%', zIndex: 100, left: '50%' }}>
							<JobPostSearchFilter searchJSONHandler={searchJSONHandler} />
						</div>
					</>
				)}
			</AnimatePresence>

			<RowFlex
				fullWidth
				gap='32px'
				style={{
					maxWidth: '1600px',
					flex: 1,
				}}
				columnMobile
			>
				<ColumnFlex
					style={{
						flex: 1,
						marginTop: '1rem',
					}}
					alignCenter
					gap='16px'
				>
					<RowFlex
						style={{
							maxWidth: '868px',
						}}
						fullWidth
						center
					>
						<NavSelector
							items={parentNavItems}
							navSelectorHandler={navParentSelector}
						/>
					</RowFlex>
					{parentNavItems.schedule.active && (
						<JobPostSchedule
							history={history}
							jobPost={jobPost}
							carePlan={carePlan}
							patient={patient}
						/>
					)}
					{parentNavItems.carePros.active && (
						<ColumnFlex
							center
							fullWidth
							style={{
								maxWidth: '868px',
							}}
						>
							<NavSelector items={navItems} navSelectorHandler={navSelectorHandler} />

							<ColumnFlex
								style={{
									flex: 1,

									borderRadius: '32px',
									textAlign: 'center',
								}}
								fullWidth
								gap='24px'
							>
								<div style={{ padding: '24px' }}>
									{navItems.search.active && (
										<ColumnFlex
											fullWidth
											gap='16px'
											style={{ marginBottom: '1.5rem' }}
										>
											<Button
												onClick={searchFilterModalHandler}
												backgroundColor={Colors.primary.primary}
												width={'200px'}
												height={'50px'}
												margin='auto'
												borderRadius='32px'
												style={{
													boxShadow: 'none',
												}}
											>
												<Text fontWeight='500' color={'white'} largeText>
													Filters
												</Text>
											</Button>

											{/* <CareProSearchFilter />   */}
										</ColumnFlex>
									)}

									<StyledCareProTilesContainer>
										{renderCareProsByTab()}
									</StyledCareProTilesContainer>
								</div>
							</ColumnFlex>
						</ColumnFlex>
					)}
				</ColumnFlex>
			</RowFlex>
		</ColumnFlex>
	);
};

const mapStateToProps = ({ patient, provider }) => {
	return {
		patient: patient.patient,
		carePlan: patient.carePlan,
		carePros: provider.caregivers,
		conversations: patient.conversations,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		searchProvidersByCarePlan: (params) =>
			dispatch(searchProvidersByCarePlan({ ...params, caregiverType: params.providerType })),
		userContactCarePro: (params) => dispatch(userContactCarePro(params)),
		push: (conversationId) => dispatch(push(`/chat/${conversationId}`)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(JobPostHandler);
