import React, { Component } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { connect } from 'react-redux';
import { faUserMd, faChild } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

import { RowFlex } from 'AppComp/styled/Basic';
import Colors from 'colors';

import Icon from 'AppComp/Icon';
import Text from 'AppComp/Text';
import Button from 'AppComp/Button';

const IconCon = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 80px;
	padding: 16px;
	background-color: ${(props) => (props.active ? Colors.grayWhite : Colors.fullWhite)};
	cursor: pointer;
`;

const ConversationFilter = () => {
	// could be ['', 'provider', 'patient', 'carePro]
	const selectedConversationType = '';
	// could be ['admin', 'user', 'carePro]

	const loggedInUserType = 'provider';

	return (
		<RowFlex padding='10px 0 0px 0' fullWidth spaceBetween>
			<RowFlex fullWidth spaceAround>
				<IconCon>
					<Icon
						icon={faChild}
						size='sm'
						color={selectedConversationType === null ? Colors.blue : Colors.gray}
					/>
					<Text color={selectedConversationType === null ? Colors.blue : Colors.gray}>
						All
					</Text>
				</IconCon>
				{loggedInUserType !== 'user' && (
					<IconCon active={selectedConversationType === 'patient'}>
						<Icon
							icon={faChild}
							size='sm'
							color={selectedConversationType === 'user' ? Colors.blue : Colors.gray}
						/>
						<Text
							color={selectedConversationType === 'user' ? Colors.blue : Colors.gray}
						>
							User
						</Text>
					</IconCon>
				)}
				{loggedInUserType !== 'carePro' && (
					<IconCon>
						<Icon
							icon={faUserMd}
							size='sm'
							color={
								selectedConversationType === 'carePro' ? Colors.blue : Colors.gray
							}
						/>
						<Text
							color={
								selectedConversationType === 'carePro' ? Colors.blue : Colors.gray
							}
						>
							CarePro
						</Text>
					</IconCon>
				)}

				<IconCon>
					<Icon
						icon={faUserMd}
						size='sm'
						color={
							selectedConversationType === 'care_provider' ? Colors.blue : Colors.gray
						}
					/>
					<Text
						color={
							selectedConversationType === 'care_provider' ? Colors.blue : Colors.gray
						}
					>
						Admin
					</Text>
				</IconCon>
			</RowFlex>
		</RowFlex>
	);
};

export default ConversationFilter;
