import React from 'react';

import { StyledText } from './styles';

const Text = ({ children, size }) => {
	return <StyledText size={size}>{children}</StyledText>;
};

export default Text;

export const TextStyled = ({ children, size, ...restProps }) => {
	return (
		<StyledText {...restProps} size={size}>
			{children}
		</StyledText>
	);
};
