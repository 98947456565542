import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledSortButton = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;

	height: 100%;
	position: relative;
`;

export const StyledSortButtonWrapper = styled.div`
	position: relative;
	top: ${({ up }) => (up ? '7px' : '-7px')};
`;

export const StyledSortIcon = styled(FontAwesomeIcon)`
	opacity: 0.3;
`;
