import React, { Component, Fragment } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { AppContainer, RowFlex } from 'basicStyles';

import { PROVIDER_NAV_WIDTH } from 'constants';

const CustomRow = styled(RowFlex)`
	overflow: hidden;
`;

import AdminNavigation from './AdminNavigation';

const AdminHomeLayout = ({ THEME, children, history, loggedIn }) => {
	const page = history.location.pathname;

	console.log('loggedin', loggedIn);
	const showNav = loggedIn || page === '/carepro-welcome' ? true : false;
	console.log('showNav', showNav);

	return (
		<ThemeProvider theme={THEME}>
			<AppContainer
				backgroundColor={'#2089d5'}
				style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
			>
				{showNav && (
					<>
						<AdminNavigation providerPortal={true} history={history} />
						<div
							style={{
								width: PROVIDER_NAV_WIDTH,
								height: '100vh',
							}}
						/>
					</>
				)}
				<div
					style={{
						//paddingTop: '16px',

						width: showNav ? `calc(100vw - ${PROVIDER_NAV_WIDTH})` : '100vw',
					}}
				>
					<CustomRow
						style={{
							borderRadius: '12px',
							paddingLeft: showNav ? '20px' : '0',
							backgroundColor: '#EEF6FC',
							width: showNav ? `calc(100vw - ${PROVIDER_NAV_WIDTH})` : '100vw',

							minHeight: '100vh',
						}}
					>
						{children}
					</CustomRow>
				</div>
			</AppContainer>
		</ThemeProvider>
	);
};

export default AdminHomeLayout;
