import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import changeCase from 'change-case';
import { Hearts } from 'react-loader-spinner';

import { Section } from 'AppComp/admin/UserModel/UserModelBlocks';
import { ModelContainer } from 'AppComp/EventModel';

import { verifyStripePayment } from 'reducers/patient';

import Button from 'AppComp/Button';
import Text from 'AppComp/Text';

import { ColumnFlex, RowFlex } from 'basicStyles';
import Colors from 'colors';
import api from 'scripts/api';
import AnimateHeight from 'react-animate-height';
import CollectCardHandler from '../CollectCard';
import { DefaultPaymentMethod } from '../PatientSetting/PatientSettingViews';
import { toast } from 'react-toastify';
import { toastErrorConfig } from '../../../reducers/provider';

//TODO ADD ENV LOGIC FROM TEST TO PROD
//var stripe = Stripe(process.env.STRIPE_PUBLIC_KEY);

const Payment = (props) => {
	console.log('payment payment', props);
	const {
		verifyStripePayment,
		savedPaymentMethods,
		id,
		stripe_error_message,
		stripe_error_code,
		provider,
		date,
	} = props;

	const [loading, updateLoader] = useState(false);
	const [showCollectPaymentMethod, updateShowCollectPaymentMethod] = useState(false);
	const handleAuthorizePayment = async () => {
		try {
			updateLoader(true);
			const _temp = await api.graph({
				query: `{
					fetchPayment(payment_id: ${id}) {
						stripe_intent_c_s
					}
				}`,
			});

			if (savedPaymentMethods.length > 0) {
				const stripeCardPaymentRes = await stripe.confirmCardPayment(
					_temp.fetchPayment.stripe_intent_c_s,
					{
						payment_method: savedPaymentMethods[0].id,
					}
				);
				console.log('stripeCardPaymentRes', stripeCardPaymentRes);

				if (stripeCardPaymentRes.error) {
					// Show error to your customer
					if (stripeCardPaymentRes.error.code === 'payment_intent_unexpected_state') {
						if (stripeCardPaymentRes.error.payment_intent.status === 'succeeded') {
							// The payment is already complete should let the backend know to check
							verifyStripePayment(id);
						}
					}
					console.log(stripeCardPaymentRes.error.message);
				} else {
					if (stripeCardPaymentRes.paymentIntent.status === 'succeeded') {
						// The payment is complete!
						verifyStripePayment(id);
					}
				}

				updateLoader(false);
			} else {
				toast.error('Please add a card to your account', toastErrorConfig);
			}
		} catch (err) {
			console.log('handleAuthorizePayment', err);
			updateLoader(false);
		}
	};

	const renderSavedTokensInputs = () => {
		return savedPaymentMethods
			.filter((d, i) => {
				console.log('d', d);
				return d.default_card === 1;
			})
			.map((d, i) => {
				return <DefaultPaymentMethod key={'sti-' + d.id} {...d} />;
			});
	};

	const closeToast = () => {
		updateShowCollectPaymentMethod(true);
	};
	return (
		<Section title={`${date}: ${changeCase.sentenceCase(stripe_error_message)}`}>
			{true && (
				<ColumnFlex center padding='16px 0' gap='16px'>
					{!showCollectPaymentMethod && (
						<>
							<ColumnFlex center gap='8px'>
								<Text largeText bold>
									Option 1: Add New Card
								</Text>
								<ColumnFlex center gap='4px'>
									<Text mediumText>Current default card</Text>
									{renderSavedTokensInputs()}
									<Text subtitle>
										If you have already resolved the issue with your payment, no
										further action is required on your part. Your account will
										be charged as normal on the next business day.
									</Text>
								</ColumnFlex>
							</ColumnFlex>
							<Button
								onClick={() => updateShowCollectPaymentMethod(true)}
								width='170px'
								margin='auto'
								backgroundColor={Colors.secondary.green}
							>
								Add New Card
							</Button>
						</>
					)}

					<AnimateHeight duration={500} height={showCollectPaymentMethod ? 'auto' : '0%'}>
						<CollectCardHandler closeToast={closeToast} />
						<Text
							onClick={() => updateShowCollectPaymentMethod(false)}
							pointer
							underline
							darkGray
							subtitle
							margin='0px 0 12px 0'
						>
							Cancel
						</Text>
					</AnimateHeight>
				</ColumnFlex>
			)}
			{true && (
				<ColumnFlex center padding='16px 0' gap='16px'>
					<ColumnFlex center gap='8px'>
						<Text largeText bold>
							Option 2: Authorize Payment
						</Text>
					</ColumnFlex>
					<Button
						width='170px'
						margin='auto'
						backgroundColor={Colors.primary.primary}
						onClick={() => handleAuthorizePayment()}
					>
						Authorize Payment
					</Button>
				</ColumnFlex>
			)}
			{stripe_error_code === 'authentication_required' && (
				<RowFlex padding='16px' fullWidth center>
					{loading ? (
						<Hearts color={Colors.primary.primary} height={50} width={50} />
					) : (
						<ColumnFlex center padding='16px 0' gap='32px'>
							<ColumnFlex center gap='8px'>
								<Text largeText bold>
									Payment Requires Additional Authentication
								</Text>
								<Text>Please authorize your payment to continue</Text>
							</ColumnFlex>
							<Button
								width='170px'
								margin='auto'
								backgroundColor={Colors.primary.primary}
								onClick={() => handleAuthorizePayment()}
							>
								Authorize Payment
							</Button>
						</ColumnFlex>
					)}
				</RowFlex>
			)}
		</Section>
	);
};
const PaymentError = ({ verifyStripePayment, payments, savedPaymentMethods, closeToast }) => {
	return (
		<ModelContainer>
			<ColumnFlex center>
				<Text color={'black'} largeText padding='12px'>
					We're sorry to hear that your card was unable to process your payment(s) during
					an offline session.
				</Text>
				<Text color={'black'} mediumText padding='8px'>
					Don't worry, we've got you covered. You have two options to choose from:
				</Text>
				<Text color={'black'} mediumText padding='8px'>
					Option 1: Change your card and wait for the payment to be reattempted the next
					day.
				</Text>
				<Text color={'black'} mediumText padding='8px'>
					Option 2: Charge your card right away and process the payment.
				</Text>
				<Text color={'black'} padding='4px'>
					To choose option 1, simply select "Change Card" and enter your new card details.
					We'll reattempt the payment the next day.
				</Text>
				<Text color={'black'} padding='4px'>
					To choose option 2, select "Charge Card Now" and follow the prompts to process
					the payment.
				</Text>

				{payments
					.filter((d, i) => {
						return d.status === 'failed_payment';
					})
					.map((d, i) => {
						return (
							<Payment
								verifyStripePayment={verifyStripePayment}
								savedPaymentMethods={savedPaymentMethods}
								{...d}
							/>
						);
					})}
				<Text margin='8px 0 0 0' color={'black'} padding='4px'>
					Thank you for your prompt attention to this matter. If you have any further
					questions or concerns, please don't hesitate to contact our customer support
					team.
				</Text>
			</ColumnFlex>
		</ModelContainer>
	);
};

const mapStateToProps = ({ patient }) => {
	return {
		savedPaymentMethods: patient.savedPaymentMethods,
		payments: patient.payments,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		verifyStripePayment: (paymentId) => dispatch(verifyStripePayment(paymentId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentError);
