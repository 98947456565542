import React, { Component, useState, useMemo, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import Colors from 'colors';
import styled from 'styled-components';
import { Views } from 'react-big-calendar';
import api from 'scripts/api';
import { JOB_OFFER_STATUS_ENUM, JOB_STATUS } from 'constants';

import { ColumnFlex, RowFlex } from 'basicStyles';
import { WORK_TIME_FRAME_TYPE } from 'constants';

import { CareProScheduleToolbar } from './CareProScheduleView';

import Schedule from 'AppComp/patient/ClientSchedule/Schedule';

import { CORE_APPLICATION_MODEL, CORE_JOB_MODEL } from 'reducers/graphqlModelTypes';

export const TileType = {
	JOB: 'JOB',
	OFFER: 'OFFER',
	SHIFT: 'SHIFT',
};

const ScheduleHandler = (props) => {
	const [userSelectedDate, setUserSelectedDate] = useState(new Date());
	const currentDate = useMemo(() => userSelectedDate, [userSelectedDate]);

	const [events, setEvents] = useState([]);

	const [view, setView] = useState({
		label: 'Month',
		value: Views.MONTH,
	});

	const [displayLabel, setDisplayLabel] = useState('Day');

	const [selectedDateTileType, setSelectedDateTileType] = useState([
		TileType.JOB,
		TileType.OFFER,
		TileType.SHIFT,
	]);

	const onSelectEvent = (event) => {
		console.log('onSelectEvent', event);
	};

	useEffect(() => {
		fetchCareProJobs();
	}, []);

	const fetchCareProJobs = async () => {
		const res = await api.graph({
			query: `{
				checkProviderSession{
					jobs {
						${CORE_JOB_MODEL}
					}
				}
			}`,
		});

		if (res.checkProviderSession) {
			parseJobsForEvents(res.checkProviderSession.jobs);
		}
	};

	const parseJobsForEvents = (jobs) => {
		console.log('parseJobsForEvents', jobs);
		// get all the active jobs
		const acceptedJobs = jobs.filter(
			(job) =>
				job.offer_status === JOB_OFFER_STATUS_ENUM.ACCEPTED &&
				job.job_status === JOB_STATUS.ACTIVE
		);

		console.log('acceptedJobs', acceptedJobs);
		// get all the active events for the active jobs
		const _events = [];

		acceptedJobs.forEach((job) => {
			// get the active timeframes
			const activeTimeFrames = job.workTimeFrames.filter(
				(timeFrame) => timeFrame.active_status_enum === WORK_TIME_FRAME_TYPE.ACTIVE
			);

			activeTimeFrames.forEach((timeFrame) => {
				const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's time zone

				const userStartDate = new Date(
					timeFrame.start_time.toLocaleString('en-US', { timeZone: userTimezone })
				);
				const userEndDate = new Date(
					timeFrame.end_time.toLocaleString('en-US', { timeZone: userTimezone })
				);
				_events.push({
					title: 'Care Session',
					start: userStartDate,
					end: userEndDate,
					allDay: false,
					resource: {
						type: 'careSession',
						workTimeFrame: timeFrame,
						job: job,
					},
				});
			});
		});

		console.log('_events', _events);
		setEvents(_events);
	};
	return (
		<RowFlex
			columnMobile
			style={{
				backgroundColor: Colors.theme.primary_background,
			}}
			fullWidth
			padding='16px'
			gap='16px'
		>
			<ColumnFlex gap='32px'>
				<CareProScheduleToolbar
					displayLabel={displayLabel}
					setView={setView}
					view={view}
					currentDate={currentDate}
					setUserSelectedDate={setUserSelectedDate}
					selectedDateTileType={selectedDateTileType}
					setSelectedDateTileType={setSelectedDateTileType}
				/>
			</ColumnFlex>
			<Schedule
				events={events}
				view={view}
				setDisplayLabel={setDisplayLabel}
				currentDate={currentDate}
				onSelectEvent={onSelectEvent}
			/>
		</RowFlex>
	);
};

export default ScheduleHandler;
