import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';

import {
	StyledPricingContainer,
	StyledHeading,
	StyledSection,
	StyledPricingWrapper,
	StyledPriceItem,
	StyledPrice,
	StyledTotal,
	StyledTotalPrice,
	StyledCalculatorContainer,
	StyledCalculatorWrapper,
	StyledCalculatorValueContainer,
	StyledCalculatedValue,
	StyledCalculatedValueLabel,
	StyledCalculatorButton,
} from './styles';

import { calculateStripeFee } from 'constants';

const Pricing = ({ hourlyRate = 0 }) => {
	const [careProHourlyRate, setCareProHourlyRate] = useState(0);
	const [careProCost, setCareProCost] = useState(0);
	const [agencyCost, setAgencyCost] = useState(0);
	const [stripeFee, setStripeFee] = useState(0);
	const [totalCharge, setTotalCharge] = useState(0);

	const hourWorked = 8;
	const agencyFee = 0.1;

	useEffect(() => {
		setCareProHourlyRate(hourlyRate / 100);
	}, [hourlyRate]);

	useEffect(() => {
		if (careProHourlyRate > 0) {
			const totalCareProCost = careProHourlyRate * hourWorked;
			setCareProCost(totalCareProCost);

			const totalAgencyCost = totalCareProCost * agencyFee;
			setAgencyCost(totalAgencyCost);

			const subTotal = totalCareProCost + agencyCost;

			const totalStripeFee = calculateStripeFee(subTotal);

			const chargeWithStripeFee = subTotal + totalStripeFee;

			setStripeFee(totalStripeFee);
			setTotalCharge(chargeWithStripeFee);
		}
	}, [careProHourlyRate]);

	const incrementPrice = () => {
		setCareProHourlyRate((prevValue) => prevValue + 1);
	};

	const deccrementPrice = () => {
		setCareProHourlyRate((prevValue) => prevValue - 1);
	};

	return (
		<StyledPricingContainer>
			<StyledHeading>Pricing</StyledHeading>
			<StyledSection>
				<StyledPricingWrapper>
					<StyledPriceItem>Hourly Rate</StyledPriceItem>
					<StyledPrice>${careProCost.toFixed(2)}</StyledPrice>
				</StyledPricingWrapper>
				<StyledPricingWrapper>
					<StyledPriceItem>Agency Fee</StyledPriceItem>
					<StyledPrice>${agencyCost.toFixed(2)}</StyledPrice>
				</StyledPricingWrapper>
			</StyledSection>
			<StyledSection>
				<StyledPricingWrapper>
					<StyledPriceItem>Subtotal</StyledPriceItem>
					<StyledPrice>{'$' + (careProCost + agencyCost).toFixed(2)}</StyledPrice>
				</StyledPricingWrapper>
				<StyledPricingWrapper>
					<StyledPriceItem>Processing Fees</StyledPriceItem>
					<StyledPrice>${stripeFee.toFixed(2)}</StyledPrice>
				</StyledPricingWrapper>
			</StyledSection>
			<StyledPricingWrapper>
				<StyledTotal>Total</StyledTotal>
				<StyledTotalPrice>{'$' + totalCharge.toFixed(2)}</StyledTotalPrice>
			</StyledPricingWrapper>

			<StyledCalculatorContainer>
				<StyledCalculatorWrapper>
					<StyledCalculatorButton onClick={deccrementPrice}>
						<FontAwesomeIcon size='sm' color='white' icon={faMinus} />
					</StyledCalculatorButton>
					<StyledCalculatorValueContainer>
						<StyledCalculatedValue>
							${careProHourlyRate?.toFixed(2)}
						</StyledCalculatedValue>
						<StyledCalculatedValueLabel>Per Hour</StyledCalculatedValueLabel>
					</StyledCalculatorValueContainer>
					<StyledCalculatorButton onClick={incrementPrice}>
						<FontAwesomeIcon size='sm' color='white' icon={faPlus} />
					</StyledCalculatorButton>
				</StyledCalculatorWrapper>
			</StyledCalculatorContainer>
		</StyledPricingContainer>
	);
};

export default Pricing;
