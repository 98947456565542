import React, { useEffect, useState } from 'react';

import { ColumnFlex, RowFlex } from 'basicStyles';
import Colors from 'colors';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';

import styled from 'styled-components';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { APPLICATION_STATUS_ENUM, TOAST_SUCCESS_CONFIG, TOAST_ERROR_CONFIG } from 'constants';

/* 
  styles: any{ }
  currentProgress:  number!
  children: React.ReactNode
*/
const CircularProgressBar = (props) => {
	const { styles, currentProgress, children, height, width } = props;

	return (
		<div
			style={{
				width: height || '100px',
				height: width || '100px',
			}}
		>
			<CircularProgressbarWithChildren
				strokeWidth={3}
				styles={buildStyles({})}
				value={currentProgress}
			>
				{children}
			</CircularProgressbarWithChildren>
		</div>
	);
};

const JobPostTileContainer = styled.div`
	width: 240px;
	padding: 20px 15px;
	border-radius: 8px;
	border: 2px solid #dddedf;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 15px;
	background-color: white;
	cursor: pointer;
`;

const StepCircleDiv = styled.div`
	height: 20px;
	width: 20px;
	border-radius: 50%;
	background-color: ${Colors.primary.primary};
	display: flex;
	justify-content: center;
	align-items: center;
`;
/* 
  JobPostStepSectionTypes

  completed: boolean!
  onClick: () => void
  label: string!
  stats: string!
*/
const JobPostStepSection = (props) => {
	const { label, stats, completed } = props;
	return (
		<RowFlex fullWidth alignCenter spaceBetween>
			<RowFlex alignCenter gap='15px'>
				<StepCircleDiv>
					{completed && <Icon size={'2xs'} icon={faCheck} color={'white'} />}
				</StepCircleDiv>
				<Text color={Colors.primary.primary} midLarge>
					{label}
				</Text>
			</RowFlex>
			<Text color={'black'} midLarge>
				{stats !== null ? stats : ''}
			</Text>
		</RowFlex>
	);
};
/* 
  JobPostStepSectionTypes
  sections: JobPostStepSectionTypes[]

*/
export const JobPostSections = (props) => {
	const { sections } = props;
	console.log('JobPostSections', props);
	return (
		<ColumnFlex fullWidth gap='15px' alignCenter>
			<div
				style={{
					width: '60%',
					backgroundColor: '#dddedf',
					height: '2px',
					borderRadius: '12px',
					marginBottom: '15px',
				}}
			/>
			{sections.map((section) => (
				<JobPostStepSection {...section} />
			))}
		</ColumnFlex>
	);
};

/* 
  JobPostTileTingType
  jobPost: CORE_JOB_POST_MODEL
  handleClick: (type) => void
*/

const SECTIONS = [
	{
		label: 'Invited',
		stats: '0',
		type: 'INVITED',
	},
	{
		label: 'Applied',
		stats: '0',
		type: 'APPLIED',
	},
	{
		label: 'Offered',
		stats: '0',
		type: 'OFFERED',
	},
];
const JobPostTileTing = (props) => {
	console.log('JobPostTileTing props', props);

	const { status, jobs, applications, onClick } = props;
	const [sections, setSections] = useState([]);

	useEffect(() => {
		getJobPostStats();
	}, [jobs, applications]);

	// invited, applied, offered

	const getJobPostStats = () => {
		const invited = applications.filter(
			(app) => app.status === APPLICATION_STATUS_ENUM.INVITED
		);

		// total applied - all items in application that have status of applied
		const applied = applications.filter(
			(app) => app.status === APPLICATION_STATUS_ENUM.APPLIED
		);

		SECTIONS[0].stats = invited.length;
		SECTIONS[1].stats = applied.length;
		SECTIONS[2].stats = jobs.length;

		setSections(SECTIONS);
	};

	const handleClick = (type) => {};
	return (
		<JobPostTileContainer onClick={() => onClick('jobPost')}>
			<CircularProgressBar currentProgress={50} styles={{}}>
				<Text largeText>50%</Text>
			</CircularProgressBar>
			<Text color={Colors.primary.primary} bold midLarge>
				Job Post
			</Text>
			<JobPostSections sections={sections} />
		</JobPostTileContainer>
	);
};

export default JobPostTileTing;
