import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { JOB_POST_MODEL } from '../../../core/api/models';

const fetchRegistryJobPosts = async ({ registryId }) => {
	try {
		const data = await api.graph({
			query: `{
					fetchRegistryJobPosts(registry_id: ${registryId}) {
						${JOB_POST_MODEL}
					}
				}`,
		});

		return data.fetchRegistryJobPosts;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchRegistryJobPostsQuery = (registryId) => {
	return useQuery({
		queryKey: ['fetchRegistryJobPosts', registryId],
		queryFn: () => {
			return fetchRegistryJobPosts({ registryId });
		},
	});
};
