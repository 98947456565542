import styled from 'styled-components';

import { colors } from '../../../utils';

export const StyledCompletedStep = styled.div`
	width: 30px;
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: ${colors.primary.primary};
	border-radius: 50%;

	color: ${colors.white};
`;
