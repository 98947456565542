import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Calendar from '../../../../../corev2/Calendar';
import { Select } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';
import PageHeading from '../../../core/components/PageHeading';
import AddScheduleForm from '../../components/AddScheduleForm';
import CancelShiftForm from '../../components/CancelShiftForm';

import { JobPostProvider } from '../../context/JobPostContext';

import { useAuth } from '../../../core/hooks/useAuth';
import { useJobPost } from '../../hooks/useJobPost';

import { useAddScheduleToJobPost } from '../../api/mutations/useAddScheduleToJobPost';
import { useDeactivateSchedule } from '../../api/mutations/useDeactivateSchedule';
import { useAddScheduleException } from '../../api/mutations/useAddScheduleException';
import { useCancelShiftMutation } from '../../api/mutations/useCancelShiftMutation';

import { Notification } from '../../../../../client/modules/core/lib';
import { notifications } from '../../../../../client/modules/core/constants';

import { formatIncomingShift, generateEvents, calendarEventStyleGenerator } from '../../lib';

import {
	StyledJobSchedulePage,
	StyledContainer,
	StyledCalendarHeader,
	StyledNavContainer,
	StyledHeadingContainer,
	StyledCalendarheading,
	StyledCalendarSelectGroup,
	StyledBlueSelect,
} from './styles';

const JobSchedulePageContent = () => {
	const [serverError, setServerError] = useState('');
	const [events, setEvents] = useState([]);
	const [view, setView] = useState('month');
	const [currentDate, setCurrentDate] = useState(new Date());
	const [isCalendarPopupOpen, setIsCalendarPopupOpen] = useState(false);
	const [isCancelShiftFormOpen, setIsCancelShiftFormOpen] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [editingEvent, setEditingEvent] = useState(null);

	const { authUser } = useAuth();
	const { jobPost, updateJobPost, isLoadingJobPost } = useJobPost();

	const addScheduleToJobPost = useAddScheduleToJobPost();
	const addScheduleException = useAddScheduleException();
	const deactivateSchedule = useDeactivateSchedule();
	const cancelShift = useCancelShiftMutation();

	useEffect(() => {
		if (jobPost) setEvents(generateEvents(jobPost.schedules, jobPost.jobs, currentDate, view));
	}, [jobPost, currentDate, view]);

	const addScheduleToJobPostHandler = async (
		startDate,
		startTime,
		endDate,
		endTime,
		repeatFrequency,
		daysOfWeek
	) => {
		try {
			await addScheduleToJobPost.mutateAsync({
				startDate,
				startTime,
				endDate,
				endTime,
				repeatFrequency,
				daysOfWeek,
				jobPostId: jobPost.id,
				carePlanId: authUser.carePlan.id,
			});

			updateJobPost();
			Notification(notifications.jobPosts.scheduleAdded);
			setIsCalendarPopupOpen(false);
		} catch (error) {
			setServerError(error[0].message);
			console.error('Add schedule to job post failed', error);
			return false;
		}
	};

	const addScheduleExceptionHandler = async (event) => {
		try {
			await addScheduleException.mutateAsync({
				startDate: moment(event.start).format('YYYY-MM-DD'),
				startTime: event.resource.schedule.start_time,
				endDate: moment(event.end).format('YYYY-MM-DD'),
				endTime: event.resource.schedule.end_time,
				scheduleId: event.resource.schedule.id,
				jobPostId: jobPost.id,
				carePlanId: authUser.carePlan.id,
				jobId: null,
				type: 'CLIENT_SCHEDULE_EXCEPTION',
			});

			updateJobPost();
			setIsCalendarPopupOpen(false);
		} catch (error) {
			console.log(error);
			setServerError(error[0].message);
			console.error('Adding client exception failed', error);
		}
	};

	const updateSchedule = async (startTime, endTime, workTimeFrameId) => {
		try {
			await updateWorkTimeFrame.mutateAsync({
				startTime,
				endTime,
				workTimeFrameId,
			});

			updateJobPost();
		} catch (error) {
			setServerError(error[0].message);
			console.error('Update work time frame failed', error);
		}
	};

	const deactivateScheduleHandler = async (event) => {
		try {
			await deactivateSchedule.mutateAsync({
				scheduleId: event.resource.schedule.id,
			});

			updateJobPost();
		} catch (error) {
			console.log(error);
			setServerError(error[0].message);
			console.error('Remove work time frame failed', error);
		}
	};

	const handleNavigateLeft = () => {
		if (view === 'month') {
			setCurrentDate(moment(currentDate).subtract(1, 'month').toDate());
		} else if (view === 'week') {
			setCurrentDate(moment(currentDate).subtract(1, 'week').toDate());
		} else if (view === 'day') {
			setCurrentDate(moment(currentDate).subtract(1, 'day').toDate());
		}
	};

	const handleNavigateRight = () => {
		if (view === 'month') {
			setCurrentDate(moment(currentDate).add(1, 'month').toDate());
		} else if (view === 'week') {
			setCurrentDate(moment(currentDate).add(1, 'week').toDate());
		} else if (view === 'day') {
			setCurrentDate(moment(currentDate).add(1, 'day').toDate());
		}
	};

	const handleViewChange = (event) => setView(event.target.value);

	const openPopupHandler = (date = null) => {
		setIsCalendarPopupOpen(true);
		if (view === 'day' && date) {
			setEditingEvent({ start: date, end: date });
		} else {
			setEditingEvent(null);
		}
	};

	const closePopupHandler = () => {
		setEditMode(false);
		setIsCalendarPopupOpen(false);
	};

	const cancelShiftModalHandler = () => {
		setIsCancelShiftFormOpen(!isCancelShiftFormOpen);
	};

	const cancelShiftHandler = async (shiftId) => {
		try {
			await cancelShift.mutateAsync({ shiftId });

			updateJobPost();
			setIsCancelShiftFormOpen(false);
		} catch (error) {
			console.log(error);
			setServerError(error[0].message);
			console.error('Cancel shift by client failed', error);
		}
	};

	const handleEventClick = (event) => {
		const { type, shift } = event.resource;

		switch (type) {
			case 'pending':
				setIsCancelShiftFormOpen(true);
				setEditingEvent(event);
				break;
			case 'available':
				setIsCalendarPopupOpen(true);
				setEditingEvent(event);
				setEditMode(true);
		}
	};

	const handleUpdateEvent = (startTime, endTime, workTimeFrameId) => {};

	if (isLoadingJobPost) {
		return <>Loading Job Post</>;
	}

	return (
		<StyledJobSchedulePage>
			<PageHeading>Schedule for {authUser.name}</PageHeading>

			{isCalendarPopupOpen && (
				<AddScheduleForm
					editMode={editMode}
					event={editingEvent}
					closePopupHandler={closePopupHandler}
					addScheduleToJobPostHandler={addScheduleToJobPostHandler}
					updateEventHandler={handleUpdateEvent}
					deleteEventHandler={addScheduleExceptionHandler}
					selectedDate={view === 'day' ? currentDate : null}
					serverError={serverError}
				/>
			)}

			{isCancelShiftFormOpen && (
				<CancelShiftForm
					shift={formatIncomingShift(editingEvent.resource.shift)}
					cancelShiftModalHandler={cancelShiftModalHandler}
					cancelShiftHandler={cancelShiftHandler}
				/>
			)}

			<StyledContainer>
				<StyledCalendarHeader>
					<StyledNavContainer>
						<StyledBlueSelect>
							<FontAwesomeIcon
								size='xs'
								icon={faChevronLeft}
								onClick={handleNavigateLeft}
							/>
						</StyledBlueSelect>

						<StyledCalendarheading>
							{view === 'day'
								? moment(currentDate).format('DD MMMM YYYY')
								: moment(currentDate).format('MMMM YYYY')}
						</StyledCalendarheading>

						<StyledBlueSelect>
							<FontAwesomeIcon
								size='xs'
								icon={faChevronRight}
								onClick={handleNavigateRight}
							/>
						</StyledBlueSelect>
					</StyledNavContainer>

					<StyledHeadingContainer>
						<StyledCalendarSelectGroup>
							<Select value={view} onChange={handleViewChange}>
								<option value='month'>Month</option>
								<option value='week'>Week</option>
								<option value='day'>Day</option>
								<option value='agenda'>Agenda</option>
							</Select>
						</StyledCalendarSelectGroup>

						<PrimaryButton size='small' onClick={() => openPopupHandler(currentDate)}>
							Add Schedule
						</PrimaryButton>
					</StyledHeadingContainer>
				</StyledCalendarHeader>

				<Calendar
					events={events}
					currentDate={currentDate}
					view={view}
					onNavigate={setCurrentDate}
					onView={setView}
					onSelectEvent={handleEventClick}
					eventStyleGenerator={calendarEventStyleGenerator}
				/>
			</StyledContainer>
		</StyledJobSchedulePage>
	);
};

const JobSchedulePage = () => {
	return (
		<JobPostProvider>
			<JobSchedulePageContent />
		</JobPostProvider>
	);
};

export default JobSchedulePage;
