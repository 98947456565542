import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

import Colors from 'colors';
import styled from 'styled-components';
import { ColumnFlex, RowFlex, DropShadow } from 'basicStyles';

import Title from 'AppComp/Title';
import Icon from 'AppComp/Icon';
import Text from 'AppComp/Text';
import { device } from 'AppComp/styled/StyledMediaQuery';
import { FormatTextInput, StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';

import {
	CusotmFieldSet,
	CustomFormButton,
	TextInputCon,
	Container,
	CustomAvatar,
} from '../ProfileInfo/ProfileInfoViews';

import { PHDColumn, PHWhiteCon } from '../ProviderHomeDashboard';

const CARE_TYPE = [
	{
		value: 'hourly',
		label: 'Hourly',
	},
	{
		value: 'live_in',
		label: 'Live In',
	},
];

const SettingPageConChild = styled.div`
	border-radius: 24px;
	flex: 1;
	background-color: white;
	height: min-content;
	@media ${device.mobileS} {
	}
`;

const RateHanlder = (props) => {
	const [rateEditStatus, updateRateEditStatus] = useState(false);
	const {
		updateProviderRate,
		provider: { care_type, hourly_rate, live_in_rate },
	} = props;

	const handleSubmit = (values) => {
		const _params = {
			rate_type: values.careType,
			live_in_rate: 0,
			hourly_rate: 0,
		};

		// live in
		const live_in_hourly_rate = values.live_in_rate.replace(/\D+/g, '');
		_params.live_in_rate = parseFloat(live_in_hourly_rate) * 100;

		// hourly rate
		const hourly_rate = values.hourly_rate.replace(/\$/g, '');
		_params.hourly_rate = parseFloat(hourly_rate) * 100;

		updateProviderRate(_params);
		updateRateEditStatus(false);
	};

	const initValues = () => {
		console.log('care_type', care_type);
		const carePlanType = CARE_TYPE.find((d, i) => {
			return d.value == care_type;
		});
		let rate = 0;

		const _carePlanType = carePlanType || CARE_TYPE[0];
		const initValues = {
			careType: _carePlanType.value,
			hourly_rate: `$${(hourly_rate || 0) / 100}`,
			live_in_rate: `$${(live_in_rate || 0) / 100}`,
		};

		return initValues;
	};
	return (
		<SettingPageConChild
			style={{
				flex: 0,
				width: '100%',
				height: 'auto',
			}}
		>
			<Formik onSubmit={handleSubmit} enableReinitialize initialValues={initValues()}>
				{(formProps) => (
					<Form noValidate>
						<CusotmFieldSet disabled={!rateEditStatus} disabledStatus={!rateEditStatus}>
							<PHWhiteCon style={{ maWidth: '600px' }}>
								<RowFlex padding='12px 16px 32px 16px' spaceBetween columnMobile>
									<Text darkGray bold largeText>
										Adjust your minimum acceptable rate
									</Text>
									{rateEditStatus ? (
										<CustomFormButton
											backgroundColor={Colors.teal}
											type='submit'
											valid={formProps.isValid}
										>
											Update Rate
										</CustomFormButton>
									) : (
										<RowFlex alignCenter>
											<Text
												color={
													rateEditStatus
														? Colors.greenLightTeal
														: Colors.gray
												}
												onClick={() =>
													updateRateEditStatus(!rateEditStatus)
												}
												margin='0 8px 0 0'
												pointer
											>
												Edit Rate Info
											</Text>
											<CustomAvatar
												height='30px'
												width='30px'
												onClick={() =>
													updateRateEditStatus(!rateEditStatus)
												}
												color={
													rateEditStatus
														? Colors.greenLightTeal
														: Colors.gray
												}
											>
												<Icon icon={faPencilAlt} size='sm' />
											</CustomAvatar>
										</RowFlex>
									)}
								</RowFlex>
								<ColumnFlex
									style={{
										borderRadius: '16px',

										padding: '16px',
									}}
									margin='10px 0 0 0'
								>
									<TextInputCon>
										<Field
											component={StyledTextSelectInputV2}
											name='careType'
											label='Care Type Preference*'
											options={CARE_TYPE}
										/>

										<Field
											component={FormatTextInput}
											name='hourly_rate'
											label='Hourly Rate'
											type='currency'
											bigForm={formProps}
										/>

										<Field
											component={FormatTextInput}
											name='live_in_rate'
											label='Live In Rate'
											type='currency'
											bigForm={formProps}
										/>
									</TextInputCon>
								</ColumnFlex>
							</PHWhiteCon>
						</CusotmFieldSet>
					</Form>
				)}
			</Formik>
		</SettingPageConChild>
	);
};

export default RateHanlder;
