import React, { useState, useEffect } from 'react';
import Text from 'AppComp/Text';
import { ColumnFlex } from 'basicStyles';
import Button from 'AppComp/Button';
import Title from 'AppComp/Title';
import Colors from 'colors';
import { faPencilAlt, faFileUpload } from '@fortawesome/free-solid-svg-icons';

import { Formik, Form, Field } from 'formik';
import { isMobile } from 'react-device-detect';
import Dropzone from 'react-dropzone';
import Icon from 'AppComp/Icon';

import {
	MARGIN_TITLE,
	FormItemsLength,
	OBFormButtonText,
	OBBasicCon,
	OBSubText,
} from 'AppComp/provider/POnboard/OnboardViews';

import StyledTextInput, {
	StyledTextSelectInputV2,
	StyledTextSelectInput,
	TextArea,
} from 'AppComp/styled/StyledTextInput';

import PageLoaderView from 'AppComp/PageLoaderView';

import {
	CusotmFieldSet,
	TextInputCon,
	Container,
	CustomAvatar,
} from '../../ProfileInfo/ProfileInfoViews';

import {
	CardSection,
	CustomFormButton,
} from 'AppComp/patient/PatientCardCollection/PatientCardCollectionViews';

import useWindowSize from 'scripts/useWindowSize';

const FORM_ITEM_LENGTH = '320px';
const ProfileInfo = ({ updateProviderProfileInfo, setIsLoading, isLoading }) => {
	const [newImage, setNewImage] = useState(false);
	const [newImageUrl, setNewImageUrl] = useState('');
	const [submitted, setSubmitted] = useState(false);

	const { width } = useWindowSize();

	const handleSubmit = (values, form) => {
		console.log('Personal - handleSubmit', values);
		setIsLoading(true);

		const updateProviderInfoParams = {
			professional_statement: values.professional_statement,
		};

		if (newImageUrl) {
			updateProviderInfoParams.image_url = newImageUrl;
		}
		updateProviderProfileInfo(updateProviderInfoParams);
	};

	const handleValidation = (values) => {
		const errors = {};
		const err = 'Required';

		if (!values.professional_statement) {
			errors.professional_statement = err;
		}

		if (values.professional_statement && values.professional_statement.length > 500) {
			errors.professional_statement = 'Exceeded character limit (500)';
		}

		return errors;
	};

	const handleDroppedFile = (acceptedFiles) => {
		var imageTypes = ['image/png', 'image/gif', 'image/bmp', 'image/jpg', 'image/jpeg'];

		var fileType = acceptedFiles[0].type;

		if (imageTypes.includes(fileType)) {
			const identifyPoolId = 'us-east-2:8aa03690-fa6e-4aab-8429-22b9b6270602';
			const bucketRegion = 'us-east-2';
			const bucketName = 'desir';

			// this.setState({
			// 	loading: true,
			// });

			AWS.config.update({
				region: bucketRegion,
				credentials: new AWS.CognitoIdentityCredentials({
					IdentityPoolId: identifyPoolId,
				}),
			});

			const s3 = new AWS.S3({
				apiVersion: '2012-10-17',
				params: { Bucket: bucketName },
			});

			const key = acceptedFiles[0].name;

			s3.upload(
				{
					Key: key,
					Body: acceptedFiles[0],
					ACL: 'public-read',
				},
				handleUploadCallback
			);
		} else {
			window.alert('dropped file is not an image');
		}
	};

	const handleUploadCallback = (err, data) => {
		//once the call back is called we either should show an error or run handleSignatureUpdate to pass the info back to the parent and handle removing the signature pad

		if (err) {
			console.log('something went wrong', err);
		} else {
			console.log('data', data);

			const { Location, Key } = data;
			console.log('Location', Location);
			setNewImage(true);
			setNewImageUrl(Location);
		}
	};

	return (
		<ColumnFlex style={{}} center>
			<Title color={'black'} bold subtitle>
				Personalize Your Profile
			</Title>
			<ColumnFlex padding='0 0 12px 0' margin='20px auto 0 auto' center gap='12px'>
				<Text
					style={{
						textAlign: 'center',
					}}
				>
					Enter a personal statement that prospective clients can view to learn more about
					you
				</Text>
				<Formik
					onSubmit={handleSubmit}
					autoComplete={'off'}
					validate={handleValidation}
					render={(formProps) => {
						console.log(formProps);
						return (
							<Form noValidate>
								<ColumnFlex alignCenter justifyCenter>
									<Dropzone
										onDrop={(acceptedFiles) => handleDroppedFile(acceptedFiles)}
									>
										{({ getRootProps, getInputProps }) => (
											<CustomAvatar
												height='120px'
												width='120px'
												margin='20px 0px 0 0'
												border={Colors.greenLightTeal}
												image_url={
													true ? (newImage ? newImageUrl : '') : image_url
												}
												{...getRootProps()}
											>
												<input {...getInputProps()} />
												{!newImage && (
													<Icon icon={faFileUpload} size='2x' />
												)}
											</CustomAvatar>
										)}
									</Dropzone>
									<FormItemsLength
										margin='20px 0 0 0'
										width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
									>
										<div
											style={{
												marginTop: '10px',
												textAlign: 'right',
												color: 'gray',
												fontSize: '12px',
											}}
										>
											{formProps.values.professional_statement
												? `${formProps.values.professional_statement.length}/500`
												: '0/500'}
										</div>

										<Field
											component={TextArea}
											name='professional_statement'
											label='Your Statement'
											type='text'
											rows='10'
											maxLength='500'
										/>
									</FormItemsLength>

									{isLoading ? (
										<PageLoaderView errorMessage='Something went wrong plz contact dev monkeys' />
									) : (
										<CustomFormButton
											style={{
												borderRadius: '24px',
											}}
											valid={formProps.isValid}
											type='submit'
											margin='32px 8px 0 0px'
											backgroundColor={Colors.primary.primary}
											width={'240px'}
											height={isMobile ? '40px' : '60px'}
										>
											<Text
												style={{
													textAlign: 'center',
												}}
												bold
												color={
													formProps.isValid
														? 'white'
														: Colors.primary.primary
												}
												midLarge
											>
												Next
											</Text>
										</CustomFormButton>
									)}
								</ColumnFlex>
							</Form>
						);
					}}
				/>
			</ColumnFlex>
		</ColumnFlex>
	);
};

export default ProfileInfo;
