/* 
  this should be close to the current functionaly of CarePlanParent 
  - with a few edge cases and fixes, CarePlanParnt should be refactor after this since a lot of the func will be ported here
  1) user lands on the page for the first time
  1.1) we create a new CarePlan Model for them (label it as "guest CarePlan") and store it to local storage
  2) we keep track of the progress they've moved through in fillwing out the carePlan
  3) the user will go through 2 main sections 
  3.1) user fills in CareProfile info 
    - this has 4 sub sections (profile, location, medical) or 3 maybe we'll see
  3.2) user fills in the CareNeeds info
   - this is one long form with lots of things
  4) User moves towards 
*/

import React, { useState, Fragment, useEffect } from 'react';
import { Hearts } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG } from 'constants';
import Colors from 'colors';

import api from 'scripts/api';
import { CARE_NEEDS_MODEL, CAREPLAN_MODEL_V2 } from 'reducers/graphqlModelTypes';

import { STEP_PROGRESS_INIT } from './ClientOnboardParent';
import CarePlanWelcome from './CarePlanWelcome';
import OnboardSubNav from './OnboardSubnav';

import CareNeedsPage from '../CareNeedsPage';

import ClientInfo from '../CarePlan/ClientInfo';
import CarePersonal from '../CarePlan/CarePersonal';
import Logistics from '../CarePlan/Logistics';

import Text from 'AppComp/Text';
export const CARE_PLAN_ACTIONS = {
	WELCOME: 'WELCOME',
	CLIENT_INFO: 'CLIENT_INFO',
	CARE_PERSONAL: 'CARE_PERSONAL',
	LOGISTICS: 'LOGISTICS',
	CARE_NEEDS: 'CARE_NEEDS',
};

import { FormButtonStyled } from '../CarePlan/CarePlanviews';

// these ids represent the fields of data required to be filled out in the CarePlanSet by sections that its being displayed
const CARE_PLAN_SET_IDS = {
	CLIENT_INFO: [60, 61, 62, 63, 64, 65, 66],
	CARE_PERSONAL: [68, 70, 71, 72],
	LOGISTICS: [76, 78, 79, 80],
};

const CARE_PLAN_ONBOARD_SUB_NAV = [
	{
		id: CARE_PLAN_ACTIONS['WELCOME'],
		title: 'Welcome',
		completed: false,
	},
	{
		id: CARE_PLAN_ACTIONS['CLIENT_INFO'],
		title: 'Client Info',
		completed: false,
	},
	{
		id: CARE_PLAN_ACTIONS['CARE_PERSONAL'],
		title: 'Care Personal',
		completed: false,
	},
	{
		id: CARE_PLAN_ACTIONS['LOGISTICS'],
		title: 'Logistics',
		completed: false,
	},
	{
		id: CARE_PLAN_ACTIONS['CARE_NEEDS'],
		title: 'Care Needs',
		completed: false,
	},
];

const CarePlanOnboard = (props) => {
	const {
		patient,
		stepsProgress,
		setStepsProgress,
		carePlan,
		handleUpdateSectionProgress,
		receivePatientCarePlan,
		fetchingCarePlan,
		setOnboardStep,
	} = props;

	const [carePlanAction, setCarePlanAction] = useState(CARE_PLAN_ACTIONS.WELCOME);

	const [shouldWaitForCarePlan, setShouldWaitForCarePlan] = useState(false);

	const [carePlanSet, setCarePlanSet] = useState(null);
	const [carePlanNeeds, setCarePlanNeeds] = useState(null);

	const [loading, setLoading] = useState(false);

	// sub nav state
	const [carePlanSubNav, setCarePlanSubNav] = useState(CARE_PLAN_ONBOARD_SUB_NAV);

	const { status, attempts } = fetchingCarePlan;
	useEffect(() => {
		if (carePlan) {
			// no carePlan lets' check if we're still loading
			calculateCarePlanOnboardingStep();
		}

		// check if carePlan is not empty in the patient key from props
		// check if there's a carePlan in the patient obj

		parseSets();
	}, [patient, carePlan]);

	useEffect(() => {
		calculateProgress();
	}, [carePlanAction]);

	useEffect(() => {
		// check if carePlan is in localStorage
		const carePlanId = localStorage.getItem('carePlanId');
		if (carePlanId) {
			setShouldWaitForCarePlan(true);
		}
	}, []);

	// update sub nav bar stats
	useEffect(() => {
		const progress = stepsProgress.CARE_PLAN;
		// based on the progress updated the sub nav state to completed for the right steps

		// based on the progress i can determine that all sub nav items before a certain index are completed

		// if 20 has been completed then i know only the first index is completed
		// if 40 has been completed then i know the first two indexes are completed
		// if 60 has been completed then i know the first three indexes are completed

		// loop through the sub nav and update the completed field based on the progress
		const updatedSubNav = carePlanSubNav.map((item, index) => {
			if (progress > index * 0.2) {
				return {
					...item,
					completed: true,
				};
			}
			return item;
		});

		// update state
		setCarePlanSubNav(updatedSubNav);
	}, [stepsProgress]);

	const parseSets = () => {
		if (carePlan == null) {
			return;
		}
		const carePlanSetData = carePlan.carePlanSet.setData;

		const carePlanNeedsData = carePlan.careNeedsSet.setData;

		const _carePlanSetData = carePlanSetData.map((item) => {
			return {
				...item,
				value: item.value
					? {
							...item.value,
							trait_value: JSON.parse(item.value.trait_value),
					  }
					: null,
			};
		});

		const _carePlanNeedsData = carePlanSetData.map((item) => {
			return {
				...item,
				value: item.value
					? {
							...item.value,
							trait_value: JSON.parse(item.value.trait_value),
					  }
					: null,
			};
		});

		setCarePlanNeeds({
			...carePlan.careNeedsSet,
			setData: _carePlanNeedsData,
		});
		setCarePlanSet({
			...carePlan.carePlanSet,
			setData: _carePlanSetData,
		});
	};
	const createCarePlan = async (relationToPatient) => {
		/* 
			creates a care plan with the right items
			- this will be called by welcome page 

			- create a new carePlan and updates the redux state with the new carePlan
		*/

		try {
			setLoading(true);
			const createCarePlanRes = await api.graph({
				query: `mutation {
          createCarePlanV2 (
            relation_to_client: "${relationToPatient}"
           	user_id: ${patient.id}
          ) {

            ${CAREPLAN_MODEL_V2}
						carePlanSet {
							${CARE_NEEDS_MODEL}
						}
						careNeedsSet {
							${CARE_NEEDS_MODEL}
						}
          }
        }`,
			});

			setLoading(false);
			if (createCarePlanRes.createCarePlanV2) {
				// update redux for care plan
				receivePatientCarePlan(createCarePlanRes.createCarePlanV2);
				// save carePlanId to local storage
				localStorage.setItem('carePlanId', createCarePlanRes.createCarePlanV2.id);

				// set the react state to the CLIENT_INFO step
				setCarePlanAction(CARE_PLAN_ACTIONS.CLIENT_INFO);
			}
		} catch (err) {
			setLoading(false);
			toast.error('Care Plan could not be created', TOAST_ERROR_CONFIG);
			console.log('createCarePlan -> err', err);
		}
	};

	const completeCarePlan = async () => {
		const updateParams = {
			completed_care_plan: true,
		};
		try {
			setLoading(true);
			const updateCarePlanRes = await api.graph({
				query: `mutation {
            updateCarePlanV2 (
              care_plan_id: ${carePlan.id},
              care_plan_input: ${api.graphStringify(updateParams)},
            ) {
              ${CAREPLAN_MODEL_V2}
							carePlanSet {
								${CARE_NEEDS_MODEL}
							}
							careNeedsSet {
								${CARE_NEEDS_MODEL}
							}
            }
          }`,
			});

			setLoading(false);
			if (updateCarePlanRes.updateCarePlanV2) {
				toast.success('Care Plan Updated', TOAST_SUCCESS_CONFIG);

				receivePatientCarePlan(updateCarePlanRes.updateCarePlanV2);
				setOnboardStep(3);
			}
		} catch (err) {
			setLoading(false);
			toast.error('Care Plan could not be updated', TOAST_ERROR_CONFIG);

			console.log(err);
		}
	};

	// update the carePlanSet with the new values
	const updateSetTraits = async (traits) => {
		console.log('Mutating carePlanSetId', traits);

		const carePlanSetId = carePlan.carePlanSet.id;

		// remove any null values from traits

		const filteredTraits = traits.filter((trait) => trait.value !== null);
		try {
			setLoading(true);
			const response = await api.graph({
				query: `mutation {
					updateSetTraits (
						set_id: ${carePlanSetId},
						traits: ${api.graphStringify(filteredTraits)},
					) {
						id
						value {
							trait_value
						}
					}
				}`,
			});

			console.log('Mutation response', response.updateSetTraits);

			if (response.updateSetTraits) {
				toast.success('Care Profile Updated', TOAST_SUCCESS_CONFIG);

				// update the redux state of the carePlan so everything is in sync

				const newCarePlanSet = response.updateSetTraits;

				const updatedCarePlan = {
					...carePlan,
					carePlanSet: {
						...carePlan.carePlanSet,
						setData: newCarePlanSet,
					},
				};

				receivePatientCarePlan(updatedCarePlan);

				if (carePlan.completed_care_plan) {
					// move to the next screen

					setCarePlanAction(CARE_PLAN_ACTIONS.CARE_PERSONAL);
				}
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toast.error('Care Profile could not be updated', TOAST_ERROR_CONFIG);
			console.log(err);
		}
	};

	const calculateProgress = () => {
		// we're going to copy logic from calculateCarePlanOnboardingStep for this since we can't rely on the current step to determine the progress
		// since we can move between steps
		let progress = 0;

		if (carePlan == null) {
			handleUpdateSectionProgress(progress, 'CARE_PLAN');
			return;
		}

		if (carePlan.completed_care_plan) {
			handleUpdateSectionProgress(1, 'CARE_PLAN');
			return;
		}

		const setCheck = carePlan.carePlanSet.setData;

		const lookup = setCheck.reduce((acc, item) => {
			acc[item.id] = {
				trait_id: item.id,
				type: item.trait_type,
				value: item.value ? item.value.trait_value : null,
			};
			return acc;
		}, {});

		// loop through CARE_PLAN_SET_IDS.CLIENT_INFO and check if the fields are filled out
		const clientInfoCheck = CARE_PLAN_SET_IDS.CLIENT_INFO.every((id) => {
			return lookup[id].value !== null;
		});

		console.log('clientInfoCheck', clientInfoCheck);
		if (!clientInfoCheck) {
			handleUpdateSectionProgress(0.2, 'CARE_PLAN');
			return;
		}

		const carePersonalCheck = CARE_PLAN_SET_IDS.CARE_PERSONAL.every((id) => {
			return lookup[id].value !== null;
		});

		console.log('carePersonalCheck', carePersonalCheck);
		if (!carePersonalCheck) {
			handleUpdateSectionProgress(0.4, 'CARE_PLAN');
			return;
		}

		// next we verify the LOGISTICS section
		// loop through CARE_PLAN_SET_IDS.LOGISTICS and check if the fields are filled out
		const logisticsCheck = CARE_PLAN_SET_IDS.LOGISTICS.every((id) => {
			return lookup[id].value !== null;
		});

		if (!logisticsCheck) {
			handleUpdateSectionProgress(0.6, 'CARE_PLAN');

			return;
		}

		// check if the careNeeds section has at least one item
		const careNeedsCheck = carePlan.careNeedsSet.setData.some((item) => item.value !== null);

		if (!careNeedsCheck) {
			handleUpdateSectionProgress(0.8, 'CARE_PLAN');
			return;
		}
	};

	// at a high level the parnet should handle what to show based on a completion level
	// that means we only need to know the steps up to the last step since the parent will handle automatically moving forward when the last step was completed
	const calculateCarePlanOnboardingStep = () => {
		// we assume this will have the right fields for the values
		const tempCarePlanSet = carePlan.carePlanSet.setData;

		// rn the logic is changign between pr so we'll play it safe

		// this should be checked based on the carePlan set

		// check if certain id have values in the CarePlanSet Data

		// first we check if the user has a carePlan
		if (carePlan == null) {
			return;
		}

		const lookup = tempCarePlanSet.reduce((acc, item) => {
			acc[item.id] = {
				trait_id: item.id,
				type: item.trait_type,
				value: item.value ? item.value.trait_value : null,
			};
			return acc;
		}, {});

		console.log('lookup', lookup);

		// loop through CARE_PLAN_SET_IDS.CLIENT_INFO and check if the fields are filled out
		const clientInfoCheck = CARE_PLAN_SET_IDS.CLIENT_INFO.every((id) => {
			return lookup[id].value !== null;
		});

		console.log('clientInfoCheck', clientInfoCheck);
		if (!clientInfoCheck) {
			setCarePlanAction(CARE_PLAN_ACTIONS.CLIENT_INFO);
			return;
		}

		const carePersonalCheck = CARE_PLAN_SET_IDS.CARE_PERSONAL.every((id) => {
			return lookup[id].value !== null;
		});

		console.log('carePersonalCheck', carePersonalCheck);
		if (!carePersonalCheck) {
			setCarePlanAction(CARE_PLAN_ACTIONS.CARE_PERSONAL);
			return;
		}

		// next we verify the LOGISTICS section
		// loop through CARE_PLAN_SET_IDS.LOGISTICS and check if the fields are filled out
		const logisticsCheck = CARE_PLAN_SET_IDS.LOGISTICS.every((id) => {
			return lookup[id].value !== null;
		});

		if (!logisticsCheck) {
			setCarePlanAction(CARE_PLAN_ACTIONS.LOGISTICS);
			return;
		}

		// if we make it to this point that means we need ot show the careNeeds page
		setCarePlanAction(CARE_PLAN_ACTIONS.CARE_NEEDS);
		// loop through CARE_PLAN_SET_IDS.CARE_PERSONAL and check if the fields are filled out

		// next we verify the CARE_PERSONAL section
	};

	const renderPages = () => {
		console.log('carePlanAction', carePlanAction);
		if (carePlanAction === CARE_PLAN_ACTIONS.WELCOME) {
			// show the welcome page
			return (
				<CarePlanWelcome
					createCarePlan={createCarePlan}
					loading={loading}
					carePlan={carePlan}
				/>
			);
		} else if (carePlanAction === CARE_PLAN_ACTIONS.CLIENT_INFO) {
			// show the client info page
			return (
				<>
					<ClientInfo
						carePlanSet={carePlanSet}
						updateSetTraits={updateSetTraits}
						setCarePlanAction={setCarePlanAction}
						carePlan={carePlan}
						loading={loading}
					/>
				</>
			);
		} else if (carePlanAction === CARE_PLAN_ACTIONS.CARE_PERSONAL) {
			// show the care personal page
			return (
				<>
					<CarePersonal
						carePlanSet={carePlanSet}
						updateSetTraits={updateSetTraits}
						setCarePlanAction={setCarePlanAction}
						carePlan={carePlan}
						loading={loading}
					/>
				</>
			);
		} else if (carePlanAction === CARE_PLAN_ACTIONS.LOGISTICS) {
			// show the logistics page
			return (
				<>
					<Logistics
						carePlanSet={carePlanSet}
						updateSetTraits={updateSetTraits}
						setCarePlanAction={setCarePlanAction}
						carePlan={carePlan}
						loading={loading}
					/>
				</>
			);
		} else if (carePlanAction === CARE_PLAN_ACTIONS.CARE_NEEDS) {
			// show the careneeds pag3
			return (
				<>
					<CareNeedsPage />
					<FormButtonStyled
						backgroundColor={Colors.primary.primary}
						width={'300px'}
						height={'70px'}
						valid={true}
						onClick={completeCarePlan}
						borderRadius='32px'
						type='submit'
					>
						<Text
							fontWeight='500'
							color={true ? 'white' : Colors.primary.primary}
							largeText
						>
							Continue
						</Text>
					</FormButtonStyled>
				</>
			);
		} else {
			return 'Something went wrong';
		}
	};

	const shouldShowRender = shouldWaitForCarePlan && status && attempts >= 1;

	console.log('carePlanAction', carePlanAction);
	console.log('shouldShowRender', shouldShowRender);

	const handleSubNavTileClick = (id) => {
		// okay so we can only move to certain steps based on what has already been filled out
		// we can only move forward if certain information is filled out too
		// what we'll compare to what aread we're is at the progress tracker as source of truth

		// so if we're .2 in we can only move to the first step
		// if we're .4 in we can only move to the first two steps
		// if we're .6 in we can only move to the first three steps
		// if we're .8 in we can only move to the first four steps
		// okay i think i get the point

		const throwToastErrForStep = () =>
			toast.error(
				'You need to complete the current steps before moving on',
				TOAST_ERROR_CONFIG
			);

		// based on our progress check if we can go to the id that we're trying to go to
		switch (id) {
			// we can always go back to the welcome page
			case CARE_PLAN_ACTIONS.WELCOME:
				setCarePlanAction(CARE_PLAN_ACTIONS.WELCOME);
				break;
			case CARE_PLAN_ACTIONS.CLIENT_INFO:
				if (stepsProgress.CARE_PLAN >= 0.4) {
					setCarePlanAction(CARE_PLAN_ACTIONS.CLIENT_INFO);
				} else throwToastErrForStep();

				break;
			case CARE_PLAN_ACTIONS.CARE_PERSONAL:
				if (stepsProgress.CARE_PLAN >= 0.6) {
					setCarePlanAction(CARE_PLAN_ACTIONS.CARE_PERSONAL);
				} else throwToastErrForStep();
				break;
			case CARE_PLAN_ACTIONS.LOGISTICS:
				if (stepsProgress.CARE_PLAN >= 0.8) {
					setCarePlanAction(CARE_PLAN_ACTIONS.LOGISTICS);
				} else throwToastErrForStep();
				break;
			case CARE_PLAN_ACTIONS.CARE_NEEDS:
				if (stepsProgress.CARE_PLAN >= 0.8) {
					setCarePlanAction(CARE_PLAN_ACTIONS.CARE_NEEDS);
				} else throwToastErrForStep();
				break;
			default:
		}
	};
	if (shouldShowRender) {
		return (
			<>
				<Hearts />
			</>
		);
	}
	return (
		<>
			<OnboardSubNav
				currentStep={carePlanAction}
				steps={carePlanSubNav}
				handleSubNavTileClick={handleSubNavTileClick}
				showArrowsBetween={true}
				descriptionText='Please Complete:'
			/>
			<>{renderPages()}</>
		</>
	);
};
1;
export default CarePlanOnboard;
