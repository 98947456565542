import styled from 'styled-components';

export const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
`;

export const StyledWrapper = styled.div`
	margin: 1rem auto;

	display: flex;
	flex-direction: column;
	min-width: 500px;
	max-width: 500px;
	align-items: center;
`;

export const StyledJobPostsWrapper = styled.div`
	margin-top: 1rem;
`;
