import React, { useEffect, useState } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Colors from 'colors';
import styled from 'styled-components';
import Select from 'react-select';

import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';
import { device } from 'AppComp/styled/StyledMediaQuery';
import Button from 'AppComp/Button';
import Text from 'AppComp/Text';
import Title from 'AppComp/Title';
import SetTable from 'AppComp/SetTable';
import Icon from 'AppComp/Icon';

import api from 'scripts/api';

import { ParentContainer } from 'AppComp/admin/ProviderSearch/ProviderSearchHandler';
import { PHWhiteCon } from 'AppComp/provider/ProviderHomeDashboard';
import { DOCUMENT_MODEL } from '../../../reducers/graphqlModelTypes';

import {
	faUserNurse,
	faUser,
	faChevronRight,
	faExclamationTriangle,
	faCheck,
	faTimes,
	faChevronUp,
	faChevronDown,
} from '@fortawesome/free-solid-svg-icons';

export const PHDColumn = styled(ColumnFlex)`
	padding: 30px 4px;
	border-radius: 12px;

	@media ${device.mobileS} {
		padding: 30px 16px;
	}

	@media ${device.laptop} {
		padding: 30px 32px 32px 52px;
	}
`;

const ColumnConEnd = styled.div`
	flex: ${({ flex }) => flex || '1'};
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
`;

const SwitchTabsButton = ({ selectedUsers, handleUserTypeSelect }) => {
	return (
		<ColumnFlex padding='10px 0' alignCenter gap='16px'>
			<ColumnFlex>
				<Button
					style={{
						borderRadius: '16px 16px 0 0',
						boxShadow: 'none',
						border: `2px solid ${
							selectedUsers.includes('client') ? 'white' : Colors.emerald
						}`,
					}}
					margin='0 0 0 0 '
					backgroundColor={selectedUsers.includes('client') ? Colors.emerald : 'white'}
					width={'200px'}
					height={'60px'}
					onClick={() => handleUserTypeSelect('client')}
				>
					<Text
						style={{
							textAlign: 'center',
						}}
						bold
						color={selectedUsers.includes('client') ? 'white' : Colors.emerald}
						midLarge
					>
						Client
					</Text>
				</Button>

				<Button
					style={{
						borderRadius: '0 0 16px 16px',
						boxShadow: 'none',
						border: `2px solid ${
							selectedUsers.includes('carePro') ? 'white' : Colors.emerald
						}`,
					}}
					margin='0 0 0 0 '
					backgroundColor={selectedUsers.includes('carePro') ? Colors.emerald : 'white'}
					width={'200px'}
					height={'60px'}
					onClick={() => handleUserTypeSelect('carePro')}
				>
					<Text
						style={{
							textAlign: 'center',
						}}
						bold
						color={selectedUsers.includes('carePro') ? 'white' : Colors.emerald}
						midLarge
					>
						CarePro
					</Text>
				</Button>
			</ColumnFlex>
		</ColumnFlex>
	);
};

const ManageDocumentsHandler = (props) => {
	const [documents, setDocuments] = useState([]);

	const [statusFilter, setStatusFilter] = useState(['pending', 'approved', 'rejected']);

	const [selectedUsers, setSelectedUsers] = useState(['client', 'carePro']);

	const [documentFilter, setDocumentFilter] = useState([]);

	const [sortOrder, setSortOrder] = useState('ascending', 'descending');

	const [expirationDateSortOrder, setExpirationDateSortOrder] = useState(
		'ascending',
		'descending'
	);
	const [uploadDateFilter, setUploadDateFilter] = useState(null); // Default state for upload date filter

	const [expirationDateFilter, setExpirationDateFilter] = useState(null);
	// Default state for expiration date filter

	const [expiredFilterEnabled, setExpiredFilterEnabled] = useState(false); // State for expired filter

	const [uploadDateFilterEnabled, setUploadDateFilterEnabled] = useState(true); // State to track if upload date filter is enabled

	const [expirationDateFilterEnabled, setExpirationDateFilterEnabled] = useState(true); // State to track if expiration date filter is enabled

	// fetch all the documents
	useEffect(() => {
		handleFetchDocuments();
	}, []);

	const handleChange = (status) => {
		const val = status;
		console.log('val', val);
		// check if val already exists in statusFilter
		if (statusFilter.includes(val)) {
			console.log('remove it');
			// remove it
			setStatusFilter(statusFilter.filter((s) => s !== val));
		} else {
			console.log('add it');
			// add it
			setStatusFilter([...statusFilter, val]);
		}
	};

	const handleUploadDateFilterClick = () => {
		const newSortOrder = sortOrder === 'ascending' ? 'descending' : 'ascending';
		setSortOrder(newSortOrder);
		// Sort documents based on upload date
		const sortedDocuments = sortDocuments(documents, 'uploaded_date', newSortOrder);
		setDocuments(sortedDocuments);
	};
	const handleExpirationDateFilterClick = () => {
		const newSortOrder = expirationDateSortOrder === 'ascending' ? 'descending' : 'ascending';
		setExpirationDateSortOrder(newSortOrder);
		// Sort documents based on expiration date
		const sortedDocuments = sortDocuments(documents, 'expiration_date', newSortOrder);
		setDocuments(sortedDocuments);
	};

	const handleToggleUploadDateFilter = () => {
		setUploadDateFilterEnabled(!uploadDateFilterEnabled);
	};

	const handleToggleExpirationDateFilter = () => {
		setExpirationDateFilterEnabled(!expirationDateFilterEnabled);
	};
	const handleExpiredFilterClick = () => {
		setExpiredFilterEnabled((prev) => !prev);

		const filteredDocs = filterExpiredDocuments(documents, !expiredFilterEnabled);
		setDocuments(filteredDocs);
	};

	const filterExpiredDocuments = (docs, filterEnabled) => {
		if (filterEnabled) {
			const currentDate = new Date();
			return docs.filter(
				(doc) => !doc.expiration_date || new Date(doc.expiration_date) >= currentDate
			);
		} else {
			console.log('Expired filter not enabled. Returning all documents.');
			return docs;
		}
	};

	const handleFetchDocuments = async () => {
		try {
			const docsRes = await api.graph({
				query: `{
					fetchCareAdvisorAgencyDocuments {
						${DOCUMENT_MODEL}
					}
				}`,
			});
			console.log('docsRes', docsRes);
			if (docsRes.fetchCareAdvisorAgencyDocuments)
				setDocuments(docsRes.fetchCareAdvisorAgencyDocuments);
			const sortedDocuments = sortDocuments(docsRes.fetchCareAdvisorAgencyDocuments);
			setDocuments(sortedDocuments);
			let renderedDocs = filterExpiredDocuments(documents, expirationDateFilterEnabled);
		} catch (err) {
			console.log(err);
		}
	};

	const handleResetFilters = () => {
		// Reset all filters and set documents to the initial state
		setSortOrder('ascending');
		setExpirationDateSortOrder('ascending');
		setUploadDateFilter(null);
		setExpirationDateFilter(null);
		setDocuments([]);
		handleFetchDocuments(); // Fetch documents again to reset the list
	};

	const renderIcon = (status) => {
		if (status === 'pending') {
			return <Icon icon={faExclamationTriangle} color={Colors.yellow} size='lg' />;
		} else if (status === 'approved') {
			return <Icon icon={faCheck} color={Colors.emerald} size='lg' />;
		} else if (status === 'rejected') {
			return <Icon icon={faTimes} color={Colors.red} size='lg' />;
		}
	};

	const sortDocuments = (documents, sortBy, sortOrder) => {
		return documents.sort((a, b) => {
			const dateA = new Date(a[sortBy]);
			const dateB = new Date(b[sortBy]);
			return sortOrder === 'ascending' ? dateA - dateB : dateB - dateA;
		});
	};

	const renderDateFilter = () => {
		return (
			<ColumnFlex padding={'0px 0px 0px 20px'}>
				<Button
					style={{
						borderRadius: '16px',
						backgroundColor: sortOrder === 'ascending' ? Colors.emerald : 'white',
					}}
					margin='15px 0 15px 0'
					width={'215px'}
					height={'60px'}
					onClick={handleUploadDateFilterClick}
					disabled={!uploadDateFilterEnabled}
				>
					{/* Render filter button with conditional styling based on enabled/disabled state */}
					<Text
						style={{
							textAlign: 'center',
						}}
						bold
						color={sortOrder === 'ascending' ? 'white' : Colors.emerald}
						midLarge
					>
						{sortOrder === 'ascending' ? 'Ascending Date' : 'Descending Date'}
					</Text>
					<Icon
						icon={sortOrder === 'ascending' ? faChevronUp : faChevronDown}
						color={sortOrder === 'ascending' ? 'white' : Colors.emerald}
						size='lg'
						style={{ marginLeft: '10px' }}
					/>
				</Button>
				{/* Render toggle button to enable/disable filter */}
				{/* Created just in case it's needed */}
				{/* <Button onClick={handleToggleUploadDateFilter}>
					{uploadDateFilterEnabled
						? 'Disable Upload Date Filter'
						: 'Enable Upload Date Filter'}
				</Button> */}
			</ColumnFlex>
		);
	};
	const filterDocumentsByExpiration = (documents, filterOrder) => {
		return documents
			.filter((doc) => !!doc.expiration_date)
			.sort((a, b) => {
				if (filterOrder === 'ascending') {
					return new Date(a.expiration_date) - new Date(b.expiration_date);
				} else {
					return new Date(b.expiration_date) - new Date(a.expiration_date);
				}
			});
	};
	const renderExpirationFilter = () => {
		return (
			<ColumnFlex padding={'0px 0px 0px 20px'}>
				<Button
					style={{
						borderRadius: '16px',
						backgroundColor:
							expirationDateSortOrder === 'ascending' ? Colors.emerald : 'white',
					}}
					margin='15px 0 15px 0'
					width={'215px'}
					height={'60px'}
					onClick={handleExpirationDateFilterClick}
					disabled={!expirationDateFilterEnabled}
				>
					{/* Render filter button with conditional styling based on enabled/disabled state */}
					<Text
						style={{
							textAlign: 'center',
						}}
						bold
						color={expirationDateSortOrder === 'ascending' ? 'white' : Colors.emerald}
						midLarge
					>
						{expirationDateSortOrder === 'ascending'
							? 'Ascending Date'
							: 'Descending Date'}
					</Text>
					<Icon
						icon={expirationDateSortOrder === 'ascending' ? faChevronUp : faChevronDown}
						color={expirationDateSortOrder === 'ascending' ? 'white' : Colors.emerald}
						size='lg'
						style={{ marginLeft: '10px' }}
					/>
				</Button>
				{/* Render toggle button to enable/disable filter */}
				{/* Created just in case it's needed */}
				{/* <Button onClick={handleToggleExpirationDateFilter}>
					{expirationDateFilterEnabled
						? 'Disable Expiration Date Filter'
						: 'Enable Expiration Date Filter'}
				</Button> */}
			</ColumnFlex>
		);
	};

	const renderExpiredFilter = () => {
		return (
			<ColumnFlex padding={'0px 0px 0px 20px'}>
				<Button
					style={{
						borderRadius: '16px',
						backgroundColor: expirationDateFilterEnabled ? Colors.emerald : 'white',
					}}
					margin='15px 0 15px 0'
					width={'215px'}
					height={'60px'}
					onClick={handleExpiredFilterClick}
				>
					<Text
						style={{
							textAlign: 'center',
						}}
						bold
						color={expirationDateFilterEnabled ? 'white' : Colors.emerald}
						midLarge
					>
						{expirationDateFilterEnabled ? 'Not Expired' : 'Expired'}
					</Text>
				</Button>
			</ColumnFlex>
		);
	};
	const renderResetButton = () => {
		return (
			<Button
				onClick={handleResetFilters}
				style={{
					borderRadius: '16px',
					backgroundColor: Colors.emerald,
					color: 'white',
					width: '150px',
					height: '50px',
					fontSize: '16px',
					fontWeight: 'bold',
					cursor: 'pointer',
					border: 'none',
					outline: 'none',
					margin: '20px 0',
				}}
			>
				Reset Filters
			</Button>
		);
	};

	const renderRows = () => {
		if (documents.length > 0) {
			// filter documents with status based on status filter
			let renderedDocs = [];
			renderedDocs = documents.filter((d) => statusFilter.includes(d.status));

			if (documentFilter.length > 0) {
				// filter documents with document name based on document filter
				renderedDocs = renderedDocs.filter((d) => documentFilter.includes(d.document_name));
			}

			if (selectedUsers.length > 0) {
				// filter documents based on user type
				renderedDocs = renderedDocs.filter((d) => selectedUsers.includes(d.uploaded_by));
			}
			return renderedDocs.map((d, i) => {
				const name = d.uploaded_by === 'carePro' ? d.provider?.display_name : `CarePlan`;

				const userType = d.uploaded_by === 'carePro' ? 'CarePro' : 'Client';
				return (
					<SetTable.RowAction
						onClick={() => props.history.push('/admin/document/' + d.id)}
					>
						<SetTable.ColumnCon flex='1'>{renderIcon(d.status)}</SetTable.ColumnCon>
						<SetTable.ColumnCon flex='2'>
							<RowFlex alignCenter gap='8px'>
								<Icon
									icon={d.uploaded_by === 'carePro' ? faUserNurse : faUser}
									color={Colors.primary.primary}
									size='lg'
								/>
							</RowFlex>
						</SetTable.ColumnCon>
						<SetTable.ColumnCon flex='4'>
							<Text color='#2D2D2D' fontWeight='100' subtext>
								{d.document_name}
							</Text>
						</SetTable.ColumnCon>

						<SetTable.ColumnCon flex='2'>
							<Text color='#2D2D2D' fontWeight='100' mediumText>
								{name}
							</Text>
						</SetTable.ColumnCon>

						<SetTable.ColumnCon flex='2'>
							<Text color='#2D2D2D' fontWeight='100' mediumText>
								{d.expiration_date
									? new Date(d.expiration_date).toLocaleDateString('en-US', {
											year: 'numeric',
											month: 'long',
											day: 'numeric',
									  })
									: 'N/A'}
							</Text>
						</SetTable.ColumnCon>

						<ColumnConEnd
							style={{
								pointer: 'cursor',
							}}
							flex='2'
						>
							<div
								style={{
									cursor: 'pointer',
								}}
							>
								<Icon icon={faChevronRight} color={Colors.emerald} size='lg' />
							</div>
						</ColumnConEnd>
					</SetTable.RowAction>
				);
			});
		} else {
			return (
				<SetTable.ColumnCon>
					<Text color='#969696' fontWeight='100' mediumText margin='16px'>
						Uploaded Documents
					</Text>
				</SetTable.ColumnCon>
			);
		}
	};

	const renderLegend = () => {
		return (
			<>
				<SetTable.ColumnCon flex='1'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Status
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon flex='2'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						User Type
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon flex='4'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Document Name
					</Text>
				</SetTable.ColumnCon>

				<SetTable.ColumnCon flex='2'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Display Name
					</Text>
				</SetTable.ColumnCon>

				<SetTable.ColumnCon flex='2'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Expiration
					</Text>
				</SetTable.ColumnCon>

				<SetTable.ColumnCon flex='2' />
			</>
		);
	};

	const renderDropDownSelect = () => {
		// create a list of all the file names
		const fileNames = documents.map((d) => d.document_name);
		// remove duplicates
		const uniqueFileNames = [...new Set(fileNames)];
		// create a list of objects for react-select
		const options = uniqueFileNames.map((f) => {
			return {
				value: f,
				label: f,
			};
		});
		return (
			<Select
				placeholder='Filter by doc name'
				options={options}
				className='doc-filter-select'
				isMulti
				onChange={(selectedOption) => {
					console.log('selectedOption', selectedOption);
					// ensure that the selected option is an array

					const arr = selectedOption.map((s) => s.value);
					setDocumentFilter(arr);
				}}
			/>
		);
	};
	const handleUserTypeSelect = (type) => {
		// if the user type is already selected, remove it
		if (selectedUsers.includes(type)) {
			setSelectedUsers(selectedUsers.filter((u) => u !== type));
		} else {
			setSelectedUsers([...selectedUsers, type]);
		}
	};
	return (
		<ParentContainer
			style={{
				minHeight: '100vh',
			}}
		>
			<PHDColumn fullHeight>
				<RowFlex gap='20px' fullHeight fullWidth columnMobile>
					<PHWhiteCon
						style={{
							flex: 1,
							backgroundColor: 'white',
							height: 'fit-content',
						}}
					>
						<Title
							subtitle
							color={Colors.greenLightTeal}
							margin='0 0 20px 0'
							fontWeight='700'
						>
							Filter
						</Title>
						<ColumnFlex
							style={{
								borderRadius: '16px',
								padding: '10px 10px 10px 10px',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignCenter
						>
							<ColumnFlex fullWidth alignStart>
								<Text
									alignStart
									fontStyle='bold'
									color='#969696'
									fontWeight='100'
									mediumText
								>
									By User Type
								</Text>
							</ColumnFlex>
							<SwitchTabsButton
								selectedUsers={selectedUsers}
								handleUserTypeSelect={handleUserTypeSelect}
							/>
						</ColumnFlex>
						<ColumnFlex
							style={{
								borderRadius: '16px',

								padding: '10px 10px 10px 10px',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignStart
							gap='12px'
						>
							<ColumnFlex fullWidth alignStart>
								<Text
									alignStart
									fontStyle='bold'
									color='#969696'
									fontWeight='100'
									mediumText
								>
									By Status
								</Text>
							</ColumnFlex>
							<RowFlex
								onClick={() => handleChange('pending')}
								alignCenter
								gap='12px'
								padding={'0px 0px 0px 20px'}
							>
								<div
									style={{
										width: '20px',
										height: '20px',
										borderRadius: '4px',
										border: '1px solid #969696',
										backgroundColor: statusFilter.includes('pending')
											? Colors.emerald
											: 'white',
									}}
								/>

								{renderIcon('pending')}
								<Text>Pending</Text>
							</RowFlex>
							<RowFlex
								onClick={() => handleChange('approved')}
								alignCenter
								gap='12px'
								padding={'0px 0px 0px 20px'}
							>
								<div
									style={{
										width: '20px',
										height: '20px',
										borderRadius: '4px',
										border: '1px solid #969696',
										backgroundColor: statusFilter.includes('approved')
											? Colors.emerald
											: 'white',
									}}
								/>

								{renderIcon('approved')}
								<Text>Approved</Text>
							</RowFlex>
							<RowFlex
								onClick={() => handleChange('rejected')}
								alignCenter
								gap='12px'
								padding={'0px 0px 0px 20px'}
							>
								<div
									style={{
										width: '20px',
										height: '20px',
										borderRadius: '4px',
										border: '1px solid #969696',
										backgroundColor: statusFilter.includes('rejected')
											? Colors.emerald
											: 'white',
									}}
								/>

								{renderIcon('rejected')}
								<Text>Rejected</Text>
							</RowFlex>
						</ColumnFlex>
						<ColumnFlex
							style={{
								borderRadius: '16px',

								padding: '10px 10px 10px 10px',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignStart
							gap='12px'
						>
							<Text fontStyle='bold' color='#969696' fontWeight='100' mediumText>
								By Type of Document
							</Text>
							{renderDropDownSelect()}
						</ColumnFlex>
						<ColumnFlex
							style={{
								borderRadius: '16px',

								padding: '10px 10px 10px 10px',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignStart
							gap='12px'
						>
							<ColumnFlex fullWidth alignStart>
								<Text
									alignStart
									fontStyle='bold'
									color='#969696'
									fontWeight='100'
									mediumText
								>
									By Upload Date
								</Text>
							</ColumnFlex>
							{renderDateFilter()}
						</ColumnFlex>
						<ColumnFlex
							style={{
								borderRadius: '16px',

								padding: '10px 10px 10px 10px',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignStart
							gap='12px'
						>
							<ColumnFlex fullWidth alignStart>
								<Text
									alignStart
									fontStyle='bold'
									color='#969696'
									fontWeight='100'
									mediumText
								>
									By Expiration Date
								</Text>
							</ColumnFlex>
							{renderExpirationFilter()}
						</ColumnFlex>
						<ColumnFlex
							style={{
								borderRadius: '16px',

								padding: '10px 10px 10px 10px',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignStart
							gap='12px'
						>
							<ColumnFlex fullWidth alignStart>
								<Text
									alignStart
									fontStyle='bold'
									color='#969696'
									fontWeight='100'
									mediumText
								>
									By Expired Date
								</Text>
							</ColumnFlex>
							{renderExpiredFilter()}
						</ColumnFlex>
					</PHWhiteCon>
					<div
						style={{
							flex: 5,
						}}
					>
						<SetTable
							title='Uploaded Documents'
							renderRows={renderRows}
							renderLegend={renderLegend}
							leftPadding='16px'
						>
							{
								//this.renderSearchBar()
							}
						</SetTable>
					</div>
				</RowFlex>
			</PHDColumn>
		</ParentContainer>
	);
};

const mapStateToProps = ({ admin }, props) => {
	return {};
};
const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageDocumentsHandler);
