import React, { Fragment, Component, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import Colors from 'colors';

import Text from 'AppComp/Text';
import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';
import StyledTextInput, { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';

import { FormItemsLength } from 'AppComp/provider/POnboard/OnboardViews';

import { ActivityRadioInupt } from 'AppComp/patient/CarePlanInfo/CarePlanInfoViews';
import { CustomFormButton } from 'AppComp/patient/PatientCardCollection/PatientCardCollectionViews';

import NavSelector, { navItemsModifier } from 'AppComp/core/NavSelector';

import { ValidateAttributeInput } from './AttributeTabValidation';
import TemplateTraitTile from './TemplateTraitTile';

import { Section } from '../UserModel/UserModelBlocks';

const toastErrorConfig = {
	containerId: 'basic',
	autoClose: false,
};

export const NAV_ITEMS = {
	added_traits: {
		text: 'Template Traits',
		stat: 0,
		active: true,
	},
	add_traits: {
		text: 'Add Traits',
		stat: 0,
		active: false,
	},
};

const TraitRadioInput = (props) => {
	const { form, field, type, custom, label, disabled, attribute } = props;

	return (
		<RowFlex
			padding='12px 16px'
			style={{
				width: '100%',
				backgroundColor: Colors.fullWhite,
				borderRadius: '8px',
				alignItems: 'center',
			}}
		>
			<input
				disabled={disabled}
				{...field}
				type={'checkbox'}
				checked={form.values[field.name]}
			/>
			<ColumnFlex
				style={{
					width: '100%',
					backgroundColor: Colors.fullWhite,
					borderRadius: '8px',
				}}
				alignCenter
				spaceBetween
				margin='8px 0 0 0'
				padding='0px 16px 0 8px'
				gap='16px 0'
			>
				<RowFlex fullWidth spaceBetween>
					<Text midLarge margin='0 8px 0 0'>
						<span style={{ fontWeight: 'bold' }}>{attribute.label}:</span>{' '}
						{attribute.description || 'No description'}
					</Text>
				</RowFlex>
				<RowFlex fullWidth gap='16px'>
					<Text margin='0 8px 0 0'>
						<span style={{ fontWeight: 'bold' }}>Required:</span>{' '}
						{attribute.required ? 'Yes' : 'No'}
					</Text>
					<Text margin='0 8px 0 0'>
						<span style={{ fontWeight: 'bold' }}>Type:</span> {attribute.trait_type}
					</Text>
				</RowFlex>
			</ColumnFlex>
		</RowFlex>
	);
};

const TemplateSection = (props) => {
	console.log('TemplateSection props', props);
	const { template, attributeTraits, addTraitToTemplate, deactivateTraitFromTemplate } = props;

	const [navItems, setNavItems] = useState(NAV_ITEMS);
	const [loading, setLoading] = useState(false);
	const [addedTraits, setAddedTraits] = useState([]);
	const [traitsNotAdded, setTraitsNotAdded] = useState([]);

	useEffect(() => {
		filterTemplateTrait();
		if (template) {
			setAddedTraits(template.attributeTraitTemplateHelpers);
		}
	}, [attributeTraits, template]);
	// filter the attribute traits to either
	/* 
    addedTraits
    traitsNotAdded

    * this helps handle which trait to show in what section
  */
	const filterTemplateTrait = () => {
		// list to check which trait id are already added to template
		const addedTraits = new Set(
			template.attributeTraitTemplateHelpers.map(
				(templateHelper) => templateHelper.attributeTrait.id
			)
		);

		console.log('attributeTraits', attributeTraits);
		// check which id from all the traits are not added
		const notAddedTraits = attributeTraits.filter((trait) => !addedTraits.has(trait.id));

		// set the state
		setTraitsNotAdded(notAddedTraits);

		// update the nav items stats
		const updatedNavItems = navItemsModifier(navItems, null, 'stat', {
			add_traits: notAddedTraits.length,
			added_traits: template.attributeTraitTemplateHelpers.length,
		});

		setNavItems(updatedNavItems);
	};

	const navSelectorHandler = (item, modifier, value) => {
		const updatedNavItems = navItemsModifier(navItems, item, modifier, value);

		setNavItems(updatedNavItems);
	};

	//state for nav items

	const handleSubmit = (values, form) => {
		console.log('TemplateSection - handleSubmit', values);

		// reduce the object to an array of keys that check if their value is true
		// ensure you have the right template id
		const selectedTraits = Object.keys(values).reduce((acc, key) => {
			if (values[key]) {
				acc.push({
					trait_id: parseInt(key, 10),
					trait_template_id: template.id,
				});
			}

			return acc;
		}, []);

		// ensure all values are valid

		// this should only run after validation runs right
		setLoading(true);

		addTraitToTemplate({
			selectedTraits,
			callBackFn: handleClearForm,
			templateId: template.id,
		});
	};

	const handleClearForm = (status) => {
		if (status) {
			form.resetForm();
		}

		setLoading(false);
	};

	return (
		<Section title={template.template_label}>
			<ColumnFlex
				fullWidth
				padding='16px'
				style={{
					backgroundColor: Colors.littleBitDarkerLightGray,
					borderRadius: '10px',
				}}
			>
				<NavSelector items={navItems} navSelectorHandler={navSelectorHandler} />
				{navItems.add_traits.active && (
					<Formik
						onSubmit={handleSubmit}
						render={(formProps) => {
							console.log(formProps);
							return (
								<Form
									noValidate
									style={{
										marginBottom: 0,
										marginTop: '8px',
									}}
								>
									<ColumnFlex alignCenter justifyCenter>
										{traitsNotAdded.map((trait, index) => {
											return (
												<FormItemsLength
													key={trait.id}
													margin='8px 0'
													width={'100%'}
												>
													<Field
														name={trait.id}
														type='text'
														backgroundColor={Colors.fullWhite}
														component={TraitRadioInput}
														label={trait.label}
														attribute={trait}
													/>
												</FormItemsLength>
											);
										})}
										{loading ? (
											<Text
												style={{
													textAlign: 'center',
												}}
												bold
												color={Colors.primary.primary}
												midLarge
											>
												Loading
											</Text>
										) : (
											<CustomFormButton
												style={{
													borderRadius: '24px',
												}}
												valid={formProps.isValid}
												type='submit'
												margin='10px 0 0 0 '
												backgroundColor={Colors.primary.primary}
												width={'140px'}
												height={'40px'}
											>
												<Text
													style={{
														textAlign: 'center',
													}}
													bold
													color={
														formProps.isValid
															? 'white'
															: Colors.primary.primary
													}
													midLarge
												>
													Add Traits
												</Text>
											</CustomFormButton>
										)}
									</ColumnFlex>
								</Form>
							);
						}}
					/>
				)}
				{navItems.added_traits.active && (
					<ColumnFlex gap='8px' padding='16px 0' alignCenter justifyCenter>
						{addedTraits.map((trait, index) => {
							return (
								<TemplateTraitTile
									key={trait.id}
									{...trait}
									deactivateTraitFromTemplate={deactivateTraitFromTemplate}
									traitTemplateId={template.id}
								/>
							);
						})}
					</ColumnFlex>
				)}
			</ColumnFlex>
		</Section>
	);
};

const TemplateTab = (props) => {
	const { attributeTraits, traitTemplates, deactivateTraitFromTemplate, addTraitToTemplate } =
		props;
	console.log('traitTemplates props', props);
	return (
		<>
			{traitTemplates.map((d, i) => {
				return (
					<TemplateSection
						addTraitToTemplate={addTraitToTemplate}
						deactivateTraitFromTemplate={deactivateTraitFromTemplate}
						attributeTraits={attributeTraits}
						key={i}
						template={d}
					/>
				);
			})}
		</>
	);
};

export default TemplateTab;
