import React, { useState, useEffect } from 'react';
import { ColumnFlex, RowFlex } from 'basicStyles';
import Colors from 'colors';
import { Formik, Form, Field } from 'formik';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

import Title from 'AppComp/Title';
import { Hearts } from 'react-loader-spinner';

import {
	MARGIN_TITLE,
	FormItemsLength,
	OBFormButtonText,
	OBBasicCon,
	OBSubText,
} from 'AppComp/provider/POnboard/OnboardViews';
import Text from 'AppComp/Text';

import {
	CardSection,
	CustomFormButton,
} from 'AppComp/patient/PatientCardCollection/PatientCardCollectionViews';

import {
	useStripe,
	useElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from '@stripe/react-stripe-js';

import Icon from 'AppComp/Icon';

import { ACCEPTED_CARDS } from 'constants';
import { toastErrorConfig, toastSuccessConfig } from 'reducers/provider';
import PageLoaderView from 'AppComp/PageLoaderView';

const FORM_ITEM_LENGTH = isMobile ? '420px' : '420px';
const BankInfo = ({
	handleGoToNextStep,
	updateProviderVerificationInfo,
	setIsLoading,
	isLoading,
}) => {
	const [err, updateErr] = useState('');
	const [addPaymentMethodFormIsValid, setAddPaymentMethodFormIsValid] = useState(false);

	const stripe = useStripe();
	const elements = useElements();

	const addPaymentMethodFormValidityHandler = (validity) => {
		setAddPaymentMethodFormIsValid(validity);
	};

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();
		setIsLoading(true);
		if (!stripe || (!elements && userStripe)) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}
		console.log(elements.getElement(CardNumberElement));
		try {
			const result = await stripe.createToken(elements.getElement(CardNumberElement), {
				currency: 'usd',
			});

			console.log('result', result);

			if (result.error) {
				// Display result.error.message in your UI.
				setIsLoading(false);
				updateErr(result.error.message);
			} else {
				updateProviderVerificationInfo({
					token: result.token.id,
				});
			}
		} catch (err) {
			console.log('err', err);
			setIsLoading(false);
			updateErr('Something went wrong please try again');
			toast.error('Something went wrong please try again', toastErrorConfig);
		}
	};

	const renderAcceptedCards = () => {
		return ACCEPTED_CARDS.map((d, i) => {
			return (
				<div key={'card-key-' + d.id}>
					<Icon size='2x' color={Colors.primary.primary} icon={d.icon} />
				</div>
			);
		});
	};

	return (
		<ColumnFlex style={{ maxWidth: FORM_ITEM_LENGTH }} center>
			<Title color={'black'} bold subtitle>
				Bank Information
			</Title>
			<ColumnFlex center>
				<Text darkGray mediumText margin='32px 0 24px 0'>
					Please Input Your Debit Card Information Here
				</Text>
				<Text darkGray mediumText margin='8px 0 24px 0'>
					Test: 4000-0566-5566-5556
				</Text>
				{err && (
					<Text error mediumText margin='8px 0 24px 0'>
						{err}
					</Text>
				)}
				<RowFlex gap='8px'>{renderAcceptedCards()}</RowFlex>
			</ColumnFlex>
			<ColumnFlex padding='0 0 12px 0' margin='20px auto 0 auto' center gap='12px'>
				<form onSubmit={handleSubmit}>
					<CardSection
						addPaymentMethodFormValidityHandler={addPaymentMethodFormValidityHandler}
					/>
					<RowFlex center>
						{/* <button disabled={!stripe}>Save Card</button> */}
						{isLoading && (
							<Hearts color={Colors.primary.primary} height={50} width={50} />
						)}
						{!isLoading && (
							<CustomFormButton
								style={{
									borderRadius: '24px',
								}}
								valid={stripe}
								type='submit'
								margin='32px 8px 0 0px'
								backgroundColor={Colors.primary.primary}
								width={'240px'}
								height={isMobile ? '40px' : '60px'}
							>
								<Text
									style={{
										textAlign: 'center',
									}}
									bold
									color={'white'}
									midLarge
								>
									Add Payment Method
								</Text>
							</CustomFormButton>
						)}
					</RowFlex>
				</form>
			</ColumnFlex>
		</ColumnFlex>
	);
};
export default BankInfo;
