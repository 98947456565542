import React from 'react';
import { Route, Switch } from 'react-router-dom';

import BlockedTimesPage from '../pages/BlockedTimesPage';

import { blockedTimesRoutes } from './constants';

const BlockedTimesRoutes = () => {
	return (
		<Switch>
			<Route path={blockedTimesRoutes.home}>
				<BlockedTimesPage />
			</Route>
		</Switch>
	);
};

export default BlockedTimesRoutes;
