export const formatTimestamp = (timestamp) => {
	const date = new Date(timestamp * 1000);
	const now = new Date();
	const differenceInSeconds = Math.floor((now - date) / 1000);
	const differenceInDays = Math.floor(differenceInSeconds / (60 * 60 * 24));

	if (differenceInSeconds < 24 * 60 * 60) {
		return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
	} else if (differenceInDays <= 7) {
		return `${differenceInDays} day${differenceInDays > 1 ? 's' : ''} ago`;
	} else {
		return date.toLocaleDateString();
	}
};
