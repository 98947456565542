import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	StyledInformationTile,
	StyledIconContainer,
	StyledTitle,
	StyledDescription,
} from './styles';

const InformationTile = (props) => {
	const { icon, title, description } = props;

	return (
		<StyledInformationTile>
			<StyledIconContainer>
				<FontAwesomeIcon icon={icon} size='4x' />
			</StyledIconContainer>

			<StyledTitle>{title}</StyledTitle>
			<StyledDescription>{description}</StyledDescription>
		</StyledInformationTile>
	);
};

export default InformationTile;
