import React, { Component, useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';
import Colors from 'colors';
import styled from 'styled-components';
import { Calendar, Views } from 'react-big-calendar';
import Select from 'react-select';

import api from 'scripts/api';

import AutoSizer from 'react-virtualized-auto-sizer';
import moment from 'moment';

import { CustomTimeSlotWrapper, MalCon, CalendarBlockedEventTile } from 'AppComp/CalendarViews';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import { device } from 'AppComp/styled/StyledMediaQuery';
import is from 'styled-is';
import { CAL_LOCALIZER } from 'AppComp/App';
import { ColumnFlex, RowFlex } from 'basicStyles';

import { DAYS_OF_WEEK } from 'constants';
import { set } from 'lodash';

export const CalandarHeaderHelper = (props) => {
	const { setDisplayLabel } = props;
	useEffect(() => {
		// console.log('props', props);
		setDisplayLabel(props.label);
	}, [props.label]);
	return null;
};

let allViews = Object.keys(Views).map((k) => Views[k]);

const CarePlanSection = styled.div`
	background-color: ${Colors.fullWhite};

	border-radius: 12px;
	width: 100%;
	height: ${({ height }) => height || '90vh'};
	margin-bottom: 16px;
	position: relative;

	${is('hideCalHeader')`
		.mbsc-cal-hdr {
			display: none !important;
		}
	`}
`;

const BasicChildContainer = styled.div`
	flex: 5;
	padding: 4px;
	margin: auto;

	position: relative;
	height: ${({ height }) => height || 'inherit'};
	z-index: 5;
	max-width: 2400px;

	@media ${device.mobileS} {
		margin: 0;
	}
`;

export const CalendarWeekHeader = (props) => {
	const { date, label } = props;
	console.log('CalendarWeekHeader', props);

	// get the day of the year from the date
	const day = new Date(date);
	const otherDay = moment(date).format('DD'); // get the day of the week as a string

	const dayOfWeek = day.getDay();

	return (
		<RowFlex
			style={{
				height: '100%',
			}}
			center
			gap='8px'
		>
			<Text bold alignCenter>
				{label}
			</Text>
			<Text bold alignCenter>
				{otherDay}
			</Text>
		</RowFlex>
	);
};

const Schedule = (props) => {
	const { view, currentDate, setDisplayLabel, events, onSelectEvent } = props;
	console.log('schedule - events', events);
	return (
		<BasicChildContainer>
			<CarePlanSection height='85vh'>
				<AutoSizer>
					{({ height, width }) => (
						<div
							style={{
								width: width,
								height: height - 30,
							}}
						>
							<Calendar
								onSelectEvent={onSelectEvent}
								localizer={CAL_LOCALIZER}
								defaultView={view.value}
								view={view.value}
								views={allViews}
								scrollToTime={currentDate}
								date={currentDate}
								step={60}
								events={events}
								showMultiDayTimes
								components={{
									toolbar: (passedProps) => {
										return (
											<CalandarHeaderHelper
												setDisplayLabel={setDisplayLabel}
												{...passedProps}
											/>
										);
									},
									week: {
										header: (props) => {
											console.log('header', props);
											return <CalendarWeekHeader {...props} />;
										},
									},
								}}
								formats={{
									dayFormat: (date, localizer) => {
										return DAYS_OF_WEEK[date.getDay()];
									},
								}}
							/>
						</div>
					)}
				</AutoSizer>
			</CarePlanSection>
		</BasicChildContainer>
	);
};

export default Schedule;
