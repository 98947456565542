import React, { useMemo, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { BackDropBg } from 'basicStyles';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Calendar, Views } from 'react-big-calendar';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import AnimateHeight from 'react-animate-height';
import Colors from 'colors';
import { CAL_LOCALIZER } from 'AppComp/App';
import { isMobile } from 'react-device-detect';
import {
	faEyeSlash,
	faEye,
	faMailBulk,
	faMapMarker,
	faChevronRight,
	faCalendarAlt,
	faMars,
	faVenus,
	faUser,
	faChevronLeft,
	faClipboard,
	faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import { ColumnFlex, RowFlex, DropShadow } from 'basicStyles';
import moment from 'moment';
import { WORK_TIME_FRAME_TYPE } from 'constants';
import Select from 'react-select';
import { userContactCarePro } from 'reducers/patient';
import { fetchSelectedProvider, switchProviderContent } from 'reducers/provider';

import { DAYS_OF_WEEK } from 'constants';

import { ParentContainer, PHWhiteCon, PHDColumn } from 'AppComp/provider/ProviderHomeDashboard';

import api from 'scripts/api';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';

import Tiles from '../../core/Tiles';

import {
	CarePlanActionSection,
	allViews,
	ButtonTing,
} from '../../provider/ProviderHomeLanding/CarePlanInfo';

import { CORE_JOB_POST_MODEL } from 'reducers/graphqlModelTypes';
import { toast } from 'react-toastify';
import { TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG, APPLICATION_STATUS_ENUM } from 'constants';

import { CalandarHeaderHelper, CalendarWeekHeader } from '../ClientSchedule/Schedule';

export const MainPanel = styled(motion.div)`
	position: fixed;
	display: flex;
	height: fit-content;
	width: fit-content;
	z-index: 100;
	left: 0;
	display: flex;
	flex-direction: row;
	top: 170px;
	border: 12px;
	gap: 20px;
	top: 50%;
	left: 50%;
	max-width: 90vw;
	transform: translate(-50%, -42%);
	max-height: 100vh;
	padding: 20px 0;
	overflow: auto;
	overflow-y: scroll;
`;

export const StyledButton = styled.button`
	background: inherit;
	border: none;
	margin-right: 8px;
`;

const ClientOfferHandler = (props) => {
	const [jobPost, setJobPost] = useState(null);

	console.log('ClientOfferHandler', props);
	const {
		carePlan,
		id,
		userContactCarePro,
		conversation,
		selectedProvider,
		fetchSelectedProvider,
		match: { params },
	} = props;

	const [events, setEvents] = useState([]);

	const [offerAmount, setOfferAmount] = useState(0);

	// cal items
	const [userSelectedDate, setUserSelectedDate] = useState(new Date());

	const currentDate = useMemo(() => userSelectedDate, [userSelectedDate]);

	const [view, setView] = useState({
		label: 'Month',
		value: Views.MONTH,
	});
	const [displayLabel, setDisplayLabel] = useState('Day');

	useEffect(() => {
		if (carePlan && selectedProvider) {
			createScheduleEventsFromCarePlan(carePlan.workTimeFrames);
		}
	}, [carePlan]);

	useEffect(() => {
		fetchJobPost();
	}, [params]);

	useEffect(() => {
		console.log('carePro id', params);
		fetchSelectedProvider(params.careProId);
	}, []);

	const history = useHistory();

	const fetchJobPost = async () => {
		try {
			if (params.jobPostId) {
				const fetchSingleJobPostRes = await api.graph({
					query: `{
            fetchSingleJobPost(job_post_id: ${params.jobPostId}) {
              ${CORE_JOB_POST_MODEL}
            }
          }`,
				});

				if (fetchSingleJobPostRes.fetchSingleJobPost) {
					setJobPost(fetchSingleJobPostRes.fetchSingleJobPost);
				}
			}
		} catch (err) {
			console.log('err', err);
			toast.error('Error fetching job post', TOAST_ERROR_CONFIG);

			//throw err;
		}
	};

	if (carePlan === null) {
		return null;
	}

	if (selectedProvider === null) {
		return null;
	}
	const careProClickHandler = (careProId) => {
		history.push('/jobPost/' + jobPost.id + '/carePro/' + careProId);
	};

	const renderSections = () => {
		const section = Object.keys(CARE_PLAN_ADLS)
			.filter((key) => {
				if (CARE_PLAN_ADLS[key].shiftTitle) {
					return true;
				} else {
					return false;
				}
			})
			.map((key) => CARE_PLAN_ADLS[key]);

		//console.log('isDisabled', isDisabled);
		return section.map((item, index) => {
			return <CarePlanActionSection {...item} key={index} />;
		});
	};

	const convertDOBToReadableAge = (dob) => {
		// dob is in the format of 'YYYY-MM-DD'
		const age = moment().diff(dob, 'years');
		return `${age} years`;
	};

	const handleOfferCarePro = async () => {
		// convert offerAmount to cents

		try {
			const intAmount = parseInt(offerAmount, 10);
			const offerAmountParsed = intAmount * 100;

			const parsedInputEvents = handleParseEventsForJobOffer();

			const offerProviderJobRes = await api.graph({
				query: `mutation {
					offerProviderJob(
						job_post_id: ${jobPost.id},
						provider_id: ${selectedProvider.id},
						hourly_rate: ${offerAmountParsed}
						work_time_frames: ${api.graphStringify(parsedInputEvents)}
					){
						${CORE_JOB_POST_MODEL}
					}
				}`,
			});

			if (offerProviderJobRes.offerProviderJob) {
				toast.success('Care pro offered', TOAST_SUCCESS_CONFIG);
				backButtonHandler();
			}
		} catch (err) {
			console.log('err', err);
			toast.error('Error offering job', TOAST_ERROR_CONFIG);
		}
	};

	const createScheduleEventsFromCarePlan = (workTimeFrames) => {
		if (carePlan === null) {
			return;
		}

		// loop through workTimeFrames
		// first get all the active ones
		const activeWorkTimeFrames = workTimeFrames.filter((workTimeFrame) => {
			return workTimeFrame.active_status_enum === WORK_TIME_FRAME_TYPE.ACTIVE;
		});

		console.log('activeWorkTimeFrames', activeWorkTimeFrames);
		// create custom shape that react-big-calendar can consume
		const _events = activeWorkTimeFrames.map((workTimeFrame) => {
			// ensure to convert gmt to local time

			const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's time zone

			const userStartDate = new Date(
				workTimeFrame.start_time.toLocaleString('en-US', { timeZone: userTimezone })
			);
			const userEndDate = new Date(
				workTimeFrame.end_time.toLocaleString('en-US', { timeZone: userTimezone })
			);
			// start time in human readable time

			return {
				title: 'Care Session',
				start: userStartDate,
				end: userEndDate,
				allDay: false,
				resource: {
					type: 'careSession',
					workTimeFrame: workTimeFrame,
					carePlan: carePlan,
				},
			};
		});

		setEvents(_events);
	};

	const backButtonHandler = () => {
		history.push('/jobPost/' + jobPost.id);
	};

	const handleParseEventsForJobOffer = () => {
		// loop through events and parse them for the job offer mutation
		// need an array of objects with the following shape
		const futureDates = events.filter((event) => {
			return event.start >= new Date();
		});
		const parsedEvents = futureDates.map((event) => {
			return {
				start_time: event.resource.workTimeFrame.start_time,
				end_time: event.resource.workTimeFrame.end_time,
				parent_id: event.resource.workTimeFrame.id,
			};
		});

		return parsedEvents;
	};
	const renderToolTip = (item) => {
		// return the start and end time
	};

	const renderDropDownForView = () => {
		const options = [
			{
				label: 'Day',
				value: Views.DAY,
			},
			{
				label: 'Week',
				value: Views.WEEK,
			},
			{
				label: 'Month',
				value: Views.MONTH,
			},
			{
				label: 'Agenda',
				value: Views.AGENDA,
			},
		];

		return (
			<div
				style={{
					minWidth: '300px',
					zIndex: 1000,
				}}
			>
				<Select
					placeholder={view.label}
					options={options}
					className='doc-filter-select'
					defaultValue={view.value}
					onChange={(selectedOption) => {
						setView(selectedOption);
					}}
				/>
			</div>
		);
	};

	const handleUserSelectDateChange = (direction) => {
		// direction = true => forward
		// direction = false => backward

		// based on the selected view is how much we'll alter the selected date
		// if view is day, we'll alter by 1 day
		// if view is week, we'll alter by 7 days
		// if view is month, we'll alter by 30 days

		let newDate = new Date(currentDate);
		if (view.value === Views.DAY) {
			newDate.setDate(newDate.getDate() + (direction ? 1 : -1));
		}
		if (view.value === Views.WEEK) {
			newDate.setDate(newDate.getDate() + (direction ? 7 : -7));
		}
		if (view.value === Views.MONTH) {
			newDate.setDate(newDate.getDate() + (direction ? 30 : -30));
		}
		setUserSelectedDate(newDate);
	};

	const handleLinkToChat = async (careProId) => {
		try {
			// this needs an extra handler since there is a chance the user won't have a conversation with the CarePro yet

			// check existing conversations and find the one with the carePro
			const conversation = props.conversations.find(
				(convo) => convo.provider && convo.provider.id === careProId
			);
			if (conversation) {
				push(conversation.id);
			} else {
				// ensure user (alias patient) is logged in
				if (patient === null) {
					return;
				}
				userContactCarePro({
					careProId: careProId,
					userId: patient.id,
				});
			}
		} catch (err) {
			console.log('err', err);
		}
	};

	return (
		<>
			<ColumnFlex
				fullWidth
				padding='16px'
				style={{
					backgroundColor: Colors.theme.primary_background,
					minHeight: 'calc(100vh - 120px)',
				}}
				alignCenter
				justifyCenter
				gap='16px'
			>
				<RowFlex
					style={{
						maxWidth: '1200px',
						width: '100%',
					}}
					columnMobile
					gap='16px'
				>
					<PHWhiteCon
						style={{
							flex: 3,
							minWidth: isMobile ? '100%' : '440px',
							backgroundColor: 'white',
						}}
					>
						<RowFlex margin='0 0 20px 0' gap='8px' alignCenter>
							<StyledButton onClick={() => backButtonHandler()}>
								<Icon color={Colors.primary.primary} icon={faArrowLeft} size='lg' />
							</StyledButton>
							<Title subtitle color={Colors.greenLightTeal} fontWeight='700'>
								Offer Job
							</Title>
						</RowFlex>
						<RowFlex fullWidth>
							<ColumnFlex
								style={{
									borderRadius: '16px',
									backgroundColor: Colors.theme.dark_accent_background,
									padding: '20px 10px 10px 10px',
									flex: 1,
								}}
								margin='10px 0 12px 0'
								alignCenter
							>
								<RowFlex fullWidth>
									<ColumnFlex gap='6px'>
										<Text midLarge fontStyle='thin'>
											Offer
										</Text>
										<RowFlex
											style={{
												width: '100%',
												padding: '10px',
												borderRadius: '24px',
												backgroundColor: 'white',
											}}
											gap='4px'
										>
											<Text bold largeText color={'black'}>
												$
											</Text>
											<input
												type='number'
												value={offerAmount}
												onChange={(e) => setOfferAmount(e.target.value)}
												style={{
													width: '100%',
													border: 'none',
													outline: 'none',
													fontSize: '24px',
													textAlign: 'center',
												}}
											/>
										</RowFlex>

										<Text midLarge fontStyle='thin'>
											per hour
										</Text>
									</ColumnFlex>
								</RowFlex>
							</ColumnFlex>
							<ColumnFlex
								style={{
									flex: 1,
								}}
								center
								gap='8px'
							>
								<ButtonTing
									onClick={() => handleOfferCarePro()}
									style={{
										borderRadius: '32px',
										width: '180px',
										height: '50px',
										backgroundColor: Colors.primary.primary,
									}}
								>
									<Text fontWeight='500' cursor color={'white'} largeText>
										Offer
									</Text>
								</ButtonTing>

								<ButtonTing
									onClick={() => props.handleLinkToChat(selectedProvider.id)}
									style={{
										borderRadius: '32px',
										width: '180px',
										height: '50px',
										backgroundColor: Colors.greenLightTeal,
										border: 'none',
									}}
								>
									<Text fontWeight='500' cursor color={'white'} largeText>
										Chat
									</Text>
								</ButtonTing>
							</ColumnFlex>
						</RowFlex>
					</PHWhiteCon>
					<PHWhiteCon
						style={{
							flex: 3,
							minWidth: isMobile ? '100%' : '440px',
							backgroundColor: 'white',
						}}
					>
						<Title
							subtitle
							color={Colors.greenLightTeal}
							margin='0 0 20px 0'
							fontWeight='700'
						>
							Care Pro
						</Title>
						<ColumnFlex
							style={{
								borderRadius: '16px',
								backgroundColor: Colors.theme.dark_accent_background,
								padding: '20px ',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignCenter
						>
							<Tiles.CareProTile
								buttonText='Chat'
								onSelectCarePro={(carePro) => {
									handleLinkToChat(selectedProvider.id);
								}}
								careProClickHandler={careProClickHandler}
								{...selectedProvider}
							/>
						</ColumnFlex>
					</PHWhiteCon>
				</RowFlex>

				<PHWhiteCon
					style={{
						maxWidth: '1200px',
						backgroundColor: 'white',
						height: 'fit-content',
						width: '100%',
					}}
				>
					<Title
						subtitle
						color={Colors.greenLightTeal}
						margin='0 0 20px 0'
						fontWeight='700'
					>
						Care Needs
					</Title>
					<ColumnFlex
						style={{
							borderRadius: '16px',
							backgroundColor: Colors.theme.dark_accent_background,
							padding: '20px 10px 10px 10px',
						}}
						fullWidth
						margin='10px 0 12px 0'
						alignCenter
					>
						{renderSections()}
					</ColumnFlex>
				</PHWhiteCon>

				<PHWhiteCon
					style={{
						flex: 3,
						maxWidth: '1200px',
						width: '100%',
						backgroundColor: 'white',
					}}
				>
					<RowFlex fullWidth justifyBetween>
						<Title
							subtitle
							color={Colors.greenLightTeal}
							margin='0 0 20px 0'
							fontWeight='700'
						>
							Schedule <span style={{ fontSize: '16px' }}>({displayLabel})</span>
						</Title>
						<RowFlex gap='8px' alignCenter>
							{renderDropDownForView()}
							<RowFlex
								alignCenter
								gap='12px'
								style={{
									cursor: 'pointer',
								}}
							>
								<Icon
									onClick={() => handleUserSelectDateChange(false)}
									icon={faChevronLeft}
									color={Colors.greenLightTeal}
									size='md'
								/>
								<Icon
									onClick={() => handleUserSelectDateChange(true)}
									icon={faChevronRight}
									color={Colors.greenLightTeal}
									size='md'
								/>
							</RowFlex>
						</RowFlex>
					</RowFlex>

					<div
						style={{
							borderRadius: '16px',
							backgroundColor: Colors.theme.dark_accent_background,
							padding: '20px 10px 10px 10px',
							height: '600px',

							width: '100%',
						}}
						margin='10px 0 12px 0'
						alignCenter
					>
						<AutoSizer>
							{({ height, width }) => (
								<div
									style={{
										width: width,
										height: height - 30,
									}}
								>
									<Calendar
										localizer={CAL_LOCALIZER}
										defaultView={view.value}
										view={view.value}
										views={allViews}
										scrollToTime={currentDate}
										date={currentDate}
										step={60}
										events={events}
										showMultiDayTimes
										components={{
											toolbar: (passedProps) => {
												return (
													<CalandarHeaderHelper
														setDisplayLabel={setDisplayLabel}
														{...passedProps}
													/>
												);
											},
											week: {
												header: (props) => {
													console.log('header', props);

													return <CalendarWeekHeader {...props} />;
												},
											},
										}}
										formats={{
											dayFormat: (date, localizer) => {
												return DAYS_OF_WEEK[date.getDay()];
											},
										}}
									/>
								</div>
							)}
						</AutoSizer>
					</div>
				</PHWhiteCon>
			</ColumnFlex>
		</>
	);
};

const mapStateToProps = ({ patient, provider }, props) => {
	return {
		selectedProvider: provider.selectedProvider,
		patient: patient.patient,
		carePlan: patient.carePlan,
		conversations: patient.conversations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSelectedProvider: (id) => dispatch(fetchSelectedProvider(id)),
		userContactCarePro: (params) => dispatch(userContactCarePro(params)),
		push: (conversationId) => dispatch(push(`/chat/${conversationId}`)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientOfferHandler);

const framerSidebarBackground = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};

const framerPanel = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};

const EMPTY_CARE_PLAN_ADLS = {
	1: {
		shiftTitle: 'Personal Care',
		title: 'Activities willing to do:',
		values: [],
	},
	2: { title: 'Environment willing to work in:', values: [] },
	3: { title: 'Comfortable being in an environment with:', values: [] },
	4: {
		shiftTitle: 'Cooking',
		title: 'Meals I am able to cook:',
		values: [],
	},
	5: {
		shiftTitle: 'Transportation',
		title: 'Transportation:',
		values: [],
	},
	6: { title: 'Languages I speak:', values: [] },
	7: {
		shiftTitle: 'Assistance',
		title: 'I would feel comfortable assisting patients with:',
		values: [],
	},
};

const CARE_PLAN_ADLS = {
	1: {
		shiftTitle: 'Personal Care',
		title: 'Activities willing to do:',
		values: ['Bed bound', 'Dementia'],
	},
	2: { title: 'Environment willing to work in:', values: [] },
	3: { title: 'Comfortable being in an environment with:', values: [] },
	4: {
		shiftTitle: 'Cooking',
		title: 'Meals I am able to cook:',
		values: [
			'Chinese',
			'French',

			'Indian',
			'Italian',
			'Japanese',
			'Korean',
			'Mexican',
			'Spanish',

			'Vietnamese',
		],
	},
	5: {
		shiftTitle: 'Transportation',
		title: 'Transportation:',
		values: ['Car', 'Public Transportation', 'Walk', 'Bike', 'Wheelchair', 'Gurney', 'Other'],
	},
	6: { title: 'Languages I speak:', values: [] },
	7: {
		shiftTitle: 'Assistance',
		title: 'I would feel comfortable assisting patients with:',
		values: [
			'Bed bound',
			'Dementia',
			'Alzheimer’s',
			'Parkinson’s',
			'ALS',
			'Autism',
			'Stroke',
			'Heart Disease',
			'Pulmonary Disease',
			'Kidney Disease',
			'Liver Disease',
			'Blindness',
			'Deafness',
			'Paraplegic',
			'Quadriplegic',
			'Other',
		],
	},
};
