import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const uploadProviderFrontBackImage = async ({ frontImg, backImg }) => {
	try {
		const query = `
			mutation {
				uploadProviderFrontBackImage(
					frontImg: "${frontImg}",
					backImg: "${frontImg}"
				) {
					id
				}
			}
		`;

		const data = await api.graph({ query });

		return data.uploadProviderFrontBackImage;
	} catch (err) {
		console.error('Error uploading Stripe documents:', err);
		throw err;
	}
};

export const useUploadStripeDocumentsMutation = () => {
	return useMutation({
		mutationKey: ['uploadProviderFrontBackImage'],
		mutationFn: (values) => {
			return uploadProviderFrontBackImage(values);
		},
	});
};
