import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import is from 'styled-is';
import Colors from '../constants/colors';

import Text from './Text';
import Title from 'AppComp/Title';

const LeftPadding = '0px';

import { device } from 'AppComp/styled/StyledMediaQuery';

import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';

const TableContainer = styled.div`
	background: #ffffff;

	position: relative;
	height: 100%;

	border-radius: 12px;
	padding: 20px 16px;
`;

const ColumnCon = styled.div`
	flex: ${({ flex }) => flex || '1'};
	display: flex;
	align-items: center;
`;

const Legend = styled(RowFlex)`
	padding-left: ${({ leftPadding }) => leftPadding || LeftPadding};
	padding-right: ${({ leftPadding }) => leftPadding || LeftPadding};
	background: ${Colors.grayWhite};
	background-color: #fafafa;
	height: 50px;
	align-items: center;
	width: 100%;
`;

const RowActionContainer = styled(RowFlex)`
	height: ${({ height }) => height || '75px'};
	background: ${Colors.fullWhite};
	align-items: center;
	margin-bottom: 15px;
	border-bottom: 1px solid ${Colors.grayWhite};
	cursor: pointer;
`;
//Removed from RowActionContainer
// -webkit-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
// -moz-box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);
// box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.25);

const RowAction = (props) => {
	const { children, height, onClick } = props;
	return (
		<RowActionContainer height={height} className='RowAction' onClick={onClick}>
			{children}
		</RowActionContainer>
	);
};

const TableBody = styled.div`
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	padding-left: ${({ leftPadding }) => leftPadding || LeftPadding};
	padding-right: ${({ leftPadding }) => leftPadding || LeftPadding};
	overflow: hidden;
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	background: ${Colors.grayWhite};
`;

const TableChildBody = styled.div`
	flex: 1;
	overflow-y: scroll;
`;
const ScrollContainer = styled.div`
	overflow-y: scroll;
`;

const Header = styled(RowFlex)`
	padding-left: ${({ leftPadding }) => leftPadding || LeftPadding};
	padding-right: ${({ leftPadding }) => leftPadding || LeftPadding};
	justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
	background-color: ${Colors.blue};
	align-items: center;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	height: 64px;
`;

export const PHWhiteCon = styled.div``;

export default class SetTable extends Component {
	static RowAction = RowAction;

	static ColumnCon = ColumnCon;

	render() {
		const { children, renderRows, renderLegend, title, justifyContent, leftPadding } =
			this.props;

		return (
			<TableContainer
				style={{
					flex: 1,
					minWidth: 'none',
				}}
			>
				{title && (
					<RowFlex
						style={{
							justifyContent: justifyContent || 'space-between',
						}}
						fullWidth
						spaceBetween
						margin='0 0 20px 0'
					>
						<Title subtitle color={Colors.greenLightTeal} fontWeight='700'>
							{title}
						</Title>
						{children}
					</RowFlex>
				)}
				<ColumnFlex
					style={{
						borderRadius: '16px',
						//backgroundColor: Colors.lightGrey,
						padding: '20px 0px 10px 00px',
					}}
					fullWidth
					margin='10px 0 12px 0'
					alignCenter
				>
					<Legend leftPadding={leftPadding}>{renderLegend()}</Legend>
					<TableBody leftPadding={leftPadding}>
						<ScrollContainer>{renderRows()}</ScrollContainer>
					</TableBody>
				</ColumnFlex>
			</TableContainer>
		);
	}
}
