import moment from 'moment';

export function formateMomentDate(timestamp, format) {
	//this assumes timestamp will always be in unix

	if (timestamp == null) {
		return null;
	} else {
		const date = moment.unix(timestamp).format(format);

		return date;
	}
}

export function copy(o) {
	var output;
	var v;
	var key;

	output = Array.isArray(o) ? [] : {};
	for (key in o) {
		v = o[key];
		output[key] = typeof v === 'object' ? copy(v) : v;
	}

	return output;
}

export function sortProviders(providers, key, increasing) {
	if (key === 'added_timestamp') {
		products = providers.map((d, i) => {
			d[key] = parseInt(d[key], 10);
			return d;
		});
	}

	const copy = providers.sort((a, b) => {
		//Equal items sort equally
		if (a[key] === b[key]) {
			return 0;
		} else if (a[key] === null) {
			return 1;
		} else if (b[key] === null) {
			return -1;
		} else if (increasing) {
			return a[key] < b[key] ? -1 : 1;
		} else {
			return a[key] < b[key] ? 1 : -1;
		}
	});

	return copy;
}

export const capitalizeFirstLetter = (string) => {
	if (string && string.length) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}
};

/* 
	helper func to find difference between two arrays of objects
	- used mainly for data set for updating only needed fields for the backend
*/
export const findDiff = (oldData, newData) => {
	// create basic look up

	console.log('oldData', oldData);
	console.log('newData', newData);

	const lookup = oldData.reduce((acc, item) => {
		acc[item.id] = item;
		return acc;
	}, {});

	// find the change from non null values

	// Find the differences
	console.log('lookup', lookup);
	const differences = newData.filter((item) => {
		// find the item in look up
		const ogItem = lookup[item.trait_id];

		if (ogItem.value !== item.value) {
			// Replace empty string with null
			if (item.value === '') {
				return { ...item, value: null };
			}
			return item;
		}
		return false;
	});

	// Handle cases where the value was removed

	console.log('differences', differences);

	return differences;
};

export const transcribeTraits = (traitsArray, referenceJSON) => {
	return traitsArray.reduce((acc, trait) => {
		// Check if value exists
		if (trait.value && trait.value.trait_value !== null) {
			// Remove double quotes from trait_value
			let traitValue = trait.value.trait_value.replace(/"/g, '');

			// Convert string 'true'/'false' to "Yes"/false
			if (traitValue === 'true') {
				traitValue = 'Yes';
			} else if (traitValue === 'false') {
				traitValue = false;
			}

			// Proceed only if traitValue is neither empty, "null", null after removing double quotes, nor false
			if (traitValue !== '' && traitValue !== 'null' && traitValue !== false) {
				let traitName = trait.label;

				// Search for the matching trait name and specific option in the reference JSON using the traitId
				for (let categoryKey in referenceJSON) {
					const category = referenceJSON[categoryKey];

					if (category.options) {
						// Check if the options object contains a 'default' key
						const optionsArray = category.options.default || category.options;

						const option = optionsArray.find((option) => option.traitId === trait.id);

						if (option) {
							switch (category.type) {
								case 'tags':
									traitName = `${option.name}`;
									break;
								default:
									traitValue = `${option.name}`;
									traitName = `${category.label}`;
									break;
							}

							break;
						}
					} else {
						traitName = `${category.label}`;
					}
				}

				acc.push({ trait: traitName, value: traitValue });
			}
		}
		return acc;
	}, []);
};
