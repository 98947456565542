import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Colors from 'colors';
import { Hearts } from 'react-loader-spinner';

import {
	faUserCircle,
	faHeart,
	faCalendar,
	faAddressBook,
	faCheck,
} from '@fortawesome/free-solid-svg-icons';

import { receivePatientCarePlan } from 'reducers/patient';

/*
 *  Client onboard parent handles
 *  * Showing the navigation/info bar at the top
 *  * Showing the correct onboarding step based on the current state of the user
 */

import { ColumnFlex, RowFlex, BackDropBg } from 'basicStyles';

import OnboardNav, { OnboardNavTile, NavDivider } from './OnboardNav';

export const STEP_PROGRESS_KEYS = {
	CARE_PLAN: 'CARE_PLAN',
	ACCOUNT: 'ACCOUNT',
	DOCUMENTS: 'DOCUMENTS',
	JOB_POST: 'JOB_POST',
};

export const STEP_PROGRESS_INIT = {
	CARE_PLAN: 0.1,
	ACCOUNT: 0.1,
	DOCUMENTS: 0.1,
	JOB_POST: 0.1,
};

import CarePlanOnboard from './CarePlanOnboard';
import ClientAccountOnboard from './ClientAccountOnboard';
import DocumentOnboard from './DocumentOnboard';
import JobPostOnboard from './JobPostOnboard';

const ClientOnboardParent = (props) => {
	const {
		patient,
		carePlan,
		history,
		clientDocumentRequirements,
		fetchingCarePlan,
		receivePatientCarePlan,
		uploadedDocuments,
		fetchingClientSession,
	} = props;

	const [onboardStep, setOnboardStep] = useState(1);

	const [stepsProgress, setStepsProgress] = useState(STEP_PROGRESS_INIT);

	const [loadingTing, setLoadingTing] = useState(true);

	useEffect(() => {
		// check if the carePlan has completed_platform_onboard to true

		// if so move the user to the home dashboard auto
		if (carePlan && carePlan.completed_platform_onboard) {
			history.push('/home');
		}
	}, [carePlan]);

	useEffect(() => {
		// check if the user is logged in
		console.log('patient', patient);

		// check if theres a user-token
		const token = localStorage.getItem('user-token');
		console.log('token', token);
		if (token === null) {
			setOnboardStep(1);
			setLoadingTing(false);
		} else {
			if (fetchingClientSession.attempts > 0) {
				if (patient) {
					if (patient.carePlan) {
						// we need to wait for the carePlan to be loaded
						if (fetchingCarePlan.attempts > 0) {
							if (carePlan) {
								renderCurrentStep();
							} else {
								console.log('something went wrong fetching carePlan');
							}
						}
					} else {
						renderCurrentStep();
					}
				} else {
					// we need to wait for the patient to be loaded

					setLoadingTing(false);
				}
			}
		}
	}, [fetchingCarePlan, fetchingClientSession]);

	const handleUpdateSectionProgress = (amount, key) => {
		console.log('amount', amount);
		console.log('key', key);

		const newProgress = { ...stepsProgress, [key]: amount };
		console.log('newProgress', newProgress);
		setStepsProgress(newProgress);
	};

	// determine which step to show based on the user current state
	const renderCurrentStep = () => {
		// first state is the user account info

		// this should be shown if the user has not created a account
		// if their is a local storage token and the redux state has user we can assume they have a account

		const tokenTest = localStorage.getItem('user-token');

		if (tokenTest == null) {
			setOnboardStep(1);
			setLoadingTing(false);
			return;
		}

		// next is checking if the careplan has been completed

		// the care plan will be created if the user gets to CarePlan Onboard and does not have a carePlan
		if (carePlan == null) {
			setOnboardStep(2);
			setLoadingTing(false);
			return;
		}

		if (carePlan && carePlan.completed_care_plan) {
			setOnboardStep(3);
			setLoadingTing(false);
			return;
		}

		// check if careplan profile is filled out
		if (carePlan && !carePlan.completed_care_plan) {
			setOnboardStep(2);
			setLoadingTing(false);
			return;
		}

		// check if careplan needs are filled out or at least has been filled with something
		if (carePlan && carePlan.careNeedsSet) {
			// if the setData has no values filled in we need to force the user to add a value to move forward

			// loop through carePlan.careNeedsSet.setData and check if any have a key called value that is not null
			const setData = carePlan.careNeedsSet.setData;

			const hasValues = setData.some((item) => item.value !== null);

			if (!hasValues) {
				setOnboardStep(2);
				setLoadingTing(false);
				return;
			}
		}

		// the documents dones not have to be down the user can always move forward with it and do it later
		// documents is part platform and agency

		// check if there a carePlan and it's completd
	};

	const renderView = () => {
		switch (onboardStep) {
			case 1:
				return (
					<ClientAccountOnboard
						handleUpdateSectionProgress={handleUpdateSectionProgress}
						renderCurrentStep={renderCurrentStep}
					/>
				);
			case 2:
				return (
					<CarePlanOnboard
						stepsProgress={stepsProgress}
						setStepsProgress={setStepsProgress}
						carePlan={carePlan}
						handleUpdateSectionProgress={handleUpdateSectionProgress}
						patient={patient}
						receivePatientCarePlan={receivePatientCarePlan}
						fetchingCarePlan={fetchingCarePlan}
						setOnboardStep={setOnboardStep}
					/>
				);

			case 3:
				return (
					<DocumentOnboard
						receivePatientCarePlan={receivePatientCarePlan}
						clientDocumentRequirements={clientDocumentRequirements}
						uploadedDocuments={uploadedDocuments}
						handleUpdateSectionProgress={handleUpdateSectionProgress}
						carePlan={carePlan}
					/>
				);
			case 4:
				return <JobPostOnboard />;
			default:
				return null;
		}
	};

	const handleNavTileClick = (id) => {
		//setOnboardStep(id);
		// based on what has been completed we can move the user to the selected step

		const toastErr = () => toast.error('Please complete previous steps');
		switch (id) {
			case 1:
				// can always go back to 1
				setOnboardStep(1);
				break;
			case 2:
				// can only move here if we've completed step 1
				// check if progress is 1 for step 1
				if (stepsProgress.ACCOUNT === 1) {
					setOnboardStep(2);
				} else toastErr();

				break;
			case 3:
				// can only move here if we've completed step 2
				// check if progress is 1 for step 2
				if (stepsProgress.CARE_PLAN === 1) {
					setOnboardStep(3);
				} else toastErr();
				break;
		}
	};

	return (
		<ColumnFlex
			fullWidth
			padding='16px'
			style={{
				backgroundColor: Colors.theme.primary_background,
				minHeight: 'calc(100vh - 120px)',
			}}
			alignCenter
			gap='32px'
		>
			{loadingTing ? (
				<Hearts />
			) : (
				<>
					<OnboardNav
						onboardStep={onboardStep}
						setOnboardStep={setOnboardStep}
						stepsProgress={stepsProgress}
						handleNavTileClick={handleNavTileClick}
					>
						<OnboardNavTile
							title='Account'
							icon={faUserCircle}
							isCurrentView={onboardStep == 1}
							percentCompleted={stepsProgress[STEP_PROGRESS_KEYS.ACCOUNT]}
							onboardStepsIndex={1}
							handleClick={handleNavTileClick}
							onboardStep={onboardStep}
						/>
						<NavDivider />

						<OnboardNavTile
							title='CarePlan'
							icon={faHeart}
							isCurrentView={onboardStep == 2}
							percentCompleted={stepsProgress[STEP_PROGRESS_KEYS.CARE_PLAN]}
							onboardStepsIndex={2}
							handleClick={handleNavTileClick}
							onboardStep={onboardStep}
						/>
						<NavDivider />
						<OnboardNavTile
							title='Documents'
							icon={faAddressBook}
							isCurrentView={onboardStep == 3}
							percentCompleted={stepsProgress[STEP_PROGRESS_KEYS.DOCUMENTS]}
							onboardStepsIndex={3}
							handleClick={handleNavTileClick}
							onboardStep={onboardStep}
						/>
					</OnboardNav>
					{renderView()}
				</>
			)}
		</ColumnFlex>
	);
};

const mapStateToProps = ({ patient, admin }) => {
	return {
		carePlan: patient.carePlan,
		patient: patient.patient,
		uploadedDocuments: patient.uploadedDocuments,
		clientDocumentRequirements: admin.clientDocumentRequirements,
		fetchingClientSession: patient.fetchingClientSession,
		fetchingCarePlan: patient.fetchingCarePlan,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		receivePatientCarePlan: (carePlan) => dispatch(receivePatientCarePlan(carePlan)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientOnboardParent);
