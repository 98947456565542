import React, { useEffect } from 'react';
import styled from 'styled-components';
import Title from '../../Title';
import { Formik, Form, Field } from 'formik';
import Colors from '../../../constants/colors';
import {
	MARGIN_TITLE,
	FormItemsLength,
	FORM_ITEM_LENGTH,
	OBFormButtonText,
	OBBasicCon,
} from './OnboardViews';
import { ColumnFlex } from '../../styled/Basic';

import { RELATION } from 'constants';

import { validate1 } from './validation';
export const LandingImage = styled.div`
	width: 400px;
	height: 300px;
	background-image: url('https://find-your-independents.s3.amazonaws.com/images/landingImageV2.png');
	background-repeat: no-repeat;

	background-position: center;
	border-radius: 40px;
`;

import StyledTextInput, { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';

const OB1CareType = (props) => {
	const { handleDataUpdate, userAccountInfo } = props;

	const handleSubmit = (values, form) => {
		const updateData = {
			relationship_to_care_plan: values.relation_to_client,
			first_name: values.first_name,
			last_name: values.last_name,
		};

		handleDataUpdate(updateData, 2);
	};

	return (
		<OBBasicCon>
			<Title className='txtCenter' margin={MARGIN_TITLE} subtitle>
				Tell us about yourself
			</Title>
			<Formik
				onSubmit={handleSubmit}
				validate={validate1}
				autoComplete={'off'}
				validateOnMount
				enableReinitialize={true}
				render={(formProps) => {
					return (
						<Form noValidate>
							<ColumnFlex
								margin='24px 0 0 0'
								alignCenter
								justifyCenter
								fullWidth
								fullHeight
							>
								{/* <FormItemsLength width={'400px'}>
									<Field
										name='relation_to_client'
										component={StyledTextSelectInputV2}
										label='Relationship to the person who needs care'
										options={RELATION}
										backgroundColor='white'
									/>
								</FormItemsLength> */}

								<FormItemsLength width={FORM_ITEM_LENGTH}>
									<Field
										name='first_name'
										component={StyledTextInput}
										label='First Name'
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength width={FORM_ITEM_LENGTH}>
									<Field
										name='last_name'
										component={StyledTextInput}
										label='Last Name'
										backgroundColor='white'
									/>
								</FormItemsLength>

								<OBFormButtonText
									backgroundColor={Colors.teal}
									type={'submit'}
									valid={formProps.isValid}
									text='Next'
								/>
							</ColumnFlex>
						</Form>
					);
				}}
			/>
		</OBBasicCon>
	);
};

export default OB1CareType;
