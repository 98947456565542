import React, { useState } from 'react';

import { ColumnFlex } from 'basicStyles';

import {
	OnboardFormContainer,
	OnboardTitle,
	OnboardSubText,
	OnboardButton,
} from './CareProOnboardViews';

const ConfirmThings = (props) => {
	console.log('setPlatformOnboard, setPlatformOnboar', props);
	const { setPlatformOnboard } = props;

	const handleContinue = () => {
		setPlatformOnboard();
	};

	return (
		<OnboardFormContainer
			style={{
				width: 'fit-content',
			}}
			center
		>
			<OnboardTitle>Welcome!</OnboardTitle>
			<ColumnFlex
				style={{
					maxWidth: '400px',
				}}
				center
				gap='30px'
			>
				<OnboardSubText>
					Before you can apply to jobs we may ask for some additional information and the
					registry will need to approve you.
				</OnboardSubText>
				<OnboardSubText>
					- You'll need to upload documents required by the registry
				</OnboardSubText>
				<OnboardSubText>
					- Complete your profile listing your skills and experience
				</OnboardSubText>

				<OnboardSubText>
					Please be patient as we work to get you approved as quickly as possible
				</OnboardSubText>
			</ColumnFlex>
			<OnboardButton onClick={() => handleContinue()}>Lets go</OnboardButton>
		</OnboardFormContainer>
	);
};

export default ConfirmThings;
