import io from 'socket.io-client';
import { eventChannel } from 'redux-saga';
import {
	all,
	apply,
	delay,
	call,
	cancel,
	fork,
	put,
	take,
	select,
	takeEvery,
	race,
	cancelled,
} from 'redux-saga/effects';
import { receivePatientConversations } from 'reducers/patient';
import {
	receiveCarePlansAction,
	receiveConversations,
	receiveSelectedConversation,
} from 'reducers/admin';
import {
	receiveProviderConversations,
	checkProviderVerification,
	receiveVerification,
	searchProvidersByCarePlan,
} from 'reducers/provider';
import { toast } from 'react-toastify';

import { SITE_ROOT } from './constants';

function connect() {
	const socket = io(SITE_ROOT);

	return new Promise((resolve) => {
		socket.on('connect', () => {
			resolve(socket);
		});
	});
}

//name should be the channel the socket should listen too
function subscribe(socket, socketName) {
	return eventChannel((emmiter) => {
		const socketMsg = (event) => {
			emmiter({
				event: socketName,
				data: event,
			});
		};
		socket.on(socketName, socketMsg);

		const unsubscribe = () => {
			socket.off(socketName, socketMsg);
		};

		return unsubscribe;
	});
}

/**
 ** Redux store select sagas
 **/
const patientState = ({ patient }) => patient;
const adminState = ({ admin }) => admin;
const providerState = ({ provider }) => provider;

/**************************************/

function* listenToUserChannel(socket, socketName) {
	const channel = yield call(subscribe, socket, socketName);
	try {
		while (true) {
			const payload = yield take(channel);

			// get the current state of conversations
			const { conversations } = yield select(patientState);

			console.log('payload', payload);

			// ensure convo exist for this user by id matching
			const convo = conversations.find((d) => d.id === payload.data.payload.conversation_id);
			if (convo) {
				// update the found convo by appending latest message
				const updatedConvo = [...convo.messages, payload.data.payload];
				const updatedConversation = {
					...convo,
					latest_message: payload.data.payload.text,
					latest_message_timestamp: parseInt(payload.data.payload.timestamp),
					messages: updatedConvo,
				};

				//todo update the convo model with latest message and new message

				// ensure to update the list of conversations
				const conversationsUpdated = conversations.map((d) => {
					if (d.id === updatedConversation.id) {
						return updatedConversation;
					} else return d;
				});

				// update the state of conversations
				yield put(receivePatientConversations(conversationsUpdated));
			} else {
				console.log('could not find convo');
			}
		}
	} finally {
		if (yield cancelled()) {
			channel.close();
		}
	}
}

function* listenForPatient() {
	// let tasks = [];
	// try {
	// 	const socket = yield call(connect);
	// 	const { conversations } = yield select(patientState);

	// 	tasks = yield all(
	// 		conversations.map(({ id }) => fork(listenToUserChannel, socket, `conversation-${id}`))
	// 	);
	// } finally {
	// 	if (yield cancelled()) {
	// 		console.log('listenForPatient -> tasks', tasks);
	// 		yield take(['PATIENT_LOGOUT', 'PATIENT_CONVERSATIONS_UPDATE']);
	// 		// Cancel all listening tasks on logout
	// 		yield all(tasks.map((task) => cancel(task)));
	// 	}
	// }

	//console.log('listenForPatient -> conversation', conversation);

	//const socketNames = conversations.map((d) => `conversation-${d.id}`);

	const socket = yield call(connect);
	const { conversations } = yield select(patientState);

	const socketNames = conversations.map((d) => `conversation-${d.id}`);

	const tasks = yield all(
		conversations.map(({ id }) => fork(listenToUserChannel, socket, `conversation-${id}`))
	);

	yield take('PATIENT_LOGOUT');
	// Cancel all listening tasks on logout
	yield all(tasks.map((task) => cancel(task)));
}

export function* watchPatientActions() {
	let task;

	while (true) {
		const action = yield take(['PATIENT_LOGIN', 'PATIENT_CONVERSATION_UPDATED']);

		if (task) {
			yield cancel(task);
		}

		// If it's a login or conversation update, restart the listenForPatient saga
		if (action.type === 'PATIENT_LOGIN' || action.type === 'PATIENT_CONVERSATIONS_UPDATED') {
			task = yield fork(listenForPatient);
		}
	}
}

function* listenToCareProChannel(socket, socketName) {
	const channel = yield call(subscribe, socket, socketName);
	try {
		while (true) {
			const payload = yield take(channel);
			console.log('payload', payload);
			// Process payload here, e.g., dispatch an action

			// get the current state of conversations
			const { conversations } = yield select(providerState);

			const convo = conversations.find((d) => d.id === payload.data.payload.conversation_id);
			if (convo) {
				const updatedConvo = [...convo.messages, payload.data.payload];
				const updatedConversation = {
					...convo,
					latest_message: payload.data.payload.text,
					latest_message_timestamp: parseInt(payload.data.payload.timestamp),
					messages: updatedConvo,
				};

				// ensure to update the list of conversations

				const conversationsUpdated = conversations.map((d) => {
					if (d.id === updatedConversation.id) {
						return updatedConversation;
					} else return d;
				});

				yield put(receiveProviderConversations(conversationsUpdated));
			} else {
				console.log('could not find convo');
			}
		}
	} finally {
		if (yield cancelled()) {
			channel.close();
		}
	}
}

function* listenToProvider() {
	const socket = yield call(connect);
	const { conversations } = yield select(providerState);

	const socketNames = conversations.map((d) => `conversation-${d.id}`);

	const tasks = yield all(
		conversations.map(({ id }) => fork(listenToCareProChannel, socket, `conversation-${id}`))
	);

	yield take('PROVIDER_LOGOUT');
	// Cancel all listening tasks on logout
	yield all(tasks.map((task) => cancel(task)));
}

async function* pollProviderOnboarding() {
	try {
		while (true) {
			const res = yield call(checkProviderVerification);
			console.log('polling');

			console.log('checkProviderVerification -> res', res);
			put(receiveVerification(res.checkProviderSession.verification));
			yield delay(5000);
		}
	} catch (err) {
		console.log('pollProviderOnboarding -> err', err);
	}
}
function* listenForAdmin() {
	const socket = yield call(connect);
	const { admin } = yield select(adminState);

	const socketName = 'admin';

	const channel = yield call(subscribe, socket, socketName);

	while (true) {
		const { logoutAction, socketPayload } = yield race({
			logoutAction: take('ADMIN_LOGOUT'),
			socketPayload: take(channel),
		});

		if (logoutAction) {
			channel.close();
		} else {
			console.log('socketPayload admin', socketPayload);
			const { payload, type } = socketPayload.data;

			const { carePlans, conversations, selectedConversation } = yield select(adminState);

			//console.log('payload', payload);
			if (type === 'new_message') {
				//toast.info(payload)
				//console.log('adminConversation');
				const _tempConvos = conversations.map((d, i) => {
					if (d.id === payload.conversation.id) {
						return {
							...payload.conversation,
							messages: [payload.message],
						};
					} else return d;
				});
				//console.log(_tempConvos);
				yield put(receiveConversations(_tempConvos));

				if (selectedConversation && selectedConversation.id === payload.conversation.id) {
					const _updatdAdminConvo = {
						...selectedConversation,
						messages: [...selectedConversation.messages, payload.message],
					};

					yield put(receiveSelectedConversation(_updatdAdminConvo));
				}
			}
			if (type === 'admin-new-carePlan') {
				const newCarePlan = [...carePlans, payload];

				yield put(receiveCarePlansAction(newCarePlan));
			}
		}
	}
}

export function* watchProviderLogin() {
	yield takeEvery('PROVIDER_LOGIN', listenToProvider);
}

export function* watchProviderOnboard() {
	while (true) {
		yield take('START_PROVIDER_ONBOARD');
		yield race([call(pollProviderOnboarding), take('STOP_PROVIDER_ONBOARD')]);
	}
}

export function* watchAdminLogin() {
	yield takeEvery('ADMIN_LOGIN', listenForAdmin);
}

export function* rootSaga() {
	yield all([
		watchPatientActions(),
		watchAdminLogin(),
		watchProviderLogin(),
		watchProviderOnboard(),
	]);
}
