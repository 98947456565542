import React, { useState, useEffect } from 'react';
import { Hearts } from 'react-loader-spinner';
import Colors from 'colors';
import { useFormik, FieldArray, FormikProvider } from 'formik-latest';

import {
	StyledSkillsPage,
	SkillsContainer,
	SkillsFormCon,
	StyledPageHeadingContainer,
	PageHeading,
	LoaderSection,
} from '../styles';
import {
	TextAreaFormComp,
	RadioFormComp,
	ManageSectionContent,
	FormWrapper,
	TextFormComp,
	SelectFormComp,
	CheckboxFormComp,
	SubmitButton,
	TimeInputComp,
} from '../styles/FormGen';

const TRAIT_TYPES = [
	{
		value: 'TEXT',
		label: 'Text',
	},
	{
		value: 'RADIO_INPUT',
		label: 'Radio Input (Single Selection)',
	},
	{
		value: 'SELECT_MULTI_INPUT',
		label: 'Select (Multi Selection)',
	},
	{
		value: 'TEXT_AREA',
		label: 'Text Area',
	},
	{
		value: 'DROP_DOWN_SELECT',
		label: 'Drop Down Select (Single Selection)',
	},
	{
		value: 'DATE_INPUT',
		label: 'Date Input',
	},
	{
		value: 'TIME_INPUT',
		label: 'Time Input',
	},
];

function debounce(func, delay) {
	let timeoutId;

	return function (...args) {
		// Clear the previous timer if this function is called again before the delay is over
		if (timeoutId) {
			clearTimeout(timeoutId);
		}

		// Set a new timer to call the function after the delay
		timeoutId = setTimeout(() => {
			func.apply(this, args);
		}, delay);
	};
}

const SkillsFormGen = (props) => {
	const { formData, handleSubmit } = props;

	const [traitLookUp, setTraitLookUp] = useState({});

	const [answersLookUp, setAnswersLookUp] = useState({});

	useEffect(() => {
		// create a lookup object for the traits so easy to get their validation rules
		const sections = formData.trait_template.sections;

		const traitLookUp = sections.reduce((acc, section) => {
			section.traits.forEach((trait) => {
				const name = trait.id;

				acc[name] = trait;
			});
			console.log(acc);
			return acc;
		}, {});

		console.log('SkillsFormGen -> traitLookUp', traitLookUp);
		setTraitLookUp(traitLookUp);

		// create a lookup object for the answers so we can set their inital value
	}, [formData]);

	const validateForm = (values) => {
		console.log('validateForm', values);

		const errors = {};

		Object.keys(traitLookUp).forEach((name) => {
			const value = values[name];

			const error = validateField(name, value);

			if (error) {
				errors[name] = error;
			}
		});

		return errors;
	};

	const generateInitialValues = () => {
		const sections = formData.trait_template.sections;

		const initialValues = sections.reduce((acc, section) => {
			section.traits.forEach((trait) => {
				const name = trait.id;
				acc[name] = '';
			});

			return acc;
		}, {});

		// loop through the answers and see if any exisit and add them to the triat id

		const answers = formData.form_answers;
		answers.forEach((answer) => {
			const { trait_id, trait_value } = answer;

			initialValues[trait_id] = JSON.parse(trait_value);
		});

		return initialValues;
	};

	const formik = useFormik({
		initialValues: generateInitialValues(),
		onSubmit: (values) => handleFormGenSubmit(values),
		validate: validateForm,
		validateOnChange: false,
		enableReinitialize: true,
		validateOnBlur: false,
		//validationSchema: validateForm,
	});

	const handleFormGenSubmit = (values) => {
		// prep the values to be an array of objects with 3 fields
		/* 
      version: 1
      value: string or array of strings
      type: string or array
    */

		const formValues = Object.keys(values).map((key) => {
			const trait = traitLookUp[key];
			const traitType = TRAIT_TYPES.find((t) => t.value === trait.trait_type);

			let value = values[key];
			let type = traitType.value;

			let trait_value_list = [];

			if (traitType.value !== 'SELECT_MULTI_INPUT') {
				trait_value_list = [value];
			} else {
				trait_value_list = value;
			}

			// we still need to add the check for pulling exisitng answers
			return {
				trait_id: Number(key),
				version: 1,
				trait_value_list,
				trait_type: type,
			};
		});

		console.log('formValues', formValues);
		// return the value to the parent component

		handleSubmit(formValues);
	};

	const renderSection = () => {
		const sections = formData.trait_template.sections;

		// if section does not have any traits in them we should not render them
		const renderedSections = sections.filter((section) => section.traits.length > 0);

		return renderedSections.map((d) => {
			return (
				<SkillsContainer key={d.id}>
					<SkillsFormCon>
						{d.label !== 'NO_NAME' && <h2> {d.label}</h2>}
						{renderTraits(d.traits, d.id)}
					</SkillsFormCon>
				</SkillsContainer>
			);
		});
	};

	const renderTraits = (traits, sectionId) => {
		return traits.map((trait) => {
			return renderTrait(trait, sectionId);
		});
	};

	// Debounce the API call with a 500ms delay
	const debouncedUpdateAPI = debounce(async (name, value) => {
		try {
			//await updateAPI(name, value);
			console.log('API should be called for new field successful');
		} catch (error) {
			console.error('API update failed:', error);
		}
	}, 2000);

	const handleChange = async (e) => {
		// First, let Formik handle the change
		formik.handleChange(e);

		// Then, extract the name and value from the event
		const { name, value } = e.target;

		// here we'll do the individual update
		debouncedUpdateAPI(name, value);
	};

	const validateField = (name, value) => {
		let error = null;

		const trait = traitLookUp[name];
		console.log('validateField -> name', name);
		console.log('validateField -> trait', trait);
		if (!trait) {
			return error;
		}

		const { required } = trait;

		// required field validation
		if (required && !value) {
			error = 'This field is required';
		}

		return error;
	};
	const renderTrait = (trait, sectionId) => {
		// we need the section id so we can clean up the index and easily pass it ot the api call since we probably will get the id info from the formik name key

		const { active, id, description, label, meta_data, required, section_id, trait_type } =
			trait;

		if (!active) {
			return null;
		}
		const name = id;
		// ensure we have support the trait type
		const traitType = TRAIT_TYPES.find((t) => t.value === trait_type);
		if (!traitType) {
			return null;
		}
		// if we make it here we can assume all is well so far

		// render the trait based on the trait type

		let options = [];

		if (meta_data && meta_data !== 'null') {
			const metaData = JSON.parse(meta_data);

			if (metaData.options) {
				options = metaData.options;
			}
		}

		switch (traitType.value) {
			case 'TEXT':
				return (
					<TextFormComp
						key={name}
						title={label}
						onChange={handleChange}
						onBlur={formik.handleBlur}
						name={name}
						value={formik.values[name]}
						error={formik.errors[name]}
					/>
				);
			case 'RADIO_INPUT':
				const metaData = JSON.parse(meta_data);

				return (
					<RadioFormComp
						key={name}
						name={name}
						onChange={handleChange}
						onBlur={formik.handleBlur}
						value={formik.values[name]}
						title={label}
						options={options}
						error={formik.errors[name]}
					/>
				);
			case 'SELECT_MULTI_INPUT':
				return (
					<CheckboxFormComp
						key={name}
						name={name}
						onChange={handleChange}
						onBlur={formik.handleBlur}
						value={formik.values[name]}
						title={label}
						options={options}
						error={formik.errors[name]}
					/>
				);
			//return renderSelectMultiInputTrait(trait);
			case 'TEXT_AREA':
				return (
					<TextAreaFormComp
						key={name}
						name={name}
						onChange={handleChange}
						onBlur={formik.handleBlur}
						value={formik.values[name]}
						title={label}
						placeholder='Enter a description'
						error={formik.errors[name]}
					/>
				);
			case 'TIME_INPUT':
				return (
					<TimeInputComp
						key={name}
						name={name}
						onChange={handleChange}
						onBlur={formik.handleBlur}
						value={formik.values[name]}
						title={label}
						placeholder='Enter a description'
						error={formik.errors[name]}
					/>
				);
			case 'DATE_INPUT':
				return (
					<TimeInputComp
						type={'datetime-local'}
						key={name}
						name={name}
						onChange={handleChange}
						onBlur={formik.handleBlur}
						value={formik.values[name]}
						title={label}
						placeholder='Enter a description'
						error={formik.errors[name]}
					/>
				);
			//return renderTextAreaTrait(trait);
			//case 'DROP_DOWN_SELECT':
			//return renderDropDownSelectTrait(trait);

			default:
				return null;
		}
	};

	console.log('formik', formik);
	return (
		<StyledSkillsPage>
			<StyledPageHeadingContainer>
				<PageHeading>{formData.trait_template.template_label}</PageHeading>
			</StyledPageHeadingContainer>
			<ManageSectionContent>
				<FormWrapper onSubmit={formik.handleSubmit}>
					{renderSection()}
					{formik.dirty && (
						<SubmitButton
							type='submit'
							style={{
								margin: '0 auto',
							}}
						>
							<p>Submit</p>
						</SubmitButton>
					)}
				</FormWrapper>
			</ManageSectionContent>
		</StyledSkillsPage>
	);
};

export default SkillsFormGen;
