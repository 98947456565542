import React from 'react';
import { Route, Switch } from 'react-router-dom';

import CareProProfilePage from '../pages/CareProProfilePage';

import { profileRoutes } from './constants';

const ProfileRoutes = () => {
	return (
		<Switch>
			<Route path={profileRoutes.careProProfile(':careProId')}>
				<CareProProfilePage />
			</Route>
		</Switch>
	);
};

export default ProfileRoutes;
