import React from 'react';

import Text from '../../../../../corev2/Text';

import {
	StyledDocumentsListContainer,
	StyledDocumentsListWrapper,
	StyledTitle,
	StyledDocumentList,
	StyledDocument,
	StyledDocumentTitle,
	StyledDocumentDate,
	StyledDocumentRejectionNote,
} from './styles';

import { truncateText } from '../../../core/lib';

const StripeDocumentsList = (props) => {
	const { title, documents } = props;

	return (
		<StyledDocumentsListContainer>
			<StyledDocumentsListWrapper>
				<StyledTitle>{title}</StyledTitle>

				<StyledDocumentList>
					{documents.map((document, index) => (
						<StyledDocument key={index}>
							<StyledDocumentTitle href={document.url} target='_blank'>
								{truncateText(document.name, 30)}
							</StyledDocumentTitle>

							<StyledDocumentDate>
								Uploaded on:{' '}
								{new Date(document.created_at * 1000).toLocaleString('en-US', {
									month: 'long',
									day: 'numeric',
									year: 'numeric',
									hour: 'numeric',
									minute: 'numeric',
									hour12: true,
								})}
							</StyledDocumentDate>

							{document.expiration_date && (
								<StyledDocumentDate>
									Expriring on:{' '}
									{new Date(document.expiration_date).toLocaleString('en-US', {
										month: 'long',
										day: 'numeric',
										year: 'numeric',
										hour: 'numeric',
										minute: 'numeric',
										hour12: true,
									})}
								</StyledDocumentDate>
							)}
						</StyledDocument>
					))}

					{documents.length === 0 && <Text>No documents found.</Text>}
				</StyledDocumentList>
			</StyledDocumentsListWrapper>
		</StyledDocumentsListContainer>
	);
};

export default StripeDocumentsList;
