import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../../corev2/utils';

export const StyledCardContainer = styled.div`
	font-family: -apple-system, 'system-ui', Segoe UI, roboto, helvetica, arial, sans-serif,
		Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
	width: 300px;
	z-index: 1000;
	position: absolute;
	top: 62px;
	right: 10px;
	background-color: ${colors.white};
	box-sizing: border-box;
	border: 1px solid ${colors.borderColor};
	border-radius: 10px;
`;

export const StyledUserInfo = styled.div`
	text-align: left;
	padding: 15px;
`;

export const StyledUserName = styled.h2`
	font-size: 1.2em;
	margin: 0;
`;

export const StyledUserEmail = styled.p`
	font-size: 0.9em;
	color: ${colors.gray};
	margin: 5px 0;
`;

export const StyledNavMenu = styled.div`
	display: flex;
	justify-content: space-around;
	align-items: center;
	margin: 0;
	box-sizing: border-box;
	background-color: ${colors.table.alternative} !important;
	width: 100%;
	text-align: left;
`;

export const StyledMenuItem = styled.button`
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 15px;
	padding-top: 16px;
	padding-bottom: 16px;
	color: ${colors.primary.primary};
	line-height: 1.7;
	width: 100%;
	background: none;
	border: none;

	svg {
		margin-bottom: 5px;
		font-size: 1.2em;
	}
`;

export const StyledMenuLink = styled.span``;

export const StyledSectionLinks = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	box-sizing: border-box;
	text-align: left;
	width: 100%;
`;

export const StyledSectionLink = styled.button`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	color: ${colors.primary.primary};
	box-sizing: border-box;
	text-decoration: none;
	width: 100%;
	padding: 8px 16px;
	background: ${colors.white};
	border-left: none;
	border-right: none;
	border-top: none;

	&:last-child {
		border-bottom: 1px solid ${colors.borderColor};
	}

	div {
		padding: 2px 0px;
	}
`;

export const StyledLink = styled.span``;

export const StyledButtonContainer = styled.div`
	width: 100%;
	box-sizing: border-box;
	padding: 8px 16px;
	display: flex;
	justify-content: center;
`;

export const StyledLogoutButton = styled.button`
	width: 100%;
	padding: 7px 16px;
	margin: 8px 0;
	border: 2px solid ${colors.primary.primary};
	background-color: transparent;
	color: #0073e6;
	font-size: 1em;
	border-radius: 25px;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;

	&:hover {
		background-color: #0073e6;
		color: white;
	}
`;
