import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { PAYMENT_METHOD_MODEL } from '../../../core/api/models';

const fetchUserSavedPaymentMethodsQuery = async () => {
	try {
		const data = await api.graph({
			query: `{
				checkUserSession{
					id,
					payment_methods {
						${PAYMENT_METHOD_MODEL}
					},
				}
			}`,
		});

		return data.checkUserSession;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchUserSavedPaymentMethodsQuery = () => {
	return useQuery({
		queryKey: ['fetchUserSavedPaymentMethodsQuery'],
		queryFn: () => {
			return fetchUserSavedPaymentMethodsQuery();
		},
	});
};
