import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchClientProfile } from 'reducers/provider';

import Wrapper from '../../core/Wrapper';
import Profiles from '../../core/Profiles';

import needs from 'constants/needs.json';

import { transcribeTraits } from 'scripts';

import { StyledClientProfileContainer } from './styles';

const ClientsPage = (props) => {
	const { client, fetchClientProfile } = props;
	const [traits, setTraits] = useState([]);

	const { clientId } = useParams();

	useEffect(() => {
		fetchClientProfile(clientId);
	}, []);

	useEffect(() => {
		if (client) {
			setTraits(transcribeTraits(client.carePlan.careNeedsSet.setData, needs));
		}
	}, [client]);

	return (
		<Wrapper>
			<StyledClientProfileContainer>
				<Profiles.ClientProfile {...client} traits={traits} />
			</StyledClientProfileContainer>
		</Wrapper>
	);
};

const mapStateToProps = ({ provider }) => {
	return {
		client: provider.client,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchClientProfile: (clientId) => dispatch(fetchClientProfile(clientId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsPage);
