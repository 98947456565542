export const filterParser = (customParser, filters, name, option, selectValue, customInput) => {
	let newFilters = filters;
	let newFilter = newFilters[name];
	let newFilterOption;
	let newFilterOptionsList;
	let newOptionIndex;

	switch (newFilter.type) {
		case 'tags':
			newFilterOption = newFilter.options.filter(
				(iteratedOption) => iteratedOption.name === option
			);

			newFilterOption = newFilterOption[0];
			newFilterOptionsList = newFilter.options;
			newFilterOption = {
				...newFilterOption,
				selected: !newFilterOption.selected,
			};

			newOptionIndex = newFilter.options.findIndex(
				(iteratedOption) => iteratedOption.name === newFilterOption.name
			);

			newFilterOptionsList[newOptionIndex] = newFilterOption;
			newFilter = { ...newFilter, options: newFilterOptionsList };
			newFilters = { ...newFilters, [name]: newFilter };
			break;

		case 'drop-down':
			if (customInput !== undefined && customInput !== '') {
				newFilter = {
					...newFilter,
					customValue: customInput,
				};

				newFilters = { ...newFilters, [name]: newFilter };
				break;
			}

			newFilterOption = newFilter.options[option].filter((iteratedOption) => {
				return iteratedOption.name === selectValue;
			});

			newFilterOption = newFilterOption[0];
			newFilterOptionsList = newFilter.options[option];
			newFilterOption = {
				...newFilterOption,
				selected: !newFilterOption.selected,
			};

			newOptionIndex = newFilter.options[option].findIndex(
				(iteratedOption) => iteratedOption.name === newFilterOption.name
			);

			const tempNewFilterOptionsList = newFilterOptionsList.map((option) => {
				return { ...option, selected: false };
			});

			tempNewFilterOptionsList[newOptionIndex] = newFilterOption;

			newFilter = {
				...newFilter,
				options: {
					...newFilter.options,
					[option]: tempNewFilterOptionsList,
				},
			};

			newFilters = { ...newFilters, [name]: newFilter };
			break;

		case 'text':
			newFilter = {
				...newFilter,
				customValue: customInput,
			};

			newFilters = { ...newFilters, [name]: newFilter };
			break;

		case 'slider':
			newFilter = {
				...newFilter,
				minValue: selectValue.minValue,
				maxValue: selectValue.maxValue,
				active: true,
			};

			newFilters = { ...newFilters, [name]: newFilter };
	}

	return { newFilters, parsedData: customParser(newFilters) };
};
