import React from 'react';
import JobStat from './JobStat';

import { StyledJobStats } from './styles';

const JobStats = (props) => {
	const { stats, changeCareProStatusParam } = props;

	return (
		<StyledJobStats>
			{Object.values(stats).map((stat, index) => (
				<JobStat
					key={index}
					title={stat.title}
					stat={stat.stat}
					isActive={stat.isActive}
					goToPage={() => changeCareProStatusParam(stat.param)}
				/>
			))}
		</StyledJobStats>
	);
};

export default JobStats;
