import React from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';

import {
	StyledCalendarContainer,
	StyledCalendarWrapper,
	StyledCustomReactBigCalendarWrapper,
} from './styles';

moment.updateLocale('en', { week: { dow: 0 } });

const localizer = momentLocalizer(moment);

const Calendar = ({
	events,
	currentDate,
	view,
	onNavigate,
	onView,
	onSelectEvent,
	eventStyleGenerator,
}) => {
	return (
		<StyledCalendarContainer>
			<StyledCalendarWrapper>
				<StyledCustomReactBigCalendarWrapper>
					<BigCalendar
						localizer={localizer}
						events={events}
						date={currentDate}
						view={view}
						onView={onView}
						onNavigate={onNavigate}
						toolbar={false}
						selectable
						onSelectEvent={onSelectEvent}
						eventPropGetter={eventStyleGenerator}
						views={['month', 'week', 'day', 'agenda']}
						style={{ width: '100%' }}
					/>
				</StyledCustomReactBigCalendarWrapper>
			</StyledCalendarWrapper>
		</StyledCalendarContainer>
	);
};

export default Calendar;
