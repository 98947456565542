import React, { Component } from 'react';
import styled from 'styled-components';

import Colors from 'colors';

import SetTable from 'AppComp/SetTable';
import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import SearchDropDownHandler from 'AppComp/SearchDropDownHandler';

const Container = styled.div`
	background-color: ${Colors.fullWhite};
	border-radius: 4px;
	width: 600px;
	height: 85%;
	margin-left: 32px;
`;

export default class PatientSearchController extends Component {
	state = {
		options: [],
	};

	componentDidMount() {
		const { searchPatients } = this.props;
		searchPatients();
	}
	handleChange = (selectedOption) => {
		this.setState({ selectedOption: selectedOption.value });
	};

	handleSearchSubmit = (input) => {
		const { searchPatients } = this.props;

		searchPatients(input);
	};

	loadOptions = (inputValue) => null;

	renderSearchBar = () => {
		const { selectedOption, options } = this.state;

		return (
			<Container>
				<SearchDropDownHandler
					options={options}
					handleChange={this.loadOptions}
					handleSearchSubmit={this.handleSearchSubmit}
					placeholder='Search Patients'
					showSuggestions
				/>
			</Container>
		);
	};

	renderRows = () => {
		const { patients, fetchPatient, goToClient } = this.props;

		if (patients.length === 0) {
			return (
				<SetTable.ColumnCon>
					<Text color='#2D2D2D' fontWeight='100' mediumText margin='16px'>
						Search for Providers
					</Text>
				</SetTable.ColumnCon>
			);
		} else {
			return patients.map((d, i) => {
				return (
					<SetTable.RowAction>
						<SetTable.ColumnCon>
							<Text color='#2D2D2D' fontWeight='100' mediumText>
								{d.name}
							</Text>
						</SetTable.ColumnCon>
						<SetTable.ColumnCon>
							<Text color='#2D2D2D' fontWeight='100' mediumText>
								{d.email}
							</Text>
						</SetTable.ColumnCon>
						<SetTable.ColumnCon>
							<Text color='#2D2D2D' fontWeight='100' mediumText>
								{d.phone_number}
							</Text>
						</SetTable.ColumnCon>
						<SetTable.ColumnCon>
							<Text color='#2D2D2D' fontWeight='100' mediumText>
								{d.address}
							</Text>
						</SetTable.ColumnCon>
						<SetTable.ColumnCon>
							<Button
								width='200px'
								color={Colors.blue}
								onClick={() => fetchPatient(d.id)}
							>
								View Patient Info
							</Button>
						</SetTable.ColumnCon>
						<SetTable.ColumnCon>
							<Button
								width='200px'
								color={Colors.blue}
								onClick={() => goToClient(d.id)}
							>
								Care Plan
							</Button>
						</SetTable.ColumnCon>
					</SetTable.RowAction>
				);
			});
		}
	};

	renderLegend = () => {
		return (
			<>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Name
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Email
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Phone Number
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Address
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon />
			</>
		);
	};
}
