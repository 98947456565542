import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { ParentContainer } from '../ProviderHomeDashboard';
import { isMobile } from 'react-device-detect';

import Text from 'AppComp/Text';
import { ColumnFlex } from 'basicStyles';
import Button from 'AppComp/Button';
import Title from 'AppComp/Title';
import Colors from 'colors';

import { OnboardParentCon } from './handler';

import Personal from './PaymentOnboard/Personal';
import BankInfo from './PaymentOnboard/BankInfo';
import BirthDate from './PaymentOnboard/BirthDate';
import DocumentVerify from './PaymentOnboard/DocumentVerify';
import TOS from './PaymentOnboard/TOS';
const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

import Pending from './PaymentOnboard/Pending';

const Payment = (props) => {
	const {
		verification,
		stripeVerificationInfo,
		isLoading,
		setIsLoading,
		checkLatestProviderSession,
		receiveStepsProgress,
	} = props;
	const [step, setStep] = useState(0);

	useEffect(() => {
		determineStep();
	}, [stripeVerificationInfo, verification]);

	const determineStep = () => {
		const stripeVerificationInfo = props.stripeVerificationInfo;
		// check what is still needed and set the step to that
		const currently_due = stripeVerificationInfo.currently_due;

		console.log('currently_due', currently_due);
		// here's what takes priority [address, banking, personal info, tos]

		// check if any of the address items are missing
		const address = currently_due.filter((item) => item.includes('address'));
		if (address.length > 0) {
			receiveStepsProgress(1 / 6);
			setStep(0);
			return;
		}

		// check if any of the banking items are missing (only single token rn)
		if (stripeVerificationInfo.token === null) {
			setStep(2);
			receiveStepsProgress(2 / 6);
			return;
		}

		// check if any of the personal info items are missing
		const personalInfo = currently_due.filter((item) => item.includes('dob'));
		const ssn = currently_due.filter((item) => item.includes('ssn_last_4'));
		if (personalInfo.length > 0 || ssn.length > 0) {
			setStep(3);
			receiveStepsProgress(3 / 6);
			return;
		}

		// check if tos is missing
		const tos = currently_due.filter((item) => item.includes('tos_acceptance'));

		if (tos.length > 0) {
			setStep(4);
			receiveStepsProgress(4 / 6);
			return;
		}

		// check if individual.verification.document is present in currently_due
		const document = currently_due.filter((item) => item.includes('document'));
		if (document.length > 0) {
			setStep(5);
			receiveStepsProgress(5 / 6);
			return;
		}
		// check if tax id shows up for the user needs
		const tax_id = currently_due.filter((item) => item.includes('tax_id'));
		if (tax_id.length > 0) {
			setStep(6);
			receiveStepsProgress(6 / 6);
			return;
		}

		setStep(6);
		return;
	};
	const handleGoToNextStep = () => {
		setStep(step + 1);
	};

	const handleGoBackStep = () => {
		setStep(step - 1);
	};

	const goToStep = (step) => {
		setStep(step);
	};

	const handleShowView = () => {
		switch (step) {
			case 0:
				return (
					<OnboardParentCon step={step}>
						<WelcomePaymentScreen {...props} handleGoToNextStep={handleGoToNextStep} />
					</OnboardParentCon>
				);
			case 1:
				return (
					<OnboardParentCon step={step}>
						<Personal {...props} handleGoToNextStep={handleGoToNextStep} />
					</OnboardParentCon>
				);
			case 2:
				return (
					<OnboardParentCon step={step}>
						<Elements stripe={stripePromise}>
							<BankInfo {...props} handleGoToNextStep={handleGoToNextStep} />
						</Elements>
					</OnboardParentCon>
				);
			case 3:
				return (
					<OnboardParentCon step={step}>
						<BirthDate {...props} handleGoToNextStep={handleGoToNextStep} />
					</OnboardParentCon>
				);
			case 4:
				return (
					<OnboardParentCon step={step}>
						<TOS {...props} handleGoToNextStep={handleGoToNextStep} />
					</OnboardParentCon>
				);
			case 5:
				return (
					<OnboardParentCon step={step}>
						<Elements stripe={stripePromise}>
							<DocumentVerify {...props} />
						</Elements>
					</OnboardParentCon>
				);
			case 6:
				return (
					<OnboardParentCon step={step}>
						<Pending {...props} />
					</OnboardParentCon>
				);

			default:
				return <WelcomePaymentScreen />;
		}
	};

	return <ParentContainer>{handleShowView()}</ParentContainer>;
};

export default Payment;

const WelcomePaymentScreen = (props) => {
	const { handleGoToNextStep } = props;

	return (
		<ColumnFlex style={{ maxWidth: '240px' }} center>
			<Title color={'black'} bold subtitle>
				Payment
			</Title>
			<ColumnFlex padding='0 0 12px 0' margin='20px auto 0 auto' center gap='12px'>
				<Text
					style={{
						textAlign: 'center',
					}}
				>
					FYI partners with Stripe to pay you. We’ll need to collect additional
					information to process your pay.
				</Text>
			</ColumnFlex>
			<Button
				margin='40px 8px 0 0px'
				onClick={() => handleGoToNextStep()}
				backgroundColor={Colors.primary.primary}
				borderRadius={'24px'}
				width={'240px'}
				height={isMobile ? '40px' : '60px'}
			>
				<Text
					style={{
						textAlign: 'center',
					}}
					bold
					color={'white'}
					midLarge
				>
					Next
				</Text>
			</Button>
		</ColumnFlex>
	);
};

//const
