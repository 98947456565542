import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';

import Text from '../../Text';
import SetTable from '../../SetTable';

export const SetTableContainer = styled.div`
	flex: 1;
	margin: 16px;
`;

export const renderDisputesTableLegend = () => {
	return (
		<>
			<SetTable.ColumnCon>
				<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
					Nurse
				</Text>
			</SetTable.ColumnCon>
			<SetTable.ColumnCon>
				<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
					Dispute Start Time
				</Text>
			</SetTable.ColumnCon>
			<SetTable.ColumnCon>
				<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
					Dispute End Time
				</Text>
			</SetTable.ColumnCon>
			<SetTable.ColumnCon>
				<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
					Time of Dispute
				</Text>
			</SetTable.ColumnCon>
			<SetTable.ColumnCon>
				<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
					Dispute Resume
				</Text>
			</SetTable.ColumnCon>
			<SetTable.ColumnCon>
				<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
					Dispute Cause
				</Text>
			</SetTable.ColumnCon>
			<SetTable.ColumnCon />
		</>
	);
};

//AdminDisputesView.jsx
