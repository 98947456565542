import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils/';

export const StyledJobPostOfferPage = styled.div`
	display: flex;
	flex-direction: column;
	gap: 24px;

	width: 100%;
	max-width: 1050px;

	margin: auto;
`;

export const StyledJobSection = styled.div`
	display: flex;
	gap: 24px;
`;

export const StyledActionsContainer = styled.div`
	width: 30%;
	max-width: 30%;

	display: flex;
	flex-direction: column;
	gap: 20px;
`;

export const StyledAction = styled.div`
	background: white;
	padding: 20px;

	color: ${colors.primary.primary};

	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;

	display: flex;
	flex-direction: column;

	gap: 8px;
`;

export const StyledActionHeading = styled.span`
	font-size: 0.9em;
	font-weight: bold;
	letter-spacing: 2px;
	margin-bottom: 8px;
	text-transform: uppercase;
	line-height: 1.4rem;
	color: ${colors.primary.primary};
`;

export const StyledStatusWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;

	padding: 10px 15px;
	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;
`;

export const StyledStatusIconContainer = styled.div`
	display: flex;
`;

export const StyledStatus = styled.span`
	font-size: 1rem;
	font-weight: 400;
`;

export const StyledOfferContainer = styled.div`
	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;

	padding: 30px;
`;

export const StyledCalculatorContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const StyledCalculatorWrapper = styled.div`
	display: flex;
	align-items: center;

	text-align: center;

	margin: auto;
`;

export const StyledCalculatorButton = styled.button`
	width: 30px;
	height: 30px;

	border-radius: 50%;
	border: none;

	background-color: ${colors.primary.primary};
`;

export const StyledCalculatorValueContainer = styled.div`
	display: flex;
	flex-direction: column;

	text-align: center;

	position: relative;

	margin: 0.75rem 2rem 0 2rem;
`;

export const StyledCalculatedValue = styled.span`
	font-size: 1.5rem;
	color: ${colors.primary.primary};
`;

export const StyledCalculatedValueLabel = styled.span`
	font-size: 0.75rem;
	font-style: italic;
	color: ${colors.primary.primary};

	position: relative;
	margin: auto;
`;

export const StyledCalculatorIconContainer = styled.div`
	color: white;
`;

export const StyleOfferButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;
	margin: 1rem auto;

	width: 153px;
`;

export const StyledMainContainer = styled.div`
	width: 70%;
	padding: 20px;
	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;

	display: flex;
	flex-direction: column;
	gap: 1rem;

	background: ${colors.white};
`;

export const StyledCareProsContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
