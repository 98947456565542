import React from 'react';

import { TransparentButton } from '../../../corev2/Buttons';

import CompletedStep from './CompletedStep';
import PendingStep from './PendingStep';

import { StyledStepItem } from './styles';

const StepItem = (props) => {
	const { children, isCompleted } = props;

	return (
		<StyledStepItem>
			{isCompleted && (
				<TransparentButton>
					<CompletedStep />
				</TransparentButton>
			)}

			{!isCompleted && (
				<TransparentButton>
					<PendingStep />
				</TransparentButton>
			)}

			{children}
		</StyledStepItem>
	);
};

export default StepItem;
