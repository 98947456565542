import React from 'react';
import * as _ from 'lodash';
import { connect } from 'formik';

class FormikAutoSave extends React.Component {
	state = {
		isSaving: false,
	};

	save = _.debounce(() => {
		const { formik, onSave } = this.props;

		if (!formik.isValid) {
			return;
		}

		this.setState({ isSaving: true, saveError: undefined }, () => {
			Promise.resolve(onSave(formik.values))
				.then((res) => {
					this.setState({ isSaving: false, lastSaved: new Date() });
				})
				.catch((err) => {
					console.log('err', err);
					this.setState({ isSaving: false, saveError });
				});
		});
	}, 300);

	componentDidUpdate(prevProps) {
		const { formik } = this.props;

		if (!_.isEqual(prevProps.formik.values, formik.values)) {
			this.save();
		}
	}

	render() {
		const { render } = this.props;

		return render ? render(this.state) : null;
	}
}

export default connect(FormikAutoSave);
