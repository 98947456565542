import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

const AnimationWrapper = (props) => {
	const { status, classNames, appear, mountOnEnter, unmountOnExit, timeout, children } = props;
	return (
		<CSSTransition
			in={status}
			classNames={classNames}
			appear={appear}
			mountOnEnter={mountOnEnter}
			unmountOnExit={unmountOnExit}
			timeout={timeout}
		>
			{children}
		</CSSTransition>
	);
};

const TimeoutShape = PropTypes.shape({
	enter: PropTypes.number,
	exit: PropTypes.number,
});

AnimationWrapper.defaultProps = {
	appear: false,
	mountOnEnter: true,
	unmountOnExit: true,
	timeout: 1000,
};

AnimationWrapper.propTypes = {
	/**If the children should animate when first loaded  */
	appear: PropTypes.bool,
	/** If the children should be mounted when the css status is toggled on*/
	mountOnEnter: PropTypes.bool,
	/** If the children should be unmounted when the css status is toggled off*/
	unmountOnExit: PropTypes.bool,
	/** Set the timeout for the exit and enter phase*/
	timeout: PropTypes.oneOfType([PropTypes.number, TimeoutShape]),
	/** Sets the status of in or not*/
	status: PropTypes.bool.isRequired,
	/** classNames for the css animations*/
	classNames: PropTypes.string.isRequired,
};

export default AnimationWrapper;
