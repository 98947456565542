import { createAction, createActions, handleActions } from 'redux-actions';

const selectedCarePlanSkeleton = {
	id: 0,
	adminProviderConversations: [],
	patient_location: 'FYI',
	patient_relation: 'FYI',
	user: {
		image_url: '',
		full_name: 'FYI',
		id: 0,
		email: 'FYI@FYI.com',
	},
};

const initialState = {
	admin: null,
	carePlans: [],
	conversationType: 'adminPatientConversation',
	selectedProviderConvoId: 0,
	adminOboardOverlay: false,
	selectedConversationType: null,
	conversations: [],
	selectedConversation: null,

	selectedCarePlanId: 0,
	payments: [],
	showForgotPasswordOverlay: false,
	forgotPasswordStatus: null,
	showChangePasswordOverlay: false,
	changePasswordStatus: null,
	userDetail: {
		status: false,
		user: null,
		type: null,
	},
	providers: [],
	patients: [],
	patientInfoChanges: [],
	physicianContacts: [],
	liteCarePlan: [],
	agencyCarePlan: [],
	clientDocumentRequirements: [],
	careProDocumentRequirements: [],
	attributeTraits: [],
	traitTemplates: [],
};

export const toggleAdminOnboardOverlay = createAction('TOGGLE_ADMIN_ONBOARD_OVERLAY');
export const toggleAdminConversationType = createAction('TOGGLE_ADMIN_CONVERSATION_TYPE');
export const toggleAdminSelectedProvider = createAction('RECEIVE_ADMIN_SELECTED_CONVO_PROVIDER_ID');
export const receiveSelectedCarePlanId = createAction('RECEIVE_SELECTED_CAREPLAN_ID');
export const receiveCarePlanOnly = createAction('RECEIVE_CAREPLANS');
export const receiveAdminInfo = createAction('RECEIVE_ADMIN_INFO');
export const receiveCarePlansAction = createAction('RECEIVE_CAREPLANS');
export const receivePayments = createAction('RECEIVE_PAYMENT');
export const receiveProviders = createAction('RECEIVE_PROVIDERS');
export const receivePatients = createAction('RECEIVE_PATIENTS');
export const receivePhysicianContacts = createAction('RECEIVE_PHYSICIAN_CONTACT');
export const receiveLiteCarePlan = createAction('RECEIVE_LITE_CARE_PLAN');
export const receiveAttributeTraits = createAction('RECEIVE_ATTRIBUTE_TRAITS');
export const receiveTraitTemplates = createAction('RECEIVE_TRAIT_TEMPLATES');

export const receiveClientDocumentRequirements = createAction(
	'RECEIVE_CLIENT_DOCUMENT_REQUIREMENTS'
);

export const receiveCareProDocumentRequirements = createAction(
	'RECEIVE_CAREPRO_DOCUMENT_REQUIREMENTS'
);

export const receiveConversations = createAction('RECEIVE_CONVERSATIONS');
export const receiveSelectedConversation = createAction('RECEIVE_SELECTED_CONVERSATION');
export const toggleForgotPasswordOverlay = createAction('PROVIDER_TOGGLE_FORGOT_PASSWORD_OVERLAY');
export const toggleChangePasswordOverlay = createAction('PROVIDER_TOGGLE_CHANGE_PASSWORD_OVERLAY');

export const receiveForgotPasswordStatus = createAction('PROVIDER_RECEIVE_FORGOT_PASSWORD_STATUS');
export const receiveNewPasswordStatus = createAction('PROVIDER_RECEIVE_NEW_PASSWORD_STATUS');

export const receiveUserDetails = createAction('RECEIVE_USER_DETAILS');
export const receiveSelectedConversationType = createAction('RECEIVE_SELECTED_CONVERSATION_TYPE');

export const handleAdminActions = handleActions(
	{
		[receiveTraitTemplates](state, { payload }) {
			return {
				...state,
				traitTemplates: payload,
			};
		},
		[receiveAttributeTraits](state, { payload }) {
			return {
				...state,
				attributeTraits: payload,
			};
		},

		[receiveCareProDocumentRequirements](state, { payload }) {
			return {
				...state,
				careProDocumentRequirements: payload,
			};
		},

		[receiveClientDocumentRequirements](state, { payload }) {
			return {
				...state,
				clientDocumentRequirements: payload,
			};
		},

		[receiveLiteCarePlan](state, { payload }) {
			return {
				...state,
				liteCarePlan: payload,
			};
		},
		[receivePhysicianContacts](state, { payload }) {
			return {
				...state,
				physicianContacts: payload,
			};
		},
		[receiveSelectedConversationType](state, { payload }) {
			return {
				...state,
				selectedConversationType: payload,
			};
		},
		[receiveConversations](state, { payload }) {
			return {
				...state,
				conversations: payload,
			};
		},
		[receiveSelectedConversation](state, { payload }) {
			return {
				...state,
				selectedConversation: payload,
			};
		},

		[receiveProviders](state, { payload }) {
			return {
				...state,
				providers: payload,
			};
		},
		[receivePatients](state, { payload }) {
			return {
				...state,
				patients: payload,
			};
		},
		[receiveUserDetails](state, { payload }) {
			return {
				...state,
				userDetail: payload,
			};
		},
		[receiveNewPasswordStatus](state, { payload }) {
			return {
				...state,
				changePasswordStatus: payload,
			};
		},
		[receiveForgotPasswordStatus](state, { payload }) {
			return {
				...state,
				forgotPasswordStatus: payload,
			};
		},
		[toggleChangePasswordOverlay](state, { payload }) {
			return {
				...state,
				showChangePasswordOverlay: payload,
			};
		},
		[toggleForgotPasswordOverlay](state, { payload }) {
			return {
				...state,
				showForgotPasswordOverlay: payload,
			};
		},
		[receivePayments](state, { payload }) {
			return {
				...state,
				payments: payload,
			};
		},

		[toggleAdminOnboardOverlay](state, { payload }) {
			return {
				...state,
				adminOboardOverlay: payload,
			};
		},
		[receiveAdminInfo](state, { payload }) {
			return {
				...state,
				admin: payload,
			};
		},
		[receiveSelectedCarePlanId](state, { payload }) {
			return {
				...state,
				selectedCarePlanId: payload,
			};
		},
		[toggleAdminConversationType](state, { payload }) {
			return {
				...state,
				conversationType: payload,
			};
		},
		[receiveCarePlanOnly](state, { payload }) {
			return {
				...state,
				carePlans: payload,
			};
		},
		[toggleAdminSelectedProvider](state, { payload }) {
			return {
				...state,
				selectedProviderConvoId: payload,
			};
		},
		[receiveCarePlansAction](state, { payload }) {
			return {
				...state,
				carePlans: payload,
			};
		},
	},
	initialState
);
