import styled from 'styled-components';

import { colors } from '../../../corev2/utils';

export const StyledFormGroup = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	text-align: left;
`;

export const StyledLabel = styled.div`
	font-size: 0.8rem;
	font-weight: 600;
	letter-spacing: 0.4px;
    line-height: 1.7
    color: ${colors.forms.fontColor}
	text-transform: uppercase;

	margin-bottom: 10px;
`;

export const StyledOptionsWrapper = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`;

export const StyledOption = styled.li`
	display: flex;
	align-items: center;

	width: 100%;
	height: 20px;
`;

export const StyledCheckboxInput = styled.input`
	border-color: ${colors.forms.formInputBorderColor};
	border-radius: 4px;
	background: ${colors.white};

	display: inline-block;
	margin: 0 5px 0 0;

	width: 13px;
	height: 13px;

	border-style: solid;
	border-width: 1px;
	box-shadow: none;
	font-size: inherit;
	outline: none;
	padding: 0 12px;
`;

export const StyledCheckboxLabel = styled.label`
	font-size: 0.8rem;
	letter-spacing: 0.4px;
    line-height: 1.7
    
	color: ${colors.forms.fontColor};
`;
