import React, { useEffect, useState } from 'react';

const FileView = ({ file, preview }) => {
	const renderFile = () => {
		if (file) {
			// check what type of file it is by getting the type from the url
			// and then render the appropriate component
			const fileType = file.split('.').pop();

			console.log('fileType', fileType);
			if (preview) {
				return <img className='overlay-image-view' src={file} alt='file' />;
			}
			switch (fileType) {
				case 'png':
					return <img className='overlay-image-view' src={file} alt='file' />;
				case 'jpg':
					return <img className='overlay-image-view' src={file} alt='file' />;
				case 'jpeg':
					return <img className='overlay-image-view' src={file} alt='file' />;
				case 'pdf':
					return (
						<iframe
							width='100%'
							height='100%'
							style={{ border: 'none' }}
							src={file}
							title='file'
						/>
					);
				default:
					return <p>File type not supported</p>;
			}
		}
	};
	return <>{renderFile()}</>;
};

export default FileView;
