import React from 'react';

import { Hearts } from 'react-loader-spinner';

import Colors from 'colors';

import { LoaderSection } from '../../../../../../corev2/FormGen/styles';

import { useFetchCarePlanContactInfo } from '../../../api/queries/useFetchCarePlanForms';
import useUpdateCarePlanFormAnswers from '../../../api/mutations/useUpdateCarePlanFormAnswers';

import FormGen from '../../../../../../corev2/FormGen';
import DescriptivePage from '../../../../../../corev2/DescriptivePage';
import Text from '../../../../../../corev2/Text';

import { useHistory } from 'react-router-dom';

import { carePlanRoutes } from '../../../routes/constants';
import { TransparentButton } from '../../../../../../corev2/Buttons';

const PersonalPage = () => {
	const history = useHistory();

	const { data, isLoading } = useFetchCarePlanContactInfo();

	const updateCarePlanFormAnswers = useUpdateCarePlanFormAnswers();

	if (isLoading) {
		return (
			<LoaderSection>
				<div
					style={{
						flex: 1,
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '100%',
					}}
				>
					<Hearts color={Colors.primary.primary} height={100} width={100} />
				</div>
			</LoaderSection>
		);
	}

	if (data === undefined || data === null) {
		return (
			<LoaderSection>
				<Text>Something went wrong</Text>
			</LoaderSection>
		);
	}
	const backButtonHandler = () => {
		history.push(carePlanRoutes.onboarding.logistics);
	};

	const handleSubmit = async (values) => {
		console.log('submitting form', values);
		// do whatever you need to move forward

		// you can assume that this can be called after
		try {
			const updateVal = await updateCarePlanFormAnswers.mutateAsync({
				formId: data.id,
				answers: values,
			});

			history.push(carePlanRoutes.onboarding.logistics);
		} catch (err) {
			console.error(err);
		}
	};
	const getDescriptionText = () => {
		return <Text size='large'>In case we need to contact anyone else, who should we call</Text>;
	};

	return (
		<>
			<DescriptivePage
				heading='Care Plan Contact Information'
				descriptionContent={getDescriptionText()}
			>
				<FormGen showTitle={false} formData={data} handleSubmit={handleSubmit} />
			</DescriptivePage>
			<TransparentButton size='small' onClick={backButtonHandler}>
				Go back
			</TransparentButton>
		</>
	);
};

export default PersonalPage;
