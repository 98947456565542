import styled from 'styled-components';

export const StyledModal = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 999 !important;
	background-color: rgba(0, 0, 0, 0.4); /* Optional: Adds a slight overlay behind the form */
`;
