import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../../corev2/utils';

export const StyledJobStat = styled.div`
	width: 100%;
	max-width: 100px;
	padding: 15px;
	background: ${({ isActive }) => (isActive ? colors.primary.primary : colors.white)};
	color: ${({ isActive }) => (isActive ? colors.white : colors.primary.primary)};
	border: ${({ isActive }) =>
		isActive ? 'none' : `1px solid ${colors.forms.formInputBorderColor}`};
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 8px;
	cursor: pointer;
	@media ${mediaQueries.tablet} {
		padding: 20px;
		max-width: 140px;
	}
`;

export const StyledStatTitle = styled.h3`
	font-size: 0.9rem;

	@media ${mediaQueries.tablet} {
		font-size: 1rem;
	}
`;

export const StyledJobStatValue = styled.p`
	font-size: 1.8rem;

	@media ${mediaQueries.tablet} {
		font-size: 2.1rem;
	}
`;
