import React, { useState, useEffect, useCallback } from 'react';

import {
	StyledShiftWrapper,
	StyledShiftContainer,
	StyledTitle,
	StyledShiftDetails,
	StyledLabel,
	StyledFieldGroup,
	StyledFieldLabel,
	StyledInputField,
	StyledButtonGroup,
	StyledTextArea,
	StyledTextWithBg,
	StyledValue,
} from './styles';

const TimerSection = ({ startDate }) => {
	const [elapsedTime, setElapsedTime] = useState(0);

	useEffect(() => {
		// Function to update the elapsed time
		const updateElapsedTime = () => {
			const currentTime = new Date();
			const timeDiff = currentTime - startDate;
			setElapsedTime(timeDiff);
		};

		// Update the elapsed time every second
		const interval = setInterval(updateElapsedTime, 1000);

		// Clear the interval on component unmount
		return () => clearInterval(interval);
	}, [startDate]);

	// Convert milliseconds to a readable format
	const formatTime = (milliseconds) => {
		let seconds = Math.floor(milliseconds / 1000);
		let minutes = Math.floor(seconds / 60);
		let hours = Math.floor(minutes / 60);

		seconds = seconds % 60;
		minutes = minutes % 60;

		return `${hours.toString().padStart(2, '0')}:${minutes
			.toString()
			.padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
	};

	return (
		<StyledShiftDetails
			style={{
				paddingTop: '15px',
				paddingBottom: '10px',
				marginBottom: '15px',
			}}
		>
			<StyledFieldGroup>
				<StyledFieldLabel>Elapsed Time Since Check In</StyledFieldLabel>
				<StyledValue>{formatTime(elapsedTime)}</StyledValue>
			</StyledFieldGroup>
		</StyledShiftDetails>
	);
};

export default TimerSection;
