import styled from 'styled-components';

import { colors } from '../../../../../../corev2/utils';

export const StyledShiftNote = styled.div`
	background-color: ${colors.table.alternative};
	flex: 1;
	padding: 30px;
	border-radius: 10px;

	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
	margin-right: 20px;
	border: 1px solid rgba(43, 105, 166, 0.3);
	border-radius: 10px;
	text-align: left;
`;
