import React, { useEffect, useState, createContext } from 'react';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { authRoutes } from './../../routes/constants';

export const OnboardingContext = createContext();

const initialOnboardingState = {
	personalInfo: {
		name: 'personalInfo',
		label: 'Personal Info',
		isCompleted: false,
		data: {
			firstName: '',
			lastName: '',
			displayName: '',
		},
		route: authRoutes.onboarding.personalInfo,
	},
	accountInfo: {
		name: 'accountInfo',
		label: 'Account',
		isCompleted: false,
		data: {
			email: '',
			phone: '',
			password: '',
		},
		route: authRoutes.onboarding.accountInfo,
	},
	authCode: {
		name: 'authCode',
		label: 'Auth Code',
		isCompleted: false,
		data: {
			authCode: '',
		},
		route: authRoutes.onboarding.authCode,
	},
};

export const OnboardingProvider = ({ children }) => {
	const [onboardingState, setOnboardingState] = useState(initialOnboardingState);

	useEffect(() => {
		const localOnboardingState = getFromLocalStorage(
			localStorageKeys.FYI_CLIENT_OBOARDING_STATE
		);

		if (localOnboardingState) {
			setOnboardingState(localOnboardingState);
		}
	}, []);

	const mutateOnboardingState = (key, value) => {
		setOnboardingState((prevState) => {
			const newState = {
				...prevState,
				[key]: {
					...prevState[key],
					...value,
				},
			};

			setInLocalStorage(
				localStorageKeys.FYI_CLIENT_OBOARDING_STATE,
				JSON.stringify(newState)
			);
			return newState;
		});
	};

	const resetOnboardingState = () => {
		setInLocalStorage(localStorageKeys.FYI_CLIENT_OBOARDING_STATE);
		setOnboardingState(initialOnboardingState);
	};

	const value = {
		onboardingState,
		mutateOnboardingState,
		resetOnboardingState,
	};

	return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};
