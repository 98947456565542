import React, { Component, Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import is from 'styled-is';

import Colors from 'colors';

import { ColumnFlex, RowFlex } from 'basicStyles';
import Text from 'AppComp/Text';

import color from '../../constants/colors';

export const StyledSelectContainer = styled.div`
	align-items: flex-start;
	position: relative;
	margin: ${(props) => props.margin || '0'};
	margin-bottom: 32px;
	transition: all ease 0.3s;

	border: none;
	border: 0.5px solid #ecf0f3;
	border-radius: 12px;
	select:valid ~ .main_label {
		top: -1.4rem;
		left: 0;
		color: #1d9c0f;
		font-size: 1rem;
	}

	select:focus,
	select:valid {
		border: ${(props) => props.border || `0.5px solid ${color.secondary.blue}`};
	}

	.error_bar:before,
	.error_bar:after {
		background-color: #1d9c0f;
	}
	.bar:before,
	.bar:after {
		background-color: #1d9c0f;
	}

	select:focus ~ .bar:before,
	select:focus ~ .bar:after,
	select:focus .error_bar:before,
	select:focus .error_bar:after {
		width: 48%;
	}
	select:valid ~ .bar:before,
	select:valid ~ .bar:after,
	select:valid ~ .error_bar:before,
	select:valid ~ .error_bar:after {
		width: 48%;
	}

	${({ theme }) => `


		select:focus ~ .main_label, select:valid ~ .main_label {
			color: ${color.secondary.blue};

		}

		select:valid ~ .main_label {
			color: ${color.secondary.blue};

		}
		select:focus , select:valid  {
			border: 0.5px solid ${color.secondary.blue};
		}

		.error_bar:before, .error_bar:after {
			background-color: ${color.secondary.blue};
		}
		.bar:before, .bar:after {
			background-color: ${color.secondary.blue};
		}
	`}
`;
export const StyledInputContainer = styled.div`
	align-items: flex-start;
	position: relative;

	margin-bottom: 32px;
	margin: ${(props) => props.margin || '0 0 32px 0'};
	transition: all ease 0.3s;

	border: none;
	border: 0.5px solid #ecf0f3;
	border-radius: 12px;
	input:focus ~ .main_label,
	input:valid ~ .main_label {
		top: -1.4rem;
		left: 0;
		color: #1d9c0f;
		font-size: 1rem;
	}
	input:focus,
	input:valid {
		border: ${(props) => props.border || `0.5px solid ${color.secondary.blue}`};
	}

	.error_bar:before,
	.error_bar:after {
		background-color: #1d9c0f;
	}
	.bar:before,
	.bar:after {
		background-color: #1d9c0f;
	}

	input:focus ~ .bar:before,
	input:focus ~ .bar:after,
	input:focus .error_bar:before,
	input:focus .error_bar:after {
		width: 48%;
	}
	input:valid ~ .bar:before,
	input:valid ~ .bar:after,
	input:valid ~ .error_bar:before,
	input:valid ~ .error_bar:after {
		width: 48%;
	}

	${({ theme }) => `


		input:focus ~ .main_label, input:valid ~ .main_label {
			color: ${color.secondary.blue};

		}
		input:focus ~ .main_label, input:valid ~ .main_label {
			color: ${color.secondary.blue};

		}
		input:focus , input:valid  {
			border: 0.5px solid ${color.secondary.blue};
		}

		.error_bar:before, .error_bar:after {
			background-color: ${color.secondary.blue};
		}
		.bar:before, .bar:after {
			background-color: ${color.secondary.blue};
		}
	`}
`;

const StyledInput = styled.input`
	padding: 0.6rem 0.2rem;
	padding-left: 18px;
	font-size: 26px;
	line-height: 1.3rem;
	font-weight: 100;
	display: block;
	width: 100%;
	border: none;
	border: 0.5px solid #ecf0f3;
	border-radius: 4px;
	color: black;
	border-radius: 12px;
	background-color: ${(props) => props.backgroundColor || Colors.lightGrey};
	&:focus {
		outline: none;
		background: ${(props) => props.backgroundColor || 'none'};
	}
	&:valid {
		outline: none;
		background: ${(props) => props.backgroundColor || 'none'};
	}
`;

const StyledInputBar = styled.span`
	display: block;
	width: 100%;

	&:before {
		left: 50%;
	}

	&:after {
		right: 50%;
	}

	&:before,
	&:after {
		content: '';
		height: 2px;
		width: 0;
		bottom: 1px;
		position: absolute;
		transition: 0.2s ease all;
	}
`;
export const ErrorLabel = styled(StyledInputBar)`
	position: absolute;
	top: 60px;
	left: 4px;
	color: ${color.heartRed};
	font-size: 12px;
	pointer-events: none;
`;

export const MainLabel = styled.label`
	color: gray;
	font-size: 0.9rem;
	line-height: 1.28rem;
	position: absolute;
	pointer-events: none;
	transition: 0.2s ease all;
	top: 18px;
	left: 12px;

	${is('disabled')`
		top: -0.88rem;
		font-size: 0.9rem;
		line-height: 0.96rem;
	`}
`;

export const ActiveMainLabel = styled.label`
	color: gray;
	font-size: 0.88rem;
	line-height: 1.28rem;
	position: absolute;
	pointer-events: none;
	transition: 0.2s ease all;
	top: 16px;
	left: 12px;

	top: -1.2rem;
	font-size: 0.8rem;
	line-height: 0.96rem;
`;

const SelectLabel = styled.label`
	color: gray;
	font-size: 0.88rem;
	line-height: 1.28rem;
	position: absolute;
	pointer-events: none;
	transition: 0.2s ease all;

	top: 16px;
	left: 12px;

	${is('disabled')`
		top: -0.88rem;
		font-size: 0.66rem;
		line-height: 0.96rem;
	`}
`;

export const ErrorBar = styled.label``;

const Container = styled.div`
	position: relative;
`;

export const BareBoneTextInput = (props) => {
	const { form, field, type, custom, placeholder } = props;
	return (
		<StyledInputContainer>
			<StyledInput required {...field} type={type} placeholder={placeholder} />
			<StyledInputBar className='bar' />

			{(form.touched[field.name] || form.submitCount > 0) && form.errors[field.name] && (
				<ErrorLabel>{form.errors[field.name]}</ErrorLabel>
			)}
			{(form.touched[field.name] || form.submitCount > 0) && form.errors[field.name] && (
				<ErrorBar />
			)}
		</StyledInputContainer>
	);
};

export const SearchBarInput = (props) => {
	const { type, name, placeholder, background, border } = props;

	return (
		<StyledInputContainer border={border}>
			<StyledInput
				type={type}
				name={name}
				placeholder={placeholder}
				background={background}
			/>
		</StyledInputContainer>
	);
};

const StyledTextArea = styled.textarea`
	color: black;
	width: 100%;
	height: 180px;
	min-height: 32px;
	border-radius: 12px;
	border: solid 1px hsl(0, 0%, 80%);
	background-color: ${Colors.lightGrey};
	padding: 8px;
`;

const CustomColumn = styled.div`
	display: flex;
	width: 100%;
	max-width: ${(props) => props.maxWidth || '420px'};

	flex-direction: column;
	align-items: flex-start;
	flex: 1;
	margin-bottom: 16px;
`;

export const TextArea = (props) => {
	const { options, field, form, label, maxWidth } = props;

	return (
		<CustomColumn maxWidth={maxWidth}>
			<RowFlex center margin='0 0 12px 0'>
				<Text margin='0 16px 0 0' className='main_label'>
					{label}
				</Text>
				{(form.touched[field.name] || form.submitCount > 0) && form.errors[field.name] && (
					<Text error>{form.errors[field.name]}</Text>
				)}
			</RowFlex>
			<StyledTextArea
				value={field.value}
				name={field.name}
				onBlur={field.onBlur}
				onChange={field.onChange}
			/>
		</CustomColumn>
	);
};

const StyledTextInput = (props) => {
	const { form, field, type, custom, label, backgroundColor, pattern } = props;

	return (
		<StyledInputContainer>
			<StyledInput
				pattern={pattern}
				backgroundColor={backgroundColor}
				required
				{...field}
				type={type}
			/>

			<StyledInputBar className='bar' />
			<MainLabel className='main_label' htmlFor={field.name}>
				{label}
			</MainLabel>
			{(form.touched[field.name] || form.submitCount > 0) && form.errors[field.name] && (
				<ErrorLabel>{form.errors[field.name]}</ErrorLabel>
			)}
			{(form.touched[field.name] || form.submitCount > 0) && form.errors[field.name] && (
				<ErrorBar />
			)}
		</StyledInputContainer>
	);
};

export const BasicTextInput = (props) => {
	const {
		label,
		id,
		type,
		placeholder,
		required,
		autoComplete,
		value,
		onChange,
		maxLength,
		margin,
	} = props;

	return (
		<StyledInputContainer margin={margin}>
			<StyledInput
				required={required}
				autoComplete={autoComplete}
				value={value}
				onChange={onChange}
				id={id}
				type={type}
				maxLength={maxLength}
			/>

			<StyledInputBar className='bar' />
			<MainLabel className='main_label' htmlFor={id}>
				{label}
			</MainLabel>
		</StyledInputContainer>
	);
};

const CustomSelect = styled.select`
	width: 100%;
	height: 50px;
	color: black;
	border: solid 1px hsl(0, 0%, 80%);
	border-radius: 12px;
	padding-left: 10px;
	margin-right: 10px;
	background-color: ${Colors.lightGrey};
`;

const UpdatedStyledSelect = styled.select`
	padding: 0.6rem 0.2rem;
	padding-left: 18px;
	font-size: 22px;
	line-height: 1rem;
	font-weight: 100;
	display: block;
	width: 100%;
	border: none;
	border: 0.5px solid #ecf0f3;
	border-radius: 4px;
	color: black;
	border-radius: 12px;
	background-color: ${(props) => props.backgroundColor || Colors.lightGrey};
	&:focus {
		outline: none;
		background: ${(props) => props.backgroundColor || 'none'};
	}
	&:valid {
		outline: none;
		background: ${(props) => props.backgroundColor || 'none'};
	}
`;
export const StyledTextSelectInputV2 = (props) => {
	const { form, field, options, label, backgroundColor } = props;

	const renderRows = () => {
		return options.map((d, i) => {
			return (
				<option value={d.value} key={`styd-${d.value}`}>
					{d.label}
				</option>
			);
		});
	};

	return (
		<StyledSelectContainer>
			<UpdatedStyledSelect
				backgroundColor={backgroundColor}
				value={field.value}
				required
				{...field}
			>
				{renderRows()}
			</UpdatedStyledSelect>
			<StyledInputBar className='bar' />
			{(form.touched[field.name] || form.submitCount > 0) && form.errors[field.name] && (
				<ErrorLabel>{form.errors[field.name]}</ErrorLabel>
			)}
			{(form.touched[field.name] || form.submitCount > 0) && form.errors[field.name] && (
				<ErrorBar />
			)}
			<SelectLabel className='main_label' htmlFor={field.name}>
				{label}
			</SelectLabel>
		</StyledSelectContainer>
	);
};
export const StyledPasswordInputContainer = styled.div`
	align-items: flex-start;
	position: relative;
	margin: ${(props) => props.margin || '0'};
	transition: all ease 0.3s;
	width: inherit;
	border-radius: 4px;
	margin-bottom: 8px;
	input:focus ~ .main_label,
	input:valid ~ .main_label {
		top: -1.2rem;
		left: 4px;
		color: #1d9c0f;
		font-size: 0.6rem;
	}
	input:focus,
	input:valid {
		border: ${(props) => props.border || `0.5px solid ${color.secondary.blue}`};
	}

	.error_bar:before,
	.error_bar:after {
		background-color: #1d9c0f;
	}
	.bar:before,
	.bar:after {
		background-color: #1d9c0f;
	}

	input:focus ~ .bar:before,
	input:focus ~ .bar:after,
	input:focus .error_bar:before,
	input:focus .error_bar:after {
		width: 50%;
	}
	input:valid ~ .bar:before,
	input:valid ~ .bar:after,
	input:valid ~ .error_bar:before,
	input:valid ~ .error_bar:after {
		width: 50%;
	}

	${({ theme }) => `


		input:focus ~ .main_label, input:valid ~ .main_label {
			color: ${color.secondary.blue};

		}
		input:focus , input:valid  {
			border: 0.5px solid ${color.secondary.blue};
		}

		.error_bar:before, .error_bar:after {
			background-color: ${color.secondary.blue};
		}
		.bar:before, .bar:after {
			background-color: ${color.secondary.blue};
		}
	`}
`;

const PasswordStatusBar = styled.div`
	width: 100%;
	postion: relative;
	height: 6px;
	overflow: hidden;
	background-color: #f5f5f5;
	border-radius: 4px;
	margin-top: 4px;
`;

const StatusBar = styled.div`
	postion: absolute;
	left: 0;
	height: 100%;
	transition: all ease 0.2s;
`;
const PasswordStatusHandler = (props) => {
	const { value } = props;

	const [statusColor, setStatusColor] = useState('');
	const [statusWidth, setStatusWidth] = useState('0%');

	useEffect(() => {
		const password = value ? value : '';
		let strength = 0;

		//If password contains both lower and uppercase characters, increase strength value.
		if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
			strength += 1;
		}

		//If it has numbers and characters, increase strength value.
		if (password.match(/([0-9])/)) {
			strength += 1;
		}

		//If it has one special character, increase strength value.
		if (password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
			strength += 1;
			// $('.one-special-char').addClass('text-success');
		}

		if (password.length > 7) {
			strength += 1;
		}

		// If value is less than 2

		if (strength < 2) {
			setStatusColor('red');
			setStatusWidth('10%');
		} else if (strength == 2) {
			setStatusColor('blue');
			setStatusWidth('60%');
		} else if (strength == 4) {
			setStatusColor('green');
			setStatusWidth('100%');
		}
	}, [value]);

	return (
		<PasswordStatusBar>
			<StatusBar style={{ backgroundColor: statusColor, width: statusWidth }} />
		</PasswordStatusBar>
	);
};

export const ErrorLabelPW = styled(StyledInputBar)`
	position: absolute;
	top: -1rem;
	right: 0px;
	color: ${color.heartRed};
	font-size: 12px;
	pointer-events: none;
	text-align: right;
`;
export const StrongPasswordTextInput = (props) => {
	const { form, field, type, custom, label, backgroundColor } = props;

	return (
		<>
			<StyledPasswordInputContainer>
				<StyledInput backgroundColor={backgroundColor} required {...field} type={type} />

				<PasswordStatusHandler value={field.value} />
				<MainLabel className='main_label' htmlFor={field.name}>
					{label}
				</MainLabel>

				{(form.touched[field.name] || form.submitCount > 0) && form.errors[field.name] && (
					<ErrorLabelPW>{form.errors[field.name]}</ErrorLabelPW>
				)}
				{(form.touched[field.name] || form.submitCount > 0) && form.errors[field.name] && (
					<ErrorBar />
				)}
			</StyledPasswordInputContainer>
			<Text nurseInfo>Password Must Have</Text>
			<Text nurseInfo>- 1 lowercase & 1 uppercase</Text>
			<Text nurseInfo>- 1 number (0-9)</Text>
			<Text nurseInfo>- 1 Special Character (!@#$%^&*).</Text>
			<Text nurseInfo margin='0 0 12px 0'>
				- Atleast 8 Character
			</Text>
		</>
	);
};

export const FormatTextInput = (props) => {
	const { form, field, type, custom, label } = props;

	return (
		<StyledInputContainer>
			<StyledInput
				required
				{...field}
				type={type}
				onChange={(event) => {
					const { value } = event.target;

					//keep numbers and period
					const resultRegex = /[^0-9.]/g;
					let formattedValue = parseFloat(value.replace(resultRegex, ''));

					if (isNaN(formattedValue)) {
						formattedValue = 0;
					}

					// keep number, period, comma, and dollar sign.
					const displayRegex = /[^0-9.,$]/g;
					const display =
						value.indexOf('.') == value.length - 1
							? value.replace(displayRegex, '')
							: '$' +
							  formattedValue.toLocaleString('en', {
									maximumFractionDigits: 2,
							  });

					form.setFieldValue(field.name, display, true);
				}}
			/>

			<StyledInputBar className='bar' />
			<MainLabel className='main_label' htmlFor={field.name}>
				{label}
			</MainLabel>
			{(form.touched[field.name] || form.submitCount > 0) && form.errors[field.name] && (
				<ErrorLabel>{form.errors[field.name]}</ErrorLabel>
			)}
			{(form.touched[field.name] || form.submitCount > 0) && <ErrorBar />}
		</StyledInputContainer>
	);
};

export default StyledTextInput;
