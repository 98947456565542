import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import Colors from 'colors';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import { TextArea } from 'AppComp/styled/StyledTextInput';

import { ColumnFlex } from 'basicStyles';
import useWindowSize from 'scripts/useWindowSize';

import StyledTextInput from 'AppComp/styled/StyledTextInput';

import { CARE_PLAN_ACTIONS } from './CarePlanParent';
import {
	FORM_ITEM_LENGTH,
	FormItemsLength,
	FormButtonStyled,
	CarePlanComponentsFormSubmitButton,
} from './CarePlanviews';

import { validateLogistics } from '../Onboard/validation';
const Logistics = (props) => {
	const { carePlan, carePlanSet, updateSetTraits, setCarePlanAction, loading } = props;
	const [initialValues, setInitialValues] = useState(null);
	const { width } = useWindowSize();

	useEffect(() => {
		renderInitialValues(carePlanSet);
	}, [carePlanSet]);

	const renderInitialValues = (careNeedsSet) => {
		console.log('Initial values - careNeedsSet', careNeedsSet);

		console.log('Initial values - careNeedsSet', carePlanSet);

		const lookup = carePlanSet.setData.reduce((acc, item) => {
			acc[item.id] = {
				trait_id: item.id,
				type: item.trait_type,
				value: item.value ? item.value.trait_value : null,
			};
			return acc;
		}, {});

		setInitialValues({
			address: lookup[76]?.value,
			address2: lookup[77]?.value,
			city: lookup[78]?.value,
			zip: lookup[79]?.value,
			state: lookup[80]?.value,
			codeToEnterHome: lookup[81]?.value,
			whereToPark: lookup[82]?.value,
			extraInfo: lookup[83]?.value,
		});
	};

	const handleSubmit = (values, form) => {
		const updateParams = [
			{ trait_id: 76, value: values.address },
			{ trait_id: 77, value: values.address2 },
			{ trait_id: 78, value: values.city },
			{ trait_id: 79, value: values.zip },
			{ trait_id: 80, value: values.state },
			{ trait_id: 81, value: values.codeToEnterHome },
			{ trait_id: 82, value: values.whereToPark },
			{ trait_id: 83, value: values.extraInfo },
		];

		updateSetTraits(updateParams);
	};

	if (!initialValues && carePlanSet == null) {
		return <div>Loading...</div>;
	}

	return (
		<ColumnFlex
			style={{
				height: 'fit-content',
				backgroundColor: 'white',
				borderRadius: '12px',
			}}
			padding={width < 768 ? 0 : '60px 80px'}
			center
			gap='32px'
		>
			<ColumnFlex fullWidth center gap='8px'>
				<Title nubtitle fontWeight='700'>
					Tell us about the person who needs care
				</Title>
			</ColumnFlex>
			<ColumnFlex
				fullWidth
				style={{
					flex: 1,
					borderRadius: '24px',
				}}
				center
			>
				<Formik
					onSubmit={handleSubmit}
					initialValues={initialValues}
					enableReinitialize={true}
					autoComplete={'off'}
					validate={validateLogistics}
					render={(formProps) => (
						<Form noValidate>
							<ColumnFlex alignCenter justifyCenter fullWidth fullHeight>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - Street Address'
										name='address'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - Street Address 2'
										name='address2'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - City'
										name='city'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - ZIP'
										name='zip'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - State'
										name='state'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - Code To Enter Home'
										name='codeToEnterHome'
										type='text'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - Where To Park'
										name='whereToPark'
										type='text'
										component={TextArea}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Logistics - Extra Info'
										name='extraInfo'
										type='text'
										component={TextArea}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<CarePlanComponentsFormSubmitButton
									isValid={formProps.isValid}
									loading={loading}
									carePlan={carePlan}
									handleBack={() =>
										setCarePlanAction(CARE_PLAN_ACTIONS.CARE_PLAN_HOME)
									}
								/>
							</ColumnFlex>
						</Form>
					)}
				/>
			</ColumnFlex>
		</ColumnFlex>
	);
};

export default Logistics;
