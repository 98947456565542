import styled from 'styled-components';
import { motion } from 'framer-motion';

import Colors from 'colors';

export const StyledContainer = styled(motion.div)`
	background: white;
	display: flex;
	height: fit-content;
	width: fit-content;
	z-index: 100;
	left: 0;
	display: flex;
	flex-direction: column;
	top: 0;
	border: 1px solid ${Colors.gray};
	border-radius: 5px;
	gap: 15px;

	padding: 30px 25px;
	overflow: auto;
`;

export const StyledFilterTab = styled.div`
	border: 1px solid ${(props) => (props.selected ? Colors.primary.primary : Colors.gray)};
	border-radius: 5px;
	display: flex;
	margin: 0 0 0 10px;
	padding: 5px 15px;
	cursor: pointer;
	align-items: center;

	&:hover span {
		color: ${Colors.primary.primary};
	}

	&:hover {
		border: 1px solid ${Colors.primary.primary};
	}
`;

export const StyledFilterTabText = styled.span`
	font-size: 14px;
	color: ${({ selected }) => (selected ? Colors.primary.primary : Colors.gray)};
`;

export const StyledDropDownContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0.5rem;
`;

export const StyledDropDown = styled.select`
	border: 1px solid ${(props) => (props.selected ? Colors.primary.primary : Colors.gray)};
	border-radius: 5px;
	display: flex;
	margin: 0 0 0 10px;
	padding: 5px 10px;
	min-width: 80px;
	width: 100%;

	font-size: 14px;
	color: ${({ selected }) => (selected ? Colors.primary.primary : Colors.gray)};
`;

export const StyledCustomInput = styled.textarea`
	border: 1px solid ${(props) => (props.selected ? Colors.primary.primary : Colors.gray)};
	border-radius: 5px;
	display: flex;
	margin: 0 0 0 10px;
	padding: 5px 10px;
	min-width: 80px;
	width: 100%;
	height: 50px;

	font-size: 14px;
	color: ${({ selected }) => (selected ? Colors.primary.primary : Colors.gray)};

	&::placeholder {
		color: ${({ selected }) => (selected ? Colors.primary.primary : Colors.gray)};
	}
`;

export const StyledFilterLabelWrapper = styled.div`
	display: flex;
	min-width: 170px;

	text-align: left;
`;

export const StyledLabel = styled.span`
	padding: 5px;
	color: ${Colors.gray};
	font-size: 0.8rem;
`;

export const StyledRangeSelectorWrapper = styled.div`
	width: 80%;

	display: flex;
	flex-direction: column;

	margin: 0.75rem auto 0 auto;
`;
