import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { receiveError } from 'reducers/environment';

import { ColumnFlex, RowFlex } from 'basicStyles';
import Colors from 'colors';

import Text from './Text';
import Button from './Button';
import OnboardFormView from './OnboardFormView';

import { device } from './styled/StyledMediaQuery';

const CustomColumn = styled(ColumnFlex)`
	text-align: left;
	border-radius: 8px;
`;

export const Con = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	gap: 20px;
`;

export const RightTextCon = styled.div`
	width: 100%;
	align-items: right;
	text-align: right;
	position: absolute;
	top: 0;
	padding-top: 16px;
`;
export const Container = styled.div`
	background-color: white;
	border-radius: 50px;
	margin: auto;

	padding: 12px;
	height: auto;
	display: flex;
	flex-direction: column;
	box-shadow: 0 10px 20px rgba(37, 50, 56, 0.19), 0 6px 6px rgba(37, 50, 56, 0.23);

	@media ${device.tablet} {
		padding: 32px;
		flex-direction: row;
		max-width: 1000px;
	}
`;

export const RightCon = styled(Con)`
	background-color: ${Colors.greenLightTeal};
	padding: 32px;
	border: 24px;
	border-radius: 24px;

	@media ${device.mobileM} {
		width: auto;
	}
`;

class OnboardFormHandler extends Component {
	componentWillUnmount() {
		const { receiveError } = this.props;

		receiveError(null);
	}

	render() {
		const { error, validate, handleSubmit, renderInitalValues, theme, children } = this.props;
		return (
			<Container>
				<Con>
					<Formik
						validate={validate}
						onSubmit={handleSubmit}
						initialValues={renderInitalValues()}
						render={(formProps) => {
							return (
								<OnboardFormView
									formProps={formProps}
									theme={theme}
									error={error}
								/>
							);
						}}
					/>
					<RightCon>{children}</RightCon>
				</Con>
			</Container>
		);
	}
}

const THEME_FORM_PROPTYPE = PropTypes.shape({
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
});

const THEME_PROPTYPE = PropTypes.shape({
	title: PropTypes.string.isRequired,
	form: PropTypes.arrayOf(THEME_FORM_PROPTYPE),
	buttonText: PropTypes.string.isRequired,
	alternateAction: PropTypes.string.isRequired,
	alternateActionText: PropTypes.string.isRequired,
});

OnboardFormHandler.defaultProps = {
	error: null,
};
OnboardFormHandler.propTypes = {
	/** Error to show if something has gone wrong, should default as null*/
	error: PropTypes.oneOfType([PropTypes.string]),
	/** Validation func for the current theme form*/
	validate: PropTypes.func.isRequired,
	/** Func that should run when all required fields are valid*/
	handleSubmit: PropTypes.func.isRequired,
	/** Funcs that handles rendering inital values for the form*/
	renderInitalValues: PropTypes.func.isRequired,
	/** object that */
	theme: THEME_PROPTYPE.isRequired,
};

const mapDispatchToProps = (dispatch) => {
	return {
		receiveError: (status) => dispatch(receiveError(status)),
	};
};

export default connect(null, mapDispatchToProps)(OnboardFormHandler);
