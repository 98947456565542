import React from 'react';
import MultiRangeSlider from 'multi-range-slider-react';

import { colors } from '../../../corev2/utils';

import {
	StyledSliderWrapper,
	StyledFilterLabelWrapper,
	StyledLabel,
	StyledRangeSelectorWrapper,
} from './styles';

const Slider = (props) => {
	const { label, minAllow, maxAllow, minValue, maxValue, step, handler } = props;

	return (
		<StyledSliderWrapper>
			<StyledFilterLabelWrapper>
				<StyledLabel>{label}:</StyledLabel>
			</StyledFilterLabelWrapper>

			<StyledRangeSelectorWrapper>
				<MultiRangeSlider
					style={{
						border: 'none',
						boxShadow: 'none',
						paddingBottom: '8px',
					}}
					min={minAllow}
					max={maxAllow}
					ruler={false}
					barInnerColor={colors.primary.primary}
					step={step}
					label={false}
					minValue={minAllow + step}
					maxValue={maxAllow - step}
					onInput={(e) => {
						if (e.minValue !== minValue && e.maxValue !== maxValue) {
							handler({
								minValue: e.minValue,
								maxValue: e.maxValue,
							});
						}
					}}
				/>
			</StyledRangeSelectorWrapper>
		</StyledSliderWrapper>
	);
};

export default Slider;
