import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import {
	StyledJobAction,
	StyledActionDetails,
	StyledJobActionTitle,
	StyledJobActionDescription,
} from './styles';

const JobAction = (props) => {
	const { title, description, goToPage } = props;

	return (
		<StyledJobAction onClick={goToPage}>
			<StyledActionDetails>
				<StyledJobActionTitle>{title}</StyledJobActionTitle>
				<StyledJobActionDescription>{description}</StyledJobActionDescription>
			</StyledActionDetails>

			<FontAwesomeIcon size='lg' icon={faChevronRight} />
		</StyledJobAction>
	);
};

export default JobAction;
