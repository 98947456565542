import React, { Component, Fragment, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import is from 'styled-is';
import { ProviderTileRowFlex, SearchCon } from '../../patient/ProviderSearch/ProviderSearchViews';

import {
	faChevronRight,
	faClock,
	faHome,
	faMars,
	faVenus,
	faChevronDown,
	faGraduationCap,
} from '@fortawesome/free-solid-svg-icons';

import Colors from 'colors';
import { ColumnFlex, RowFlex } from 'basicStyles';

import Title from 'AppComp/Title';
import Icon from 'AppComp/Icon';
import Text from 'AppComp/Text';

import { CareProSection, SectionTopRight } from 'AppComp/patient/CareProProfile/CareProProfilViews';

import JobSchedule from './JobSchedule';
export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	flex: 1;
	height: calc(100% - 40px);
	padding-top: 0;
	margin: 20px 0;
	padding: 32px 16px;
	align-items: center;

	position: relative;
`;

const JobViewHandler = (props) => {
	return (
		<Container>
			<SearchCon>
				<ProviderTileRowFlex
					style={{
						minHeight: '30vh',
						padding: '0',
						flex: 3,
					}}
				>
					<RowFlex
						fullWidth
						style={{
							backgroundColor: 'white',
							padding: '16px 10px',
						}}
					>
						<Title
							subtitle
							color={Colors.primary.primary}
							padding='0 0px'
							margin='0 0 20px 0'
							fontWeight='700'
						>
							Overview
						</Title>
					</RowFlex>
					<RowFlex gap='32px' fullWidth justifyStart padding='10px 32px'>
						<div
							style={{
								borderRadius: '8px',
								backgroundColor: 'white',
								height: '200px',
								width: '140px',
							}}
						/>
						<ColumnFlex gap='16px'>
							<Text largeText padding='0px 0px 5px 0px'>
								Name of the client
							</Text>

							<RowFlex gap='15px' alignCenter>
								<ColumnFlex center gap='5px'>
									<Icon
										size='2x'
										margin='3px'
										color={Colors.secondary.blue}
										icon={'MALE' === 'female' ? faVenus : faMars}
									/>
									<Text color={Colors.secondary.black} fontWeight='100' nurseInfo>
										{'MALE' === 'female' ? 'Female' : 'Male'}
									</Text>
								</ColumnFlex>
								<ColumnFlex center gap='5px'>
									<Icon
										size='2x'
										margin='3px'
										color={Colors.secondary.blue}
										icon={'hourly' === 'hourly' ? faClock : faHome}
									/>
									<Text color={Colors.secondary.black} fontWeight='100' nurseInfo>
										{'Hourly' === 'hourly' ? 'Hourly' : 'Live In'}
									</Text>
								</ColumnFlex>
							</RowFlex>
							<Text>Notes:</Text>
						</ColumnFlex>
					</RowFlex>
					<ColumnFlex
						fullWidth
						style={{
							backgroundColor: 'white',
							padding: '10px 16px',
						}}
					>
						<CareProSection title=' Statement' icon={faHome}>
							<Text>N/A</Text>
						</CareProSection>
						<CareProSection title='CarePlan' icon={faGraduationCap}>
							<Text color='black'>N/A</Text>
						</CareProSection>
					</ColumnFlex>
				</ProviderTileRowFlex>
				<ColumnFlex
					style={{
						flex: '1',
						width: '100%',
					}}
					gap='16px'
				>
					<SectionTopRight
						style={{
							height: 'fit-content',
							padding: '0',
						}}
					>
						<RowFlex
							fullWidth
							style={{
								backgroundColor: 'white',
								padding: '16px 10px',
							}}
						>
							<Title
								subtitle
								color={Colors.primary.primary}
								padding='0 0px'
								margin='0 0 20px 0'
								fontWeight='700'
							>
								Job Offer
							</Title>
						</RowFlex>
					</SectionTopRight>
				</ColumnFlex>
			</SearchCon>
			<SearchCon
				style={{
					jusitfyContent: 'center',
					alignItems: 'flex-start',
				}}
			>
				<JobSchedule />
			</SearchCon>
		</Container>
	);
};

export default JobViewHandler;
