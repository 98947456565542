import React, { useState } from 'react';
import Title from '../../Title';
import { Formik, Form, Field } from 'formik';
import Colors from '../../../constants/colors';
import {
	MARGIN_TITLE,
	FormItemsLength,
	FORM_ITEM_LENGTH,
	OBFormButtonText,
	OBBasicCon,
} from './OnboardViews';
import { ColumnFlex } from '../../styled/Basic';
import { connect } from 'react-redux';

import Text from 'AppComp/Text';

import { validateAuth } from './validation';
import StyledTextInput from 'AppComp/styled/StyledTextInput';

import PageLoaderView from '../../PageLoaderView';

import { handleProviderLogin } from 'reducers/provider';
import api from '../../../scripts/api';

import {
	PROVIDER_MODEL_V2,
	CAREPLAN_MODEL_V2,
	PATIENT_MODEL_V2,
	VERIFICATION_MODEL,
} from 'reducers/graphqlModelTypes';
import { toast } from 'react-toastify';
import { toastErrorConfig, toastSuccessConfig } from '../../../reducers/provider';

const OBPhoneConfirmation = (props) => {
	const {
		state: {
			email,
			firstName,
			lastName,
			password,
			phoneNumber,
			gender,
			care_type,
			hourlyRate,
			liveInRate,
			displayName,
			registry_id,
		},
	} = props;

	const [loading, _loading] = useState(false);
	const [err, _err] = useState(null);

	const handleSubmit = (values, form) => {
		_loading(true);
		handleRegister(values.authCode.trim());
	};

	const handleRegister = async (authCode) => {};

	const resendCode = () => {
		_err(null);
		return new Promise(function (resolve, reject) {
			api.graph({
				query: `mutation {
					verifyProviderPhone(phone_number: "${phoneNumber}", email: "${email}")
				}`,
			})
				.then((res) => {
					//updateOnboardState({ error: null, step: 1, providerInfo: values });
					toast.success('New code sent', toastSuccessConfig);
				})

				.catch((err) => {
					console.log('registerProvider -> err', err);

					if (Array.isArray(err)) {
						_err([0].message);
						toast.error([0].message, toastErrorConfig);
					} else {
						_err('Please try again');
						toast.error('Please try again', toastErrorConfig);
					}
				});
		});
	};
	return (
		<OBBasicCon
			style={{
				maxWidth: '420px',
				marginTop: '20px',
			}}
		>
			<Title
				style={{ textAlign: 'center' }}
				maxWidth={'320px'}
				margin={MARGIN_TITLE}
				subtitle
			>
				FYI values your privacy - We just want to be sure it is you !
			</Title>
			<Text style={{ textAlign: 'center' }} nurseInfo gray>
				Please type the numerical confirmation code that has been texted to you.
			</Text>
			<div style={{ marginBottom: '10px' }} />
			<Formik
				onSubmit={handleSubmit}
				autoComplete={'off'}
				validate={validateAuth}
				render={(formProps) => {
					console.log(formProps);
					return (
						<Form noValidate>
							<ColumnFlex alignCenter justifyCenter fullWidth fullHeight>
								<FormItemsLength margin='30px 0 0 0' width={FORM_ITEM_LENGTH}>
									<Field
										component={StyledTextInput}
										name='authCode'
										label='Auth Code'
										type='text'
									/>
								</FormItemsLength>

								{loading ? (
									<PageLoaderView errorMessage='This may take a min, please hold off from refreshing' />
								) : (
									<OBFormButtonText
										backgroundColor={Colors.teal}
										type='submit'
										valid={formProps.isValid}
										text='Next'
									/>
								)}
								{err && (
									<Text margin='20px 0 0 0 ' bold error>
										{err}
									</Text>
								)}
								{!loading && (
									<Text
										onClick={() => resendCode()}
										margin='20px 0 0 0'
										underline
										nurseInfo
										gray
										pointer
									>
										Re Send Text
									</Text>
								)}
							</ColumnFlex>
						</Form>
					);
				}}
			/>
			<div style={{ marginBottom: '10px' }} />
			<Text
				margin='0 0 20px 0'
				pointer
				onClick={() => props.ChangeLocation(3)}
				subtitle
				underline
			>
				Miss typed? Change Email Or Phone Number
			</Text>
		</OBBasicCon>
	);
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		handleProviderLogin: (provider) => dispatch(handleProviderLogin(provider)),
	};
};

export default connect(null, mapDispatchToProps)(OBPhoneConfirmation);
