import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { STRIPE_ACCOUNT_FILE } from '../../../core/api/models';

const fetchStripeFiles = async () => {
	try {
		const data = await api.graph({
			query: `
				query {
					fetchStripeAccountFiles {
						${STRIPE_ACCOUNT_FILE}
					}
				}
			`,
		});

		return data.fetchStripeAccountFiles;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchStripeAccountFiles = () => {
	return useQuery({
		queryKey: ['fetchStripeAccountFiles'],
		queryFn: () => {
			return fetchStripeFiles();
		},
		retry: false,
	});
};
