import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import PageHeading from '../../../core/components/PageHeading';
import CreateJobPostForm from '../../components/CreateJobPostForm';

import { useAuth } from '../../../core/hooks/useAuth';
import { useCreateJobPostMutation } from '../../api/mutations/useCreateJobPostMutation';

import { jobPostsRoutes } from '../../routes/constants';

import { Notification } from '../../../../../client/modules/core/lib';
import { notifications } from '../../../../../client/modules/core/constants';

import { StyledCreateJobPostPage } from './styles';

const CreateJobPostPage = () => {
	const [serverError, setServerError] = useState('');
	const history = useHistory();

	const { authUser } = useAuth();
	const createJobPostMutation = useCreateJobPostMutation();

	const createJobPostHandler = async (values) => {
		try {
			setServerError('');
			const resItem = await createJobPostMutation.mutateAsync({
				title: values.title,
				description: values.description,
				carePlanId: authUser.carePlan.id,
				registryId: 1,
			});

			Notification(notifications.jobPosts.created);
			history.replace(`${jobPostsRoutes.jobPost(resItem.id)}`);
		} catch (error) {
			console.error('Create job post form failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledCreateJobPostPage>
			<PageHeading>Create Job Post </PageHeading>

			<CreateJobPostForm
				initialValues={{ title: '', description: '' }}
				createJobPostHandler={createJobPostHandler}
				serverError={serverError}
			/>
		</StyledCreateJobPostPage>
	);
};

export default CreateJobPostPage;
