import React, { useState, useEffect } from 'react';

import { ParentContainer } from '../../ProviderHomeDashboard';
import { isMobile } from 'react-device-detect';

import Text from 'AppComp/Text';
import { ColumnFlex } from 'basicStyles';
import Button from 'AppComp/Button';
import Title from 'AppComp/Title';
import Colors from 'colors';

import { OnboardParentCon } from '../handler';

import ProfileInfo from './ProfileInfo';

const ProfileHandler = (props) => {
	const {
		verification,
		stripeVerificationInfo,
		isLoading,
		setIsLoading,
		updateProviderProfileInfo,
		receiveStepsProgress,
	} = props;
	const [step, setStep] = useState(0);

	useEffect(() => {
		determineStep();
	}, [verification, stripeVerificationInfo]);
	const determineStep = () => {
		if (verification.professional_statement === null) {
			setStep(0);
			receiveStepsProgress(0);
			return;
		} else {
			setStep(1);
			receiveStepsProgress(0.5);
			return;
		}
	};

	const handleShowView = () => {
		if (step === 0) {
			return (
				<OnboardParentCon step={step}>
					<ProfileInfo {...props} />
				</OnboardParentCon>
			);
		} else {
			return (
				<OnboardParentCon step={step}>
					<Text color={Colors.yellow} largeText>
						Your Stripe information is undergoing verification.
					</Text>
				</OnboardParentCon>
			);
		}
	};
	return <ParentContainer>{handleShowView()}</ParentContainer>;
};

export default ProfileHandler;
