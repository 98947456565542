import React, { useEffect, useState } from 'react';
import Title from '../../Title';
import { Formik, Form, Field } from 'formik';
import Colors from '../../../constants/colors';
import {
	MARGIN_TITLE,
	FormItemsLength,
	FORM_ITEM_LENGTH,
	OBFormButtonText,
	OBBasicCon,
} from './OnboardViews';
import { ColumnFlex, RowFlex } from '../../styled/Basic';
import { connect } from 'react-redux';
import { searchProviders } from 'reducers/provider';

import Text from 'AppComp/Text';

import { validatePw } from './validation';
import StyledTextInput, { StrongPasswordTextInput } from 'AppComp/styled/StyledTextInput';
import Icon from 'AppComp/Icon';
import {
	faEnvelope,
	faInfo,
	faKey,
	faMapMarkerAlt,
	faSearchLocation,
} from '@fortawesome/free-solid-svg-icons';
import PageLoaderView from '../../PageLoaderView';
import api from '../../../scripts/api';

import { BackText } from '../../patient/Onboard/OnboardViews';

const OB3ProviderPassword = (props) => {
	const [isValidOverride, setValidOverride] = useState(false);

	const [loading, _loading] = useState(false);
	const [err, _err] = useState(null);

	const handleSubmit = (values, form) => {
		const newState = {
			...props.state,
			email: values.email,
			password: values.password,
			phoneNumber: values.phone,
		};
		props._UPS(newState);

		_loading(true);

		handleVerifyUser(newState);
	};

	const handleVerifyUser = async (newState) => {
		try {
			await api.graph({
				query: `mutation {
					verifyProviderPhone(phone_number: "${newState.phoneNumber}", email: "${newState.email}")
				}`,
			});

			_loading(false);
			props.ChangeLocation(4);
		} catch (err) {
			_loading(false);
			console.log(err);
			if (Array.isArray(err)) {
				_err(err[0].message);
			} else {
				_err('Something went wrong');
			}
		}
	};

	const handleInitValue = () => {
		const { state } = props;

		// check if props.state care_type & rate are not null
		if (state.email && state.phoneNumber && state.password) {
			return {
				email: props.state.email,
				phone: props.state.phoneNumber,
			};
		} else {
			return {};
		}
	};

	return (
		<OBBasicCon
			style={{
				maxWidth: '420px',
			}}
		>
			{/* <Icon size='3x' color={Colors.greenLightTeal} icon={faKey} /> */}
			<Title
				style={{ textAlign: 'center' }}
				maxWidth={'320px'}
				margin={MARGIN_TITLE}
				subtitle
			>
				One Step Closer for care that's for you
			</Title>
			<Text nurseInfo gray>
				enter what you'd like to be your account password
			</Text>
			<div style={{ marginBottom: '10px' }} />
			<Formik
				onSubmit={handleSubmit}
				autoComplete={'off'}
				validate={validatePw}
				initialValues={handleInitValue()}
				render={(formProps) => {
					console.log('pobpassword', formProps);
					return (
						<Form noValidate>
							<ColumnFlex alignCenter justifyCenter fullWidth fullHeight>
								<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
									<Field
										name='email'
										type='text'
										component={StyledTextInput}
										label='Email'
									/>
								</FormItemsLength>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'items-start',
										justifyContent: 'flex-start',
										margin: '20px 0 0 0',
										position: 'relative',
									}}
								>
									<Title
										style={{
											textAlign: 'left',
											position: 'absolute',
											left: '-40px',
											top: '0',
											bottom: '0',
											zIndex: '20',
											height: '20px',
										}}
										maxWidth={'320px'}
										margin={'0px 0 0 0'}
										subtitle
										padding={'34px 0 0 0'}
									>
										+1
									</Title>
									<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
										<Field
											component={StyledTextInput}
											name='phone'
											label='Phone Number*'
											type='tel'
										/>
									</FormItemsLength>
								</div>

								<FormItemsLength margin='30px 0 0 0' width={FORM_ITEM_LENGTH}>
									<Field
										component={StrongPasswordTextInput}
										name='password'
										label='Password*'
										type='password'
									/>
								</FormItemsLength>
								<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
									<Field
										component={StyledTextInput}
										name='confirm_password'
										label='Confirm Password*'
										type='password'
									/>
								</FormItemsLength>

								{loading ? (
									<PageLoaderView errorMessage='Something seems to be wrong with our connection' />
								) : (
									<OBFormButtonText
										backgroundColor={Colors.primary.primary}
										type='submit'
										valid={isValidOverride || formProps.isValid}
										text='Next'
									/>
								)}
								{err && (
									<Text margin='20px 0 0 0 ' bold error>
										{err}
									</Text>
								)}
							</ColumnFlex>
						</Form>
					);
				}}
			/>
			<BackText onClick={() => props.ChangeLocation(2)}>Back</BackText>
		</OBBasicCon>
	);
};

export default OB3ProviderPassword;
