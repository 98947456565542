export const authRoutes = {
	login: '/care-pro/auth/login',
	onboarding: {
		home: '/care-pro/auth/onboarding',
		platform: {
			home: '/care-pro/auth/onboarding/platform',
			personalInfo: '/care-pro/auth/onboarding/platform/personal',
			accountInfo: '/care-pro/auth/onboarding/platform/account',
			authCode: '/care-pro/auth/onboarding/platform/verify',
		},
		professionalStatement: '/care-pro/auth/onboarding/professional-statement',
		skills: '/care-pro/auth/onboarding/skills',
		stripe: {
			home: '/care-pro/auth/onboarding/stripe',
			address: '/care-pro/auth/onboarding/stripe/address',
			payouts: '/care-pro/auth/onboarding/stripe/payouts',
			personal: '/care-pro/auth/onboarding/stripe/personal',
			tos: '/care-pro/auth/onboarding/stripe/tos',
		},
	},
};
