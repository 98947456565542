import React, { useState, useEffect } from 'react';

import DynamicFilter from 'AppComp/core/DynamicFilter';

import { filterParser } from 'AppComp/core/DynamicFilter';

// DEFINE FILTERS FOR A CLIENT'S VIEWS OF JOB POSTS

const filters = {
	type: {
		type: 'tags',
		name: 'type',
		label: 'Type',
		options: [
			{ name: 'Hourly', value: 'hourly', selected: false },
			{ name: 'Live In', value: 'live-in', selected: false },
		],
	},
	gender: {
		type: 'tags',
		name: 'gender',
		label: 'Gender',
		options: [
			{ name: 'Male', value: 'male', selected: false },
			{ name: 'Female', value: 'female', selected: false },
			{ name: 'No Preference', value: 'no-preference', selected: false },
		],
	},
	location: {
		type: 'drop-down',
		name: 'location',
		label: 'Location',
		options: {
			city: [
				{ name: 'City', value: 'City', selected: false },
				{ name: 'New York', value: 'New York', selected: false },
				{ name: 'San Francisco', value: 'San Francisco', selected: false },
			],
		},
	},
	hourly_price: {
		type: 'slider',
		name: 'hourly_price',
		label: 'Hourly Price',
		minValue: 0,
		maxValue: 0,
		minAllow: 5,
		maxAllow: 50,
		step: 5,
		active: false,
	},
	// OPTIONAL DROP-DOWN LIST
	// price: {
	// 	type: 'drop-down',
	// 	name: 'price',
	// 	label: 'Price',
	// 	options: {
	// 		min: [
	// 			{ name: 'Min', value: 'Min', selected: false },
	// 			{ name: '1', value: 1, selected: false },
	// 			{ name: '2', value: 2, selected: false },
	// 		],
	// 		max: [
	// 			{ name: 'Max', value: 'Max', selected: false },
	// 			{ name: '1', value: 1, selected: false },
	// 			{ name: '2', value: 2, selected: false },
	// 		],
	// 	},
	// },
};

const JobPostSearchFilter = (props) => {
	const { searchJSONHandler } = props;

	const [searchJSON, setSearchJSON] = useState({});
	const [searchFilters, setSearchFilters] = useState(filters);

	useEffect(() => {
		searchJSONHandler(searchJSON);
	}, [searchJSON]);

	// Function to write custom parsing logic to handle use cases such as persisting to DB, and
	// sending saerch queries to backend to fetch data.

	const customParser = (filters) => console.log(filters);

	const searchFilterHandler = (name, option, selectValue) => {
		const { newFilters, json } = filterParser(
			customParser,
			searchFilters,
			name,
			option,
			selectValue
		);

		setSearchFilters(newFilters);
		setSearchJSON(json);
	};

	return <DynamicFilter filters={searchFilters} filterHandler={searchFilterHandler} />;
};

export default JobPostSearchFilter;
