import styled from 'styled-components';

import Colors from 'colors';

import { device } from 'AppComp/styled/StyledMediaQuery';

export const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 120px);

	background-color: ${Colors.theme.primary_background};

	padding: 1rem;
`;

export const StyledProfileContainer = styled.div`
	width: 100%;
	max-width: 1000px;
	margin: auto;
	padding: 0.75rem;

	border: 1px solid ${Colors.primary.primary};
	border-radius: 32px;
	background-color: white;

	display: flex;
	flex-direction: column;
	flex: 1;

	@media ${device.tablet} {
		flex-direction: row;

		padding: 2rem;
	}
`;

export const StyledProfileWrapper = styled.div`
	width: 100%;
	text-align: center;

	padding: 0 1rem 0 1rem;

	@media ${device.tablet} {
		padding: 0 3rem 0 1rem;
		width: 50%;

		text-align: left;
	}
`;

export const StyledPersonalInfoContainer = styled.div`
	display: flex;
	flex-direction: column;

	margin-bottom: 0.75rem;

	@media ${device.tablet} {
		flex-direction: row;
	}
`;

export const StyledPersonalInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;

	margin-top: 0.25rem;
	margin-left: 0rem;

	@media ${device.tablet} {
		margin-left: 1rem;
	}
`;

export const StyledLocation = styled.span`
	font-size: 0.75rem;
	color: ${Colors.primary.new_primary};

	margin: 0.75rem 0 0 0;
`;

export const StyledGender = styled.span`
	font-size: 0.75rem;
	color: ${Colors.primary.new_primary};

	margin: 0.25rem 0 0 0;
`;

export const StyledDescription = styled.p`
	font-size: 0.75rem;
	color: ${Colors.primary.new_primary};
	word-wrap: break-word;

	margin-bottom: 1rem;
`;

export const StyledOfferButtonContainer = styled.div`
	display: flex;
	justify-content: center;

	margin-top: 1rem;
`;

export const StyledReminderTextContainer = styled.div`
	text-align: center;

	margin-top: 2rem;
`;

export const StyledReminderText = styled.p`
	font-size: 0.75rem;
	font-style: italic;
	color: ${Colors.primary.new_primary};
`;

export const StyledInformationContainer = styled.div`
	width: 100%;
	margin-top: 2rem;

	@media ${device.tablet} {
		width: 50%;
	}
`;

export const StyledInformationHeading = styled.h2`
	font-size: 1.5rem;
	color: ${Colors.primary.new_primary};

	margin-bottom: 1.5rem;
`;

export const StyledTraitsContainer = styled.div`
	width: 100%;
	margin-top: 2rem;

	display: flex;
	flex-direction: column;
`;

export const StyledAbility = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;

	justify-content: space-between;
	margin-bottom: 1rem;
`;

export const StyledTraitHeading = styled.h3`
	font-size: 1rem;
	font-weight: bold;
	color: ${Colors.primary.new_primary};
`;

export const StyledTraitValue = styled.p`
	font-size: 0.75rem;
	color: ${Colors.primary.new_primary};

	width: 200px;
	text-align: right;
`;
