import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { JOB_POST_MODEL } from '../../../core/api/models';

const offerCarePro = async ({ startDate, endDate, jobPostId, careProId, hourlyRate, shifts }) => {
	try {
		const data = await api.graph({
			query: `mutation {
					    offerProviderJob(
							start_date: "${startDate}",
							end_date: "${endDate}",
						    job_post_id: ${jobPostId},
						    provider_id: ${careProId},
						    hourly_rate: ${hourlyRate}
						    shifts: ${api.graphStringify(shifts)}
					    ){
						    ${JOB_POST_MODEL}
					    }
				    }`,
		});

		return data.offerProviderJob;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useOfferCarePro = () => {
	return useMutation({
		mutationKey: ['offerCarePro'],
		mutationFn: (values) => {
			return offerCarePro(values);
		},
	});
};
