import moment from 'moment';

export const formatIncomingShift = (incomingShift) => {
	return {
		id: incomingShift.id,
		jobId: incomingShift.job_id || null,
		scheduleId: incomingShift.schedule_id || null,
		title: 'Care Session',
		startDate: incomingShift.start_date,
		startTime: moment(
			`${incomingShift.start_date} ${incomingShift.start_time}`,
			'YYYY-MM-DD HH:mm:ss'
		).format('MMMM D, YYYY [at] h:mm A'),
		endDate: incomingShift.end_date,
		endTime: moment(
			`${incomingShift.end_date} ${incomingShift.end_time}`,
			'YYYY-MM-DD HH:mm:ss'
		).format('MMMM D, YYYY [at] h:mm A'),
	};
};
