var greenTeal = '#219086';
var greenLightTeal = '#2BAFA4';

const Colors = {
	fullWhite: '#FFFFFF',
	grayWhite: '#FDFDFD',
	activeWhite: '#F6F6F6',
	blue: '#264CAB',
	yellow: '#FFB621',
	teal: '#15959A',
	greenLightTeal: '#2BAFA4',
	emerald: '#2CDA9D',
	greenTeal: '#219086',
	black: '#2D2D2D',
	gray: '#969696',
	darkerGray: '#636363',
	lightBlack: '#47495A',
	heartRed: '#E06C7F',
	ratingStar: '#F5A623',
	tealGradient: `background-image: linear-gradient(90deg, ${greenTeal} 0%, ${greenLightTeal} 95%)`,
	grey: '#E5E5E5',
	red: '#E60202',
	lightGrey: '#F6F6F6',
	littleBitDarkerLightGray: '#F5F5F5',
	textBlack: '#2D2D2D',
	lightBlue: '#5FAFE7',
	darkBlue: '#2b69a3',
	primary: {
		primary: '#2089d5',
		primary_shade_down: '#3C9DE2',
		primary_light_shade: '#EEF6FC',
		new_primary: '#2D6DA6',
		primary_accent: '#fafafa',
	},
	theme: {
		primary_background: '#EDF6FB',
		dark_accent_background: '#DEEEF8',
		gray_background: '#F6F6F6',
	},
	memebrship: {
		gold: '#FFD700',
		silver: '#C0C0C0',
		bronze: '#CD7F32',
	},
	secondary: {
		blue: '#2b69a3',
		light_blue: '#ab3e5b',
		black: '#424242',
		yellow: '#f0e989',
		green: '#1e857b',
		white: '#ffffff',
	},
};

export default Colors;
