import styled from 'styled-components';

import { colors, mediaQueries } from '../utils';

export const StyledCalendarContainer = styled.div`
	border: 1px solid rgba(43, 105, 166, 0.3);
	border-radius: 10px;
	padding-top: 32px;
	padding-right: 32px;
	padding-bottom: 16px;
	padding-left: 32px;
	background-color: ${colors.white};
	display: flex;
	column-gap: 10px;
	flex-direction: column;
	align-items: center;
`;

export const StyledCalendarWrapper = styled.div`
	width: 100%;
	height: 70vh; /* Adjust to a responsive height based on viewport height */
	height: 1000px; /* Ensure the calendar never collapses */
	margin-top: 20px;

	.rbc-calendar {
		height: 100% !important; /* Ensures that the calendar takes full height */
	}

	/* Ensure full width for smaller devices */
	@media (max-width: 768px) {
		height: 60vh;
	}

	@media (max-width: 576px) {
		height: 50vh;
	}
`;

export const StyledCustomReactBigCalendarWrapper = styled.div`
	/* General Calendar Styling */
	.rbc-calendar {
		border-radius: 10px;
		margin: 20px 0;
		padding: 10px;
	}

	/* Hide the default toolbar */
	.rbc-toolbar {
		display: none;
	}

	/* Remove overall border and keep only on date cells */
	.rbc-month-view {
		border-collapse: collapse;
		border: none !important; /* Ensure no borders are applied */
	}

	.rbc-month-row {
		border: 1px solid #ddd; /* Add borders around month rows */
	}

	/* Header styles (Mon, Tue, Wed, etc.) */
	.rbc-header {
		font-size: 14px;
		line-height: 1.6; /* Adjusted line-height value */
		color: black !important;
		text-align: left;
		padding: 10px;
		border: none; /* Remove the border from day names */
		text-transform: uppercase;
		font-weight: 100;
	}

	/* Date Cell Styles */
	.rbc-date-cell {
		text-align: left;
		padding: 10px;
		font-size: 18px; /* Adjust the size of the date number */
		font-weight: 600;
		color: #333;
	}

	/* Highlight cells that are out of the current month range */
	.rbc-off-range-bg {
		background-color: white; /* Light grey for dates outside the current month */
	}

	.rbc-day-bg:hover {
		border-bottom: 2px solid #5d5d5d;
	}

	/* Add some styling to dates out of range */
	.rbc-off-range {
		color: #bbb; /* Grey out the dates from previous/next month */
	}

	/* Custom styling for the number in the date cell */
	.rbc-date-cell button {
		font-size: 24px;
		font-weight: 700;
		color: #5d5d5d; /* Change color of the number */
		display: block;
		padding: 10px;
		margin-bottom: 5px;
	}

	@media (max-width: 768px) {
		.rbc-date-cell button {
			font-size: 16px;
			font-weight: normal; /* Remove font weight on smaller screens */
		}
	}

	/* Remove any border from the overall calendar structure */
	.rbc-row {
		border: none;
	}

	/* Remove borders between the days of the week (Mon, Tue, Wed, etc.) */
	.rbc-header + .rbc-header {
		border: none;
	}

	/* Fix for potential issue with calendar month day styling */
	.calendar-month__day:after {
		background-color: transparent;
		bottom: 0;
		content: '';
		display: block;
		height: 2px;
		left: -1px;
		position: absolute;
		transition: var(--tec-transition-background-color);
		width: calc(100% + 2px);
	}

	.rbc-off-range {
		color: red;
	}
`;
