import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { SHIFT_MODEL } from '../../../core/api/models';

const endShift = async ({ shiftId, lat, long }) => {
	try {
		const data = await api.graph({
			query: `mutation {
        				endShift (shift_id: ${shiftId}, location_lat: ${lat}, location_long: ${long}) {
					${SHIFT_MODEL}
				}
			}`,
		});

		return data.endShift;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const useEndShiftMutation = () => {
	return useMutation({
		mutationKey: ['endShift'],
		mutationFn: (values) => {
			return endShift(values);
		},
	});
};
