import React, { useState } from 'react';

import {
	OnboardFormContainer,
	OnboardTitle,
	OnboardSubText,
	OnboardButton,
	OnboardFormButton,
	FormItemsLength,
} from '../../../../../provider/CareProOnboard/CareProOnboardViews';
import { ColumnFlex, RowFlex } from 'basicStyles';
import { Hearts } from 'react-loader-spinner';

import Colors from 'colors';

import { CardSection } from 'AppComp/patient/PatientCardCollection/PatientCardCollectionViews';

import api from 'scripts/api';

import {
	useStripe,
	useElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from '@stripe/react-stripe-js';

import { ACCEPTED_CARDS } from 'constants';

import Icon from 'AppComp/Icon';
const BankInfo = (props) => {
	const { loading, updateProviderVerificationInfo } = props;

	const [err, updateErr] = useState('');
	const [addPaymentMethodFormIsValid, setAddPaymentMethodFormIsValid] = useState(false);

	const stripe = useStripe();
	const elements = useElements();

	const addPaymentMethodFormValidityHandler = (validity) => {
		setAddPaymentMethodFormIsValid(validity);
	};

	const createFinancialConnection = async () => {
		try {
			updateErr('');
			const res = await api.graph({
				query: ` {
					createFinancialConnectionSession 
				}`,
			});

			if (res) {
				console.log('res', res);
				const { createFinancialConnectionSession } = res;
				console.log('createFinancialConnectionSession', createFinancialConnectionSession);
				const collectThing = await stripe.collectBankAccountToken({
					clientSecret: createFinancialConnectionSession,
				});
				console.log('collectThing', collectThing);
				if (collectThing.error) {
				} else {
					// we can assume everythign went well
					// ensure collectThing has token field and get the id
					if (collectThing.token) {
						updateProviderVerificationInfo({
							token: collectThing.token.id,
						});
					}
				}
			}
		} catch (err) {
			console.log('err', err);
		}
	};

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || (!elements && useStripe)) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}
		console.log(elements.getElement(CardNumberElement));
		try {
			updateErr('');
			const result = await stripe.createToken(elements.getElement(CardNumberElement), {
				currency: 'usd',
			});

			console.log('result', result);

			if (result.error) {
				// Display result.error.message in your UI.

				updateErr(result.error.message);
			} else {
				updateProviderVerificationInfo({
					token: result.token.id,
				});
			}
		} catch (err) {
			console.log('err', err);

			updateErr('Something went wrong please try again');
			toast.error('Something went wrong please try again', toastErrorConfig);
		}
	};

	const renderAcceptedCards = () => {
		return ACCEPTED_CARDS.map((d, i) => {
			return (
				<div key={'card-key-' + d.id}>
					<Icon size='2x' color={Colors.primary.primary} icon={d.icon} />
				</div>
			);
		});
	};

	return (
		<>
			{/* <OnboardFormContainer center>
				<OnboardTitle>Add a Bank </OnboardTitle>
				<OnboardSubText>
					Easily add a bank account in order to recieve your payments
				</OnboardSubText>
				{loading ? (
					<p>Loading...</p>
				) : (
					<OnboardButton onClick={() => createFinancialConnection()}>
						Collect Bank Info
					</OnboardButton>
				)}
			</OnboardFormContainer> */}
			{/* <div
				style={{
					padding: '20px 0',
				}}
			/>
			<OnboardSubText>Or manually add a debit card </OnboardSubText> */}
			<div
				style={{
					padding: '20px 0',
				}}
			/>
			<OnboardFormContainer center>
				<OnboardTitle> Debit Card Information</OnboardTitle>
				<OnboardSubText>Please Input Your Debit Card Information Here</OnboardSubText>
				{process.env.ENVIRONMENT === 'DEVELOPMENT' && (
					<OnboardSubText>Test: 4000-0566-5566-5556</OnboardSubText>
				)}

				{err && (
					<OnboardSubText
						style={{
							color: Colors.red,
							textAlign: 'center',
						}}
					>
						{err}
					</OnboardSubText>
				)}
				<ColumnFlex center fullWidth>
					<form onSubmit={handleSubmit}>
						<CardSection
							addPaymentMethodFormValidityHandler={
								addPaymentMethodFormValidityHandler
							}
						/>
						<RowFlex center>
							{/* <button disabled={!stripe}>Save Card</button> */}
							{loading ? (
								<Hearts color={Colors.primary.primary} height={50} width={50} />
							) : (
								<OnboardFormButton
									backgroundColor={Colors.primary.primary}
									type='submit'
									valid={stripe}
								>
									Add Payout Method
								</OnboardFormButton>
							)}
						</RowFlex>
					</form>
				</ColumnFlex>
			</OnboardFormContainer>
		</>
	);
};

export default BankInfo;
