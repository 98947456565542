import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import FormWrapper from '../FormWrapper';
import InputContainer from '../InputContainer';

import { FormContainer, Input, Label, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';
import Text from '../../../../../corev2/Text';

import loginFormValidationSchema from './validation';

const LoginForm = (props) => {
	const { serverError, initialValues, loginHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => loginHandler(values),
		validationSchema: toFormikValidationSchema(loginFormValidationSchema),
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Label> Email </Label>

					<Input
						name='email'
						type='email'
						onChange={formik.handleChange}
						placeholder='Email'
						value={formik.values.email}
					/>

					{formik.errors.email && <ErrorMessage> {formik.errors.email} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Label> Password </Label>

					<Input
						type='password'
						name='password'
						onChange={formik.handleChange}
						placeholder='Password'
						value={formik.values.password}
					/>

					{formik.errors.password && (
						<ErrorMessage> {formik.errors.password} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<PrimaryButton size='small' type='submit'>
						Login
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>

				<Text>
					By logging in, you agree to the FYI Terms and Conditions & Privacy Policy.
				</Text>
			</FormWrapper>
		</FormContainer>
	);
};

export default LoginForm;
