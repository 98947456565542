import { useQuery } from '@tanstack/react-query';

import api from 'scripts/api';
import { MIN_TRAIT_TEMPLATE_MODEL } from '../models';

const fetchRegistryTraitTemplates = async () => {
	try {
		const data = await api.graph({
			query: `{
        fetchRegistryTraitTemplates {
          ${MIN_TRAIT_TEMPLATE_MODEL}
        }
      }`,
		});

		return data.fetchRegistryTraitTemplates;
	} catch (err) {
		console.log(err);
		throw err;
	}
};

const useFetchRegistryTraitTemplates = () => {
	return useQuery({
		queryKey: ['fetchRegistryTraitTemplates'],
		queryFn: () => {
			return fetchRegistryTraitTemplates();
		},
	});
};

export default useFetchRegistryTraitTemplates;
