import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledDocumentsListContainer = styled.div`
	width: 100%;
	background-color: #edf6fb;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;

	@media (max-width: 768px) {
		padding: 10px;
	}

	@media (max-width: 480px) {
		padding: 5px;
	}
`;

export const StyledDocumentsListWrapper = styled.div`
	width: 100%;
	max-width: 1000px;
	box-sizing: border-box;
	padding: 20px;
	background-color: white;
	border: 1px solid rgba(43, 105, 166, 0.3);
	color: #2b69a3;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	margin-bottom: 24px;

	@media (max-width: 768px) {
		padding: 15px;
		max-width: 100%;
	}

	@media (max-width: 480px) {
		padding: 10px;
	}
`;

export const StyledTitle = styled.h3`
	font-size: 18px;
	font-weight: 700;
	color: #2b69a3;
	letter-spacing: 2px;
	text-transform: uppercase;
	margin-bottom: 15px;
	text-align: left;

	@media (max-width: 480px) {
		font-size: 16px;
	}
`;

export const StyledDocumentList = styled.div`
	background-color: #eaf4fc; /* Slightly darker background for the list */
	border-radius: 8px;
	padding: 20px;
	border: 1px solid #cbd4db; /* Border to separate from the background */
`;

export const StyledDocument = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px 0;
	font-size: 15px;
	line-height: 1.7;

	&:last-child {
		border-bottom: none; /* No border for the last item */
	}
`;

export const StyledDocumentTitle = styled.a`
	margin: 0;
	font-size: 15px;
	line-height: 1.7;
	color: #2b69a3;

	&:hover {
		text-decoration: underline;
	}
`;

export const StyledDocumentDate = styled.span`
	font-size: 15px;
	color: #4a6f91; /* Date color */
`;

export const StyledDocumentRejectionNote = styled.p`
	font-size: 15px;
	font-weight: bold;
	color: #4a6f91; /* Date color */
`;
