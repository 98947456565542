import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { ParentContainer, PHDColumn } from '../ProviderHomeDashboard';
import { device } from 'AppComp/styled/StyledMediaQuery';
import { isMobile } from 'react-device-detect';

import Text from 'AppComp/Text';
import { ColumnFlex } from 'basicStyles';
import Button from 'AppComp/Button';
import Title from 'AppComp/Title';
import Colors from 'colors';

import Email from './Email';
import Steps from './Steps';

const Certification = ({ handleGoToNextStep }) => {
	const handleOfficeMoreInfo = () => {
		handleExitClick();
		showInfoModel({
			show: true,
			title: 'We Must Verify Your Certificates..',
			text: 'FYI is a marketplace that give you the opportunity to create your own personal business profile. Clients that are looking for Care Providers will be able to search FYI and perhaps select you! Our annual fees are reasonable. They can be upgraded at any time, and you pay only the difference. Each tier allows you to enhance the business profile that you want potential clients to be able to see.',
		});
	};

	return (
		<>
			<Title color={'black'} bold subtitle>
				Certifications Verification
			</Title>
			<Text
				style={{
					textAlign: 'center',
				}}
			>
				Please visit our office to verify your certifications.
			</Text>
			<ColumnFlex
				style={{
					maxWidth: '320px',
				}}
				padding='0 0 12px 0'
				margin='20px auto 0 auto'
				center
				gap='12px'
			>
				<Text
					style={{
						textAlign: 'center',
					}}
				>
					10400 Griffin Rd #210, Cooper City, FL 33328
				</Text>
				<Text
					style={{
						textAlign: 'center',
					}}
				>
					Hours (Monday - Friday : 9:30am - 5:30pm. (Closed On Weekends))
				</Text>
				<Text
					style={{
						textAlign: 'center',
					}}
				>
					Phone (954) 744-8424
				</Text>
			</ColumnFlex>
		</>
	);
};

export default Certification;
