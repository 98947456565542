import React, { Component, useState, useMemo, useEffect } from 'react';

import Colors from 'colors';
import styled from 'styled-components';
import { Views } from 'react-big-calendar';
import Select from 'react-select';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';

import { ColumnFlex, RowFlex } from 'basicStyles';

import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export const ActionSection = styled.div`
	height: fit-content;
`;

const ClientSchedulePartialToolbar = (props) => {
	const {
		currentDate,
		view,
		setView,
		displayLabel,
		selectedDateTileType,
		setSelectedDateTileType,
		setUserSelectedDate,
	} = props;
	const renderDropDownForView = () => {
		const options = [
			{
				label: 'Day',
				value: Views.DAY,
			},
			{
				label: 'Week',
				value: Views.WEEK,
			},
			{
				label: 'Month',
				value: Views.MONTH,
			},
			{
				label: 'Agenda',
				value: Views.AGENDA,
			},
		];

		return (
			<Select
				placeholder={view.label}
				options={options}
				className='doc-filter-select'
				defaultValue={view.value}
				onChange={(selectedOption) => {
					setView(selectedOption);
				}}
			/>
		);
	};

	const handleUserSelectDateChange = (direction) => {
		// direction = true => forward
		// direction = false => backward

		// based on the selected view is how much we'll alter the selected date
		// if view is day, we'll alter by 1 day
		// if view is week, we'll alter by 7 days
		// if view is month, we'll alter by 30 days

		let newDate = new Date(currentDate);
		if (view.value === Views.DAY) {
			newDate.setDate(newDate.getDate() + (direction ? 1 : -1));
		}
		if (view.value === Views.WEEK) {
			newDate.setDate(newDate.getDate() + (direction ? 7 : -7));
		}
		if (view.value === Views.MONTH) {
			newDate.setDate(newDate.getDate() + (direction ? 30 : -30));
		}
		setUserSelectedDate(newDate);
	};
	return (
		<ActionSection>
			<ColumnFlex
				style={{
					backgroundColor: 'white',
					borderRadius: '12px',
					padding: '16px',
				}}
			>
				<RowFlex fullWidth justifyBetween>
					<Title
						subtitle
						color={Colors.greenLightTeal}
						margin='0 0 0px 0'
						fontWeight='700'
					>
						{displayLabel}
					</Title>
					<RowFlex
						alignCenter
						gap='12px'
						style={{
							cursor: 'pointer',
						}}
					>
						<Icon
							onClick={() => handleUserSelectDateChange(false)}
							icon={faChevronLeft}
							color={Colors.greenLightTeal}
							size='md'
						/>
						<Icon
							onClick={() => handleUserSelectDateChange(true)}
							icon={faChevronRight}
							color={Colors.greenLightTeal}
							size='md'
						/>
					</RowFlex>
				</RowFlex>

				<ColumnFlex
					style={{
						borderRadius: '16px',
						//backgroundColor: Colors.lightGrey,
						padding: '10px 10px 10px 10px',
					}}
					fullWidth
					margin='10px 0 12px 0'
					alignCenter
				>
					<ColumnFlex fullWidth alignStart>
						{/* <Text
							alignStart
							fontStyle='bold'
							color='#969696'
							fontWeight='100'
							mediumText
						>
							View
						</Text> */}
						{renderDropDownForView()}
					</ColumnFlex>
				</ColumnFlex>
			</ColumnFlex>
		</ActionSection>
	);
};

export default ClientSchedulePartialToolbar;
