import React, { useState, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import ProviderHomeDashboard from './ProviderHomeDashboard';
import SettingsPage from './SettingsPage';
import ProviderProfilePage from './ProviderProfilePage';
import Blocked from './Blocked';
import BasicOverlayWrapper from '../BasicOverlayWrapper';

import Payments from './Payments';

import MobileCheckHOC from '../MobileCheckHOC';
import BrowserCheckHOC from '../BrowserCheckHOC';

import ProviderHomeLanding from './ProviderHomeLanding/ProviderHomeLandingHandler';

import CheckOutOnboard from './CheckoutOnboard';

import CareProChat from './CareProChat';
import Documents from './Documents';
import Shift from './Shift';
import Jobs from './Jobs';
import CareProSchedule from './CareProSchedule';
import ClientsPage from './ClientsPage';
import ClientProfilePage from './ClientProfilePage';
import CareAbilitiesPage from './CareAbilitiesPage';
import JobPostsPage from './JobPostsPage';
import ApplyToJobPostPage from './ApplyToJobPostPage';
import VerifyBanking from './CareProOnboard/BankingOnboard/VerifyBanking';
import CareProOnboardParent from './CareProOnboard';

const ProviderRoutes = (props) => {
	return (
		<>
			<Switch>
				<Route
					path='/carepro'
					exact
					children={(props) => {
						return <ProviderHomeLanding {...props} />;
					}}
				/>
				<Route
					path='/carepro/calendar'
					exact
					children={(props) => {
						return <CareProSchedule {...props} />;
					}}
				/>

				<Route
					path='/carepro/blocked'
					exact
					children={(props) => {
						return <Blocked {...props} />;
					}}
				/>

				<Route
					path='/carepro/profile'
					exact
					children={(props) => {
						return <ProviderProfilePage {...props} />;
					}}
				/>
				<Route
					path='/carepro/stripe'
					exact
					children={(props) => {
						return <SettingsPage {...props} />;
					}}
				/>
				<Route
					path='/carepro/payments'
					exact
					children={(props) => {
						return <Payments {...props} />;
					}}
				/>
				<Route
					path='/carepro/payouts'
					exact
					children={(props) => {
						return <PayoutsTable {...props} />;
					}}
				/>

				<Route
					path={['/carepro/chat/:conversation_id', '/carepro/chat']}
					children={(props) => {
						return <CareProChat {...props} />;
					}}
				/>
				<Route
					path='/carepro/jobs'
					children={(props) => {
						return <Jobs {...props} />;
					}}
				/>

				<Route
					path='/carepro/documents'
					children={(props) => {
						return <Documents {...props} />;
					}}
				/>
				<Route
					path='/carepro/shift'
					children={(props) => {
						return <Shift {...props} />;
					}}
				/>
				<Route
					path='/carepro/clients/:clientId'
					children={(props) => {
						return <ClientProfilePage {...props} />;
					}}
				/>
				<Route
					path='/carepro/clients'
					children={(props) => {
						return <ClientsPage {...props} />;
					}}
				/>
				<Route
					path='/carepro/care-abilities'
					children={(props) => {
						return <CareAbilitiesPage {...props} />;
					}}
				/>
				<Route
					path='/carepro/job-posts/:jobPostId/apply'
					children={(props) => {
						return <ApplyToJobPostPage {...props} />;
					}}
				/>
				<Route
					path='/carepro/job-posts'
					children={(props) => {
						return <JobPostsPage {...props} />;
					}}
				/>
				<Route
					path='/carepro/verify-banking'
					children={(props) => {
						return <VerifyBanking {...props} />;
					}}
				/>
			</Switch>
		</>
	);
};

export default ProviderRoutes;
