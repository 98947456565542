import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { StyledCompletedStep } from './styles';

const CompletedStep = () => {
	return (
		<StyledCompletedStep>
			<FontAwesomeIcon size='xs' icon={faCheck} />
		</StyledCompletedStep>
	);
};

export default CompletedStep;
