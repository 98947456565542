export const validateCareProPersonalStripe = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.address_line1) {
		errors.address_line1 = err;
	}

	if (!values.address_city) {
		errors.address_city = err;
	}

	if (!values.address_state) {
		errors.address_state = err;
	}

	if (!values.address_postal_code) {
		errors.zip = err;
	}

	return errors;
};

export const validateCareProDobSSnlStripe = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.dob_day) {
		errors.dob_day = err;
	}

	if (!values.dob_month) {
		errors.dob_month = err;
	}

	if (!values.dob_year) {
		errors.dob_year = err;
	}

	if (!values.id_number) {
		errors.id_number = err;
	} else {
		// ensure its 9 digits
		if (values.id_number.length !== 9) {
			errors.id_number = 'Must be 9 digits';
		}

		// ensure there are no letters
		if (isNaN(values.id_number)) {
			errors.id_number = 'Must be a number';
		}
	}

	return errors;
};

export const validateFullSsn = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.id_number) {
		errors.id_number;
	}

	// ensure id_number is exactly 9 digits
	if (values.id_number) {
		if (values.id_number.length !== 9) {
			errors.id_number = 'Must be 9 digits';
		}
	}

	return errors;
};
