import styled from 'styled-components';

import { device } from 'AppComp/styled/StyledMediaQuery';

export const StyledRow = styled.div`
	display: flex;
	gap: 2px;
	margin: auto;

	@media ${device.tablet} {
		margin: 0;
	}
`;
