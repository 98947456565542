import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CLIENT_MODEl } from '../../../core/api/models';

const updateUserStripeToken = async ({ userId, tokenId }) => {
	try {
		const data = await api.graph({
			query: `mutation {
                updatePatientStripeToken(
                    user_id: ${userId},
                    token: "${tokenId}"
                ) {
                    ${CLIENT_MODEl}
                }
            }`,
		});

		return data.updatePatientStripeToken;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useUpdateUserStripeTokenMutation = () => {
	return useMutation({
		mutationKey: ['updateUserStripeToken'],
		mutationFn: (values) => {
			return updateUserStripeToken(values);
		},
	});
};
