const formatDate = (timestamp) => {
	const date = new Date(timestamp * 1000);

	const day = date.toLocaleString('en-US', { weekday: 'long' });
	const month = date.toLocaleString('en-US', { month: 'long' });
	const dayNumber = date.getDate();
	const year = date.getFullYear();

	const ordinalSuffix = (n) => {
		if (n >= 11 && n <= 13) return 'th';
		const lastDigit = n % 10;
		if (lastDigit === 1) return 'st';
		if (lastDigit === 2) return 'nd';
		if (lastDigit === 3) return 'rd';
		return 'th';
	};

	return `${day}, ${month} ${dayNumber}${ordinalSuffix(dayNumber)}, ${year}`;
};

export const groupMessagesByDate = (messages) => {
	const groupedMessages = messages.reduce((acc, message) => {
		const date = formatDate(message.timestamp);
		if (!acc[date]) acc[date] = [];
		acc[date].push(message);
		return acc;
	}, {});

	return Object.keys(groupedMessages).map((date) => ({
		date,
		messages: groupedMessages[date],
	}));
};
