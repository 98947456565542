import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const changeDefaultPayoutSource = async ({ externalCardTokenId }) => {
	try {
		const data = await api.graph({
			query: `mutation {
				        changeDefaultPayoutSource(
							external_card_token_id: "${externalCardTokenId}") 
							{
				        id
				}
			}`,
		});

		return data.updatePatietnDefaultPaymentMethod;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useChangeDefaultPayoutSourceMutation = () => {
	return useMutation({
		mutationKey: ['changeDefaultPayoutSource'],
		mutationFn: (values) => {
			return changeDefaultPayoutSource(values);
		},
	});
};
