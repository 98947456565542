import React from 'react';
import changeCase from 'change-case';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import AnimateHeight from 'react-animate-height';

import styled from 'styled-components';
import { ACCEPTED_CARDS } from 'constants';

import { RowFlex, ColumnFlex } from 'basicStyles';
import Colors from 'colors';

import { device } from '../../styled/StyledMediaQuery';

import FormButton from 'AppComp/FormButton';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';

export const FormInputCon = styled.div`
	margin: ${(props) => props.margin || '0'};
	padding: ${(props) => props.padding || '0'};
	width: ${({ width }) => width};
`;

export const CusotmFieldSet = styled.fieldset`
	display: block;
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-block-start: 0;
	padding-inline-start: 0;
	padding-inline-end: 0;
	padding-block-end: 0;
	min-inline-size: min-content;
	border-image: initial;
	border: none;

	${({ disabledStatus }) =>
		disabledStatus &&
		`
		.main_label {
			top: -1.2rem;
			left: 4px;
			color: ${Colors.gray};
			font-size: 0.80rem;
		}
	`}
`;
/*
border-width: 2px;
border-style: groove;
border-color: threedface;
*/

export const CustomRow = styled(RowFlex)`
	justify-content: flex-start;
`;

export const Divider = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${Colors.gray};
	margin: 12px 0 12px 0;
`;

export const CardContainer = styled.div`
	margin: 16px 0;
	background-color: #fcfcfd;
	border-radius: 8px;
	padding: 6 6px;
	height: 38px;
	border: 0.5px solid #ecf0f3;
	border-radius: 4px;
	min-width: 300px;
	max-width: 600px;
	width: 50%;
`;

export const CardFormCon = styled.form`
	width: 100%;
	margin-block-end: 0;
`;

export const CustomFormButton = styled(FormButton)`
	${({ valid }) =>
		!valid &&
		`
		background-color: white;
		border: 1px solid ${Colors.secondary.blue};
		color: ${Colors.secondary.blue};

	`}

	${({ valid }) =>
		valid &&
		`
		background-color: ${Colors.secondary.blue};
		color: white;
	`}
`;

export const CurosrRow = styled(RowFlex)`
	cursor: pointer;
`;

export const Card = styled.div`
	height: 60px;
	width: 60px;
	background-position: center;
	background-size: contain;
	background-image: url('${(props) => props.image_url}');
	background-repeat: no-repeat;
	margin-left: 8px;
`;

export const StyledTokenCon = styled(ColumnFlex)`
	width: 220px;
	padding: 18px 12px 12px 12px;
	border: ${({ primary }) => (primary ? 'solid 2px green' : 'none')};
	cursor: pointer;

	@media ${device.mobileL} {
		width: 300px;
	}

	background: #ffffff;
	box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.1);
	border-radius: 20px;
`;

export const DefaultPaymentMethod = (props) => {
	const { id, brand, last_4, userId, exp_month, exp_year, type } = props;

	const card = ACCEPTED_CARDS.find((d, i) => {
		return d.name === brand;
	});

	return (
		<RowFlex alignCenter margin='8px 0 0px 12px'>
			<StyledTokenCon primary={true}>
				<RowFlex margin='0 8px' spaceBetween alignCenter>
					<Text color='black'>{`xxxx xxxx xxxx ${last_4}`}</Text>
				</RowFlex>
				<RowFlex margin='0 8px' spaceBetween alignCenter>
					<Text color='black'>{`${exp_month}/${exp_year}`}</Text>
					<Card
						image_url={card ? card.image_url : changeCase.sentenceCase(brand)}
						margin='0 8px 0 8px'
					/>
				</RowFlex>
			</StyledTokenCon>
		</RowFlex>
	);
};

const numericDigitsRegex = /^\d{4}$/;
const phoneRegex = /^\d{10}$/;

export const SavedTokenInput = (props) => {
	const {
		updateUserDefaultPaymentMethod,
		deletePaymentMethod,
		id,
		brand,
		last_4,
		user,
		exp_month,
		exp_year,
		type,
		primary,
	} = props;
	const card = ACCEPTED_CARDS.find((d, i) => {
		return d.name === brand;
	});

	const handleClick = () => {
		props.deletePaymentMethod({
			source_id: id,
			user_id: user.id,
		});
	};

	return (
		<RowFlex alignCenter margin='8px 0 0px 12px'>
			<StyledTokenCon
				onClick={() => updateUserDefaultPaymentMethod({ user_id: user.id, source_id: id })}
				primary={primary}
			>
				<RowFlex margin='0 8px' spaceBetween alignCenter>
					<Text color='black'>{`xxxx xxxx xxxx ${last_4}`}</Text>
				</RowFlex>
				<RowFlex margin='0 8px' spaceBetween alignCenter>
					<Text color='black'>{`${exp_month}/${exp_year}`}</Text>
					<Card
						image_url={card ? card.image_url : changeCase.sentenceCase(brand)}
						margin='0 8px 0 8px'
					/>
				</RowFlex>
			</StyledTokenCon>

			<RowFlex pointer spaceBetween margin='0 0 0 24px'>
				<Icon onClick={() => handleClick()} icon={faTrash} />
			</RowFlex>
		</RowFlex>
	);
};

export const CardIconCon = styled.div``;
