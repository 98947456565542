import React, { useState, Component, Fragment } from 'react';
import styled from 'styled-components';
import changeCase from 'change-case';
import { connect } from 'react-redux';

import useWindowSize from 'scripts/useWindowSize';

import Colors from 'colors';

import { ColumnFlex } from 'AppComp/styled/Basic';
import { BasicTextInput } from 'AppComp/styled/StyledTextInput';
import ConversationTile from './ConversationTile';
import ConversationFilter from './ConversationFilter';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: ${({ width }) => (width < 1024 ? `100%` : `340px`)};
	background-color: ${Colors.fullWhite};
	max-height: calc(100vh - 40px);
	height: fit-content;
	margin: 20px 0;
	border-radius: 22px;
	overflow: hidden;
	padding-bottom: 16px;
`;

const CareProConversations = (props) => {
	const { setSelectedConversation, selectedConversation, conversations, loggedInUser } = props;

	const selectedConversationType = 'Type';

	const { width } = useWindowSize();

	const [keyword, setKeyword] = useState('');
	const [filteredConvo, setFilteredConvo] = useState([]);

	const handleKeywordChange = (e) => {
		setKeyword(e.target.value);
		const searchWord = e.target.value.toLowerCase();
		const searchResultArr = [];

		conversations.forEach((convo) => {
			var check = false;

			const convoType = convo.user_type;

			searchWord.split(' ').forEach((word) => {
				// we're looping through each convo
				if (convoType === 'patient') {
					// user/admin convo

					const user = convo.user;
					const userSearchItems = [
						user.name,
						user.email,
						user.phone_number,
						user.carePlan.first_name,
						user.carePlan.last_name,
					];
					userSearchItems.forEach((item) => {
						if (item.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
							check = true;
						}
					});

					const admin = convo.admin;
					const adminSearchItems = [admin.email, admin.name];
					adminSearchItems.forEach((item) => {
						if (item.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
							check = true;
						}
					});
				} else if (convoType === 'provider') {
					// care provider/admin

					const admin = convo.admin;
					const adminSearchItems = [admin.email, admin.name];
					adminSearchItems.forEach((item) => {
						if (item.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
							check = true;
						}
					});

					const provider = convo.provider;
					const providerSearchItems = [
						provider.email,
						provider.displayName,
						provider.first_name,
						provider.last_name,
					];

					providerSearchItems.forEach((item) => {
						if (item.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
							check = true;
						}
					});
				} else if (convoType === 'carePro') {
					// care provider/user
					const provider = convo.provider;
					const providerSearchItems = [
						provider.email,
						provider.displayName,
						provider.first_name,
						provider.last_name,
					];

					providerSearchItems.forEach((item) => {
						if (item.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
							check = true;
						}
					});

					const user = convo.user;
					const userSearchItems = [
						user.name,
						user.email,
						user.phone_number,
						user.carePlan.first_name,
						user.carePlan.last_name,
					];
					userSearchItems.forEach((item) => {
						if (item.toLowerCase().indexOf(word.toLowerCase()) !== -1) {
							check = true;
						}
					});
				}
			});

			if (check) searchResultArr.push(convo);
		});

		setFilteredConvo(searchResultArr);
	};

	const renderConversations = () => {
		return conversations.map((convo) => {
			const isUnread = convo.messages.some((msg) => !msg.read);
			const isOpened =
				selectedConversation &&
				selectedConversation.conversation &&
				selectedConversation.conversation.id === convo.id &&
				selectedConversation.opened;

			const chatStyle = {
				fontWeight: isUnread && !isOpened ? 'bold' : 'normal',
			};
			let renderedConversations;
			if (keyword === '') {
				renderedConversations = conversations;
			} else {
				renderedConversations = filteredConvo;
			}

			return (
				<ConversationTile
					key={convo.id}
					conversation={convo}
					setSelectedConversation={setSelectedConversation}
					selectedConversation={selectedConversation}
					active={selectedConversation && selectedConversation.id === convo.id}
					loggedInUser={loggedInUser}
					chatStyle={chatStyle}
				/>
			);
		});
	};

	return (
		<Container width={width}>
			<ColumnFlex
				style={{
					overflowY: 'scroll',
				}}
				padding='16px 4px 0px 4px'
				fullWidth
				fullHeight
			>
				<div
					style={{
						padding: '0 16px 8px 16px',
					}}
				>
					<BasicTextInput
						margin={'20px 0 0px 0 '}
						value={keyword}
						onChange={handleKeywordChange}
						label={`Search By ${changeCase.sentenceCase(selectedConversationType)}`}
						placeholder={`Search By `}
					/>
				</div>
				{/* <ConversationFilter /> */}

				{renderConversations()}
			</ColumnFlex>
		</Container>
	);
};

export default CareProConversations;
