import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CONVERSATION_MODEL, MESSAGE_MODEL } from '../../../core/api/models';

const fetchConversations = async () => {
	try {
		const data = await api.graph({
			query: `{
				checkUserSession {
					conversations {
						${CONVERSATION_MODEL}
						messages {
								${MESSAGE_MODEL}
						}
					},
				}
			}`,
		});

		return data.checkUserSession;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchConversationsQuery = () => {
	return useQuery({
		queryKey: ['fetchConversations'],
		queryFn: () => {
			return fetchConversations();
		},
	});
};
