import React, { useState } from 'react';
import styled from 'styled-components';
import { ColumnFlex, RowFlex, Avatar } from 'basicStyles';
import Colors from 'colors';
import { device } from 'AppComp/styled/StyledMediaQuery';
import {
	faChevronRight,
	faClock,
	faHeart,
	faHome,
	faMars,
	faVenus,
} from '@fortawesome/free-solid-svg-icons';
import changeCase from 'change-case';
import { CARE_PLAN_ATTRIBUTES } from 'constants';

// To include the default styles
import BasicTextInput from 'AppComp/styled/StyledTextInput';
import Button from 'AppComp/Button';
import Text from 'AppComp/Text';
import SetTable from 'AppComp/SetTable';
import Title from 'AppComp/Title';
import Icon from 'AppComp/Icon';
import { BasicChildContainerWider } from '../WelcomePage/WelcomePageViews';
import Img from 'AppComp/Img';

import StyledCheckboxInput from 'AppComp/StyledCheckboxInput';
import { DropShadow } from '../../styled/Basic';

const CustomImage = styled(Img)`
	height: 100px;
	width: 100px;
	image-orientation: ;
`;

export const CustomRow = styled(RowFlex)`
	flex: 1;
	overflow-y: scroll;
`;

export const WiderBasicChildContainerWider = styled(BasicChildContainerWider)`
	height: auto;
	@media ${device.mobileL} {
	}
	@media ${device.laptop} {
		max-width: 1400px;
	}
	@media ${device.desktopL} {
		width: 1680px;
	}
`;
const RangeContainer = styled.div`
	padding: 12px 24px 0 0px;
	width: 200px;
	.input-range__track--active {
		background-color: ${({ theme }) => theme.primaryColors.primary};
	}
	.input-range__label {
		font-size: 11px;
		line-height: 12px;
	}
	.input-range__slider {
		background: ${({ theme }) => theme.primaryColors.primary};
		border: 1px solid ${({ theme }) => theme.primaryColors.primary};
	}
`;

export const FilterDropDownCon = styled.div`
	width: 190px;

	.css-dvua67-singleValue {
		color: ${({ theme }) => theme.primaryColors.primary};
	}
`;

export const CarePlanButton = styled(Button)`
	position: relative;
	z-index: 999;
	color: ${({ theme }) => theme.primaryColors.primary};
`;

export const SpanContainer = styled.span`
	padding: ${(props) => props.padding || '0'};
	position: ${(props) => props.position || 'static'};
	top: ${(props) => props.top || 'auto'};
	float: ${(props) => props.float || 'none'};
	&::after {
		clear: both;
	}
`;

export const FilterButton = styled(Button)`
	@media ${device.mobileS} {
		display: flex;
	}

	@media ${device.laptop} {
		display: none;
	}
`;

export const SortSpanContainer = styled(SpanContainer)`
	@media ${device.mobileS} {
		float: left;
	}

	@media ${device.laptop} {
		float: right;
	}
`;
export const NavButton = styled.span`
	color: white;
	border: none;
	width: ${({ width }) => width || '20px'};
	height: ${({ height }) => height || '20px'};
	background-color: ${({ backgroundColor }) => backgroundColor || 'initial'};
	margin: ${(props) => props.margin || '0'};

	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
	transition: all ease 0.3s;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;
const PatientDashboardLeft = styled.div``;

const PatientDashboardRight = styled.div`
	background: ${Colors.grayWhite};
	right: 0;

	@media ${device.mobileS} {
	}

	@media ${device.laptop} {
	}
`;

const DashboardRightContainer = styled.div`
	background: ${Colors.grayWhite};

	@media ${device.mobileS} {
		padding: 30px 0px 0px 0px;
	}

	@media ${device.laptop} {
		padding: 0px 0px 0px 30px;
	}
`;

//position: absolute;
const LeftBlock = styled(ColumnFlex)`
	height: auto;
	width: 100%;
	margin-bottom: 36px;
	background-color: #fafafa;
	border-radius: 24px;

	box-shadow: 0 1px 4px rgba(37, 50, 56, 0.12), 0 1px 3px rgba(37, 50, 56, 0.24);
	align-items: left;
	padding-bottom: 24px;

	z-index: 100000;

	align-items: left;

	@media ${device.mobileS} {
	}

	@media ${device.laptop} {
		margin-right: 32px;
	}

	@media ${device.mobileS} {
	}

	@media ${device.laptop} {
		background: ${Colors.fullWhite};
	}
`;
export const FilterContainer = styled(ColumnFlex)`
	background-color: ${({ backgroundColor }) => backgroundColor || 'none'};
	padding: ${(props) => props.padding || '0'};
	.rangeslider-horizontal .rangeslider__fill {
		background-color: ${Colors.primary.primary};
	}
`;

const FilterTitleCopyThing = styled(FilterContainer)`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

const ResultsDivider = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${Colors.gray};
`;

const Clearfix = styled.div`
	content: '';
	clear: both;
	display: table;
`;

export const PatientGreenBG = styled.div`
	height: 100px;
	width: 100vw;
	background-color: ${({ theme }) => theme.primaryColors.primary};

	z-index: 3;
	top: 0;
`;

export const PatientDashboardContainer = styled(ColumnFlex)`
	position: relative;
	box-shadow: 0 1px 4px rgba(37, 50, 56, 0.12), 0 1px 3px rgba(37, 50, 56, 0.24);
	padding: 0 0 0 12px;
	background-color: #fafafa;
	@media ${device.mobileS} {
	}

	@media ${device.laptop} {
	}
`;

const PROVIDER_TYPE_DESCRIPTION = {
	live_in: 'Caregiver charges a hourly rate. Usually work in shifts to not exceed 12hr',
	hourly: 'Caregiver Live In has a daily rate',
};

const StarContainer = styled.div`
	padding: 4px;
`;
const StarIcon = ({ handleStarSelection, index, selectedIndex }) => {
	return (
		<StarContainer onClick={() => handleStarSelection(index)}>
			<Icon
				size='2x'
				color={selectedIndex >= index ? Colors.heartRed : Colors.gray}
				icon={faHeart}
			/>
		</StarContainer>
	);
};

import CareProSearchFilterHandler from 'AppComp/patient/CareProSearchFilter/CareProSearchFilterHandler';

export const ProviderFilter = (props) => {
	const {
		filterDisplay,
		toggleFilterBar,
		genderKeys,
		handleGenderCheckboxUpdate,
		renderSpan,
		ratingKeys,
		renderHearts,
		princeRangeValue,
		hanldeCustomRangeLabel,
		handleInputChange,
		handleRatingCheckboxUpdate,
		ratingFilter,
		providerType,
		handleProviderTypeChange,
		genderFilter,
		renderResultsTop,
	} = props;

	const [locationFilter, setLocationFilter] = useState('');

	return (
		<ColumnFlex gap='20px'>
			<CareProSearchFilterHandler />
		</ColumnFlex>
	);
};

export const ProviderResults = (props) => {
	const { history, renderLegend, renderRows } = props;

	return (
		<>
			<FilterTitleCopyThing
				backgroundColor={Colors.primary.primary_accent}
				padding='15px 15px 15px 0px'
			>
				<Title bold trititle fontStyle='italic'>
					Search Result
				</Title>
			</FilterTitleCopyThing>
			<Clearfix />
			<ResultsDivider />
			<SetTable renderLegend={renderLegend} renderRows={renderRows} />
		</>
	);
};

export const TestDiv = styled.div`
	max-width: 300px;
	max-height: 300px;
	margin-bottom: 12px;
`;

export const TileCon = styled(ColumnFlex)`
	background: ${Colors.fullWhite};
	border-radius: 16px;
	cursor: pointer;
	transition: all ease 0.3s;

	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}

	@media ${device.mobileS} {
	}

	@media ${device.laptop} {
	}

	@media ${device.mobileS} {
	}

	@media ${device.laptop} {
		width: 620px;
	}
`;

export const TileOnboardProviderCon = styled(ColumnFlex)`
	background: ${Colors.fullWhite};
	border-radius: 16px;
	cursor: pointer;
	transition: all ease 0.3s;

	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}

	@media ${device.mobileS} {
	}

	@media ${device.laptop} {
	}

	@media ${device.mobileS} {
	}

	@media ${device.laptop} {
		width: 210px;
	}
`;

export const ProviderTileRowFlex = styled(ColumnFlex)`
	flex: 1;

	gap: 20px;
	align-items: center;
	background-color: ${Colors.theme.dark_accent_background};

	padding: 24px;
	border-radius: 12px;
`;

export const TextBack = styled(RowFlex)`
	padding: 6px 16px;
	background-color: #fafafa;
	border-radius: 12px;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin: 8px 3px;
	${DropShadow(1)}
`;

export const SeeMoreCon = styled(RowFlex)`
	padding: 6px 16px;
	background-color: ${Colors.secondary.green};
	border-radius: 12px;
	align-items: center;
	justify-content: center;
	text-align: center;
	margin: 8px 0px;

	transition: all ease 0.3s;

	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}
`;
export const MoreCustom = styled(RowFlex)`
	flex-wrap: wrap;

	width: fill-available;
	justify-content: space-around;
	margin-bottom: ;
`;
export const ProviderSearchTile = (props) => {
	console.log('props', props);
	const {
		history,
		id,
		name,
		background_check_status,
		distanceToUser,
		averageRating,
		first_name,

		gender,
		care_type,
		image_url,
		display_name,
		professional_statement,
	} = props;

	const openProviderProfile = () => {
		history.push(`/viewprovider/${id}`);
	};

	return (
		<TileCon onClick={() => openProviderProfile()}>
			<RowFlex padding='16px 20px 10 20px' columnMobile alignCenter spaceBetween>
				<RowFlex columnMobile alignCenter gap='20px'>
					<Avatar border='32px' height='100px' width='100px'>
						<CustomImage
							src={image_url}
							style={{
								borderRadius: '50%',
							}}
						/>
					</Avatar>
					<ColumnFlex
						style={{
							minWidth: '200px',
						}}
						gap='5px'
					>
						<Title color={Colors.secondary.black} subtitle fontWeight='bold'>
							{first_name}
						</Title>
						<Text color={Colors.secondary.black} fontWeight='100' nurseInfo>
							Verified
						</Text>
						<RowFlex gap='1.5px'>
							{[0, 1, 1, 1, 1].map((d, i) => {
								return (
									<Icon
										size='lg'
										margin='3px'
										color={Colors.heartRed}
										icon={faHeart}
									/>
								);
							})}
						</RowFlex>
					</ColumnFlex>
					<ColumnFlex wordWrap gap='16px'>
						<RowFlex gap='15px' alignCenter>
							<ColumnFlex center gap='5px'>
								<Icon
									size='2x'
									margin='3px'
									color={Colors.secondary.blue}
									icon={gender === 'female' ? faVenus : faMars}
								/>
								<Text color={Colors.secondary.black} fontWeight='100' nurseInfo>
									{gender === 'female' ? 'Woman' : 'Men'}
								</Text>
							</ColumnFlex>
							<ColumnFlex center gap='5px'>
								<Icon
									size='2x'
									margin='3px'
									color={Colors.secondary.blue}
									icon={care_type === 'hourly' ? faClock : faHome}
								/>
								<Text color={Colors.secondary.black} fontWeight='100' nurseInfo>
									{care_type === 'hourly' ? 'Hourly' : 'Live In'}
								</Text>
							</ColumnFlex>
						</RowFlex>
						<Text color={Colors.secondary.black} fontWeight='100' nurseInfo>
							{professional_statement}
						</Text>
					</ColumnFlex>
				</RowFlex>

				<div
					onClick={() => {
						openProviderProfile();
					}}
					style={{ cursor: 'pointer', padding: '20px' }}
				>
					<Icon
						size='2x'
						margin='3px'
						color={Colors.secondary.green}
						icon={faChevronRight}
					/>
				</div>
			</RowFlex>
		</TileCon>
	);
};

export const SearchCon = styled.div`
	display: flex;
	flex-direction: column;
	width: 90vw;

	margin-top: 32px;
	position: relative;
	height: ${({ height }) => height || 'inherit'};
	z-index: 5;
	gap: 40px;
	@media ${device.mobileS} {
		width: 94vw;
	}
	@media ${device.mobileL} {
	}
	@media ${device.laptop} {
		flex-direction: row;
		width: 1080px;
	}
	@media ${device.desktopL} {
	}
`;

export const ProviderPreviewTile = (props) => {
	const { id, name, background_check_status, distanceToUser, averageRating, first_name } = props;

	return (
		<TestDiv>
			<TileCon>
				<RowFlex padding='16px 36px 10 36px' alignCenter>
					<Avatar height='72px' width='72px'>
						<Icon size='2x' color={Colors.heartRed} icon={faHeart} />
					</Avatar>
					<ColumnFlex margin='0 0 0 16px;'>
						<Text
							color={Colors.secondary.black}
							nurseName
							inline
							margin='16px 0px 0px 0px'
							fontWeight='bold'
						>
							{name}
						</Text>

						<RowFlex margin='16px 0 12px 0'>
							{[0, 1, 1, 1, 1].map((d, i) => {
								return (
									<Icon
										size='sm'
										margin='2px'
										color={Colors.heartRed}
										icon={faHeart}
									/>
								);
							})}
						</RowFlex>
					</ColumnFlex>
				</RowFlex>
			</TileCon>
		</TestDiv>
	);
};

export const ProviderOnboardingPreviewTile = (props) => {
	const { id, name, gender, care_type } = props;

	return (
		<TileOnboardProviderCon padding='16px 18px' center>
			<RowFlex padding='16px 12px' alignCenter>
				<Avatar height='72px' width='72px'>
					<Icon size='2x' color={Colors.heartRed} icon={faHeart} />
				</Avatar>
				<ColumnFlex margin='0 0 0 16px;'>
					<Text
						color={Colors.secondary.black}
						nurseName
						inline
						margin='16px 0px 0px 0px'
						fontWeight='bold'
					>
						{name}
					</Text>

					<RowFlex margin='16px 0 12px 0'>
						{[0, 1, 1, 1, 1].map((d, i) => {
							return (
								<Icon
									size='sm'
									margin='2px'
									color={Colors.heartRed}
									icon={faHeart}
								/>
							);
						})}
					</RowFlex>
				</ColumnFlex>
			</RowFlex>
			<RowFlex gap='20px' alignCenter>
				<ColumnFlex center gap='5px'>
					<Icon
						size='2x'
						margin='3px'
						color={Colors.secondary.blue}
						icon={gender === 'female' ? faVenus : faMars}
					/>
					<Text color={Colors.secondary.black} fontWeight='100' nurseInfo>
						{gender === 'female' ? 'Female' : 'Male'}
					</Text>
				</ColumnFlex>
				<ColumnFlex center gap='5px'>
					<Icon
						size='2x'
						margin='3px'
						color={Colors.secondary.blue}
						icon={care_type === 'hourly' ? faClock : faHome}
					/>
					<Text color={Colors.secondary.black} fontWeight='100' nurseInfo>
						{care_type === 'hourly' ? 'Hourly' : 'Live In'}
					</Text>
				</ColumnFlex>
			</RowFlex>
		</TileOnboardProviderCon>
	);
};
/* 
	<LeftBlock display={filterDisplay}>
				
			<Title padding='20px ' bold trititle>
			Filter
		</Title>
		<RowFlex alignStart wrap>
			<FilterContainer padding='15px 15px 15px 15px'>
				<Text color={Colors.gray} bold nurseName padding='10px 0px'>
					Caregiver Type
				</Text>
				<RowFlex>
					<Button
						onClick={() => handleProviderTypeChange('live_in')}
						backgroundColor={
							providerType === 'live_in'
								? Colors.primary.primary
								: Colors.white
						}
						color={
							providerType === 'live_in'
								? Colors.white
								: Colors.primary.primary
						}
						borderRadius='20px 4px 4px 20px'
					>
						Live In
					</Button>
					
					<Button
						onClick={() => handleProviderTypeChange('hourly')}
						backgroundColor={
							providerType === 'hourly'
								? Colors.primary.primary
								: Colors.white
						}
						borderRadius='4px 20px 20px 4px'
						color={
							providerType === 'hourly'
								? Colors.white
								: Colors.primary.primary
						}
					>
						Hourly
					</Button>
				</RowFlex>
				<RowFlex center fullWidth>
					<Text subtext color={Colors.gray} margin='8px 0 0 0'>
						{PROVIDER_TYPE_DESCRIPTION[providerType]}
					</Text>
				</RowFlex>
			</FilterContainer>

			<FilterContainer padding='15px 15px 15px 15px'>
				<Text color={Colors.gray} bold nurseName padding='10px 0px'>
					Gender
				</Text>
				<RowFlex>
					<Button
						onClick={() => handleGenderCheckboxUpdate('female')}
						width='100px'
						backgroundColor={
							genderFilter === 'female'
								? Colors.primary.primary
								: Colors.white
						}
						color={
							genderFilter === 'female'
								? Colors.white
								: Colors.primary.primary
						}
						borderRadius='20px 4px 4px 20px'
					>
						Woman
					</Button>
					<Button
						width='100px'
						onClick={() => handleGenderCheckboxUpdate('')}
						backgroundColor={
							genderFilter === '' ? Colors.primary.primary : Colors.white
						}
						color={genderFilter === '' ? Colors.white : Colors.primary.primary}
						borderRadius='4px 4px 4px 4px'
					>
						Both
					</Button>
					<Button
						width='100px'
						onClick={() => handleGenderCheckboxUpdate('male')}
						backgroundColor={
							genderFilter === 'male' ? Colors.primary.primary : Colors.white
						}
						color={
							genderFilter === 'male' ? Colors.white : Colors.primary.primary
						}
						borderRadius='4px 20px 20px 4px'
					>
						Man
					</Button>
				</RowFlex>
			</FilterContainer>

			<FilterContainer padding='15px 15px 15px 15px'>
				<Text color={Colors.gray} bold nurseName padding='10px 0px'>
					Review Rating
				</Text>
				<RowFlex>
					<StarIcon
						handleStarSelection={handleRatingCheckboxUpdate}
						index={1}
						selectedIndex={ratingFilter}
					/>
					<StarIcon
						handleStarSelection={handleRatingCheckboxUpdate}
						index={2}
						selectedIndex={ratingFilter}
					/>
					<StarIcon
						handleStarSelection={handleRatingCheckboxUpdate}
						index={3}
						selectedIndex={ratingFilter}
					/>
					<StarIcon
						handleStarSelection={handleRatingCheckboxUpdate}
						index={4}
						selectedIndex={ratingFilter}
					/>
					<StarIcon
						handleStarSelection={handleRatingCheckboxUpdate}
						index={5}
						selectedIndex={ratingFilter}
					/>
				</RowFlex>
			</FilterContainer>
			<div style={{ maxWidth: '300px' }}>
				<FilterContainer padding='15px 15px 15px 15px' fullWidth>
					<Text color={Colors.gray} bold nurseName padding='10px 0px'>
						{` 	${changeCase.sentenceCase(providerType)} Rate Range`}
					</Text>
					{/* <RangeContainer>
					<InputRange
						maxValue={50}
						minValue={1}
						value={princeRangeValue}
						allowSameValues
						onChange={() => handleInputChange()}
						format={hanldeCustomRangeLabel}
					/>
				</RangeContainer>
				<Slider
					value={princeRangeValue}
					min={providerType === 'live_in' ? 50 : 5}
					max={providerType === 'live_in' ? 1000 : 200}
					step={providerType === 'live_in' ? 10 : 5}
					onChange={handleInputChange}
					format={String(hanldeCustomRangeLabel)}
				/>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
					}}
				></div> 
			
					<Text color={Colors.gray} bold nurseName padding='4px 0px'>
						{` $10 - $${princeRangeValue}`}
					</Text>
				</FilterContainer>
			</div>

			{renderResultsTop()}
		</RowFlex>
	</LeftBlock>
*/
