import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import qsueryString from 'query-string';

import {
	checkProviderSession,
	logoutProvider,
	handleProviderLogin,
	changeProviderPassword,
	forgotProviderPassword,
	verifyCareProEmail,
} from 'reducers/provider';

import {
	toggleForgotPasswordOverlay,
	toggleChangePasswordOverlay,
	receiveError,
	showInfoModel,
} from 'reducers/environment';

import ProviderRoutes from './ProviderRoutes';
import ProviderWelcomeHandler from './ProviderWelcomeHandler';

import PageLoaderChildView from '../PageLoaderChildView';
import BasicOverlayWrapper from '../BasicOverlayWrapper';

import ForgotPassword from '../patient/ForgotPassword';
import ChangePassword from '../patient/ChangePassword';

import ProviderHomeLayout from './ProviderHomeLayout';

import { NAVIGATION, THEME, PROVIDER_NAV_WIDTH } from 'constants';

const ProviderApp = (props) => {
	const {
		provider,
		handleProviderLogin,
		error,
		logoutProvider,
		receiveError,
		registering,
		toggleForgotPasswordOverlay,
		showChangePasswordOverlay,
		changePasswordStatus,
		showForgotPasswordOverlay,
		forgotProviderPassword,
		changeProviderPassword,
		toggleChangePasswordOverlay,
		forgotPasswordStatus,
		history,
		history: { push },
		location,
		checkProviderSession,
		verifyCareProEmail,
	} = props;

	useEffect(() => {
		if (location.pathname === '/carepro/password') {
			checkProviderSession();
			toggleChangePasswordOverlay(true);
		} else if (location.pathname === '/carepro/verifyAccount') {
			const queryParams = qsueryString.parse(location.search, { arrayFormat: 'comma' });

			const user_id = queryParams.user_id ? parseInt(queryParams.user_id, 10) : 0;
			const params = {
				user_id: user_id,
				hashed_key: queryParams.hashed_key,
			};

			verifyCareProEmail(params);
		} else {
			const token = localStorage.getItem('user-token');

			console.log('checking local storeage token for carepro', token);
			if (token) {
				// check session
				checkProviderSession();
			}
		}
	}, []);

	return (
		<>
			<BasicOverlayWrapper
				status={showChangePasswordOverlay}
				classNames='basic-overlay'
				appear
				handleExitClick={() => toggleChangePasswordOverlay(false)}
			>
				<ChangePassword
					handleExitClick={() => toggleChangePasswordOverlay(false)}
					passwordStatus={changePasswordStatus}
					changePassword={changeProviderPassword}
				/>
			</BasicOverlayWrapper>

			<BasicOverlayWrapper
				status={showForgotPasswordOverlay}
				classNames='basic-overlay'
				appear
				handleExitClick={() => toggleForgotPasswordOverlay(false)}
			>
				<ForgotPassword
					forgotPassword={forgotProviderPassword}
					forgotPasswordStatus={forgotPasswordStatus}
				/>
			</BasicOverlayWrapper>
			<ProviderHomeLayout
				THEME={THEME.provider}
				NAVIGATION={NAVIGATION.provider}
				loggedIn={provider !== null}
				push={push}
				logOut={logoutProvider}
				location={location}
				history={history}
			>
				{provider !== null && !registering ? (
					<ProviderRoutes {...props} />
				) : (
					<PageLoaderChildView>
						<ProviderWelcomeHandler
							handleProviderLogin={handleProviderLogin}
							receiveError={receiveError}
							error={error}
							{...props}
							toggleForgotPasswordOverlay={toggleForgotPasswordOverlay}
							checkProviderSession={checkProviderSession}
							history={history}
						/>
					</PageLoaderChildView>
				)}
			</ProviderHomeLayout>
		</>
	);
};

const mapStateToProps = ({ environment, provider }, props) => {
	return {
		provider: provider.provider,
		registering: provider.registering,
		error: environment.error,
		verification: provider.verification,
		showForgotPasswordOverlay: environment.showForgotPasswordOverlay,
		forgotPasswordStatus: environment.forgotPasswordStatus,
		showChangePasswordOverlay: environment.showChangePasswordOverlay,
		changePasswordStatus: environment.changePasswordStatus,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		checkProviderSession: () => dispatch(checkProviderSession()),
		startProviderOnboardingPoll: () => dispatch({ type: 'START_PROVIDER_ONBOARD' }),
		endProviderOnboardingPoll: () => dispatch({ type: 'END_PROVIDER_ONBOARD' }),
		logoutProvider: () => dispatch(logoutProvider()),
		handleProviderLogin: (provider) => dispatch(handleProviderLogin(provider)),
		receiveError: (err) => dispatch(receiveError(err)),
		toggleChangePasswordOverlay: (status) => dispatch(toggleChangePasswordOverlay(status)),
		forgotProviderPassword: (email) => dispatch(forgotProviderPassword(email)),
		changeProviderPassword: (password) => dispatch(changeProviderPassword(password)),
		toggleForgotPasswordOverlay: (status) => dispatch(toggleForgotPasswordOverlay(status)),
		showInfoModel: (params) => dispatch(showInfoModel(params)),
		verifyCareProEmail: (params) => dispatch(verifyCareProEmail(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderApp);
