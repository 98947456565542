import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { ParentContainer, PHDColumn } from '../ProviderHomeDashboard';
import { device } from 'AppComp/styled/StyledMediaQuery';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

import {
	updateProviderVerificationInfo,
	createProviderStripeAccount,
	receiveVerification,
	receiveStripeVerificationInfo,
	receiveStepsProgress,
} from 'reducers/provider';
import { VERIFICATION_MODEL, STRIPE_VERIFICATION_INFO_MODEL } from 'reducers/graphqlModelTypes';
import Text from 'AppComp/Text';
import { ColumnFlex } from 'basicStyles';
import Button from 'AppComp/Button';
import Title from 'AppComp/Title';
import Colors from 'colors';

import Email from './Email';
import Steps from './Steps';
import Payment from './Payment';
import Certification from './Certification';
import Profile from './Profile';

import PageLoaderView from 'AppComp/PageLoaderView';

import api from 'scripts/api';

import { PROVIDER_NAV_WIDTH, TOAST_ERROR_CONFIG } from 'constants';

const CheckOutOnboardHandler = (props) => {
	const {
		stripeVerificationInfo,
		verification,
		provider,
		startProviderOnboardingPoll,
		receiveVerification,
		receiveStripeVerificationInfo,
		createProviderStripeAccount,

		receiveStepsProgress,
	} = props;
	// top level view changes based on the view - we need to check if anything is missing

	const [isLoading, setIsLoading] = useState(false);
	const [step, setStep] = useState(0);
	/*
	 * 1. check if the user has email verified
	 * * if not, show the intro screen with the ability to move forward to the email verification screen
	 */

	useEffect(() => {
		console.log('verification', verification);
		determineStepFromVerification();
		//startProviderOnboardingPoll();
	}, [provider, verification, stripeVerificationInfo]);

	useEffect(() => {
		console.log('verification', verification);
		createStripeAccount();
	}, []);

	const createStripeAccount = () => {
		createProviderStripeAccount();
	};
	const determineStepFromVerification = () => {
		const payoutsAndPaymentStripeStatus =
			verification.details_submitted &&
			verification.charges_enabled &&
			verification.payouts_enabled
				? true
				: false;

		const pendingVerificationCardPayments =
			verification.card_payments === 'pending' ? true : false;

		//const pendingVerificationCardPayments = true;

		const pendingVerificationPayouts = verification.transfers === 'pending' ? true : false;
		//const pendingVerificationPayouts = true;

		let stripeIdentityStatus = false;
		//stripeVerificationInfo could be null at this point
		if (stripeVerificationInfo !== null) {
			stripeIdentityStatus =
				stripeVerificationInfo && stripeVerificationInfo.currently_due.length === 0
					? true
					: false;
		}

		let stripeVerify = payoutsAndPaymentStripeStatus && stripeIdentityStatus ? true : false;

		if (pendingVerificationCardPayments && pendingVerificationPayouts) {
			stripeVerify = true;
		}

		const email = verification.valid_email ? true : false;
		const adminVerification = verification.admin_verification ? true : false;
		const membership = true;

		// this going with profile attr
		//&& verification.profile_attributes
		const profile = verification.professional_statement !== null ? true : false;

		const steps = [email, stripeVerify, adminVerification, membership, profile];

		// iterate through steps find the first false and set the step to that

		const runStepFunc = steps.findIndex((step) => step === false);
		if (runStepFunc === -1) {
			setStep(5);
		} else {
			setStep(runStepFunc + 1);
		}
	};

	const checkLatestProviderSession = async () => {
		try {
			const res = await api.graph({
				query: `{
					checkProviderSession {
						
						verification {
							${VERIFICATION_MODEL}
						},
						stripeVerificationInfo {
							${STRIPE_VERIFICATION_INFO_MODEL}
						}
					}
				}`,
			});

			const data = res.checkProviderSession;

			if (data.verification) receiveVerification(data.verification);
			if (data.stripeVerificationInfo)
				receiveStripeVerificationInfo(data.stripeVerificationInfo);

			setIsLoading(false);
		} catch (err) {
			console.log('checkLatestProviderSession - err', err);
		}
	};
	const updateProviderVerificationInfo = async (inputs) => {
		console.log('updateProviderVerificationInfo - inputs', inputs);
		try {
			const res = await api.graph({
				query: `mutation {
					updateProviderStripeInformation(
						updateValues: ${api.graphStringify(inputs)},
					) {
						verification {
							${VERIFICATION_MODEL}
						},
						stripeVerificationInfo {
							${STRIPE_VERIFICATION_INFO_MODEL}
						}
					}
				}`,
			});

			const data = res.updateProviderStripeInformation;
			console.log('updateProviderVerificationInfo-data', data);
			if (data.errors && data.errors.length > 0) {
				toast.error(data.errors[0].message, TOAST_ERROR_CONFIG);
			} else {
				if (data.verification) receiveVerification(data.verification);
				if (data.stripeVerificationInfo)
					receiveStripeVerificationInfo(data.stripeVerificationInfo);
			}

			setIsLoading(false);
		} catch (err) {
			console.log('err', err);
			setIsLoading(false);
			if (err[0] && err[0].message) {
				toast.error(err[0].message, TOAST_ERROR_CONFIG);
			}

			console.log('updateProviderVerificationInfo - err', err);
		}
	};

	const updateProviderProfileInfo = async (inputs) => {
		try {
			const res = await api.graph({
				query: `mutation {
					updateProivderInfo(
						profile_info: ${api.graphStringify(inputs)},
					) {
						verification {
							${VERIFICATION_MODEL}
						},
						stripeVerificationInfo {
							${STRIPE_VERIFICATION_INFO_MODEL}
						}
					}
				}`,
			});

			const data = res.updateProivderInfo;
			if (data.verification) receiveVerification(data.verification);
			if (data.stripeVerificationInfo)
				receiveStripeVerificationInfo(data.stripeVerificationInfo);

			setIsLoading(false);
		} catch (err) {
			setIsLoading(false);
			console.log('updateProviderProfileInfo - err', err);
		}
	};

	const addProviderAttributes = async (carePlanInfoInput) => {
		try {
		} catch (err) {}
	};
	const handleGoToNextStep = () => {
		setStep(step + 1);
	};

	const handleGoBackStep = () => {
		setStep(step - 1);
	};

	const goToStep = (step) => {
		setStep(step);
	};
	const handleShowView = () => {
		switch (step) {
			case 0:
				return (
					<OnboardParentCon step={step}>
						<WelcomeScreen
							{...props}
							updateProviderVerificationInfo={updateProviderVerificationInfo}
							handleGoToNextStep={handleGoToNextStep}
							receiveStepsProgress={receiveStepsProgress}
						/>
					</OnboardParentCon>
				);
			case 1:
				return (
					<OnboardParentCon step={step}>
						<Email {...props} handleGoToNextStep={handleGoToNextStep} />
					</OnboardParentCon>
				);
			case 2:
				return (
					<Payment
						{...props}
						updateProviderVerificationInfo={updateProviderVerificationInfo}
						handleGoToNextStep={handleGoToNextStep}
						setIsLoading={setIsLoading}
						isLoading={isLoading}
						k
						checkLatestProviderSession={checkLatestProviderSession}
					/>
				);
			case 3:
				return (
					<OnboardParentCon step={step}>
						<Certification {...props} handleGoToNextStep={handleGoToNextStep} />
					</OnboardParentCon>
				);
			case 4:
				return (
					<OnboardParentCon step={step}>
						<p>Ensure user can skip this</p>
					</OnboardParentCon>
				);

			case 5:
				return (
					<Profile
						setIsLoading={setIsLoading}
						isLoading={isLoading}
						{...props}
						updateProviderProfileInfo={updateProviderProfileInfo}
						addProviderAttributes={addProviderAttributes}
					/>
				);

			default:
				return (
					<WelcomeScreen
						setIsLoading={setIsLoading}
						isLoading={isLoading}
						handleGoToNextStep={handleGoToNextStep}
					/>
				);
		}
	};
	return (
		<ParentContainer
			style={{
				width: isMobile ? undefined : `calc(100vw - ${PROVIDER_NAV_WIDTH})`,
			}}
		>
			{stripeVerificationInfo ? (
				handleShowView()
			) : (
				<PageLoaderView errorMessage='Something went wrong plz contact dev monkeys' />
			)}
		</ParentContainer>
	);
};

export const PDColumnExtended = styled(PHDColumn)``;
export const TileThing = styled(ColumnFlex)`
	padding: 8px;
	padding-top: 32px;
	border-radius: 12px;
	// min-width: 360px;
	margin: 0 auto 0 auto;
	@media ${device.tablet} {
		width: ${({ step }) => (step === 4 ? `calc(98vw - ${PROVIDER_NAV_WIDTH});` : '420px')};
		padding: 40px;
	}
`;

export const OnboardParentCon = ({ step, children }) => {
	return (
		<PHDColumn
			fullHeight
			center={isMobile ? true : false}
			style={{
				backgroundColor: Colors.primary.primary_light_shade,
				paddingTop: '20px',
				minHeight: '100vh',
			}}
		>
			<TileThing step={step} center gap={'10px'}>
				{children}
			</TileThing>
		</PHDColumn>
	);
};
const WelcomeScreen = ({ handleGoToNextStep }) => {
	return (
		<ColumnFlex style={{ maxWidth: '240px' }} center>
			<Title color={'black'} bold subtitle>
				Welcome to the Provider Community!
			</Title>
			<ColumnFlex padding='0 0 12px 0' margin='16px auto 0 auto' center gap='12px'>
				<Text
					style={{
						textAlign: 'center',
					}}
				>
					You're Welcomed to look around and acustom your self to the platform. Before we
					can get you matched with clients, we need to have a few things in order.
				</Text>
			</ColumnFlex>
			<Button
				onClick={handleGoToNextStep}
				margin='32px 8px 0 0px'
				backgroundColor={Colors.primary.primary}
				borderRadius={'24px'}
				width={'240px'}
				height={isMobile ? '40px' : '60px'}
			>
				<Text
					style={{
						textAlign: 'center',
					}}
					bold
					color={'white'}
					midLarge
				>
					Let's Get Started
				</Text>
			</Button>
		</ColumnFlex>
	);
};

const mapStateToProps = ({ environment, provider }, props) => {
	return {
		stripeVerificationInfo: provider.stripeVerificationInfo,
		provider: provider.provider,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createProviderStripeAccount: () => dispatch(createProviderStripeAccount()),
		receiveVerification: (data) => dispatch(receiveVerification(data)),
		receiveStripeVerificationInfo: (data) => dispatch(receiveStripeVerificationInfo(data)),
		receiveStepsProgress: (step) => dispatch(receiveStepsProgress(step)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutOnboardHandler);
