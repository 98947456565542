import React from 'react';
import MultiRangeSlider from 'multi-range-slider-react';

import Text from 'AppComp/Text';
import { RowFlex, ColumnFlex } from 'basicStyles';

import Colors from 'colors';

import {
	StyledContainer,
	StyledFilterTab,
	StyledFilterTabText,
	StyledDropDownContainer,
	StyledDropDown,
	StyledCustomInput,
	StyledFilterLabelWrapper,
	StyledLabel,
	StyledRangeSelectorWrapper,
} from './styles';

const framerPanel = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};

const TagFilter = ({ name, label, options, filterHandler }) => {
	return (
		<RowFlex>
			<StyledFilterLabelWrapper>
				<StyledLabel>{label}:</StyledLabel>
			</StyledFilterLabelWrapper>

			{options.map((option) => {
				return (
					<StyledFilterTab
						key={option.name}
						selected={option.selected}
						onClick={() => filterHandler(name, option.name, option.value)}
					>
						<StyledFilterTabText selected={option.selected}>
							{option.name}
						</StyledFilterTabText>
					</StyledFilterTab>
				);
			})}
		</RowFlex>
	);
};

const SelectFilter = ({ name, label, options, customValue, filterHandler }) => {
	return (
		<RowFlex>
			<StyledFilterLabelWrapper>
				<StyledLabel padding='5px'>{label}:</StyledLabel>
			</StyledFilterLabelWrapper>

			{Object.keys(options).map((key) => {
				const dropDownItems = options[key];
				const dropDownIsSelected = dropDownItems.some(
					(item, index) => index !== 0 && item.selected
				);

				const showCustomInput =
					dropDownItems[dropDownItems.length - 1].selected === true &&
					dropDownItems[dropDownItems.length - 1].name === 'Custom';

				return (
					<StyledDropDownContainer key={name}>
						<StyledDropDown
							onChange={(event) => filterHandler(name, key, event.target.value)}
							selected={dropDownIsSelected}
						>
							{dropDownItems.map((item) => {
								return (
									<option selected={item.selected} key={item.name}>
										<Text>{item.name}</Text>
									</option>
								);
							})}
						</StyledDropDown>

						{showCustomInput && (
							<StyledCustomInput
								selected={dropDownIsSelected}
								type='text'
								placeholder='Enter custom value'
								value={customValue}
								onChange={(event) =>
									filterHandler(name, key, null, event.target.value)
								}
							/>
						)}
					</StyledDropDownContainer>
				);
			})}
		</RowFlex>
	);
};

const TextInput = ({ name, label, customValue, filterHandler }) => {
	return (
		<RowFlex center>
			<StyledFilterLabelWrapper>
				<StyledLabel padding='5px'>{label}:</StyledLabel>
			</StyledFilterLabelWrapper>
			<StyledCustomInput
				type='text'
				placeholder='Enter value'
				value={customValue}
				selected={!!customValue}
				onChange={(event) => filterHandler(name, null, null, event.target.value)}
			/>
		</RowFlex>
	);
};

const Slider = ({ name, label, minAllow, maxAllow, minValue, maxValue, step, filterHandler }) => {
	return (
		<ColumnFlex>
			<StyledFilterLabelWrapper>
				<StyledLabel>{label}:</StyledLabel>
			</StyledFilterLabelWrapper>

			<StyledRangeSelectorWrapper>
				<MultiRangeSlider
					style={{
						border: 'none',
						boxShadow: 'none',
						paddingBottom: '8px',
					}}
					min={minAllow}
					max={maxAllow}
					ruler={false}
					step={step}
					label={false}
					minValue={minAllow + step}
					maxValue={maxAllow - step}
					barInnerColor={Colors.primary.primary}
					onInput={(e) => {
						if (e.minValue !== minValue || e.maxValue !== maxValue) {
							filterHandler(name, null, {
								minValue: e.minValue,
								maxValue: e.maxValue,
							});
						}
					}}
				/>
			</StyledRangeSelectorWrapper>
		</ColumnFlex>
	);
};

const DynamicFilter = (props) => {
	const { filters, filterHandler } = props;

	return (
		<StyledContainer initial={false} aria-label='Modal' {...framerPanel}>
			{Object.keys(filters).map((filter) => {
				switch (filters[filter].type) {
					case 'tags':
						return (
							<TagFilter
								name={filters[filter].name}
								label={filters[filter].label}
								options={filters[filter].options}
								filterHandler={filterHandler}
							/>
						);
					case 'drop-down':
						return (
							<SelectFilter
								name={filters[filter].name}
								label={filters[filter].label}
								options={filters[filter].options}
								filterHandler={filterHandler}
								customValue={filters[filter].customValue}
							/>
						);
					case 'slider':
						return (
							<Slider
								name={filters[filter].name}
								label={filters[filter].label}
								minAllow={filters[filter].minAllow}
								maxAllow={filters[filter].maxAllow}
								minValue={filters[filter].minValue}
								maxValue={filters[filter].maxValue}
								step={filters[filter].step}
								filterHandler={filterHandler}
							/>
						);
					case 'text':
						return (
							<TextInput
								name={filters[filter].name}
								label={filters[filter].label}
								customValue={filters[filter].customValue}
								filterHandler={filterHandler}
							/>
						);
				}
			})}
		</StyledContainer>
	);
};

export default DynamicFilter;
