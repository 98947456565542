import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { JOB_POST_MODEL } from '../../../core/api/models';

const createJobPost = async ({ title, description, carePlanId, registryId }) => {
	try {
		const data = await api.graph({
			query: `mutation {
					createJobPost(
						title: "${title}", 
						description: "${description}", 
						care_plan_id: ${carePlanId}, 
						registry_id: ${registryId}
					) {
					${JOB_POST_MODEL}
					}
				}`,
		});

		return data.createJobPost;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useCreateJobPostMutation = () => {
	return useMutation({
		mutationKey: ['createJobPost'],
		mutationFn: (values) => {
			return createJobPost(values);
		},
	});
};
