import { useQuery } from '@tanstack/react-query';

import api from '../../utility/graphQL';

import { CARE_PRO_MODEL, STRIPE_STATUS_MODEL, CARE_PRO_REGISTRY_STATUS_MODEL } from '../models';

const checkCareProSession = async () => {
	try {
		const data = await api.graph({
			query: `
				query {
					checkProviderSession {
						${CARE_PRO_MODEL}

						stripeStatus {
							${STRIPE_STATUS_MODEL}
						}
							
						careProRegistryStatus {
							${CARE_PRO_REGISTRY_STATUS_MODEL}
						}
					}
				}
			`,
		});

		return data.checkProviderSession;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useCheckCareProSessionQuery = () => {
	return useQuery({
		queryKey: ['checkCareProSession'],
		queryFn: () => {
			return checkCareProSession();
		},
		retry: false,
	});
};
