import React from 'react';
import { connect } from 'react-redux';

import { allPayments, fetchUserDetails } from 'reducers/admin';

import SetTable from 'AppComp/SetTable';

import PaymentTableController from './PaymentTableController';

import { SetTableContainer } from '../ManageDisputes/AdminDisputesView';

class PaymentTableHandler extends PaymentTableController {
	componentDidMount() {
		const { allPayments } = this.props;

		allPayments();
	}

	render() {
		return (
			<SetTableContainer>
				<SetTable
					title='Payments'
					renderLegend={this.renderLegend}
					renderRows={this.renderRows}
					leftPadding='16px'
				/>
			</SetTableContainer>
		);
	}
}

const mapStateToProps = ({ admin }, props) => {
	return {
		payments: admin.payments,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		allPayments: () => dispatch(allPayments()),
		fetchUserDetails: (params) => dispatch(fetchUserDetails(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentTableHandler);
