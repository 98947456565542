import styled from 'styled-components';

import { colors } from '../../utils';

export const StyledTextArea = styled.textarea`
	border: 1px solid ${colors.forms.formInputBorderColor};
	border-width: 1px;
	box-shadow: none;
	border-radius: 4px;
	color: ${colors.forms.fontColor};
	line-height: 40px;
	padding: 0 12px;
	width: 100%;
	min-height: 90px;

	resize: ${(props) => props.resize};

	font-size: 15px;

	::placeholder {
		color: ${colors.forms.placeholderColor};
		opacity: 1;

		font-size: 15px;
	}

	:focus {
		outline: thin dotted currentColor;
	}
`;
