import React from 'react';

import { PrimaryButton, SecondaryButton } from '../../Buttons';

import Rating from '../../Rating';

import {
	StyledTileContainer,
	StyledAvatarContainer,
	StyledPersonalInfoContainer,
	StyledPersonalInfoWrapper,
	StyledPersonalInfo,
	StyledName,
	StyledRate,
	StyledHour,
	StyledLocation,
	StyledDescriptionContainer,
	StyledDescription,
	StyledButtonContainer,
} from './styles';

const CareProTile = (props) => {
	console.log('CareProTile', props);
	const {
		id,
		rating,
		displayName,
		professionalStatement,
		hourlyRate,
		customButtonText,
		onCustomButtonClick,
		viewCareProHandler,
	} = props;

	return (
		<StyledTileContainer>
			<StyledAvatarContainer />

			<StyledPersonalInfoContainer>
				<StyledPersonalInfoWrapper>
					<StyledPersonalInfo>
						<StyledName>{displayName}</StyledName>
						<Rating rating={rating} />
					</StyledPersonalInfo>
					<StyledRate>
						<StyledHour>{hourlyRate ? `$${hourlyRate} / hr` : 'No rate'}</StyledHour>
					</StyledRate>
				</StyledPersonalInfoWrapper>
				<StyledLocation>
					{
						// this should be location
					}
				</StyledLocation>
				<StyledDescriptionContainer>
					<StyledDescription>{professionalStatement}</StyledDescription>
				</StyledDescriptionContainer>
				<StyledButtonContainer>
					{customButtonText && (
						<PrimaryButton size='small' onClick={() => onCustomButtonClick(id)}>
							{customButtonText}
						</PrimaryButton>
					)}

					<SecondaryButton size='small' onClick={() => viewCareProHandler(id)}>
						View
					</SecondaryButton>
				</StyledButtonContainer>
			</StyledPersonalInfoContainer>
		</StyledTileContainer>
	);
};

export default CareProTile;
