import React, { useState, Fragment, useEffect } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import styled from 'styled-components';

import {
	faUserCircle,
	faHeart,
	faCalendar,
	faAddressBook,
	faCheck,
} from '@fortawesome/free-solid-svg-icons';

import 'react-circular-progressbar/dist/styles.css';

import Colors from 'colors';

import { ColumnFlex, RowFlex, BackDropBg } from 'basicStyles';

import Icon from 'AppComp/Icon';

import Text from 'AppComp/Text';

import { STEP_PROGRESS_KEYS } from './ClientOnboardParent';

/*
 * Navigation should display the current step the user is at
 * * the user should be able to go back to previous steps but not forward
 * if its' current step well show the circle with the percent if not we'll just show the icon and title
 */

/* 
  params {
    title: string,
    icon: string,
    isCurrentView: boolean,
    onboardStepsIndex: number,
    percentCompleted: int 0.1 - 1,
  }
*/
export const OnboardNavTile = (props) => {
	const {
		height,
		width,
		title,
		icon,
		isCurrentView,
		onboardStepsIndex,
		percentCompleted,
		handleClick,
		onboardStep,
	} = props;

	console.log('percentCompleted', percentCompleted);
	const clickHandler = () => {
		console.log('clicked');

		handleClick(onboardStepsIndex);
	};

	// check if the  step has been completed
	// we can check by checking if onboardStep is greater than the current step

	// only edge case is document step since its' not hard flow required

	const isStepCompleted = percentCompleted >= 1 || onboardStep > onboardStepsIndex;
	return (
		<ColumnFlex
			onClick={clickHandler}
			style={
				{
					// height: '150px',
					// width: '150px',
				}
			}
			center
			gap='8px'
		>
			{isStepCompleted ? (
				<>
					<ColumnFlex
						style={{
							width: '60px',
							height: '60px',
							justifyContent: 'flex-end',
						}}
					>
						<Icon icon={faCheck} size='2xl' color={Colors.greenLightTeal} />
					</ColumnFlex>

					<Text>{title}</Text>
				</>
			) : (
				<>
					{isCurrentView ? (
						<>
							<div
								style={{
									width: '60px',
									height: '60px',
								}}
							>
								<CircularProgressbarWithChildren
									strokeWidth={8}
									styles={buildStyles({})}
									value={percentCompleted * 100}
									minValue={1}
									maxValue={100}
								>
									<p>{percentCompleted * 100}%</p>
								</CircularProgressbarWithChildren>
								<Text>{title}</Text>
							</div>
						</>
					) : (
						<>
							<ColumnFlex
								style={{
									width: '60px',
									height: '60px',
									justifyContent: 'flex-end',
								}}
							>
								<Icon icon={icon} size='2xl' color={Colors.primary.primary} />
							</ColumnFlex>

							<Text>{title}</Text>
						</>
					)}
				</>
			)}
		</ColumnFlex>
	);
};

export const NavDivider = styled.div`
	flex: 1;
	height: 2px;
	background-color: ${Colors.primary.primary};
`;

const OnboardNav = (props) => {
	const { children } = props;

	return (
		<RowFlex
			style={{
				maxWidth: '600px',
				width: '100%',
			}}
			justifyBetween
			alignCenter
			gap='16px'
		>
			{children}

			{/* <NavDivider />
			<OnboardNavTile
				title='JobPost'
				icon={faCalendar}
				isCurrentView={onboardStep == 4}
				percentCompleted={stepsProgress[STEP_PROGRESS_KEYS.JOB_POST]}
				onboardStepsIndex={4}
				handleClick={handleStepClick}
				onboardStep={onboardStep}
			/> */}
		</RowFlex>
	);
};

export default OnboardNav;
