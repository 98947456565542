import React from 'react';
import styled from 'styled-components';

import { RowFlex, ColumnFlex } from 'basicStyles';
import Colors from 'colors';
import {
	CALENDAR_HEADER_VIEWS,
	CALENDAR_HEADER_VIEWS_FULL,
	CALENDAR_HEADER_VIEWS_FULL_MOVE,
} from 'constants';

import Text from './Text';
import Icon from './Icon';
import Title from './Title';
import { device } from './styled/StyledMediaQuery';

const HeaderCon = styled(RowFlex)`
	background-color: ${({ theme }) => theme.primaryColors.primary};
	width: 100%;

	height: 100px !important;

	border-top-right-radius: 12px;
	border-top-left-radius: 12px;

	@media ${device.mobileS} {
		flex-direction: column;
		gap: 14px;
		padding: 16px;
	}

	@media ${device.laptop} {
		flex-direction: row;
		padding: 32px;
	}
`;

const ViewTextContainer = styled.div`
	@media ${device.mobileS} {
		padding: 8px 4px;
	}

	@media ${device.laptop} {
		padding: 8px 24px;
	}
	border-radius: 16px;
	margin: 0 8px 0 8px;
	transition: all ease 0.3s;
	cursor: pointer;

	${({ selected, theme }) =>
		!selected &&
		`
		background-color: ${theme.primary.primary};
		color: white;
	`}

	${({ selected, theme }) =>
		selected &&
		`
		color: ${theme.color};
		background-color: white;
	`}
`;

export const MalCon = styled.div`
	background-color: white;
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
`;
const HeaderViewSelection = (props) => {
	const { label, value, selectedView, updateCalCurrentView } = props;

	return (
		<ViewTextContainer
			onClick={() => updateCalCurrentView(value)}
			selected={value === selectedView}
		>
			<Text>{label}</Text>
		</ViewTextContainer>
	);
};

const NavigationControl = (props) => {
	const { onNavigate, children } = props;

	return (
		<RowFlex alignCenter>
			<ViewTextContainer onClick={() => onNavigate('TODAY')} selected={false}>
				<Text>Today</Text>
			</ViewTextContainer>
			<ViewTextContainer onClick={() => onNavigate('PREV')} selected={false}>
				<Text>Back</Text>
			</ViewTextContainer>
			<ViewTextContainer onClick={() => onNavigate('NEXT')} selected={false}>
				<Text>Next</Text>
			</ViewTextContainer>
		</RowFlex>
	);
};

export const CalendarHeader = (props) => {
	console.log('CalendarHeader props', props);

	const renderViews = () => {
		return CALENDAR_HEADER_VIEWS_FULL.map((d, i) => {
			return (
				<HeaderViewSelection
					key={'hvs-' + d.id}
					{...d}
					selectedView={props.view}
					updateCalCurrentView={props.updateCalCurrentView}
				/>
			);
		});
	};

	const { onNavigate } = props;

	return (
		<HeaderCon alignCenter spaceBetween>
			<RowFlex alignCenter>
				<Title bold subtitle color='white'>
					{props.label}
				</Title>
				<RowFlex alignCenter>{renderViews()}</RowFlex>
			</RowFlex>

			<NavigationControl onNavigate={onNavigate} />
		</HeaderCon>
	);
};

export const BlockedCalendar = (props) => {
	const renderViews = () => {
		return CALENDAR_HEADER_VIEWS.map((d, i) => {
			return (
				<HeaderViewSelection
					key={'hvs-' + d.id}
					{...d}
					selectedView={props.view}
					onView={props.onView}
				/>
			);
		});
	};

	const { onNavigate, label } = props;

	return (
		<HeaderCon alignCenter spaceBetween>
			<Title color='white' subtitle bold>
				Blocked Calendar
			</Title>
			<RowFlex alignCenter>{renderViews()}</RowFlex>
			{/*
				<NavigationControl
					onNavigate={onNavigate}
				>
					{
						//label
					}
				</NavigationControl>
				*/}
		</HeaderCon>
	);
};

const TimeSlotCon = styled(RowFlex)`
	min-height: 25px;
	flex: 1;
`;

export const CustomTimeSlotWrapper = (props) => {
	const { children } = props;

	return <TimeSlotCon center>{children}</TimeSlotCon>;
};

const EventCon = styled(ColumnFlex)``;

const BlockedEventCon = styled(ColumnFlex)`
	.rbc-event {
		background-color: #ffb621;
	}
`;

const EventStausBar = styled.div`
	width: 100%;
	height: 8px;
	background-color: ${({ theme }) => theme.color};
`;

const BlockedEventStausBar = styled.div`
	width: 100%;
	height: 8px;
	background-color: #ffb621;
`;

export const CalendarEventTile = (props) => {
	const {
		title,
		event: { start, end },
	} = props;

	return (
		<EventCon justifyStart>
			<EventStausBar />
			<Text margin='8px 0 4px 0' color='white' padding='0 8px'>
				{title}
			</Text>
			<Text color='white' margin='4px 0 4px 0' padding='0 8px'></Text>
		</EventCon>
	);
};

export const CalendarBlockedEventTile = (props) => {
	const {
		title,
		event: { start, end },
	} = props;

	return (
		<BlockedEventCon justifyStart>
			<BlockedEventStausBar />
			<Text margin='8px 0 4px 0' color='white' padding='0 8px'>
				{title}
			</Text>
			<Text color='white' margin='4px 0 4px 0' padding='0 8px'></Text>
		</BlockedEventCon>
	);
};
