import React from 'react';

import { StyledPrimaryButton } from './styles';

const PrimaryButton = (props) => {
	const { children, ...restProps } = props;

	return <StyledPrimaryButton {...restProps}>{children}</StyledPrimaryButton>;
};

export default PrimaryButton;
