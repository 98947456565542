import React, { useState } from 'react';
import styled from 'styled-components';
import {
	faUserCircle,
	faTimes,
	faUserNurse,
	faAddressBook,
	faCalendar,
	faInfo,
	faMailBulk,
	faFile,
} from '@fortawesome/free-solid-svg-icons';
import Colors from 'colors';
import { isMobile } from 'react-device-detect';
import { Avatar } from 'AppComp/styled/Basic';

import colors from 'colors';

import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';
import Title from 'AppComp/Title';
import { ColumnFlex, RowFlex, DropShadow } from '../../styled/Basic';
import { device } from '../../styled/StyledMediaQuery';

import HeartIcon from '../../../fonts/heart.svg';
import HeartGreen from '../../../fonts/heart_green.svg';
import { connect } from 'react-redux';

import { toggleOnboardOverlay } from 'reducers/patient';
import { ItemColCon2, ItemsTile, LandingNavigation, LandingSection } from './WelcomePageStyles';

export const Section = styled.div`
	height: ${({ height }) => height};
	background-color: ${({ backgroundColor }) => backgroundColor};
	display: flex;
	width: 100%;
	overflow: hidden;
	position: relative;
`;
export const ContentSection = styled.div`
	text-align: 'right';
`;
export const LandingImage = styled.div`
	width: 442px;
	max-width: 1200px;
	background-image: url('https://find-your-independents.s3.amazonaws.com/images/landingImageV2.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	height: 388px;
	border-radius: 8px;
	flex: none;
	order: 0;
	flex-grow: 0;
`;

export const LandingTextSection = styled.div`
	width: inherit;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-start;
	color: white;
	height: 400px;

	@media ${device.laptop} {
		height: 388px;
		max-width: 1200px;
	}
`;

export const LeftAlignTextSection = styled.div`
	padding: 32px 0 0 10%;
	max-width: 1000px;
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	color: white;
	text-align: left;
`;
export const LandingTitle = styled(Title)`
	font-style: normal;
	font-weight: normal;
	font-size: 50px;
	line-height: 64px;
	/* or 115% */
	text-align: right;
	text-overflow: clip;
	letter-spacing: -0.04em;

	@media ${device.laptop} {
		font-size: 72px;
		line-height: 83px;
	}
`;

// margin-top: 120px;
// margin-bottom: 24px;
export const LandingText = styled(Text)`
	font-style: normal;
	font-weight: bold;
	font-size: 36px;
	line-height: 43px;
	text-align: right;

	text-align: ${({ textAlign }) => textAlign || 'inital'};

	@media ${device.laptop} {
	}
`;

//margin: 16px 0 0 0;
export const TestimonalText = styled(Text)`
	word-wrap: break-word;
	width: 320px;
	font-size: 20px;

	line-height: 22px;
	color: white;
	text-align: ${({ textAlign }) => textAlign};
`;
export const TempImage = styled.div`
	height: 100%;
	width: 100%;
	background-image: url('https://find-your-independents.s3.amazonaws.com/images/questionMark.png');
	background-repeat: no-repeat;
	background-size: 50%;
	background-position: center;
	padding: 32px 0 0 10%;
	border-radius: 8px;
`;
export const CustomRowSection = styled(RowFlex)`
	max-wdith: 2400px;
`;

export const CarouselImage = styled.div`
	max-width: 1680px;
	height: 100%;
`;
export const CImage = styled.div`
	width: 100%;
	height: 336px;
	max-width: 532px;
	background-image: url('https://find-your-independents.s3.amazonaws.com/images/landingImageV2.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	border-radius: 8px;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 10px;
`;

export const FaceTiles = styled.div`
	height: 80px;
	width: 80px;
	border-radius: 50%;
	background-color: ${Colors.secondary.green};
	margin: 16px 16px;
`;

export const Navigation = styled.div`
	width: 100vw;
	position: relative;
	margin: auto;
	margin-bottom: 20px;
`;

export const NavigationChildContainer = styled.div`
	width: 100vw;
	padding: 16px;
	margin: auto;
	position: relative;
	height: ${({ height }) => height || 'inherit'};
	z-index: 5;
	@media ${device.mobileS} {
		width: 100%;
	}
	@media ${device.mobileL} {
	}
	@media ${device.laptop} {
	}
	@media ${device.desktopL} {
		max-width: 1600px;
	}
`;
export const BasicChildContainer = styled.div`
	padding: 4px;
	margin: auto;
	position: relative;
	height: ${({ height }) => height || 'inherit'};
	z-index: 5;
	@media ${device.mobileS} {
		padding: 24px 4px;
		width: 98vw;
	}
	@media ${device.mobileL} {
		width: 100vw;
		padding: 16px;
	}
	@media ${device.laptop} {
		width: 980px;
	}
	@media ${device.desktopL} {
	}
`;

export const BasicChildContainerWider = styled.div`
	width: 100vw;
	padding: 16px;
	margin: auto;
	position: relative;
	height: ${({ height }) => height || 'inherit'};
	z-index: 5;
	@media ${device.mobileS} {
		width: 98vw;
	}
	@media ${device.mobileL} {
	}
	@media ${device.laptop} {
		width: 1280px;
	}
	@media ${device.desktopL} {
	}
`;
const TempHeart = styled.div`
	width: 60px;
	height: 60px;
	background-color: black;
	border-radius: 50%;
`;

const TextLine = styled.div`
	width: 100%;
	height: 4px;
	background-color: ${Colors.primary.primary};
`;

const TextLineG = styled.div`
	width: 100%;
	height: 4px;
	background-color: ${Colors.secondary.green};
`;
const HeartButtonStyle = styled.svg`
	width: 50px;
	height: 50px;
`;
export const WelcomeButton = ({ children, onClick }) => {
	return (
		<RowFlex pointer onClick={onClick} alignCenter>
			<HeartIcon />
			<ColumnFlex alignCenter margin='0 0 0 16px'>
				<Text color={Colors.primary.primary} largeText padding='4px'>
					{children}
				</Text>
				<TextLine />
			</ColumnFlex>
		</RowFlex>
	);
};

export const WelcomeButtonGreen = ({ children, onClick }) => {
	return (
		<RowFlex pointer onClick={onClick} alignCenter>
			<HeartGreen />
			<ColumnFlex alignCenter margin='0 0 0 16px'>
				<Text color={Colors.secondary.green} largeText padding='4px'>
					{children}
				</Text>
				<TextLineG />
			</ColumnFlex>
		</RowFlex>
	);
};
export const IntroText = styled(Text)`
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 200%;
	text-align: center;
	color: #000000;
`;

export const IntroSubText = styled(Text)`
	font-weight: 300;
	font-size: 20px;
	line-height: 150%;
	text-align: center;
`;
export const HeartOne = styled.div`
	background-image: url('https://find-your-independents.s3.amazonaws.com/Heart1.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: top right;
	position: absolute;
	right: -15;
	bottom: 50px;
	width: 300px;
	height: 300px;
	z-index: 1;

	@media ${device.laptop} {
		width: 500px;
		height: 694px;
	}
`;

export const HeartTwo = styled.div`
	background-image: url('https://find-your-independents.s3.amazonaws.com/Heart2.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: top left;
	position: absolute;
	left: -15;
	bottom: 0px;
	width: 300px;
	height: 300px;
	z-index: 1;

	@media ${device.laptop} {
		width: 500px;
		height: 694px;
	}
`;

export const LandingSubHeader = styled(Text)`
	font-size: 36px;
	line-height: 43px;
	font-weight: bold;
`;

export const LandingSubText = styled(Text)`
	font-style: normal;
	font-weight: 300;
	font-size: 18px;
	line-height: 40px;
	text-align: right;
`;

export const SubTitle = styled(Text)`
	font-style: normal;
	font-weight: bold;
	font-size: 48px;
	line-height: 58px;
	text-align: center;
`;

export const SubText = styled(Text)`
	font-style: normal;
	font-weight: normal;
	font-size: 24px;
	line-height: 40px;
	/* or 167% */

	text-align: center;
`;

export const CustomRow = styled(RowFlex)`
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	height: 180px;

	@media ${device.mobileS} {
	}
	@media ${device.laptop} {
		height: 100px;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
	@media ${device.laptopL} {
	}
	@media ${device.desktopL} {
	}
`;

const MVContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100px;
	background-color: #ffffff;
	${DropShadow(1)}
	position: relative;
	z-index: 100;
`;

const IconCon = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 24px;
	cursor: pointer;
	text-align: center;
`;

const NavigationTile = ({ icon, title, route, routeValue, currentPage, history, joyRideId }) => {
	return (
		<ColumnFlex
			margin='10px 15px 0px 15px'
			className={joyRideId}
			alignCenter
			onClick={() => history.push(route)}
		>
			<Avatar
				height='80px'
				width='80px'
				color={currentPage === routeValue ? Colors.secondary.blue : Colors.gray}
			>
				<Icon
					color={currentPage === routeValue ? Colors.secondary.blue : Colors.gray}
					icon={icon}
					size='2x'
				/>
			</Avatar>
			<Text
				margin='10px 0 0 0'
				textAlign='center'
				style={{
					textAlign: 'center',
					maxWidth: '80px',
				}}
				color={currentPage === routeValue ? Colors.secondary.blue : Colors.gray}
			>
				{title}
			</Text>
		</ColumnFlex>
	);
};

export const NAV_SIDE_ITEMS_WIDTH = '60px';
const WebsiteNavTing = ({
	title,
	history,
	patient,
	toggleOnboardOverlay,
	savedPaymentMethods,
	payments,
	showNav,
	fetchingClientSession,
	showNavHandler,
	carePlan,
}) => {
	const [showMobileNav, toggleSideNavBarMobile] = useState(false);
	const page = history.location.pathname;

	const handleInfoClick = () => {};

	const [open, setOpen] = useState(false);
	const toggleMenu = () => {
		setOpen(!open);
	};

	const showToast = () => {};

	return (
		<>
			<LandingNavigation
				toggleOnboardOverlay={toggleOnboardOverlay}
				history={history}
				patient={patient}
				handleInfoClick={handleInfoClick}
				showToast={showToast}
				savedPaymentMethods={savedPaymentMethods}
				payments={payments}
				showNav={showNav}
				showNavHandler={showNavHandler}
				fetchingClientSession={fetchingClientSession}
				carePlan={carePlan}
			/>
		</>
	);
};

const mapStateToProps = ({ patient }) => {
	return {
		savedPaymentMethods: patient.savedPaymentMethods,
		payments: patient.payments,
		patient: patient.patient,
		fetchingClientSession: patient.fetchingClientSession,
		carePlan: patient.carePlan,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleOnboardOverlay: (status) => dispatch(toggleOnboardOverlay(status)),
	};
};

export const WebsiteNav = connect(mapStateToProps, mapDispatchToProps)(WebsiteNavTing);
