import styled from 'styled-components';

export const StyledPage = styled.div`
	margin: auto;
	background-color: #edf6fb;
	min-height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 42px 0 42px 0;
`;
