import React from 'react';

import { faHeart } from '@fortawesome/free-solid-svg-icons';

import Icon from 'AppComp/Icon';
import Colors from 'colors';

import { StyledRow } from './styles';

const Rating = ({ rating }) => {
	return (
		<StyledRow>
			{[...Array(rating)].map((_, i) => {
				return <Icon key={i} size='sm' color={Colors.primary.new_primary} icon={faHeart} />;
			})}
		</StyledRow>
	);
};

export default Rating;
