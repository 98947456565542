import { z } from 'zod';

const personalStripeFormValidationSchema = z.object({
	month: z
		.string()
		.min(1, 'Month is required')
		.regex(/^(0?[1-9]|1[012])$/, 'Invalid month format.'), // MM format (1-12)
	day: z
		.string()
		.min(1, 'Day is required')
		.regex(/^(0?[1-9]|[12][0-9]|3[01])$/, 'Invalid day format.'), // DD format (1-31)
	year: z
		.string()
		.min(1, 'Year is required')
		.regex(/^(19|20)\d{2}$/, 'Invalid year format.'), // YYYY format (1900-2099)
	ssn: z
		.string()
		.min(1, 'SSN is required')
		.regex(/^\d{9}$/, 'Invalid SSN format (9 digits required).'), // SSN format: 9 digits only
});

export default personalStripeFormValidationSchema;
