import React from 'react';
import styled from 'styled-components';
import { ColumnFlex } from 'basicStyles';
import { device } from '../../styled/StyledMediaQuery';

import { PROVIDER_NAV_WIDTH } from 'constants';
//ProviderHomeDashboardView.jsx

export const ParentContainer = styled.div`
	flex: 1;

	border: 12px;

	@media ${device.laptop} {
		padding-right: calc(${PROVIDER_NAV_WIDTH} / 2);
	}
`;

export const CalContainer = styled.div`
	height: 100%;
	width: 100%;
	padding-bottom: 36px;
	margin-bottom: 36px;
	background-color: #ffffff;
`;

export const PHDColumn = styled(ColumnFlex)`
	padding: 30px 4px;
	border-radius: 12px;

	@media ${device.mobileS} {
		padding: 30px 16px;
	}

	@media ${device.laptop} {
		padding: 30px 32px 32px 52px;
	}
`;

export const PHWhiteCon = styled.div`
	border-radius: 12px;
	padding: 20px 10 10 10;
`;
