import React from 'react';

import { StyledInputContainer } from './styles';

const InputContainer = (props) => {
	const { children } = props;

	return <StyledInputContainer>{children}</StyledInputContainer>;
};

export default InputContainer;
