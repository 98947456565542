import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import { useFormik, FieldArray, FormikProvider } from 'formik-latest';
import { z } from 'zod';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import {
	ManageSectionCon,
	ManageSectionContent,
	InputContainer,
	Input,
	FormWrapper,
	ErrorMessage,
} from './styles/ManageSectionStyles';

import { SubmitButton } from './styles/FormGen';

import {
	RadioFormComp,
	TextFormComp,
	SelectFormComp,
	TextAreaFormComp,
	MultiOptionsComp,
} from './styles/ManageTraitStyles';

import useAddTrait from '../api/mutations/useAddTrait';
import useEditTrait from '../api/mutations/useEditTrait';

import { useFormTemplateContext, ACTIONS } from '../context';
import { version } from 'process';

/* 

 TEXT
  RADIO_INPUT
  SELECT_MULTI_INPUT
  TEXT_AREA
  DROP_DOWN_SELECT
  DATE_INPUT
  TIME_INPUT*/
const TRAIT_TYPES = [
	{
		value: 'TEXT',
		label: 'Text',
	},
	{
		value: 'RADIO_INPUT',
		label: 'Radio Input (Single Selection)',
	},
	{
		value: 'SELECT_MULTI_INPUT',
		label: 'Select (Multi Selection)',
	},
	{
		value: 'TEXT_AREA',
		label: 'Text Area',
	},
	// {
	// 	value: 'DROP_DOWN_SELECT',
	// 	label: 'Drop Down Select (Single Selection)',
	// },
	{
		value: 'DATE_INPUT',
		label: 'Date Input',
	},
	{
		value: 'TIME_INPUT',
		label: 'Time Input',
	},
];

// zod validation for schema
/* 
  these fields are required
  - label
  - trait_type
  - active
  - required
*/
const sectionSchema = z.object({
	label: z.string().nonempty('Label is required'),
	trait_type: z.string().nonempty('Trait Type is required'),
	active: z.string().nonempty('Active is required'),
	required: z.string().nonempty('Required is required'),
	// options is not required but if there are any it none of them should be identical
	//options: z.array(z.string()).nonempty('Options are required').unique(),
});

export const AddTrait = (props) => {
	const { isOpen, setShowAddTrait, traitTemplateId, sectionId, traits } = props;

	const { state, dispatch } = useFormTemplateContext();

	const addTrait = useAddTrait();

	const formik = useFormik({
		initialValues: {
			label: '',
			active: 'true',
			required: 'true',
			description: '',
			trait_type: 'TEXT',
			options: [],
		},
		onSubmit: (values) => handleFormSubmit(values),
		enableReinitialize: true,
		validationSchema: toFormikValidationSchema(sectionSchema),
	});

	const handleFormSubmit = async (values) => {
		try {
			let metaData = {
				version: 1,
			};

			if (values.options.length > 0) {
				metaData.options = values.options.filter((d) => d !== '').map((d) => d);

				// ensure metaData.options is unique and is no null values
				const uniqueOptions = [...new Set(metaData.options)];
				metaData.options = uniqueOptions;
			}

			// check for any changes in the values

			console.log('metaData', metaData);
			metaData = JSON.stringify(metaData);

			const newTrait = await addTrait.mutateAsync({
				label: values.label,
				active: values.active === 'true' ? true : false,
				required: values.required === 'true' ? true : false,
				traitType: values.trait_type,
				sectionId: sectionId,
				traitTemplateId: traitTemplateId,
				description: values.description || null,
				orderIndex: traits.length,
				meta_data: metaData,
			});
			const sections = state.formTemplate.sections;

			console.log('newTrait', newTrait);
			// loop through the section and add thr trait to the right section
			const updatedSections = sections.map((s) => {
				if (s.id === sectionId) {
					s.traits.push(newTrait);
				}
				return s;
			});
			// update the formTemplate with the new section

			dispatch({
				type: ACTIONS.SET_FORM_TEMPLATE,
				payload: {
					...state.formTemplate,
					sections: updatedSections,
				},
			});

			setShowAddTrait(false);
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<motion.div
			initial={{ x: '200%' }}
			animate={{ x: isOpen ? -50 : '200%' }}
			exit={{ x: '200%' }}
			transition={{ type: 'spring', stiffness: 300, damping: 30 }}
			style={{
				width: '380px',
				height: 'fit-content',
				maxHeight: '90vh',
				overflowY: 'auto',
				backgroundColor: 'white',
				position: 'fixed',
				top: 20,
				bottom: 0,
				right: 0,
				zIndex: 1000,
				borderRadius: '8px',
				border: '1px solid rgba(43, 105, 166, 0.3)',
			}}
		>
			<ManageSectionCon>
				<h2>Add a Trait</h2>
				<ManageSectionContent>
					<FormWrapper onSubmit={formik.handleSubmit}>
						<TextFormComp
							name='label'
							onChange={formik.handleChange}
							value={formik.values.label}
							title='Question Label'
							placeholder='Enter a question label'
							error={formik.errors.label}
						/>
						<SelectFormComp
							name='trait_type'
							onChange={formik.handleChange}
							value={formik.values.trait_type}
							title='Trait Type'
							options={TRAIT_TYPES}
							error={formik.errors.trait_type}
						/>
						{/* Conditionally Render Options Field */}
						{['RADIO_INPUT', 'SELECT_MULTI_INPUT', 'DROP_DOWN_SELECT'].includes(
							formik.values.trait_type
						) && (
							<FormikProvider value={formik}>
								<FieldArray
									validateOnChange={false}
									name='options'
									render={(arrayHelpers) => {
										return (
											<>
												<MultiOptionsComp
													arrayHelpers={arrayHelpers}
													formik={formik}
												/>
												<div>
													{/* Display error if options are required but not provided */}
													{formik.errors.options &&
														typeof formik.errors.options ===
															'string' && (
															<div className='error'>
																{formik.errors.options}
															</div>
														)}
												</div>
											</>
										);
									}}
								/>
							</FormikProvider>
						)}

						<RadioFormComp
							name='active'
							onChange={formik.handleChange}
							value={formik.values.active}
							title='Active'
							options={[
								{ value: 'true', label: 'Yes' },
								{ value: 'false', label: 'No' },
							]}
							error={formik.errors.active}
						/>
						<RadioFormComp
							name='required'
							onChange={formik.handleChange}
							value={formik.values.required}
							title='Required'
							options={[
								{ value: 'true', label: 'Yes' },
								{ value: 'false', label: 'No' },
							]}
							error={formik.errors.required}
						/>
						<TextAreaFormComp
							name='description'
							onChange={formik.handleChange}
							value={formik.values.description}
							title='Description'
							placeholder='Enter a description'
							error={formik.errors.description}
						/>
						<SubmitButton
							type='submit'
							style={{
								margin: '0 auto',
							}}
						>
							<p>Add Trait</p>
						</SubmitButton>
					</FormWrapper>
					<p
						onClick={() => setShowAddTrait(false)}
						style={{
							color: 'black',
							textAlign: 'center',
							cursor: 'pointer',
							fontWeight: 'bold',
							margin: '0 auto',
						}}
					>
						Close
					</p>
				</ManageSectionContent>
			</ManageSectionCon>
		</motion.div>
	);
};

export const EditTrait = (props) => {
	const { isOpen, setShowEditTrait, trait } = props;

	const { state, dispatch } = useFormTemplateContext();

	const editTrait = useEditTrait();

	const renderTraitMetaDataOptions = () => {
		console.log('renderTraitMetaData - trait', trait);
		if (trait.meta_data) {
			const metaData = JSON.parse(trait.meta_data);
			// check what version of this meta data is
			if (metaData.version) {
				if (metaData.version === 1) {
					// version one assumes that option is saved as string[]
					if (metaData.options) {
						// so we can simply return it as expected
						return metaData.options;
					}
				}
			}
		}
		return [];
	};
	const formik = useFormik({
		initialValues: {
			label: trait.label,
			active: trait.active ? 'true' : 'false',
			required: trait.required ? 'true' : 'false',
			description: trait.description || '',
			trait_type: trait.trait_type,
			options: renderTraitMetaDataOptions(),
		},
		onSubmit: (values) => handleSubmit(values),
		enableReinitialize: true,
		validationSchema: toFormikValidationSchema(sectionSchema),
	});

	const handleSubmit = async (values) => {
		console.log('EditTrait - values', values);
		try {
			// so this needs to be enforced from the front end & backend
			// basically in version 1 items we're going to only hold values and not labels (since we're assumign the value and label are identical)
			let metaData = {
				version: 1,
			};

			if (values.options.length > 0) {
				metaData.options = values.options.filter((d) => d !== '').map((d) => d);

				// ensure metaData.options is unique and is no null values
				const uniqueOptions = [...new Set(metaData.options)];
				metaData.options = uniqueOptions;
			}

			// check for any changes in the values
			console.log('metaData', metaData);
			metaData = JSON.stringify(metaData);

			const updatedTrait = await editTrait.mutateAsync({
				trait_id: trait.id,
				label: values.label,
				description: values.description,
				active: values.active === 'true' ? true : false,
				required: values.required === 'true' ? true : false,
				trait_type: values.trait_type,
				section_id: trait.section_id,
				meta_data: metaData,
				order_index: trait.order_index,
			});
			console.log('updatedTrait', updatedTrait);

			const sections = state.formTemplate.sections;
			// loop through the section and add thr trait to the right section
			const updatedSections = sections.map((section) => {
				if (section.id === trait.section_id) {
					section.traits = section.traits.map((t) => {
						if (t.id === trait.id) {
							return updatedTrait;
						}
						return t;
					});
				}
				return section;
			});
			// update the formTemplate with the new section

			console.log('0xupdatedSections', updatedSections);
			dispatch({
				type: ACTIONS.SET_FORM_TEMPLATE,
				payload: {
					...state.formTemplate,
					sections: updatedSections,
				},
			});

			setShowEditTrait(false);
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<motion.div
			initial={{ x: '200%' }}
			animate={{ x: isOpen ? -50 : '200%' }}
			exit={{ x: '200%' }}
			transition={{ type: 'spring', stiffness: 300, damping: 30 }}
			style={{
				width: '380px',
				height: 'fit-content',
				maxHeight: '90vh',
				overflowY: 'auto',
				backgroundColor: 'white',
				position: 'fixed',
				top: 20,
				bottom: 0,
				right: 0,
				zIndex: 1000,
				borderRadius: '8px',
				border: '1px solid rgba(43, 105, 166, 0.3)',
			}}
		>
			<ManageSectionCon>
				<h2>Edit Trait</h2>
				<ManageSectionContent>
					<FormWrapper onSubmit={formik.handleSubmit}>
						<TextFormComp
							name='label'
							onChange={formik.handleChange}
							value={formik.values.label}
							title='Question Label'
							placeholder='Enter a question label'
							error={formik.errors.label}
						/>
						{/* <SelectFormComp
							name='trait_type'
							onChange={formik.handleChange}
							value={formik.values.trait_type}
							title='Trait Type'
							options={TRAIT_TYPES}
							error={formik.errors.trait_type}
						/> */}
						{/* Conditionally Render Options Field */}
						{['RADIO_INPUT', 'SELECT_MULTI_INPUT', 'DROP_DOWN_SELECT'].includes(
							formik.values.trait_type
						) && (
							<FormikProvider value={formik}>
								<FieldArray
									validateOnChange={false}
									name='options'
									render={(arrayHelpers) => {
										return (
											<>
												<MultiOptionsComp
													arrayHelpers={arrayHelpers}
													formik={formik}
												/>
												<div>
													{/* Display error if options are required but not provided */}
													{formik.errors.options &&
														typeof formik.errors.options ===
															'string' && (
															<div className='error'>
																{formik.errors.options}
															</div>
														)}
												</div>
											</>
										);
									}}
								/>
							</FormikProvider>
						)}
						<RadioFormComp
							name='active'
							onChange={formik.handleChange}
							value={formik.values.active}
							title='Active'
							options={[
								{ value: 'true', label: 'Yes' },
								{ value: 'false', label: 'No' },
							]}
							error={formik.errors.active}
						/>
						<RadioFormComp
							name='required'
							onChange={formik.handleChange}
							value={formik.values.required}
							title='Required'
							options={[
								{ value: 'true', label: 'Yes' },
								{ value: 'false', label: 'No' },
							]}
							error={formik.errors.required}
						/>
						<TextAreaFormComp
							name='description'
							onChange={formik.handleChange}
							value={formik.values.description}
							title='Description'
							placeholder='Enter a description'
							error={formik.errors.description}
						/>
						<SubmitButton
							type='submit'
							style={{
								margin: '0 auto',
							}}
						>
							<p>Save Edits</p>
						</SubmitButton>
					</FormWrapper>
					<p
						onClick={() => setShowEditTrait(false)}
						style={{
							color: 'black',
							textAlign: 'center',
							cursor: 'pointer',
							fontWeight: 'bold',
							margin: '0 auto',
						}}
					>
						Close
					</p>
				</ManageSectionContent>
			</ManageSectionCon>
		</motion.div>
	);
};
