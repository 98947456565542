import React, { Fragment } from 'react';
import styled from 'styled-components';
import is from 'styled-is';
import { connect } from 'react-redux';

import { resetOnboard } from 'reducers/model';
import AnimationWrapper from './AnimationWrapper';
import Text from './Text';

import color from '../constants/colors';

export const ModelContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 80vw;
	max-width: 700px;
	border-radius: 12px;
	background-color: white;
	margin-top: 12px;
	${is('center')`
		align-items: center;
	`}
`;

export const ModelHeader = (props) => {
	const { children } = props;

	return (
		<ModelTitle>
			<div />
			<Text color='white' largeText>
				{children}
			</Text>
			<div />
		</ModelTitle>
	);
};
export const ModelTitle = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.color || '#2BAFA4'};
	height: 54px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding: 0 8px;
	text-align: center;
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
`;

export const ModelBottom = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: #457699;
	height: 54px;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	padding: 0 8px;
`;

export const BasicModelButton = (props) => {
	const { children, id, onClick } = props;

	return (
		<ModelButton id={id || 'BasicModelButton'} valid onClick={onClick}>
			<div />
			<Text color='white' largeText>
				{children}
			</Text>
			<div />
		</ModelButton>
	);
};

export const ModelButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ theme }) => theme.color || '#457699'};
	height: 54px;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	transition: opacity 0.3s ease;
	cursor: pointer;
	${({ valid }) =>
		!valid &&
		`
		opacity: .5;
	`}

	${({ valid }) =>
		valid &&
		`
		opacity: 1;
	`}

	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		opacity: 0.9;
	}
`;

export const ModelFormContainer = styled.div`
	display: flex;
	flex-direction: row;
	background-color: ${color.lessGray};
	align-items: center;
	justify-content: space-around;
	width: 100%;
	padding: 24px 0 24px 0;
`;

export const FieldCon = styled.div`
	padding: ${(props) => (props.padding ? props.padding : '0')};
	display: flex;
	flex-direction: column;
	width: 36%;
	margin: ${(props) => (props.margin ? props.margin : '0')};
`;

export const AlternateSection = styled.div`
	height: ${(props) => (props.height ? props.height : 'auto')};
	background-color: ${({ bg }) => bg || color.lightGrey};
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
	padding: ${(props) => props.padding || '0'};
`;
class Wrapper extends React.Component {
	componentWillUnmount() {
		//this.props.receiveLoginError(null)
		const { reset, receiveError, receiveSuccess, step } = this.props;
		if (reset) {
			receiveError(null);
			receiveSuccess(null);
			step(0);
		}
	}

	renderChildren = () => {
		const { children, step } = this.props;

		if (Array.isArray(children)) {
			return children.map((d, i) => {
				const Cloned = React.cloneElement(d);
				return (
					<AnimationWrapper
						status={step === i}
						classNames='model'
						key={'model-' + i}
						timeout={{
							enter: 1200,
							exit: 1200,
						}}
					>
						<Cloned {...props} />
					</AnimationWrapper>
				);
			});
		} else {
			//const Component =
			return React.cloneElement(children, { ...this.props });
		}
	};

	render() {
		return <Fragment>{this.renderChildren()}</Fragment>;
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		resetOnboard: () => dispatch(resetOnboard(err)),
	};
};

export default connect(null, mapDispatchToProps)(Wrapper);
