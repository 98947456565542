import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const addScheduleToJobPost = async ({
	startDate,
	startTime,
	endDate,
	endTime,
	repeatFrequency,
	daysOfWeek,
	jobPostId,
	carePlanId,
}) => {
	try {
		const data = await api.graph({
			query: `mutation {
                addScheduleToJobPost(
					start_date: "${startDate}",
                    start_time: "${startTime}",
					end_date: "${endDate}",
                    end_time: "${endTime}",
					repeat_frequency: "${repeatFrequency}",
					days_of_week: "${daysOfWeek}",
                    care_plan_id: ${carePlanId},
					job_post_id: ${jobPostId}
                    ) {
                    id
                }
            }`,
		});

		return data.addScheduleToJobPost;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useAddScheduleToJobPost = () => {
	return useMutation({
		mutationKey: ['addScheduleToJobPost'],
		mutationFn: (values) => {
			return addScheduleToJobPost(values);
		},
	});
};
