import React, { Component, useState, useMemo, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { connect } from 'react-redux';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Views } from 'react-big-calendar';
import styled from 'styled-components';

import Colors from 'colors';
import { WORK_TIME_FRAME_TYPE, JOB_OFFER_STATUS_ENUM, JOB_STATUS } from 'constants';
import api from 'scripts/api';
import useWindowSize from 'scripts/useWindowSize';
import usePreventScroll from 'scripts/usePreventScroll';

import {
	BackDropBg,
	ColumnFlex,
	RowFlex,
	MODAL_BACKDROP,
	MODAL_PANEL,
	ModalPanel,
} from 'basicStyles';
import Icon from 'AppComp/Icon';
import Button from 'AppComp/Button';
import Text from 'AppComp/Text';

import Schedule from './Schedule';
import ClientScheduleToolbar from './ClientScheduleToolbar';
import ClientSchedulePartialToolbar from './ClientSchedulePartialToolbar';
import AddScheduleSession from './AddScheduleSession';
import EditScheduleSession from './EditScheduleSession';

export const ActionSection = styled.div`
	flex: 1;
	height: fit-content;
`;

export const TileType = {
	TENTATIVE: 'TENTATIVE',
	SCHEDULED: 'SCHEDULED',
	RESCHEDULED: 'RESCHEDULED',
	PENDING_CLIENT_APPROVAL: 'PENDING_CLIENT_APPROVAL',
	PENDING_PROVIDER_APPROVAL: 'PENDING_PROVIDER_APPROVAL',
};

const ClientScheduleHandler = (props) => {
	console.log('ClientSchedulehandler', props.carePlan);
	const { patient, carePlan, history } = props;

	const [userSelectedDate, setUserSelectedDate] = useState(new Date());
	const currentDate = useMemo(() => userSelectedDate, [userSelectedDate]);

	const [events, setEvents] = useState([]);
	const [jobEvents, setJobEvents] = useState([]);

	const [showAddScheduleOverlay, setShowAddScheduleOverlay] = useState(false);

	const [selectedDateTileType, setSelectedDateTileType] = useState([
		TileType.TENTATIVE,
		TileType.SCHEDULED,
		TileType.RESCHEDULED,
		TileType.PENDING_CLIENT_APPROVAL,
		TileType.PENDING_PROVIDER_APPROVAL,
	]);
	const [view, setView] = useState({
		label: 'Month',
		value: Views.MONTH,
	});

	const [displayLabel, setDisplayLabel] = useState('Day');
	const { width } = useWindowSize();

	const [selectedEvent, setSelectedEvent] = useState(null);
	const [showScheduleFiltersOverlay, setShowScheduleFiltersOverlay] = useState(false);
	const [showEditEventOverlay, setShowEditEventOverlay] = useState(false);

	usePreventScroll(showAddScheduleOverlay);

	useEffect(() => {
		// create the events array that Schedule will consume
		if (carePlan) {
			createScheduleEventsFromCarePlan(carePlan.workTimeFrames);
			createJobEvents(carePlan.jobs);
		}
	}, [carePlan]);

	const createJobEvents = (jobs) => {
		// each job has workTimeFrame

		// we only want the jobs that have
		if (carePlan === null) {
			return;
		}

		const acceptedJobs = jobs.filter(
			(job) =>
				job.offer_status === JOB_OFFER_STATUS_ENUM.ACCEPTED &&
				job.job_status === JOB_STATUS.ACTIVE
		);

		const _jobEvents = [];

		acceptedJobs.forEach((job) => {
			// get the active timeframes
			const activeTimeFrames = job.workTimeFrames.filter(
				(timeFrame) => timeFrame.active_status_enum === WORK_TIME_FRAME_TYPE.ACTIVE
			);

			activeTimeFrames.forEach((timeFrame) => {
				const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's time zone

				const userStartDate = new Date(
					timeFrame.start_time.toLocaleString('en-US', { timeZone: userTimezone })
				);
				const userEndDate = new Date(
					timeFrame.end_time.toLocaleString('en-US', { timeZone: userTimezone })
				);
				_jobEvents.push({
					title: 'Job Session',
					start: userStartDate,
					end: userEndDate,
					allDay: false,
					resource: {
						type: 'jobSession',
						workTimeFrame: timeFrame,
						job: job,
					},
				});
			});
		});

		console.log('_jobEvents', _jobEvents);
		setJobEvents(_jobEvents);
	};
	const createScheduleEventsFromCarePlan = (workTimeFrames) => {
		if (carePlan === null) {
			return;
		}

		// loop through workTimeFrames
		// first get all the active ones
		const activeWorkTimeFrames = workTimeFrames.filter((workTimeFrame) => {
			return workTimeFrame.active_status_enum === WORK_TIME_FRAME_TYPE.ACTIVE;
		});

		console.log('activeWorkTimeFrames', activeWorkTimeFrames);
		// create custom shape that react-big-calendar can consume
		const _events = activeWorkTimeFrames.map((workTimeFrame) => {
			// ensure to convert gmt to local time

			const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's time zone

			const userStartDate = new Date(
				workTimeFrame.start_time.toLocaleString('en-US', { timeZone: userTimezone })
			);
			const userEndDate = new Date(
				workTimeFrame.end_time.toLocaleString('en-US', { timeZone: userTimezone })
			);

			return {
				title: 'Care Session',
				start: userStartDate,
				end: userEndDate,
				allDay: false,
				resource: {
					type: 'careSession',
					workTimeFrame: workTimeFrame,
					carePlan: carePlan,
				},
			};
		});

		setEvents(_events);
	};

	const isMobile = width < 768;

	const handleModalExitClick = () => {
		setShowAddScheduleOverlay(false);
	};

	const handleSuccess = async (editEvent) => {
		console.log('CLientScheld handler handleSuccess', editEvent);
		try {
			// basically just fetch the latest events and rerender them
			const workTimeFramesRes = await api.graph({
				query: ` {
					checkUserSession {
						carePlan {
							workTimeFrames {
								id,
								start_time,
								end_time,
								type_enum,
								active_status_enum,
								pattern_id,
								pattern_key,	
							}
						}
					}
				}`,
			});

			if (workTimeFramesRes.checkUserSession.carePlan) {
				createScheduleEventsFromCarePlan(
					workTimeFramesRes.checkUserSession.carePlan.workTimeFrames
				);

				if (editEvent) {
					setShowEditEventOverlay(false);
				} else {
					setShowAddScheduleOverlay(false);
				}
			}
		} catch (err) {
			console.error(err);
		}
	};
	const onSelectEvent = (event) => {
		console.log('onSelectEvent', event);
		setSelectedEvent(event);
		setShowEditEventOverlay(true);
	};

	const ScheduleFilters = () => {
		if (isMobile) {
			return (
				<AnimatePresence mode='wait' initial={false}>
					{showScheduleFiltersOverlay && (
						<>
							<BackDropBg
								{...MODAL_BACKDROP}
								aria-hidden='true'
								onClick={() => setShowScheduleFiltersOverlay(false)}
							/>

							<ModalPanel aria-label='Sidebar' {...MODAL_PANEL}>
								<ColumnFlex gap='32px'>
									<ClientScheduleToolbar
										selectedDateTileType={selectedDateTileType}
										setSelectedDateTileType={setSelectedDateTileType}
										displayLabel={displayLabel}
										setView={setView}
										view={view}
										currentDate={currentDate}
										setUserSelectedDate={setUserSelectedDate}
									/>
									<Text
										onClick={() => setShowScheduleFiltersOverlay(false)}
										style={{
											cursor: 'pointer',
											margin: 'auto',
										}}
										fontWeight='100'
										color={'white'}
									>
										exit
									</Text>
									<RowFlex fullWidth center>
										<Button
											backgroundColor={Colors.primary.primary}
											width={'300px'}
											height={'70px'}
											borderRadius='32px'
											style={{
												shadow: 'none',
											}}
											onClick={() => setShowAddScheduleOverlay(true)}
										>
											<Text fontWeight='500' color={'white'} largeText>
												Add a Schedule
											</Text>
										</Button>
									</RowFlex>
								</ColumnFlex>
							</ModalPanel>
						</>
					)}
				</AnimatePresence>
			);
		} else {
			return (
				<ColumnFlex gap='32px'>
					<ClientScheduleToolbar
						selectedDateTileType={selectedDateTileType}
						setSelectedDateTileType={setSelectedDateTileType}
						displayLabel={displayLabel}
						setView={setView}
						view={view}
						currentDate={currentDate}
						setUserSelectedDate={setUserSelectedDate}
					/>
					<RowFlex fullWidth center>
						<Button
							backgroundColor={Colors.primary.primary}
							width={'300px'}
							height={'70px'}
							borderRadius='32px'
							style={{
								shadow: 'none',
							}}
							onClick={() => setShowAddScheduleOverlay(true)}
						>
							<Text fontWeight='500' color={'white'} largeText>
								Add a Schedule
							</Text>
						</Button>
					</RowFlex>
				</ColumnFlex>
			);
		}
	};

	return (
		<RowFlex
			columnMobile
			style={{
				backgroundColor: Colors.theme.primary_background,
			}}
			fullWidth
			padding='16px'
			gap='16px'
		>
			<AnimatePresence mode='wait' initial={false}>
				{showAddScheduleOverlay && (
					<AddScheduleSession
						carePlan={carePlan}
						handleModalExitClick={handleModalExitClick}
						handleSuccess={handleSuccess}
					/>
				)}
			</AnimatePresence>
			<AnimatePresence mode='wait' initial={false}>
				{showEditEventOverlay && (
					<EditScheduleSession
						event={selectedEvent}
						handleModalExitClick={() => setShowEditEventOverlay(false)}
						handleSuccess={handleSuccess}
					/>
				)}
			</AnimatePresence>
			{isMobile && (
				<>
					<ClientSchedulePartialToolbar
						selectedDateTileType={selectedDateTileType}
						setSelectedDateTileType={setSelectedDateTileType}
						displayLabel={displayLabel}
						setView={setView}
						view={view}
						currentDate={currentDate}
						setUserSelectedDate={setUserSelectedDate}
					></ClientSchedulePartialToolbar>
					<button
						style={{ background: 'white', border: 'none' }}
						onClick={() => setShowScheduleFiltersOverlay(true)}
					>
						<Icon
							color={Colors.secondary.blue}
							icon={faBars}
							size={isMobile ? '1x' : '2x'}
						/>
					</button>
				</>
			)}
			<ScheduleFilters />
			<Schedule
				events={[...events, ...jobEvents]}
				view={view}
				setDisplayLabel={setDisplayLabel}
				currentDate={currentDate}
				onSelectEvent={onSelectEvent}
			/>
		</RowFlex>
	);
};

const mapStateToProps = ({ patient }) => {
	return {
		carePlan: patient.carePlan,
		patient: patient.patient,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientScheduleHandler);
