import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FormContainer, Input, Select, Label, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../FormWrapper';
import InputContainer from '../InputContainer';

import addressFormValidationSchema from './validation';

import { usStates } from '../../../../../corev2/utils';
import { TextStyled } from '../../../../../corev2/Text';

const AddressForm = (props) => {
	const { loading, initialValues, addressFormHandler, serverError } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => addressFormHandler(values),
		enableReinitialize: true,
		validationSchema: toFormikValidationSchema(addressFormValidationSchema),
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Label> Address Line 1</Label>

					<Input
						name='addressLine1'
						type='text'
						onChange={formik.handleChange}
						placeholder='Address Line 1'
						value={formik.values.addressLine1}
					/>

					{formik.errors.addressLine1 && (
						<ErrorMessage> {formik.errors.addressLine1} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Address Line 2</Label>

					<Input
						name='addressLine2'
						type='text'
						onChange={formik.handleChange}
						placeholder='Address Line 2'
						value={formik.values.addressLine2}
					/>

					{formik.errors.addressLine2 && (
						<ErrorMessage> {formik.errors.addressLine2} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> City </Label>

					<Input
						name='city'
						type='text'
						onChange={formik.handleChange}
						placeholder='City'
						value={formik.values.city}
					/>

					{formik.errors.city && <ErrorMessage> {formik.errors.city} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Label> State</Label>

					<Select name='state' onChange={formik.handleChange} value={formik.values.state}>
						{usStates.map((usState) => {
							return (
								<option key={usState} value={usState}>
									{usState}
								</option>
							);
						})}
					</Select>

					{formik.errors.state && <ErrorMessage> {formik.errors.state} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Label> ZIP </Label>

					<Input
						name='zip'
						type='text'
						onChange={formik.handleChange}
						placeholder='ZIP'
						value={formik.values.zip}
					/>

					{formik.errors.zip && <ErrorMessage> {formik.errors.zip} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					{loading ? (
						<TextStyled
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							Loading...
						</TextStyled>
					) : (
						<PrimaryButton type='submit' size='small'>
							Next
						</PrimaryButton>
					)}

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default AddressForm;
