import React from 'react';
import styled from 'styled-components';
import Title from '../../Title';

import { MARGIN_TITLE, OBBasicCon, OBButtonText, OBSubText, MARGIN_ITEMS } from './OnboardViews';
import { ColumnFlex } from '../../styled/Basic';
import Text from 'AppComp/Text';

export const LandingImage = styled.div`
	width: 300px;
	height: 200px;
	background-image: url('https://find-your-independents.s3.amazonaws.com/images/landingImageV2.png');
	background-repeat: no-repeat;

	background-position: center;
	border-radius: 40px;
`;

const POB1Learn = (props) => {
	const handleSubmit = () => {
		props.ChangeLocation(1);
	};

	return (
		<div
			style={{
				padding: '16px',
			}}
		>
			<OBBasicCon
				style={
					{
						// maxWidth: '420px',
					}
				}
			>
				<div style={{ borderRadius: '20px' }}>
					<LandingImage />
				</div>

				<Title
					style={{
						textAlign: 'center',
					}}
					margin={MARGIN_TITLE}
					subtitle
				>
					Welcome to the FYI Care Giver Community!
				</Title>

				<ColumnFlex margin='20px 0' style={{ textAlign: 'center', maxWidth: '400px' }}>
					<Text
						mediumText
						color='#636363'
						style={{ lineHeight: '24px' }}
						margin={MARGIN_ITEMS}
					>
						No more long waits to get paid by a payroll company… So if you are tired of
						being paid weekly or bi-weekly … Join the FYI marketplace.
					</Text>

					<OBSubText
						text='FYI is a marketplace web service. You are in control at FYI.
				You are self-empowered to schedule your workday and be in
				charge of setting your own pay rates that you, and your
				clients agree upon together.'
					/>
				</ColumnFlex>
				<OBButtonText valid onClick={handleSubmit} text='Continue' />
			</OBBasicCon>
		</div>
	);
};

export default POB1Learn;
