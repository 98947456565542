import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { PAYMENT_MODEL } from '../../../core/api/models';

const fetchPayments = async () => {
	try {
		const data = await api.graph({
			query: `{
				checkUserSession{
                    payments {
                        ${PAYMENT_MODEL}
                    }
				}
			}`,
		});

		return data.checkUserSession.payments;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchPaymentsQuery = () => {
	return useQuery({
		queryKey: ['fetchPayments'],
		queryFn: () => {
			return fetchPayments();
		},
	});
};
