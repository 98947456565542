import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ShiftsPage from '../pages/ShiftsPage';
import ShiftPage from '../pages/ShiftPage';

import { shiftsRoutes } from './constants';

const ShiftsRoutes = () => {
	return (
		<Switch>
			<Route path={shiftsRoutes.shift(':shiftId')} exact>
				<ShiftPage />
			</Route>

			<Route path={shiftsRoutes.home}>
				<ShiftsPage />
			</Route>
		</Switch>
	);
};

export default ShiftsRoutes;
