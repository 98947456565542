import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';

import { UPDATE_PASSWORD_THEME } from 'constants';

import { changePatientPassword } from 'reducers/patient';

import OnboardFormHandler from 'AppComp/OnboardFormHandler';
import Text from 'AppComp/Text';

import { ColumnFlex, RowFlex } from 'basicStyles';

export default class ChangePasswordHandler extends Component {
	state = {};

	//form func to run when all fields are valid, needs to handle register and login of patient
	handleSubmit = (values, form) => {
		const { changePassword } = this.props;

		changePassword(values.password);
	};

	//handles rendering the inital values for the form to validate
	renderInitalValues = () => {
		const initValues = {
			password: '',
			confirmPassword: '',
		};

		return initValues;
	};

	validate = (values) => {
		const errors = {};
		const err = 'Required';

		if (!values.password) {
			errors.password = err;
		}

		if (!values.confirmPassword) {
			errors.confirmPassword = err;
		}

		if (values.confirmPassword !== values.password) {
			errors.confirmPassword = 'Passwords are not identical';
		}

		return errors;
	};

	render() {
		const { error, passwordStatus, handleExitClick } = this.props;

		const { activeTheme } = this.state;

		return (
			<OnboardFormHandler
				error={error}
				validate={this.validate}
				handleSubmit={this.handleSubmit}
				renderInitalValues={this.renderInitalValues}
				theme={UPDATE_PASSWORD_THEME}
			>
				<ColumnFlex fullHeight center>
					{passwordStatus !== null && (
						<>
							<Text margin='24px 0 0 0' largeText color='white' bold>
								{passwordStatus.message}
							</Text>
							{passwordStatus.status && (
								<Text
									margin='24px 0 0 0'
									largeText
									color='white'
									bold
									pointer
									underline
									onClick={() => handleExitClick()}
								>
									Exit
								</Text>
							)}
						</>
					)}
				</ColumnFlex>
			</OnboardFormHandler>
		);
	}
}
