import { z } from 'zod';

const editDetailsValidationSchema = z.object({
	email: z.string().email('Invalid email address'),
	phone: z.string().refine(
		(val) => {
			const regex = /^(1\s?)?(\d{3}[-\s.]?)?\d{3}[-\s.]?\d{4}$/;
			return regex.test(val);
		},
		{
			message:
				'Invalid phone number format. Must be xxx-xxx-xxxx, xxxxxxxxxx, or 1xxxxxxxxxx.',
		}
	),
});

export default editDetailsValidationSchema;
