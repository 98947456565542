import React, { useState } from 'react';
import styled from 'styled-components';
import Colors from 'colors';

import { ColumnFlex, RowFlex, BackDropBg } from 'basicStyles';

export const OnboardFormContainer = styled(ColumnFlex)`
	background-color: white;
	border-radius: 20px;
	padding: 30px;
	gap: 20px;
	max-width: 700px;
`;

import Title from 'AppComp/Title';
import FormButton from 'AppComp/FormButton';
import Button from 'AppComp/Button';
import Text from 'AppComp/Text';

export const OnboardTitle = (props) => {
	const { children, style } = props;
	return (
		<Title style={style} bold subtitle>
			{children}
		</Title>
	);
};

export const OnboardSubText = (props) => {
	const { children, style, onClick } = props;

	return (
		<Text
			onClick={onClick}
			style={{
				textAlign: 'center',
				...style,
			}}
			mediumText
			gray
		>
			{children}
		</Text>
	);
};

export const MARGIN_ITEMS = '0';

export const FORM_ITEM_LENGTH = '260px';

export const FormItemsLength = styled.div`
	width: ${({ width }) => width || FORM_ITEM_LENGTH};
	margin: ${({ margin }) => margin ?? MARGIN_ITEMS};
	color: black;
`;

export const OnboardFormButtonStyle = styled(FormButton)`
	min-width: 240px;
	height: 70px;
	border-radius: 50px;
	box-shadow: none;

	${({ valid, backgroundColor }) =>
		!valid &&
		`
		background-color: white;
		border: 1px solid ${backgroundColor || Colors.primary.primary};
		color: ${backgroundColor || Colors.primary.primary};

	`}

	${({ valid, backgroundColor }) =>
		valid &&
		`
		background-color: ${backgroundColor || Colors.primary.primary};
		color: white;
	`}
`;

export const OnboardButtonStyle = styled(Button)`
	min-width: 240px;
	height: 70px;
	border-radius: 50px;
	box-shadow: none;

	${({ valid, backgroundColor }) =>
		!valid &&
		`
		background-color: white;
		border: 1px solid ${backgroundColor || Colors.primary.primary};
		color: ${backgroundColor || Colors.primary.primary};

	`}

	${({ valid, backgroundColor }) =>
		valid &&
		`
		background-color: ${backgroundColor || Colors.primary.primary};
		color: white;
	`}
`;
export const OnboardFormButton = (props) => {
	const { backgroundColor, valid, type, children, margin } = props;

	return (
		<OnboardFormButtonStyle
			margin={margin || '0 0 20px 0'}
			backgroundColor={backgroundColor}
			type={type}
			valid={valid}
		>
			<Title trititle color={valid ? Colors.fullWhite : Colors.darkerGray}>
				{children}
			</Title>
		</OnboardFormButtonStyle>
	);
};

export const OnboardButton = (props) => {
	const { backgroundColor, children, margin, onClick } = props;

	return (
		<OnboardButtonStyle
			margin={margin || '0'}
			backgroundColor={backgroundColor}
			valid={true}
			onClick={onClick}
		>
			<Title trititle color={Colors.fullWhite}>
				{children}
			</Title>
		</OnboardButtonStyle>
	);
};
