import { useQuery } from '@tanstack/react-query';

import api from '../../utility/graphQL';

import { CLIENT_MODEl } from '../models';

const checkUserSession = async () => {
	try {
		const data = await api.graph({
			query: `{
				checkUserSession{
					${CLIENT_MODEl}
				}
			}`,
		});

		return data.checkUserSession;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useCheckUserSessionQuery = () => {
	return useQuery({
		queryKey: ['checkUserSession'],
		queryFn: () => {
			return checkUserSession();
		},
		retry: false,
	});
};
