export const ValidateAttributeInput = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.label) {
		errors.label = err;
	}

	if (!values.type) {
		errors.attribute = err;
	}

	// ensure the type value isn't the default value
	if (values.type === null) {
		errors.type = 'Required';
	}
	console.log('errors', errors);
	return errors;
};
