import React, { useState } from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import AddressForm from '../../../components/AddressForm';

import { useStripeOnboarding } from '../../../hooks/useStripeOnboarding';
import { useUpdateCareProStripeInformationMutation } from '../../../api/mutations/useUpdateCareProStripeInformationMutation';

import { StyledAddressPage } from './styles';

const AddressPage = () => {
	const [serverError, setServerError] = useState('');
	const [loading, setLoading] = useState(false);

	const { stripeOnboardingState, mutateStripeOnboardingState } = useStripeOnboarding();
	const updateCareProStripeInformation = useUpdateCareProStripeInformationMutation();

	const addressFormHandler = async (values) => {
		try {
			setLoading(true);
			await updateCareProStripeInformation.mutateAsync({
				address_line1: values.addressLine1,
				address_city: values.addressLine2,
				address_state: values.state,
				address_postal_code: values.zip,
			});

			mutateStripeOnboardingState('address', {
				...stripeOnboardingState.address,
				data: {
					addressLine1: values.addressLine1,
					addressLine2: values.addressLine2,
					city: values.city,
					state: values.state,
					zip: values.zip,
				},
				isCompleted: true,
			});
			setLoading(false);
		} catch (error) {
			setLoading(false);
			console.error('Stripe address form failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledAddressPage>
			<PageHeading>Address</PageHeading>

			<AddressForm
				initialValues={{
					addressLine1: stripeOnboardingState.address.data.addressLine1,
					addressLine2: stripeOnboardingState.address.data.addressLine2,
					city: stripeOnboardingState.address.data.city,
					state: stripeOnboardingState.address.data.state,
					zip: stripeOnboardingState.address.data.zip,
				}}
				addressFormHandler={addressFormHandler}
				serverError={serverError}
				loading={loading}
			/>
		</StyledAddressPage>
	);
};

export default AddressPage;
