import React, { useState } from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';
import Colors from 'colors';

import {
	faHome,
	faUserCircle,
	faTimes,
	faCalendarAlt,
	faCalendarWeek,
	faFile,
	faMailBulk,
	faMoneyBill,
	faHandHoldingHeart,
	faBriefcase,
} from '@fortawesome/free-solid-svg-icons';

import { isMobile } from 'react-device-detect';
import { device } from 'AppComp/styled/StyledMediaQuery';

import { RowFlex, Avatar, ColumnFlex } from 'basicStyles';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';

import { PROVIDER_NAV_WIDTH } from 'constants';

import Steps from '../CheckoutOnboard/Steps';

const Section = styled.div`
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
	display: flex;
	width: ${PROVIDER_NAV_WIDTH};
	height: 100vh;
	flex-direction: column;
	align-items: center;
	padding: 10px 0 0 0;
	justify-content: flex-start;
	background-color: ${Colors.primary.primary};
`;

const FixedCon = styled.div`
	position: fixed;
	display: flex;
	height: fit-content;
	z-index: 100;
	display: flex;
	flex-direction: column;

	@media ${device.mobileS} {
		right: 6px;
		bottom: 60px;
	}

	@media ${device.laptop} {
		right: 24px;
		bottom: 120px;
	}
`;

export const NavTile = (props) => {
	const { title, icon, history, isMobile, push, route } = props;
	const active = false;

	const [isActive, setIsActive] = useState(active);

	return (
		<RowFlex
			onMouseEnter={() => setIsActive(true)}
			onMouseLeave={() => setIsActive(false)}
			padding='0px 0 0 16px'
			fullWidth
			alignCenter
			gap='8px'
			style={{
				height: '50px',
				borderBottom: isActive ? `2px solid ${Colors.fullWhite}` : 'none',
				cursor: 'pointer',
			}}
			onClick={() => push(route)}
		>
			<Icon size={isMobile ? '1x' : '1x'} color={'white'} icon={icon} />
			<Text subtitle color={'white'}>
				{title}
			</Text>
		</RowFlex>
	);
};
const LandingContentSecNav = (props) => {
	return (
		<Section
			style={{
				position: 'fixed',
				overflow: 'scroll',
				minHeight: '100vh',
			}}
		>
			{props.children}
		</Section>
	);
};

const PortalNavigationHandler = ({
	title,
	history,
	verification,

	provider,
	providerOnboarding,
	conversation,
}) => {
	const page = history.location.pathname;

	const [isMenuOpen, setIsMenuOpen] = React.useState(false);

	let isValid = false;

	if (verification) {
		isValid = verification.valid;
	}
	const [open, setOpen] = useState(false);

	const toggleMenu = () => {
		setOpen(!open);
	};

	const logoURl = 'https://find-your-independents.s3.amazonaws.com/images/FYIlogo.png';

	const goToStep = (step) => {};

	return (
		<>
			<FixedCon>
				{/* {!open && (
					<MenuTile
						icon={faBars}
						title='Menu'
						toggleMenu={() => setIsMenuOpen(true)}
						history={history}
					/>
				)}
				<MenuTile
					icon={faInfo}
					title='Info'
					toggleMenu={() => handleInfoClick()}
					history={history}
				/> */}

				{
					//conversation && <ProviderChatModel conversation={conversation} />
				}
			</FixedCon>
			<LandingContentSecNav
				style={{
					position: 'relative',
					overflow: 'scroll',
				}}
			>
				<ColumnFlex justifyStart alignCenter spaceBetween gap='40px' fullWidth>
					<ColumnFlex justifyStart alignCenter gap='4px'>
						<Avatar
							height={'100px'}
							width={'100px'}
							border='white'
							color={'white'}
							backgroundColor={'white'}
						>
							<div
								onClick={() => history.push('/carepro')}
								style={{
									backgroundImage: `url("${logoURl}")`,
									backgroundRepeat: 'no-repeat',
									height: '80px',
									width: '80px',
									backgroundSize: 'contain',
									backgroundPosition: 'left center',
									cursor: 'pointer',
								}}
							/>
						</Avatar>

						{!isMobile && (
							<Title
								style={{
									cursor: 'pointer',
								}}
								subtitle
								color={'white'}
								margin='16px 0 0 0'
								fontWeight='700'
								onClick={() => history.push('/carepro')}
							>
								Care Pro
							</Title>
						)}
					</ColumnFlex>

					{page === '/carepro-welcome' ||
					(provider && verification && !verification.valid) ? (
						<Steps goToStep={goToStep} step={0} />
					) : (
						<ColumnFlex gap='8px' fullWidth>
							<NavTile
								icon={faHome}
								title='Home'
								route='/carepro'
								history={history}
								push={history.push}
							/>
							<NavTile
								icon={faCalendarAlt}
								title='Calendar'
								route='/carepro/calendar'
								history={history}
								push={history.push}
							/>
							<NavTile
								icon={faCalendarWeek}
								title='Shift'
								route='/carepro/shift'
								history={history}
								push={history.push}
							/>
							{/* <NavTile
								icon={faTimes}
								title='Blocked Times'
								route='/carepro/blocked'
								history={history}
								push={history.push}
							/> */}
							<NavTile
								icon={faHandHoldingHeart}
								title='Care Abilities'
								route='/carepro/care-abilities'
								history={history}
								push={history.push}
							/>
							<NavTile
								icon={faBriefcase}
								title='Job Posts'
								route='/carepro/job-posts'
								history={history}
								push={history.push}
							/>
							<NavTile
								icon={faUserCircle}
								title='Profile'
								route='/carepro/profile'
								history={history}
								push={history.push}
							/>
							<NavTile
								icon={faMailBulk}
								title='Chat'
								route='/carepro/chat'
								history={history}
								push={history.push}
							/>

							<NavTile
								icon={faFile}
								title='Documents'
								route='/carepro/documents'
								history={history}
								push={history.push}
							/>
							<NavTile
								icon={faMoneyBill}
								title='Payments'
								route='/carepro/payments'
								history={history}
								push={history.push}
							/>
						</ColumnFlex>
					)}
				</ColumnFlex>
			</LandingContentSecNav>
		</>
	);
};

const mapStateToProps = ({ environment, provider }, props) => {
	return {
		provider: provider.provider,
		verification: provider.verification,
		conversation: provider.conversation,
		stepsProgress: provider.stepsProgress,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reducers in this format
	return {
		showInfoModel: (params) => dispatch(showInfoModel(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PortalNavigationHandler);
