import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

import Colors from 'colors';
import { ColumnFlex, DropShadow } from 'basicStyles';
import Icon from 'AppComp/Icon';

import { AlternateSection, ModelHeader } from 'AppComp/ModelOverlay';

export const Container = styled.div`
	border-radius: 50%;
	height: 90px;
	width: 90px;
	transition: all 0.2s ease-in-out 0.2s;
	position: fixed;
	background-color: ${Colors.fullWhite};
	border: solid 1px ${Colors.greenLightTeal};
	top: 25px;
	right: 200px;

	z-index: 999;
	${DropShadow(1)}
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}
`;

export const ExitContainer = styled.div`
	position: absolute;
	left: 8px;
	top: -32px;
	cursor: pointer;
	margin: 12px;
	z-index: 100;
`;

export const ModelContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 80vw;
	max-width: 500px;
	border-radius: 12px;
	background-color: white;
`;

export const InfoButton = (props) => {
	const { onClick } = props;
	return (
		<Container onClick={onClick}>
			<Icon size={'2x'} icon={faInfo} color={Colors.greenLightTeal} />
		</Container>
	);
};

export const OverlayContainer = styled.div`
	background: #ffffff;
	box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.16);
	border-radius: 3px;
	width: 90%;
	max-width: 300px;
	height: 100px;
	margin-bottom: 60px;
	position: relative;
`;

export const CustomAlternate = styled(AlternateSection)`
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
`;

export const Header = styled(ModelHeader)`
	border-radius: 12px;
	text-align: center;
`;
