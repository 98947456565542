import React, { Component, Fragment, useState, useEffect } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import styled from 'styled-components';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import 'react-circular-progressbar/dist/styles.css';

import Icon from 'AppComp/Icon';
import Colors from 'colors';

export const Container = styled.div`
	position: fixed;
	height: 60px;
	width: 60px;
	bottom: 20px;
	right: 10px;
	z-index: 9999;
`;

const ScrollHandler = (props) => {
	const { idName } = props;

	const [scrollProgress, setScrollProgress] = useState(0);

	const scrollListener = () => {
		const winScroll = window.pageYOffset;
		const height = document.documentElement.scrollHeight - 874;

		const scrolled = (winScroll / height) * 100;

		setScrollProgress(scrolled);
	};

	useEffect(() => {
		window.addEventListener('scroll', scrollListener);
		return () => window.removeEventListener('scroll', scrollListener);
	});

	return (
		<Container>
			<CircularProgressbarWithChildren
				value={scrollProgress}
				styles={{
					path: {
						stroke: '#2BAFA4',
					},
				}}
			>
				<Icon icon={faArrowDown} color={Colors.greenLightTeal} />
			</CircularProgressbarWithChildren>
		</Container>
	);
};

export default ScrollHandler;
