import React, { Component, Fragment } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import Colors from 'colors';
import { ColumnFlex, RowFlex } from 'basicStyles';
import { AnimatePresence, motion } from 'framer-motion';

import {
	ModelContainer,
	AlternateSection,
	ModelHeader,
	BasicModelButton,
} from '../../ModelOverlay';
import Text from '../../Text';
import { OBButtonText } from '../POnboard/OnboardViews';
import { toast } from 'react-toastify';
import { BackDropBg } from '../PortalNavigation/MobilePortalNavigationHandler';

export default class BlockedController extends Component {
	state = {
		showBlockedTimeModel: false,
		calView: {
			eventList: {
				type: 'day',
				size: 1,
				scrollable: true,
			},
			calendar: {
				type: 'week',
				labels: true,
				size: 1,
			},
		},
	};

	updateBlockedOverlay = () => {
		console.log('did this run');
		const { showBlockedTimeModel } = this.state;

		this.setState({
			showBlockedTimeModel: !showBlockedTimeModel,
		});
	};

	handleInfo = () => {
		const { showInfoModel } = this.props;
		showInfoModel({
			show: true,
			text: 'Add Blocked Times to your schedule, Click on a blocked time to remove it',
			title: 'Control when you are available ',
		});
	};
	renderEvents = () => {
		const { blockedTimes } = this.props;

		const blockedTimesEvents = blockedTimes.map((d, i) => {
			const { day_of_week, start_time, end_time, id } = d;

			const date = moment().startOf('week').add(day_of_week, 'days').format('YYYY-MM-DD');
			//console.log('date', date)
			const startTime = moment(start_time, 'HHmm').format('HH:mm:ss');
			const endTime = moment(end_time, 'HHmm').format('HH:mm:ss');

			return {
				id: id,
				title: 'Blocked',
				start: new Date(`${date} ${startTime}`),
				end: new Date(`${date} ${endTime}`),
				props: { ...d },
				allDay: false,
				color: Colors.red,
			};
		});

		return blockedTimesEvents;
	};

	handleEventClick = (event) => {
		console.log('event', event);

		const {
			deleteBlockedTime,
			provider: { id },
		} = this.props;

		toast(
			({ closeToast }) => (
				<ConfirmDeleteBlockedTime
					closeToast={closeToast}
					deleteBlockedTime={deleteBlockedTime}
					providerId={id}
					blockedTime={event}
				/>
			),

			{
				autoClose: false,
				hideProgressBar: true,
				style: { padding: '0', width: '350px', overflowY: 'scroll', overflow: 'scroll' },
				draggable: false,
				closeOnClick: false,
				closeButton: true,
				position: 'top-center',
				containerId: 'LargeToast',
			}
		);
	};
}

const ConfirmDeleteBlockedTime = ({ deleteBlockedTime, providerId, blockedTime, closeToast }) => {
	const handleDelete = () => {
		deleteBlockedTime({
			blockedTimeId: blockedTime.props.id,
			providerId: providerId,
		});
	};

	console.log('evnt', blockedTime);

	const startTime = moment(blockedTime.start).format('h:mm a');
	const endTime = moment(blockedTime.end).format('h:mm a');
	const day = moment(blockedTime.start).format('dddd');

	return (
		<AlternateSection
			style={{
				backgroundColor: 'white',
			}}
			margin='12px 0 0 0'
			padding='20px'
		>
			<ColumnFlex
				style={{
					minWidth: '100%',
					alignItems: 'center',
					justifyContent: 'center',
				}}
				gap='20px'
			>
				<Text bold largeText color={Colors.secondary.blue}>
					Delete Blocked Time ?
				</Text>
				<Text bold>{`${day}: ${startTime} - ${endTime}`}</Text>

				<OBButtonText text={'Delete'} onClick={() => handleDelete()} valid={true} />
			</ColumnFlex>
		</AlternateSection>
	);
};

const MainPanel = styled(motion.div)`
	position: fixed;
	display: flex;
	height: fit-content;
	width: fit-content;
	z-index: 100;
	left: 0;
	display: flex;
	flex-direction: column;

	top: 0;
	border: 12px;

	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

export const ConfirmDeleteGenericSession = ({
	deleteGenericSession,
	genericTime,
	setShowDeleteGenericSessionModel,
	closeToast,
}) => {
	const handleDelete = () => {
		deleteGenericSession({
			id: genericTime.props.id,
			closeToast,
		});
	};

	console.log('evnt', genericTime);

	const startTime = moment(genericTime.start).format('h:mm a');
	const endTime = moment(genericTime.end).format('h:mm a');
	const day = moment(genericTime.start).format('dddd');

	return (
		<>
			<BackDropBg
				onClick={() => setShowDeleteGenericSessionModel(false)}
				{...framerSidebarBackground}
				aria-hidden='true'
			></BackDropBg>
			<MainPanel
				aria-label='Sidebar'
				{...framerPanel}
				style={{
					padding: '16px 0 0 0',
					gap: '0px',
				}}
			>
				<AlternateSection
					style={{
						borderRadius: '12px',
						backgroundColor: 'white',
					}}
					margin='12px 0 0 0'
					padding='20px'
				>
					<ColumnFlex
						style={{
							minWidth: '100%',
							alignItems: 'center',
							justifyContent: 'center',
						}}
						gap='20px'
					>
						<Text bold largeText color={Colors.secondary.blue}>
							Delete Generic Session ?
						</Text>
						<Text bold>{`${day}: ${startTime} - ${endTime}`}</Text>

						<OBButtonText text={'Delete'} onClick={() => handleDelete()} valid={true} />
						<Text
							margin='20px 0 0 0'
							style={{ cursor: 'pointer' }}
							onClick={() => setShowDeleteGenericSessionModel(false)}
							underline
						>
							Close
						</Text>
					</ColumnFlex>
				</AlternateSection>
			</MainPanel>
		</>
	);
};

const framerSidebarBackground = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};

// const framerPanel = {
// 	initial: { y: '-100%' },
// 	animate: { y: 0 },
// 	exit: { y: '-100%' },
// 	transition: { duration: 0.3 },
// };

const framerPanel = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};
