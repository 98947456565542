import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';

import DynamicFilter, { filterParser } from '../../core/DynamicFilter';

import { StyledPage } from './styles';

import filters from 'constants/needs.json';
import parser from './lib/parser';
import reverseParser from './lib/reverseParser';

import { updateSetTraits } from 'reducers/patient';

const CareNeedsPage = (props) => {
	const { carePlan, updateSetTraits } = props;

	const [traits, setTraits] = useState([]);
	const [searchFilters, setSearchFilters] = useState(filters);

	const debouncedUpdateSetTraits = useCallback(
		(() => {
			let timer;
			return (traits, carePlan) => {
				clearTimeout(timer);
				timer = setTimeout(() => {
					if (traits.length && carePlan) {
						updateSetTraits({ set_id: carePlan.careNeedsSet.id, traits });
					}
				}, 1000);
			};
		})(),
		[updateSetTraits]
	);

	useEffect(() => {
		debouncedUpdateSetTraits(traits, carePlan);
	}, [traits, carePlan, debouncedUpdateSetTraits]);

	useEffect(() => {
		if (carePlan) {
			setSearchFilters(reverseParser(carePlan.careNeedsSet.setData, searchFilters));
		}
	}, [carePlan]);

	const searchFilterHandler = (name, option, selectValue, customInput) => {
		const { newFilters, parsedData } = filterParser(
			parser,
			searchFilters,
			name,
			option,
			selectValue,
			customInput
		);

		setTraits(parsedData);
		setSearchFilters(newFilters);
	};

	return (
		<StyledPage
			style={{
				backgroundColor: '#EDF6FB',
				width: '100vw',
			}}
		>
			<DynamicFilter filters={searchFilters} filterHandler={searchFilterHandler} />
		</StyledPage>
	);
};

const mapStateToProps = ({ patient }) => {
	return {
		patient: patient.patient,
		carePlan: patient.carePlan,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updateSetTraits: (params) => dispatch(updateSetTraits(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CareNeedsPage);
