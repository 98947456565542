import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CLIENT_MODEl } from '../../../core/api/models';

const loginUser = async ({ email, password }) => {
	try {
		const data = await api.graph({
			query: `mutation{
				loginUser(
					email: "${email}",
					password: "${password}"
				) {
					token
					user {
						${CLIENT_MODEl}
					}
					
				},
			}`,
		});

		return data.loginUser;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useLoginUserMutation = () => {
	return useMutation({
		mutationKey: ['loginUser'],
		mutationFn: (values) => {
			return loginUser(values);
		},
	});
};
