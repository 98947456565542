//EditScheduleView.jsx
import React, { Fragment, Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { RowFlex, ColumnFlex } from 'basicStyles';

import Text from './Text';

export const CarePlanInfoSection = styled(ColumnFlex)`
	padding: 32px 44px;
`;

export const CustomModelTitle = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ color }) => color || '#457699'};
	height: 54px;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	padding: 0 8px;
`;

export const Header = (props) => {
	const { text, color } = props;

	return (
		<CustomModelTitle color={color}>
			<div />
			<Text color={color ? 'white' : 'black'} largeText>
				{text}
			</Text>
			<div />
		</CustomModelTitle>
	);
};

export const CustomModelButton = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ color }) => color || '#457699'};
	height: 54px;
	border-bottom-left-radius: 12px;
	border-bottom-right-radius: 12px;
	transition: opacity 0.3s ease;
	cursor: pointer;
	${({ valid }) =>
		!valid &&
		`
		opacity: .5;
	`}

	${({ valid }) =>
		valid &&
		`
		opacity: 1;
	`}

	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		opacity: 0.9;
	}
`;
