import React from 'react';
import styled, { css } from 'styled-components';
import is from 'styled-is';
import changeCase from 'change-case';

import { RowFlex, Avatar, DropShadow, ColumnFlex } from 'AppComp/styled/Basic';

import Text from 'AppComp/Text';
import Title from 'AppComp/Title';
import Colors from 'colors';

import { formateMomentDate } from 'scripts';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 95%;

	${is('spaceBetween')`
    justify-content: space-between;
  `}
`;

const CustomRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-self: flex-start;
	flex-direction: ${({ flexDirection }) => flexDirection};
	justify-content: ${({ justify }) => justify};
	align-items: flex-start;
	margin-bottom: 16px;
`;

const CustomAvatar = styled(Avatar)`
	height: 80px;
	width: 80px;
	margin-right: none;
`;

const ChatBubble = styled.div`
	margin-left: 12px;
	margin-right: 12px;
	margin-bottom: 12px;
	border-radius: 12px;
	padding: 20px;
	${DropShadow(2)}
	max-width: 40vw;
	overflow: hidden;
	@media (max-width: 768px) {
		max-width: 80vw;
	}

	@media (max-width: 480px) {
		max-width: 90vw;
	}
`;

const ChatBubbleContent = styled.div`
	overflow: auto;
	word-wrap: break-word;
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	margin: ${(props) => props.margin || '0'};
`;

const MessageTile = ({ convo, style }) => {
	let loggedInUserKey = convo.loggedInUser;

	if (convo.loggedInUser === 'patient') {
		loggedInUserKey = 'user';
	}
	const sentByLoggedInUser = convo.sent_by === loggedInUserKey;

	return (
		<ChatMessageTile
			type={sentByLoggedInUser}
			sentByLoggedInUser={sentByLoggedInUser}
			loggedInUserKey={loggedInUserKey}
			justify={!sentByLoggedInUser ? 'flex-start' : 'flex-end'}
			flexDirection={!sentByLoggedInUser ? 'row' : 'row-reverse'}
			{...convo}
			key={'cmtmt-' + convo.id}
			style={style}
		/>
	);
};

const ChatMessageTile = (props) => {
	const {
		justify,
		flexDirection,
		image_url,
		timestamp,
		type,
		text,
		style,
		email_text,
		sent_by_admin,
		sentByLoggedInUser,
	} = props;

	const renderText = () => {
		if (type === 'email') {
			return email_text;
		} else {
			return text;
		}
	};

	return (
		<CustomRow alignSelf={justify} flexDirection={flexDirection} style={style}>
			{image_url && (
				<Column>
					<CustomAvatar image_url={image_url} />
				</Column>
			)}
			<ColumnFlex>
				<ChatBubble
					style={{
						backgroundColor: sentByLoggedInUser ? Colors.lightBlue : Colors.fullWhite,
					}}
					provider={type === 'provider'}
					user={type === 'user'}
				>
					<ChatBubbleContent>
						<Text mediumText>{renderText()}</Text>
					</ChatBubbleContent>
				</ChatBubble>
				<Text padding='0 10px' margin='0 0 8px 0' nurseInfo gray>
					{`${formateMomentDate(
						timestamp,
						'MM/DD hh:mm a'
					)} From ${changeCase.sentenceCase(type)}`}
				</Text>
			</ColumnFlex>
		</CustomRow>
	);
};

export default MessageTile;
