import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import qsueryString from 'query-string';

import Text from './Text';

import {
	CustomInput,
	Container,
	ResultsCon,
	ResultsOverflowCon,
	ResultRow,
} from './SearchDropDownViews';

class SearchDropDownHandler extends Component {
	consturctor(props) {
		this.inputRef = React.createRef();
	}

	state = {
		showModel: false,
		value: '',
	};

	componentDidMount() {
		const { defaultValue } = this.props;

		const searchParams = qsueryString.parse(location.search, { arrayFormat: 'comma' });

		if (searchParams.search) {
			this.setState({
				value: searchParams.search,
			});
		}
	}

	//This handles firing a submit action whenever a user is focused on the input and hits the enter key
	handleKeyDown = (e) => {
		const { showModel } = this.props;

		if (e.key === 'Enter') {
			//should only call this when the user hits enter on the keyboard and if the user is focused on the text input
			const { handleSearchSubmit } = this.props;

			handleSearchSubmit(e.target.value);
		}
	};

	//Since the on change callback returns an event we need to parse the response to pass to the parent func a string rather than an javascipt event instance
	onChangeHandler = (e) => {
		const { handleChange } = this.props;

		this.setState({
			value: e.target.value,
		});

		handleChange(e.target.value);
	};

	//handles rendering the results from the search
	renderRows = () => {
		const { options, handleSearchSubmit } = this.props;

		//whenever the row is clicked we must hide the view and search for that product
		return options.map((d, i) => {
			return (
				<ResultRow key={`rr-sd-${d.value}`} onClick={() => this.handleRowClick(d)}>
					<Text subtext color='black'>
						{d.value}
					</Text>
				</ResultRow>
			);
		});
	};

	onFocusCallback = (e) => {
		this.setState({
			showModel: true,
		});
	};

	onBlurCallBack = (e) => {
		// this.setState({
		// 	showModel: false
		// })
	};

	handleRowClick = (selection) => {
		const { handleSearchSubmit } = this.props;

		handleSearchSubmit(selection);
		this.setState({
			value: selection.value,
			showModel: false,
		});
	};

	onMouseLeaveCallBack = (e) => {
		this.setState({
			showModel: false,
		});
	};

	render() {
		const { options, placeholder, showSuggestions } = this.props;

		const { showModel, value } = this.state;

		return (
			<Container onMouseLeave={this.onMouseLeaveCallBack}>
				<CustomInput
					onKeyDown={this.handleKeyDown}
					placeholder={placeholder}
					onChange={this.onChangeHandler}
					onBlur={this.onBlurCallBack}
					onFocus={this.onFocusCallback}
					value={value}
					ref={this.inputRef}
				/>
				{showSuggestions && (
					<AnimateHeight
						duration={500}
						height={showModel && options.length > 0 ? 'auto' : '0%'}
					>
						<ResultsCon>
							<ResultsOverflowCon>{this.renderRows()}</ResultsOverflowCon>
						</ResultsCon>
					</AnimateHeight>
				)}
			</Container>
		);
	}
}

const optionsShape = PropTypes.shape({
	/** Value to be refferenced when used*/
	value: PropTypes.string.isRequired,
	/** Label to be displayed*/
	label: PropTypes.string.isRequired,
});

SearchDropDownHandler.propTypes = {
	/** [{}] of values and labels to be shown for the dropdown*/
	options: PropTypes.arrayOf(optionsShape).isRequired,
	/** func to run when a change is detected in the text input*/
	handleChange: PropTypes.func.isRequired,
	//* Func to run when search bar is submited*/
	handleSearchSubmit: PropTypes.func.isRequired,
	//* Text to display as placeholder for text input*/
	placeholder: PropTypes.string.isRequired,
	//* Bool to display suggestions or not */
	showSuggestions: PropTypes.bool.isRequired,
};

export default SearchDropDownHandler;
