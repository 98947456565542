import React from 'react';
import styled from 'styled-components';

import AppColors from 'colors';

/*
	SearchDropDownViews File Index
	- Container *
	- CustomInput *
	- ResultsCon *
	- ResultsOverflowCon *
	- ResultRow *
*/

export const Container = styled.div`
	position: relative;
	height: 100%;
	width: inherit;
`;

export const CustomInput = styled.input`
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsl(0, 0%, 100%);
	border-color: hsl(0, 0%, 80%);
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	cursor: default;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	min-height: 38px;
	outline: 0 !important;
	position: relative;
	-webkit-transition: all 100ms;
	transition: all 100ms;
	box-sizing: border-box;
	padding-left: 16px;
	width: inherit;
	height: 100%;
`;

export const ResultsCon = styled.div`
	top: 35px;
	right: 0;
	left: 0;
	position: absolute;
	z-index: 1000;
	overflow: hidden;
	overflow-y: scroll;
	display: flex;
	flex-direction: column;
	max-height: 200px;
	min-height: 40px;
	width: inherit;
	background-color: hsl(0, 0%, 100%);
	border-color: hsl(0, 0%, 80%);
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
`;

export const ResultsOverflowCon = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

export const ResultRow = styled.div`
	width: inherit;
	height: 40px;
	border-bottom: solid 0.3px gray;
	padding-left: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	transition: all ease 0.3s;

	&:hover {
		background-color: ${AppColors.borderGray};
	}
`;
