import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';

import { RowFlex, DropShadow } from 'basicStyles';
import Text from 'AppComp/Text';

/*
	* CarePlanInfoViews Index
	* - DropShadowRow
	* - ActivityRadioInupt
	* - CustomRadioInput *

*/

export const DropShadowRow = styled(RowFlex)`
	height: 80px;
	${DropShadow(1)}
`;

export const CustomRadioInput = styled.input`
	margin-right: 8px;
`;

export const ActivityRadioInupt = (props) => {
	const { form, field, type, custom, label, disabled } = props;

	return (
		<RowFlex alignCenter margin='8px'>
			<CustomRadioInput
				disabled={disabled}
				{...field}
				type={'checkbox'}
				checked={form.values[field.name]}
			/>
			<Text mediumText color='black'>
				{label}
			</Text>
		</RowFlex>
	);
};

export const CustomLengthCon = styled.div`
	width: 280px;
	color: black;
	border-radius: 12px;

	@media screen and (min-width: 800px) {
		width: 320px;
	}
`;

export const DropDownInput = (props) => {
	const { options, field, placeholder, form, isDisabled } = props;

	return (
		<Select
			options={options}
			name={field.name}
			value={options ? options.find((option) => option.value === field.value) : ''}
			onChange={(options) => form.setFieldValue(field.name, options.value)}
			onBlur={field.onBlur}
			placeholder={placeholder}
			isDisabled={isDisabled}
		/>
	);
};
