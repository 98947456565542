import React, { createContext, useReducer, useContext } from 'react';

const FormTemplateContext = createContext();

const initialState = {
	formTemplate: null,
	sections: [],
};

export const ACTIONS = {
	SET_FORM_TEMPLATE: 'SET_FORM_TEMPLATE',
	SET_SECTIONS: 'SET_SECTIONS',
};
function formTemplateReducer(state, action) {
	switch (action.type) {
		case ACTIONS.SET_FORM_TEMPLATE:
			return {
				...state,
				formTemplate: action.payload,
			};
		case 'SET_SECTIONS':
			return {
				...state,
				sections: action.payload,
			};
		default:
			return state;
	}
}

export const FormTemplateProvider = ({ children }) => {
	const [state, dispatch] = useReducer(formTemplateReducer, initialState);
	return (
		<FormTemplateContext.Provider value={{ state, dispatch }}>
			{children}
		</FormTemplateContext.Provider>
	);
};

export const useFormTemplateContext = () => useContext(FormTemplateContext);
