import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';

import { ONBOARD_PATIENT_THEME } from 'constants';
import { loginUser, toggleRegisterOnboard } from 'reducers/patient';

import { toggleForgotPasswordOverlay } from 'reducers/environment';

import { ColumnFlex, RowFlex } from 'basicStyles';

import OnboardFormHandler from '../OnboardFormHandler';
import Text from '../Text';

import { validatePatientLogin, validatePatientRegister } from '../../constants/validation';

class OnboardPatientHanlder extends Component {
	state = {
		activeTheme: 'login',
		registerValues: null,
	};

	componentDidMount() {
		const { registerOnboard } = this.props;

		if (registerOnboard) {
			this.setState({
				activeTheme: 'register',
			});
		}
	}

	componentWillUnmount() {
		const { toggleRegisterOnboard } = this.props;

		toggleRegisterOnboard(false);
	}

	componentDidUpdate(prevProps, prevState) {
		const { showPhoneValidationInput } = this.props;
		if (showPhoneValidationInput !== prevProps.showPhoneValidationInput) {
			if (showPhoneValidationInput) {
				this.setState({
					activeTheme: 'verifyPhone',
				});
			}
		}
	}
	//form func to run when all fields are valid, needs to handle register and login of patient
	handleSubmit = (values, form) => {
		console.log('handleSumbit -> values', values);
		const { activeTheme } = this.state;

		const { loginUser, registerUser, carePlan, verifyUserPhone } = this.props;

		loginUser(values);
	};

	//handles rendering the inital values for the form to validate
	renderInitalValues = () => {
		const initValues = {
			email: '',
			name: '',
			phoneNumber: '',
			password: '',
			confirm_password: '',
		};

		return initValues;
	};

	//handles changing the theme from login to register for patients
	handleThemeChange = (theme) => {
		this.setState({ activeTheme: theme });
	};

	handleForgotPassword = () => {
		const { toggleForgotPasswordOverlay, handleExitClick } = this.props;

		toggleForgotPasswordOverlay(true);
		handleExitClick();
	};

	handleClickOnboard = () => {
		const { pushRoute, handleExitClick } = this.props;

		pushRoute('/welcome');
		handleExitClick();
	};
	render() {
		const { error } = this.props;

		const { activeTheme } = this.state;

		return (
			<OnboardFormHandler
				error={error}
				validate={activeTheme === 'login' ? validatePatientLogin : validatePatientRegister}
				handleSubmit={this.handleSubmit}
				renderInitalValues={this.renderInitalValues}
				theme={ONBOARD_PATIENT_THEME[activeTheme]}
			>
				<ColumnFlex fullHeight center>
					<Text
						fontStyle='bold'
						largeText
						pointer
						underline
						color='white'
						onClick={() => this.handleClickOnboard()}
					>
						{ONBOARD_PATIENT_THEME[activeTheme].alternateActionText}
					</Text>
					<Text
						mediumText
						pointer
						color='white'
						underline
						margin='24px 0 0 0'
						onClick={() => this.handleForgotPassword()}
					>
						Forgot Password?
					</Text>
				</ColumnFlex>
			</OnboardFormHandler>
		);
	}
}

const mapStateToProps = ({ environment, patient }) => {
	return {
		error: environment.error,
		registerOnboard: patient.registerOnboard,
		carePlan: patient.carePlan,
		showPhoneValidationInput: environment.showPhoneValidationInput,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		loginUser: (params) => dispatch(loginUser(params)),
		toggleRegisterOnboard: (status) => dispatch(toggleRegisterOnboard(status)),
		toggleForgotPasswordOverlay: (status) => dispatch(toggleForgotPasswordOverlay(status)),
		pushRoute: (route) => dispatch(push(route)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardPatientHanlder);
