import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Color from 'colors';
import { ColumnFlex } from '../../styled/Basic';

import { handleProviderLogin, receiveProviderInfo, receiveStepsProgress } from 'reducers/provider';

import POB1Learn from './POB1Learn';
import POB2CareType from './POB2CareType';
import POB3ProviderPassword from './POB3ProviderPassword';
import POB3ProviderInfo from './POB3ProviderInfo';
import OBPhoneConfirmation from './OBPhoneConfirmation';
import PortalNavigationHandler from '../PortalNavigation/PortalNavigationHandler';

import useWindowSize from 'scripts/useWindowSize';
import { PROVIDER_NAV_WIDTH } from 'constants';

const registerProviderParams = {
	email: null,
	password: null,
	firstName: null,
	lastName: null,
	displayName: null,
	phoneNumber: null,
	authCode: null,
	gender: null,
	rate: null,
	care_type: null,
	registry_id: 1, // WE CHANGE THIS TO WHENEVER WE ADD THE FUNCTIONALITY FOR REGISTRIES
};
const OnboardHandler = (props) => {
	const [state, _state] = useState(registerProviderParams);
	const [location, _location] = useState(0);

	const { width } = useWindowSize();

	useEffect(() => {
		const step = localStorage.getItem('PROVIDER_ONBOARD_STEP');
		const stateString = localStorage.getItem('PROVIDER_ONBOARD_STATE');

		props.receiveStepsProgress(0);
		// GET PROVIDER_ONBOARD_STATE FROM LOCAL STORAGE

		if (step) {
			const stepNum = parseInt(step, 10);
			props.receiveStepsProgress(stepNum / 5);
			_location(stepNum);
		}
		if (stateString) {
			const state = JSON.parse(stateString);
			_UPS(state);
		}
	}, []);

	const saveLocalState = (state) => {
		const stateString = JSON.stringify(state);
		localStorage.setItem('PROVIDER_ONBOARD_STATE', stateString);
	};

	const _UPS = (newState) => {
		_state(newState);
		saveLocalState(newState);
	};

	const ChangeLocation = (step) => {
		localStorage.setItem('PROVIDER_ONBOARD_STEP', step);
		props.receiveStepsProgress(step / 5);
		_location(step);
	};

	const handleView = () => {
		const params = {
			state: state,
			_UPS: _UPS,
			provider: props.provider,
			location: location,
			ChangeLocation: ChangeLocation,
			history: props.history,
			receiveStepsProgress,
		};
		console.log('location', location);
		const Showing = [
			POB1Learn,
			POB2CareType,
			POB3ProviderInfo,
			POB3ProviderPassword,
			OBPhoneConfirmation,
		];
		const Comp = Showing[location];

		return <Comp {...params} />;
	};

	// check if the current step is even

	const evenStep = location % 2 == 0;

	const goToStep = (step) => {
		setStep(step);
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'row',

				overflow: 'hidden',
			}}
		>
			{width > 768 && (
				<>
					<div
						style={{
							width: PROVIDER_NAV_WIDTH,
							height: '100vh',
						}}
					/>
					<PortalNavigationHandler providerOnboarding history={props.history} />
				</>
			)}

			<div
				style={{
					//minHeight: '40vh',

					flexGrow: 1,
					flex: 1,
					width: `calc(100vw - ${PROVIDER_NAV_WIDTH})`,
					// paddingRight: `calc(${PROVIDER_NAV_WIDTH} / 2)`,
					borderRadius: '12px',
					transition: 'all 0.3s ease-in-out',
					backgroundColor: Color.primary_light_shade,
				}}
			>
				<div
					style={{
						overflowY: 'auto',
						paddingTop: '50px',
						paddingBottom: '40px',
					}}
				>
					<ColumnFlex alignCenter justifyStart fullWidth>
						{handleView()}
					</ColumnFlex>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({ provider }) => {
	return {
		provider: provider.provider,
	};
};
const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		handleProviderLogin: (proivder) => dispatch(handleProviderLogin(proivder)),
		receiveProviderInfo: (proivder) => dispatch(receiveProviderInfo(proivder)),
		receiveStepsProgress: (progress) => dispatch(receiveStepsProgress(progress)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardHandler);
