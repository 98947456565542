import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';

import { faChevronRight, faCheck } from '@fortawesome/free-solid-svg-icons';

import { ColumnFlex, RowFlex, BackDropBg } from 'basicStyles';
import Icon from 'AppComp/Icon';

import Text from 'AppComp/Text';
import { render } from 'less';
const OnboardSubNavTile = (props) => {
	const { id, title, completed, onClick, currentStep } = props;

	const handleOnClick = () => {
		onClick(id);
	};
	return (
		<ColumnFlex gap='12px' alignCenter>
			<RowFlex
				style={{
					cursor: 'pointer',
				}}
				alignCenter
				gap='8px'
				columnMobile
				onClick={handleOnClick}
			>
				<ColumnFlex
					style={{
						width: '30px',
						height: '30px',
						borderRadius: '50%',
						border: currentStep === id ? '2px solid green' : '2px solid #2089d5',
					}}
					center
				>
					{completed && (
						<Icon
							icon={faCheck}
							color={currentStep === id ? 'green' : '#2089d5'}
							size='xs'
						/>
					)}
				</ColumnFlex>
				<Text color={currentStep === id ? 'green' : '#2089d5'} mediumText>
					{title}
				</Text>
			</RowFlex>
		</ColumnFlex>
	);
};

/* 
  used for sub-navigation of certain onboard sections
  params {
    steps: [
      {
        title: string,
        completed: boolean,
        id: string
      }
    ]
    handleSubTileClick: function(id)
  }
*/
const OnboardSubNav = (props) => {
	const { descriptionText, steps, handleSubNavTileClick, currentStep, showArrowsBetween } = props;
	console.log('steps', steps);
	const renderSubTiles = () => {
		// a bit complicated because we need to add these right chevron between each tile with exception of the last tile

		return steps.map((step, index) => {
			console.log('step', step);
			const isLastTile = index === steps.length - 1;
			return (
				<>
					<OnboardSubNavTile
						title={step.title}
						completed={step.completed}
						onClick={handleSubNavTileClick}
						id={step.id}
						currentStep={currentStep}
					/>
					{!isLastTile && showArrowsBetween && (
						<div
							style={{
								width: '20px',
								height: '4px',
								borderRadius: '30%',
								backgroundColor: '#2089d5',
							}}
						/>
					)}
				</>
			);
		});
	};
	return (
		<RowFlex padding='16px 0' wrap center alignCenter gap='32px'>
			<Text> {descriptionText}</Text>
			{renderSubTiles()}
		</RowFlex>
	);
};

export default OnboardSubNav;
