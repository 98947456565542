import React from 'react';

import { StyledModal } from './styles';

const Modal = (props) => {
	const { children } = props;

	return <StyledModal>{children}</StyledModal>;
};

export default Modal;
