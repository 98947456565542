import React, { Component, useState, useMemo, useEffect } from 'react';

import Colors from 'colors';
import styled from 'styled-components';
import { Calendar, Views } from 'react-big-calendar';
import Select from 'react-select';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';

import { ColumnFlex, RowFlex } from 'basicStyles';

import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

export const ActionSection = styled.div`
	height: fit-content;
`;

const TileType = {
	TENTATIVE: 'TENTATIVE',
	SCHEDULED: 'SCHEDULED',
	RESCHEDULED: 'RESCHEDULED',
	PENDING_CLIENT_APPROVAL: 'PENDING_CLIENT_APPROVAL',
	PENDING_PROVIDER_APPROVAL: 'PENDING_PROVIDER_APPROVAL',
};

const TileTypeDisplayData = [
	{
		type: TileType.TENTATIVE,
		color: Colors.teal,
		label: 'Tentative',
	},
	{
		type: TileType.SCHEDULED,
		color: Colors.blue,
		label: 'Scheduled',
	},
	{
		type: TileType.RESCHEDULED,
		color: Colors.yellow,
		label: 'Rescheduled',
	},
	{
		type: TileType.PENDING_CLIENT_APPROVAL,
		color: Colors.red,
		label: 'Pending Client Approval',
	},
	{
		type: TileType.PENDING_PROVIDER_APPROVAL,
		color: Colors.emerald,
		label: 'Pending Provider Approval',
	},
];

const ClientScheduleToolbar = (props) => {
	const {
		currentDate,
		view,
		setView,
		displayLabel,
		selectedDateTileType,
		setSelectedDateTileType,
		setUserSelectedDate,
	} = props;
	const renderDropDownForView = () => {
		const options = [
			{
				label: 'Day',
				value: Views.DAY,
			},
			{
				label: 'Week',
				value: Views.WEEK,
			},
			{
				label: 'Month',
				value: Views.MONTH,
			},
			{
				label: 'Agenda',
				value: Views.AGENDA,
			},
		];

		return (
			<Select
				placeholder={view.label}
				options={options}
				className='doc-filter-select'
				defaultValue={view.value}
				onChange={(selectedOption) => {
					setView(selectedOption);
				}}
			/>
		);
	};

	const handleUserSelectDateChange = (direction) => {
		// direction = true => forward
		// direction = false => backward

		// based on the selected view is how much we'll alter the selected date
		// if view is day, we'll alter by 1 day
		// if view is week, we'll alter by 7 days
		// if view is month, we'll alter by 30 days

		let newDate = new Date(currentDate);
		if (view.value === Views.DAY) {
			newDate.setDate(newDate.getDate() + (direction ? 1 : -1));
		}
		if (view.value === Views.WEEK) {
			newDate.setDate(newDate.getDate() + (direction ? 7 : -7));
		}
		if (view.value === Views.MONTH) {
			newDate.setDate(newDate.getDate() + (direction ? 30 : -30));
		}
		setUserSelectedDate(newDate);
	};
	return (
		<ActionSection>
			<ColumnFlex
				style={{
					backgroundColor: 'white',
					borderRadius: '12px',
					padding: '16px',
				}}
			>
				<RowFlex fullWidth justifyBetween>
					<Title
						subtitle
						color={Colors.greenLightTeal}
						margin='0 0 0px 0'
						fontWeight='700'
					>
						{displayLabel}
					</Title>
					<RowFlex
						alignCenter
						gap='12px'
						style={{
							cursor: 'pointer',
						}}
					>
						<Icon
							onClick={() => handleUserSelectDateChange(false)}
							icon={faChevronLeft}
							color={Colors.greenLightTeal}
							size='md'
						/>
						<Icon
							onClick={() => handleUserSelectDateChange(true)}
							icon={faChevronRight}
							color={Colors.greenLightTeal}
							size='md'
						/>
					</RowFlex>
				</RowFlex>

				<ColumnFlex
					style={{
						borderRadius: '16px',
						//backgroundColor: Colors.lightGrey,
						padding: '10px 10px 10px 10px',
					}}
					fullWidth
					margin='10px 0 12px 0'
					alignCenter
				>
					<ColumnFlex fullWidth alignStart>
						{/* <Text
							alignStart
							fontStyle='bold'
							color='#969696'
							fontWeight='100'
							mediumText
						>
							View
						</Text> */}
						{renderDropDownForView()}
					</ColumnFlex>
				</ColumnFlex>

				<ColumnFlex
					style={{
						borderRadius: '16px',
						// backgroundColor: Colors.lightGrey,
						padding: '10px 10px 10px 10px',
					}}
					fullWidth
					margin='10px 0 12px 0'
					alignStart
					gap='12px'
				>
					{TileTypeDisplayData.map((tileType) => {
						const isSelected = selectedDateTileType.includes(tileType.type);

						return (
							<RowFlex
								style={{
									cursor: 'pointer',
								}}
								onClick={() =>
									setSelectedDateTileType((prev) => {
										if (prev.includes(tileType.type)) {
											return prev.filter((type) => type !== tileType.type);
										}
										return [...prev, tileType.type];
									})
								}
								alignCenter
								padding='0 0  0'
								margin='0 0 10px 0'
								fullWidth
								gap='12px'
							>
								<div
									style={{
										width: '30px',
										height: '15px',
										backgroundColor: isSelected ? tileType.color : 'white',
										borderRadius: '4px',
										border: `solid 2px ${tileType.color}`,
									}}
								/>
								<Text fontWeight='200'>{tileType.label}</Text>
							</RowFlex>
						);
					})}
				</ColumnFlex>
			</ColumnFlex>
		</ActionSection>
	);
};

export default ClientScheduleToolbar;
