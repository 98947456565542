import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledJobPostsCategory = styled.div`
	max-width: 100%;
	border: 1px solid ${colors.primary.primary};
	border-radius: 8px;
	padding: 20px;
	background: ${colors.primary.primary};
	cursor: pointer;

	display: flex;
	gap: 8px;

	transition: transform 0.1s ease-in-out;

	&:hover {
		transform: scale(1.02);
	}
`;

export const StyledCategoryIcon = styled.div`
	width: 30px;
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: ${colors.primary.primary};
	border-radius: 50%;

	color: ${colors.white};
`;

export const StyledInformationContainer = styled.div`
	margin-left: 0.25rem;
`;

export const StyledJobPostTitle = styled.h3`
	font-size: 1rem;
	font-weight: bold;
	color: ${colors.white};

	line-height: 1.4;

	margin-bottom: 0.25rem;
`;

export const StyledJobPostDescription = styled.p`
	font-size: 14px;
	color: ${colors.white};

	line-height: 1.4;
`;
