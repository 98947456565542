import React from 'react';
import styled from 'styled-components';
import is from 'styled-is';
import PropTypes from 'prop-types';

import colors from 'colors';

const FormButton = styled.button`
	border-radius: ${({ borderRadius }) => borderRadius || '4px'};
	color: ${({ color }) => color || 'white'};

	  
	border: ${({ border }) => border || 'none'};
	width: ${({ width }) => width || '150px'};
	height: ${({ height }) => height || '40px'};
	background-color: ${({ backgroundColor }) => backgroundColor || 'initial'};
	display: ${({ display }) => display || 'flex'};
	margin: ${(props) => props.margin || '0'};
	padding: ${(props) => props.padding || '0'};
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.10), 0 1px 2px 0 rgba(0,0,0,0.30);
	transition: 	transition: all ease 0.3s;
	text-align: center;
	align-items: center;
	justify-content: center;
	cursor: pointer;
 	ease .3s;

	
	${is('primary')`
	    background-color: ${colors.blue}
	`}
	${is('secondary')`
	    background-color: ${colors.purple}
	`}

	${({ valid, theme }) =>
		!valid &&
		`
		background-color: white;
		color: ${theme.color};
		border: 1px solid ${theme.color};
	`}

	${({ valid, theme }) =>
		valid &&
		`
		background-color:  ${theme.color};
		color: white;

	`}

	:hover{
		box-shadow: 0 6px 12px 0 rgba(0,0,0,0.20), 0 1px 4px 0 rgba(0,0,0,0.30);
	}
	:active{
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.20), 0 1px 3px 0 rgba(0,0,0,0.30);
	}

`;
FormButton.propTypes = {
	/** width of the element, if not passsed defaults to 150px*/
	width: PropTypes.string,
	/** height of the button, if not passsed defaults to 40px*/
	height: PropTypes.string,
	/** backgroundColor of the button, if not passed defaults to inital*/
	backgroundColor: PropTypes.string,
	/** sets the background color to primary color of app: NOT SET YET*/
	primary: PropTypes.bool,
	/** sets the background color to secondary color of app: NOT SET YET*/
	secondary: PropTypes.bool,
	/** valid: if the button should visually show a valid status for confirmation*/
	valid: PropTypes.bool,
};

export default FormButton;
