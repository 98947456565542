import React, { Component, useState, useMemo, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Select from 'react-select';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { ColumnFlex, RowFlex } from 'basicStyles';

import Colors from 'colors';
import styled from 'styled-components';
import { Views } from 'react-big-calendar';

import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import Title from 'AppComp/Title';
import Icon from 'AppComp/Icon';

import { ButtonTing, InputItemsContainer } from './CreateJobViews';
import Schedule from '../ClientSchedule/Schedule';

import CreateSchedule from './CreateSchedule';

export const ActionSection = styled.div`
	height: fit-content;
`;

const ManageJobScheduleToolbar = (props) => {
	const { view, setView, displayLabel, setShowAddScheduleOverlay } = props;
	const renderDropDownForView = () => {
		const options = [
			{
				label: 'Day',
				value: Views.DAY,
			},
			{
				label: 'Week',
				value: Views.WEEK,
			},
			{
				label: 'Month',
				value: Views.MONTH,
			},
			{
				label: 'Agenda',
				value: Views.AGENDA,
			},
		];

		return (
			<Select
				placeholder='Select View'
				options={options}
				className='doc-filter-select'
				defaultValue={view}
				onChange={(selectedOption) => {
					setView(selectedOption);
				}}
			/>
		);
	};

	return (
		<ActionSection>
			<></>
			<ColumnFlex
				style={{
					backgroundColor: 'white',
					borderRadius: '12px',
					padding: '16px',
				}}
			>
				<RowFlex fullWidth spaceBetween>
					<Title
						subtitle
						color={Colors.greenLightTeal}
						margin='0 0 0px 0'
						fontWeight='700'
					>
						{displayLabel}
					</Title>
					<RowFlex center gap='16px'>
						<Icon icon={faChevronLeft} size='2xl' color={Colors.secondary.blue} />
						<Icon icon={faChevronRight} size='2xl' color={Colors.secondary.blue} />
					</RowFlex>
				</RowFlex>

				<ColumnFlex
					style={{
						borderRadius: '16px',
						//backgroundColor: Colors.lightGrey,
						padding: '10px 10px 10px 10px',
					}}
					fullWidth
					margin='10px 0 12px 0'
					alignCenter
				>
					<ColumnFlex fullWidth alignStart>
						<Text
							alignStart
							fontStyle='bold'
							color='#969696'
							fontWeight='100'
							mediumText
						>
							View
						</Text>
						{renderDropDownForView()}
					</ColumnFlex>
					<Button
						style={{
							borderRadius: '32px',
						}}
						valid={true}
						type='submit'
						margin='10px 0 0 0 '
						backgroundColor={Colors.primary.primary}
						width={'200px'}
						height={'60px'}
						onClick={() => setShowAddScheduleOverlay(true)}
					>
						<Text
							style={{
								textAlign: 'center',
							}}
							bold
							color={'white'}
							midLarge
						>
							Add a Schedule
						</Text>
					</Button>
				</ColumnFlex>
			</ColumnFlex>
		</ActionSection>
	);
};

const MangeSchedule = (props) => {
	const currentDate = useMemo(() => new Date(), []);

	// overlay state
	const [showAddScheduleOverlay, setShowAddScheduleOverlay] = useState(true);

	const [view, setView] = useState({
		label: 'Week',
		value: Views.WEEK,
	});

	const [displayLabel, setDisplayLabel] = useState('Day');

	return (
		<RowFlex
			mobileColumn
			style={{
				backgroundColor: Colors.theme.primary_background,
			}}
			fullWidth
			padding='16px'
			gap='16px'
		>
			<AnimatePresence>
				{showAddScheduleOverlay && (
					<CreateSchedule setShowAddScheduleOverlay={setShowAddScheduleOverlay} />
				)}
			</AnimatePresence>

			<ColumnFlex gap='32px'>
				<ManageJobScheduleToolbar
					view={view}
					displayLabel={displayLabel}
					setView={setView}
					setShowAddScheduleOverlay={setShowAddScheduleOverlay}
				/>
				<ActionSection>
					<ColumnFlex
						style={{
							backgroundColor: 'white',
							borderRadius: '12px',
							padding: '16px',
						}}
					>
						<RowFlex fullWidth spaceBetween>
							<Title
								subtitle
								color={Colors.greenLightTeal}
								margin='0 0 0px 0'
								fontWeight='700'
							>
								Budget Calculator
							</Title>
						</RowFlex>
					</ColumnFlex>
				</ActionSection>
			</ColumnFlex>
			<Schedule view={view} setDisplayLabel={setDisplayLabel} currentDate={currentDate} />
		</RowFlex>
	);
};

export default MangeSchedule;
