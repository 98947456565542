import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { coreRoutes } from '../../routes/constants';
import { authRoutes } from '../../../auth/routes/constants';
import { documentsRoutes } from '../../../documents/routes/constants';

import { useAuth } from '../../hooks/useAuth';

import MenuButton from './MenuButton';
import DropDownMenu from './DropDownMenu';
import { TransparentButton } from '../../../../../corev2/Buttons';

import {
	StyledBanner,
	StyledSecondaryBanner,
	StyledTriBanner,
	StyledHeader,
	StyledWrapper,
	StyledLogo,
	StyledNavButtonsWrapper,
	StyledNavText,
} from './styles';

const NavBar = (props) => {
	const [isDropDownOpen, setIsDropDownOpen] = useState(false);

	const [logoURL, setLogoURL] = useState(
		'https://find-your-independents.s3.amazonaws.com/images/FYIlogo.png'
	);

	const history = useHistory();

	const {
		statusUpdate,
		isAuthenticated,
		isStripeVerified,
		hasCompletedPlatformOnboarding,
		hasCompletedStripeOnboarding,
		completedDocumentApproval,
		authCarePro,
		isRegistryApproved,
	} = useAuth();

	const dropDownHandler = () => {
		setIsDropDownOpen(!isDropDownOpen);
	};

	const goToHomePage = () => {
		history.push(coreRoutes.root);
	};

	const goToLoginPage = () => {
		history.push(authRoutes.login);
	};

	const goToOnboardingPage = () => {
		history.push(authRoutes.onboarding.home);
	};

	const goToJobPostsPage = () => {
		history.push(coreRoutes.jobPosts);
	};

	const goToSkillsPage = () => {
		history.push(coreRoutes.skills);
	};
	const goToChatPage = () => {
		history.push(coreRoutes.chat);
	};

	const goToDocumentsPage = () => {
		history.push(coreRoutes.documents);
	};

	const goToStripeOnboardingPage = () => {
		if (
			authCarePro &&
			authCarePro.stripeStatus &&
			authCarePro.stripeStatus.stripe_data.currently_due
		) {
			// ensure that currently_due includes 'individual.verification.document'
			if (
				authCarePro.stripeStatus.stripe_data.currently_due.includes(
					'individual.verification.document'
				)
			) {
				history.push(documentsRoutes.stripeDocuments);
			} else {
				history.push(coreRoutes.stripeOnboarding);
			}
		} else {
			history.push(coreRoutes.stripeOnboarding);
		}
	};

	const renderStripeStatusBannerText = () => {
		if (
			authCarePro &&
			authCarePro.stripeStatus &&
			authCarePro.stripeStatus.stripe_data.currently_due
		) {
			// ensure that currently_due includes 'individual.verification.document'
			if (
				authCarePro.stripeStatus.stripe_data.currently_due.includes(
					'individual.verification.document'
				)
			) {
				return 'Pending Stripe Approval - Please upload request Documents';
			}
		}
		return 'Pending Stripe Approval - Please complete your onboarding';
	};

	return (
		<>
			{isAuthenticated && (
				<>
					{!isRegistryApproved && <StyledBanner>{statusUpdate}</StyledBanner>}

					{!completedDocumentApproval && (
						<StyledSecondaryBanner onClick={() => goToDocumentsPage()}>
							Pending Document Approval From Registry
						</StyledSecondaryBanner>
					)}

					{!isStripeVerified && (
						<StyledTriBanner onClick={() => goToStripeOnboardingPage()}>
							{renderStripeStatusBannerText()}{' '}
						</StyledTriBanner>
					)}
				</>
			)}

			<StyledHeader>
				<StyledWrapper>
					<StyledLogo onClick={goToHomePage} src={logoURL} />

					<StyledNavButtonsWrapper>
						{isAuthenticated &&
							!hasCompletedPlatformOnboarding &&
							!hasCompletedStripeOnboarding && (
								<TransparentButton onClick={goToOnboardingPage}>
									<StyledNavText>Onboard</StyledNavText>
								</TransparentButton>
							)}

						{isAuthenticated &&
							hasCompletedPlatformOnboarding &&
							hasCompletedStripeOnboarding &&
							isStripeVerified && (
								<>
									<TransparentButton onClick={goToSkillsPage}>
										<StyledNavText>Job Skills</StyledNavText>
									</TransparentButton>
									<TransparentButton onClick={goToJobPostsPage}>
										<StyledNavText>Job posts</StyledNavText>
									</TransparentButton>

									<TransparentButton onClick={goToChatPage}>
										<StyledNavText>Chat</StyledNavText>
									</TransparentButton>
								</>
							)}

						{!isAuthenticated && (
							<>
								<TransparentButton size='small' onClick={goToLoginPage}>
									Login
								</TransparentButton>

								<TransparentButton size='small' onClick={goToOnboardingPage}>
									Signup
								</TransparentButton>
							</>
						)}

						{isAuthenticated && (
							<TransparentButton onClick={dropDownHandler}>
								<MenuButton />
							</TransparentButton>
						)}

						{isDropDownOpen && <DropDownMenu dropDownHandler={dropDownHandler} />}
					</StyledNavButtonsWrapper>
				</StyledWrapper>
			</StyledHeader>
		</>
	);
};

export default NavBar;
