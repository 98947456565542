import React from 'react';

import { StepItem } from '../../../../../corev2/Steps';

import { StyledCarePlanItem, StyledTitle, StyledDescription } from './styles';

const CarePlanItem = (props) => {
	const { title, description, onClickHandler } = props;

	return (
		<StyledCarePlanItem onClick={onClickHandler}>
			<StepItem isCompleted />

			<StyledTitle>{title}</StyledTitle>

			<StyledDescription>{description}</StyledDescription>
		</StyledCarePlanItem>
	);
};

export default CarePlanItem;
