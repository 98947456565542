import styled from 'styled-components';

import Colors from 'colors';

import { device } from 'AppComp/styled/StyledMediaQuery';

export const StyledAvatarContainer = styled.div`
	width: 120px;
	height: 120px;
	min-width: 80px;
	border-radius: 50%;
	background-color: white;
	border: 1px solid ${Colors.primary.new_primary};

	margin: auto;

	@media ${device.tablet} {
		margin: 0;
	}
`;
