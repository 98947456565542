import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { STEP_PROGRESS_KEYS } from '../CareProOnboardParent';

import OnboardSubNav from '../../../patient/ClientOnboard/OnboardSubnav';

import Welcome from './Welcome';

import PersonalInfo from './PersonalInfo';
import AccountInfo from './AccountInfo';
import PhoneVerify from './PhoneVerify';
import Profile from './Profile';
import useWindowSize from 'scripts/useWindowSize';

import api from 'scripts/api';

import { toastErrorConfig, toastSuccessConfig } from 'reducers/provider';

const CARE_PRO_ACCOUNT_ONBOARD_SUB_NAV = [
	{
		id: 1,
		title: 'Welcome',
		completed: false,
	},
	{
		id: 2,
		title: 'Personal Info',
		completed: false,
	},
	{
		id: 3,
		title: 'Account Info',
		completed: false,
	},
	{
		id: 4,
		title: 'Phone Confirmation',
		completed: false,
	},
	{
		id: 5,
		title: 'Profile',
		completed: false,
	},
];

const AccountOnboard = (props) => {
	const { width } = useWindowSize();

	const {
		receiveProviderInfo,
		provider,
		handleUpdateSectionProgress,
		renderCurrentStep,
		handleProviderLogin,
		setOnboardStep,
	} = props;
	const [accountSubNav, setAccountSubNav] = useState(CARE_PRO_ACCOUNT_ONBOARD_SUB_NAV);
	const [loading, setLoading] = useState(false);

	// state to keep track of carepro items for account creation
	const [careProAccountInfo, setCareProAccountInfo] = useState({
		email: null,
		password: null,
		first_name: null,
		last_name: null,
		display_name: null,
		phone_number: null,
		auth_code: null,
		gender: null,
		registry_id: 1,
	});

	// keep track of sub nav state location
	const [careProAccountOnboardStep, setCareProAccountOnboardStep] = useState(1);

	useEffect(() => {
		// calculate the completion of sub steps
		calculateSubStepCompletion();
	}, [careProAccountInfo]);

	useEffect(() => {
		// check if careProAccountInfo is in local
		const careProAccountInfoLocal = localStorage.getItem('careProAccountInfo');
		console.log('careProAccountInfoLocal', careProAccountInfoLocal);
		if (careProAccountInfoLocal) {
			setCareProAccountInfo(JSON.parse(careProAccountInfoLocal));
			calculateCurrentStep(JSON.parse(careProAccountInfoLocal));
		}
	}, []);
	// handle checking the amount of this sub step completion for sub nav

	const calculateCurrentStep = () => {
		const parsedLocal = careProAccountInfo;

		let step = 1;
		if (parsedLocal.first_name && parsedLocal.last_name) {
			step = 3;
		}

		if (parsedLocal.email && parsedLocal.password && parsedLocal.phone_number) {
			step = 4;
		}

		if (provider) {
			step = 5;
		}
		setCareProAccountOnboardStep(step);
		calculateSubStepCompletion();
	};
	const calculateSubStepCompletion = () => {
		const _accountSubNav = accountSubNav;

		// if fi	rst name last name gender and display name they've copmleted step 2
		console.log('careProAccountInfo', careProAccountInfo);
		if (careProAccountInfo.first_name && careProAccountInfo.last_name) {
			_accountSubNav[0].completed = true;
			_accountSubNav[1].completed = true;
		}

		if (
			careProAccountInfo.email &&
			careProAccountInfo.password &&
			careProAccountInfo.phone_number
		) {
			_accountSubNav[2].completed = true;
		}

		if (provider) {
			_accountSubNav[3].completed = true;
		}

		if (provider && provider.professional_statement) {
			_accountSubNav[4].completed = true;
		}

		console.log('_accountSubNav', _accountSubNav);
		setAccountSubNav(_accountSubNav);
	};

	const handleSubNavTileClick = (id) => {
		console.log('tile clicked id', id);
		// based on the account sub nav we can only move to views that have been completed or the first view after the last completion

		// flip the arr and get the first item that is completed that would be the most recent

		const _accountSubNav = accountSubNav;
		const reversedSubNav = _accountSubNav.slice().reverse();
		const mostRecentCompleted = reversedSubNav.find((item) => item.completed);
		console.log('mostRecentCompleted', mostRecentCompleted);

		if (mostRecentCompleted) {
			if (id <= mostRecentCompleted.id + 1) {
				setCareProAccountOnboardStep(id);
			} else {
				toast.error('Please complete the previous step', toastErrorConfig);
			}
		} else {
			toast.error('Please complete the previous step', toastErrorConfig);
		}
	};
	const renderView = () => {
		switch (careProAccountOnboardStep) {
			case 1:
				return <Welcome handleDataUpdate={handleDataUpdate} />;
			case 2:
				return (
					<PersonalInfo
						careProAccountInfo={careProAccountInfo}
						handleDataUpdate={handleDataUpdate}
					/>
				);
			case 3:
				return (
					<AccountInfo
						careProAccountInfo={careProAccountInfo}
						handleDataUpdate={handleDataUpdate}
					/>
				);
			case 4:
				return (
					<PhoneVerify
						careProAccountInfo={careProAccountInfo}
						handleDataUpdate={handleDataUpdate}
						handleProviderLogin={handleProviderLogin}
						handleSubNavTileClick={handleSubNavTileClick}
					/>
				);
			case 5:
				return (
					<Profile
						receiveProviderInfo={receiveProviderInfo}
						provider={provider}
						renderCurrentStep={renderCurrentStep}
						setOnboardStep={setOnboardStep}
					/>
				);

			default:
				return <>default</>;
		}
	};

	const handleDataUpdate = (careProAccountInfoData, step) => {
		//setCareProAccountOnboardStep(step);
		console.log('careProAccountInfoData', careProAccountInfoData);

		const wtf = { ...careProAccountInfo, ...careProAccountInfoData };

		localStorage.setItem('careProAccountInfo', JSON.stringify(wtf));
		console.log('wtf', wtf);
		setCareProAccountInfo(wtf);
		if (step) {
			setCareProAccountOnboardStep(step);

			switch (step) {
				case 2:
					const _accountSubNav = accountSubNav;
					_accountSubNav[0].completed = true;
					setAccountSubNav(_accountSubNav);
					handleUpdateSectionProgress(0.2, STEP_PROGRESS_KEYS.ACCOUNT);

					break;
				case 3:
					handleUpdateSectionProgress(0.4, STEP_PROGRESS_KEYS.ACCOUNT);

					break;
				case 4:
					handleUpdateSectionProgress(0.6, STEP_PROGRESS_KEYS.ACCOUNT);

					break;
				case 6:
					handleUpdateSectionProgress(0.8, STEP_PROGRESS_KEYS.ACCOUNT);

					break;
				default:
					break;
			}
		}
	};

	console.log('accountSubNav', accountSubNav);
	return (
		<>
			{width > 868 && (
				<OnboardSubNav
					currentStep={careProAccountOnboardStep}
					steps={accountSubNav}
					handleSubNavTileClick={handleSubNavTileClick}
					showArrowsBetween={true}
					descriptionText=''
				/>
			)}

			{renderView()}
		</>
	);
};

export default AccountOnboard;
