import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { SHIFT_MODEL } from '../../../core/api/models';

const submitShift = async ({ shiftId }) => {
	try {
		const data = await api.graph({
			query: `mutation {
					submitShift ( 
						shift_id: ${shiftId}
						) {
						${SHIFT_MODEL}
						}
				}`,
		});

		return data.submitShift;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const useSubmitShiftMutation = () => {
	return useMutation({
		mutationKey: ['submitShift'],
		mutationFn: (values) => {
			return submitShift(values);
		},
	});
};
