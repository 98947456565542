import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Colors from 'colors';
import { Hearts } from 'react-loader-spinner';

import {
	faUserCircle,
	faHeart,
	faCalendar,
	faAddressBook,
	faCheck,
} from '@fortawesome/free-solid-svg-icons';
import api from 'scripts/api';
import { toastErrorConfig, toastSuccessConfig } from 'reducers/provider';

import {
	PROVIDER_MODEL_V2,
	STRIPE_STATUS_MODEL,
	CARE_PRO_REGISTRY_STATUS_MODEL,
	VERIFICATION_MODEL,
	PAYOUT_METHOD_MODEL,
} from 'reducers/graphqlModelTypes';

import {
	updateProviderVerificationInfo,
	createProviderStripeAccount,
	receiveVerification,
	receiveStripeVerificationInfo,
	handleProviderLogin,
	receiveProviderInfo,
	checkProviderSession,
} from 'reducers/provider';

import ConfirmThings from './ConfirmThings';

import OnboardNav, { OnboardNavTile, NavDivider } from '../../patient/ClientOnboard/OnboardNav';

export const STEP_PROGRESS_KEYS = {
	ACCOUNT: 'ACCOUNT',
	BANKING: 'BANKING',
	// DOCUMENTS: 'DOCUMENTS',
};

import { ColumnFlex, RowFlex, BackDropBg } from 'basicStyles';

import AccountOnboard from './AccountOnboard/AccountOnboard';
import BankingOnboard from './BankingOnboard/BankingOnboard';

export const STEP_PROGRESS_INIT = {
	ACCOUNT: 0.1,
	BANKING: 0.1,
	// DOCUMENTS: 0.1,
};

const CareProOnboardParent = (props) => {
	const {
		checkProviderSession,
		provider,
		history,
		fetchingCareProSession,
		handleProviderLogin,
		receiveProviderInfo,
	} = props;

	const [onboardStep, setOnboardStep] = useState(1);

	const [stepsProgress, setStepsProgress] = useState(STEP_PROGRESS_INIT);

	const [loadingTing, setLoadingTing] = useState(true);

	const handleUpdateSectionProgress = (amount, key) => {
		const newProgress = { ...stepsProgress, [key]: amount };

		setStepsProgress(newProgress);
	};

	// hard check to ensure the carePro has completed the platform onboarding and can be shown the actual site
	useEffect(() => {
		// if (provider && provider.careProRegistryStatus) {
		// 	history.push('/carepro/home');
		// }
	}, [provider]);

	// initial state check
	useEffect(() => {
		renderCurrentStep();
	}, []);

	useEffect(() => {
		// check if local storage has user token
		const token = localStorage.getItem('user-token');

		if (token) {
			// check session
			checkProviderSession();
		}
	}, []);

	useEffect(() => {
		// check if the user has a session
		console.log('0x09 - provider', provider);
		console.log('fetchingCareProSession', fetchingCareProSession);
		const tokenTest = localStorage.getItem('user-token');

		console.log('tokenTest', tokenTest);
		if (tokenTest !== null) {
			// wait for fetchingCareProSession to be loading false and attempt 1
			if (fetchingCareProSession.attempts === 1) {
				renderCurrentStep();
			}
		}
	}, [fetchingCareProSession]);

	const renderCurrentStep = () => {
		console.log('0x07 - provider', provider);
		const tokenTest = localStorage.getItem('user-token');

		if (tokenTest == null) {
			setOnboardStep(1);
			setLoadingTing(false);
			return;
		}

		if (provider) {
			// check if they have completed adding professional_statement
			console.log('professional_statement', provider.professional_statement);
			if (
				provider.professional_statement === null ||
				provider.professional_statement === ''
			) {
				setOnboardStep(1);
				setLoadingTing(false);
			} else {
				if (provider.stripeStatus) {
					console.log('where did we end up at', provider.stripeStatus);
					console.log('0x0a', provider.stripeStatus.stripe_data.currently_due);

					// we only want to check if the currently due items are the items handled by the immediate onboard which are
					/* 
						- personal 
						- address
						- ssn_last_4 - dob
						- payout method
						- tos_acceptance
					*/
					if (provider.stripeStatus.stripe_data.currently_due) {
						const currentlyDue = provider.stripeStatus.stripe_data.currently_due;

						const address = currentlyDue.filter((item) => item.includes('address'));
						const externalAccount = currentlyDue.filter((item) =>
							item.includes('external_account')
						);
						const personalInfo = currentlyDue.filter((item) => item.includes('dob'));
						const ssn = currentlyDue.filter((item) => item.includes('ssn_last_4'));
						const tos = currentlyDue.filter((item) => item.includes('tos_acceptance'));

						// if any of these fields are true we need to send them back to banking onboard (step 2)
						if (
							address.length > 0 ||
							externalAccount.length > 0 ||
							personalInfo.length > 0 ||
							ssn.length > 0 ||
							tos.length > 0
						) {
							setOnboardStep(2);
							setLoadingTing(false);
							return;
						} else {
							// else we've collected all the data we need for stripe at the moment and will collect the rest later
							setOnboardStep(3);
							setLoadingTing(false);
							return;
						}
					} else {
						toast.error('Something went wrong, please try again', toastErrorConfig);
					}
				} else {
					toast.error('Something went wrong, please try again', toastErrorConfig);
				}
			}
		}
	};

	//setPlatformOnboard

	const setPlatformOnboard = async () => {
		try {
			// attempt to validate the platform onboarding
			const res = await api.graph({
				query: `mutation {
					setPlatformOnboard {
						${PROVIDER_MODEL_V2}
						stripeStatus {
							${STRIPE_STATUS_MODEL}
						}
						careProRegistryStatus {
							${CARE_PRO_REGISTRY_STATUS_MODEL}
						}
					}
				}`,
			});

			if (res.setPlatformOnboard) {
				const { setPlatformOnboard } = res;

				// take the carepro to their home page
				if (setPlatformOnboard.careProRegistryStatus.platform_onboard) {
					receiveProviderInfo(setPlatformOnboard);
					history.push('/carepro');
				}
			}
		} catch (error) {
			console.log('setPlatformOnboard -> err', error);
			toast.error('Something went wrong, please try again', toastErrorConfig);
		}
	};
	const renderView = () => {
		switch (onboardStep) {
			case 1:
				return (
					<AccountOnboard
						renderCurrentStep={renderCurrentStep}
						handleUpdateSectionProgress={handleUpdateSectionProgress}
						handleProviderLogin={handleProviderLogin}
						receiveProviderInfo={receiveProviderInfo}
						provider={provider}
						setOnboardStep={setOnboardStep}
					/>
				);
			case 2:
				return (
					<BankingOnboard
						renderCurrentStep={renderCurrentStep}
						handleUpdateSectionProgress={handleUpdateSectionProgress}
						receiveProviderInfo={receiveProviderInfo}
						provider={provider}
					/>
				);
			case 3:
				return <ConfirmThings setPlatformOnboard={setPlatformOnboard} />;
			default:
				return null;
		}
	};

	const handleNavTileClick = (id) => {
		console.log('did we make it here??', id);
		//setOnboardStep(id);
		// based on what has been completed we can move the user to the selected step

		const toastErr = () => toast.error('Please complete previous steps');
		switch (id) {
			case 1:
				// can always go back to 1
				setOnboardStep(1);

				break;
			case 2:
				break;
			case 3:
			// can only move here if we've completed step 2
			default:
				toastErr();
				break;
		}
	};

	return (
		<ColumnFlex
			fullWidth
			padding='16px'
			style={{
				backgroundColor: Colors.theme.primary_background,
				minHeight: 'calc(100vh - 120px)',
			}}
			alignCenter
			gap='32px'
		>
			{loadingTing ? (
				<Hearts />
			) : (
				<>
					<OnboardNav>
						<OnboardNavTile
							title='Account'
							icon={faUserCircle}
							isCurrentView={onboardStep == 1}
							percentCompleted={stepsProgress[STEP_PROGRESS_KEYS.ACCOUNT]}
							onboardStepsIndex={1}
							handleClick={handleNavTileClick}
							onboardStep={onboardStep}
						/>
						<NavDivider />

						<OnboardNavTile
							title='Banking'
							icon={faHeart}
							isCurrentView={onboardStep == 2}
							percentCompleted={stepsProgress[STEP_PROGRESS_KEYS.BANKING]}
							onboardStepsIndex={2}
							handleClick={handleNavTileClick}
							onboardStep={onboardStep}
						/>
					</OnboardNav>

					{renderView()}
				</>
			)}
		</ColumnFlex>
	);
};

const mapStateToProps = ({ environment, provider }, props) => {
	return {
		provider: provider.provider,
		fetchingCareProSession: provider.fetchingCareProSession,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		createProviderStripeAccount: () => dispatch(createProviderStripeAccount()),

		handleProviderLogin: (data) => dispatch(handleProviderLogin(data)),
		receiveProviderInfo: (data) => dispatch(receiveProviderInfo(data)),
		checkProviderSession: () => dispatch(checkProviderSession()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CareProOnboardParent);
