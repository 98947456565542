import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledFormContainer = styled.div`
	background: ${colors.white};
	width: 100%;
	max-width: 500px;
	padding: 20px;
	z-index: 999 !important;
	box-sizing: border-box;
	border-radius: 10px;
	box-shadow: 0px 4px 10px ${colors.black};
	width: 100%;
	max-width: 450px;
	font-size: 25px;
`;

export const StyledFormTitle = styled.h2`
	font-size: 18px;
	color: ${colors.primary.primary};
	text-align: center;
	margin-bottom: 20px;
	font-weight: 700;
	letter-spacing: 2px;
`;

export const StyledButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 1rem;
`;
