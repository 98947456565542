import {
	CAREPLAN_MODEL_V2,
	PROVIDER_MODEL_V2,
	PATIENT_MODEL_V2,
	PAYMENT_METHOD_TYPE_MODEL,
	PAYMENT_TYPE_MODEL,
	CORE_JOB_MODEL,
	SHIFT_MODEL,
	USER_MODEL_V2,
} from './graphqlModelTypes';

export const VERIFY_STRIPE_PAYMENT = `
  payment_methods {
    ${PAYMENT_METHOD_TYPE_MODEL}
  },
  payments {
    ${PAYMENT_TYPE_MODEL}
    provider {
      ${PROVIDER_MODEL_V2}
    }
  },
		
	carePlan {
		${CAREPLAN_MODEL_V2}
		jobs {
      ${CORE_JOB_MODEL}
    }       
    shifts {
      ${SHIFT_MODEL}
    }
		coordinator {
			${PATIENT_MODEL_V2}
		},				
	}

`;

export const USER_PROPOSED_SCHEDULE_CHANGE_TYPES = `
  carePlan {
    ${CAREPLAN_MODEL_V2}
    jobs {
      ${CORE_JOB_MODEL}
    }

    coordinator {
      ${PATIENT_MODEL_V2}
    },
 
  }
`;

export const UPDATE_CARE_PLAN_PAITENT_INFO_TYPES = `
  ${CAREPLAN_MODEL_V2}
    jobs {
      ${CORE_JOB_MODEL}
    }
    user {
      ${PATIENT_MODEL_V2}
    },
`;

export const REGISTER_USER_TYPES = `
  ${PATIENT_MODEL_V2}
  payment_methods {
    ${PAYMENT_METHOD_TYPE_MODEL}
  },
  payments {
    ${PAYMENT_TYPE_MODEL}
    provider {
      ${PROVIDER_MODEL_V2}
    }
  },
  conversations {
    id,
    user_type,
    unread_message,
    latest_message_timestamp,
    page,
    total_pages,
    messages {
      id,
      sent_by_admin,
      text,
      timestamp,
      device_sent_from,
    }
  },

`;

export const PT_INITAL_DATA_FETCH = `

  ${PATIENT_MODEL_V2}
            
  carePlan {
    id,
   
  }

  payment_methods {
    id,
    last_4,
    cvc_check,
    brand,
    type,
    exp_month,
    exp_year,
    default_card
  }
`;

export const VERIFY_STRIPE_PAYMENT_HELPER = `

  shifts {
    ${SHIFT_MODEL}
  }
            
 
 
  payments {
    ${PAYMENT_TYPE_MODEL}
  },
  verification {
    valid,
    error_code,
    error_reason,
    patient_personal_details_submitted,
    patient_location_details_submitted,
    payment_method_verification,
    
    error_code,
    error_reason,
    valid_email,
    valid_phone,
    missing_information
  },
  payment_methods {
    id,
    last_4,
    cvc_check,
    brand,
    type,
    exp_month,
    exp_year,
    default_card
  }
`;
