import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CARE_PRO_MODEL } from '../../../core/api/models';

const fetchCarePro = async ({ careProId }) => {
	try {
		const data = await api.graph({
			query: `{
					provider(id: ${careProId}) {
						${CARE_PRO_MODEL}
					}
				}`,
		});

		return data.provider;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchCareProQuery = (careProId) => {
	return useQuery({
		queryKey: ['fetchCarePro', careProId],
		queryFn: () => {
			return fetchCarePro({ careProId });
		},
	});
};
