import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledCareNeedsContainer = styled.div`
	width: 100%;
	padding: 30px;
	background-color: white;
	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;
	float: left;
	margin-right: 16px;
	margin-bottom: 16px;

	display: flex;
	flex-direction: row;
	gap: 0.5rem;
`;
