import React, { Component, Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import styled from 'styled-components';

import Colors from 'colors';

import Text from '../Text';
import Button from '../Button';
import Icon from '../Icon';

import { device } from '../styled/StyledMediaQuery';
import { ColumnFlex, RowFlex } from '../styled/Basic';

export const CertificationContentContainer = styled.div`
	width: 100%;
	padding-top: 10px;
	display: block;
`;

export const TextContainer = styled.div`
	display: flex;
	align-items: center;
	flex-direction: row;
`;

export const ProviderHeaderTC = styled(TextContainer)`
	height: 60px;
	line-height: 60px;
`;

export const TopNavContainer = styled(TextContainer)`
	position: absolute;
	left: 90px;
	top: 130px;
`;

export const ProviderProfileContainer = styled.div`
	width: 100vw;
	height: auto;
	margin-bottom: 2vh;
	padding-bottom: 32px;
	z-index: 50;
	position: relative;
`;

export const ProviderProfileItems = styled.div`
	max-width: 950px;
	flex-direction: row;
	margin: 0 auto;
	margin-top: 50px;

	@media ${device.mobileS} {
		width: 100vw;
	}

	@media ${device.laptop} {
		width: 950px;
	}
`;

export const ProviderProfileOverview = styled.div`
	width: 625px;
	border-radius: 8px;
	min-height: 550px;
	height: auto;
	display: inline-block;
	-webkit-box-shadow: 0px 0px 2px 0px rgba(148, 148, 148, 1);
	-moz-box-shadow: 0px 0px 2px 0px rgba(148, 148, 148, 1);
	box-shadow: 0px 0px 2px 0px rgba(148, 148, 148, 1);
	padding-bottom: 16px;
	background-color: white;
	@media ${device.mobileS} {
		width: 100%;
	}

	@media ${device.tablet} {
		width: 625px;
		margin: 0 auto;
		display: block;
	}

	@media ${device.laptop} {
		display: inline-block;
	}
`;

export const ProviderProfileOverviewHeader = styled.div`
	width: 100%;
	height: 60px;
	background: ${Colors.secondary.green};
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
`;

export const ProviderOverviewTop = styled.div`
	height: 325px;
	width: 625px;
	border-bottom: 1px solid ${Colors.gray};
	background-color: white;

	@media ${device.mobileS} {
		width: 100%;
		height: 200px;
	}

	@media ${device.mobileM} {
		height: 254px;
	}

	@media ${device.tablet} {
		width: 625px;
		height: 325px;
	}
`;

export const FittedImg = styled.img`
	height: 100%;
	width: 100%;
`;

export const ProviderProfileImgContainer = styled.div`
	height: 250px;
	width: 210px;
	margin: 30px 20px 0px 15px;
	display: inline-block;

	@media ${device.mobileS} {
		height: 110px;
		width: 90px;
	}

	@media ${device.mobileM} {
		height: 180px;
		width: 150px;
	}

	@media ${device.tablet} {
		height: 250px;
		width: 210px;
	}
`;

export const ProviderInfoContainer = styled.div`
	height: auto;
	display: inline-block;
	vertical-align: top;
	margin-top: 30px;
`;

export const ProviderOverviewBottom = styled.div`
	height: auto;
	width: 625px;

	@media ${device.mobileS} {
		width: 100vw;
	}

	@media ${device.tablet} {
		width: 625px;
	}
`;

export const ProviderBottomCell = styled(RowFlex)`
	padding-bottom: 20px;
	padding-top: 20px;
	border-bottom: solid 1px black;
`;

export const ProviderIconDiv = styled(ColumnFlex)`
	width: 40px;
	margin: 0 auto;
`;

export const ProviderBottomIcon = styled.div`
	width: 50px;

	vertical-align: top;
`;

export const ProviderBottomText = styled(ColumnFlex)`
	flex: 1;

	vertical-align: top;
	padding-top: 3px;

	@media ${device.mobileS} {
		width: 80%;
		height: auto;
	}

	@media ${device.tablet} {
	}
`;

export const LeftText = styled(Text)`
	display: inline-block;
	float: left;
`;

export const RightIcon = styled(Icon)`
	float: right;
	display: inline-block;

	@media ${device.mobileS} {
		margin-right: 10px;
	}

	@media ${device.tablet} {
		margin-right: 50px;
	}
`;

export const Clearfix = styled.div`
	content: '';
	clear: both;
	display: table;
`;

export const SpanContainer = styled.span`
	padding: ${(props) => props.padding || '0'};
	position: ${(props) => props.position || 'static'};
	top: ${(props) => props.top || 'auto'};
	float: ${(props) => props.float || 'none'};
	&::after {
		clear: both;
	}
`;

export const TabText = styled(Text)`
	height: ${(props) => props.height || '0'};
	line-height: ${(props) => props.lineHeight || '16px'};
	border-bottom: 4px solid ${Colors.fullWhite};
`;

export const InnerStars = styled.div`
	position: absolute;
	top: -1;
	left: 0;
	white-space: pre;
	overflow: hidden;
	width: ${(props) => props.width || 'auto'};

	::before {
		content: '\f005  \f005  \f005  \f005  \f005';
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		color: ${Colors.secondary.yellow};
	}
`;

export const OutterStars = styled.div`
	position: relative;
	display: inline-block;

	::before {
		content: '\f005  \f005  \f005  \f005  \f005';
		font-family: 'Font Awesome 5 Free';
		font-weight: 900;
		color: ${Colors.gray};
	}
`;
