import { createAction, handleActions } from 'redux-actions';

//model.js
const initialState = {
	error: null,
	success: null,
	loading: false,
	step: 0,
};

export const receiveError = createAction('RECEIVE_ERROR');
export const receiveStep = createAction('RECEIVE_STEP');
export const receiveSuccess = createAction('RECEIVE_SUCCESS');
export const resetOnboard = createAction('RESET_ONBOARD');
export const toggleLoading = createAction('TOGGLE_LOADING');

export default handleActions(
	{
		[receiveError](state, { payload }) {
			return {
				...state,
				error: payload,
			};
		},
		[receiveStep](state, { payload }) {
			return {
				...state,
				step: payload,
			};
		},
		[receiveSuccess](state, { payload }) {
			return {
				...state,
				success: payload,
			};
		},
		[toggleLoading](state, { payload }) {
			return {
				...state,
				loading: payload,
			};
		},
	},
	initialState
);
