import React, { Fragment, Component, useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Colors from 'colors';

import {
	addDocumentsRequirements,
	removeDocumentsRequirements,
	fetchClientDocumentRequirement,
	fetchCareProDocumentRequirement,
	fetchAttributeTraits,
	createAttributeTrait,
	fetchTraitTemplates,
	addTraitToTemplate,
	deactivateTraitFromTemplate,
} from 'reducers/admin';

import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';

import AttributesTab from './AttributesTab';

import {
	AgencyView,
	AgencyClientDocuments,
	AgencyCareProDocuments,
} from 'AppComp/admin/UserModel/UserModelBlocks';
import styled from 'styled-components';
import { device } from 'AppComp/styled/StyledMediaQuery';
import Button from 'AppComp/Button';
import Text from 'AppComp/Text';
import TemplateTab from './TemplateTab';
export const ModelContainer = styled.div`
	margin: 16px 0 16px 0;
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 85vw;
	min-width: 435px;

	align-items: center;

	@media ${device.tablet} {
		width: 520px;
	}
`;

const ModelInnerCon = styled.div`
	background-color: white;
	border-radius: 8px;
	width: 100%;
`;

const SwitchTabsButton = ({ activeTab, goToTab }) => {
	return (
		<RowFlex padding='10px 0'>
			<Button
				style={{
					borderRadius: '24px 0 0 24px',
				}}
				margin='10px 0 0 0 '
				backgroundColor={activeTab === 'agency' ? Colors.primary.primary : 'white'}
				width={'140px'}
				height={'40px'}
				onClick={() => goToTab('agency')}
			>
				<Text
					style={{
						textAlign: 'center',
					}}
					bold
					color={activeTab === 'agency' ? 'white' : Colors.primary.primary}
					midLarge
				>
					Agency
				</Text>
			</Button>
			<Button
				style={{
					borderRadius: '0',
				}}
				margin='10px 0 0 0 '
				backgroundColor={activeTab === 'client' ? Colors.primary.primary : 'white'}
				width={'140px'}
				height={'40px'}
				onClick={() => goToTab('client')}
			>
				<Text
					style={{
						textAlign: 'center',
					}}
					bold
					color={activeTab === 'client' ? 'white' : Colors.primary.primary}
					midLarge
				>
					Client
				</Text>
			</Button>
			<Button
				style={{
					borderRadius: '0',
				}}
				margin='10px 0 0 0 '
				backgroundColor={activeTab === 'attributes' ? Colors.primary.primary : 'white'}
				width={'140px'}
				height={'40px'}
				onClick={() => goToTab('attributes')}
			>
				<Text
					style={{
						textAlign: 'center',
					}}
					bold
					color={activeTab === 'attributes' ? 'white' : Colors.primary.primary}
					midLarge
				>
					Attributes
				</Text>
			</Button>
			<Button
				style={{
					borderRadius: '0',
				}}
				margin='10px 0 0 0 '
				backgroundColor={activeTab === 'templates' ? Colors.primary.primary : 'white'}
				width={'140px'}
				height={'40px'}
				onClick={() => goToTab('templates')}
			>
				<Text
					style={{
						textAlign: 'center',
					}}
					bold
					color={activeTab === 'templates' ? 'white' : Colors.primary.primary}
					midLarge
				>
					Templates
				</Text>
			</Button>
			<Button
				style={{
					borderRadius: '0 24px 24px 0',
				}}
				margin='10px 0 0 0 '
				backgroundColor={activeTab === 'carepro' ? Colors.primary.primary : 'white'}
				width={'140px'}
				height={'40px'}
				onClick={() => goToTab('carepro')}
			>
				<Text
					style={{
						textAlign: 'center',
					}}
					bold
					color={activeTab === 'carepro' ? 'white' : Colors.primary.primary}
					midLarge
				>
					CarePro
				</Text>
			</Button>
		</RowFlex>
	);
};
const AgencySettingsHandler = (props) => {
	const {
		fetchAttributeTraits,
		attributeTraits,
		createAttributeTrait,
		fetchTraitTemplates,
		addTraitToTemplate,
		deactivateTraitFromTemplate,
		traitTemplates,
	} = props;

	useEffect(() => {
		props.fetchClientDocumentRequirement();
		props.fetchCareProDocumentRequirement();

		fetchAttributeTraits();
		fetchTraitTemplates();
	}, []);
	console.log('AgencySettingsHandler props', props);

	const { goToTab } = props;
	const activeTab = props.match.params.tab;

	const renderTab = () => {
		switch (activeTab) {
			case 'agency':
				return (
					<>
						<ModelContainer>
							<ModelInnerCon>
								<AgencyView />
							</ModelInnerCon>
						</ModelContainer>
					</>
				);
			case 'attributes':
				return (
					<ModelContainer>
						<ModelInnerCon>
							<ColumnFlex>
								<AttributesTab
									createAttributeTrait={createAttributeTrait}
									attributeTraits={attributeTraits}
								/>
							</ColumnFlex>
						</ModelInnerCon>
					</ModelContainer>
				);
			case 'templates':
				return (
					<ModelContainer>
						<ModelInnerCon>
							<ColumnFlex>
								<TemplateTab
									addTraitToTemplate={addTraitToTemplate}
									deactivateTraitFromTemplate={deactivateTraitFromTemplate}
									traitTemplates={traitTemplates}
									attributeTraits={attributeTraits}
								/>
							</ColumnFlex>
						</ModelInnerCon>
					</ModelContainer>
				);
			case 'client':
				return (
					<>
						<ModelContainer>
							<ModelInnerCon>
								<ColumnFlex>
									<AgencyClientDocuments
										addDocumentsRequirements={props.addDocumentsRequirements}
										removeDocumentsRequirements={
											props.removeDocumentsRequirements
										}
										clientDocumentRequirements={
											props.clientDocumentRequirements
										}
									/>
								</ColumnFlex>
							</ModelInnerCon>
						</ModelContainer>
					</>
				);
			case 'carepro':
				return (
					<>
						<ModelContainer>
							<ModelInnerCon>
								<ColumnFlex>
									<AgencyCareProDocuments
										addDocumentsRequirements={props.addDocumentsRequirements}
										careProDocumentRequirements={
											props.careProDocumentRequirements
										}
										removeDocumentsRequirements={
											props.removeDocumentsRequirements
										}
									/>
								</ColumnFlex>
							</ModelInnerCon>
						</ModelContainer>
					</>
				);
			default:
				return <AgencyView />;
		}
	};
	return (
		<ColumnFlex alignCenter fullWidth fullHeight>
			<SwitchTabsButton activeTab={activeTab} goToTab={goToTab} />

			<RowFlex padding='0 32px' gap='0 16px '>
				{renderTab()}
			</RowFlex>
		</ColumnFlex>
	);
};

const mapStateToProps = ({ admin }, props) => {
	return {
		agencyCarePlan: admin.agencyCarePlan,
		clientDocumentRequirements: admin.clientDocumentRequirements,
		careProDocumentRequirements: admin.careProDocumentRequirements,
		attributeTraits: admin.attributeTraits,
		traitTemplates: admin.traitTemplates,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		goToTab: (tab) => dispatch(push(`/admin/settings/${tab}`)),
		addDocumentsRequirements: (params) => dispatch(addDocumentsRequirements(params)),
		removeDocumentsRequirements: (params) => dispatch(removeDocumentsRequirements(params)),
		fetchClientDocumentRequirement: () => dispatch(fetchClientDocumentRequirement()),
		fetchCareProDocumentRequirement: () => dispatch(fetchCareProDocumentRequirement()),
		fetchAttributeTraits: () => dispatch(fetchAttributeTraits()),
		createAttributeTrait: (params) => dispatch(createAttributeTrait(params)),
		fetchTraitTemplates: () => dispatch(fetchTraitTemplates()),
		addTraitToTemplate: (params) => dispatch(addTraitToTemplate(params)),
		deactivateTraitFromTemplate: (params) => dispatch(deactivateTraitFromTemplate(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AgencySettingsHandler);
