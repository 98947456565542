import React, { Component, useState, useMemo, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { connect } from 'react-redux';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { Views } from 'react-big-calendar';
import styled from 'styled-components';

import Colors from 'colors';
import { WORK_TIME_FRAME_TYPE, JOB_OFFER_STATUS_ENUM, JOB_STATUS } from 'constants';
import api from 'scripts/api';
import useWindowSize from 'scripts/useWindowSize';
import usePreventScroll from 'scripts/usePreventScroll';

import {
	BackDropBg,
	ColumnFlex,
	RowFlex,
	MODAL_BACKDROP,
	MODAL_PANEL,
	ModalPanel,
} from 'basicStyles';
import Icon from 'AppComp/Icon';
import Button from 'AppComp/Button';
import Text from 'AppComp/Text';

import Schedule from './Schedule';
import ClientScheduleToolbar from './ClientScheduleToolbar';
import ClientSchedulePartialToolbar from './ClientSchedulePartialToolbar';
import AddScheduleSession from './AddScheduleSession';
import EditScheduleSession from './EditScheduleSession';

import { PROVIDER_MODEL_V2, CORE_JOB_POST_MODEL, CORE_JOB_MODEL } from 'reducers/graphqlModelTypes';

export const ActionSection = styled.div`
	flex: 1;
	height: fit-content;
`;

export const TileType = {
	TENTATIVE: 'TENTATIVE',
	SCHEDULED: 'SCHEDULED',
	RESCHEDULED: 'RESCHEDULED',
	PENDING_CLIENT_APPROVAL: 'PENDING_CLIENT_APPROVAL',
	PENDING_PROVIDER_APPROVAL: 'PENDING_PROVIDER_APPROVAL',
};

const JobPostSchedule = (props) => {
	const { patient, carePlan, history, jobPost } = props;

	const [userSelectedDate, setUserSelectedDate] = useState(new Date());
	const currentDate = useMemo(() => userSelectedDate, [userSelectedDate]);

	const [jobEvents, setJobEvents] = useState([]);

	const [showAddScheduleOverlay, setShowAddScheduleOverlay] = useState(false);

	const [selectedDateTileType, setSelectedDateTileType] = useState([
		TileType.TENTATIVE,
		TileType.SCHEDULED,
		TileType.RESCHEDULED,
		TileType.PENDING_CLIENT_APPROVAL,
		TileType.PENDING_PROVIDER_APPROVAL,
	]);

	const [view, setView] = useState({
		label: 'Month',
		value: Views.MONTH,
	});

	const [displayLabel, setDisplayLabel] = useState('Day');

	const [selectedEvent, setSelectedEvent] = useState(null);
	const [showScheduleFiltersOverlay, setShowScheduleFiltersOverlay] = useState(false);
	const [showEditEventOverlay, setShowEditEventOverlay] = useState(false);

	usePreventScroll(showAddScheduleOverlay);
	const { width } = useWindowSize();

	useEffect(() => {
		// create the events array that Schedule will consume
		if (jobPost) {
			createScheduleEventsFromJobPost(jobPost.workTimeFrames);
		}
	}, [jobPost]);

	const createScheduleEventsFromJobPost = (workTimeFrames) => {
		if (jobPost === null) {
			return;
		}

		// loop through workTimeFrames
		// first get all the active ones
		const activeWorkTimeFrames = workTimeFrames.filter((workTimeFrame) => {
			return workTimeFrame.active_status_enum === WORK_TIME_FRAME_TYPE.ACTIVE;
		});

		console.log('activeWorkTimeFrames', activeWorkTimeFrames);
		// create custom shape that react-big-calendar can consume
		const _events = activeWorkTimeFrames.map((workTimeFrame) => {
			// ensure to convert gmt to local time

			const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's time zone

			const userStartDate = new Date(
				workTimeFrame.start_time.toLocaleString('en-US', { timeZone: userTimezone })
			);
			const userEndDate = new Date(
				workTimeFrame.end_time.toLocaleString('en-US', { timeZone: userTimezone })
			);

			return {
				title: 'Care Session',
				start: userStartDate,
				end: userEndDate,
				allDay: false,
				resource: {
					type: 'careSession',
					workTimeFrame: workTimeFrame,
					jobPost: jobPost,
					carePlan: carePlan,
				},
			};
		});

		setJobEvents(_events);
	};

	const isMobile = width < 768;

	const handleModalExitClick = () => {
		setShowAddScheduleOverlay(false);
	};

	const handleSuccess = async (editEvent) => {
		console.log('CLientScheld handler handleSuccess', editEvent);
		try {
			// basically just fetch the latest events and rerender them
			const fetchSingleJobPostRes = await api.graph({
				query: `{
          fetchSingleJobPost(job_post_id: ${jobPost.id}) {
            ${CORE_JOB_POST_MODEL}
          }
        }`,
			});

			if (fetchSingleJobPostRes.fetchSingleJobPost) {
				createScheduleEventsFromJobPost(
					fetchSingleJobPostRes.fetchSingleJobPost.workTimeFrames
				);

				if (editEvent) {
					setShowEditEventOverlay(false);
				} else {
					setShowAddScheduleOverlay(false);
				}
			}
		} catch (err) {
			console.error(err);
		}
	};

	const onSelectEvent = (event) => {
		console.log('onSelectEvent', event);
		setSelectedEvent(event);
		setShowEditEventOverlay(true);
	};

	const ScheduleFilters = () => {
		if (isMobile) {
			return (
				<AnimatePresence mode='wait' initial={false}>
					{showScheduleFiltersOverlay && (
						<>
							<BackDropBg
								{...MODAL_BACKDROP}
								aria-hidden='true'
								onClick={() => setShowScheduleFiltersOverlay(false)}
							/>

							<ModalPanel aria-label='Sidebar' {...MODAL_PANEL}>
								<ColumnFlex gap='32px'>
									<ClientScheduleToolbar
										selectedDateTileType={selectedDateTileType}
										setSelectedDateTileType={setSelectedDateTileType}
										displayLabel={displayLabel}
										setView={setView}
										view={view}
										currentDate={currentDate}
										setUserSelectedDate={setUserSelectedDate}
									/>
									<Text
										onClick={() => setShowScheduleFiltersOverlay(false)}
										style={{
											cursor: 'pointer',
											margin: 'auto',
										}}
										fontWeight='100'
										color={'white'}
									>
										exit
									</Text>
									<RowFlex fullWidth center>
										<Button
											backgroundColor={Colors.primary.primary}
											width={'300px'}
											height={'70px'}
											borderRadius='32px'
											style={{
												shadow: 'none',
											}}
											onClick={() => setShowAddScheduleOverlay(true)}
										>
											<Text fontWeight='500' color={'white'} largeText>
												Add a Schedule
											</Text>
										</Button>
									</RowFlex>
								</ColumnFlex>
							</ModalPanel>
						</>
					)}
				</AnimatePresence>
			);
		} else {
			return (
				<ColumnFlex gap='32px'>
					<ClientScheduleToolbar
						selectedDateTileType={selectedDateTileType}
						setSelectedDateTileType={setSelectedDateTileType}
						displayLabel={displayLabel}
						setView={setView}
						view={view}
						currentDate={currentDate}
						setUserSelectedDate={setUserSelectedDate}
					/>
					<RowFlex fullWidth center>
						<Button
							backgroundColor={Colors.primary.primary}
							width={'300px'}
							height={'70px'}
							borderRadius='32px'
							style={{
								shadow: 'none',
							}}
							onClick={() => setShowAddScheduleOverlay(true)}
						>
							<Text fontWeight='500' color={'white'} largeText>
								Add a Schedule
							</Text>
						</Button>
					</RowFlex>
				</ColumnFlex>
			);
		}
	};

	return (
		<RowFlex
			columnMobile
			style={{
				backgroundColor: Colors.theme.primary_background,
			}}
			fullWidth
			padding='16px'
			gap='16px'
		>
			<AnimatePresence mode='wait' initial={false}>
				{showAddScheduleOverlay && (
					<AddScheduleSession
						carePlan={carePlan}
						handleModalExitClick={handleModalExitClick}
						handleSuccess={handleSuccess}
						jobPostId={jobPost.id}
					/>
				)}
			</AnimatePresence>
			<AnimatePresence mode='wait' initial={false}>
				{showEditEventOverlay && (
					<EditScheduleSession
						event={selectedEvent}
						handleModalExitClick={() => setShowEditEventOverlay(false)}
						handleSuccess={handleSuccess}
					/>
				)}
			</AnimatePresence>
			{isMobile && (
				<>
					<ClientSchedulePartialToolbar
						selectedDateTileType={selectedDateTileType}
						setSelectedDateTileType={setSelectedDateTileType}
						displayLabel={displayLabel}
						setView={setView}
						view={view}
						currentDate={currentDate}
						setUserSelectedDate={setUserSelectedDate}
					></ClientSchedulePartialToolbar>
					<button
						style={{ background: 'white', border: 'none' }}
						onClick={() => setShowScheduleFiltersOverlay(true)}
					>
						<Icon
							color={Colors.secondary.blue}
							icon={faBars}
							size={isMobile ? '1x' : '2x'}
						/>
					</button>
				</>
			)}
			<ScheduleFilters />
			<Schedule
				events={jobEvents}
				view={view}
				setDisplayLabel={setDisplayLabel}
				currentDate={currentDate}
				onSelectEvent={onSelectEvent}
			/>
		</RowFlex>
	);
};

export default JobPostSchedule;
