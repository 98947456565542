const parser = (filters) => {
	const result = [];

	for (const key in filters) {
		const field = filters[key];

		switch (field.type) {
			case 'drop-down':
				field.options.default.forEach((option) => {
					if (option.selected) {
						if (option.value === 'custom') {
							result.push({
								trait_id: option.traitId,
								value: field.customValue,
							});
						}
						if (option.value !== 'default') {
							result.push({
								trait_id: option.traitId,
								value: option.value,
							});
						}
					} else {
						if (option.value !== 'default') {
							result.push({
								trait_id: option.traitId,
								value: 'null',
							});
						}
					}
				});
				break;

			case 'text':
				result.push({
					trait_id: field.traitId,
					value: field.customValue,
				});
				break;

			case 'tags':
				field.options.forEach((option) => {
					result.push({
						trait_id: option.traitId,
						value: option.selected.toString(),
					});
				});
				break;

			default:
				break;
		}
	}

	return result;
};

export default parser;
