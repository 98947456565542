import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import PageHeading from '../../../core/components/PageHeading';
import { CareProTile } from '../../../../../corev2/Tiles';
import JobOfferGuide from '../../components/JobOfferGuide';
import CareNeeds from '../../components/CareNeeds';
import ShiftItem from '../../components/ShiftItem';

import { profileRoutes } from '../../../profiles/routes/constants';

import { JobPostProvider } from '../../context/JobPostContext';

import { useJobPost } from '../../hooks/useJobPost';

import { useFetchSelectedCareProQuery } from '../../api/queries/useFetchSelectedCareProQuery';

import {
	StyledJobPostOfferPage,
	StyledJobSection,
	StyledAction,
	StyledActionHeading,
	StyledStatusWrapper,
	StyledStatusIconContainer,
	StyledStatus,
	StyledActionsContainer,
	StyledMainContainer,
	StyledMainWrapper,
	StyledShiftsContainer,
} from './styles';

import { formatIncomingShift, sortShifts } from '../../lib';

const ViewOfferPageContent = () => {
	const [shifts, setShifts] = useState([]);
	const [job, setJob] = useState(null);
	const history = useHistory();
	const { jobOfferId } = useParams();

	const { jobPost, isLoadingJobPost } = useJobPost();

	const { data: carePro, isLoading: isLoadingCarePro } = useFetchSelectedCareProQuery(
		job?.provider.id
	);

	useEffect(() => {
		if (jobPost) {
			const matchedJob = jobPost.jobs.find((job) => job.id == jobOfferId);
			setJob(matchedJob);

			if (matchedJob) {
				const formattedShifts = matchedJob.shifts.map((shift) =>
					formatIncomingShift(shift)
				);
				setShifts(sortShifts(formattedShifts));
			}
		}
	}, [jobPost, jobOfferId]);

	if (isLoadingJobPost) {
		return <>Loading Job Post</>;
	}

	if (!job) {
		return <>Job not found</>;
	}

	if (isLoadingCarePro) {
		return <>Loading Care Pro</>;
	}

	const viewCareProHandler = (careProId) => {
		history.push(profileRoutes.careProProfile(careProId));
	};

	return (
		<StyledJobPostOfferPage>
			<PageHeading>
				Offer made to: {carePro.displayName} for job "{jobPost.title}"
			</PageHeading>

			<JobOfferGuide />

			<StyledJobSection>
				<StyledActionsContainer>
					<StyledAction>
						<StyledActionHeading>Status</StyledActionHeading>

						<StyledStatusWrapper>
							<StyledStatusIconContainer>
								<FontAwesomeIcon icon={faExclamationCircle} size='lg' />
							</StyledStatusIconContainer>

							<StyledStatus>Offered</StyledStatus>
						</StyledStatusWrapper>
					</StyledAction>
				</StyledActionsContainer>

				<StyledMainContainer>
					<StyledMainWrapper>
						<StyledActionHeading>Care Pro</StyledActionHeading>

						<CareProTile
							key={carePro.id}
							id={carePro.id}
							displayName={carePro.displayName}
							rating={carePro.averageReview}
							hourlyRate={carePro.hourly_rate}
							professionalStatement={carePro.professional_statement}
							viewCareProHandler={viewCareProHandler}
						/>
					</StyledMainWrapper>

					<StyledMainWrapper>
						<StyledActionHeading>Care Needs</StyledActionHeading>

						<CareNeeds />
					</StyledMainWrapper>

					<StyledMainWrapper>
						<StyledActionHeading>Shifts Offered</StyledActionHeading>

						<StyledShiftsContainer>
							{shifts.map((shift) => (
								<ShiftItem
									key={shift.id}
									shift={shift}
									isSelected={true}
									onClickHandler={() => {}}
								/>
							))}
						</StyledShiftsContainer>
					</StyledMainWrapper>
				</StyledMainContainer>
			</StyledJobSection>
		</StyledJobPostOfferPage>
	);
};

const ViewOfferPage = () => {
	return (
		<JobPostProvider>
			<ViewOfferPageContent />
		</JobPostProvider>
	);
};

export default ViewOfferPage;
