import React, { useState, useEffect } from 'react';

import NavTabs from '../../../../../corev2/NavTabs';
import DescriptivePage from '../../../../../corev2/DescriptivePage';
import StripeDocumentsList from '../../components/StripeDocumentsList';
import Text from '../../../../../corev2/Text';
import Loader from '../../../../../corev2/Loader';
import UploadTile from '../../components/UploadTile';

import { useAuth } from '../../../core/hooks/useAuth';
import { useUploadStripeDocumentsMutation } from '../../api/mutations/useUploadStripeDocumentsMutation';
import { useFetchStripeAccountFiles } from '../../api/queries/useFetchStripeAccountFilesQuery';
import { stripeDocumentRequests } from '../../lib/stripeDocumentRequests';

import {
	StyledDocumentsContainer,
	StyledNavContainer,
	StyledDocumentsWrapper,
	StyledDocumentRequest,
} from './styles';

const NAV_ITEMS = {
	requirements: { text: 'Requirements', stat: 0, active: true },
	uploaded: { text: 'Uploaded', stat: 0, active: false },
};

const StripeDocumentsPage = () => {
	const [navItems, setNavItems] = useState(NAV_ITEMS);
	const [uploadedDocumentStates, setUploadedDocumentStates] = useState({}); // Manage loading states

	const { authCarePro } = useAuth();
	const uploadStripeDocumentsMutation = useUploadStripeDocumentsMutation();

	const { data: stripeAccountFiles, isLoading: isLoadingStripeAccountFiles } =
		useFetchStripeAccountFiles();

	const stripeDocumentRequestsList =
		authCarePro?.stripeStatus?.stripe_data?.currently_due?.filter((doc) =>
			doc.endsWith('.document')
		) || [];

	// Update nav items dynamically
	useEffect(() => {
		if (!isLoadingStripeAccountFiles) {
			setNavItems((prev) => ({
				...prev,
				requirements: { ...prev.requirements, stat: stripeDocumentRequestsList.length },
				uploaded: { ...prev.uploaded, stat: stripeAccountFiles.length },
			}));
		}
	}, [stripeDocumentRequestsList, stripeAccountFiles, isLoadingStripeAccountFiles]);

	// Handle document upload
	const handleUploadDocuments = async (file, documentRequirementId, expirationDate) => {
		setUploadedDocumentStates((prev) => ({
			...prev,
			[documentRequirementId]: { loading: true },
		}));

		try {
			// Upload file to Stripe
			const data = new FormData();
			data.append('file', file);
			data.append('purpose', 'identity_document');

			const fileResult = await fetch('https://uploads.stripe.com/v1/files', {
				method: 'POST',
				headers: {
					Authorization: `Bearer ${process.env.STRIPE_PUBLIC_KEY}`,
				},
				body: data,
			});

			const fileDataResult = await fileResult.json();

			// Save the document using your API
			await uploadStripeDocumentsMutation.mutateAsync({
				frontImg: fileDataResult.id,
			});

			setUploadedDocumentStates((prev) => ({
				...prev,
				[documentRequirementId]: { loading: false, success: true },
			}));
		} catch (error) {
			console.error('Error uploading Stripe document:', error);

			setUploadedDocumentStates((prev) => ({
				...prev,
				[documentRequirementId]: { loading: false, success: false },
			}));
		}
	};

	const getDescriptionText = () => {
		if (navItems.requirements.active) {
			return (
				<Text size='large'>
					Requirements are the documents that Stripe requires for verification. Upload the
					requested documents to comply with Stripe’s requirements.
				</Text>
			);
		}
		if (navItems.uploaded.active) {
			return (
				<Text size='large'>
					Uploaded documents are the ones you have already provided to Stripe.
				</Text>
			);
		}
		return null;
	};

	if (isLoadingStripeAccountFiles) {
		return <Loader />;
	}

	return (
		<DescriptivePage
			heading='Stripe Document Requests'
			descriptionContent={getDescriptionText()}
		>
			<StyledDocumentsContainer>
				<StyledNavContainer>
					<NavTabs
						items={navItems}
						navTabsHandler={(key) =>
							setNavItems((prev) =>
								Object.entries(prev).reduce((acc, [k, item]) => {
									acc[k] = { ...item, active: k === key };
									return acc;
								}, {})
							)
						}
					/>
				</StyledNavContainer>

				{navItems.requirements.active && (
					<StyledDocumentsWrapper>
						<StyledDocumentRequest>
							{stripeDocumentRequestsList.map((documentRequirement, index) => (
								<UploadTile
									key={index}
									documentRequirement={{
										id: index,
										name: stripeDocumentRequests(documentRequirement),
										requires_expiration: false, // Stripe-specific: Modify if expiration is required
									}}
									onUpload={(file, expirationDate) =>
										handleUploadDocuments(file, index, expirationDate)
									}
									loading={uploadedDocumentStates[index]?.loading || false}
								/>
							))}

							{stripeDocumentRequestsList.length === 0 && (
								<Text>No document requirements.</Text>
							)}
						</StyledDocumentRequest>
					</StyledDocumentsWrapper>
				)}

				{navItems.uploaded.active && (
					<StripeDocumentsList
						title='Uploaded Stripe Documents'
						documents={stripeAccountFiles}
					/>
				)}
			</StyledDocumentsContainer>
		</DescriptivePage>
	);
};

export default StripeDocumentsPage;
