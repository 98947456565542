import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledJobPostPage = styled.div`
	width: 100%;
	max-width: 1000px;
	display: flex;
	flex-direction: column;
	gap: 24px;
	margin: auto;
	padding: 15px;

	@media ${mediaQueries.tablet} {
		padding: 0 20px;
	}

	@media ${mediaQueries.laptop} {
		padding: 0 40px;
	}
`;

export const StyledPageHeadingContainer = styled.div`
	display: flex;
	justify-content: center;
	padding: 15px 0;

	@media ${mediaQueries.mobileM} {
		padding: 20px 0;
	}
`;

export const StyledJobSection = styled.div`
	display: flex;
	gap: 24px;
	flex-direction: column;

	@media ${mediaQueries.tablet} {
		flex-direction: row;
	}
`;

export const StyledActionsContainer = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 20px;

	@media ${mediaQueries.tablet} {
		width: 33%;
	}
`;

export const StyledCareProsContainer = styled.div`
	width: 100%;
	background: ${colors.white};
	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;
	padding: 15px;

	@media ${mediaQueries.mobileL} {
		padding: 20px;
	}

	@media ${mediaQueries.tablet} {
		width: 67%;
		padding: 25px;
	}
`;
