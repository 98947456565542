import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import styled from 'styled-components';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { RowFlex, ColumnFlex, DropShadow } from 'AppComp/styled/Basic';
import Icon from 'AppComp/Icon';

import Colors from 'colors';

import Button from 'AppComp/Button';
import FileView from 'AppComp/FileView';

const imageVariants = {
	enter: {
		opacity: 0,
	},
	center: {
		opacity: 1,
		transition: {
			duration: 0.5,
		},
	},
	exit: {
		opacity: 0,
		transition: {
			duration: 0.5,
		},
	},
};

const StepCon = styled(ColumnFlex)`
	width: 50px;
	height: 50px;
	background-color: ${Colors.primary.primary};
	border-radius: 50%;
	${DropShadow(3)};
	cursor: pointer;
`;

const OverlaySlider = ({ images, visible, setVisible, initIndex = 0 }) => {
	console.log('initIndex', initIndex);
	const [imageIndex, setImageIndex] = useState(initIndex);

	console.log('imageIndex', imageIndex);
	useEffect(() => {
		console.log('initIndex use Effect', initIndex);
		setImageIndex(initIndex);
	}, [initIndex]);
	// Handlers to go to next/previous image
	const showNextImage = () => {
		console.log('showNextImage - is this runnign');
		setImageIndex((prevIndex) => (prevIndex + 1) % images.length);
	};

	const showPrevImage = () => {
		console.log('showPrevImage - is this runnign');
		setImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
	};

	// Close overlay handler
	const closeOverlay = () => setVisible(false);

	const testError = (e) => {
		window.alert('testError');
		console.log('testError, e', e);
	};
	return (
		<AnimatePresence>
			{visible && (
				<motion.div
					initial='enter'
					animate='center'
					exit='exit'
					variants={imageVariants}
					key={imageIndex}
					className='image-overlay'
				>
					<motion.div
						style={{
							height: '100%',
							width: '100%',
						}}
						key={imageIndex}
						variants={imageVariants}
						initial='enter'
						animate='center'
						exit='exit'
						// Optional: click image to close
					>
						<ColumnFlex
							style={{
								height: '100%',
								width: '100%',
								overflow: 'hidden',
							}}
							padding='16px 0 '
							center
						>
							<a href={images[imageIndex]} download={images[imageIndex]}>
								<Button
									borderRadius='12px'
									width='120px'
									height='50px'
									backgroundColor={Colors.emerald}
								>
									Download
								</Button>
							</a>

							<ColumnFlex
								style={{
									height: '70vh',
									width: '100%',
									overflow: 'scroll',
								}}
								className='image-overlay-container'
							>
								{/* <FileViewer
									fileType={images[imageIndex].split('.').pop()}
									filePath={images[imageIndex]}
									onError={testError}
									errorComponent={ErrorView}
								/> */}
								<FileView file={images[imageIndex]} />
							</ColumnFlex>
							<RowFlex alignCenter gap='8px'>
								<StepCon center onClick={showPrevImage}>
									<Icon icon={faChevronLeft} color={Colors.white} size='lg' />
								</StepCon>

								<Button
									borderRadius='12px'
									width='120px'
									height='50px'
									backgroundColor={Colors.greenTeal}
									onClick={() => closeOverlay()}
								>
									Close
								</Button>

								<StepCon center onClick={showNextImage}>
									<Icon icon={faChevronRight} color={Colors.white} size='lg' />
								</StepCon>
							</RowFlex>
						</ColumnFlex>
					</motion.div>
				</motion.div>
			)}
		</AnimatePresence>
	);
};

export default OverlaySlider;

const ErrorView = () => {
	return (
		<ColumnFlex fullWidth fullHeight center>
			<Text>There was an error loading this document. Please download to view</Text>
		</ColumnFlex>
	);
};
