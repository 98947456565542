import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { SCHEDULE_MODEL } from '../../../core/api/models';

const deactivateSchedule = async ({ scheduleId }) => {
	try {
		const data = await api.graph({
			query: `mutation {
                deactivateSchedule(
					schedule_id: ${scheduleId}
                    ) {
                    ${SCHEDULE_MODEL}
                }
            }`,
		});

		return data.deactivateSchedule;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useDeactivateSchedule = () => {
	return useMutation({
		mutationKey: ['deactivateSchedule'],
		mutationFn: (values) => {
			return deactivateSchedule(values);
		},
	});
};
