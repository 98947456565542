import styled from 'styled-components';

import { colors } from '../utils';

export const StyledNavTabsContainer = styled.div`
	width: 100%;

	display: flex;
	border-radius: 20px;
`;

export const StyledNavItem = styled.div`
	display: flex;
	flex: 1;
	cursor: pointer;

	background: ${({ active }) => (active ? colors.primary.primary : 'none')};
	border-radius: 20px;

	text-align: center;
	align-items: center;
	justify-content: center;

	padding: 8px 0;
`;

export const StyledItemText = styled.p`
	font-size: 0.9rem;
	font-weight: ${({ active }) => (active ? '700' : '500')};
	color: ${({ active }) => (active ? colors.white : colors.primary.primary)};
`;

export const StyledNavItemStat = styled.span`
	font-size: 14px;
	font-weight: ${({ active }) => (active ? '700' : '500')};
`;
