import React from 'react';
import { useHistory } from 'react-router-dom/';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUser,
	faMoneyBill,
	faBriefcase,
	faComments,
	faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { PrimaryButton } from '../../../../../../corev2/Buttons';

import {
	StyledCardContainer,
	StyledUserInfo,
	StyledUserName,
	StyledUserEmail,
	StyledNavMenu,
	StyledMenuItem,
	StyledMenuLink,
	StyledSectionLinks,
	StyledSectionLink,
	StyledLink,
	StyledButtonContainer,
} from './styles';

import { useAuth } from '../../../hooks/useAuth';

import { coreRoutes } from '../../../routes/constants';

const DropDownMenu = (props) => {
	const { dropDownHandler } = props;

	const history = useHistory();

	const { authCarePro, logoutCarePro } = useAuth();

	const goToDocumentsPage = () => {
		history.push(coreRoutes.documents);
		dropDownHandler();
	};

	const goToDashbboardPage = () => {
		history.push(coreRoutes.dashboard);
		dropDownHandler();
	};

	const goToPaymentsPage = () => {
		history.push(coreRoutes.payments);
		dropDownHandler();
	};

	const goToShiftsPage = () => {
		history.push(coreRoutes.shifts);
		dropDownHandler();
	};

	const logOutHandler = () => {
		logoutCarePro();
		dropDownHandler();
		history.push(coreRoutes.home);
	};

	return (
		<StyledCardContainer>
			<StyledUserInfo>
				<StyledUserName>{authCarePro.displayName}</StyledUserName>
				<StyledUserEmail>{authCarePro.email}</StyledUserEmail>
			</StyledUserInfo>
			<StyledNavMenu>
				<StyledMenuItem>
					<FontAwesomeIcon icon={faUser} />
					<StyledMenuLink>Profile</StyledMenuLink>
				</StyledMenuItem>

				<StyledMenuItem>
					<FontAwesomeIcon icon={faMoneyBill} onClick={goToPaymentsPage} />
					<StyledMenuLink>Payments</StyledMenuLink>
				</StyledMenuItem>

				<StyledMenuItem onClick={goToShiftsPage}>
					<FontAwesomeIcon icon={faBriefcase} />
					<StyledMenuLink>Shifts</StyledMenuLink>
				</StyledMenuItem>

				<StyledMenuItem>
					<FontAwesomeIcon icon={faComments} />
					<StyledMenuLink>Chat</StyledMenuLink>
				</StyledMenuItem>
			</StyledNavMenu>

			<StyledSectionLinks>
				<StyledSectionLink onClick={goToDashbboardPage}>
					<StyledLink>Dashboard</StyledLink>
					<FontAwesomeIcon icon={faChevronRight} />
				</StyledSectionLink>

				<StyledSectionLink onClick={goToDocumentsPage}>
					<StyledLink>Documents</StyledLink>
					<FontAwesomeIcon icon={faChevronRight} />
				</StyledSectionLink>

				<StyledSectionLink>
					<StyledLink>Schedule</StyledLink>
					<FontAwesomeIcon icon={faChevronRight} />
				</StyledSectionLink>

				<StyledSectionLink>
					<StyledLink>Help</StyledLink>
					<FontAwesomeIcon icon={faChevronRight} />
				</StyledSectionLink>
			</StyledSectionLinks>
			<StyledButtonContainer>
				<PrimaryButton size='small' onClick={logOutHandler}>
					Log Out
				</PrimaryButton>
			</StyledButtonContainer>
		</StyledCardContainer>
	);
};

export default DropDownMenu;
