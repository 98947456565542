import React, { Component, Fragment, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import is from 'styled-is';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { Formik, Form, Field } from 'formik';
import Colors from 'colors';

import Icon from 'AppComp/Icon';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';

import useWindowSize from 'scripts/useWindowSize';

import MessageTile from './MessageTile';

import CustomTextInput from './CustomTextInput';
import Text from 'AppComp/Text';
import { RowFlex, ColumnFlex, DropShadow } from 'AppComp/styled/Basic';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex: 1;
	height: calc(100vh - 120px);

	padding: 0px 16px;
	align-items: center;

	position: relative;
`;

export const ChatTextWrapperParent = styled.div`
	flex: 1 1 auto;
	height: calc(100% - 40px);
	width: 98%;

	border-radius: 12px;
`;
export const ChatColumn = styled.div`
	padding-top: 16px;
	display: flex;
	flex: 1;
	height: 100%;
	flex-direction: column;
	overflow: scroll;
	padding-bottom: 90px;
	gap: 16px;
`;

export const ChatMessagesColumn = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	overflow: hidden;
	padding: 12px;
	border-radius: 12px;
	gap: 16px;
	background-color: white;
	border-radius: 12px;
`;
export const StyledButton = styled.button`
	background: inherit;
	border: none;
`;
export const ChatHeader = styled(RowFlex)`
	height: 100px;
	width: 100%;
	background-color: white;
	border-radius: 12px;
`;
const CustomForm = styled.form`
	border-radius: 12px;
	${DropShadow(2)}
	padding: 16px;
	width: 95%;
	max-width: 800px;
	bottom: 12px;
	z-index: 100;
	background-color: white;
	position: absolute;
	transition: all ease-in-out 0.3s;
	margin-block-end: 0;
	align-self: center;
`;

const CustomFormButton = styled.button`
	border: none;
	background-color: white;
`;

const LoaderCon = styled.div`
	height: 50px;
	width: 50px;
	margin-left: 16px;
`;

const TextWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	text-align: center;
`;

const ChatMessages = ({ messages, loggedInUser, selectedConversation }) => {
	const scrollRef = useRef(null);

	useEffect(() => {
		if (scrollRef.current) {
			const { offsetHeight, scrollHeight, scrollTop } = scrollRef.current;

			if (scrollHeight >= scrollTop + offsetHeight) {
				scrollRef.current.scrollTo(0, scrollHeight);
			}
		}
	}, [messages, scrollRef.current]);

	const updatedMessages = messages.map((msg) => ({
		...msg,
		loggedInUser: loggedInUser,
	}));

	return (
		<AutoSizer style={{ width: '100%', height: '100%' }}>
			{({ height, width }) => (
				<>
					{!messages.length && (
						<TextWrapper>
							<Text largeText bold margin={'auto'}>
								{selectedConversation
									? 'No Messages'
									: 'Select a conversation to start chatting'}
							</Text>
						</TextWrapper>
					)}
					<div
						ref={scrollRef}
						style={{
							height: height,
							width: width,
							overflowY: 'scroll',
						}}
					>
						{updatedMessages.map((msg) => (
							<MessageTile key={msg.id} convo={msg} />
						))}
					</div>
				</>
			)}
		</AutoSizer>
	);
};

const MessageView = (props) => {
	const { handleSendMessage } = props;

	const [textLength, setTextLength] = useState(0.1);

	const handleSubmit = async (values, form) => {
		try {
			form.setSubmitting();
			form.resetForm();
			setTextLength(0.1);
			handleSendMessage(values.text);
		} catch (err) {
			console.log(err);
		}
	};

	const updateTextLength = (length) => {
		setTextLength(length);
	};

	return (
		<Formik onSubmit={handleSubmit} initialValues={{ text: '' }}>
			{(formProps) => (
				<CustomForm
					id='msg-tile'
					noValidate
					onSubmit={formProps.handleSubmit}
					autocomplete='off'
				>
					<RowFlex id='msg-tile' center>
						<Field
							name='text'
							component={CustomTextInput}
							placeholder='Start Typing Or Click Here To Start Composing A Message…'
							maxLength='140'
							updateTextLength={updateTextLength}
						/>

						<CustomFormButton type='submit'>
							<LoaderCon>
								<CircularProgressbarWithChildren
									strokeWidth={7}
									styles={buildStyles({})}
									value={(textLength / 140) * 100}
								>
									{textLength !== 0.1 ? `${textLength}` : ''}
								</CircularProgressbarWithChildren>
							</LoaderCon>
						</CustomFormButton>
					</RowFlex>
				</CustomForm>
			)}
		</Formik>
	);
};

const ChatHeaderView = (props) => {
	const { width } = useWindowSize();

	const { selectedConversation, loggedInUser, backButtonHandler } = props;

	if (selectedConversation === null) {
		return <ChatHeader />;
	}

	const renderUserName = () => {
		// convo is with the admin since the provider is login
		const { user_type, user, provider } = selectedConversation;

		if (loggedInUser === 'carePro') {
			if (user_type === 'care_provider') {
				return `Admin`;
			} else if (user_type === 'carePro') {
				// means convo with user
				return `${user.name}`;
			}
		} else if (loggedInUser === 'patient') {
			if (user_type === 'patient') {
				return `${selectedConversation.admin.name} (Admin)`;
			} else if (user_type === 'carePro') {
				// means convo with user
				return `${selectedConversation.provider.first_name} ${selectedConversation.provider.last_name}`;
			}
		} else if (loggedInUser === 'admin') {
			if (user_type === 'patient') {
				return `${user.name}`;
			} else if (user_type === 'carePro') {
				// means convo with user
				return `${provider.first_name} ${provider.last_name}`;
			}
		}
	};

	return (
		<ChatHeader padding='0 24px' alignCenter spaceBetween>
			<RowFlex alignCenter gap='16px'>
				{width < 1024 ? (
					<StyledButton onClick={backButtonHandler}>
						<Icon color={Colors.primary.primary} icon={faArrowLeft} size='lg' />
					</StyledButton>
				) : undefined}

				<div
					style={{
						width: '60px',
						height: '60px',
						backgroundColor: Colors.theme.dark_accent_background,
						borderRadius: '50%',
					}}
				/>
				<Text
					color={Colors.secondary.black}
					mediumText
					fontWeight='thin'
					style={{
						whiteSpace: 'nowrap',
					}}
				>
					{renderUserName()}
				</Text>
			</RowFlex>
			<Text
				color={Colors.secondary.black}
				mediumText
				fontWeight='thin'
				style={{
					whiteSpace: 'nowrap',
				}}
			>
				Info
			</Text>
		</ChatHeader>
	);
};
const ChatView = (props) => {
	const { selectedConversation, handleSendMessage, loggedInUser, backButtonHandler } = props;

	return (
		<Container>
			<ChatTextWrapperParent id='chat-box'>
				<ChatColumn id='' fullHeight>
					<ChatHeaderView
						loggedInUser={loggedInUser}
						selectedConversation={selectedConversation}
						backButtonHandler={backButtonHandler}
					/>

					<ChatMessagesColumn>
						<ChatMessages
							loggedInUser={loggedInUser}
							messages={selectedConversation ? selectedConversation.messages : []}
							selectedConversation={selectedConversation}
						/>
					</ChatMessagesColumn>
					<div style={{ float: 'left', clear: 'both' }} />
					{selectedConversation && <MessageView handleSendMessage={handleSendMessage} />}
				</ChatColumn>
			</ChatTextWrapperParent>
		</Container>
	);
};

export default ChatView;
