import styled from 'styled-components';

import { colors } from '../../utils';

export const StyledLabel = styled.div`
	padding: 0;
	font-size: 14px;
	color: ${colors.primary.primary};
	letter-spacing: 0px !important;
`;
