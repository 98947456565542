import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	faBriefcase,
	faEnvelope,
	faMousePointer,
	faPercentage,
	faHandsHelping,
	faStopCircle,
} from '@fortawesome/free-solid-svg-icons';

import DescriptivePage from '../../../../../corev2/DescriptivePage';
import Text from '../../../../../corev2/Text';
import { PrimaryButton } from '../../../../../corev2/Buttons';
import JobPostsCategory from '../../components/JobPostsCategory';
import JobPost from '../../components/JobPost';
import Loader from '../../../../../corev2/Loader';

import { jobPostsRoutes } from '../../routes/constants';

import { useAuth } from '../../../core/hooks/useAuth';
import { useFetchRegistryJobPostsQuery } from '../../api/queries/useFetchRegistryJobPostsQuery';
import { useGenerateJobPostContent } from '../../hooks/useGenerateJobPostsContent';

import { StyledJobPostsContainer, StyledSideContent, StyledJobPostsText } from './styles';

const ICONS_MAP = {
	search: faBriefcase,
	invited: faEnvelope,
	applied: faMousePointer,
	offered: faPercentage,
	active: faHandsHelping,
	inactive: faStopCircle,
};

const JobPostsPage = () => {
	const [jobPosts, setJobPosts] = useState([]);
	const { jobPostsCategory } = useParams();
	const history = useHistory();

	const { authCarePro } = useAuth();
	const { data, isLoading: isLoadingJobPosts } = useFetchRegistryJobPostsQuery(1); // registry_id is 1

	const { filteredJobPosts, stats } = useGenerateJobPostContent(jobPosts, authCarePro?.id);

	useEffect(() => {
		if (data) {
			setJobPosts(data);
		}
	}, [data]);

	const goToJobPostsPage = () => {
		history.push(jobPostsRoutes.home);
	};

	const goToJobPostPage = (jobPostId) => {
		history.push(jobPostsRoutes.jobPost(jobPostId));
	};

	const goToJobPostsCategoryPage = (jobPostsCategory) => {
		history.push(jobPostsRoutes.jobPostsCategory(jobPostsCategory));
	};

	const jobJobPostsDescriptions = () => {
		let text = (
			<Text size='large'>
				Explore job posts in your registry. Select a category to view specific job listings
				and details.
			</Text>
		);

		if (jobPostsCategory === 'search') {
			text = (
				<Text size='large'>
					Browse all available jobs in your registry. These jobs are open and you can
					apply to any that suit your preferences and skills.
				</Text>
			);
		}
		if (jobPostsCategory === 'invited') {
			text = (
				<Text size='large'>
					You have been specifically invited to these jobs. Employers believe you are a
					strong candidate and would like you to consider applying.
				</Text>
			);
		}
		if (jobPostsCategory === 'applied') {
			text = (
				<Text size='large'>
					These are the jobs you have already applied to. Check back here to see updates
					or responses from employers.
				</Text>
			);
		}
		if (jobPostsCategory === 'offered') {
			text = (
				<Text size='large'>
					You have been offered these jobs. Review the job details and decide whether to
					accept or decline the offer.
				</Text>
			);
		}
		if (jobPostsCategory === 'active') {
			text = (
				<Text size='large'>
					These are your active jobs where you are currently working. Stay updated on
					ongoing assignments and tasks here.
				</Text>
			);
		}
		if (jobPostsCategory === 'inactive') {
			text = (
				<Text size='large'>
					These jobs are no longer active. They may have been declined, completed, or
					cancelled by the employer.
				</Text>
			);
		}

		return (
			<StyledSideContent>
				{text}

				{jobPostsCategory && (
					<PrimaryButton onClick={goToJobPostsPage} size='small'>
						Categories
					</PrimaryButton>
				)}
			</StyledSideContent>
		);
	};

	if (isLoadingJobPosts) return <Loader />;

	return (
		<DescriptivePage
			heading={`Job Posts${jobPostsCategory ? ` - ${stats[jobPostsCategory].title}` : ''}`}
			descriptionContent={jobJobPostsDescriptions()}
		>
			<StyledJobPostsContainer>
				{!jobPostsCategory &&
					Object.keys(stats).map((categoryKey) => {
						const category = stats[categoryKey];
						return (
							<JobPostsCategory
								key={categoryKey}
								icon={ICONS_MAP[categoryKey]}
								title={category.title}
								description={`Browse ${category.title.toLowerCase()} jobs available in your registry.`}
								goToJobPostsCategoryPage={() =>
									goToJobPostsCategoryPage(category.slug)
								}
								stat={category.stat}
							/>
						);
					})}

				{jobPostsCategory &&
					filteredJobPosts.map((jobPost) => {
						return (
							<JobPost
								key={jobPost.id}
								title={jobPost.title}
								description={jobPost.description}
								goToJobPostPage={() => goToJobPostPage(jobPost.id)}
							/>
						);
					})}

				{jobPostsCategory && filteredJobPosts.length === 0 && (
					<StyledJobPostsText> No job posts in this category. </StyledJobPostsText>
				)}
			</StyledJobPostsContainer>
		</DescriptivePage>
	);
};

export default JobPostsPage;
