import React, { useEffect, useState } from 'react';
import { ParentContainer, PHWhiteCon, PHDColumn } from 'AppComp/provider/ProviderHomeDashboard';
import Title from 'AppComp/Title';
import { isMobile } from 'react-device-detect';
import Colors from 'colors';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import AnimateHeight from 'react-animate-height';
import { ColumnFlex, RowFlex, Avatar } from 'AppComp/styled/Basic';
import Icon from 'AppComp/Icon';

import { CarePlanActives, ShiftSignatureCollection } from './ShiftViews';

const ShiftAdl = (props) => {
	const [showAdl, setShowAdl] = useState(false);
	const { shift } = props;
	return (
		<PHWhiteCon
			style={{
				maxWidth: isMobile ? '100%' : '440px',
				backgroundColor: Colors.lightGrey,
				flex: 3,
				backgroundColor: 'white',
			}}
		>
			<RowFlex fullWidth spaceBetween alignCenter>
				<Title subtitle color={Colors.greenLightTeal} margin='0 0 00px 0' fontWeight='700'>
					ADL
				</Title>
				<div
					style={{
						cursor: 'pointer',
					}}
					onClick={() => setShowAdl(!showAdl)}
				>
					<Icon
						icon={showAdl ? faChevronDown : faChevronRight}
						size='2xl'
						color={Colors.secondary.blue}
					/>
				</div>
			</RowFlex>
			<AnimateHeight duration={500} height={showAdl ? 'auto' : '0%'}>
				<ColumnFlex
					style={{
						borderRadius: '16px',
						backgroundColor: Colors.lightGrey,
						padding: '0px 10px 10px 10px',
					}}
					fullWidth
					margin='10px 0 12px 0'
				>
					<CarePlanActives shift={shift} />
				</ColumnFlex>
			</AnimateHeight>
		</PHWhiteCon>
	);
};

export default ShiftAdl;
