import styled from 'styled-components';

import Colors from 'colors';

export const StyledWrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: calc(100vh - 120px);

	background-color: ${Colors.theme.primary_background};

	padding: 1rem;
`;
