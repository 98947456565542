import 'regenerator-runtime/runtime';

import React from 'react';
import { render } from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { Provider, connect } from 'react-redux';
import { createBrowserHistory, createHashHistory } from 'history';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import App from './components/App';

import createRootReducer from './reducers';
import { rootSaga } from './sagas';

// Stylesheets
import './styles/index.less';

// Redux Store setup
const logger = createLogger({
	level: 'info',
	collapsed: true,
});

//saga middleware
const sagaMiddleware = createSagaMiddleware();

// Create an enhanced history that syncs navigation events with the store
const isProduction = process.env.NODE_ENV === 'production';

//const envHistory = isProduction ? createBrowserHistory() : createHashHistory();
const envHistory = createBrowserHistory();

const middleware = [thunk, routerMiddleware(envHistory), logger, sagaMiddleware];

const store = createStore(
	createRootReducer(envHistory), // root reducer with router state
	compose(applyMiddleware(...middleware))
);

sagaMiddleware.run(rootSaga);

const appMount = document.getElementById('app-mount-point');

render(
	<Provider store={store}>
		<ConnectedRouter history={envHistory}>
			<App />
		</ConnectedRouter>
	</Provider>,
	appMount
);
