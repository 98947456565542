import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import is from 'styled-is';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { ColumnFlex, DropShadow } from 'basicStyles';
import Colors from 'colors';

import { connect } from 'react-redux';
import { receiveOpenChatExternal } from 'reducers/patient';
import { isMobile } from 'react-device-detect';

import PatientChatHandler from './PatientChatHandler';
import AnimationWrapper from '../AnimationWrapper';
import Icon from '../Icon';
import { device } from '../styled/StyledMediaQuery';

const Container = styled.div`
	border-radius: 50%;

	transition: all 0.4s ease-in-out 0.3s;
	position: fixed;
	background-color: ${Colors.fullWhite};

	z-index: 9999900;
	${DropShadow(3)}

	@media ${device.mobileS} {
		height: 50px;
		width: 50px;
		bottom: 20px;
		left: 8px;

		${is('active')`
		left: 40px;
		height: 90%;
		width: 85%;
		border-radius: 6px;
		transition: all .4s ease-in-out;
	`}
	}

	@media ${device.laptop} {
		height: 80px;
		width: 80px;
		bottom: 20px;
		left: 24px;

		${is('active')`
		left: 50px
		height: 500px;
		width: 450px;
		border-radius: 6px;
		transition: all .4s ease-in-out;
	`}
	}
`;

const IconContainer = styled(ColumnFlex)`
	transition: opacity ease 0.3s;
	opacity: 0;
	cursor: pointer;

	&.p-i-appear {
		transition: opacity ease 0.3s;
		opacity: 1;
	}
	&.p-i-enter {
		transition: opacity ease 0.3s;
		opacity: 0;
	}
	&.pi-enter-active {
		transition: opacity ease 0.3s;
		opacity: 1;
	}
	&.p-i-enter-done {
		transition: opacity ease 0.3s;
		opacity: 1;
	}

	&.p-i-exit {
		transition: opacity ease 0.3s;
		opacity: 1;
	}

	&.p-i-exit-acitve {
		transition: opacity ease 0.3s;
		opacity: 1;
	}
	&.p-i-exit-done {
		transition: opacity ease 0.3s;
		opacity: 0;
	}
`;

class PatientChatModel extends Component {
	state = {
		active: false,
	};

	componentDidUpdate(prevProps, props) {
		const { conversation, receiveOpenChatExternal, openChatExternal } = this.props;

		if (
			prevProps.conversation.messages.length > 0 &&
			prevProps.conversation.messages.length < conversation.messages.length
		) {
			this.setState({
				active: true,
			});
		}

		if (openChatExternal && this.state.active === false) {
			this.setState({
				active: true,
			});
			receiveOpenChatExternal(false);
		}
	}

	toggleActiveChat = () => {
		const { active } = this.state;

		this.setState({
			active: !active,
		});
	};

	render() {
		const { active } = this.state;

		return (
			<Container className='pt_chat' active={active}>
				<AnimationWrapper status={active} classNames='p-c'>
					<PatientChatHandler toggleActiveChat={this.toggleActiveChat} />
				</AnimationWrapper>
				<AnimationWrapper status={!active} classNames='p-i' appear>
					<IconContainer fullHeight center onClick={() => this.toggleActiveChat()}>
						<Icon
							icon={faComments}
							size={isMobile ? '1x' : '2x'}
							color={Colors.greenLightTeal}
						/>
					</IconContainer>
				</AnimationWrapper>
			</Container>
		);
	}
}

const mapStateToProps = ({ patient }) => {
	return {
		openChatExternal: patient.openChatExternal,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		receiveOpenChatExternal: (status) => dispatch(receiveOpenChatExternal(status)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientChatModel);
