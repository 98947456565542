import React, { useState, useEffect } from 'react';

import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

import Text from 'AppComp/Text';
import { ColumnFlex } from 'basicStyles';
import Button from 'AppComp/Button';
import api from '../../../scripts/api';
import Title from 'AppComp/Title';

const Email = (props) => {
	const { verification, provider } = props;

	const resendEmailVerification = async () => {
		try {
			const graphResponse = await api.graph({
				query: `mutation {
					reSendProviderVerification
				}`,
			});
			toast.success('Email Sent');
			console.log('graphResponse', graphResponse);
		} catch (err) {
			console.log('err', err);
		}
	};

	return (
		<>
			<Title color={'black'} bold subtitle>
				Email Verification
			</Title>
			<ColumnFlex
				style={{
					maxWidth: '320px',
				}}
				padding='0 0 12px 0'
				margin='20px auto 0 auto'
				center
				gap='12px'
			>
				<Text
					style={{
						textAlign: 'center',
					}}
					midLarge
				>
					{`Check your email for a verification link (${provider.email})`}
				</Text>
				<Text
					style={{
						textAlign: 'left',
					}}
					underline
					pointer
					onClick={() => resendEmailVerification()}
				>
					(Resend Email)
				</Text>
			</ColumnFlex>
		</>
	);
};

export default Email;
