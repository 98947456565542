import { useMutation } from '@tanstack/react-query';

import api from 'scripts/api';

import { TRAIT_TEMPLATE_SECTION_MODEL } from '../models';

const editSection = async ({ sectionId, sectionLabel, selected_index }) => {
	try {
		console.log('oxselected_index', selected_index);
		const data = await api.graph({
			query: `mutation {
        editSection ( 
          section_id: ${sectionId}, 
          ${sectionLabel ? `section_label: "${sectionLabel}",` : ''}
          ${selected_index || selected_index === 0 ? `selected_index: ${selected_index}` : ''}
        ) {
          ${TRAIT_TEMPLATE_SECTION_MODEL}
        }
      }`,
		});

		return data.editSection;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

const useEditSection = () => {
	return useMutation({
		mutationKey: ['editSection'],
		mutationFn: (values) => {
			return editSection(values);
		},
	});
};

export default useEditSection;
