import styled from 'styled-components';

import Colors from 'colors';

import { device } from 'AppComp/styled/StyledMediaQuery';

export const StyledButton = styled.button`
	width: 80px;
	height: 30px;

	background: ${({ isTransparent }) => (isTransparent ? 'inherit' : Colors.primary.new_primary)};
	border: 1px solid ${Colors.primary.new_primary};
	border-radius: 18px;

	font-size: 0.75rem;
	color: ${({ isTransparent }) => (isTransparent ? Colors.primary.new_primary : 'white')};

	@media ${device.tablet} {
		width: 100px;
		height: 40px;

		font-size: 0.875rem;
	}
`;
