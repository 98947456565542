const uploadFilesToAWSS3 = async (files) => {
	const identifyPoolId = 'us-east-1:66059173-f49e-413c-840a-3be0bd12aa8f';
	const bucketRegion = 'us-east-1';
	const bucketName = 'documents-uploads-2';

	AWS.config.update({
		region: bucketRegion,
		credentials: new AWS.CognitoIdentityCredentials({
			IdentityPoolId: identifyPoolId,
		}),
	});

	const s3 = new AWS.S3({
		apiVersion: '2012-10-17',
		params: { Bucket: bucketName },
	});

	const uploadPromises = Object.keys(files).map(async (id) => {
		const { file, expirationDate } = files[id];
		const key = `${Date.now()}-${file.name}`;
		const fileType = file.type;

		// Upload the file to S3
		const data = await s3
			.upload({
				Key: key,
				Body: file,
				ACL: 'public-read',
				ContentType: fileType,
			})
			.promise();

		const fileLocation = data.Location;

		return {
			...files[id],
			uploaded: true,
			url: fileLocation,
		};
	});

	const uploadedFiles = await Promise.all(uploadPromises);
	return uploadedFiles.reduce((acc, file, index) => {
		const fileId = Object.keys(files)[index];
		acc[fileId] = file;
		return acc;
	}, {});
};

export default uploadFilesToAWSS3;
