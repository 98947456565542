//careplan.js
import api from '../scripts/api';

const initialState = {
	selectedCarePlan: null,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		default:
			return state;
	}
}
