import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { ColumnFlex, RowFlex, DropShadow } from 'basicStyles';

const RegistryOnboardParent = (props) => {
	const { patient, carePlan, accountStatus } = props;

	console.log('carePlan', carePlan);
	if (carePlan === null) {
		return null;
	}

	if (carePlan.completed_platform_onboard === false) {
		return null;
	}
	if (
		carePlan.completed_platform_onboard === true &&
		carePlan.completed_registry_onboard === true
	) {
		return null;
	}

	// the below should only show when the user has completed platform onboarding but not registry onboarding
	return (
		<RowFlex
			style={{
				height: '40px',
				width: '100vw',

				backgroundColor: '#2089d5',
				position: 'relative',
			}}
			center
		>
			<RowFlex
				style={{
					height: '40px',
					width: '100vw',
					top: 0,
					backgroundColor: '#2089d5',
					position: 'fixed',
					zIndex: 1000,
				}}
				center
			>
				Pending Registry Approval
			</RowFlex>
		</RowFlex>
	);
};

const mapStateToProps = ({ admin, patient, environment }, props) => {
	return {
		patient: patient.patient,
		accountStatus: patient.accountStatus,
		carePlan: patient.carePlan,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistryOnboardParent);
