import React from 'react';

import PageHeading from '../PageHeading';

import { StyledDescriptivePage, StyledHeadingSection } from './styles';

const DescriptivePage = (props) => {
	const { heading, descriptionContent, children } = props;

	return (
		<StyledDescriptivePage>
			<StyledHeadingSection>
				<PageHeading>{heading}</PageHeading>

				{descriptionContent}
			</StyledHeadingSection>

			{children}
		</StyledDescriptivePage>
	);
};

export default DescriptivePage;
