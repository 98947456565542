import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const initialStats = {
	search: {
		title: 'Search',
		stat: 0,
		isActive: false,
		slug: 'search',
	},
	invited: {
		title: 'Invited',
		stat: 0,
		isActive: false,
		slug: 'invited',
	},
	applied: {
		title: 'Applied',
		stat: 0,
		isActive: false,
		slug: 'applied',
	},
	offered: {
		title: 'Offered',
		stat: 0,
		isActive: false,
		slug: 'offered',
	},
	active: {
		title: 'Active',
		stat: 0,
		isActive: false,
		slug: 'active',
	},
	inactive: {
		title: 'Inactive',
		stat: 0,
		isActive: false,
		slug: 'inactive',
	},
};

export const useGenerateJobPostContent = (incomingJobPosts, careProId) => {
	const [stats, setStats] = useState(initialStats);
	const [jobPosts, setJobPosts] = useState([]);
	const { jobPostsCategory } = useParams();

	useEffect(() => {
		if (incomingJobPosts) {
			// Filter invited jobs
			const invitedJobs = incomingJobPosts.filter((jobPost) =>
				jobPost.applications.some((application) => application.status === 'invited')
			);

			// Filter offered jobs
			const offeredJobs = incomingJobPosts.filter((jobPost) =>
				jobPost.jobs.some((job) => job.status === 'offer' && job.offer_status === 'offered')
			);

			// Filter applied jobs, excluding jobs that are already in the offered category
			const appliedJobs = incomingJobPosts.filter(
				(jobPost) =>
					!offeredJobs.includes(jobPost) &&
					jobPost.applications.some(
						(application) =>
							application.status === 'applied' &&
							application.provider_id === careProId
					)
			);

			// Filter active jobs
			const activeJobs = incomingJobPosts.filter((jobPost) =>
				jobPost.jobs.some((job) => job.status === 'job' && job.job_status === 'active')
			);

			// Filter inactive (declined) jobs
			const inactiveJobs = incomingJobPosts.filter((jobPost) =>
				jobPost.jobs.some(
					(job) => job.status === 'offer' && job.offer_status === 'rejected'
				)
			);

			// Filter other jobs that are neither applied, invited, offered, active, nor inactive
			const otherJobs = incomingJobPosts.filter(
				(jobPost) =>
					!invitedJobs.includes(jobPost) &&
					!appliedJobs.includes(jobPost) &&
					!offeredJobs.includes(jobPost) &&
					!activeJobs.includes(jobPost) &&
					!inactiveJobs.includes(jobPost)
			);

			setJobPosts({
				invited: invitedJobs,
				applied: appliedJobs,
				offered: offeredJobs,
				active: activeJobs,
				inactive: inactiveJobs,
				other: otherJobs,
			});

			// Update the stats
			setStats((prevStats) => ({
				...prevStats,
				search: {
					...prevStats.search,
					stat: otherJobs.length,
				},
				invited: {
					...prevStats.invited,
					stat: invitedJobs.length,
				},
				applied: {
					...prevStats.applied,
					stat: appliedJobs.length,
				},
				offered: {
					...prevStats.offered,
					stat: offeredJobs.length,
				},
				active: {
					...prevStats.active,
					stat: activeJobs.length,
				},
				inactive: {
					...prevStats.inactive,
					stat: inactiveJobs.length,
				},
			}));
		}
	}, [incomingJobPosts, careProId]);

	useEffect(() => {
		// Update the active state based on the URL parameter
		setStats((prevStats) => {
			const newStats = { ...prevStats };
			Object.keys(newStats).forEach((key) => {
				newStats[key] = {
					...newStats[key],
					isActive: key === jobPostsCategory,
				};
			});
			return newStats;
		});
	}, [jobPostsCategory]);

	const filteredJobPosts = useMemo(() => {
		if (stats.search.isActive) {
			return jobPosts.other;
		}

		if (stats.invited.isActive) {
			return jobPosts.invited;
		}

		if (stats.applied.isActive) {
			return jobPosts.applied;
		}

		if (stats.offered.isActive) {
			return jobPosts.offered;
		}

		if (stats.active.isActive) {
			return jobPosts.active;
		}

		if (stats.inactive.isActive) {
			return jobPosts.inactive;
		}

		return [];
	}, [
		stats.search.isActive,
		stats.invited.isActive,
		stats.applied.isActive,
		stats.offered.isActive,
		stats.active.isActive,
		stats.inactive.isActive,
		jobPosts,
	]);

	return { filteredJobPosts, stats };
};
