import React, { Component, Fragment } from 'react';

import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import {
	receiveForgotPasswordStatus,
	receiveNewPasswordStatus,
	showInfoModel,
} from 'reducers/environment';

import api from '../scripts/api';

import PaymentError from '../components/patient/PaymentError';

import {
	CAREPLAN_MODEL_V2,
	PROVIDER_MODEL_V2,
	PATIENT_MODEL_V2,
	PAYMENT_METHOD_TYPE_MODEL,
	CONVERSATION_MODEL,
	MESSAGE_MODEL,
	DOCUMENT_MODEL,
	CORE_JOB_MODEL,
	CARE_NEEDS_MODEL,
} from './graphqlModelTypes';

import {
	USER_PROPOSED_SCHEDULE_CHANGE_TYPES,
	UPDATE_CARE_PLAN_PAITENT_INFO_TYPES,
	PT_INITAL_DATA_FETCH,
} from './paitentReducerHelper';
import { UserRegisterWelcomeToast } from '../components/patient/UserOnboardSteps/UserOnboardStepsStyles';

const initialState = {
	nurses: [],
	carePlan: null,

	patient: null,
	loading: false,
	totalPages: 0,
	currentPage: 0,
	nursesPerPage: 5,
	showOnboardOverlay: false,
	registerOnboard: false,
	savedPaymentMethods: [],
	conversations: [],
	payments: [],
	showPaymentAuthModel: false,

	showCollectCardModel: false,
	openChatExternal: false,
	accountStatus: null,

	// extending the reducer to have this so the nav bar can always be sync up in regards to if the user has en exisiting connected provdier
	jobs: [],
	jobPost: [],
	uploadedDocuments: [],

	// we're going to add some state to keep track of loading
	// this should help knowing when somethign is fetching in order to show or not show certain views or at least know
	fetchingClientSession: {
		status: false,
		attempts: 0,
		error: false,
		errorMessage: null,
	},
	fetchingCarePlan: {
		status: false,
		attempts: 0,
		error: false,
		errorMessage: null,
	},
};

export const receivePaymentMethods = createAction('RECEIVE_PAYMENT_METHODS');

export const toggleRegisterOnboard = createAction('TOGGLE_REGISTER_ONBOARD');
export const toggleOnboardOverlay = createAction('TOGGLE_ONBOARD_OVERLAY');
export const setTotalPages = createAction('SET_TOTAL_PAGES');
export const setCurrentPage = createAction('SET_CURRENT_PAGE');
//handles updaitng patient personal info
export const receivePatientInfo = createAction('RECEIVE_PATIENT_INFO');
//hanldes updating patient conversations
export const receivePatientConversations = createAction('RECEIVE_PATIENT_CONVERSATIONS');
//handles updating patient careplan
export const receivePatientCarePlan = createAction('RECEIVE_PATIENT_CAREPLAN');
export const receivePayments = createAction('RECEIVE_PAYMENTS');
export const receiveOpenChatExternal = createAction('RECEIVE_OPEN_CHAT_EXTERNAL');

export const updatePaymentAuthModelStatus = createAction('RECEIVE_PAYMENT_AUTH_MODEL_STATUS');
export const updateCardCollectModelStatus = createAction('UPDATE_CARD_COLLECTION_MODEL_STATUS');

export const receiveAccountStatus = createAction('RECEIVE_ACCOUNT_STATUS');

export const receiveUploadedDocuments = createAction('RECEIVE_UPLOADED_DOCUMENTS');

export const receiveFetchingClientSessionStatus = createAction('RECEIVE_FETCHING_CLIENT_SESSION');
export const receiveFetchingCarePlanStatus = createAction('RECEIVE_FETCHING_CARE_PLAN');

export default handleActions(
	{
		[receiveFetchingClientSessionStatus](state, { payload }) {
			return {
				...state,
				fetchingClientSession: {
					...state.fetchingClientSession,
					...payload,
				},
			};
		},
		[receiveFetchingCarePlanStatus](state, { payload }) {
			return {
				...state,
				fetchingCarePlan: {
					...state.fetchingCarePlan,
					...payload,
				},
			};
		},
		[receiveUploadedDocuments](state, { payload }) {
			return {
				...state,
				uploadedDocuments: payload,
			};
		},

		[receiveAccountStatus](state, { payload }) {
			return {
				...state,
				accountStatus: payload,
			};
		},
		[receiveOpenChatExternal](state, { payload }) {
			return {
				...state,
				openChatExternal: payload,
			};
		},
		[updateCardCollectModelStatus](state, { payload }) {
			return {
				...state,
				showCollectCardModel: payload,
			};
		},

		[updatePaymentAuthModelStatus](state, { payload }) {
			return {
				...state,
				showPaymentAuthModel: payload,
			};
		},
		[receivePayments](state, { payload }) {
			return {
				...state,
				payments: payload,
			};
		},
		[receivePaymentMethods](state, { payload }) {
			return {
				...state,
				savedPaymentMethods: payload,
			};
		},

		[toggleRegisterOnboard](state, { payload }) {
			return {
				...state,
				registerOnboard: payload,
			};
		},
		[setCurrentPage](state, { payload }) {
			return {
				...state,
				currentPage: payload,
			};
		},
		[toggleOnboardOverlay](state, { payload }) {
			return {
				...state,
				showOnboardOverlay: payload,
			};
		},
		[receivePatientInfo](state, { payload }) {
			return {
				...state,
				patient: payload,
			};
		},
		[receivePatientConversations](state, { payload }) {
			return {
				...state,
				conversations: payload,
			};
		},
		[receivePatientCarePlan](state, { payload }) {
			return {
				...state,
				carePlan: payload,
			};
		},
		[setTotalPages](state, { payload }) {
			return {
				...state,
				totalPages: payload,
			};
		},
	},
	initialState
);

const toastErrorConfig = {
	containerId: 'basic',
	autoClose: false,
};

const toastSuccessConfig = {
	containerId: 'basic',
	autoClose: true,
};

//handles updating all the patient info with the redux state when a user first is loaded Register
//split into three different dispatch func so each part could be updated independently if needed
export function receivePatient(user) {
	return (dispatch) => {
		const {
			id,
			email,
			phone_number,
			stripe_customer_id,
			carePlan,
			conversations,
			name,
			address,
			stripe_token,
			created_timestamp,
			payment_methods,
			payments,
		} = user;
		//
		if (payments) dispatch(receivePayments(payments));

		//
		dispatch(receivePaymentMethods(payment_methods));
		//
		if (carePlan) {
			dispatch(receivePatientCarePlan(carePlan));
		}

		//
		dispatch(receivePatientConversations(conversations));
		//
		dispatch(
			receivePatientInfo({
				id,
				email,
				phone_number,
				stripe_customer_id,
				name,
				address,
				stripe_token,
				created_timestamp,
			})
		);
	};
}

/* 
	MUTATIONS
*/

export function updatePatietnDefaultPaymentMethod({ user_id, source_id }) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				updatePatietnDefaultPaymentMethod( payment_method_id: "${source_id}") {
					${PAYMENT_METHOD_TYPE_MODEL}
				}
			}`,
		})
			.then((res) => {
				console.log('updatePatietnDefaultPaymentMethod', updatePatietnDefaultPaymentMethod);
				toast.success('Default payment method updated.', toastSuccessConfig);
				dispatch(receivePaymentMethods(res.updatePatietnDefaultPaymentMethod));
			})
			.catch((err) => {
				console.log('updatepatientdefaultPaymentMethod err', err);
				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);
				}
			});
	};
}

export function verifyStripePayment(shift_id) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				verifyStripePayment(shift_id: ${shift_id}) {
					${VERIFY_STRIPE_PAYMENT_HELPER}
				}
			}`,
		})
			.then((res) => {
				console.log('verifyStripePayment -> res: ', res.loginUser);
				const { payment_methods, payments, verification } = res.loginUser;

				// Update the verification status
				dispatch(receiveAccountStatus(verification));

				// Update the payment methods tied to user
				dispatch(receivePaymentMethods(payment_methods));

				// update payments
				if (payments) dispatch(receivePayments(payments));
			})
			.catch((err) => {
				console.log('verifyStripePayment-> err: ', err);

				console.log('verifyStripePayment -> err: ', err);

				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);
				}
			});
	};
}
export const createUserStripeIntent = async () => {
	try {
		const intent = await api.graph({
			query: `mutation { createUserStripeIntent }`,
		});
		console.log('intent', intent);
		return intent;
	} catch (err) {
		console.log('createUserStripeIntent err', err);
		if (Array.isArray(err)) {
			toast.error(err[0].message, toastErrorConfig);
		} else {
			toast.error('Something went wrong please notify support', toastErrorConfig);
		}
	}
};

export function deletePaymentMethod({ source_id, user_id }) {
	return (dispatch) => {
		console.log('pre api', dispatch);

		api.graph({
			query: `mutation {
				deletePaymentMethod(
					source_id: "${source_id}",
					user_id: ${user_id}
					user_type: "patient"
				) {
					${PAYMENT_METHOD_TYPE_MODEL}
				}
			}`,
		})
			.then((res) => {
				dispatch(receivePaymentMethods(res.deletePaymentMethod));
				toast.success('Payment method deleted', toastSuccessConfig);
			})
			.catch((err) => {
				console.log('deletePaymentMethod err', err);
				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);
				}
			});
	};
}

export function changePatientPassword(password) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				changePatientPassword(password: "${password}") {
					id
				}
			}`,
		})
			.then((res) => {
				dispatch(
					receiveNewPasswordStatus({
						status: true,
						message: 'Password has been updated',
					})
				);
			})
			.catch((err) => {
				console.log('err');

				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);
				}
			});
	};
}

export function forgotPatientPassword(email) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				forgotPassword(
					email: "${email}",
					account_type: "patient"
				)
			}`,
		})
			.then((res) => {
				dispatch(
					receiveForgotPasswordStatus({
						status: true,
						message: 'Password reset link has been sent, Please check your email',
					})
				);
			})
			.catch((err) => {
				console.log('err');

				dispatch(
					receiveForgotPasswordStatus({
						status: true,
						message: 'Password reset link has been sent, Please check your email',
					})
				);
			});
	};
}

/*
 * logoutUser
 * params none
 * return string
 */

export function logoutUser() {
	return (dispatch) => {
		api.graph({
			query: `mutation{
				logoutUser
			}`,
		})
			.then((res) => {
				localStorage.removeItem('carePlanId');
				localStorage.removeItem('user-token');
				dispatch({ type: 'PATIENT_LOGOUT' });

				dispatch(push('/'));
			})
			.catch((err) => {
				console.log('logoutUser err', err);
				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);
				}
			});
	};
}
/*
 * updatePatient mutaion
 * params { userId: int! userInput: obj!}
 * userInput { email: string, phone_number: string, address: string }
 * returns a patientType
 */
export function updatePatient({ userId, userInput }) {
	return (dispatch) => {
		api.graph({
			query: `mutation{
				updatePatient(
					userId: ${userId},
					userInput: ${api.graphStringify(userInput)}
				) {
					${PATIENT_MODEL_V2}
					payment_methods {
						${PAYMENT_METHOD_TYPE_MODEL}
					},
				}
			}`,
		})
			.then((res) => {
				dispatch(receivePatientInfo(res.updatePatient));
				if (res.updatePatient.payment_methods) {
					toast.success('New payment method added.', toastSuccessConfig);
					dispatch(receivePaymentMethods(res.updatePatient.payment_methods));
				}
			})
			.catch((err) => {
				console.log('updatePatient update');
				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);
				}
			});
	};
}

/* 
	QUERY	
*/

export function fetchUserSavedPayments() {
	return (dispatch) => {
		api.graph({
			query: `{
				checkUserSession{
					payment_methods {
						${PAYMENT_METHOD_TYPE_MODEL}
					},
				}
			}`,
		})
			.then((res) => {
				console.log('fetchUserSavedPayments -> res: ', res.checkUserSession);

				dispatch(receivePaymentMethods(res.checkUserSession.payment_methods));
			})
			.catch((err) => {
				console.log('fetchUserSavedPayments-> err: ', err);
				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);
				}
			});
	};
}

export function deleteGenericSession({ id, closeToast }) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				deleteGenericSession(
					blocked_time_id: ${id}
				) {
					${CAREPLAN_MODEL_V2}
				
		
				}
			}`,
		})
			.then((res) => {
				console.log('deleteGenericSession -> res: ', res);

				dispatch(receivePatientCarePlan(res.deleteGenericSession));
				toast.success('Generic session deleted', toastSuccessConfig);
				closeToast(false);
			})
			.catch((err) => {
				console.log('deleteGenericSession -> err: ', err);

				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);
				} else {
					toast.error(
						'Something went wrong deleting session, please notify support',
						toastErrorConfig
					);
				}
			});
	};
}

export function fetchCareplan(carePlanId) {
	return (dispatch, getState) => {
		const { fetchingCarePlan } = getState().patient;
		dispatch(receiveFetchingCarePlanStatus({ status: true, attempts: 0 }));
		api.graph({
			query: `{
				fetchCareplan(care_plan_id: ${carePlanId}) {
					${CAREPLAN_MODEL_V2}
					carePlanSet {
						id,
						label,
						
						setData {
							id,
							label,
							required,
							description,
							trait_type,
							value {
								id,
								attribute_trait_id,
								attribute_set_id,
								trait_value,
								last_edited,
								
							}
						}
					}
					user {
						text_verification,
					}
				

				}
			}`,
		})
			.then((res) => {
				console.log('fetchCareplan -> res', res);

				if (res.fetchCareplan) {
					dispatch(
						receiveFetchingCarePlanStatus({
							status: false,
							attempts: fetchingCarePlan.attempts + 1,
						})
					);
					dispatch(receivePatientInfo(res.fetchCareplan.user));
					dispatch(receivePatientCarePlan(res.fetchCareplan));

					localStorage.setItem('carePlanId', res.fetchCareplan.id);
				} else {
					console.log('somehow fetched a care plan that doesnt exist');
					// dispatch(createGuestCareplan());
				}
			})
			.catch((err) => {
				console.log('fetchCareplan -> err', err);
				dispatch(
					receiveFetchingCarePlanStatus({
						status: false,
						attempts: fetchingCarePlan.attempts + 1,
						error: true,
					})
				);
				if (Array.isArray(err)) {
					//toast.error(err[0].message, toastErrorConfig);
				} else {
					//toast.error('Something went wrong please notify support', toastErrorConfig);
				}
			});
	};
}

// here we want to fetch the most immediate care plan info that is needed almost at a high level
// that would mainly be the care plan set data (needs and plan)

export function fetchUserCarePlan() {
	return (dispatch, getState) => {
		dispatch(receiveFetchingClientSessionStatus({ status: true }));
		api.graph({
			query: `{
				checkUserSession {
					
					carePlan {
						id,
					
						
					
						
					
						
						
						completed_registry_onboard
						completed_platform_onboard
					
						completed_care_plan
						careNeedsSet {
							${CARE_NEEDS_MODEL}
						}
						carePlanSet {
							${CARE_NEEDS_MODEL}
						}
					
						
						
					},
				}
			}`,
		})
			.then((res) => {
				console.log('fetchUserCarePlan', res);
				const { carePlan, payment_methods } = res.checkUserSession;
				//console.log('checkUserSession -> carePlan', carePlan);

				const { fetchingCarePlan } = getState().patient;

				if (carePlan) {
					localStorage.setItem('carePlanId', carePlan.id);
					dispatch(receivePatientCarePlan(carePlan));

					// get the current loading state

					// update the loading state
					dispatch(
						receiveFetchingCarePlanStatus({
							error: false,
							status: false,
							attempts: fetchingCarePlan.attempts + 1,
						})
					);
				} else {
					localStorage.removeItem('carePlanId');
					dispatch(
						receiveFetchingCarePlanStatus({
							error: true,
							status: false,
							attempts: fetchingCarePlan.attempts + 1,
							errorMessage: 'No care plan found',
						})
					);
				}
			})
			.catch((err) => {
				const { fetchingCarePlan } = getState().patient;

				console.log('fetchUserCarePlan -> err', err);
				localStorage.removeItem('carePlanId');
				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);

					dispatch(
						receiveFetchingCarePlanStatus({
							error: true,
							status: false,
							attempts: fetchingCarePlan.attempts + 1,
							errorMessage: err[0].message,
						})
					);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);

					dispatch(
						receiveFetchingCarePlanStatus({
							error: true,
							status: false,
							attempts: fetchingCarePlan.attempts + 1,
							errorMessage: 'Something went wrong please notify support',
						})
					);
				}
			});
	};
}
export function fetchUserConversation() {
	return (dispatch) => {
		api.graph({
			query: `{
				checkUserSession {
					conversations {
						${CONVERSATION_MODEL}
						messages {
								${MESSAGE_MODEL}
						}
					},
				}
			}`,
		})
			.then((res) => {
				console.log('fetchUserConversation -> res', res);
				dispatch(receivePatientConversations(res.checkUserSession.conversations));
				dispatch({ type: 'PATIENT_LOGIN' });
			})
			.catch((err) => {
				console.log('fetchUserConversation -> err', err);
			});
	};
}

export function userContactCarePro({ careProId, userId }) {
	return (dispatch, getState) => {
		api.graph({
			query: `mutation{
				userContactCarePro (
					provider_id: ${careProId},
					user_id: ${userId},
				) {
						${CONVERSATION_MODEL}
						messages {
							${MESSAGE_MODEL}
						}
					},
			}`,
		})
			.then((res) => {
				console.log(careProId, userId);

				const { conversations } = getState().patient;
				//dispatch({ type: 'PATIENT_CONVERSATION_UPDATE_DOWN' });
				console.log('userContactCarePro -> res', res);
				// create a new conversations array
				dispatch;
				const _temp = [...conversations, res.userContactCarePro];

				dispatch(receivePatientConversations(_temp));
				dispatch({ type: 'PATIENT_CONVERSATION_UPDATED' });
				dispatch(push(`/chat/${res.userContactCarePro.id}`));
			})
			.catch((err) => {
				console.log('fetchUserConversation -> err', err);
			});
	};
}
export function patientSendMessage({ conversation_id, text, providerId }) {
	return (dispatch) => {
		api.graph({
			query: `mutation{
				patientSendMessage(
					conversation_id: ${conversation_id},
					text: "${text}",
					${
						providerId
							? `
							provider_id: ${providerId},
						
						`
							: ``
					}
				)
			}`,
		})
			.then((res) => {
				console.log('patientSendMessage res', res);
			})
			.catch((err) => {
				console.log('patientSendMessage err ', err);
			});
	};
}

export function fetchCarePlanUploadedDocuments(care_plan_id) {
	console.log('fetchCarePlanUploadedDocuments', care_plan_id);
	return (dispatch) => {
		api.graph({
			query: `{
				fetchCarePlanUploadedDocuments(care_plan_id: ${care_plan_id}) {
					${DOCUMENT_MODEL}
				}
			}`,
		})
			.then((res) => {
				console.log('fetchCarePlanUploadedDocuments -> res', res);
				if (res.fetchCarePlanUploadedDocuments) {
					dispatch(receiveUploadedDocuments(res.fetchCarePlanUploadedDocuments));
				}
			})
			.catch((err) => {
				console.log('fetchCarePlanUploadedDocuments -> err', err);
			});
	};
}

export function clientUploadDocument({ user_id, document_url, document_name, care_plan_id }) {
	return (dispatch) => {
		api.graph({
			query: `mutation{
				clientUploadDocument(
					user_id: ${user_id},
					document_url: "${document_url}",
					document_name: "${document_name}",
					care_plan_id: ${care_plan_id},

				) {
					${DOCUMENT_MODEL}
				}
			}`,
		})
			.then((res) => {
				console.log('clientUploadDocument -> res', res);
				if (res.clientUploadDocument) {
					toast.success(
						'Document Uploaded (pending office approval)',
						toastSuccessConfig
					);
					dispatch(receiveUploadedDocuments(res.clientUploadDocument));
				}
			})
			.catch((err) => {
				console.log('clientUploadDocument -> err', err);
			});
	};
}

export function checkUserSession() {
	return (dispatch, getState) => {
		dispatch(receiveFetchingClientSessionStatus({ status: true }));
		api.graph({
			query: `{
				checkUserSession{
					${PT_INITAL_DATA_FETCH}
				}
			}`,
		})
			.then((res) => {
				console.log('checkUserSession -> res: ', res.checkUserSession);
				//dispatch(receivePatient(res.checkUserSession));

				const {
					payment_methods,

					verification,
					id,
					email,
					phone_number,
					stripe_customer_id,
					name,
					created_timestamp,
					care_plan_id,
					carePlan,
					payments,
				} = res.checkUserSession;

				// update basic user profile information
				dispatch(
					receivePatientInfo({
						id: id,
						email: email,
						phone_number: phone_number,
						stripe_customer_id: stripe_customer_id,
						name: name,
						created_timestamp: created_timestamp,
						carePlan: carePlan,
					})
				);

				// Update the verification status
				//dispatch(receiveAccountStatus(verification));

				if (verification) {
					/*
					todo: add back when in prod
					if (!verification.valid) {
						toast(
							({ closeToast }) => (
								<UserRegisterWelcomeToast closeToast={closeToast} />
							),

							{
								autoClose: false,
								hideProgressBar: false,
								style: { padding: '2px' },
								draggable: false,
								closeOnClick: false,
								closeButton: true,
								position: 'top-center',
								containerId: 'LargeToast',
								toastId: 'newGeneric',
							}
						);
					}
					*/
				}

				// Update the payment methods tied to user
				dispatch(receivePaymentMethods(payment_methods));

				if (payment_methods.length === 0) {
					//toast.success('Save a card to get matched!', toastSuccessConfig);
				}

				// update payments
				//if (payments) dispatch(receivePayments(payments));

				// if the user has a carePlan add it to local storage
				if (carePlan) localStorage.setItem('carePlanId', carePlan.id);

				// need a way to check if the user has a carePlanProviderMatch and active credit card

				// if (activeProviders && payment_methods.length === 0) {
				// 	dispatch(updateCardCollectModelStatus(true));
				// }

				// need a way to check if the user has a carePlanProviderMatch and active credit card

				// Start fetching the user conversation
				dispatch(fetchUserConversation());

				// Start fetching the user careplan
				dispatch(fetchUserCarePlan());

				// get the current loading state
				const { fetchingClientSession } = getState().patient;
				// update the loading state
				dispatch(
					receiveFetchingClientSessionStatus({
						error: false,
						status: false,
						attempts: fetchingClientSession.attempts + 1,
					})
				);
			})
			.catch((err) => {
				console.log('checkUserSession-> err: ', err);

				var carePlanId = localStorage.getItem('carePlanId');

				// get the current loading state
				const { fetchingClientSession } = getState().patient;

				if (carePlanId) {
					// if somethign goes wrong here we should play it safe and remove careplan from local storage
					localStorage.removeItem('carePlanId');
				}

				if (Array.isArray(err)) {
					//toast.error(err[0].message, toastErrorConfig);

					dispatch(
						receiveFetchingClientSessionStatus({
							error: true,
							errorMessage: err[0].message,
							status: false,
							attempts: fetchingClientSession.attempts + 1,
						})
					);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);
					dispatch(
						receiveFetchingClientSessionStatus({
							error: true,
							errorMessage: 'Something went wrong please notify support',
							status: false,
							attempts: fetchingClientSession.attempts + 1,
						})
					);
				}
			});
	};
}

export function verifyUserEmail({ user_id, hashed_key }) {
	return (dispatch) => {
		api.graph({
			query: `mutation{
				verifyUserEmail(
					user_id: ${user_id},
					hashed_key: "${hashed_key}"
				) {
					token
					user {
						${PT_INITAL_DATA_FETCH}
					}
				}
			}`,
		})
			.then((res) => {
				console.log('verifyUserEmail -> res: ', res.verifyUserEmail);
				//dispatch(receivePatient(res.checkUserSession));

				if (res.verifyUserEmail.token) {
					localStorage.setItem('user-token', res.verifyUserEmail.token);
				}

				const {
					payment_methods,

					verification,
					id,
					email,
					phone_number,
					stripe_customer_id,
					name,
					created_timestamp,
					care_plan_id,
					payments,
				} = res.verifyUserEmail.user;

				toast.success('Email Verified', toastSuccessConfig);
				// update basic user profile information
				dispatch(
					receivePatientInfo({
						id: id,
						email: email,
						phone_number: phone_number,
						stripe_customer_id: stripe_customer_id,
						name: name,
						created_timestamp: created_timestamp,
					})
				);
				// update payments
				if (payments) dispatch(receivePayments(payments));

				// Update the verification status
				dispatch(receiveAccountStatus(verification));

				// Update the payment methods tied to user
				dispatch(receivePaymentMethods(payment_methods));

				if (payment_methods.length === 0) {
					//toast.success('Save a card to get matched!', toastSuccessConfig);
				}
				// if the user has a carePlan add it to local storage
				if (care_plan_id) localStorage.setItem('carePlanId', care_plan_id);

				// Start fetching the user conversation
				dispatch(fetchUserConversation());

				// Start fetching the user careplan
				dispatch(fetchUserCarePlan());

				dispatch(toggleOnboardOverlay(false));
			})
			.catch((err) => {
				console.log('verifyUserEmail-> err: ', err);

				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);
				}
			});
	};
}
export function editedFilterInfo({ job_post_id }) {
	return (dispatch, getState) => {
		api.graph({
			query: `mutation {
				editedFilterInfo(job_post_id: ${job_post_id}) {
					
				}
			}`,
		});
	};
}

/*
 * updateSetTraits func
 * Takes
 * @set_id: int!
 * @traits
 */

export function updateSetTraits({ traits, set_id }) {
	return (dispatch, getState) => {
		api.graph({
			query: `mutation{
				updateSetTraits(
					set_id: ${set_id},
					traits: ${api.graphStringify(traits)}
				) {
					id
					value {
						trait_value
					}
				}
			}`,
		}).then((res) => {
			console.log('updateSetTraits', res.updateSetTraits);
		});
	};
}

//Login a new user
export function loginUser({ email, password }) {
	return (dispatch, getState) => {
		// update the loading state so the platform knows to show a spinner

		//receiveFetchingClientSessionStatus({ status: true });
		api.graph({
			query: `mutation{
				loginUser(
					email: "${email}",
					password: "${password}"
				) {
					token
					user {
						${PT_INITAL_DATA_FETCH}
					}
					
				},
			}`,
		})
			.then((res) => {
				if (res.loginUser.token) {
					localStorage.setItem('user-token', res.loginUser.token);
				}

				const {
					payment_methods,

					verification,
					id,
					email,
					phone_number,
					stripe_customer_id,
					name,
					created_timestamp,
					care_plan_id,
					payments,
				} = res.loginUser.user;

				// update basic user profile information
				dispatch(
					receivePatientInfo({
						id: id,
						email: email,
						phone_number: phone_number,
						stripe_customer_id: stripe_customer_id,
						name: name,
						created_timestamp: created_timestamp,
					})
				);
				// update payments
				if (payments) dispatch(receivePayments(payments));

				// Update the verification status
				dispatch(receiveAccountStatus(verification));

				// if (verification) {
				// 	if (!verification.valid) {
				// 		toast(
				// 			({ closeToast }) => (
				// 				<UserRegisterWelcomeToast closeToast={closeToast} />
				// 			),

				// 			{
				// 				autoClose: false,
				// 				hideProgressBar: true,
				// 				style: { padding: '2px' },
				// 				draggable: false,
				// 				closeOnClick: false,
				// 				closeButton: true,
				// 				position: 'top-center',
				// 				containerId: 'LargeToast',
				// 				toastId: 'newGeneric',
				// 			}
				// 		);
				// 	}
				// }

				// Update the payment methods tied to user
				dispatch(receivePaymentMethods(payment_methods));

				if (payment_methods.length === 0) {
					//toast.success('Save a card to get matched!', toastSuccessConfig);
				}
				// if the user has a carePlan add it to local storage
				if (care_plan_id) localStorage.setItem('carePlanId', care_plan_id);

				// need a way to check if the user has a carePlanProviderMatch and active credit card
				// if (carePlanProviderMatch && payment_methods) {
				// 	if (carePlanProviderMatch) {
				// 		const activeProviders = carePlanProviderMatch.find((d, i) => {
				// 			return d.status === 'active';
				// 		});

				// 		if (activeProviders && payment_methods.length === 0) {
				// 			dispatch(updateCardCollectModelStatus(true));
				// 		}
				// 	}
				// }
				// need a way to check if the user has a carePlanProviderMatch and active credit card

				// Start fetching the user conversation
				dispatch(fetchUserConversation());

				// Start fetching the user careplan
				dispatch(fetchUserCarePlan());

				dispatch(toggleOnboardOverlay(false));

				// update the loading state

				// get the current loading state
				const { fetchingClientSession } = getState().patient;
				// update the loading state
				dispatch(
					receiveFetchingClientSessionStatus({
						error: false,
						status: false,
						attempts: fetchingClientSession.attempts + 1,
					})
				);
			})
			.catch((err) => {
				const { fetchingClientSession } = getState().patient;

				console.log('loginUser-> err: ', err);

				console.log('loginUser -> err: ', err);

				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);

					dispatch(
						receiveFetchingClientSessionStatus({
							error: true,
							errorMessage: err[0].message,
							status: false,
							attempts: fetchingClientSession.attempts + 1,
						})
					);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);
					dispatch(
						receiveFetchingClientSessionStatus({
							error: true,
							errorMessage: 'Something went wrong please notify support',
							status: false,
							attempts: fetchingClientSession.attempts + 1,
						})
					);
				}
			});
	};
}
