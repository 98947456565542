import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { StyledSortButton, StyledSortButtonWrapper, StyledSortIcon } from './styles';

const SortButton = (props) => {
	const { direction } = props;

	return (
		<StyledSortButton>
			{direction === 'ascending' && <FontAwesomeIcon icon={faSortUp} />}
			{direction === 'descending' && <FontAwesomeIcon icon={faSortDown} />}

			{!direction && (
				<>
					<StyledSortButtonWrapper up>
						<StyledSortIcon icon={faSortUp} />
					</StyledSortButtonWrapper>
					<StyledSortButtonWrapper>
						<StyledSortIcon icon={faSortDown} />
					</StyledSortButtonWrapper>
				</>
			)}
		</StyledSortButton>
	);
};

export default SortButton;
