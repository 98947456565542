import React, { useState } from 'react';
import MultiRangeSlider from 'multi-range-slider-react';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';

import { ColumnFlex, RowFlex } from 'basicStyles';

import Colors from 'colors';
import styled from 'styled-components';

import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import Title from 'AppComp/Title';
import Icon from 'AppComp/Icon';

import AnimateHeight from 'react-animate-height';

//import { CarePlanActionSection } from '../../provider/Shift/ShiftViews';

import { InputItemsContainer, ButtonTing } from './CreateJobViews';

import { Formik, Form, Field } from 'formik';
import AutoSave from 'AppComp/AutoSave';
import { ActivityRadioInupt } from 'AppComp/patient/CarePlanInfo/CarePlanInfoViews';

export const CustomLengthCon = styled.div`
	width: ${({ width }) => width || '280px'};
	color: black;
	border-radius: 12px;
`;

const CarePlanActionSection = (props) => {
	const [show, setShow] = useState(false);

	return (
		<ColumnFlex
			fullWidth
			padding='12px 24px'
			style={{
				border: `solid 3px ${Colors.theme.dark_accent_background}`,
				borderRadius: '16px',
				//display:non
			}}
			alignStart
		>
			<RowFlex gap='8px' justifyBetween alignCenter fullWidth>
				<Text bold padding='0 0 0 10px' largeText bolx color={Colors.secondary.blue}>
					{props.shiftTitle}
				</Text>
				<RowFlex
					style={{ cursor: 'pointer' }}
					onClick={() => setShow(!show)}
					center
					gap='8px'
				>
					<Text pointer color={Colors.primary.primary}>
						{show ? 'Hide' : 'Show '}
					</Text>
					<Icon
						icon={!show ? faEye : faEyeSlash}
						size='sm'
						color={Colors.primary.primary}
					/>
				</RowFlex>
			</RowFlex>
			<AnimateHeight
				style={{
					width: '100%',
				}}
				duration={500}
				height={show ? 'auto' : '0%'}
			>
				<RowFlex
					style={{
						textAlign: 'left',
					}}
					wrap
					gap='4px'
					fullWidth
				>
					{props.values.map((item, index) => {
						return (
							<CustomLengthCon key={index} width='45%'>
								<Field
									component={ActivityRadioInupt}
									name={`${props.shiftTitle}::${item}`}
									label={item}
									type='checkbox'
									disabled={props.disabled}
								/>
							</CustomLengthCon>
						);
					})}
				</RowFlex>
			</AnimateHeight>
		</ColumnFlex>
	);
};

const JobActivities = ({ handleNextView }) => {
	const isDisabled = false;
	const renderInitalValues = () => {
		const initialValues = {};

		// if (props.shift.care_plan_options) {
		// 	const shiftValues = JSON.parse(props.shift.care_plan_options);

		// 	shiftValues.forEach((item) => {
		// 		initialValues[item] = true;
		// 	});
		// }

		//console.log('initialValues', initialValues);
		return initialValues;
	};

	const renderSections = () => {
		const section = Object.keys(CARE_PLAN_ADLS)
			.filter((key) => {
				if (CARE_PLAN_ADLS[key].shiftTitle) {
					return true;
				} else {
					return false;
				}
			})
			.map((key) => CARE_PLAN_ADLS[key]);

		//console.log('isDisabled', isDisabled);
		return section.map((item, index) => {
			return <CarePlanActionSection disabled={isDisabled} {...item} key={index} />;
		});
	};

	const renderThing = (state) => {
		//console.log('state', state);
	};
	const handleSave = async (values) => {};
	return (
		<InputItemsContainer>
			<ColumnFlex gap='32px' center>
				<ColumnFlex gap='8px' center>
					<Title bold subtitle>
						What activities will the job require?
					</Title>
				</ColumnFlex>
				<ColumnFlex
					gap='40px'
					style={{
						width: '100%',
					}}
				>
					{
						// content here
					}

					<Formik
						style={{
							width: '100%',
						}}
						initialValues={renderInitalValues()}
					>
						{(formikProps) => (
							<Form
								style={{
									width: '100%',
								}}
							>
								{/* Your form inputs here */}
								<ColumnFlex padding='16px 0 ' fullWidth gap='10px'>
									{renderSections()}
								</ColumnFlex>

								<AutoSave onSave={handleSave} render={renderThing} />
							</Form>
						)}
					</Formik>
				</ColumnFlex>
				<ButtonTing
					onClick={() => {
						handleNextView(1, {});
					}}
					style={{
						borderRadius: '32px',
						width: '180px',
						height: '50px',
					}}
				>
					<Text fontWeight='500' cursor color={'white'} largeText>
						Continue
					</Text>
				</ButtonTing>
			</ColumnFlex>
		</InputItemsContainer>
	);
};

const EMPTY_CARE_PLAN_ADLS = {
	1: {
		shiftTitle: 'Personal Care',
		title: 'Activities willing to do:',
		values: [],
	},
	2: { title: 'Environment willing to work in:', values: [] },
	3: { title: 'Comfortable being in an environment with:', values: [] },
	4: {
		shiftTitle: 'Cooking',
		title: 'Meals I am able to cook:',
		values: [],
	},
	5: {
		shiftTitle: 'Transportation',
		title: 'Transportation:',
		values: [],
	},
	6: { title: 'Languages I speak:', values: [] },
	7: {
		shiftTitle: 'Assistance',
		title: 'I would feel comfortable assisting patients with:',
		values: [],
	},
};

const CARE_PLAN_ADLS = {
	1: {
		shiftTitle: 'Personal Care',
		title: 'Activities willing to do:',
		values: ['Bed bound', 'Dementia'],
	},
	2: { title: 'Environment willing to work in:', values: [] },
	3: { title: 'Comfortable being in an environment with:', values: [] },
	4: {
		shiftTitle: 'Cooking',
		title: 'Meals I am able to cook:',
		values: [
			'Chinese',
			'French',

			'Indian',
			'Italian',
			'Japanese',
			'Korean',
			'Mexican',
			'Spanish',

			'Vietnamese',
		],
	},
	5: {
		shiftTitle: 'Transportation',
		title: 'Transportation:',
		values: ['Car', 'Public Transportation', 'Walk', 'Bike', 'Wheelchair', 'Gurney', 'Other'],
	},
	6: { title: 'Languages I speak:', values: [] },
	7: {
		shiftTitle: 'Assistance',
		title: 'I would feel comfortable assisting patients with:',
		values: [
			'Bed bound',
			'Dementia',
			'Alzheimer’s',
			'Parkinson’s',
			'ALS',
			'Autism',
			'Stroke',
			'Heart Disease',
			'Pulmonary Disease',
			'Kidney Disease',
			'Liver Disease',
			'Blindness',
			'Deafness',
			'Paraplegic',
			'Quadriplegic',
			'Other',
		],
	},
};

export default JobActivities;
