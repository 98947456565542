import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/';

import PageHeading from '../../../core/components/PageHeading';
import LoginForm from '../../components/LoginForm';

import { Notification } from '../../../../../client/modules/core/lib';
import { notifications } from '../../../../../client/modules/core/constants';

import { useLoginUserMutation } from '../../api/mutations/useLoginUserMutation';
import { useAuth } from '../../../core/hooks/useAuth';

import { coreRoutes } from '../../../core/routes/constants';

import { StyledLoginPage } from './styles';

const LoginPage = () => {
	const [serverError, setServerError] = useState('');

	const loginMutation = useLoginUserMutation();
	const { loginUser } = useAuth();

	const history = useHistory();

	const loginHandler = async (values) => {
		try {
			setServerError('');
			const { token, user } = await loginMutation.mutateAsync(values);
			loginUser(token, user);

			Notification(notifications.auth.login);
			history.replace(coreRoutes.jobPosts);
		} catch (error) {
			console.error('Login failed', error);
			setServerError(error[0].message);
		}
	};

	return (
		<StyledLoginPage>
			<PageHeading>Login</PageHeading>

			<LoginForm
				initialValues={{ email: '', password: '' }}
				loginHandler={loginHandler}
				serverError={serverError}
			/>
		</StyledLoginPage>
	);
};

export default LoginPage;
