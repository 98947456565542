import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ColumnFlex, DropShadow } from 'basicStyles';
import { Formik } from 'formik';
import Colors from 'colors';
import { connect } from 'react-redux';
import { receiveOpenChatExternal } from 'reducers/patient';

import { patientSendMessage } from 'reducers/patient';

import Text from '../Text';
import PatientChatHandlerMessage from './PatientChatHandlerMessage';
import PatientChatBubble from './PatientChatBubble';
const ExitContainer = styled.div`
	position: absolute;
	left: -32px;
	top: -32px;
	cursor: pointer;

	z-index: 100;
	color: red;
	font-height: 60px;
	font-weight: 800;
	background-color: white;
	padding: 12px;
	border-radius: 50%;
	transition: all ease 0.3s;
	text-align: center;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}
`;
const ChatContainer = styled(ColumnFlex)`
	height: 100%;
	width: 100%;
	transition: opacity ease 0.3s;
	opacity: 0;
	padding: 0 0 8px 0;
	&.p-c-enter {
		opacity: 1;
	}

	&.p-c-enter-done {
		opacity: 1;
	}

	&.p-c-exit {
		opacity: 0;
	}

	&.p-c-exit-done {
		opacity: 0;
	}
`;
const ChatHeader = styled(ColumnFlex)`
	height: 60px;
	width: 100%;
	background-color: ${Colors.primary.primary};
	${DropShadow(1)};
	color: white;
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
`;
const ConversationContainer = styled.div`
	display: flex;
	flex-direction: column;
	overflow: hidden;
`;

const ChatTextWrapperParent = styled.div`
	flex: 1 1 auto;
	overflow-y: scroll;
	padding: 12px;
	background-color: ${Colors.activeWhite};
	word-wrap: break-word;
`;
class PatientChatHandler extends Component {
	state = {
		textLength: 0.1,
	};

	componentDidMount() {
		this.scrollToBottom();
	}

	updateTextLength = (length) => {
		console.log('updateTextLength', length);
		this.setState({
			textLength: length,
		});
	};
	componentDidUpdate(prevProps, prevState) {
		const { conversation } = this.props;

		if (prevProps.conversation.length !== conversation) {
			this.scrollToBottom();
		}
	}

	scrollToBottom = () => {
		const chatBox = document.getElementById('chat-box');
		chatBox.scrollTop = chatBox.scrollHeight;
	};

	handleSubmit = (values, form) => {
		const { patientSendMessage, conversation, location } = this.props;

		const params = {
			conversation_id: conversation.id,
			text: values.text,
		};
		console.log('location.pathname', location.pathname);
		if (location && location.pathname) {
			const locationCheck = location.pathname.indexOf('/viewprovider/');
			console.log('locationCheck', locationCheck);
			if (locationCheck > -1) {
				var providerId = parseInt(location.pathname.replace(/\D/g, ''), 10);
				params.providerId = providerId;
			}
		}

		patientSendMessage(params);
		this.setState({ textLength: 0.1 });
		form.resetForm();
	};

	renderConversation = () => {
		const { conversation } = this.props;

		return conversation.messages.map((d, i) => {
			const justifyContent = d.sent_by_admin === 1 ? 'flex-start' : 'flex-end';
			return (
				<PatientChatBubble key={'pch-b-' + d.id} {...d} justifyContent={justifyContent} />
			);
		});
	};

	render() {
		const { toggleActiveChat } = this.props;
		const { textLength } = this.state;
		return (
			<ChatContainer>
				<ExitContainer onClick={() => toggleActiveChat()}>x</ExitContainer>
				<ChatHeader padding='0 0 8px 12px' onClick={() => toggleActiveChat()}>
					<Text color='whtie' margin='12px 0 8px 0'>
						Admin
					</Text>
				</ChatHeader>
				<ChatTextWrapperParent id='chat-box'>
					<ConversationContainer>{this.renderConversation()}</ConversationContainer>
				</ChatTextWrapperParent>
				<Formik
					onSubmit={this.handleSubmit}
					initialValues={{ text: '' }}
					render={(formProps) => {
						return (
							<PatientChatHandlerMessage
								updateTextLength={this.updateTextLength}
								form={formProps}
								textLength={textLength}
							/>
						);
					}}
				/>
			</ChatContainer>
		);
	}
}

const mapStateToProps = ({ patient, router }) => {
	return {
		conversation: patient.conversation,
		location: router.location,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		patientSendMessage: (params) => dispatch(patientSendMessage(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientChatHandler);
