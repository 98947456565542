import React, { useState } from 'react';
import { STATES_OPTIONS } from 'constants';
import PageLoaderView from 'AppComp/PageLoaderView';

import {
	OnboardFormContainer,
	OnboardTitle,
	OnboardSubText,
	OnboardButton,
	OnboardFormButton,
	FormItemsLength,
} from '../CareProOnboardViews';
import { ColumnFlex } from 'basicStyles';

import { Formik, Form, Field } from 'formik';
import StyledTextInput, { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';
import Colors from 'colors';

import { validateCareProPersonalStripe } from './validation';

const Personal = (props) => {
	const { updateProviderVerificationInfo, setIsLoading, isLoading } = props;

	const handleSubmit = (values, form) => {
		console.log('Personal - handleSubmit', values);
		setIsLoading(true);
		updateProviderVerificationInfo({
			address_line1: values.address_line1,
			address_city: values.address_city,
			address_state: values.address_state,
			address_postal_code: values.address_postal_code,
		});
	};

	return (
		<OnboardFormContainer
			style={{
				width: 'fit-content',
			}}
			center
		>
			<OnboardTitle>Address</OnboardTitle>
			<OnboardSubText>
				Let us know where you live so we can verify your identity.
			</OnboardSubText>
			<Formik
				onSubmit={handleSubmit}
				validate={validateCareProPersonalStripe}
				autoComplete={'off'}
				render={(formProps) => {
					console.log(formProps);
					return (
						<Form noValidate>
							<ColumnFlex padding='20px 0 0 0' alignCenter justifyCenter>
								<FormItemsLength>
									<Field
										name='address_line1'
										type='text'
										component={StyledTextInput}
										label='Street Address'
										backgroundColor={'white'}
									/>
								</FormItemsLength>
								<FormItemsLength>
									<Field
										name='address_line2'
										type='text'
										component={StyledTextInput}
										backgroundColor={'white'}
										label='Street Address cont.'
									/>
								</FormItemsLength>
								<FormItemsLength>
									<Field
										name='address_city'
										type='text'
										backgroundColor={'white'}
										component={StyledTextInput}
										label='City'
									/>
								</FormItemsLength>
								<FormItemsLength>
									<Field
										name='address_state'
										component={StyledTextSelectInputV2}
										backgroundColor={'white'}
										label='State'
										options={STATES_OPTIONS}
									/>
								</FormItemsLength>
								<FormItemsLength>
									<Field
										name='address_postal_code'
										backgroundColor={'white'}
										type='text'
										component={StyledTextInput}
										label='Zip Code'
									/>
								</FormItemsLength>
								{isLoading ? (
									<PageLoaderView errorMessage='Something went wrong plz contact dev monkeys' />
								) : (
									<OnboardFormButton
										backgroundColor={Colors.primary.primary}
										type='submit'
										valid={formProps.isValid}
									>
										Next
									</OnboardFormButton>
								)}
							</ColumnFlex>
						</Form>
					);
				}}
			/>
		</OnboardFormContainer>
	);
};

export default Personal;
