import React, { useState, useEffect } from 'react';
import { ColumnFlex } from 'basicStyles';
import Colors from 'colors';
import { Formik, Form, Field } from 'formik';
import { isMobile } from 'react-device-detect';
import { validateCareProDobSSnlStripe } from 'constants/validation';

import Title from 'AppComp/Title';

import {
	MARGIN_TITLE,
	FormItemsLength,
	OBFormButtonText,
	OBBasicCon,
	OBSubText,
} from 'AppComp/provider/POnboard/OnboardViews';
import Text from 'AppComp/Text';

import StyledTextInput, { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';
import Button from 'AppComp/Button';
import { RowFlex } from '../../../styled/Basic';

import { MONTHS } from 'constants';

import { CustomFormButton } from 'AppComp/patient/PatientCardCollection/PatientCardCollectionViews';

const FORM_ITEM_LENGTH = isMobile ? '320px' : '420px';

import PageLoaderView from 'AppComp/PageLoaderView';

const BirthDate = ({ updateProviderVerificationInfo, setIsLoading, isLoading }) => {
	const handleSubmit = (values, form) => {
		console.log('Personal - handleSubmit', values);

		setIsLoading(true);
		// get the last 4 digits of the ssn

		updateProviderVerificationInfo({
			dob_day: values.dob_day,
			dob_month: values.dob_month,
			dob_year: values.dob_year,
			ssn_last_4: values.ssn_last_4,
		});
	};

	const renderPossibleAge = () => {
		// starting 18 years ago should end at 118 years ago
		const currentYear = new Date().getFullYear();
		const years = [
			{
				value: null,
				label: null,
			},
		];

		for (let i = 18; i < 118; i++) {
			years.push({
				value: currentYear - i,
				label: currentYear - i,
			});
		}

		return years;
	};

	const renderPossibleDays = () => {
		const days = [
			{
				value: null,
				label: null,
			},
		];

		for (let i = 1; i < 33; i++) {
			days.push({
				value: i,
				label: i,
			});
		}

		return days;
	};
	return (
		<ColumnFlex style={{ maxWidth: FORM_ITEM_LENGTH }} center>
			<Title color={'black'} bold subtitle>
				Personal Info
			</Title>
			<Text
				style={{
					textAlign: 'center',
				}}
			>
				Please enter your date of birth
			</Text>
			<Text
				style={{
					textAlign: 'center',
				}}
			>
				Testing last 4 SSN: 0000
			</Text>
			<ColumnFlex padding='0 0 12px 0' margin='20px auto 0 auto' center gap='12px'>
				<Formik
					onSubmit={handleSubmit}
					autoComplete={'off'}
					validate={validateCareProDobSSnlStripe}
					initialValues={{
						ssn_last_4: '0000',
					}}
					render={(formProps) => {
						console.log(formProps);
						return (
							<Form noValidate>
								<ColumnFlex alignCenter justifyCenter>
									<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
										<Field
											name='dob_month'
											type='text'
											component={StyledTextSelectInputV2}
											options={MONTHS}
											label='Month'
											backgroundColor={'white'}
										/>
									</FormItemsLength>
									<RowFlex spaceBetween fullWidth>
										<FormItemsLength margin='20px 0 0 0' width={'160px'}>
											<Field
												name='dob_day'
												type='text'
												component={StyledTextSelectInputV2}
												options={renderPossibleDays()}
												label='Day'
												backgroundColor={'white'}
											/>
										</FormItemsLength>
										<FormItemsLength margin='20px 0 0 0' width={'160px'}>
											<Field
												name='dob_year'
												type='year'
												component={StyledTextSelectInputV2}
												options={renderPossibleAge()}
												label='Year'
												backgroundColor={'white'}
											/>
										</FormItemsLength>
									</RowFlex>

									<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
										<Field
											name='ssn_last_4'
											type='password'
											component={StyledTextInput}
											label='Last 4 digits of SSN'
											backgroundColor={'white'}
										/>
									</FormItemsLength>
									{isLoading ? (
										<PageLoaderView errorMessage='Something went wrong plz contact dev monkeys' />
									) : (
										<CustomFormButton
											style={{
												borderRadius: '24px',
											}}
											valid={formProps.isValid}
											type='submit'
											margin='32px 8px 0 0px'
											backgroundColor={Colors.primary.primary}
											width={'240px'}
											height={isMobile ? '40px' : '60px'}
										>
											<Text
												style={{
													textAlign: 'center',
												}}
												bold
												color={
													formProps.isValid
														? 'white'
														: Colors.primary.primary
												}
												midLarge
											>
												Next
											</Text>
										</CustomFormButton>
									)}
								</ColumnFlex>
							</Form>
						);
					}}
				/>
			</ColumnFlex>
		</ColumnFlex>
	);
};
export default BirthDate;
