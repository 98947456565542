import React from 'react';

import { StyledDangerButton } from './styles';

const DangerButton = (props) => {
	const { children, ...restProps } = props;

	return <StyledDangerButton {...restProps}>{children}</StyledDangerButton>;
};

export default DangerButton;
