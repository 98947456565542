import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { JOB_POST_MODEL } from '../../../core/api/models';

const inviteProviderToJobPost = async ({ jobPostId, careProId }) => {
	try {
		const data = await api.graph({
			query: `mutation {
					inviteProviderToJobPost(
						job_post_id: ${jobPostId},
						provider_id: ${careProId}
					){
						${JOB_POST_MODEL}
					}
				}`,
		});

		return data.inviteProviderToJobPost;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useInviteProviderToJobPost = (jobPostId, careProId) => {
	return useMutation({
		mutationKey: ['inviteProviderToJobPost', jobPostId, careProId],
		mutationFn: ({ jobPostId, careProId }) => {
			return inviteProviderToJobPost({ jobPostId, careProId });
		},
	});
};
