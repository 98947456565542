import { push } from 'connected-react-router';
import { toast } from 'react-toastify';

import api from '../../../scripts/api';

import { receiveError } from '../../environment';

import { fetchUserDetails } from './admin';

import { DEFAULT_TOAST_OPTIONS } from '../../../components/App';
import { ADMIN_MODEL } from '../../graphqlModelTypes';

import { toggleAdminOnboardOverlay, receiveAdminInfo, receiveUserDetails } from '../actions';

/**************************
	Function to check admin session
	no params needed
**************************/
export function checkAdminSession() {
	return (dispatch) => {
		api.graph({
			query: `{
				checkAdminSession {
					${ADMIN_MODEL}
				}
			}`,
		})
			.then((res) => {
				console.log('checkAdminSession res: ', res);
				dispatch(receiveAdminInfo(res.checkAdminSession));
				dispatch(toggleAdminOnboardOverlay(false));
				dispatch({ type: 'ADMIN_LOGIN' });
			})
			.catch((err) => {
				console.log('checkAdminSession -> err, ', err);
			});
	};
}

/**************************
	Function to log in admin
	func params {
		email: string!
		password: string!
}
**************************/
export function loginAdmin({ email, password }) {
	return (dispatch) => {
		api.graph({
			query: `mutation{
				loginAdmin(
					email: "${email}",
					password: "${password}"
				) {
					${ADMIN_MODEL}
				}
			}`,
		})
			.then((res) => {
				dispatch(receiveAdminInfo(res.loginAdmin));
				dispatch(toggleAdminOnboardOverlay(false));
				dispatch({ type: 'ADMIN_LOGIN' });
			})
			.catch((err) => {
				console.log('loginAdmin -> err, ', err);

				if (Array.isArray(err)) {
					dispatch(receiveError(err[0].message));
					toast.warning(receiveError(err[0].message));
				} else {
					dispatch(receiveError('Something went wrong'));
				}
			});
	};
}

export function logoutAdmin() {
	return (dispatch) => {
		api.graph({
			query: `mutation{
				logoutAdmin
			}`,
		})
			.then((res) => {
				dispatch({ type: 'ADMIN_LOGOUT' });
				dispatch(push('/admin'));
			})
			.catch((err) => {
				console.log('logoutAdmin -> err, ', err);
			});
	};
}

export function verifyProviderOffice(providerId) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				verifyProviderOffice(providerId: ${providerId}) {
					id
				}
			}`,
		})
			.then((res) => {
				toast.success('Succesfully Verifed Provider', DEFAULT_TOAST_OPTIONS);
				dispatch(fetchUserDetails({ id: res.verifyProviderOffice.id, type: 'provider' }));
			})
			.catch((err) => {
				console.log('verifyProviderOffice err', err);

				toast.error(
					'Something Went Wrong Verifying The Provider. Please Try Again, If the problem continues Please contact Tech For Help',
					DEFAULT_TOAST_OPTIONS
				);
			});
	};
}

export function unVerifyProviderOffice(providerId) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				unVerifyProviderOffice(providerId: ${providerId}) {
					id
				}
			}`,
		})
			.then((res) => {
				toast.success('Succesfully  Un-Verify Provider', DEFAULT_TOAST_OPTIONS);
				dispatch(fetchUserDetails({ id: res.unVerifyProviderOffice.id, type: 'provider' }));
			})
			.catch((err) => {
				console.log('unVerifyProviderOffice err', err);

				toast.error(
					'Something Went Wrong Un-Verify The Provider. Please Try Again, If the problem continues Please contact Tech For Help',
					DEFAULT_TOAST_OPTIONS
				);
			});
	};
}

export function deleteTestUserAccount(userId, userType) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				deleteTestUserAccount(userId: ${userId}, userType: "${userType}") 
			}`,
		})
			.then((res) => {
				toast.success('Provider Fully Deleted', DEFAULT_TOAST_OPTIONS);

				dispatch(
					receiveUserDetails({
						status: false,
						user: '',
						type: '',
					})
				);
				dispatch(searchProviders());
			})
			.catch((err) => {
				console.log('delete account err err', err);
			});
	};
}

export function authorizePendingShifts() {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				authorizePendingShifts
			}`,
		})
			.then((res) => {
				console.log('authorizePendingShifts res: ', res);
				toast.success('Shift processing', DEFAULT_TOAST_OPTIONS);
			})
			.catch((err) => {
				console.log('authorizePendingShifts err', err);
			});
	};
}

export function captureApprovedShifts() {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				captureApprovedShifts
			}`,
		})
			.then((res) => {
				console.log('captureApprovedShifts res: ', res);
				toast.success('Approved Shifts Captured', DEFAULT_TOAST_OPTIONS);
			})
			.catch((err) => {
				console.log('captureApprovedShifts err', err);
			});
	};
}
