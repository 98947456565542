import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import AnimateHeight from 'react-animate-height';
import { Formik, Form, Field } from 'formik';
import Dropzone from 'react-dropzone';
import {
	faChevronDown,
	faChevronRight,
	faCheck,
	faCheckCircle,
	faClipboardList,
	faDollarSign,
	faPercent,
	faTimes,
	faFileUpload,
} from '@fortawesome/free-solid-svg-icons';
import changeCase from 'change-case';
import { FixedSizeList as List } from 'react-window';
import moment from 'moment';
import AutoSizer from 'react-virtualized-auto-sizer';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import qsueryString from 'query-string';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

import { ColumnFlex, RowFlex } from 'basicStyles';
import Colors from 'colors';
import { CARE_PLAN_ATTRIBUTES } from 'constants';
import api from 'scripts/api';
import { API_ROOT } from 'constants';

import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';
import ChatMessageTile from 'AppComp/admin/ChatMessageTile';
import Title from 'AppComp/Title';
import Button from 'AppComp/Button';
import { DisputeShower, DisputeTimeSlot } from '../ManageDisputes/AdminDisputeSummaryView';

import StyledTextInput from 'AppComp/styled/StyledTextInput';

import { FormItemsLength } from 'AppComp/provider/POnboard/OnboardViews';

import { CustomAvatar } from 'AppComp/provider/ProfileInfo/ProfileInfoViews';
/*
    - SectionCon
    - IconCon
    - SectionInfo
    - ImgContainer
    - FittedImg
    - Section
    - renderCertifications
    - renderStarRatings
    - TextInfo
*/
const SectionCon = styled(RowFlex)`
	padding: ${(props) => props.padding || '20px'};
	color: ${({ color }) => color || Colors.textBlack};
	border-bottom: solid 1px black;
	width: 100%;
`;
const IconCon = styled.div`
	width: 50px;
`;

const MessageHeight = styled.div`
	height: 300px;
	width: 100%;
`;

const CustomUL = styled.ul`
	color: black;
`;

const SignatureCon = styled.div`
	width: 100%;
	height: 200px;

	padding: 8px;
	overflow: hidden;
	background-color: ${Colors.lightGrey};
`;

const RoatedImage = styled.div`
	height: 150px;
	width: 100%;

	background-image: url('${(props) => props.image_url}');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	overflow: hidden;
`;
export const ImagesAreForTheWeak = (props) => {
	return (
		<SignatureCon>
			<RoatedImage image_url={props.image_url} />
		</SignatureCon>
	);
};
export const ImgContainer = styled.div`
	height: 250px;
	width: 210px;
`;
export const FittedImg = styled.img`
	height: 100%;
	width: 100%;
`;

export const Section = (props) => {
	const { icon, defaultActive, title, children, padding } = props;

	const [active, setActiveStatus] = useState(defaultActive);

	return (
		<SectionCon alignStart padding={padding}>
			{icon && (
				<IconCon>
					<Icon icon={icon} color={Colors.gray} />
				</IconCon>
			)}

			<ColumnFlex fullWidth>
				<RowFlex
					fullWidth
					spaceBetween
					alignCenter
					onClick={() => setActiveStatus(!active)}
					pointer
					margin='0 0 8px 0'
				>
					<Text largeText color={Colors.gray}>
						{title}
					</Text>
					<Icon icon={active ? faChevronDown : faChevronRight} color={Colors.gray} />
				</RowFlex>
				<AnimateHeight duration={500} height={active ? 'auto' : '0%'}>
					{React.Children.count(children) > 0 ? (
						children
					) : (
						<Text padding='0 0 0 16px' color={Colors.gray}>
							N/A
						</Text>
					)}
				</AnimateHeight>
			</ColumnFlex>
		</SectionCon>
	);
};

export const renderCertifications = (certifications) => {
	return certifications.map((d, i) => {
		return (
			<RowFlex spaceBetween fullWidth alignCenter>
				<Text>{d.name}</Text>
				<Icon icon={faCheck} color='black' />
			</RowFlex>
		);
	});
};

export const renderStarRatings = (averageReview) => {
	const starsTotal = 5;
	var starPercentageNumber = (averageReview / starsTotal) * 100;

	if (starPercentageNumber === 90) {
		starPercentageNumber += 2;
	}

	starPercentageNumber = `${starPercentageNumber}%`;

	const hearts = [];
	for (var i = 0; i < averageReview; i++) {
		hearts.push(<Icon icon={faHeart} key={`$12-${i}`} padding='8px' color={Colors.heartRed} />);
	}
	return (
		<RowFlex alignCenter>
			<RowFlex alignCenter padding='0 0px 0 0'>
				{hearts}
			</RowFlex>
			<Text subtext margin='0 0 0 16px'>
				{`${averageReview} out of 5`}
			</Text>
		</RowFlex>
	);
};

export const TextInfo = (props) => {
	const { title, value, margin, padding, children, color, alignCenter, column } = props;

	return (
		<RowFlex
			style={{
				minHeight: '80px',
				borderRadius: '10px',
				backgroundColor: Colors.littleBitDarkerLightGray,
				gap: '16px',
				paddingTop: alignCenter ? '6px' : '18px',
				paddingLeft: '18px',
				paddintBottom: '8px',
				justifyItems: 'start',
				alignItems: alignCenter ? 'center' : 'start',
				margin: '8px 0',
				flexDirection: column ? 'column' : 'row',
				flexWrap: 'wrap',
			}}
			padding={padding}
			margin={margin}
			item
		>
			<Text bold mediumText style={{ textAlign: 'left' }} fontWeight='italic'>
				{title}:
			</Text>
			{value ? (
				<Text color={color} mediumText>
					{value}
				</Text>
			) : (
				children
			)}
		</RowFlex>
	);
};

export const TextBlockInfo = (props) => {
	const { title, value, margin, padding, children, color } = props;

	return (
		<ColumnFlex
			style={{
				width: '135px',
				height: '115px',
				borderRadius: '10px',
				backgroundColor: Colors.littleBitDarkerLightGray,
				gap: '16px',
				paddingTop: '18px',
				paddingLeft: '18px',
				paddingBottom: '12px',
				justifyItems: 'space-between',
				alignItems: 'start',
				justifyContent: 'space-between',
			}}
			padding={padding}
		>
			<Text bold mediumText style={{ textAlign: 'left' }} fontWeight='italic'>
				{title}:
			</Text>
			{value ? (
				<Text style={{ textAlign: 'left' }} color={color} mediumText>
					{value}
				</Text>
			) : (
				children
			)}
		</ColumnFlex>
	);
};

export const MessageTile = (props) => {
	const convo = props.data[props.index];

	const sentByAmin = convo.sent_by_admin === 1;

	return (
		<ChatMessageTile
			type={!sentByAmin ? 'user' : 'advisor'}
			justify={!sentByAmin ? 'flex-start' : 'flex-end'}
			flexDirection={!sentByAmin ? 'row' : 'row-reverse'}
			{...convo}
			key={'cmtmt-' + convo.id}
			style={props.style}
		/>
	);
};

export const PaymentInfo = (props) => {
	const { hourly_rate, hours, subtotal, stripe_fee, fyi_share, provider_payout, timestamp } =
		props;

	const parseCent = (cents) => `$${cents / 100}`;
	return (
		<>
			<Text margin='24px 0 00' largeText bold>
				Payment Info {`${moment.unix(timestamp).format('MM/DD/YYYY')}: Payment Info`}
			</Text>
			<RowFlex padding='20px 0 0 0' gap='8px' justifyCenter wrap>
				<TextBlockInfo
					title='Hourly Rate'
					value={parseCent(hourly_rate)}
					margin='8px 0 0 0'
				/>
				<TextBlockInfo title='Hours Worked' value={hours} margin='8px 0 0 0' />
				<TextBlockInfo
					title='Total Revenue'
					value={parseCent(subtotal)}
					margin='8px 0 0 0'
				/>
				<TextBlockInfo
					title='Stripe Fee'
					value={parseCent(stripe_fee)}
					margin='8px 0 0 0'
				/>
				<TextBlockInfo title='FYI Fee' value={parseCent(fyi_share)} margin='8px 0 0 0' />
				<TextBlockInfo
					title='CarePro Payout'
					value={parseCent(provider_payout)}
					margin='8px 0 0 0'
				/>
			</RowFlex>
		</>
	);
};
export const ProviderInfo = (props) => {
	const {
		first_name,
		last_name,
		averageRating,

		account_tier,
		email,
		gender,
		phone_number,
		account_status,
		discharge_timestamp,
		averageReview,
		display_name,
	} = props;

	return (
		<>
			<Text largeText bold margin='8px 0 12px 0'>
				{`${first_name} ${last_name}`}{' '}
				<span
					style={{
						fontWeight: 'normal',
						fontStyle: 'italic',
						fontSize: '12px',
						color: 'black',
					}}
				>
					{display_name ? `(${display_name})` : ''}
				</span>
			</Text>
			{renderStarRatings(averageReview)}
			<RowFlex padding='20px 0 0 0' gap='8px' wrap>
				<TextBlockInfo title='Account Tier' value={account_tier} margin='8px 0 0 0' />
				<TextBlockInfo title='Gender' value={gender} margin='8px 0 0 0' />
				<TextBlockInfo
					title='Account Status'
					value={changeCase.sentenceCase(account_status)}
					margin='8px 0 0 0'
				/>
				{account_status === 'in_active' && (
					<TextBlockInfo
						title='Discharge Date'
						value={moment.unix(discharge_timestamp).format('MM/DD/YYYY')}
						margin='8px 0 0 0'
					/>
				)}
			</RowFlex>
			<TextInfo title='Email' value={email} margin='8px 0 0 0' />
			<TextInfo title='Phone Number' value={phone_number} margin='8px 0 0 0' />
		</>
	);
};

export const PatientInfo = (props) => {
	const { name, email, phone_number, address } = props;

	return (
		<>
			<Text mediumText margin='8px 0 12px 0'>
				{name}
			</Text>
			<TextInfo title='Email' value={email} margin='8px 0 0 0' />
			<TextInfo title='Phone Number' value={phone_number} margin='8px 0 0 0' />
		</>
	);
};

export function UserVerificationInfo(props) {
	const {
		valid,
		patient_personal_details_submitted,
		patient_location_details_submitted,
		payment_method_verification,
		error_reason,
		valid_email,
		valid_phone,
		missing_information,
	} = props;

	const missinInfo = missing_information
		.map((info) => changeCase.sentenceCase(info))
		.reduce((p, c, i) => {
			// create a single string from array of strings
			if (i === 0) {
				return c;
			} else {
				return p + ', ' + c;
			}
		}, '');
	return (
		<>
			<RowFlex padding='20px 0 0 0' gap='8px' wrap>
				<TextBlockInfo
					title='Valid for FYI Platform'
					value={valid ? 'Valid' : 'Invalid'}
					margin='8px 0 0 0'
				/>
				<TextBlockInfo title='Error Reason' value={error_reason} margin='8px 0 0 0' />

				<TextBlockInfo
					title='Patient Personal Details Submitted'
					value={patient_personal_details_submitted ? 'Yes' : 'Missing Info'}
					margin='8px 0 0 0'
				/>

				<TextBlockInfo
					title='Patient Location Details Submitted'
					value={patient_location_details_submitted ? 'Yes' : 'Missing Info'}
					margin='8px 0 0 0'
				/>

				<TextBlockInfo
					title='Payment Method Verification'
					value={payment_method_verification ? 'Yes' : 'Missing Info'}
					margin='8px 0 0 0'
				/>

				<TextBlockInfo
					title={'Valid Email'}
					value={valid_email ? 'Verfied' : 'Unverified'}
					margin='8px 0 0 0'
				/>
				<TextBlockInfo
					title={'Valid Phone'}
					value={valid_phone ? 'Verfied' : 'Unverified'}
					margin='8px 0 0 0'
				/>
			</RowFlex>
			<TextInfo title='Missing Information' value={missinInfo} margin='8px 0 0 0' />
		</>
	);
}
export const VerificationInfo = (props) => {
	const {
		valid,
		details_submitted,
		charges_enabled,
		card_payments,
		transfers,
		payouts_enabled,
		error_code,
		error_reason,
		admin_verification,

		valid_email,
	} = props;

	return (
		<>
			<TextInfo
				title='Verified Email'
				value={valid_email ? 'Verified' : 'Unverified'}
				margin='8px 0 0 0'
			/>
			<TextInfo
				title='Stripe Detailes Submitted'
				value={details_submitted ? 'Submitted' : 'Missing'}
				margin='8px 0 0 0'
			/>
			<TextInfo
				title='Stripe Charges Enabled'
				value={charges_enabled ? 'Valid' : 'Invalid'}
				margin='8px 0 0 0'
			/>
			<TextInfo
				title='Stripe Card Payments Enabled'
				value={card_payments}
				margin='8px 0 0 0'
			/>
			<TextInfo title='Stripe Transfers Enabled' value={transfers} margin='8px 0 0 0' />
			<TextInfo
				title='Stripe Pay Outs Enabled'
				value={payouts_enabled ? 'Verified' : 'Unverified'}
				margin='8px 0 0 0'
			/>
			<TextInfo title='Stripe Error Codes' value={error_code || 'N/A'} margin='8px 0 0 0' />
			<TextInfo
				title='Stripe Error Reason'
				value={error_reason || 'N/A'}
				color='red'
				margin='8px 0 0 0'
			/>
			<TextInfo
				title='Admin Verification'
				value={admin_verification ? 'Verified' : 'Unverified'}
				margin='8px 0 0 0'
			/>

			<Text color={valid ? 'green' : '#C6C400'} bold largeText margin='8px 0'>
				{valid ? 'Verified info' : 'Unverified or missing info'}
			</Text>
		</>
	);
};

export const CarePlanInfo = (carePlan) => {
	const {
		address_street,
		address_street_2,
		address_city,
		address_state,
		address_zip,
		first_name,
		last_name,
		phone_number,
		email,
		patient_relation,
		hourly_rate,
		type,
		user,

		dob,
		gender,
		medical_notes,

		display_name,
	} = carePlan;

	/* TODO */
	const address = `${address_street} ${
		address_street_2 ? address_street_2 : ''
	} ${address_city}, ${address_state} ${address_zip}`;

	return (
		<>
			<TextInfo title='Client Location' value={address} margin='8px 0 0 0' />
			<TextInfo title='Client Relation to User' value={patient_relation} margin='8px 0 0 0' />
			<TextInfo title='Client Name' value={`${first_name} ${last_name}`} margin='8px 0 0 0' />
			<TextInfo title='Display Name' value={display_name} margin='8px 0 0 0' />
			<TextInfo title='Client DOB' value={dob} margin='8px 0 0 0' />
			<TextInfo title='Medical Notes' value={medical_notes} margin='8px 0 0 0' />
			<TextInfo title='Client Gender' value={gender} margin='8px 0 0 0' />
			<TextInfo title='Client Email' value={email} margin='8px 0 0 0' />
			<TextInfo title='Client Phone' value={phone_number} margin='8px 0 0 0' />
			<TextInfo title='Hourly Rate' value={`$${hourly_rate / 100}`} margin='8px 0 0 0' />
			<TextInfo title='Type' value={type} margin='8px 0 0 0' />

			{user && (
				<Section title='Associated User' padding='20px 0'>
					<PatientInfo {...user} />
				</Section>
			)}
		</>
	);
};

export const Conversation = ({ messages }) => {
	return (
		<MessageHeight>
			<AutoSizer>
				{({ height, width }) => (
					<List
						height={height}
						itemCount={messages?.length || 0}
						itemSize={90}
						width={width}
						itemData={messages}
					>
						{MessageTile}
					</List>
				)}
			</AutoSizer>
		</MessageHeight>
	);
};

export const renderCoversations = (conversation) => {
	const [showCarePlan, toggleCarePlan] = useState(false);
	const { unread_message, latest_message_timestamp, messages } = conversation;

	return (
		<Section title={'Conversations'}>
			{messages.length > 0 && (
				<Section title='Messages' padding='20px 0'>
					<Conversation messages={messages} />
				</Section>
			)}
		</Section>
	);
};

export const GenericSessionSection = (props) => {
	const { day_of_week, start_time, end_time } = props;

	const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
	return (
		<Text mediumText bold padding='12px 0 12px 16px'>
			{`- ${days[day_of_week]} From ${moment(start_time, 'hhmm').format(
				'hh:mm a'
			)} to ${moment(end_time, 'hhmm').format('hh:mm a')}`}
		</Text>
	);
};

export const ScheduleSession = (props) => {
	const { status, date, start_time, end_time, proposed_start_time, proposed_end_time } = props;

	return (
		<Section
			title={`${moment(date, 'MM-DD-YYYY').format('MMM DD YYYY')} : ${changeCase.sentenceCase(
				status
			)}`}
			padding='20px 0 20px 16px'
		>
			<TextInfo
				title='Start'
				value={moment(start_time, 'hhmm').format('hh:mm a')}
				margin='8px 0 0 0'
			/>
			<TextInfo
				title='End'
				value={moment(end_time, 'hhmm').format('hh:mm a')}
				margin='8px 0 0 0'
			/>
			{proposed_start_time && (
				<TextInfo
					title='Proposed Start Time'
					value={moment(proposed_start_time, 'hhmm').format('hh:mm a')}
					margin='8px 0 0 0'
				/>
			)}
			{proposed_end_time && (
				<TextInfo
					title='Proposed End Time'
					value={moment(proposed_end_time, 'hhmm').format('hh:mm a')}
					margin='8px 0 0 0'
				/>
			)}
		</Section>
	);
};

export const CustomInput = styled.input`
	border-radius: 12px;
	border: solid 1px #c6c6c6;
	color: black;
	padding: 8px 12px;
`;

export const ChangeFyiCommissionSection = (props) => {
	const {
		updatedCommissionStatus,
		hourly_rate,
		provider_hourly_rate,
		fyi_commission,
		handleUpdateFyiCommission,
	} = props;

	const [updatedPrice, togglePriceChange] = useState(false);
	const [showDollar, toggleShowDollar] = useState(true);
	const [fyi_share, updateShare] = useState('');
	const [totalHours, setInputValue] = useState(1);

	useEffect(() => {
		togglePriceChange(false);
	}, [updatedCommissionStatus]);

	useEffect(() => {
		updateShare(`$${parseFloat(fyi_commission / 100)}`);
	}, [fyi_commission]);

	useEffect(() => {
		if (showDollar) {
			updateShare('$1.00');
		} else {
			updateShare('%10.00');
		}
	}, [showDollar]);

	const onChangePriceHandler = (event) => {
		const { value } = event.target;

		if (!updatedPrice) togglePriceChange(true);
		const resultRegex = /[^0-9.]/g;
		let formattedValue = parseFloat(value.replace(resultRegex, ''));

		if (isNaN(formattedValue)) {
			formattedValue = 0;
		}

		const symbol = showDollar ? '$' : '%';

		const displayRegex = /[^0-9.,$]/g;
		const display =
			value.indexOf('.') == value.length - 1
				? value.replace(displayRegex, '')
				: symbol +
				  formattedValue.toLocaleString('en', {
						maximumFractionDigits: 2,
				  });
		updateShare(display);
	};

	const onChangeHandler = (event) => {
		setInputValue(event.target.value);
	};

	const floatCommissionFloat = showDollar
		? fyi_share.replace(/\$/g, '')
		: fyi_share.replace(/\%/g, '');
	const testnum = parseFloat(floatCommissionFloat);
	const hourlyFromatted = hourly_rate / 100;
	const fyiCommission = showDollar ? testnum : (testnum / 100) * hourlyFromatted;
	const subtotal = (fyiCommission + hourlyFromatted) * totalHours;
	const stripeFee = subtotal * 0.029 + 0.3;
	const stripeClean = stripeFee.toFixed(2);
	const total = stripeFee + subtotal;
	const totalToClient = total.toFixed(2);
	const providerRealized = (totalHours * hourlyFromatted).toFixed(2);

	const calculatedAmount = fyiCommission * totalHours;

	return (
		<Section padding='20px 0' title={'Adjust FYI Commission'}>
			<RowFlex alignCenter>
				<TextInfo title='FYI  Commission' margin='8px 0 0 0'>
					<CustomInput type='text' value={fyi_share} onChange={onChangePriceHandler} />
				</TextInfo>
				<RowFlex alignCenter margin='0 0 0 12px'>
					<Icon
						color={showDollar ? Colors.blue : Colors.gray}
						icon={faDollarSign}
						onClick={() => toggleShowDollar(true)}
					/>
					<Text margin='0 8px' />
					<Icon
						color={showDollar ? Colors.gray : Colors.blue}
						icon={faPercent}
						onClick={() => toggleShowDollar(false)}
					/>
				</RowFlex>
			</RowFlex>

			<TextInfo title='Hours Work  ' margin='8px 0 0 0'>
				<CustomInput type='number' value={totalHours} onChange={onChangeHandler} />
			</TextInfo>
			<TextInfo
				title='CarePro Match Rate '
				value={`$${hourly_rate / 100} / Per Hour`}
				margin='8px 0 0 0'
			/>
			<TextInfo
				title='CarePro +/- Rate'
				value={`$${(hourly_rate - provider_hourly_rate) / 100}`}
				margin='8px 0 0 0'
			/>
			<TextInfo
				title='Consumer Hourly Rate'
				value={`$${fyiCommission + hourly_rate / 100}`}
				margin='8px 0 0 0'
			/>
			<TextInfo title='CarePro Realized' value={`$${providerRealized}`} margin='8px 0 0 0' />
			<TextInfo
				title='FYI Earned'
				value={`$${Math.round(calculatedAmount * 100) / 100}`}
				margin='8px 0 0 0'
			/>
			<TextInfo title='Stripe Fee' value={`$${stripeClean}`} margin='8px 0 0 0' />
			<TextInfo title='Cost To Client' value={`$${totalToClient}`} margin='8px 0 0 0' />

			{updatedPrice && (
				<Button
					onClick={() => handleUpdateFyiCommission(fyi_share)}
					width='200px'
					backgroundColor={Colors.blue}
					margin='24px 0 0 0'
					backgro
				>
					Update FYI Commission
				</Button>
			)}
		</Section>
	);
};

import { ActivityRadioInupt } from 'AppComp/patient/CarePlanInfo/CarePlanInfoViews';
import { CustomFormButton } from 'AppComp/patient/PatientCardCollection/PatientCardCollectionViews';
import { set } from 'lodash';

export const AgencyCareProDocuments = ({
	careProDocumentRequirements,
	addDocumentsRequirements,
	removeDocumentsRequirements,
}) => {
	console.log('careProDocumentRequirements', careProDocumentRequirements);

	const [docs, setDocs] = useState([]); // [{docname: 'docname', required: true, officeSubmit: true}

	useEffect(() => {
		// sort the order of careProDocumentRequirements by date filed created_at tr
		// treat created_at as a sttring for new Date()
		const sorted = careProDocumentRequirements.sort((a, b) => {
			return new Date(b.created_at) - new Date(a.created_at);
		});

		setDocs(sorted);
	}, [careProDocumentRequirements]);

	const handleSubmit = (values, form) => {
		console.log('AgencyCareProDocuments - handleSubmit', values);
		// ensure values.docname is not already in docs

		const docname = values.docname;
		const required = values.required;

		const docCheck = docs.filter((x) => x.docname === docname);
		// ensure that either clientSubmit or officeSubmit is true

		console.log('docname', docname);
		if (docname === undefined || docname === '') {
			form.setFieldError('docname', 'Please a document name');
		} else if (docCheck.length === 0) {
			addDocumentsRequirements({
				name: docname,
				required: required || false,
				user_type: 'carePro',

				requires_expiration: values.requires_expiration || false,
			});
			form.setValues({
				docname: '',
				required: false,
			});
		} else {
			// show error message in lang
			form.setFieldError('docname', 'Document already added');
		}
	};

	const handleDelete = (itemId) => {
		removeDocumentsRequirements({ id: itemId, user_type: 'carePro' });
	};

	return (
		<>
			<Section defaultActive title={'CarePro Documents Requirements'}>
				<ColumnFlex
					fullWidth
					padding='16px'
					style={{
						backgroundColor: Colors.littleBitDarkerLightGray,
						borderRadius: '10px',
					}}
				>
					<Formik
						onSubmit={handleSubmit}
						autoComplete={'off'}
						render={(formProps) => {
							return (
								<Form
									noValidate
									style={{
										marginBottom: 0,
										marginTop: '8px',
									}}
								>
									<ColumnFlex alignCenter justifyCenter>
										<FormItemsLength margin='0' width={'100%'}>
											<Field
												name={'docname'}
												type='text'
												backgroundColor={Colors.fullWhite}
												component={StyledTextInput}
												label={'Document Name'}
											/>
										</FormItemsLength>
										<RowFlex fullWidth gap='16px'>
											<div
												style={{
													padding: '8px 16px',
													borderRadius: '12px',
													backgroundColor: 'white',
													height: 'min-content',
												}}
											>
												<Field
													component={ActivityRadioInupt}
													name='required'
													label='Required'
													type='checkbox'
												/>
											</div>
											<div
												style={{
													padding: '8px 16px',
													borderRadius: '12px',
													backgroundColor: 'white',
													height: 'min-content',
												}}
											>
												<Field
													component={ActivityRadioInupt}
													name='requires_expiration'
													label='Requires Expiration'
													type='checkbox'
												/>
											</div>
										</RowFlex>
										<CustomFormButton
											style={{
												borderRadius: '24px',
											}}
											valid={formProps.isValid}
											type='submit'
											margin='10px 0 0 0 '
											backgroundColor={Colors.primary.primary}
											width={'140px'}
											height={'40px'}
										>
											<Text
												style={{
													textAlign: 'center',
												}}
												bold
												color={
													formProps.isValid
														? 'white'
														: Colors.primary.primary
												}
												midLarge
											>
												Add
											</Text>
										</CustomFormButton>
									</ColumnFlex>
								</Form>
							);
						}}
					/>
				</ColumnFlex>
				{docs.length > 0 && (
					<ColumnFlex
						fullWidth
						margin='8px 0 0 0'
						padding='16px'
						alignCenter
						style={{
							backgroundColor: Colors.littleBitDarkerLightGray,
							borderRadius: '10px',
						}}
					>
						{docs.map((doc, i) => {
							return (
								<ColumnFlex
									style={{
										width: '100%',
										backgroundColor: Colors.fullWhite,
										borderRadius: '8px',
									}}
									key={i}
									alignCenter
									spaceBetween
									margin='8px 0 0 0'
									padding='12px 16px'
									gap='16px 0'
								>
									<RowFlex fullWidth spaceBetween>
										<Text midLarge margin='0 8px 0 0'>
											<span style={{ fontWeight: 'bold' }}>Document:</span>{' '}
											{doc.name}
										</Text>
										<div
											onClick={() => {
												handleDelete(doc.id);
											}}
											style={{
												cursor: 'pointer',
											}}
										>
											<Icon color={Colors.red} icon={faTimes} />
										</div>
									</RowFlex>
									<RowFlex fullWidth gap='16px'>
										<Text margin='0 8px 0 0'>
											<span style={{ fontWeight: 'bold' }}>Required:</span>{' '}
											{doc.required ? 'Yes' : 'No'}
										</Text>
										<Text margin='0 8px 0 0'>
											<span style={{ fontWeight: 'bold' }}>
												Requires Expiration:
											</span>{' '}
											{doc.requires_expiration ? 'Yes' : 'No'}
										</Text>
									</RowFlex>
								</ColumnFlex>
							);
						})}
					</ColumnFlex>
				)}
			</Section>
		</>
	);
};

export const AgencyClientDocuments = ({
	clientDocumentRequirements,
	addDocumentsRequirements,
	removeDocumentsRequirements,
}) => {
	const [docs, setDocs] = useState([]); // [{docname: 'docname', required: true, officeSubmit: true}

	useEffect(() => {
		// treat created_at as a sttring for new Date()
		const sorted = clientDocumentRequirements.sort((a, b) => {
			return new Date(b.created_at) - new Date(a.created_at);
		});

		setDocs(sorted);
	}, [clientDocumentRequirements]);

	const handleSubmit = (values, form) => {
		console.log('AgencyClientDocuments - handleSubmit', values);
		// ensure values.docname is not already in docs

		const docname = values.docname;
		const required = values.required;
		const officeSubmit = values.officeSubmit;
		const clientSubmit = values.clientSubmit;

		const docCheck = docs.filter((x) => x.docname === docname);
		// ensure that either clientSubmit or officeSubmit is true
		if (!clientSubmit && !officeSubmit) {
			form.setFieldError('docname', 'Please select a whom can upload');
			return;
		}

		if (docname === '') {
			form.setFieldError('docname', 'Please enter a value');
		} else if (docCheck.length === 0) {
			addDocumentsRequirements({
				name: docname,
				required: required,
				user_type: 'client',
				requires_expiration: values.requires_expiration || false,
			});
			//setDocs([...docs, { docname, required, officeSubmit, clientSubmit }]);
			form.setValues({
				docname: '',
				required: false,
				officeSubmit: false,
				clientSubmit: false,
			});
		} else {
			// show error message in lang
			form.setFieldError('docname', 'Document already added');
		}
	};

	const handleDelete = (itemId) => {
		removeDocumentsRequirements({ id: itemId, user_type: 'client' });
	};
	return (
		<>
			<Section defaultActive title={'	Client Documents Requirements'}>
				<ColumnFlex
					fullWidth
					padding='16px'
					style={{
						backgroundColor: Colors.littleBitDarkerLightGray,
						borderRadius: '10px',
					}}
				>
					<Formik
						onSubmit={handleSubmit}
						autoComplete={'off'}
						render={(formProps) => {
							console.log(formProps);
							return (
								<Form
									noValidate
									style={{
										marginBottom: 0,
										marginTop: '8px',
									}}
								>
									<ColumnFlex alignCenter justifyCenter>
										<FormItemsLength margin='0' width={'100%'}>
											<Field
												name={'docname'}
												type='text'
												backgroundColor={Colors.fullWhite}
												component={StyledTextInput}
												label={'Document Name'}
											/>
										</FormItemsLength>
										<RowFlex fullWidth gap='16px'>
											<div
												style={{
													padding: '8px 16px',
													borderRadius: '12px',
													backgroundColor: 'white',
													height: 'min-content',
												}}
											>
												<Field
													component={ActivityRadioInupt}
													name='required'
													label='Required'
													type='checkbox'
												/>
											</div>
											<div
												style={{
													padding: '8px 16px',
													borderRadius: '12px',
													backgroundColor: 'white',
												}}
											>
												<Text>Whom Can Upload</Text>
												<Field
													component={ActivityRadioInupt}
													name='officeSubmit'
													label='Office'
													type='checkbox'
												/>
												<Field
													component={ActivityRadioInupt}
													name='clientSubmit'
													label='Client'
													type='checkbox'
												/>
											</div>
										</RowFlex>
										<CustomFormButton
											style={{
												borderRadius: '24px',
											}}
											valid={formProps.isValid}
											type='submit'
											margin='10px 0 0 0 '
											backgroundColor={Colors.primary.primary}
											width={'140px'}
											height={'40px'}
										>
											<Text
												style={{
													textAlign: 'center',
												}}
												bold
												color={
													formProps.isValid
														? 'white'
														: Colors.primary.primary
												}
												midLarge
											>
												Add
											</Text>
										</CustomFormButton>
									</ColumnFlex>
								</Form>
							);
						}}
					/>
				</ColumnFlex>
				{docs.length > 0 && (
					<ColumnFlex
						fullWidth
						margin='8px 0 0 0'
						padding='16px'
						alignCenter
						style={{
							backgroundColor: Colors.littleBitDarkerLightGray,
							borderRadius: '10px',
						}}
					>
						{docs.map((doc, i) => {
							return (
								<ColumnFlex
									style={{
										width: '100%',
										backgroundColor: Colors.fullWhite,
										borderRadius: '8px',
									}}
									key={i}
									alignCenter
									spaceBetween
									margin='8px 0 0 0'
									padding='12px 16px'
									gap='16px 0'
								>
									<RowFlex fullWidth spaceBetween>
										<Text midLarge margin='0 8px 0 0'>
											<span style={{ fontWeight: 'bold' }}>Document:</span>{' '}
											{doc.name}
										</Text>
										<div
											onClick={() => {
												handleDelete(doc.id);
											}}
											style={{
												cursor: 'pointer',
											}}
										>
											<Icon color={Colors.red} icon={faTimes} />
										</div>
									</RowFlex>
									<RowFlex fullWidth gap='16px'>
										<Text margin='0 8px 0 0'>
											<span style={{ fontWeight: 'bold' }}>Required:</span>{' '}
											{doc.required ? 'Yes' : 'No'}
										</Text>
										{/* <Text margin='0 8px 0 0'>
											<span style={{ fontWeight: 'bold' }}>
												{' '}
												Office Submit:
											</span>{' '}
											{doc.officeSubmit ? 'Yes' : 'No'}
										</Text>
										<Text margin='0 8px 0 0'>
											<span style={{ fontWeight: 'bold' }}>
												{' '}
												Client Submit:
											</span>{' '}
											{doc.clientSubmit ? 'Yes' : 'No'}
										</Text> */}
									</RowFlex>
								</ColumnFlex>
							);
						})}
					</ColumnFlex>
				)}
			</Section>
		</>
	);
};

const DocumentTile = (props) => {
	const { doc, docs, setDocs } = props;

	return (
		<ColumnFlex
			style={{
				width: '100%',
				backgroundColor: Colors.fullWhite,
				borderRadius: '8px',
			}}
			key={i}
			alignCenter
			spaceBetween
			margin='8px 0 0 0'
			padding='12px 16px'
			gap='16px 0'
		>
			<RowFlex fullWidth spaceBetween>
				<Text midLarge margin='0 8px 0 0'>
					<span style={{ fontWeight: 'bold' }}>Document:</span> {doc.docname}
				</Text>
				<div
					onClick={() => {
						const newItems = docs.filter((x) => x.docname !== doc.docname);
						setDocs(newItems);
					}}
					style={{
						cursor: 'pointer',
					}}
				>
					<Icon color={Colors.red} icon={faTimes} />
				</div>
			</RowFlex>
			<RowFlex fullWidth gap='16px'>
				<Text margin='0 8px 0 0'>
					<span style={{ fontWeight: 'bold' }}>Required:</span>{' '}
					{doc.required ? 'Yes' : 'No'}
				</Text>
				<Text margin='0 8px 0 0'>
					<span style={{ fontWeight: 'bold' }}> Office Submit:</span>{' '}
					{doc.officeSubmit ? 'Yes' : 'No'}
				</Text>
			</RowFlex>
		</ColumnFlex>
	);
};
export const AgencyView = (props) => {
	const [newImage, setNewImage] = useState(false);
	const [newImageUrl, setNewImageUrl] = useState('');

	const handleDroppedFile = (acceptedFiles) => {
		var imageTypes = ['image/png', 'image/gif', 'image/bmp', 'image/jpg', 'image/jpeg'];

		var fileType = acceptedFiles[0].type;

		if (imageTypes.includes(fileType)) {
			const identifyPoolId = 'us-east-2:8aa03690-fa6e-4aab-8429-22b9b6270602';
			const bucketRegion = 'us-east-2';
			const bucketName = 'desir';

			// this.setState({
			// 	loading: true,
			// });

			AWS.config.update({
				region: bucketRegion,
				credentials: new AWS.CognitoIdentityCredentials({
					IdentityPoolId: identifyPoolId,
				}),
			});

			const s3 = new AWS.S3({
				apiVersion: '2012-10-17',
				params: { Bucket: bucketName },
			});

			const key = acceptedFiles[0].name;

			s3.upload(
				{
					Key: key,
					Body: acceptedFiles[0],
					ACL: 'public-read',
				},
				handleUploadCallback
			);
		} else {
			window.alert('dropped file is not an image');
		}
	};

	const handleUploadCallback = (err, data) => {
		//once the call back is called we either should show an error or run handleSignatureUpdate to pass the info back to the parent and handle removing the signature pad

		if (err) {
			console.log('something went wrong', err);
		} else {
			console.log('data', data);

			const { Location, Key } = data;
			console.log('Location', Location);
			setNewImage(true);
			setNewImageUrl(Location);
		}
	};

	return (
		<Section defaultActive title={'Agency Settings '}>
			<TextInfo title='Agency Name' alignCenter margin='8px 0 0 0'>
				<CustomInput type='text' />
			</TextInfo>
			<TextInfo title='Agency Address' alignCenter margin='8px 0 0 0'>
				<CustomInput type='text' />
			</TextInfo>
			<TextInfo title='Logo Upload' alignCenter margin='8px 0 0 0'>
				<Dropzone onDrop={(acceptedFiles) => handleDroppedFile(acceptedFiles)}>
					{({ getRootProps, getInputProps }) => (
						<CustomAvatar
							height='120px'
							width='200px'
							margin='20px 0px 16px 0'
							borderRadius='4px'
							style={{
								borderRadius: '8px',
							}}
							border={Colors.greenLightTeal}
							image_url={true ? (newImage ? newImageUrl : '') : image_url}
							{...getRootProps()}
						>
							<input {...getInputProps()} />
							{!newImage && <Icon icon={faFileUpload} size='2x' />}
						</CustomAvatar>
					)}
				</Dropzone>
			</TextInfo>
		</Section>
	);
};
export const BareBoneCalculator = (props) => {
	const [showDollar, toggleShowDollar] = useState(true);
	const [fyi_share_static, updateShareStatic] = useState('$2.00');
	const [fyi_share_percent, updateSharePercent] = useState('%2.5');

	const [totalHours, setInputValue] = useState(1);
	const [hourly_rate, updateHourlyRate] = useState('$10.00');

	const fyi_share = showDollar ? fyi_share_static : fyi_share_percent;

	useEffect(() => {
		if (showDollar) {
			//updateShare('$5.00');
		} else {
			//updateShare('%10.00');
		}
	}, [showDollar]);

	const updateShare = (value) => {
		if (showDollar) {
			updateShareStatic(value);
		} else {
			updateSharePercent(value);
		}
	};
	const onChangePriceHandler = (event) => {
		const { value } = event.target;

		const resultRegex = /[^0-9.]/g;
		let formattedValue = parseFloat(value.replace(resultRegex, ''));

		if (isNaN(formattedValue)) {
			formattedValue = 0;
		}

		const symbol = showDollar ? '$' : '%';

		const displayRegex = /[^0-9.,$]/g;
		const display =
			value.indexOf('.') == value.length - 1
				? value.replace(displayRegex, '')
				: symbol +
				  formattedValue.toLocaleString('en', {
						maximumFractionDigits: 2,
				  });
		updateShare(display);
	};

	const onChangeHandler = (event) => {
		setInputValue(event.target.value);
	};

	const onChangeProviderMatchRate = (event) => {
		const { value } = event.target;

		const resultRegex = /[^0-9.]/g;
		let formattedValue = parseFloat(value.replace(resultRegex, ''));

		if (isNaN(formattedValue)) {
			formattedValue = 0;
		}

		const symbol = '$';

		const displayRegex = /[^0-9.,$]/g;
		const display =
			value.indexOf('.') == value.length - 1
				? value.replace(displayRegex, '')
				: symbol +
				  formattedValue.toLocaleString('en', {
						maximumFractionDigits: 2,
				  });
		updateHourlyRate(display);
	};

	const floatCommissionFloat = showDollar
		? fyi_share.replace(/\$/g, '')
		: fyi_share.replace(/\%/g, '');

	const testnum = parseFloat(floatCommissionFloat);

	const hourlyFromatted = parseFloat(hourly_rate.replace(/\$/g, ''));

	const fyiCommission = showDollar ? testnum : (testnum / 100) * hourlyFromatted;

	const subtotal = (fyiCommission + hourlyFromatted) * totalHours;
	const stripeFee = subtotal * 0.029 + 0.3;
	const stripeClean = stripeFee.toFixed(2);
	const total = stripeFee + subtotal;
	const totalToClient = total.toFixed(2);
	const providerRealized = (totalHours * hourlyFromatted).toFixed(2);
	const calculatedAmount = fyiCommission * totalHours;

	return (
		<Section defaultActive title={'Operating Fee '}>
			<TextInfo title='Client Bill Rate' alignCenter margin='8px 0 0 0'>
				<CustomInput type='text' />
			</TextInfo>
			<TextInfo title='CarePro Hourly Rate' alignCenter margin='8px 0 0 0'>
				<CustomInput type='text' value={hourly_rate} onChange={onChangeProviderMatchRate} />
			</TextInfo>
			<TextInfo title='Hours Worked' alignCenter margin='8px 0 0 0'>
				<CustomInput type='number' value={totalHours} onChange={onChangeHandler} />
			</TextInfo>

			<RowFlex alignCenter>
				<TextInfo title='Agency Operating Fee' alignCenter margin='8px 0 0 0'>
					<CustomInput type='text' value={fyi_share} onChange={onChangePriceHandler} />
				</TextInfo>
				<RowFlex alignCenter margin='0 0 0 12px'>
					<Icon
						color={showDollar ? Colors.blue : Colors.gray}
						icon={faDollarSign}
						onClick={() => toggleShowDollar(true)}
					/>
					<Text margin='0 8px' />
					<Icon
						color={showDollar ? Colors.gray : Colors.blue}
						icon={faPercent}
						onClick={() => toggleShowDollar(false)}
					/>
				</RowFlex>
			</RowFlex>
			<RowFlex padding='20px 0 0 0' gap='8px' wrap>
				<TextBlockInfo
					title='User Hourly Rate'
					value={`$${fyiCommission + hourlyFromatted}`}
					margin='8px 0 0 0'
				/>
				<TextBlockInfo
					title='CarePro Realized'
					value={`$${providerRealized}`}
					margin='8px 0 0 0'
				/>
				<TextBlockInfo
					title='Agency Earned'
					value={`$${Math.round(calculatedAmount * 100) / 100}`}
					margin='8px 0 0 0'
				/>
				<TextBlockInfo title='Stripe Fee' value={`$${stripeClean}`} margin='8px 0 0 0' />
				<TextBlockInfo
					title='Final Cost to Client'
					value={`$${totalToClient}`}
					margin='8px 0 0 0'
				/>
			</RowFlex>
		</Section>
	);
};

export const ReportSectionViews = (props) => {
	const [selectedReport, updateSelectedReport] = useState(null);
	const [selectedUserType, updateSelectedUserType] = useState(null);
	const [selectedUser, updateSelectedUser] = useState(null);
	const [selectedProviderInCarePlan, updateSelectedProvider] = useState(null);

	const [users, receiveUsers] = useState([]);
	const [providersInCarePlan, receiveProviders] = useState([]);

	const [loading, updateLoaderStatus] = useState(false);

	//Get today's date using the JavaScript Date object.
	var ourDate = new Date();

	//Change it so that it is 7 days in the past.
	var pastDate = ourDate.getDate() - 7;
	ourDate.setDate(pastDate);

	const [state, setState] = useState([
		{
			startDate: ourDate,
			endDate: new Date(),
			key: 'selection',
		},
	]);

	const handleSelectChange = (event) => {
		console.log('event.target.value', event.target.value);
		updateSelectedReport(event.target.value);
	};
	const handleSelectedUserType = (event) => {
		updateSelectedUserType(event.target.value);
	};
	const handleSelectedUserChange = (event) => {
		updateSelectedUser(event.target.value, 10);

		if (selectedUserType === 'Care Plan') {
			const _temp = users.find((d, i) => {
				return parseInt(event.target.value, 10) === d.value;
			});

			receiveProviders([{ label: 'All', value: 'all' }, ..._temp.providers]);
		}
	};

	const handleSelectedProviderChange = (event) => {
		updateSelectedProvider(event.target.value);
	};
	const handleFetchingReport = async () => {
		updateLoaderStatus(true);
		//await api.getCSV(`reports/${selectedReport}`);

		let paramsString = '';
		if (selectedReport === 'invoice') {
			let userType = '';
			let selectedProviders = null;
			if (selectedUserType === 'Provider') {
				userType = 'providr';
			} else if (selectedUserType === 'Patient') {
				userType = 'patient';
			} else if (selectedUserType === 'Care Plan') {
				userType = 'care_plan';
			}

			console.log('state', state);

			const _state = state[0];
			const start_date = new Date(_state.startDate).getTime();
			const end_date = new Date(_state.endDate).getTime();
			console.log('start_date', start_date);
			console.log('end_date', end_date);
			const queryStringThing = qsueryString.stringify(
				{
					type_id: selectedUser,
					type: userType,
					selected_provider: selectedProviderInCarePlan,
					start_date: Math.round(start_date / 1000),
					end_date: Math.round(end_date / 1000),
				},
				{ arrayFormat: 'comma' }
			);
			console.log('queryStringThing', queryStringThing);
			paramsString = queryStringThing;
		} else if (selectedReport === 'care_provider') {
		} else if (selectedReport === 'patient') {
		} else if (selectedReport === 'general') {
		}

		console.log('paramsString', paramsString);
		window.open(`${API_ROOT}reports/${selectedReport}?${paramsString}`);
		// app.get('/api/v/1/reports/invoice', reporting.generateInvoiceReport);
		// app.get('/api/v/1/reports/care_provider', reporting.generateProviderReport);
		// app.get('/api/v/1/reports/patient', reporting.generatePatientReport);
		// app.get('/api/v/1/reports/general', reporting.generateGeneralReport);
	};

	useEffect(() => {
		if (selectedReport === 'invoice') {
			let query;
			if (selectedUserType === 'Provider') {
				query = {
					query: `{
					fetchAllProvider {
						id,
						first_name,
						last_name,
					}
				}`,
				};
			} else if (selectedUserType === 'Patient') {
				query = {
					query: `{
					fetchAllPatients {
						id,
						name
					}
				}`,
				};
			} else if (selectedUserType === 'Care Plan') {
				query = {
					query: `{
						allCarePlans( type: "patient") {
							id,
							first_name,
							last_name,
							coordinator {
								name
							},
						
						}
					}`,
				};
			}

			api.graph(query)
				.then((res) => {
					let data = [];
					if (selectedUserType === 'Provider') {
						const providers = res.fetchAllProvider.map((d, i) => {
							return {
								label: `${d.first_name} ${d.last_name}`,
								value: d.id,
							};
						});
						data = [...providers];
					} else if (selectedUserType === 'Patient') {
						const patients = res.fetchAllPatients.map((d, i) => {
							return {
								label: d.name,
								value: d.id,
							};
						});

						data = [...patients];
					} else {
						const carePlans = res.allCarePlans.map((d, i) => {
							console.log('d', d);

							return {
								label: `${d.first_name}, ${d.last_name}`,
								value: d.id,
								providers: [],
							};
						});
						data = [...carePlans];
					}

					receiveUsers(data);
				})
				.catch((err) => {
					console.log('something went wrong', err);
				});
		}
	}, [selectedUserType]);

	console.log('users', users);

	return (
		<Section defaultActive title={'FYI Report Generator'}>
			<ColumnFlex alignCenter>
				<Text margin='12px 0'>
					Please Select a type of report and date range for specfifed report
				</Text>
				<RowFlex margin='8px 0' center fullWidth>
					<Text margin='0 8px' bold>
						Export
					</Text>
					<select value={selectedReport} onChange={handleSelectChange}>
						<option value={''}></option>
						<option value={'invoice'}>Invoice</option>
						<option value={'care_provider'}>Care Giver</option>
						<option value={'patients'}>Patients</option>
						<option value={'general'}>General</option>
					</select>
					<Text margin='0 8px' bold>
						Report{' '}
					</Text>
				</RowFlex>

				<AnimateHeight duration={500} height={selectedReport === 'invoice' ? 'auto' : '0%'}>
					<RowFlex margin='8px 0' center fullWidth>
						<Text margin='0 8px' bold>
							By
						</Text>
						<select value={selectedUserType} onChange={handleSelectedUserType}>
							<option value={''}></option>
							<option value={'Patient'}>Patient</option>
							<option value={'Provider'}>Provider</option>
							<option value={'Care Plan'}>Care Plan</option>
						</select>
					</RowFlex>
				</AnimateHeight>
				<AnimateHeight
					duration={500}
					height={
						selectedReport === 'invoice' && selectedUserType !== null ? 'auto' : '0%'
					}
				>
					<RowFlex margin='8px 0' center fullWidth>
						<Text margin='0 8px' bold>
							{`${selectedUserType ? selectedUserType : 'User Selection'}`}
						</Text>
						<select value={selectedUser} onChange={handleSelectedUserChange}>
							<option value={''}></option>
							{users.map((d, i) => {
								return (
									<option key={d.label + d.vale} value={d.value}>
										{d.label}
									</option>
								);
							})}
						</select>
					</RowFlex>
				</AnimateHeight>
				<AnimateHeight
					duration={500}
					height={
						selectedReport === 'invoice' &&
						selectedUserType === 'Care Plan' &&
						selectedUser !== null
							? 'auto'
							: '0%'
					}
				>
					<RowFlex margin='8px 0' center fullWidth>
						<Text margin='0 8px' bold>
							For Provider
						</Text>
						<select
							value={selectedProviderInCarePlan}
							onChange={handleSelectedProviderChange}
						>
							{providersInCarePlan.map((d, i) => {
								return (
									<option key={`123-${d.value}`} value={d.value}>
										{d.label}
									</option>
								);
							})}
						</select>
					</RowFlex>
				</AnimateHeight>
				<Text bold>Within </Text>
				<Text margin='8px 0' />
				<DateRange
					editableDateInputs={true}
					onChange={(item) => setState([item.selection])}
					moveRangeOnFirstSelection={false}
					ranges={state}
				/>

				<AnimateHeight duration={500} height={selectedReport !== null ? 'auto' : '0%'}>
					<Button
						width='auto'
						margin='12px 0 12px 0'
						padding='0 8px'
						backgroundColor={Colors.blue}
						onClick={() => handleFetchingReport()}
					>{`Generate ${selectedReport ? selectedReport : ''} Report `}</Button>
				</AnimateHeight>
			</ColumnFlex>
		</Section>
	);
};
