import React, { useEffect, useState, createContext } from 'react';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { authRoutes } from '../../routes/constants';

export const PlatformOnboardingContext = createContext();

const initialPlatformOnboardingState = {
	personalInfo: {
		name: 'personalInfo',
		label: 'Personal Info',
		isCompleted: false,
		data: {
			firstName: '',
			lastName: '',
			gender: '',
			displayName: '',
		},
		route: authRoutes.onboarding.platform.personalInfo,
	},
	accountInfo: {
		name: 'accountInfo',
		label: 'Account',
		isCompleted: false,
		data: {
			email: '',
			phone: '',
			password: '',
		},
		route: authRoutes.onboarding.platform.accountInfo,
	},
	authCode: {
		name: 'authCode',
		label: 'Auth Code',
		isCompleted: false,
		data: {
			authCode: '',
		},
		route: authRoutes.onboarding.platform.authCode,
	},
};

export const PlatformOnboardingProvider = ({ children }) => {
	const [platformOnboardingState, setPlatformOnboardingState] = useState(
		initialPlatformOnboardingState
	);

	useEffect(() => {
		const localPlatformOnboardingState = getFromLocalStorage(
			localStorageKeys.FYI_CARE_PRO_PLATFORM_OBOARDING_STATE
		);

		if (localPlatformOnboardingState) {
			setPlatformOnboardingState(localPlatformOnboardingState);
		}
	}, []);

	const mutatePlatformOnboardingState = (key, value) => {
		setPlatformOnboardingState((prevState) => {
			const newState = {
				...prevState,
				[key]: {
					...prevState[key],
					...value,
				},
			};

			setInLocalStorage(
				localStorageKeys.FYI_CARE_PRO_PLATFORM_OBOARDING_STATE,
				JSON.stringify(newState)
			);
			return newState;
		});
	};

	const resetPlatformOnboardingState = () => {
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_PLATFORM_OBOARDING_STATE);
		setPlatformOnboardingState(initialPlatformOnboardingState);
	};

	const value = {
		platformOnboardingState,
		mutatePlatformOnboardingState,
		resetPlatformOnboardingState,
	};

	return (
		<PlatformOnboardingContext.Provider value={value}>
			{children}
		</PlatformOnboardingContext.Provider>
	);
};
