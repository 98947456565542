import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { PrimaryButton, DangerButton } from '../../../../../corev2/Buttons';
import EditDetailsForm from '../../components/EditDetailsForm';
import PaymentMethodForm from '../../components/PaymentMethodForm';
import PaymentMethod from '../../components/PaymentMethod';

import {
	StyledContainer,
	StyledWelcomeSection,
	StyledWelcomeHeading,
	StyledWelcomeText,
	StyledContentWrapper,
	StyledAccountSection,
	StyledInnerAccountSection,
	StyledPaymentSection,
	StyledInnerPaymentSection,
	StyledSectionTitle,
	StyledButtonsContainer,
	StyledPaymentMethodsContainer,
	StyledPaymentInfoText,
} from './styles.js';

import { useFetchUserSavedPaymentMethodsQuery } from '../../api/queries/useFetchUserSavedPaymentMethodsQuery';
import { useUpdateUserMutation } from '../../api/mutations/useUpdateUserMutation';
import { useDeletePaymentMethodMutation } from '../../api/mutations/useDeletePaymentMethodMutation';
import { useUpdateDefaultPaymentMethodMutation } from '../../api/mutations/useUpdateDefaultPaymentMethodMutation';
import { useUpdateUserStripeTokenMutation } from '../../api/mutations/useUpdateUserStripeTokenMutation';

import { Notification } from '../../../../../client/modules/core/lib';
import { notifications } from '../../../../../client/modules/core/constants';

import { useAuth } from '../../../core/hooks/useAuth';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const DashboardPage = () => {
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [serverError, setServerError] = useState('');

	const { authUser, logoutUser } = useAuth();

	const { data, isLoading, refetch: refreshUserData } = useFetchUserSavedPaymentMethodsQuery();

	const updateUser = useUpdateUserMutation();
	const deletePaymentMethod = useDeletePaymentMethodMutation();
	const updateDefaultPaymentMethod = useUpdateDefaultPaymentMethodMutation();
	const updateUserStripeToken = useUpdateUserStripeTokenMutation();

	useEffect(() => {
		if (data) setPaymentMethods(data.payment_methods);
	}, [data]);

	const updateUserhandler = async (values) => {
		try {
			await updateUser.mutateAsync({
				userId: authUser.id,
				userInputs: { email: values.email, phone_number: values.phone },
			});

			refreshUserData();
			Notification(notifications.dashboard.accountInfo.updated);
		} catch (error) {
			console.error('Updating the user failed', error);
			set;
		}
	};

	const addPaymentMethodHandler = async (tokenId) => {
		try {
			await updateUserStripeToken.mutateAsync({
				userId: authUser.id,
				tokenId,
			});

			Notification(notifications.dashboard.paymentMethods.paymentMethodAdded);
			refreshUserData();
		} catch (error) {
			console.error('Add payment method handler failed.', error);
		}
	};

	const updateDefaultPaymentMethodHandler = async (sourceId) => {
		try {
			await updateDefaultPaymentMethod.mutateAsync({
				sourceId,
			});

			Notification(notifications.dashboard.paymentMethods.defaultPaymentMethodChanged);
			refreshUserData();
		} catch (error) {
			console.error('Updating default payment method failed', error);
		}
	};

	const deletePaymentMethodHandler = async (sourceId) => {
		try {
			await deletePaymentMethod.mutateAsync({
				sourceId,
				userId: authUser.id,
			});

			Notification(notifications.dashboard.paymentMethods.paymentMethodDeleted);
			refreshUserData();
		} catch (error) {
			console.error('Deleting payment method failed', error);
		}
	};

	const logoutHandler = () => {
		logoutUser();
		Notification(notifications.auth.logout);
	};

	return (
		<StyledContainer>
			<StyledWelcomeSection>
				<StyledWelcomeHeading>WELCOME BACK</StyledWelcomeHeading>
				<StyledWelcomeText>
					This is the Settings section for patients such as yourself
				</StyledWelcomeText>
			</StyledWelcomeSection>

			<StyledContentWrapper>
				<StyledAccountSection>
					<StyledSectionTitle>ACCOUNT</StyledSectionTitle>
					<StyledInnerAccountSection>
						<EditDetailsForm
							initialValues={{ email: authUser.email, phone: authUser.phone_number }}
							updateUserhandler={updateUserhandler}
							serverError={serverError}
						/>

						<StyledButtonsContainer>
							<PrimaryButton size='small'>Change Password</PrimaryButton>
							<PrimaryButton size='small'>Export Invoices</PrimaryButton>
							<DangerButton size='small' onClick={logoutHandler}>
								Log Out
							</DangerButton>
						</StyledButtonsContainer>
					</StyledInnerAccountSection>
				</StyledAccountSection>

				<StyledPaymentSection>
					<StyledSectionTitle>PAYMENT METHODS</StyledSectionTitle>
					<StyledInnerPaymentSection>
						<StyledPaymentInfoText>
							In order to provide our best service we must have a card on file. Please
							add your card information below.
						</StyledPaymentInfoText>

						<StyledPaymentMethodsContainer>
							{paymentMethods.map((paymentMethod) => {
								return (
									<PaymentMethod
										key={paymentMethod.id}
										id={paymentMethod.id}
										last4={paymentMethod.last_4}
										expiryMonth={paymentMethod.exp_month}
										expiryYear={paymentMethod.exp_year}
										cardBrand={paymentMethod.brand}
										isDefault={paymentMethod.default_card}
										updateDefaultPaymentMethodHandler={
											updateDefaultPaymentMethodHandler
										}
										deletePaymentMethodHandler={deletePaymentMethodHandler}
									/>
								);
							})}
						</StyledPaymentMethodsContainer>

						<Elements stripe={stripePromise}>
							<PaymentMethodForm addPaymentMethodHandler={addPaymentMethodHandler} />
						</Elements>
					</StyledInnerPaymentSection>
				</StyledPaymentSection>
			</StyledContentWrapper>
		</StyledContainer>
	);
};

export default DashboardPage;
