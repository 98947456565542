import { useMutation } from '@tanstack/react-query';
import api from 'scripts/api';

import { TRAIT_MODEL } from '../models';

const addTrait = async ({
	label,
	required,
	active,
	traitType,
	sectionId,
	traitTemplateId,
	description,
	meta_data,
	orderIndex,
}) => {
	try {
		const data = await api.graph({
			query: `mutation {
        addTrait ( 
          label: "${label}", 
          required: ${required},
          active: ${active},
          trait_type: "${traitType}",
          section_id: ${sectionId},
          trait_template_id: ${traitTemplateId},
          order_index: ${orderIndex},
          ${description ? `description: "${description}",` : ''}
          ${meta_data ? `meta_data: ${JSON.stringify(meta_data)}` : ''}

        ) {
          ${TRAIT_MODEL}
        }
      }`,
		});

		return data.addTrait;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

const useAddTrait = () => {
	return useMutation({
		mutationKey: ['addTrait'],
		mutationFn: (values) => {
			return addTrait(values);
		},
	});
};

export default useAddTrait;
