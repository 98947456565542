import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { DOCUMENT_MODEL } from '../../../core/api/models';

const fetchUploadedDocuments = async () => {
	try {
		const data = await api.graph({
			query: `{
				fetchUserUploadedDocuments {
					${DOCUMENT_MODEL}
				}
			}`,
		});

		return data.fetchUserUploadedDocuments;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchUploadedDocumentsQuery = () => {
	return useQuery({
		queryKey: ['fetchUploadedDocuments'],
		queryFn: () => {
			return fetchUploadedDocuments();
		},
		retry: false,
	});
};
