import React from 'react';
import { API_ROOT } from '../constants';

export default {
	graphStringify(json) {
		json = JSON.stringify(json);
		return json.replace(/\"([^(\")"]+)\":/g, '$1:');
	},
	get(route, query) {
		return fetch(API_ROOT + route, {
			method: 'GET',
			crossDomain: true,
			xhrFields: {
				withCredentials: true,
			},
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			data: JSON.stringify(query),
		})
			.then((res) => {
				console.log(res);
				res.json();
			})
			.catch((err) => console.error(err));
	},
	getCSV(route, query) {
		return fetch(API_ROOT + route, {
			method: 'GET',
			crossDomain: true,
			xhrFields: {
				withCredentials: true,
			},

			headers: {
				Accept: 'text/csv',
			},
		})
			.then((response) => {
				console.log(response);
				return response.blob();
			})
			.then((blob) => {
				console.log(blob);
				var url = window.URL.createObjectURL(blob);
				var a = document.createElement('a');
				a.href = url;
				a.download = 'download.csv';
				document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
				a.click();
				a.remove(); //afterwards we remove the element again
			})
			.catch((err) => console.error(err));
	},
	post(route, data) {
		return fetch(API_ROOT + route, {
			method: 'POST',
			xhrFields: {
				withCredentials: true,
			},
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
			credentials: 'include',
		})
			.then((res) => res.json())
			.then((res) => {
				return res;
			})
			.catch((err) => console.error(err));
	},
	graph(graphData) {
		// get the token from local stroage
		const token = localStorage.getItem('user-token');
		// add the token to the headers

		let headers = {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		};

		if (token != null) {
			headers.Authorization = `Bearer ${token}`;
		}

		return fetch(API_ROOT + 'graph', {
			method: 'POST',
			crossDomain: true,
			xhrFields: {
				withCredentials: true,
			},
			headers: headers,
			body: JSON.stringify(graphData),
			credentials: 'include',
		})
			.then((res) => res.json())
			.then((payload) => {
				if (payload && payload.errors) {
					throw payload.errors;
				} else if (payload && payload.data && payload.data != null) {
					return payload.data;
				} else {
					throw 'network error';
				}
			})
			.catch((err) => {
				throw err;
			});
	},
	getExt(route, headers, query) {
		return fetch(route, {
			method: 'GET',
			crossDomain: true,
			headers: headers,
		})
			.then((res) => res.json())
			.then((res) => {
				return res;
			})
			.catch((err) => console.error(err));
	},
	postExt(route, headers, data) {
		return fetch(route, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			body: data,
		})
			.then((res) => {
				return res;
			})
			.catch((err) => console.error(err));
	},
};
