import React from 'react';

import styled from 'styled-components';

import Colors from 'colors';
export const StyledSkillsPage = styled.div`
	padding: 50px 25px;
	margin-bottom: 20px;

	width: 100%;
	max-width: 1100px;

	display: flex;
	flex-direction: column;
	gap: 40px;

	margin: auto;
`;

export const SkillsContainer = styled.div`
	width: 70%;

	display: flex;
	flex-direction: column;
	gap: 20px;

	margin: auto;
	background-color: white;
`;

export const SkillsFormCon = styled.div`
	max-width: 100%;
	border: 1px solid ${Colors.primary.primary};
	border-radius: 8px;
	padding: 40px;
	background: ${Colors.white};
	cursor: pointer;

	display: flex;
	flex-direction: column;
	gap: 0px;
`;

export const StyledPageHeadingContainer = styled.div`
	display: flex;
	justify-content: center;
`;

export const StyledPageHeading = styled.h1`
	color: ${Colors.primary.primary};
	font-family: 'Bellota';
	letter-spacing: 2px;
	margin: 0 auto 20px auto;
	line-height: 1.4;
	font-size: 1.8em;
`;

export const LoaderSection = styled.div`
	min-height: 100vh;
	max-width: 1000px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-items: flex-start;
	gap: 24px;

	margin: auto;
`;

export const PageHeading = (props) => {
	const { children } = props;

	return <StyledPageHeading>{children}</StyledPageHeading>;
};
