import React, { useState } from 'react';
import MultiRangeSlider from 'multi-range-slider-react';
import { AnimatePresence, motion } from 'framer-motion';

import { ColumnFlex, RowFlex } from 'basicStyles';

import Colors from 'colors';
import styled from 'styled-components';

import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import Title from 'AppComp/Title';

import { ButtonTing, InputItemsContainer } from './CreateJobViews';

const MainPanel = styled(motion.div)`
	position: fixed;
	display: flex;
	height: fit-content;
	width: fit-content;
	z-index: 100;
	left: 0;
	display: flex;
	flex-direction: column;
	background-color: ${Colors.primary.primary};
	top: 0;
	border: 12px;

	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;

const framerPanel = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};

const framerSidebarBackground = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};

export const BackDropBg = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(4px);
`;

export const ButtonTingTing = styled(RowFlex)`
	border: solid ${({ color }) => color || Colors.greenLightTeal} 2px;
	border-radius: 32px;
	padding: 12px 16px;
	cursor: pointer;

	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}
`;

const StyledDateInput = styled.input`
	border: solid 2px ${Colors.greenLightTeal};
	background-color: white;
	padding: 12px 16px;
	border-radius: 32px;
	cursor: pointer;
	color: ${Colors.greenLightTeal};
`;

const WeekDayTile = (props) => {
	const { id, day, handleClickDate, selectedDays } = props;

	// check that id is in selectedDays
	const isSelected = selectedDays.includes(id);

	return (
		<ButtonTingTing
			onClick={() => handleClickDate(id)}
			center
			style={{
				width: '80px',
				backgroundColor: isSelected ? Colors.greenLightTeal : 'white',
			}}
		>
			<Text fontWeight='300' color={isSelected ? 'white' : Colors.greenLightTeal} mediumText>
				{day}
			</Text>
		</ButtonTingTing>
	);
};

const TimeFramesTiles = (props) => {
	const { id, timeFrame, handleClickTimeFrame, selectedTimeFrames } = props;

	// check that id is in selectedTimeFrames
	const isSelected = selectedTimeFrames.includes(id);

	return (
		<ButtonTingTing
			onClick={() => handleClickTimeFrame(id)}
			center
			style={{
				width: '140px',
				backgroundColor: isSelected ? Colors.greenLightTeal : 'white',
			}}
		>
			<Text fontWeight='300' color={isSelected ? 'white' : Colors.greenLightTeal} mediumText>
				{timeFrame}
			</Text>
		</ButtonTingTing>
	);
};
const CreateSchedule = (props) => {
	const [selectedDays, setSelectedDays] = useState([]);
	const [selectedTimeFrames, setSelectedTimeFrames] = useState([]);

	const [selectedStartDate, setSelectedStartDate] = useState(
		new Date().toISOString().split('T')[0]
	);

	const [selectedEndDate, setSelectedEndDate] = useState(new Date());
	// e being the id of the day
	const handleClickDate = (e) => {
		if (selectedDays.includes(e)) {
			setSelectedDays(selectedDays.filter((day) => day !== e));
		} else {
			setSelectedDays([...selectedDays, e]);
		}
	};

	const handleClickTimeFrame = (e) => {
		if (selectedTimeFrames.includes(e)) {
			setSelectedTimeFrames(selectedTimeFrames.filter((timeFrame) => timeFrame !== e));
		} else {
			setSelectedTimeFrames([...selectedTimeFrames, e]);
		}
	};

	/* 
    date tile format {
      start: Date,
      end: Date,
      title: String,
      allDay: Boolean (default false),
      
    }
  */
	const handleConfirmation = () => {
		// okay so this is something

		// if the selected time frames direct neighbors are selected, then we can merge them into one
		// although we should never merge more than 2 since that would be 12 hours

		// we also can't mix night with morning shifts
		// so to summarize
		// we can merge 6am - 12pm with 12pm - 6pm
		// but we can't merge 6pm - 12am with 12am - 6am

		// check if the selectedTimeFrames are direct neighbors
		/* 
      0: 6am - 12pm
      1: 12pm - 6pm
      2: 6pm - 12am
      3: 12am - 6am
    */

		const dateTiles = [];

		if (selectedTimeFrames.includes(0) && selectedTimeFrames.includes(1)) {
			// i can merge the tiles
			// convert the local to utc time
			const start = new Date();
			start.setHours(6, 0, 0, 0);
			const end = new Date();
			end.setHours(18, 0, 0, 0);
		}
	};
	return (
		<>
			<BackDropBg
				onClick={() => props.setShowAddScheduleOverlay(false)}
				{...framerSidebarBackground}
				aria-hidden='true'
			></BackDropBg>
			<MainPanel
				aria-label='Sidebar'
				{...framerPanel}
				style={{
					backgroundColor: 'white',
					padding: '20px',
					borderRadius: '12px',
				}}
			>
				<InputItemsContainer>
					<ColumnFlex gap='32px' center>
						<ColumnFlex gap='8px' center>
							<Title bold subtitle>
								When do you need care?
							</Title>
						</ColumnFlex>
						<ColumnFlex
							margin='16px 0 0 0'
							gap='40px'
							style={
								{
									//width: '400px',
								}
							}
						>
							<RowFlex wrap center gap='8px'>
								<WeekDayTile
									id={1}
									selectedDays={selectedDays}
									handleClickDate={handleClickDate}
									day='Mon'
								/>
								<WeekDayTile
									id={2}
									selectedDays={selectedDays}
									handleClickDate={handleClickDate}
									day='Tue'
								/>
								<WeekDayTile
									id={3}
									selectedDays={selectedDays}
									handleClickDate={handleClickDate}
									day='Wed'
								/>
								<WeekDayTile
									id={4}
									selectedDays={selectedDays}
									handleClickDate={handleClickDate}
									day='Thu'
								/>
								<WeekDayTile
									id={5}
									selectedDays={selectedDays}
									handleClickDate={handleClickDate}
									day='Fri'
								/>
								<WeekDayTile
									id={6}
									selectedDays={selectedDays}
									handleClickDate={handleClickDate}
									day='Sat'
								/>
								<WeekDayTile
									id={7}
									selectedDays={selectedDays}
									handleClickDate={handleClickDate}
									day='Sun'
								/>
							</RowFlex>
							<RowFlex center gap='16px'>
								<ColumnFlex gap='4px' alignStart>
									<Text>Start Time</Text>
									{
										// ensure you can't go back in time
									}
									<StyledDateInput
										min={new Date().toISOString().split('T')[0]}
										type='time'
									/>
								</ColumnFlex>
								<ColumnFlex gap='4px' alignStart>
									<Text>Till</Text>
									{
										// ensure you can't go back in time
									}
									<StyledDateInput
										min={new Date().toISOString().split('T')[0]}
										type='time'
									/>
								</ColumnFlex>
							</RowFlex>
							<RowFlex fullWidth center>
								<ColumnFlex gap='4px' alignStart>
									<Text>Starting</Text>
									{
										// ensure you can't go back in time
									}
									<StyledDateInput
										min={new Date().toISOString().split('T')[0]}
										type='date'
									/>
								</ColumnFlex>
								<ColumnFlex gap='4px' alignStart>
									<Text>Till</Text>
									{
										// ensure you can't go back in time
									}
									<StyledDateInput
										min={new Date().toISOString().split('T')[0]}
										type='date'
									/>
								</ColumnFlex>
							</RowFlex>

							<RowFlex center gap='16px'>
								<ColumnFlex gap='4px' alignStart>
									<Text>Repeat every</Text>
								</ColumnFlex>
							</RowFlex>
							<Text
								style={{
									color: Colors.darkerGray,
								}}
								fontStyle='italic'
							>
								These can always be changed later
							</Text>
						</ColumnFlex>
						<ButtonTing
							style={{
								borderRadius: '32px',
								width: '180px',
								height: '50px',
							}}
						>
							<Text fontWeight='500' cursor color={'white'} largeText>
								Continue
							</Text>
						</ButtonTing>
					</ColumnFlex>
				</InputItemsContainer>
			</MainPanel>
		</>
	);
};

export default CreateSchedule;
