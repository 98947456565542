import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

import Loader from '../../../../../corev2/Loader';
import { Select } from '../../../../../corev2/Forms';
import Calendar from '../../../../../corev2/Calendar';

import PageHeading from '../../../core/components/PageHeading';

import { useAuth } from '../../../core/hooks/useAuth';
import { useJobPost } from '../../hooks/useJobPost';

import { JobPostProvider } from '../../context/JobPostContext';

import { generateEvents, calendarEventStyleGenerator } from '../../lib';

import {
	StyledJobSchedulePage,
	StyledContainer,
	StyledCalendarHeader,
	StyledNavContainer,
	StyledHeadingContainer,
	StyledCalendarheading,
	StyledCalendarSelectGroup,
	StyledBlueSelect,
} from './styles';

const JobScheduleContent = () => {
	const [events, setEvents] = useState([]);
	const [view, setView] = useState('month');
	const [currentDate, setCurrentDate] = useState(new Date());

	const { jobPost, jobStatus, isLoadingJobPost, updateJobPost } = useJobPost();

	useEffect(() => {
		if (jobPost) setEvents(generateEvents(jobPost.schedules, jobPost.jobs, currentDate, view));
	}, [jobPost, currentDate, view]);

	const handleNavigateLeft = () => {
		if (view === 'month') {
			setCurrentDate(moment(currentDate).subtract(1, 'month').toDate());
		} else if (view === 'week') {
			setCurrentDate(moment(currentDate).subtract(1, 'week').toDate());
		} else if (view === 'day') {
			setCurrentDate(moment(currentDate).subtract(1, 'day').toDate());
		}
	};

	const handleNavigateRight = () => {
		if (view === 'month') {
			setCurrentDate(moment(currentDate).add(1, 'month').toDate());
		} else if (view === 'week') {
			setCurrentDate(moment(currentDate).add(1, 'week').toDate());
		} else if (view === 'day') {
			setCurrentDate(moment(currentDate).add(1, 'day').toDate());
		}
	};

	const handleViewChange = (event) => setView(event.target.value);

	if (isLoadingJobPost) return <Loader />;

	return (
		<StyledJobSchedulePage>
			<PageHeading>Schedule for {jobPost.title}</PageHeading>

			<StyledContainer>
				<StyledCalendarHeader>
					<StyledNavContainer>
						<StyledBlueSelect>
							<FontAwesomeIcon
								size='xs'
								icon={faChevronLeft}
								onClick={handleNavigateLeft}
							/>
						</StyledBlueSelect>

						<StyledCalendarheading>
							{view === 'day'
								? moment(currentDate).format('DD MMMM YYYY')
								: moment(currentDate).format('MMMM YYYY')}
						</StyledCalendarheading>

						<StyledBlueSelect>
							<FontAwesomeIcon
								size='xs'
								icon={faChevronRight}
								onClick={handleNavigateRight}
							/>
						</StyledBlueSelect>
					</StyledNavContainer>

					<StyledHeadingContainer>
						<StyledCalendarSelectGroup>
							<Select value={view} onChange={handleViewChange}>
								<option value='month'>Month</option>
								<option value='week'>Week</option>
								<option value='day'>Day</option>
								<option value='agenda'>Agenda</option>
							</Select>
						</StyledCalendarSelectGroup>
					</StyledHeadingContainer>
				</StyledCalendarHeader>

				<Calendar
					events={events}
					currentDate={currentDate}
					view={view}
					onNavigate={setCurrentDate}
					onView={setView}
					onSelectEvent={() => {}}
					eventStyleGenerator={calendarEventStyleGenerator}
				/>
			</StyledContainer>
		</StyledJobSchedulePage>
	);
};

const JobSchedulePage = () => {
	return (
		<JobPostProvider>
			<JobScheduleContent />
		</JobPostProvider>
	);
};

export default JobSchedulePage;
