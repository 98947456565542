import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import Tiles from 'AppComp/core/Tiles';
import api from 'scripts/api';

import { CORE_JOB_POST_MODEL } from 'reducers/graphqlModelTypes';

import { StyledContainer, StyledWrapper, StyledJobPostsWrapper } from './styles';
import NavSelector, { navItemsModifier } from 'AppComp/core/NavSelector';

export const NAV_ITEMS = {
	jobPosts: {
		text: 'Job Posts',
		stat: 0,
		active: true,
	},
	appliedJobPosts: {
		text: 'Applied',
		stat: 0,
		active: false,
	},
};

const JobPostsPage = (props) => {
	const { provider } = props;
	const [jobPosts, setJobPosts] = useState([]);
	const [navItems, setNavItems] = useState(NAV_ITEMS);

	const history = useHistory();

	const onJobPostClick = (jobPostId) => {
		history.push('/carepro/job-posts/' + jobPostId + '/apply');
	};

	const navSelectorHandler = (item, modifier, value) => {
		const updatedNavItems = navItemsModifier(navItems, item, modifier, value);

		setNavItems(updatedNavItems);
	};

	const fetchJobPosts = async () => {
		try {
			// _loading(true);
			const registry_id = 1;

			const jobPosts = await api.graph({
				query: `{
					fetchRegistryJobPosts(registry_id: ${registry_id}) {
						${CORE_JOB_POST_MODEL}
					}
				}`,
			});

			if (jobPosts.fetchRegistryJobPosts) {
				console.log(jobPosts.fetchRegistryJobPosts);
				setJobPosts(jobPosts.fetchRegistryJobPosts);
			}
		} catch (err) {
			console.log(err);
			// _loading(false);
			throw new Error(err);
		}
	};

	useEffect(() => {
		fetchJobPosts();
	}, []);

	return (
		<StyledContainer>
			<StyledWrapper>
				<NavSelector items={navItems} navSelectorHandler={navSelectorHandler} />

				<StyledJobPostsWrapper>
					{jobPosts.map((jobPost) => {
						return (
							<Tiles.JobPostTile
								key={jobPost.id}
								jobPost={jobPost}
								onJobPostClick={onJobPostClick}
							></Tiles.JobPostTile>
						);
					})}
				</StyledJobPostsWrapper>
			</StyledWrapper>
		</StyledContainer>
	);
};

const mapStateToProps = ({ provider }) => {
	return {
		provider: provider.provider,
	};
};

export default connect(mapStateToProps)(JobPostsPage);
