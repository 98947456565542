import React from 'react';

import { PrimaryButton, SecondaryButton } from '../../Buttons';

import {
	StyledTileContainer,
	StyledAvatarContainer,
	StyledPersonalInfoContainer,
	StyledPersonalInfoWrapper,
	StyledPersonalInfo,
	StyledName,
	StyledRate,
	StyledHour,
	StyledLocation,
	StyledDescriptionContainer,
	StyledDescription,
	StyledButtonContainer,
} from './styles';

const ClientTile = (props) => {
	const {
		id,
		name,
		hourlyRate,
		jobDescription,
		customButtonText,
		onCustomButtonClick,
		viewClientProfileHandler,
	} = props;

	return (
		<StyledTileContainer>
			<StyledAvatarContainer />

			<StyledPersonalInfoContainer>
				<StyledPersonalInfoWrapper>
					<StyledPersonalInfo>
						<StyledName>{name}</StyledName>
					</StyledPersonalInfo>
					<StyledRate>
						<StyledHour>${hourlyRate}/ hr </StyledHour>
					</StyledRate>
				</StyledPersonalInfoWrapper>
				<StyledLocation>Lake Raymondport</StyledLocation>
				<StyledDescriptionContainer>
					<StyledDescription>{jobDescription}</StyledDescription>
				</StyledDescriptionContainer>
				<StyledButtonContainer>
					{customButtonText && (
						<PrimaryButton size='small' onClick={() => onCustomButtonClick(id)}>
							{customButtonText}
						</PrimaryButton>
					)}

					<SecondaryButton size='small' onClick={() => viewClientProfileHandler(id)}>
						View
					</SecondaryButton>
				</StyledButtonContainer>
			</StyledPersonalInfoContainer>
		</StyledTileContainer>
	);
};

export default ClientTile;
