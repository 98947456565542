import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

import Colors from 'colors';
import api from 'scripts/api';
import { TOAST_ERROR_CONFIG } from 'constants';

import {
	fetchCareProUploadedDocuments,
	receiveUploadedDocuments,
	fetchCareProDocumentRequirement,
} from 'reducers/provider';
import { DOCUMENT_MODEL } from 'reducers/graphqlModelTypes';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import { ColumnFlex, RowFlex } from 'AppComp/styled/Basic';
import Upload, { DisplayUploadedDocument } from 'AppComp/patient/Documents/Upload';
import { ParentContainer } from 'AppComp/provider/ProviderHomeDashboard/ProviderHomeDashboardView';
import { PHDColumn } from 'AppComp/provider/ProviderHomeDashboard';
import { OptionSectionCareProDoc } from 'AppComp/patient/Options/OptionsViews';
import useWindowSize from 'scripts/useWindowSize';

export const toastSuccessConfig = {
	containerId: 'basic',
	autoClose: true,
};

const ModelInnerCon = styled.div`
	background-color: white;
	border-radius: 8px;
	flex: 1;
	padding: 10px 20px 10px 20px;
`;
const DocumentHandler = (props) => {
	const {
		provider,
		fetchCareProUploadedDocuments,
		careProDocumentRequirements,
		fetchCareProDocumentRequirement,
		uploadedDocuments,
		careProUploadedDocument,
		receiveUploadedDocuments,
	} = props;

	const { width } = useWindowSize();

	useEffect(() => {
		fetchCareProDocumentRequirement();
	}, []);
	useEffect(() => {
		if (provider) {
			//fetchCareProDocumentRequirement();
			fetchCareProUploadedDocuments();
		}
	}, [provider]);

	const handleUploadCallBackParent = async (
		document_urls,
		required_document_id,
		setLoading,
		expiration_date
	) => {
		try {
			console.log('is this handleUploadCallBackParent running');
			const res = await api.graph({
				query: `mutation {
					careProUploadDocument(
						document_urls: ${api.graphStringify(document_urls)}
						required_document_id: ${required_document_id}
						${expiration_date ? `expiration_date: "${expiration_date}"` : ''}
					) {
						${DOCUMENT_MODEL}
					}
				}`,
			});

			console.log('careProUploadDocumentRes', res);
			if (res.errors) {
				toast.error(res.errors[0].message, TOAST_ERROR_CONFIG);
			}

			if (res.careProUploadDocument) {
				toast.success('Document Uploaded Successfully', toastSuccessConfig);

				receiveUploadedDocuments(res.careProUploadDocument);
			}
			setLoading(false);
			return true;
		} catch (err) {
			console.log('careProUploadDocument err', err);

			toast.error('Something went wrong uploading, please try again', TOAST_ERROR_CONFIG);

			console.log(err);
		}
	};

	const displayNotUploadedDocs = () => {
		// we need to only show the documents that have not been uplaoded
		// ergo we need to check from the uploaded documents and the careProDocumentRequirements

		// create a look up of the uploaded documents by the documentRequirment id
		const uploadedDocumentsLookup = uploadedDocuments.reduce((acc, item) => {
			const documentRequirement = item.documentRequirement;

			if (!documentRequirement) {
				return acc;
			} else {
				acc[documentRequirement.id] = item;
			}

			return acc;
		}, {});

		// we don't care their status we only chare to show them on not in this section

		// filter out the documents that have been uploaded
		const notUploadedDocuments = careProDocumentRequirements.filter((item) => {
			// ensure the documentRequirment is on the model

			if (uploadedDocumentsLookup[item.id]) {
				return false;
			}
			return true;
		});

		if (notUploadedDocuments.length === 0) {
			return (
				<ColumnFlex>
					<Text>No Documents to Upload</Text>
				</ColumnFlex>
			);
		}
		return notUploadedDocuments.map((item, index) => {
			// we need to check if there has been an upload for this requirment and if it's been approved to show as the status
			const approved = uploadedDocuments.filter((doc) => {
				if (doc.documentRequirement.id === item.id) {
					if (doc.status === 'pending') {
						return true;
					}
				}
				return false;
			});

			const pending = uploadedDocuments.filter((doc) => {
				if (doc.documentRequirement.id === item.id) {
					if (doc.status === 'pending') {
						return true;
					}
				}
				return false;
			});

			let status = 'not_uploaded';
			let message = '';
			if (pending.length > 0) {
				message = `${pending.length} ${
					pending.length > 1 ? 'Documents' : 'Document'
				} Pending Approval`;
				status = 'pending';
			} else if (approved.length > 0) {
				message = 'Uploaded Document Approved';
				status = 'approved';
			}

			console.log('item', item);
			const _params = {
				document_name: item.name,
				status: status,
				required: item.required,
				requires_expiration: item.requires_expiration ? true : false,
				approved: approved,
				pending: pending,
				message: message,
				requiredDocumentId: item.id,
				required_document_id: item.id,
			};

			console.log('_params', _params);
			return (
				<OptionSectionCareProDoc
					key={`${item.name}-pendingItems::${pending.length}`}
					requiredDocumentId={item.id}
					{..._params}
				>
					<Upload
						document_name={item.name}
						handleUploadCallBackParent={handleUploadCallBackParent}
						uploaded={item}
						requiredDocumentId={item.id}
						{..._params}
					/>
				</OptionSectionCareProDoc>
			);
		});
	};

	const displayUploadedDocs = () => {
		const reverseTheOrder = [...uploadedDocuments].reverse();
		return reverseTheOrder.map((item, index) => {
			return (
				<OptionSectionCareProDoc key={index} {...item}>
					<RowFlex gap='16px'>
						{/* <Upload
							document_name={item.name}
							handleUploadCallBackParent={handleUploadCallBackParent}
							uploaded={item}
						/> */}
						<DisplayUploadedDocument uploaded={item} />
						<ModelInnerCon>
							<ColumnFlex>
								<Text bold>Uploaded:</Text>
								<Text>
									{
										// parse the gmt date to local time
										new Date(item.uploaded_date).toLocaleString()
									}
								</Text>

								{item.rejection_notes && (
									<>
										<Text bold>Rejection Reason:</Text>
										<Text>{item.rejection_notes}</Text>
									</>
								)}
							</ColumnFlex>
						</ModelInnerCon>
					</RowFlex>
				</OptionSectionCareProDoc>
			);
		});
	};
	return (
		<ParentContainer
			style={{
				minHeight: '100vh',
				padding: isMobile ? '20px 10px' : '20px 32px 20px 32px',
			}}
		>
			<RowFlex
				columnMobile
				fullWidth
				padding='20px 0 0 0'
				style={{
					maxWidth: '1200px',
				}}
			>
				<PHDColumn
					style={{
						flex: 1,
					}}
					gap='24px'
				>
					<Title fontWeight='100' nubtitle color={Colors.primary.primary}>
						Documents
					</Title>
					<Text fontWeight='100' color={'black'} largeText>
						In order to best serve our clients, we require that you upload the following
					</Text>
					<Text fontWeight='100' color={'black'} largeText>
						If you have any questions, please reach out to us in our chat
					</Text>
				</PHDColumn>
				<PHDColumn
					style={{
						height: 'fit-content',
						backgroundColor: 'transparent',
						width: width > 1000 ? '50%' : '100%',
						flex: 1,
					}}
				>
					<Title bold nubtitle color={Colors.secondary.green}>
						Document Request
					</Title>

					<RowFlex wrap gap='12px' margin='20px 0 0 0' fullWidth>
						{displayNotUploadedDocs()}
					</RowFlex>
					<Title bold nubtitle margin='32px 0 0 0 ' color={Colors.secondary.green}>
						Uploaded Documents
					</Title>

					<RowFlex wrap gap='12px' margin='20px 0 0 0' fullWidth>
						{displayUploadedDocs()}
					</RowFlex>
				</PHDColumn>
			</RowFlex>
		</ParentContainer>
	);
};

const mapStateToProps = ({ provider, admin }, props) => {
	return {
		uploadedDocuments: provider.uploadedDocuments,
		careProDocumentRequirements: admin.careProDocumentRequirements,
		provider: provider.provider,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		fetchCareProUploadedDocuments: () => dispatch(fetchCareProUploadedDocuments()),
		receiveUploadedDocuments: (docs) => dispatch(receiveUploadedDocuments(docs)),
		fetchCareProDocumentRequirement: () => dispatch(fetchCareProDocumentRequirement()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DocumentHandler);
