import React, { useState, Fragment, useEffect } from 'react';

const JobPostOnboard = (props) => {
	return (
		<Fragment>
			<div>JobPostOnboard</div>
		</Fragment>
	);
};

export default JobPostOnboard;
