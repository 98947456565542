import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledShift = styled.div`
	display: flex;
	font-family: -apple-system, 'system-ui', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
		'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	flex-direction: column;
	justify-content: space-between;
	align-items: left;
	padding: 10px;
	background-color: ${colors.white};
	border-radius: 5px;
	border: 1px solid ${colors.table.border};
	font-size: 14px;
	margin-bottom: 10px;
	cursor: pointer;
	color: ${colors.primary.primary} @media ${mediaQueries.tablet} {
		font-size: 13px;
	}

	@media ${mediaQueries.mobileL} {
		font-size: 12px;
		padding: 8px;
	}
`;

export const StyledShiftHeader = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const StyledLabel = styled.span`
	font-weight: 600;
	color: ${colors.primary.primary};
	font-size: 16px !important;

	@media ${mediaQueries.tablet} {
		font-size: 12px;
	}

	@media ${mediaQueries.mobileL} {
		font-size: 11px;
	}
`;

export const StyledCheckIcon = styled.div`
	width: 15px;
	height: 15px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: ${colors.primary.primary};
	border-radius: 50%;

	color: ${colors.white};
`;

export const StyledTimeContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

export const StyledValue = styled.span`
	color: #333;
	font-weight: 400;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.4;

	@media ${mediaQueries.tablet} {
		font-size: 12px;
	}

	@media ${mediaQueries.mobileL} {
		font-size: 11px;
	}
`;
