import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';

import { TOAST_SUCCESS_CONFIG, TOAST_ERROR_CONFIG } from 'constants';

import api from 'scripts/api';

import { PAYMENT_TYPE_MODEL, USER_MODEL_V2, PROVIDER_MODEL_V2 } from 'reducers/graphqlModelTypes';

const Container = styled.div`
	padding: 20px;
	font-family: Arial, sans-serif;
	background-color: #f0f4f8;
	min-height: 100vh;
`;

const Title = styled.h1`
	text-align: center;
	margin-bottom: 20px;
	font-size: 2em;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(${(props) => props.columns}, 1fr);
	gap: 20px;
	margin-bottom: 40px;
`;

const Item = styled.div`
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 10px;
	background-color: white;
`;
// const ItemCon = (props) => {
// 	const { children  } = props;

// 	return <ItemCon>
// 		{children}
// 	</ItemCon>
// }

const Label = styled.h6`
	margin-bottom: 5px;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.4;
`;

const Input = styled.h3`
	font-size: 16px;
	font-weight: 700;
	line-height: 1.4;
`;
/*
 * items that are expected to be less than 10 characters should be placed in 5 grid (such as status, amounts, etc.)
 * items that are expected to be less than 20 characters should be placed in 4 grid (such as dates, full names etc)
 * items that are expected to be less than 30 characters should be placed in 3 grid (such as addresses, emails, error reason)
 * items more than potentially 50 should be split column (list, open text, etc)
 */

// this expects the full shift model
// will not render any sublevel types such as Job but will render ShiftCareAcitivty Set (eventually)
/* 
	SHIFT_MODEL 
*/
export const ShiftDetail = (props) => {
	const {
		id,
		status,
		clock_in_time,
		clock_out_time,
		start_time,
		end_time,
		client_signature_url,
		location_lat,
		location_long,
		clock_out_location_lat,
		clock_out_location_long,
		notes,
		care_plan_options,
		rating,
		confirmed_shift_time,
	} = props;
	return (
		<>
			<Title>Shift</Title>
			<GridContainer columns={5}>
				<Item>
					<Label>ID</Label>
					<Input>{id}</Input>
				</Item>
				<Item>
					<Label>Status</Label>
					<Input>{status}</Input>
				</Item>

				<Item>
					<Label>Confirmed Shit Time</Label>
					<Input>{confirmed_shift_time}</Input>
				</Item>
			</GridContainer>
			<GridContainer columns={4}>
				<Item>
					<Label>Actual Clock In Time</Label>
					<Input>{clock_in_time}</Input>
				</Item>
				<Item>
					<Label>Actual Clock Out Time</Label>
					<Input>{clock_out_time}</Input>
				</Item>
				<Item>
					<Label>Scheduled Start Time</Label>
					<Input>{start_time}</Input>
				</Item>
				<Item>
					<Label>Scheduled End Time</Label>
					<Input>{end_time}</Input>
				</Item>
				<Item>
					<Label>Location Lat</Label>
					<Input>{location_lat}</Input>
				</Item>
				<Item>
					<Label>Location Long</Label>
					<Input>{location_long}</Input>
				</Item>
				<Item>
					<Label>Clock Out Location lat</Label>
					<Input>{clock_out_location_lat}</Input>
				</Item>
				<Item>
					<Label>Clock Out Location Long</Label>
					<Input>{clock_out_location_long}</Input>
				</Item>
			</GridContainer>
			<GridContainer columns={3}>
				<Item>
					<Label>Sig Url</Label>
					<Input>{client_signature_url}</Input>
				</Item>
			</GridContainer>
			<GridContainer columns={2}>
				<Item>
					<Label>Notes</Label>
					<Input>{notes}</Input>
				</Item>
			</GridContainer>
		</>
	);
};

export const PaymentDetail = (props) => {
	const {
		id,
		stripe_payment_intent_id,
		hourly_rate,
		total_time_worked,
		subtotal,
		stripe_fee,
		fyi_share,
		provider_payout,
		timestamp,
		stripe_error,
		stripe_error_code,
		stripe_error_message,
		failed_payment_attempts,
		agency_fee_hourly,
		payment_status,
	} = props;

	return (
		<>
			<Title>Payment</Title>

			<GridContainer columns={5}>
				<Item>
					<Label>Agency Fee Hourly</Label>
					<Input>{agency_fee_hourly}</Input>
				</Item>
				<Item>
					<Label>Failed payment Attempts</Label>
					<Input>{failed_payment_attempts}</Input>
				</Item>
				<Item>
					<Label>FYI Fee</Label>
					<Input>{fyi_share}</Input>
				</Item>
				<Item>
					<Label>CarePro Hourly Rate</Label>
					<Input>{hourly_rate}</Input>
				</Item>
				<Item>
					<Label>ID</Label>
					<Input>{id}</Input>
				</Item>
				<Item>
					<Label> Status</Label>
					<Input>{payment_status}</Input>
				</Item>
				<Item>
					<Label>CarePro Payout</Label>
					<Input>{provider_payout}</Input>
				</Item>
				<Item>
					<Label>Payment Error</Label>
					<Input>{stripe_error}</Input>
				</Item>
				<Item>
					<Label>Error Code</Label>
					<Input>{stripe_error_code}</Input>
				</Item>
				<Item>
					<Label>Stripe Fee</Label>
					<Input>{stripe_fee}</Input>
				</Item>
				<Item>
					<Label>Subtotal</Label>
					<Input>{subtotal}</Input>
				</Item>
				<Item>
					<Label>Total Time Worked</Label>
					<Input>{total_time_worked}</Input>
				</Item>
			</GridContainer>
		</>
	);
};

/* 
	this one is a bit tricky since it needs to be able to render a carepln and careplan modesl can come in many different ways

	so here what we exppect
	- just a careplan no user aka care coordinator 
*/
export const CarePlan = (props) => {
	const { user, careNeedsSet, carePlanSet } = props;

	const { email, name, phone_number } = user;
	const renderCarePlanSet = (setModel) => {
		return setModel.setData.map((setData) => {
			return (
				<Item>
					<Label>{setData.label}</Label>
					<Input>
						{setData.value ? JSON.parse(setData.value.trait_value) || 'N/A' : 'N/A'}
					</Input>
				</Item>
			);
		});
	};
	return (
		<>
			<Title>Care Coordinator</Title>
			<GridContainer columns={4}>
				<Item>
					<Label>Display Name</Label>
					<Input>Needs to be plugged in</Input>
				</Item>
				<Item>
					<Label>Email</Label>
					<Input>{email}</Input>
				</Item>
				<Item>
					<Label>Name</Label>
					<Input>{name}</Input>
				</Item>
				<Item>
					<Label>Phone Number</Label>
					<Input>{phone_number}</Input>
				</Item>
			</GridContainer>
			<Title>Care Receiver</Title>
			<GridContainer columns={4}>{renderCarePlanSet(carePlanSet)}</GridContainer>

			<Title>CareNeeds</Title>
			<GridContainer columns={4}>{renderCarePlanSet(careNeedsSet)}</GridContainer>
		</>
	);
};

export const CareProDetail = (props) => {
	const {
		id,
		email,
		displayName,
		gender,
		image_url,
		phone_number,
		background_check_status,
		professional_statement,
		first_name,
		last_name,
		care_type,
		account_status,
		display_name,
		hourly_rate,
		live_in_rate,
		careAbilitySet,
	} = props;

	return (
		<>
			<Title>Care Pro</Title>
			<GridContainer columns={4}>
				<Item>
					<Label>Id</Label>
					<Input>{id}</Input>
				</Item>
				<Item>
					<Label>Email</Label>
					<Input>{email}</Input>
				</Item>
				<Item>
					<Label>Display Name</Label>
					<Input>{displayName}</Input>
				</Item>
				<Item>
					<Label>gender</Label>
					<Input>{gender}</Input>
				</Item>
				<Item>
					<Label>Phone Number</Label>
					<Input>{phone_number}</Input>
				</Item>
				<Item>
					<Label>First Name</Label>
					<Input>{first_name}</Input>
				</Item>
				<Item>
					<Label>Last Name</Label>
					<Input>{last_name}</Input>
				</Item>
				<Item>
					<Label>Account Status</Label>
					<Input>{account_status}</Input>
				</Item>
				<Item>
					<Label>Display Name</Label>
					<Input>{display_name}</Input>
				</Item>
			</GridContainer>

			<GridContainer columns={2}>
				<Item>
					<Label>Professional Statement</Label>
					<Input>{professional_statement}</Input>
				</Item>
			</GridContainer>
		</>
	);
};

const PaymentPage = (props) => {
	const [payment, setPayment] = useState(null);

	const { history, location } = props;
	// get the payment_id from the url

	useEffect(() => {
		const payment_id = location.pathname.split('/').pop();

		// ensure the payment_id is a number
		if (isNaN(payment_id)) {
			toast.error('Invalid payment id', TOAST_ERROR_CONFIG);
		} else {
			fetchPayment(payment_id);
		}
	}, []);

	const fetchPayment = async (id) => {
		try {
			const paymentRes = await api.graph({
				query: `{
					fetchPayment(payment_id: ${id}) {
						${PAYMENT_TYPE_MODEL}
						user{
							${USER_MODEL_V2}
						},
						provider {
							${PROVIDER_MODEL_V2}
						}

						shift {
							${SHIFT_MODEL}
						}
						
					}
				}`,
			});

			if (paymentRes.fetchPayment) {
				setPayment(paymentRes.fetchPayment);
				return;
			}
		} catch (err) {
			console.log('fetchPayment -> err', err);
		}
	};
	if (payment === null) {
		return <p>loading</p>;
	}

	return (
		<Container>
			<PaymentDetail {...payment} />

			<ShiftDetail {...payment.shift} />
			<CarePlan user={payment.user} {...payment.user.carePlan} />
			<CareProDetail {...payment.provider} />
		</Container>
	);
};

export default PaymentPage;
