import React, { Component } from 'react';
import styled from 'styled-components';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';

import Text from 'AppComp/Text';
import Colors from 'colors';

import { ColumnFlex } from 'basicStyles';

import OnboardProvider from './OnboardProvider';

import BasicOverlayWrapper from '../BasicOverlayWrapper';

import {
	LandingContentRow,
	LandingContentSec,
	LandingTitle,
	LandingImage,
} from '../patient/WelcomePage/WelcomePageStyles';
import { OBButtonText } from './POnboard/OnboardViews';

export const ParentContainer = styled.div`
	flex: 1;
	width: 100%;
`;

export const LandingCon = styled.div`
	width: 100%;
	height: auto;
	min-height: 80vh;

	position: relative;
`;

export const ContentContainer = styled.div``;

class ProviderWelcomeHandler extends Component {
	state = {
		showLoginOverlay: false,
	};

	updateKeyValueState = (key, value) => {
		this.setState({ [key]: value });
	};

	closeLoginOverlay = () => this.updateKeyValueState('showLoginOverlay', false);

	closeLoginOpenSignup = () => {
		this.setState({
			showLoginOverlay: false,
			showRegisterOverlay: true,
		});
	};

	render() {
		const { history, handleProviderLogin, receiveError, error, toggleForgotPasswordOverlay } =
			this.props;

		const { showLoginOverlay } = this.state;

		return (
			<>
				<BasicOverlayWrapper
					status={showLoginOverlay}
					appear
					handleExitClick={this.closeLoginOverlay}
				>
					<OnboardProvider
						handleProviderLogin={handleProviderLogin}
						closeLoginOpenSignup={this.closeLoginOpenSignup}
						handleExitClick={this.closeLoginOverlay}
						receiveError={receiveError}
						error={error}
						toggleForgotPasswordOverlay={toggleForgotPasswordOverlay}
						history={history}
					/>
				</BasicOverlayWrapper>
				<ColumnFlex
					style={{
						minHeight: '100vh',
						overflow: 'scroll',
						width: '100vw',
					}}
				>
					<LandingCon>
						<LandingContentSec>
							<LandingContentRow margin='1vh 0 8vh 0' justifyCenter>
								<ColumnFlex
									style={{
										justifyContent: 'center',
										alignItems: 'center',
										maxWidth: '100%',
									}}
									alignCenter
									padding='32px 0 32px 0'
								>
									{isDesktop && (
										<ColumnFlex
											padding='0 0 24px 0'
											alignCenter
											style={{ flex: 1 }}
										>
											<LandingImage
												style={{
													height: isDesktop
														? '300px'
														: isTablet
														? '150px'
														: 'auto',
													display: isMobile ? 'none' : 'block',
												}}
											/>
										</ColumnFlex>
									)}
									<LandingTitle bold>Take Control Of Your</LandingTitle>
									<LandingTitle bold>Business</LandingTitle>
									<Text
										margin='16px 0 0 0'
										maxWidth='540px'
										style={{ textAlign: 'center' }}
										largeText
									>
										FYI was created with the goal of providing quality senior
										care services to help our clients remain at home.
									</Text>
									<ColumnFlex margin='40px 0 0 0' center columnMobile>
										<OBButtonText
											onClick={() => history.push('/carepro-welcome')}
											backgroundColor={Colors.primary.primary}
											text='Get Started!'
											valid
										/>
										<div style={{ margin: '8px' }} />
										<OBButtonText
											onClick={() =>
												this.setState({ showLoginOverlay: true })
											}
											backgroundColor={Colors.primary.primary_shade_down}
											text='Login'
											valid
										/>
										<div style={{ margin: '8px' }} />
									</ColumnFlex>
								</ColumnFlex>
							</LandingContentRow>
						</LandingContentSec>
					</LandingCon>
				</ColumnFlex>
			</>
		);
	}
}

export default ProviderWelcomeHandler;
