import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';

import { FORGOT_PASSWORD_THEME } from 'constants';

import OnboardFormHandler from 'AppComp/OnboardFormHandler';
import Text from 'AppComp/Text';

import { ColumnFlex, RowFlex } from 'basicStyles';

export default class ForgotPasswordHandler extends Component {
	state = {};

	//form func to run when all fields are valid, needs to handle register and login of patient
	handleSubmit = (values, form) => {
		const { forgotPassword } = this.props;

		forgotPassword(values.email);
	};

	//handles rendering the inital values for the form to validate
	renderInitalValues = () => {
		const initValues = {
			email: '',
		};

		return initValues;
	};

	//handles changing the theme from login to register for patients
	handleThemeChange = (theme) => {
		this.setState({ activeTheme: theme });
	};

	validate = (values) => {
		const errors = {};
		const err = 'Required';

		if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
			errors.email = 'Invalid email address';
		}

		if (!values.email) {
			errors.email = err;
		}

		return errors;
	};

	render() {
		const { error, forgotPasswordStatus } = this.props;

		const { activeTheme } = this.state;

		return (
			<OnboardFormHandler
				error={error}
				validate={this.validate}
				handleSubmit={this.handleSubmit}
				renderInitalValues={this.renderInitalValues}
				theme={FORGOT_PASSWORD_THEME}
			>
				<ColumnFlex fullHeight center>
					<Text mediumText>
						Please input email associated with your account and we'll send over a
						recovery link
					</Text>
					{forgotPasswordStatus !== null && (
						<Text margin='24px 0 0 0' largeText bold>
							{forgotPasswordStatus.message}
						</Text>
					)}
				</ColumnFlex>
			</OnboardFormHandler>
		);
	}
}
