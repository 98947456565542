import styled from 'styled-components';

export const StyledInputContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;

	gap: 0.5rem;
	margin-bottom: 20px;
`;
