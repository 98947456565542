import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import styled, { ThemeProvider } from 'styled-components';
import { push } from 'connected-react-router';
import useWindowSize from 'scripts/useWindowSize';

import colors from 'colors';

import { patientSendMessage } from 'reducers/patient';

import CareProConversations from 'AppComp/provider/CareProChat/CareProConversations';

import ChatView from 'AppComp/provider/CareProChat/ChatView';

import { RowFlex } from 'AppComp/styled/Basic';

import { ParentContainer } from '@appComp/provider/ProviderHomeDashboard';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const CustomRowMobile = styled(RowFlex)`
	position: relative;
`;

export const StyledButton = styled.button`
	background: inherit;
	border: none;
	margin-right: 8px;
`;

import Icon from 'AppComp/Icon';

import api from 'scripts/api';
const ClientChatHandler = (props) => {
	const { conversations, patientSendMessage, location, push } = props;

	const [selectedConversation, setSelectedConversation] = useState(null);

	const history = useHistory();
	const { width } = useWindowSize();
	const { conversation_id } = useParams();

	useEffect(() => {
		if (selectedConversation) {
			// find the conversation in the list of conversations
			const conversation = conversations.find((d) => d.id === selectedConversation.id);
			if (conversation) {
				setSelectedConversation(conversation);
			}
		}
	}, [conversations]);

	useEffect(() => {
		if (selectedConversation) {
			push(selectedConversation.id);
		}
	}, [selectedConversation]);

	useEffect(() => {
		const conversationIdCheck = location.pathname.split('/');
		if (conversationIdCheck.length >= 2) {
			const convoCheckId = parseInt(conversationIdCheck[2]);
			const conversation = conversations.find((d) => d.id === convoCheckId);
			if (conversation) {
				setSelectedConversation(conversation);
			}
		}
	}, [location, conversations]);

	const backButtonHandler = () => {
		history.push('/chat');
	};

	const handleSendMessage = async (message) => {
		try {
			console.log('selectedConversation', selectedConversation);

			if (selectedConversation.user_type === 'carePro') {
				const chatRes = await api.graph({
					query: `mutation{
            patientSendMessage(
              conversation_id: ${selectedConversation.id},
              text: "${message}"
              provider_id: ${selectedConversation.provider.id}
            ) 
          }`,
				});
				console.log('chatRes', chatRes);
			} else {
				const chatRes = await api.graph({
					query: `mutation{
            patientSendMessage(
              conversation_id: ${selectedConversation.id},
              text: "${message}"
            ) 
          }`,
				});
				console.log('chatRes', chatRes);
			}
		} catch (error) {
			console.log('error', error);
		}
	};

	const historyBackButton = () => {
		console.log('history', history);
		history.goBack();
		console.log('work?');
	};
	return (
		<ParentContainer
			style={{
				flex: 1,
				display: 'flex',
				flexDirection: 'row',
				backgroundColor: colors.theme.primary_background,
				padding: '0 12px',
			}}
		>
			{width < 1024 && conversation_id && (
				<ChatView
					selectedConversation={selectedConversation}
					handleSendMessage={handleSendMessage}
					loggedInUser={'patient'}
					backButtonHandler={backButtonHandler}
				/>
			)}

			{width < 1024 && !conversation_id && (
				<CareProConversations
					setSelectedConversation={setSelectedConversation}
					selectedConversation={selectedConversation}
					conversations={conversations}
					loggedInUser={'patient'}
				/>
			)}

			{!(width < 1024) && (
				<>
					{!conversation_id ? (
						<CareProConversations
							setSelectedConversation={setSelectedConversation}
							selectedConversation={selectedConversation}
							conversations={conversations}
							loggedInUser={'patient'}
						/>
					) : (
						<StyledButton
							style={{
								alignSelf: 'flex-start',
								padding: '50px',
							}}
							onClick={() => historyBackButton()}
						>
							<Icon color={colors.primary.primary} icon={faArrowLeft} size='2xl' />
						</StyledButton>
					)}

					<ChatView
						selectedConversation={selectedConversation}
						handleSendMessage={handleSendMessage}
						loggedInUser={'patient'}
						backButtonHandler={backButtonHandler}
					/>
				</>
			)}
		</ParentContainer>
	);
};

const mapStateToProps = ({ patient }, props) => {
	return {
		conversations: patient.conversations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		patientSendMessage: (params) => dispatch(patientSendMessage(params)),
		push: (chatId) => dispatch(push(`/chat/${chatId}`)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientChatHandler);
