import React, { useState } from 'react';
import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import Colors from 'colors';
import useWindowSize from 'scripts/useWindowSize';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { toast } from 'react-toastify';

import api from 'scripts/api';

import { APPLICATION_STATUS_ENUM, TOAST_SUCCESS_CONFIG, TOAST_ERROR_CONFIG } from 'constants';

import {
	BackDropBg,
	ColumnFlex,
	RowFlex,
	MODAL_BACKDROP,
	MODAL_PANEL,
	ModalPanel,
} from 'basicStyles';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Button from 'AppComp/Button';

import { PHWhiteCon } from 'AppComp/provider/ProviderHomeDashboard';

const InputConTemp = styled.div``;
import {
	DateSection,
	StartTimeSection,
	EndTimeSection,
	RepeatTimeSection,
} from './AddSessionSections';
import { add } from 'lodash';
const AddScheduleSession = (props) => {
	// props
	const { carePlan, handleModalExitClick, handleSuccess, jobPostId } = props;

	// states
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [selectedStartTime, setSelectedStartTime] = useState('');
	const [selectedEndTime, setSelectedEndTime] = useState('');
	const [selectedRepeatTimeFrame, setSelectedRepeatTimeFrame] = useState('one_time');

	// util hooks
	const { width, height } = useWindowSize();

	const handleCreateCareSession = async () => {
		try {
			// ensure selected time & selected end time are not undefined or null
			if (!selectedStartTime || !selectedEndTime) {
				toast.error('Please select a start and end time', TOAST_ERROR_CONFIG);
				return;
			}

			if (selectedStartTime > selectedEndTime) {
				toast.error('End time must be after start time', TOAST_ERROR_CONFIG);
				return;
			}

			if (carePlan === null || carePlan === undefined) {
				toast.error('Care Plan not found', TOAST_ERROR_CONFIG);
				return;
			}

			// if selectedRepeatTimeFrame is every_weekday, ensure that the selected start and end date are during the week
			if (selectedRepeatTimeFrame === 'every_weekday') {
				const day = selectedDate.getDay();

				if (day === 0 || day === 6) {
					toast.error('Selected date is not a weekday', TOAST_ERROR_CONFIG);
					return;
				}
			}
			console.log('selectedDate', selectedDate);
			console.log('selectedStartTime', selectedStartTime);
			console.log('selectedEndTime', selectedEndTime);

			// Convert selectedDate to UTC
			const utcSelectedDate = new Date(
				selectedDate.getUTCFullYear(),
				selectedDate.getUTCMonth(),
				selectedDate.getUTCDate()
			);

			// Parse start and end times in UTC
			const utcStartTime = new Date(
				`${utcSelectedDate.toISOString().slice(0, 10)}T${selectedStartTime}`
			);
			const utcEndTime = new Date(
				`${utcSelectedDate.toISOString().slice(0, 10)}T${selectedEndTime}`
			);

			// Combine date with start and end times (all in UTC)
			const start = new Date(utcSelectedDate);
			start.setHours(
				utcStartTime.getHours(),
				utcStartTime.getMinutes(),
				utcStartTime.getSeconds()
			);

			const end = new Date(utcSelectedDate);
			end.setHours(utcEndTime.getHours(), utcEndTime.getMinutes(), utcEndTime.getSeconds());

			const addWorkTimeFrame = await api.graph({
				query: `mutation {
          addWorkTimeFrameToJobPost(
            start_time: "${start}",
            end_time: "${end}",
            care_plan_id: ${carePlan.id},
						repeat_every_id: "${selectedRepeatTimeFrame}"
						job_post_id: ${jobPostId}
          ) {
            id
          }
        }`,
			});

			if (addWorkTimeFrame.addWorkTimeFrameToJobPost) {
				toast.success('Care session added', TOAST_SUCCESS_CONFIG);
				await handleSuccess(false);
			}
		} catch (err) {
			console.error(err);
		}
	};

	console.log('selectedStartTime', selectedStartTime);
	console.log('selectedEndTime', selectedEndTime);

	const createDate = (date, time) => {
		// time item
		const timePart = new Date(`1970-01-01T${time}:00Z`);

		const combinedDate = new Date(
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			timePart.getHours(),
			timePart.getMinutes(),
			timePart.getSeconds()
		);

		return combinedDate;
	};
	return (
		<>
			<BackDropBg
				onClick={() => handleModalExitClick()}
				{...MODAL_BACKDROP}
				aria-hidden='true'
			></BackDropBg>
			<ModalPanel aria-label='Sidebar' {...MODAL_PANEL}>
				<ColumnFlex gap='12px'>
					<PHWhiteCon
						style={{
							minWidth: width < 800 ? '100%' : '440px',
							minHeight: '600px',
							backgroundColor: 'white',
							borderRadius: '24px',
						}}
					>
						<ColumnFlex gap='32px' padding='24px 0' fullWidth center>
							<Title
								nubtitle
								color={Colors.greenLightTeal}
								margin='0 0 20px 0'
								fontWeight='700'
							>
								Add a Care Session
							</Title>
							<ColumnFlex
								style={{
									width: '320px',
								}}
								gap='24px'
							>
								<DateSection
									selectedDate={selectedDate}
									setSelectedDate={setSelectedDate}
								/>
								<StartTimeSection
									selectedStartTime={selectedStartTime}
									selectedEndTime={selectedEndTime}
									setSelectedStartTime={setSelectedStartTime}
								/>
								<EndTimeSection
									selectedStartTime={selectedStartTime}
									selectedEndTime={selectedEndTime}
									setSelectedEndTime={setSelectedEndTime}
								/>
								<RepeatTimeSection
									selectedRepeatTimeFrame={selectedRepeatTimeFrame}
									setSelectedRepeatTimeFrame={setSelectedRepeatTimeFrame}
								/>
								{/* <UserInputSection />
								<UserInputSection /> */}
							</ColumnFlex>
							<ColumnFlex center gap='12px'>
								<Button
									onClick={() => handleCreateCareSession()}
									backgroundColor={Colors.primary.primary}
									width={'300px'}
									height={'70px'}
									borderRadius='32px'
									style={{
										shadow: 'none',
									}}
								>
									<Text fontWeight='500' color={'white'} largeText>
										Create Care Session
									</Text>
								</Button>
								<Text
									onClick={() => handleModalExitClick()}
									style={{
										cursor: 'pointer',
									}}
									fontWeight='100'
									color={'black'}
								>
									exit
								</Text>
							</ColumnFlex>
						</ColumnFlex>
					</PHWhiteCon>
				</ColumnFlex>
			</ModalPanel>
		</>
	);
};

export default AddScheduleSession;
