import React from 'react';

import AutoSizer from 'react-virtualized-auto-sizer';

import { CustomTimeSlotWrapper, MalCon, CalendarBlockedEventTile } from 'AppComp/CalendarViews';

import { Calendar } from 'react-big-calendar';
import { DAYS_OF_WEEK } from 'constants';

import Colors from 'colors';

import { RowFlex } from 'basicStyles';
import { ProviderTileRowFlex } from '../../patient/ProviderSearch/ProviderSearchViews';

import Title from 'AppComp/Title';

import { CAL_LOCALIZER } from '../../App';

const JobSchedule = (props) => {
	return (
		<ProviderTileRowFlex
			style={{
				minHeight: '30vh',
				padding: '0',
				flex: 1,
			}}
		>
			<RowFlex
				fullWidth
				spaceBetween
				style={{
					backgroundColor: 'white',
					padding: '16px 10px',
				}}
			>
				<Title
					subtitle
					color={Colors.primary.primary}
					padding='0 0px'
					margin='0 0 20px 0'
					fontWeight='700'
				>
					Propose Schedule
				</Title>
			</RowFlex>
			<div
				style={{
					width: '100%',
					padding: '0 20px',
					height: '800px',
				}}
				fullWidth
				center
			>
				<AutoSizer>
					{({ height, width }) => (
						<MalCon
							style={{
								width: width,
								height: height - 30,
							}}
						>
							<Calendar
								localizer={CAL_LOCALIZER}
								events={[]}
								defaultView='week'
								scrollToTime={new Date()}
								formats={{
									dayFormat: (date, localizer) => {
										return DAYS_OF_WEEK[date.getDay()];
									},
								}}
								components={{
									timeSlotWrapper: CustomTimeSlotWrapper,
									toolbar: () => <div />,
									event: CalendarBlockedEventTile,
									week: {
										event: CalendarBlockedEventTile,
									},
								}}
							/>
						</MalCon>
					)}
				</AutoSizer>
			</div>
		</ProviderTileRowFlex>
	);
};

export default JobSchedule;
