import React from 'react';

import { StyledFieldGroup } from './styles';

const FiedGroup = (props) => {
	const { children } = props;

	return <StyledFieldGroup>{children}</StyledFieldGroup>;
};

export default FiedGroup;
