import React from 'react';

import { StyledFormWrapper } from './styles';

const FormWrapper = (props) => {
	const { children, ...restProps } = props;

	return <StyledFormWrapper {...restProps}>{children}</StyledFormWrapper>;
};

export default FormWrapper;
