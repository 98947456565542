import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledCardForm = styled.form`
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 15px;
	gap: 10px;

	@media ${mediaQueries.tablet} {
		gap: 20px;
		flex-direction: column;
	}
`;

export const StyledCardFormField = styled.div`
	width: 100%;
	font-size: 14px;
	display: flex;
	flex-direction: column;

	@media ${mediaQueries.tablet} {
		width: 100% !important;
	}
`;

export const StyledCardLabel = styled.label`
	margin-bottom: 5px;
	font-size: 14px;
	color: ${colors.primary.primary};
	letter-spacing: 0px !important;
`;

export const StyledCardDetailsContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 10px;

	@media ${mediaQueries.tablet} {
		flex-direction: row;
		gap: 20px;
	}
`;
