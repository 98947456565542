import React, { useEffect, useState } from 'react';
import Colors from 'colors';
import styled from 'styled-components';
import Select from 'react-select';

import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';
import { device } from 'AppComp/styled/StyledMediaQuery';
import Button from 'AppComp/Button';
import Text from 'AppComp/Text';
import Title from 'AppComp/Title';
import SetTable from 'AppComp/SetTable';
import Icon from 'AppComp/Icon';

import { BasicTextInput } from 'AppComp/styled/StyledTextInput';
import { ParentContainer } from 'AppComp/admin/ProviderSearch/ProviderSearchHandler';
import { PHWhiteCon } from 'AppComp/provider/ProviderHomeDashboard';
import { SHIFT_MODEL } from '../../../reducers/graphqlModelTypes';

import { faChevronRight, faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

import api from 'scripts/api';

//enum('pending','clocked_in','clocked_out','submitted_shift','cancelled','selected','removed', 'paid')
const ALL_TYPES = [
	'pending',
	'clocked_in',
	'clocked_out',
	'submitted_shift',
	'cancelled',
	'selected',
	'removed',
	'paid',
];
export const PHDColumn = styled(ColumnFlex)`
	padding: 30px 4px;
	border-radius: 12px;

	@media ${device.mobileS} {
		padding: 30px 16px;
	}

	@media ${device.laptop} {
		padding: 30px 32px 32px 52px;
	}
`;

const ColumnConEnd = styled.div`
	flex: ${({ flex }) => flex || '1'};
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
`;

const ManageShiftsHandler = (props) => {
	const [shifts, setShifts] = useState([]);

	const [keyword, setKeyword] = useState(''); // Search term
	const [statusFilter, setStatusFilter] = useState([]);
	const [startTimeSortOrder, setStartTimeSortOrder] = useState('ascending', 'descending');
	const [endTimeSortOrder, setEndTimeSortOrder] = useState('ascending', 'descending');
	const [startTimeFilterEnabled, setStartTimeFilterEnabled] = useState(true); // State to track if start time filter is enabled
	const [endTimeFilterEnabled, setEndTimeFilterEnabled] = useState(true); // State to track if start time filter is enabled

	// fetch all the shifts
	useEffect(() => {
		handleFetchShift();
	}, [statusFilter]);

	const handleKeywordChange = (e) => {
		setKeyword(e.target.value);
	};

	const handleFetchShift = async () => {
		try {
			const shiftsRes = await api.graph({
				query: `{
					fetchRegistryShifts {
						${SHIFT_MODEL}
					}
				}`,
			});
			console.log('shiftsRes', shiftsRes);
			if (shiftsRes.fetchRegistryShifts) setShifts(shiftsRes.fetchRegistryShifts);
		} catch (err) {
			console.log(err);
		}
	};
	const renderRows = () => {
		let filteredShifts = shifts;
		if (statusFilter.length > 0) {
			filteredShifts = filteredShifts.filter((shift) => statusFilter.includes(shift.status));
		}
		return filteredShifts.map((d, i) => {
			console.log('d', d);
			const renderTotalTimeElapsed = () => {
				if (d.start_time && d.end_time) {
					const startTime = new Date(d.start_time);
					const endTime = new Date(d.end_time);

					if (!isNaN(startTime.getTime()) && !isNaN(endTime.getTime())) {
						const diff = endTime - startTime;
						const minutes = Math.floor(diff / 1000 / 60);
						const hours = Math.floor(minutes / 60);
						const days = Math.floor(hours / 24);

						const time = `${days} days, ${hours % 24} hours, ${minutes % 60} minutes`;

						return time;
					} else {
						return 'Invalid date format';
					}
				}
			};
			return (
				<SetTable.RowAction
					onClick={() => props.history.push(`/admin/shift/${d.id}`)}
					key={'time-rtr1-' + d.id}
				>
					<SetTable.ColumnCon flex='4'>
						<RowFlex alignCenter gap='8px'>
							<Text fontStyle='italic'>
								{new Date(d.start_time).toLocaleDateString('en-US', {
									//year: 'numeric',
									month: '2-digit',
									day: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									second: '2-digit',
								})}
							</Text>
							<span>-</span>
							<Text fontStyle='italic'>
								{new Date(d.end_time).toLocaleDateString('en-US', {
									//year: 'numeric',
									month: '2-digit',
									day: 'numeric',
									hour: '2-digit',
									minute: '2-digit',
									second: '2-digit',
								})}
							</Text>
						</RowFlex>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon flex='4'>
						<Text color='#2D2D2D' fontWeight='100' mediumText>
							{renderTotalTimeElapsed()}
						</Text>
					</SetTable.ColumnCon>

					<SetTable.ColumnCon flex='2'>
						<Text color='#2D2D2D' fontWeight='100' mediumText>
							{d.provider.displayName ||
								d.provider.first_name + ' ' + d.provider.last_name}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon flex='2'>
						<Text color='#2D2D2D' fontWeight='100' mediumText>
							{`${d.carePlan.first_name} ${d.carePlan.last_name}`}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon flex='2'>
						<Text color='#2D2D2D' fontWeight='100' mediumText>
							{d.status}
						</Text>
					</SetTable.ColumnCon>
					<ColumnConEnd
						style={{
							pointer: 'cursor',
						}}
						flex='0.5'
					>
						<div
							style={{
								cursor: 'pointer',
							}}
						>
							<Icon icon={faChevronRight} color={Colors.emerald} size='lg' />
						</div>
					</ColumnConEnd>
				</SetTable.RowAction>
			);
		});
	};

	const sortShifts = (shift, sortBy, sortOrder) => {
		return shift.sort((a, b) => {
			const dateA = new Date(a[sortBy]);
			const dateB = new Date(b[sortBy]);
			return sortOrder === 'ascending' ? dateA - dateB : dateB - dateA;
		});
	};

	const renderLegend = () => {
		return (
			<>
				<SetTable.ColumnCon flex='4'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						(Start - Stop)
					</Text>
				</SetTable.ColumnCon>

				<SetTable.ColumnCon flex='4'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Hours Worked
					</Text>
				</SetTable.ColumnCon>

				<SetTable.ColumnCon flex='2'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Care Pro
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon flex='2'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Client
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon flex='2'>
					<Text fontStyle='italic' color='#969696' fontWeight='100' mediumText>
						Status
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon flex='0.5'></SetTable.ColumnCon>
			</>
		);
	};
	const renderDropDownSelect = () => {
		const options = ALL_TYPES.map((type) => {
			return {
				value: type,
				label: type,
			};
		});

		return (
			<Select
				placeholder='Filter by status'
				options={options}
				className='doc-filter-select'
				isMulti
				onChange={(selectedOption) => {
					const selectedTypes = selectedOption.map((s) => s.value);
					setStatusFilter(selectedTypes);
				}}
			/>
		);
	};

	const renderStartTimeFilter = () => {
		return (
			<ColumnFlex padding={'0px 0px 0px 20px'}>
				<Button
					style={{
						borderRadius: '16px',
						backgroundColor:
							startTimeSortOrder === 'ascending' ? Colors.emerald : 'white',
					}}
					margin='15px 0 15px 0'
					width={'215px'}
					height={'60px'}
					onClick={handleStartTimeFilterClick}
					disabled={!startTimeFilterEnabled}
				>
					{/* Render filter button with conditional styling based on enabled/disabled state */}
					<Text
						style={{
							textAlign: 'center',
						}}
						bold
						color={startTimeSortOrder === 'ascending' ? 'white' : Colors.emerald}
						midLarge
					>
						{startTimeSortOrder === 'ascending' ? 'Ascending Time' : 'Descending Time'}
					</Text>
					<Icon
						icon={startTimeSortOrder === 'ascending' ? faChevronUp : faChevronDown}
						color={startTimeSortOrder === 'ascending' ? 'white' : Colors.emerald}
						size='lg'
						style={{ marginLeft: '10px' }}
					/>
				</Button>
				{/* Render toggle button to enable/disable filter */}
				{/* Created just in case it's needed */}
				{/* <Button onClick={handleToggleUploadDateFilter}>
					{uploadDateFilterEnabled
						? 'Disable Upload Date Filter'
						: 'Enable Upload Date Filter'}
				</Button> */}
			</ColumnFlex>
		);
	};

	const handleStartTimeFilterClick = () => {
		const newSortOrder = startTimeSortOrder === 'ascending' ? 'descending' : 'ascending';
		setStartTimeSortOrder(newSortOrder);
		// Sort time sheets based on start time
		const sortedShifts = sortShifts(shifts, 'uploaded_date', newSortOrder);
		setShifts(sortedShifts);
	};

	const renderEndTimeFilter = () => {
		return (
			<ColumnFlex padding={'0px 0px 0px 20px'}>
				<Button
					style={{
						borderRadius: '16px',
						backgroundColor:
							endTimeSortOrder === 'ascending' ? Colors.emerald : 'white',
					}}
					margin='15px 0 15px 0'
					width={'215px'}
					height={'60px'}
					onClick={handleEndTimeFilterClick}
					disabled={!endTimeFilterEnabled}
				>
					{/* Render filter button with conditional styling based on enabled/disabled state */}
					<Text
						style={{
							textAlign: 'center',
						}}
						bold
						color={endTimeSortOrder === 'ascending' ? 'white' : Colors.emerald}
						midLarge
					>
						{endTimeSortOrder === 'ascending' ? 'Ascending Time' : 'Descending Time'}
					</Text>
					<Icon
						icon={endTimeSortOrder === 'ascending' ? faChevronUp : faChevronDown}
						color={endTimeSortOrder === 'ascending' ? 'white' : Colors.emerald}
						size='lg'
						style={{ marginLeft: '10px' }}
					/>
				</Button>
			</ColumnFlex>
		);
	};

	const handleEndTimeFilterClick = () => {
		const newSortOrder = endTimeSortOrder === 'ascending' ? 'descending' : 'ascending';
		setEndTimeSortOrder(newSortOrder);
		// Sort time sheets based on end time
		const sortedShifts = sortShifts(shifts, 'uploaded_date', newSortOrder);
		setShifts(sortedShifts);
	};

	return (
		<ParentContainer
			style={{
				minHeight: '100vh',
			}}
		>
			<PHDColumn fullHeight>
				<RowFlex gap='30px' fullHeight fullWidth columnMobile>
					<PHWhiteCon
						style={{
							flex: 1,
							backgroundColor: 'white',
							height: 'fit-content',
						}}
					>
						<Title
							subtitle
							color={Colors.greenLightTeal}
							margin='0 0 20px 0'
							fontWeight='700'
						>
							Filter
						</Title>
						<ColumnFlex
							style={{
								overflowY: 'scroll',
							}}
							padding='16px 4px 0px 4px'
							fullWidth
							fullHeight
						>
							<div
								style={{
									padding: '0 16px 8px 16px',
								}}
							>
								<BasicTextInput
									margin={'20px 0 0px 0 '}
									value={keyword}
									onChange={handleKeywordChange}
									label={`Search By Client`}
									placeholder={`Search By Client`}
								/>
							</div>
						</ColumnFlex>
						<ColumnFlex
							style={{
								borderRadius: '16px',
								backgroundColor: Colors.lightGrey,
								padding: '10px 10px 10px 10px',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignStart
							gap='12px'
						>
							<Text fontStyle='bold' color='#969696' fontWeight='100' mediumText>
								By Shift Status
							</Text>
							{renderDropDownSelect()}
						</ColumnFlex>
						<ColumnFlex
							style={{
								borderRadius: '16px',
								backgroundColor: Colors.lightGrey,
								padding: '10px 10px 10px 10px',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignStart
							gap='12px'
						>
							<ColumnFlex fullWidth alignStart>
								<Text
									alignStart
									fontStyle='bold'
									color='#969696'
									fontWeight='100'
									mediumText
								>
									By Start Time
								</Text>
							</ColumnFlex>
							{renderStartTimeFilter()}
						</ColumnFlex>
						<ColumnFlex
							style={{
								borderRadius: '16px',
								backgroundColor: Colors.lightGrey,
								padding: '10px 10px 10px 10px',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignStart
							gap='12px'
						>
							<ColumnFlex fullWidth alignStart>
								<Text
									alignStart
									fontStyle='bold'
									color='#969696'
									fontWeight='100'
									mediumText
								>
									By End Time
								</Text>
							</ColumnFlex>
							{renderEndTimeFilter()}
						</ColumnFlex>
					</PHWhiteCon>
					<div
						style={{
							flex: 5,
						}}
					>
						{shifts.length === 0 ? (
							<Text>No Shifts Submitted</Text>
						) : (
							<SetTable
								title='Shifts'
								renderRows={renderRows}
								renderLegend={renderLegend}
								leftPadding='16px'
							/>
						)}
					</div>
				</RowFlex>
			</PHDColumn>
		</ParentContainer>
	);
};

export default ManageShiftsHandler;
