import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CardSection, CustomFormButton } from '../PatientCardCollection/PatientCardCollectionViews';
import { ColumnFlex, RowFlex, Avatar } from 'basicStyles';
import { connect } from 'react-redux';
import { Hearts } from 'react-loader-spinner';
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardCvcElement,
	CardExpiryElement,
} from '@stripe/react-stripe-js';
import AnimateHeight from 'react-animate-height';

import {
	createUserStripeIntent,
	updatePatient,
	updateCardCollectModelStatus,
} from 'reducers/patient';
import { ACCEPTED_CARDS } from 'constants';
import Colors from 'colors';
import { ModelContainer } from 'AppComp/EventModel';

import {
	Card,
	StyledTokenCon,
	SavedTokenInput,
	CardIconCon,
} from '../PatientSetting/PatientSettingViews';
import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import Icon from 'AppComp/Icon';
import { toast } from 'react-toastify';
import { toastErrorConfig, toastSuccessConfig } from '../../../reducers/provider';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const MagicStuff = ({
	updatePatient,
	userStripe,
	loading,
	updateErr,
	updateLoader,
	userId,
	updateCardStatus,
	closeToast,
}) => {
	const stripe = useStripe();
	const elements = useElements();

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();
		updateLoader(true);
		if (!stripe || (!elements && userStripe)) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}
		console.log(elements.getElement(CardNumberElement));
		try {
			const result = await stripe.confirmCardSetup(userStripe, {
				payment_method: {
					card: elements.getElement(CardNumberElement),
					billing_details: {},
				},
			});
			updateLoader(false);
			if (result.error) {
				// Display result.error.message in your UI.
				updateErr(result.error.message);
			} else {
				updateCardStatus(true);
				updatePatient({
					userId: userId,
					userInput: { payment_method_verification: 'verified' },
				});
				toast.success('Card Added', toastSuccessConfig);
				if (closeToast) {
					closeToast();
				}
			}
		} catch (err) {
			console.log('PatientCardcollectionViews 117', err);
			updateErr('Something went wrong please try again');
			toast.error('Something went wrong please try again', toastErrorConfig);
		}
	};

	return (
		<ColumnFlex center>
			<form onSubmit={handleSubmit}>
				<CardSection />
				<RowFlex center>
					{/* <button disabled={!stripe}>Save Card</button> */}
					{loading && <Hearts color={Colors.primary.primary} height={50} width={50} />}
					{!loading && (
						<CustomFormButton
							width='200px'
							valid={stripe}
							backgroundColor={Colors.teal}
							type='submit'
						>
							Add Payment Method
						</CustomFormButton>
					)}
				</RowFlex>
			</form>
		</ColumnFlex>
	);
};

const CollectCardHandler = (props) => {
	const { patient, updatePatient, handleExitClick } = props;

	const [userStripe, updateUserStripe] = useState(null);
	const [loading, updateLoader] = useState(false);
	const [err, updateErr] = useState(null);
	const [cardAdded, updateCardStatus] = useState(false);

	useEffect(() => {
		fetchStripeThing();
	}, []);

	const fetchStripeThing = async (props) => {
		const stripeClientThing = await createUserStripeIntent();
		console.log('createUserStripeIntent', stripeClientThing);
		updateUserStripe(stripeClientThing.createUserStripeIntent);
	};
	const renderAcceptedCards = () => {
		return ACCEPTED_CARDS.map((d, i) => {
			return (
				<CardIconCon key={'card-key-' + d.id}>
					<Icon size='3x' color={Colors.primary.primary} icon={d.icon} />
				</CardIconCon>
			);
		});
	};
	return (
		<ColumnFlex alignCenter padding='32px'>
			<RowFlex alignCenter>{err && <Text error>{err}</Text>}</RowFlex>
			{/* <AnimateHeight duration={500} height={cardAdded ? 'auto' : '0%'}>
					<>
						<Text darkGray mediumText margin='32px 0 24px 0'>
							You Card will only be charged ...
						</Text>
						<Button
							margin='auto'
							onClick={() => handleExitClick()}
							backgroundColor={Colors.primary.primary}
						>
							Exit
						</Button>
					</>
				</AnimateHeight> */}
			<AnimateHeight duration={500} height={!cardAdded ? 'auto' : '0%'}>
				<ColumnFlex center>
					<Text darkGray mediumText margin='32px 0 24px 0'>
						Please Input Card Information Here
					</Text>
					<RowFlex gap='8px'>{renderAcceptedCards()}</RowFlex>
					<Elements stripe={stripePromise}>
						<MagicStuff
							closeToast={props.closeToast}
							updatePatient={updatePatient}
							userStripe={userStripe}
							loading={loading}
							err={err}
							updateErr={updateErr}
							updateLoader={updateLoader}
							userId={patient.id}
							updateCardStatus={updateCardStatus}
						/>
					</Elements>
				</ColumnFlex>
			</AnimateHeight>
		</ColumnFlex>
	);
};

const mapStateToProps = ({ patient }) => {
	return {
		patient: patient.patient,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updatePatient: (params) => dispatch(updatePatient(params)),
		updateCardCollectModelStatus: (status) => dispatch(updateCardCollectModelStatus(status)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CollectCardHandler);
