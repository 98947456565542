import styled from 'styled-components';
import React, { Component, Fragment } from 'react';
import PT from 'prop-types';

const Container = styled.div`
	position: fixed;
	z-index: 9000;
	overflow-y: scroll;
	width: 100vw;

	min-height: 100vh;

	left: 0;
	top: 0;
	opacity: 0;
	background-color: rgba(159, 159, 159, 0.5);
	transition: opacity 0.3s ease;

	&.basic-overlay-appear-done {
		opacity: 1;
		transition: opacity ease 0.4s;
		.container {
			top: 50%;
		}
	}

	&.basic-overlay-enter-active {
		opacity: 0;

		.container {
			top: -50%;
		}
	}

	&.basic-overlay-enter-done {
		opacity: 1;
		transition: opacity ease 0.3s;
		.container {
			transition: top cubic-bezier(1, 0, 0, 1) 0.5s;
			top: 50%;
		}
	}

	&.basic-overlay-exit {
		opacity: 1;
		transition: opacity ease 0.4s;
		.container {
			top: 50%;
		}
	}
	&.basic-overlay-exit-active {
		opacity: 0;
		transition: opacity ease 0.4s 0.6s;
		.container {
			transition: top cubic-bezier(1, 0, 0, 1) 0.6s;
			top: -50%;
		}
	}
	&.basic-overlay-exit-done {
		transition: opacity ease 0.3s;
		opacity: 0;

		.container {
			top: -50%;
		}
	}
`;

const Background = styled.div`
	position: absolute;
	background-color: white;
	opacity: 0.2;
	width: 100vw;
	height: 100vh;
	z-index: 20;
`;

const ContentContainer = styled.div`
	left: 50%;
	margin: auto;
	top: ${({ overflow }) => (overflow ? '0' : '50%')};
	transform: ${({ overflow }) => (overflow ? 'translate(-50%, 0)' : 'translate(-50%, -50%)')};
	padding: ${({ overflow }) => (overflow ? '32px' : '0')};
	z-index: 30;
	position: absolute;
	transition: all ease 0.2s;
	color: white;
	max-height: 90vh;
	padding-bottom: 20px;
`;

export default class OverlayWrapper extends Component {
	state = {
		identifer: 'overlay-' + Math.random().toString(36).substring(7),
		updateStyle: false,
	};

	componentWillReceiveProps(nextProps) {
		const { identifer } = this.state;

		const outerContianer = document.querySelector('.' + identifer);
		const height = outerContianer.clientHeight;

		if (height > window.innerHeight) {
			this.setState({ updateStyle: true });
		}
	}

	render() {
		const { identifer, updateStyle } = this.state;

		const { handleExitClick, className, children } = this.props;

		return (
			<Container>
				<Background onClick={() => handleExitClick()} />
				<ContentContainer className={className + ' ' + identifer} overflow={updateStyle}>
					{children}
				</ContentContainer>
			</Container>
		);
	}
}
OverlayWrapper.defaultProps = {
	className: '',
};

OverlayWrapper.propTypes = {
	handleExitClick: PT.func.isRequired,
	className: PT.string,
	//children: PT.element.isRequired,
};
