import React, { useEffect, useState } from 'react';
import changeCase from 'change-case';

import { SubTitle, Container, Title, GridContainer, Item, Label, Input } from './CoreView';

const CareProStripeStatusDataView = (props) => {
	const {
		id,
		charges_enabled,
		card_payments,
		transfers_enabled,
		payouts_enabled,
		provider_id,
		status,
		stripe_data,
	} = props;

	const renderStripeData = () => {
		if (stripe_data) {
			const { currently_due } = stripe_data;
			// convert stripe_data to array but exclude currently_due
			const stripeDataArray = Object.keys(stripe_data)
				.filter((key) => key !== 'currently_due' && key !== 'requested_data_errors')
				.map((key) => {
					return {
						key: key,
						value: stripe_data[key],
					};
				});

			return (
				<>
					<SubTitle>Filled Data</SubTitle>
					<GridContainer columns={5}>
						{stripeDataArray.map((item, index) => {
							return (
								<Item key={index}>
									<Label>{changeCase.titleCase(item.key)}</Label>
									<Input>{item.value}</Input>
								</Item>
							);
						})}
					</GridContainer>
					{currently_due.length > 0 && (
						<>
							<SubTitle>Currently Due Data</SubTitle>
							<GridContainer columns={3}>
								{currently_due.map((item, index) => {
									console.log('item', item);
									return (
										<Item key={index}>
											<Label>{changeCase.titleCase(item)}</Label>
										</Item>
									);
								})}
							</GridContainer>
						</>
					)}
				</>
			);
		}
	};
	return (
		<>
			<Title>Stripe Status</Title>
			<GridContainer columns={3}>
				<Item>
					<Label>Charges Enabled</Label>
					<Input>{charges_enabled ? 'Approved' : 'Unapproved'}</Input>
				</Item>
				<Item>
					<Label>Card Payments</Label>
					<Input>{card_payments ? 'Approved' : 'Unapproved'}</Input>
				</Item>
				<Item>
					<Label>Transfers Enabled</Label>
					<Input>{transfers_enabled ? 'Approved' : 'Unapproved'}</Input>
				</Item>
				<Item>
					<Label>Payouts Enabled</Label>
					<Input>{payouts_enabled ? 'Approved' : 'Unapproved'}</Input>
				</Item>
				<Item>
					<Label>Status</Label>
					<Input>{status}</Input>
				</Item>
			</GridContainer>
			{renderStripeData()}
		</>
	);
};

export default CareProStripeStatusDataView;
