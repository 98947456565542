import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

import Loader from '../../../../../corev2/Loader';
import Chat from '../../components/Chat';
import ChatScreen from '../../components/ChatScreen';

import { useFetchConversationsQuery } from '../../api/queries/useFetchConversationsQuery';
import { useSendMessageMutation } from '../../api/mutations/useSendMessageMutation';

import { StyledChatContainer, StyledChatListContainer } from './styles';

import { mediaQueries } from '../../../../../corev2/utils';

import useMediaQuery from '../../hooks/useMediaQuery';
import usePreventScroll from '../../../core/hooks/usePreventScroll';

const ChatPage = () => {
	const { data, isLoading: isLoadingChat } = useFetchConversationsQuery();
	const { chatId } = useParams();
	const history = useHistory();
	const isSmallScreen = !useMediaQuery(mediaQueries.tablet);

	usePreventScroll([]);

	const [chat, setChat] = useState([]);

	const sendMessage = useSendMessageMutation();

	useEffect(() => {
		if (data && chatId) {
			const conversation = data?.conversations?.find((c) => c.id === parseInt(chatId));

			if (conversation) {
				setChat(conversation);
			}
		}
	}, [data, chatId]);

	const sendMessageHandler = async (conversationId, text, careProid) => {
		try {
			await sendMessage.mutateAsync({
				conversationId,
				text,
				careProid,
			});
		} catch (error) {
			console.error('Send message failed', error);
		}
	};

	const handleSendMessage = async (message) => {
		if (chat.user_type === 'carePro') {
			sendMessageHandler(chat.id, message, chat.provider.id);
		} else {
			sendMessageHandler(chat.id, message);
		}
	};

	if (isLoadingChat) {
		return <Loader />;
	}

	const handleChatClick = (conversation) => {
		setChat(conversation);
		history.push(`/care-pro/chat/${conversation.id}`);
	};

	return (
		<StyledChatContainer>
			{isSmallScreen ? (
				chatId ? (
					<ChatScreen conversations={chat} handleSendMessage={handleSendMessage} />
				) : (
					<StyledChatListContainer>
						<Chat
							onClick={(obj) => handleChatClick(obj)}
							conversations={data?.conversations || []}
						/>
					</StyledChatListContainer>
				)
			) : (
				<>
					<StyledChatListContainer $chatId={!!chatId}>
						<Chat
							onClick={(obj) => handleChatClick(obj)}
							conversations={data?.conversations || []}
						/>
					</StyledChatListContainer>

					{chatId && (
						<ChatScreen conversations={chat} handleSendMessage={handleSendMessage} />
					)}
				</>
			)}
		</StyledChatContainer>
	);
};

export default ChatPage;
