import { useQuery } from '@tanstack/react-query';

import api from 'scripts/api';

import { TRAIT_TEMPLATE_MODEL } from '../models';

const fetchRegistryTemplate = async ({ traitTemplateId }) => {
	try {
		const data = await api.graph({
			query: `{
        fetchRegistryTemplate(trait_template_id: ${traitTemplateId}) {
          ${TRAIT_TEMPLATE_MODEL}
        }
      }`,
		});

		return data.fetchRegistryTemplate;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

const useFetchRegistryTemplate = (traitTemplateId) => {
	return useQuery({
		queryKey: ['fetchRegistryTemplate', traitTemplateId],
		queryFn: () => {
			return fetchRegistryTemplate({ traitTemplateId });
		},
	});
};

export default useFetchRegistryTemplate;
