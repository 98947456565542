import { createAction, handleActions } from 'redux-actions';

import api from '../scripts/api';

// Reducer
const initialState = {
	error: null,
	info: {
		show: false,
		title: ' Create a re-usable Model for info #154 ',
		text: 'This should be used when a user first lands on the page and whenever they inquire more info regarding the page maybe not when first visited but needs to be verified',
	},

	showForgotPasswordOverlay: false,
	forgotPasswordStatus: null,
	showChangePasswordOverlay: false,
	changePasswordStatus: null,
	showPhoneValidationInput: false,
};

export const receiveError = createAction('RECEIVE_ERR0R');
export const showInfoModel = createAction('SHOW_INFO_MODEL');
export const hideInfoModel = createAction('HIDE_INFO_MODEL');

export const receiveForgotPasswordStatus = createAction('RECEIVE_FORGOT_PASSWORD_STATUS');
export const receiveNewPasswordStatus = createAction('RECEIVE_NEW_PASSWORD_STATUS');
export const receivePhoneValidationStatus = createAction('RECEIVE_PHONE_VALIDATION_STATUS');

export const toggleForgotPasswordOverlay = createAction('TOGGLE_FORGOT_PASSWORD_OVERLAY');
export const toggleChangePasswordOverlay = createAction('TOGGLE_CHANGE_PASSWORD_OVERLAY');

const RECEIVE_ERR0R = 'RECEIVE_ERR0R';

export default handleActions(
	{
		[receivePhoneValidationStatus](state, { payload }) {
			return {
				...state,
				showPhoneValidationInput: payload,
			};
		},
		[receiveNewPasswordStatus](state, { payload }) {
			return {
				...state,
				changePasswordStatus: payload,
			};
		},
		[receiveForgotPasswordStatus](state, { payload }) {
			return {
				...state,
				forgotPasswordStatus: payload,
			};
		},
		[toggleChangePasswordOverlay](state, { payload }) {
			return {
				...state,
				showChangePasswordOverlay: payload,
			};
		},
		[toggleForgotPasswordOverlay](state, { payload }) {
			return {
				...state,
				showForgotPasswordOverlay: payload,
			};
		},
		[hideInfoModel](state, { payload }) {
			return {
				...state,
				info: {
					...state.info,
					show: false,
				},
			};
		},
		[showInfoModel](state, { payload }) {
			return {
				...state,
				info: payload,
			};
		},
		[receiveError](state, { payload }) {
			return {
				...state,
				error: payload,
			};
		},
	},
	initialState
);
