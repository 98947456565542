import React from 'react';
import styled from 'styled-components';
import is from 'styled-is';
import PropTypes from 'prop-types';

import Colors from '../constants/colors';
import { device } from './styled/StyledMediaQuery';

const Text = styled.p`
	font-size: 12px;
	line-height: 16px;
	color: ${({ color }) => color || Colors.textBlack};
	margin: ${(props) => props.margin || '0'};
	padding: ${(props) => props.padding || '0'};
	max-width: ${(props) => props.maxWidth || 'none'};
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	font-style: ${({ fontStyle }) => fontStyle || 'normal '};
	font-family: ${({ fontFamily }) => fontFamily || 'Helvetica'};
	background: ${({ background }) => background || 'none'};

	@media ${device.tablet} {
		font-size: 14px;
		line-height: 20px;
	}
	@media ${device.laptopL} {
		font-size: 16px;
		line-height: 24px;
	}

	${is('largeText')`
		font-size: 20px;
		line-height: 24px;

		@media ${device.tablet} {
			font-size: 22px;
			line-height: 27px;
		}
		@media ${device.laptopL} {
			font-size: 24px;
			line-height: 29px;
		}
		
	`}

	${is('midLarge')`
		font-size: 16x;
		line-height: 18px;

		@media ${device.tablet} {
			font-size: 18px;
			line-height: 20px;
		}
		@media ${device.laptopL} {
			font-size: 20px;
		line-height: 22px;
		}
	`}

	${is('providerOnboard')`
	font-size: 20px;
	line-height: 22px;

	@media ${device.tablet} {
		font-size: 20x;
	line-height: 22px;
	}
	@media ${device.laptopL} {
		font-size: 20px;
	line-height: 22px;
	}
`}

	${is('mediumText')`
		font-size: 14px;
		line-height: 20px;

		@media ${device.tablet} {
			font-size: 16px;
			line-height: 24px;
		}
		@media ${device.laptopL} {
			font-size: 18px;
		line-height: 26px;
		}
	`}

	${is('subtitle')`
		font-size: 8px;
		line-height: 10px;
		@media ${device.tablet} {
			font-size: 10px;
			line-height: 12px;
		}
		@media ${device.laptopL} {
			font-size: 12px;
		line-height: 14px;
		}
	`}
	${is('nurseName')`
		font-size: 12px;
		line-height: 14px;
		@media ${device.tablet} {
			font-size: 14px;
			line-height: 16px;
		}
		@media ${device.laptopL} {
			font-size: 16px;
		line-height: 18px;
		}
	`}
	${is('nurseInfo')`
		font-size: 10px;
		line-height: 12px;
		@media ${device.tablet} {
			font-size: 12px;
			line-height: 14px;
		}
		@media ${device.laptopL} {
			font-size: 14px;
		line-height: 16px;
		}
	`}

	${is('bold')`
		font-weight: 600;
	`}
	
	${is('subtext')`
		font-size: 9px;
		line-height: 10px;

		@media ${device.tablet} {
			font-size: 10px;
			line-height: 12px;
		}
		@media ${device.laptopL} {
			font-size: 11px;
		line-height: 13px;
		}

	`}

	${is('error')`
		color: ${Colors.heartRed};
		font-size: 11px;
		line-height: 11px;
	`}

	${is('gray')`
		color: ${Colors.lightGray};
	`}

	${is('darkGray')`
		color: ${Colors.darkerGray};
	`}

	${is('pointer')`
		cursor: pointer;
	`}

	${is('underline')`
		text-decoration: underline;
	`}

    ${is('overflow')`
    	overflow: hidden;
    	text-overflow: ellipsis;
	`}
	${is('inline')`
    	display: inline-block;
	`}
`;
Text.propTypes = {
	/** Color of the text displayed */
	color: PropTypes.string,
	/** Margins that should be added, needs to be in short hand syntax ex margin: top right bottom left; */
	margin: PropTypes.string,
	//* Max width that a text can take */
	maxWidth: PropTypes.string,
	//* Font weight of the text */
	fontWeight: PropTypes.string,
	//* Font style of the text; ex: italic, normal & oblique */
	fontStyle: PropTypes.string,
	//* Font family to be used */
	fontFamily: PropTypes.string,
	//* if the text should be increased to largeText size */
	largeText: PropTypes.bool,
	//* If the text should be reduced to subtext size */
	subtext: PropTypes.bool,
	//* If the text should be reudeced to subtitle size */
	subtitle: PropTypes.bool,
	//* If the text is supposed to reprsent an error displayed */
	error: PropTypes.bool,
	//* If the text should be the website defualt gray color */
	gray: PropTypes.bool,
	//* If the text reprsents a action */
	pointer: PropTypes.bool,
	//* If the text reprsents a link */
	underline: PropTypes.bool,
	//* If the text needs to be placed on same line */
	inline: PropTypes.bool,
};

export default Text;
