import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import { StyledContainer } from './styles';

const MenuButton = () => {
	return (
		<StyledContainer>
			<FontAwesomeIcon icon={faEllipsisV} />
			<FontAwesomeIcon icon={faEllipsisV} />
			<FontAwesomeIcon icon={faEllipsisV} />
		</StyledContainer>
	);
};

export default MenuButton;
