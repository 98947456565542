import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PaymentsPage from '../pages/PaymentsPage';

import { paymentRoutes } from './constants';

const PaymentRoutes = () => {
	return (
		<Switch>
			<Route path={paymentRoutes.home}>
				<PaymentsPage />
			</Route>
		</Switch>
	);
};

export default PaymentRoutes;
