import React from 'react';

import { StyledFormContainer } from './styles';

const FormContainer = (props) => {
	const { children, ...restProps } = props;

	return <StyledFormContainer {...restProps}>{children}</StyledFormContainer>;
};

export default FormContainer;
