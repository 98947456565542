/**
 * Truncates a string to the specified maximum length and ensures the first few and last 5 characters are always visible.
 * @param {string} text - The input string to truncate.
 * @param {number} maxLength - The maximum allowed length of the string.
 * @returns {string} - The truncated string with "..." in the middle if necessary, or the original string.
 */
export const truncateText = (text, maxLength) => {
	if (text.length > maxLength) {
		// Calculate the length for the first and last parts
		const visibleStartLength = Math.max(0, maxLength - 5 - 3); // Reserve space for "..."
		const start = text.slice(0, visibleStartLength);
		const end = text.slice(-5); // Last 5 characters
		return `${start}...${end}`;
	}

	return text;
};
