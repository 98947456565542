import React, { useState } from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';
import Colors from 'colors';
import { isMobile } from 'react-device-detect';

import { receiveUserDetails, authorizePendingShifts, captureApprovedShifts } from 'reducers/admin';

import { PROVIDER_NAV_WIDTH } from 'constants';

import { device } from 'AppComp/styled/StyledMediaQuery';

import { RowFlex, Avatar, ColumnFlex } from 'basicStyles';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';

import {
	faComment,
	faHome,
	faUserNurse,
	faAddressBook,
	faExclamationTriangle,
	faBusinessTime,
	faFolderOpen,
	faCalculator,
	faClipboardList,
	faFile,
} from '@fortawesome/free-solid-svg-icons';

const Section = styled.div`
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
	display: flex;
	width: ${PROVIDER_NAV_WIDTH};
	height: 100vh;
	flex-direction: column;
	align-items: center;
	padding: 10px 0 0 0;
	justify-content: flex-start;
	background-color: ${Colors.primary.primary};
`;

const FixedCon = styled.div`
	position: fixed;
	display: flex;
	height: fit-content;
	z-index: 100;
	display: flex;
	flex-direction: column;

	@media ${device.mobileS} {
		right: 6px;
		bottom: 60px;
	}

	@media ${device.laptop} {
		right: 24px;
		bottom: 120px;
	}
`;

const LandingContentSecNav = (props) => {
	return (
		<Section
			style={{
				position: 'fixed',
			}}
		>
			{props.children}
		</Section>
	);
};

const NavTile = (props) => {
	const { title, icon, history, isMobile, push, route, onClick } = props;
	const active = false;

	const [isActive, setIsActive] = useState(active);

	const handleClick = () => {
		if (route) {
			push(route);
		} else if (onClick) {
			onClick();
		}
	};
	return (
		<RowFlex
			onMouseEnter={() => setIsActive(true)}
			onMouseLeave={() => setIsActive(false)}
			padding='0px 0 0 16px'
			fullWidth
			alignCenter
			gap='8px'
			style={{
				height: '50px',
				borderBottom: isActive ? `2px solid ${Colors.fullWhite}` : 'none',
				cursor: 'pointer',
			}}
			onClick={() => handleClick()}
		>
			<Icon size={isMobile ? '1x' : '1x'} color={'white'} icon={icon} />
			<Text subtitle color={'white'}>
				{title}
			</Text>
		</RowFlex>
	);
};

const AdminNavigationHandler = (props) => {
	const logoURl = 'https://find-your-independents.s3.amazonaws.com/images/FYIlogo.png';

	const {
		history,
		receiveUserDetails,
		authorizePendingShifts,
		goToSettings,
		captureApprovedShifts,
	} = props;

	return (
		<>
			<LandingContentSecNav
				style={{
					position: 'relative',
				}}
			>
				<ColumnFlex justifyStart alignCenter spaceBetween gap='40px' fullWidth>
					<ColumnFlex justifyStart alignCenter gap='4px'>
						<Avatar
							height={'100px'}
							width={'100px'}
							border='white'
							color={'white'}
							backgroundColor={'white'}
						>
							<div
								onClick={() => history.push('/admin')}
								style={{
									backgroundImage: `url("${logoURl}")`,
									backgroundRepeat: 'no-repeat',
									height: '80px',
									width: '80px',
									backgroundSize: 'contain',
									backgroundPosition: 'left center',
									cursor: 'pointer',
								}}
							/>
						</Avatar>

						{!isMobile && (
							<Title
								style={{
									cursor: 'pointer',
									textAlign: 'center',
								}}
								subtitle
								color={'white'}
								margin='16px 0 0 0px'
								fontWeight='700'
								onClick={() => history.push('/admin')}
							>
								Care Advisor
							</Title>
						)}
					</ColumnFlex>
					<ColumnFlex padding='0 0 0 8px' gap='8px' fullWidth>
						<NavTile
							icon={faHome}
							title='Home'
							route='/admin'
							history={history}
							push={history.push}
						/>
						{/* <NavTile
							icon={faBusinessTime}
							title='Shifts'
							route='/admin/shifts'
							history={history}
							push={history.push}
						/> */}
						<NavTile
							icon={faComment}
							title='Messages'
							route='/admin/messages'
							history={history}
							push={history.push}
						/>
						{/* <NavTile
							icon={faExclamationTriangle}
							title='Disputes'
							route='/admin/disputes'
							history={history}
							push={history.push}
						/> */}
						<NavTile
							icon={faUserNurse}
							title='CarePro'
							route='/admin/carepros'
							history={history}
							push={history.push}
						/>
						<NavTile
							icon={faUserNurse}
							title='Clients'
							route='/admin/clients'
							history={history}
							push={history.push}
						/>
						<NavTile
							icon={faAddressBook}
							title='Care Plans'
							route='/admin/careplans'
							history={history}
							push={history.push}
						/>
						<NavTile
							icon={faFile}
							title='Documents'
							route='/admin/documents'
							history={history}
							push={history.push}
						/>
						<NavTile
							icon={faFolderOpen}
							title='Platform Forms'
							route='/admin/forms'
							history={history}
							push={history.push}
						/>
						<NavTile
							icon={faFolderOpen}
							title='Settings'
							route='/admin/settings/agency'
							history={history}
							push={history.push}
						/>
						<NavTile
							icon={faCalculator}
							title='Calculator'
							onClick={() =>
								receiveUserDetails({ status: true, type: 'calculator', user: true })
							}
							history={history}
							push={history.push}
						/>

						<NavTile
							icon={faClipboardList}
							title='Reports'
							onClick={() =>
								receiveUserDetails({ status: true, type: 'reports', user: true })
							}
							history={history}
							push={history.push}
						/>

						<NavTile
							icon={faClipboardList}
							title='Authorize Payment'
							onClick={() => authorizePendingShifts()}
							history={history}
							push={history.push}
						/>

						<NavTile
							icon={faClipboardList}
							title='Capture Approved'
							onClick={() => captureApprovedShifts()}
							history={history}
							push={history.push}
						/>
					</ColumnFlex>
				</ColumnFlex>
			</LandingContentSecNav>
		</>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		receiveUserDetails: (params) => dispatch(receiveUserDetails(params)),
		authorizePendingShifts: () => dispatch(authorizePendingShifts()),
		captureApprovedShifts: () => dispatch(captureApprovedShifts()),
		goToSettings: () => dispatch(push('/admin/settings/agency')),
	};
};

export default connect(null, mapDispatchToProps)(AdminNavigationHandler);
