import React from 'react';
import { connect } from 'react-redux';

import {
	dischargeProvider,
	verifyProviderOffice,
	unVerifyProviderOffice,
	deleteTestUserAccount,
} from 'reducers/admin';

import { ModelContainer } from 'AppComp/EventModel';

import UserModelController from './UserModelController';

class UserModelHadler extends UserModelController {
	render() {
		return <ModelContainer>{this.renderViews()}</ModelContainer>;
	}
}

const mapStateToProps = ({ admin }) => {
	return {
		admin: admin.admin,
		userDetail: admin.userDetail,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		dischargeProvider: (id) => dispatch(dischargeProvider(id)),
		verifyProviderOffice: (providerId) => dispatch(verifyProviderOffice(providerId)),
		unVerifyProviderOffice: (providerId) => dispatch(unVerifyProviderOffice(providerId)),
		deleteTestUserAccount: (userId, userType) =>
			dispatch(deleteTestUserAccount(userId, userType)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserModelHadler);
