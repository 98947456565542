import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { SHIFT_MODEL } from '../../../core/api/models';

const updateShift = async ({ shift_id, params }) => {
	try {
		const data = await api.graph({
			query: `mutation {
					updateShift ( 
					shift_id: ${shift_id},
					shift_input: ${api.graphStringify(params)}
					) {
						${SHIFT_MODEL}
					}
				}`,
		});

		return data.updateShift;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const useUpdateShiftMutation = () => {
	return useMutation({
		mutationKey: ['updateShift'],
		mutationFn: (values) => {
			return updateShift(values);
		},
	});
};
