import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const sendMessage = async ({ conversationId, text }) => {
	try {
		const query = `mutation{
				providerSendMessage(
					conversation_id: ${conversationId},
					text: "${text}"
				) 
			}`;

		const data = await api.graph({ query });

		return data.providerSendMessage;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useSendMessageMutation = () => {
	return useMutation({
		mutationKey: ['sendMessage'],
		mutationFn: (values) => {
			return sendMessage(values);
		},
	});
};
