import styled from 'styled-components';
import React, { useState } from 'react';

import { faTimes, faCog } from '@fortawesome/free-solid-svg-icons';

import { ErrorMessage } from './ManageSectionStyles';
import { Colors } from './ManageSectionStyles';
import Icon from 'AppComp/Icon';

import { SubSubmitButton } from './FormGen';
import { wrap } from 'lodash';
import { AnimatePresence } from 'framer-motion';

import { EditTrait } from '../ManageTrait';

/* 

  Radio Component are used in the option where user can only make one selection out of many
*/
const RadioCon = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const ULStyles = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
	li {
		display: flex;
		flex-direction: row;
		gap: 15px;
		align-items: center;
	}
`;
// radio input with a large circle to select
const RadioCustomInput = styled.input;

const LabelStyling = styled.label`
	font-size: 1rem;
	color: #000;
	font-weight: 500;
	line-height: 0.9;
`;

export const RadioFormComp = (props) => {
	const { title, options, value, name, onChange, error } = props;

	console.log('va;ue', value);
	const renderOptions = () => {
		return options.map((d, i) => {
			return (
				<li key={i}>
					<input
						name={name}
						type='radio'
						value={d.value}
						checked={d.value === value}
						onChange={onChange}
					/>
					<LabelStyling>{d.label}</LabelStyling>
				</li>
			);
		});
	};
	return (
		<RadioCon>
			<h3
				style={{
					letterSpacing: '.1rem',
				}}
			>
				{title}
			</h3>
			<ULStyles>{renderOptions()}</ULStyles>
			<ErrorMessage>{error && error}</ErrorMessage>
		</RadioCon>
	);
};

export const StyledInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5rem;
`;

export const StyledInput = styled.input`
	border: 1px solid #81b0df;
	border-width: 1px;
	box-shadow: none;
	border-radius: 4px;
	color: ${Colors.forms.fontColor};
	line-height: 40px;
	padding: 0 12px;
	width: 100%;
	min-height: 42px;

	font-size: 15px;

	::placeholder {
		color: ${Colors.forms.placeholderColor};
		opacity: 1;

		font-size: 15px;
	}

	:focus {
		outline: thin dotted currentColor;
	}
`;

export const TextFormComp = (props) => {
	const { title, value, name, onChange, error, placeholder } = props;
	return (
		<StyledInputContainer>
			<h3
				style={{
					letterSpacing: '.1rem',
				}}
			>
				{title}
			</h3>
			<StyledInput placeholder={placeholder} name={name} value={value} onChange={onChange} />
			<ErrorMessage>{error && error}</ErrorMessage>
		</StyledInputContainer>
	);
};

const TraitOptionsCon = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
`;

const OptionCon = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0px;
`;

const TextOptionTrait = (props) => {
	const { arrayHelpers, name, onChange, value, error, index } = props;
	return (
		<StyledInputContainer>
			<TraitOptionsCon>
				<StyledInput
					placeholder={`Enter an option ${index + 1}`}
					text='text'
					name={name}
					value={value}
					onChange={onChange}
				/>
				<Icon icon={faTimes} color='red' onClick={() => arrayHelpers.remove(index)} />
			</TraitOptionsCon>
			<ErrorMessage>{error && error}</ErrorMessage>
		</StyledInputContainer>
	);
};
export const MultiOptionsComp = (props) => {
	const { formik, arrayHelpers, error } = props;
	const renderOptions = () => {
		if (formik.values.options && formik.values.options.length > 0) {
			return formik.values.options.map((option, index) => {
				return (
					<TextOptionTrait
						key={index}
						arrayHelpers={arrayHelpers}
						name={`options[${index}]`}
						value={option}
						index={index}
						onChange={formik.handleChange}
					/>
				);
			});
		}
	};
	return (
		<StyledInputContainer>
			<h3
				style={{
					letterSpacing: '.1rem',
				}}
			>
				Options
			</h3>
			<OptionCon>{renderOptions()}</OptionCon>

			<SubSubmitButton
				onClick={() => arrayHelpers.push('')}
				style={{
					margin: 'auto',
				}}
				type='button'
			>
				<p>Add an Option</p>
			</SubSubmitButton>
			<ErrorMessage>{error && error}</ErrorMessage>
		</StyledInputContainer>
	);
};

// need a select drop down with the same style as the StyledInput
const SelectInput = styled.select`
	border: 1px solid #81b0df;
	border-width: 1px;
	box-shadow: none;
	border-radius: 4px;
	color: ${Colors.forms.fontColor};
	line-height: 40px;
	padding: 5px 12px;
	width: 100%;
	min-height: 42px;

	font-size: 15px;

	::placeholder {
		color: ${Colors.forms.placeholderColor};
		opacity: 1;

		font-size: 15px;
	}

	:focus {
		outline: thin dotted currentColor;
	}
`;

export const SelectFormComp = (props) => {
	const { title, value, name, onChange, error, options } = props;

	const renderOptions = () => {
		return options.map((d, i) => {
			return (
				<option key={i} value={d.value}>
					{d.label}
				</option>
			);
		});
	};

	return (
		<StyledInputContainer>
			<h3
				style={{
					letterSpacing: '.1rem',
				}}
			>
				{title}
			</h3>
			<SelectInput name={name} value={value} onChange={onChange}>
				{renderOptions()}
			</SelectInput>

			<ErrorMessage>{error && error}</ErrorMessage>
		</StyledInputContainer>
	);
};

// now i need a textarea with that same styling but max its' height at liek 400px but takes it's contaier width
const TextAreaInput = styled.textarea`
	border: 1px solid #81b0df;
	border-width: 1px;
	box-shadow: none;
	border-radius: 4px;
	color: ${Colors.forms.fontColor};
	padding: 5px 12px;
	width: 100%;
	min-height: 42px;
	font-size: 15px;
	resize: none;
	height: 150px;
	::placeholder {
		color: ${Colors.forms.placeholderColor};
		opacity: 1;

		font-size: 15px;
	}

	:focus {
		outline: thin dotted currentColor;
	}
`;

export const TextAreaFormComp = (props) => {
	const { title, value, name, onChange, error, placeholder } = props;

	return (
		<StyledInputContainer>
			<h3
				style={{
					letterSpacing: '.1rem',
				}}
			>
				{title}
			</h3>
			<TextAreaInput
				placeholder={placeholder}
				name={name}
				value={value}
				onChange={onChange}
			/>
			<ErrorMessage>{error && error}</ErrorMessage>
		</StyledInputContainer>
	);
};

const TraitDisplayCon = styled.div`
	display: flex;
	flex-direction: column;

	border-radius: 10px;
	gap: 10px;
	width: 100%;

	padding: 20px;
	border: 1px solid rgba(43, 105, 166, 0.3);
	@media (min-width: 700px) {
		padding: 30px;
	}
`;

const HeaderSectionCon = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const StatusCon = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`;
export const TraitDisplay = (props) => {
	const { trait } = props;

	const { label, trait_type, required, active, description, meta_data, id } = trait;
	console.log('required', required);
	const [showEditTrait, setShowEditTrait] = useState(false);

	return (
		<>
			<AnimatePresence>
				{showEditTrait && (
					<EditTrait
						isOpen={showEditTrait}
						setShowEditTrait={setShowEditTrait}
						trait={trait}
					/>
				)}
			</AnimatePresence>
			<TraitDisplayCon>
				<HeaderSectionCon>
					<h3>{label}</h3>
					<div
						onClick={() => setShowEditTrait(true)}
						style={{
							display: 'flex',
							flexDirection: 'row',
							gap: '10px',
							alignItems: 'center',
							cursor: 'pointer',
						}}
					>
						<h3>{trait_type}</h3>
						<Icon icon={faCog} color='black' />
					</div>
				</HeaderSectionCon>
				<StatusCon>
					<h4>{required === true ? 'Required' : 'Not Required'}</h4>
					<h4> || </h4>
					<h4>{active === true ? 'Active' : 'Not Active'}</h4>
				</StatusCon>
				<h5
					style={{
						textOverflow: 'ellipsis',
						overflowWrap: 'break-word',
					}}
				>
					{description}
				</h5>
			</TraitDisplayCon>
		</>
	);
};
