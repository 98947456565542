import styled from 'styled-components';

export const StyledTimeInput = styled.input`
	padding: 10px;
	border: ${(props) => (props.isValid ? 'none' : '2px solid #ff6347')};
	border-radius: 4px;
	font-size: 16px;

	background: rgb(229, 229, 229);

	border: none;

	margin: auto;

	font-size: 24px;
	line-height: 29px;
`;

export const StyledDateTimeInput = styled.input`
	border-radius: 12px;
	border: solid 1px #c6c6c6;
	color: black;
	padding: 8px 12px;
`;
