import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledJobPost = styled.div`
	max-width: 100%;
	border: 1px solid ${colors.primary.primary};
	border-radius: 8px;
	padding: 20px;
	background: ${colors.white};
	cursor: pointer;

	display: flex;
	gap: 8px;

	transition: transform 0.1s ease-in-out;

	&:hover {
		transform: scale(1.02);
	}
`;

export const StyledInformationContainer = styled.div`
	margin-left: 0.25rem;
`;

export const StyledJobPostTitle = styled.h3`
	font-size: 1rem;
	font-weight: bold;
	color: ${colors.primary.primary};

	line-height: 1.4;

	margin-bottom: 0.25rem;
`;

export const StyledJobPostDescription = styled.p`
	font-size: 14px;
	color: ${colors.primary.primary};

	line-height: 1.4;
`;
