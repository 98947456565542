import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Colors from 'colors';
import { toast } from 'react-toastify';
import useWindowSize from 'scripts/useWindowSize';

import {
	faEyeSlash,
	faEye,
	faMailBulk,
	faMapMarker,
	faChevronRight,
	faCalendarAlt,
	faMars,
	faVenus,
	faChevronLeft,
	faUser,
	faClipboard,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { DAYS_OF_WEEK } from 'constants';

import { BackDropBg } from 'basicStyles';
import { isMobile } from 'react-device-detect';
import Title from 'AppComp/Title';
import { ColumnFlex, RowFlex, Avatar } from '../../styled/Basic';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Calendar, Views } from 'react-big-calendar';
import AnimateHeight from 'react-animate-height';
import Text from 'AppComp/Text';
import { CAL_LOCALIZER } from 'AppComp/App';
import Icon from 'AppComp/Icon';
import Button from 'AppComp/Button';
import api from 'scripts/api';
import Select from 'react-select';

import { SELECTED_ACTION_VIEW } from './ProviderHomeLandingHandler';

const framerSidebarBackground = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};

const framerPanel = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};

import { ParentContainer, PHWhiteCon, PHDColumn } from '../ProviderHomeDashboard';
import { CalandarHeaderHelper, CalendarWeekHeader } from '../../patient/ClientSchedule/Schedule';

import { APPLICATION_STATUS_ENUM, TOAST_SUCCESS_CONFIG, TOAST_ERROR_CONFIG } from 'constants';

export const CustomLengthCon = styled.div`
	width: ${({ width }) => width || '280px'};
	color: black;
	border-radius: 12px;
`;

export const ButtonTing = styled.div`
	background-color: white;
	border: 2px solid ${Colors.primary.primary};
	border-radius: 50px;
	padding: 12px 42px;
	display: flex;
	justify-content: center;
	align-items: center;

	cursor: pointer;

	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}
`;

export const ActivityRadioInput = (props) => {
	const { label } = props;

	return (
		<RowFlex alignCenter gap='6px' margin='8px'>
			<div
				style={{
					width: '20px',
					height: '20px',
					borderRadius: '8px',
					border: `solid 2px ${Colors.primary.primary}`,
					backgroundColor: `${Colors.primary.primary}`,
				}}
			/>
			<Text mediumText color='black'>
				{label}
			</Text>
		</RowFlex>
	);
};

export const CarePlanActionSection = (props) => {
	const [show, setShow] = useState(false);

	return (
		<ColumnFlex
			fullWidth
			padding='12px 0'
			style={{
				border: `solid 3px ${Colors.theme.dark_accent_background}`,
				borderRadius: '16px',
				//display:non
			}}
			alignStart
		>
			<RowFlex gap='8px' justifyBetween alignCenter fullWidth>
				<Text bold largeText>
					{props.shiftTitle}{' '}
					<span
						style={{
							fontSize: '1rem',
							fontStyle: 'italic',
						}}
					>
						{`(${props.values.length})`}
					</span>
				</Text>
				<RowFlex
					style={{ cursor: 'pointer' }}
					onClick={() => setShow(!show)}
					center
					gap='8px'
				>
					<Text pointer color={Colors.primary.primary}>
						{show ? 'Hide' : 'Show '}
					</Text>
					<Icon
						icon={!show ? faEye : faEyeSlash}
						size='sm'
						color={Colors.primary.primary}
					/>
				</RowFlex>
			</RowFlex>
			<AnimateHeight
				style={{
					width: '100%',
				}}
				duration={500}
				height={show ? 'auto' : '0%'}
			>
				<RowFlex
					style={{
						textAlign: 'left',
					}}
					wrap
					gap='4px'
					fullWidth
				>
					{props.values.map((item, index) => {
						return (
							<CustomLengthCon key={index} width='45%'>
								<ActivityRadioInput label={item} />
							</CustomLengthCon>
						);
					})}
				</RowFlex>
			</AnimateHeight>
		</ColumnFlex>
	);
};

export const MainPanel = styled(motion.div)`
	position: fixed;
	display: flex;
	height: fit-content;
	width: fit-content;
	z-index: 100;
	left: 0;
	display: flex;
	flex-direction: row;
	top: 0;
	border: 12px;
	gap: 20px;
	top: 50%;
	left: 50%;
	max-width: 90vw;
	transform: translate(-50%, -50%);
	max-height: 100vh;
	padding: 20px 0;
	overflow: auto;
`;

export let allViews = Object.keys(Views).map((k) => Views[k]);
import { WORK_TIME_FRAME_TYPE } from 'constants';

const CarePlanInfo = (props) => {
	console.log('carePlanInfo', props);
	const { carePlan, selectedAction, selectedActionView, conversations } = props;
	const { dob, gender, address_city, address_zip, medical_notes } = carePlan;

	const [userSelectedDate, setUserSelectedDate] = useState(new Date());
	const currentDate = useMemo(() => userSelectedDate, [userSelectedDate]);

	const { width, height } = useWindowSize();

	const [view, setView] = useState({
		label: 'Week',
		value: Views.WEEK,
	});

	const [events, setEvents] = useState([]);

	const [displayLabel, setDisplayLabel] = useState('Day');

	useEffect(() => {
		// create the events array that Schedule will consume
		if (carePlan) {
			createScheduleEventsFromCarePlan(carePlan.workTimeFrames);
		}
	}, [carePlan]);

	useEffect(() => {
		if (selectedActionView === 'JOB') {
			createScheduleEventsFromCarePlan(selectedAction.workTimeFrames);
		}
	}, [selectedActionView, selectedAction]);

	const createScheduleEventsFromCarePlan = (workTimeFrames) => {
		if (carePlan === null) {
			return;
		}

		// loop through workTimeFrames
		// first get all the active ones
		const activeWorkTimeFrames = workTimeFrames.filter((workTimeFrame) => {
			return workTimeFrame.active_status_enum === WORK_TIME_FRAME_TYPE.ACTIVE;
		});

		console.log('activeWorkTimeFrames', activeWorkTimeFrames);
		// create custom shape that react-big-calendar can consume
		const _events = activeWorkTimeFrames.map((workTimeFrame) => {
			// ensure to convert gmt to local time

			const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's time zone

			const userStartDate = new Date(
				workTimeFrame.start_time.toLocaleString('en-US', { timeZone: userTimezone })
			);
			const userEndDate = new Date(
				workTimeFrame.end_time.toLocaleString('en-US', { timeZone: userTimezone })
			);

			return {
				title: 'Care Session',
				start: userStartDate,
				end: userEndDate,
				allDay: false,
				resource: {
					type: 'careSession',
					workTimeFrame: workTimeFrame,
					carePlan: carePlan,
				},
			};
		});

		setEvents(_events);
	};

	const renderSections = () => {
		const section = Object.keys(CARE_PLAN_ADLS)
			.filter((key) => {
				if (CARE_PLAN_ADLS[key].shiftTitle) {
					return true;
				} else {
					return false;
				}
			})
			.map((key) => CARE_PLAN_ADLS[key]);

		//console.log('isDisabled', isDisabled);
		return section.map((item, index) => {
			return <CarePlanActionSection {...item} key={index} />;
		});
	};

	const convertDOBToReadableAge = (dob) => {
		// dob is in the format of 'YYYY-MM-DD'
		const age = moment().diff(dob, 'years');
		return `${age} years`;
	};

	const applyToJobPost = async () => {
		try {
			const applyToJobPostRes = await api.graph({
				query: `mutation {
					applyToJobPost(
						job_post_id: ${selectedAction.jobPost.id},
						provider_id: ${props.provider.id}
					){
						id
					}
				}`,
			});

			if (applyToJobPostRes.applyToJobPost) {
				toast.success('Applied to Job', TOAST_SUCCESS_CONFIG);
				props.handleExitModalWithRefresh();
			}
		} catch (err) {
			toast.error('Error carePro applying to jobpost', TOAST_ERROR_CONFIG);
			console.log('err', err);
		}
	};
	const renderDropDownForView = () => {
		const options = [
			{
				label: 'Day',
				value: Views.DAY,
			},
			{
				label: 'Week',
				value: Views.WEEK,
			},
			{
				label: 'Month',
				value: Views.MONTH,
			},
			{
				label: 'Agenda',
				value: Views.AGENDA,
			},
		];

		return (
			<div
				style={{
					minWidth: '300px',
					zIndex: 1000,
				}}
			>
				<Select
					placeholder={view.label}
					options={options}
					className='doc-filter-select'
					defaultValue={view.value}
					onChange={(selectedOption) => {
						setView(selectedOption);
					}}
				/>
			</div>
		);
	};

	const respondToJobOffer = async (status) => {
		try {
			console.log('selectedAction', selectedAction);
			const respondToJobOfferRes = await api.graph({
				query: `mutation {
          careProJobOfferResponse(
            job_id: ${selectedAction.id},
						response_status: "${status}"
             provider_id: ${props.provider.id}
            ) {
            	id
          	}		
        }`,
			});
			if (respondToJobOfferRes.careProJobOfferResponse) {
				toast.success('Responded to job offer' + ' ' + status, TOAST_SUCCESS_CONFIG);
				props.handleExitModalWithRefresh();
			}
		} catch (err) {
			console.log('err', err);
			toast.error('Error responding to job offer', TOAST_ERROR_CONFIG);
		}
	};

	const handleChatClick = () => {
		// first we need to have all the conversation from this CarePr
		console.log('carePlna', carePlan);
		if (carePlan.pocUser === null) {
			return;
		}
		// check if this user id in found in any of the conversations

		const conversation = conversations
			.filter((d) => d.user !== null)
			.find((d) => d.user.id === carePlan.pocUser.id);

		console.log('conversation', conversation);

		if (conversation) {
			props.pushToChat(conversation.id);
		} else {
			// need to create the conversatin first
			props.careProContactUser({
				careProId: props.provider.id,
				userId: carePlan.pocUser.id,
			});
		}
		//
	};

	const handleUserSelectDateChange = (direction) => {
		// direction = true => forward
		// direction = false => backward

		// based on the selected view is how much we'll alter the selected date
		// if view is day, we'll alter by 1 day
		// if view is week, we'll alter by 7 days
		// if view is month, we'll alter by 30 days

		let newDate = new Date(currentDate);
		if (view.value === Views.DAY) {
			newDate.setDate(newDate.getDate() + (direction ? 1 : -1));
		}
		if (view.value === Views.WEEK) {
			newDate.setDate(newDate.getDate() + (direction ? 7 : -7));
		}
		if (view.value === Views.MONTH) {
			newDate.setDate(newDate.getDate() + (direction ? 30 : -30));
		}
		setUserSelectedDate(newDate);
	};

	const renderJobApplication = () => {
		const { budget_low, budget_high } = selectedAction.jobPost;

		let title = '';
		if (selectedAction.status === APPLICATION_STATUS_ENUM.INVITED) {
			title = 'Invited to Apply to Job';
		} else if (selectedAction.status === APPLICATION_STATUS_ENUM.APPLIED) {
			title = 'Applied to Job';
		}
		return (
			<PHWhiteCon
				style={{
					flex: 3,
					minWidth: isMobile ? '100%' : '440px',
					backgroundColor: 'white',
				}}
			>
				<Title subtitle color={Colors.greenLightTeal} margin='0 0 20px 0' fontWeight='700'>
					{title}
				</Title>
				<RowFlex fullWidth gap='16px' justifyBetween>
					<ColumnFlex
						style={{
							borderRadius: '16px',
							backgroundColor: Colors.theme.dark_accent_background,
							padding: '20px',
						}}
						margin='10px 0 12px 0'
						alignCenter
					>
						<RowFlex fullWidth>
							<ColumnFlex gap='4px'>
								<Text midLarge fontStyle='thin'>
									Budget range
								</Text>
								<Text bold largeText color={'black'}>
									{`$${budget_low} - $${budget_high}`}
								</Text>
								<Text midLarge fontStyle='thin'>
									per hour
								</Text>
							</ColumnFlex>
						</RowFlex>
					</ColumnFlex>
					<ColumnFlex
						style={{
							flex: 1,
						}}
						center
						gap='8px'
					>
						{selectedAction.status === APPLICATION_STATUS_ENUM.INVITED && (
							<ButtonTing
								onClick={() => applyToJobPost()}
								style={{
									borderRadius: '32px',
									width: '180px',
									height: '50px',
									backgroundColor: Colors.primary.primary,
								}}
							>
								<Text fontWeight='500' cursor color={'white'} largeText>
									Apply
								</Text>
							</ButtonTing>
						)}
						{carePlan.pocUser && (
							<ButtonTing
								onClick={() => handleChatClick()}
								style={{
									borderRadius: '32px',
									width: '180px',
									height: '50px',
									backgroundColor: Colors.greenLightTeal,
								}}
							>
								<Text fontWeight='500' cursor color={'white'} largeText>
									Chat
								</Text>
							</ButtonTing>
						)}
					</ColumnFlex>
				</RowFlex>
			</PHWhiteCon>
		);
	};
	const renderJobOffer = () => {
		const { hourly_amount, status, offer_status } = props.selectedAction;

		return (
			<PHWhiteCon
				style={{
					flex: 3,
					minWidth: isMobile ? '100%' : '440px',
					backgroundColor: 'white',
				}}
			>
				<Title subtitle color={Colors.greenLightTeal} margin='0 0 20px 0' fontWeight='700'>
					Job Offer
				</Title>
				<RowFlex fullWidth>
					<ColumnFlex
						style={{
							borderRadius: '16px',
							backgroundColor: Colors.theme.dark_accent_background,
							padding: '20px 10px 10px 10px',
							flex: 1,
						}}
						margin='10px 0 12px 0'
						center
						gap='8px'
					>
						<Text midLarge fontStyle='thin'>
							Offered
						</Text>
						<Text bold largeText color={'black'}>
							{`$${hourly_amount / 100} `}
						</Text>
						<Text midLarge fontStyle='thin'>
							per hour
						</Text>
					</ColumnFlex>
					<ColumnFlex
						style={{
							flex: 1,
						}}
						center
						gap='8px'
					>
						<ButtonTing
							onClick={() => respondToJobOffer('accepted')}
							style={{
								borderRadius: '32px',
								width: '180px',
								height: '50px',
								backgroundColor: Colors.primary.primary,
							}}
						>
							<Text fontWeight='500' cursor color={'white'} largeText>
								Accept
							</Text>
						</ButtonTing>
						<ButtonTing
							onClick={() => respondToJobOffer('rejected')}
							style={{
								borderRadius: '32px',
								width: '180px',
								height: '50px',
								backgroundColor: Colors.heartRed,
								border: 'none',
							}}
						>
							<Text fontWeight='500' cursor color={'white'} largeText>
								Reject
							</Text>
						</ButtonTing>
						{carePlan.pocUser && (
							<ButtonTing
								onClick={() => handleChatClick()}
								style={{
									borderRadius: '32px',
									width: '180px',
									height: '50px',
									backgroundColor: Colors.greenLightTeal,
									border: 'none',
								}}
							>
								<Text fontWeight='500' cursor color={'white'} largeText>
									Chat
								</Text>
							</ButtonTing>
						)}
					</ColumnFlex>
				</RowFlex>
			</PHWhiteCon>
		);
	};

	const renderSelectedAction = () => {
		if (props.selectedActionView === SELECTED_ACTION_VIEW.JOB) {
			return renderJobOffer();
		} else {
			return renderJobApplication();
		}
	};
	console.log('width', width);
	return (
		<>
			<BackDropBg
				onClick={() => props.handleExitClickModal()}
				{...framerSidebarBackground}
				aria-hidden='true'
			></BackDropBg>
			<MainPanel aria-label='Sidebar' {...framerPanel} style={{}}>
				<ColumnFlex gap='12px'>
					<RowFlex
						style={{
							flexDirection: width < 1000 ? 'column' : 'row',
						}}
						gap='16px'
					>
						{renderSelectedAction()}
						<PHWhiteCon
							style={{
								flex: 3,
								minWidth: isMobile ? '100%' : '440px',
								backgroundColor: 'white',
							}}
						>
							<Title
								subtitle
								color={Colors.greenLightTeal}
								margin='0 0 20px 0'
								fontWeight='700'
							>
								Care Plan
							</Title>
							<ColumnFlex
								style={{
									borderRadius: '16px',
									backgroundColor: Colors.theme.dark_accent_background,
									padding: '20px ',
								}}
								fullWidth
								margin='10px 0 12px 0'
								alignCenter
							>
								<RowFlex
									style={{
										flex: 1,
									}}
									padding='8px'
									justifyBetween
									fullWidth
								>
									<RowFlex alignCenter gap='8px'>
										<Icon
											size='2x'
											color={Colors.greenLightTeal}
											icon={faMapMarker}
										/>
										<Text midLarge> {`${address_city} - ${address_zip}`}</Text>
									</RowFlex>
									<RowFlex alignCenter gap='8px'>
										<Icon
											size='2x'
											color={Colors.greenLightTeal}
											icon={gender === 'female' ? faVenus : faMars}
										/>

										<Text midLarge> {gender}</Text>
									</RowFlex>
								</RowFlex>
								<RowFlex
									fullWidth
									style={{
										flex: 1,
									}}
									padding='8px'
									justifyBetween
								>
									<RowFlex alignCenter gap='8px'>
										<Icon
											size='2x'
											color={Colors.greenLightTeal}
											icon={faCalendarAlt}
										/>
										<Text midLarge>4 Days a week</Text>
									</RowFlex>

									<RowFlex alignCenter gap='8px'>
										<Icon
											size='2x'
											color={Colors.greenLightTeal}
											icon={faUser}
										/>
										<Text midLarge>{convertDOBToReadableAge(dob)}</Text>
									</RowFlex>
								</RowFlex>
							</ColumnFlex>
						</PHWhiteCon>
					</RowFlex>
					{width < 1600 && (
						<PHWhiteCon
							style={{
								minWidth: isMobile ? '100%' : '440px',
								backgroundColor: 'white',
								height: 'fit-content',
							}}
						>
							<Title
								subtitle
								color={Colors.greenLightTeal}
								margin='0 0 20px 0'
								fontWeight='700'
							>
								Care Needs
							</Title>
							<ColumnFlex
								style={{
									borderRadius: '16px',
									backgroundColor: Colors.theme.dark_accent_background,
									padding: '20px 10px 10px 10px',
								}}
								fullWidth
								margin='10px 0 12px 0'
								alignCenter
							>
								{renderSections()}
							</ColumnFlex>
						</PHWhiteCon>
					)}
					<PHWhiteCon
						style={{
							flex: 3,
							minWidth: width < 1100 ? '88vw' : '1000px',
							backgroundColor: 'white',
						}}
					>
						<RowFlex fullWidth justifyBetween>
							<Title
								subtitle
								color={Colors.greenLightTeal}
								margin='0 0 20px 0'
								fontWeight='700'
							>
								Schedule <span style={{ fontSize: '16px' }}>({displayLabel})</span>
							</Title>
							<RowFlex gap='8px' alignCenter>
								{renderDropDownForView()}
								<RowFlex
									alignCenter
									gap='12px'
									style={{
										cursor: 'pointer',
									}}
								>
									<Icon
										onClick={() => handleUserSelectDateChange(false)}
										icon={faChevronLeft}
										color={Colors.greenLightTeal}
										size='md'
									/>
									<Icon
										onClick={() => handleUserSelectDateChange(true)}
										icon={faChevronRight}
										color={Colors.greenLightTeal}
										size='md'
									/>
								</RowFlex>
							</RowFlex>
						</RowFlex>
						<div
							style={{
								borderRadius: '16px',
								backgroundColor: Colors.theme.dark_accent_background,
								padding: '20px 10px 10px 10px',
								height: '600px',
							}}
							fullWidth
							margin='10px 0 12px 0'
							alignCenter
						>
							<AutoSizer>
								{({ height, width }) => (
									<div
										style={{
											width: width,
											height: height - 30,
										}}
									>
										<Calendar
											localizer={CAL_LOCALIZER}
											defaultView={view.value}
											view={view.value}
											views={allViews}
											scrollToTime={currentDate}
											date={currentDate}
											events={events}
											step={60}
											showMultiDayTimes
											components={{
												toolbar: (passedProps) => {
													return (
														<CalandarHeaderHelper
															setDisplayLabel={setDisplayLabel}
															{...passedProps}
														/>
													);
												},
												week: {
													header: (props) => {
														console.log('header', props);

														return <CalendarWeekHeader {...props} />;
													},
												},
											}}
											formats={{
												dayFormat: (date, localizer) => {
													return DAYS_OF_WEEK[date.getDay()];
												},
											}}
										/>
									</div>
								)}
							</AutoSizer>
						</div>
					</PHWhiteCon>
					{width < 1600 && (
						<RowFlex
							fullWidth
							center
							style={{
								margin: '12px 0',
							}}
						>
							<ButtonTing
								onClick={() => props.handleExitClickModal()}
								margin='auto'
								style={{
									borderRadius: '32px',
									width: '180px',
									height: '50px',
								}}
							>
								<Text
									fontWeight='500'
									cursor
									color={Colors.primary.primary}
									largeText
								>
									Exit
								</Text>
							</ButtonTing>
						</RowFlex>
					)}
				</ColumnFlex>
				<ColumnFlex gap='12px'>
					{width > 1600 && (
						<>
							<PHWhiteCon
								style={{
									minWidth: isMobile ? '100%' : '440px',
									backgroundColor: 'white',
									height: 'fit-content',
								}}
							>
								<Title
									subtitle
									color={Colors.greenLightTeal}
									margin='0 0 20px 0'
									fontWeight='700'
								>
									Care Needs
								</Title>
								<ColumnFlex
									style={{
										borderRadius: '16px',
										backgroundColor: Colors.theme.dark_accent_background,
										padding: '20px 10px 10px 10px',
									}}
									fullWidth
									margin='10px 0 12px 0'
									alignCenter
								>
									{renderSections()}
								</ColumnFlex>
							</PHWhiteCon>
							<RowFlex fullWidth center>
								<ButtonTing
									onClick={() => props.handleExitClickModal()}
									style={{
										borderRadius: '32px',
										width: '180px',
										height: '50px',
									}}
								>
									<Text
										fontWeight='500'
										cursor
										color={Colors.primary.primary}
										largeText
									>
										Exit
									</Text>
								</ButtonTing>
							</RowFlex>
						</>
					)}
				</ColumnFlex>
			</MainPanel>
		</>
	);
};

export default CarePlanInfo;

const EMPTY_CARE_PLAN_ADLS = {
	1: {
		shiftTitle: 'Personal Care',
		title: 'Activities willing to do:',
		values: [],
	},
	2: { title: 'Environment willing to work in:', values: [] },
	3: { title: 'Comfortable being in an environment with:', values: [] },
	4: {
		shiftTitle: 'Cooking',
		title: 'Meals I am able to cook:',
		values: [],
	},
	5: {
		shiftTitle: 'Transportation',
		title: 'Transportation:',
		values: [],
	},
	6: { title: 'Languages I speak:', values: [] },
	7: {
		shiftTitle: 'Assistance',
		title: 'I would feel comfortable assisting patients with:',
		values: [],
	},
};

const CARE_PLAN_ADLS = {
	1: {
		shiftTitle: 'Personal Care',
		title: 'Activities willing to do:',
		values: ['Bed bound', 'Dementia'],
	},
	2: { title: 'Environment willing to work in:', values: [] },
	3: { title: 'Comfortable being in an environment with:', values: [] },
	4: {
		shiftTitle: 'Cooking',
		title: 'Meals I am able to cook:',
		values: [
			'Chinese',
			'French',

			'Indian',
			'Italian',
			'Japanese',
			'Korean',
			'Mexican',
			'Spanish',

			'Vietnamese',
		],
	},
	5: {
		shiftTitle: 'Transportation',
		title: 'Transportation:',
		values: ['Car', 'Public Transportation', 'Walk', 'Bike', 'Wheelchair', 'Gurney', 'Other'],
	},
	6: { title: 'Languages I speak:', values: [] },
	7: {
		shiftTitle: 'Assistance',
		title: 'I would feel comfortable assisting patients with:',
		values: [
			'Bed bound',
			'Dementia',
			'Alzheimer’s',
			'Parkinson’s',
			'ALS',
			'Autism',
			'Stroke',
			'Heart Disease',
			'Pulmonary Disease',
			'Kidney Disease',
			'Liver Disease',
			'Blindness',
			'Deafness',
			'Paraplegic',
			'Quadriplegic',
			'Other',
		],
	},
};
