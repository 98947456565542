import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledOnboardingButton = styled.div`
	max-width: 100%;
	border: 1px solid ${colors.primary.primary};
	border-radius: 8px;
	padding: 20px;
	background: ${colors.primary.primary};
	cursor: pointer;

	display: flex;
	gap: 16px;

	transition: transform 0.1s ease-in-out;

	&:hover {
		transform: scale(1.02);
	}
`;

export const StyledInformationContainer = styled.div`
	display: flex;
	flex-direction: column;

	justify-content: center;
`;

export const StyledPlatformOnboardingTitle = styled.h3`
	font-size: 1em;
	color: ${colors.white};
`;

export const StyledBlueCheckMark = styled.div`
	width: 30px;
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: ${colors.primary.primary};
	border: 2px solid ${colors.white};
	border-radius: 50%;

	color: ${colors.white};
`;
