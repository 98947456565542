import React, { useState } from 'react';
import Title from '../../Title';
import { Formik, Form, Field } from 'formik';
import Colors from '../../../constants/colors';
import {
	MARGIN_TITLE,
	FormItemsLength,
	FORM_ITEM_LENGTH,
	OBFormButtonText,
	OBBasicCon,
	OBSubText,
} from './OnboardViews';
import { ColumnFlex } from '../../styled/Basic';

import { validateProviderInfo } from './validation';
import StyledTextInput, { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';
import Icon from 'AppComp/Icon';

import { faMapMarkerAlt, faPortrait, faSearchLocation } from '@fortawesome/free-solid-svg-icons';
import PageLoaderView from '../../PageLoaderView';
import api from '../../../scripts/api';

import { BackText } from '../../patient/Onboard/OnboardViews';

const GENDERS = [
	{
		value: '',
		label: '',
	},
	{
		value: 'female',
		label: 'Female',
	},
	{
		value: 'male',
		label: 'Male',
	},
];

const POB3ProviderInfo = (props) => {
	const [isValidOverride, setValidOverride] = useState(false);

	const handleSubmit = (values, form) => {
		const newState = {
			...props.state,

			firstName: values.firstName,
			lastName: values.lastName,
			displayName: values.displayName,
			gender: values.gender,
		};
		props._UPS(newState);

		props.ChangeLocation(3);
	};

	const handleInitValue = () => {
		// check if props.state care_type & rate are not null
		if (props.state.firstName && props.state.lastName && props.state.gender) {
			if (!isValidOverride) {
				setValidOverride(true);
			}

			return {
				firstName: props.state.firstName,
				lastName: props.state.lastName,
				gender: props.state.gender,
			};
		} else {
			return {};
		}
	};

	return (
		<OBBasicCon
			style={{
				maxWidth: '420px',
			}}
		>
			{/* <Icon size='3x' color={Colors.greenLightTeal} icon={faPortrait} /> */}
			<Title style={{ textAlign: 'center' }} margin={MARGIN_TITLE} bold subtitle>
				Tell us about a little about yourself
			</Title>
			<OBSubText text='' />
			<Formik
				onSubmit={handleSubmit}
				autoComplete={'off'}
				validate={validateProviderInfo}
				initialValues={handleInitValue()}
				render={(formProps) => {
					console.log(formProps);
					return (
						<Form noValidate>
							<ColumnFlex
								alignCenter
								justifyCenter
								margin='10px 0 0 0'
								fullWidth
								fullHeight
							>
								<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
									<Field
										name='firstName'
										type='text'
										component={StyledTextInput}
										label='First Name'
									/>
								</FormItemsLength>
								<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
									<Field
										name='lastName'
										type='text'
										component={StyledTextInput}
										label='Last Name'
									/>
								</FormItemsLength>
								<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
									<Field
										component={StyledTextSelectInputV2}
										name='gender'
										label='Gender*'
										options={GENDERS}
									/>
								</FormItemsLength>
								<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
									<Field
										name='displayName'
										type='text'
										component={StyledTextInput}
										label='Display Name'
									/>
								</FormItemsLength>

								<OBFormButtonText
									backgroundColor={Colors.primary.primary}
									type='submit'
									valid={isValidOverride || formProps.isValid}
									text='Next'
								/>
							</ColumnFlex>
						</Form>
					);
				}}
			/>
			<BackText onClick={() => props.ChangeLocation(1)}>Back</BackText>
		</OBBasicCon>
	);
};

export default POB3ProviderInfo;
