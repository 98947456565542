import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import AnimationWrapper from './AnimationWrapper';
import OverlayWrapper from './OverlayWrapper';

import { device } from './styled/StyledMediaQuery';

/**
	This component is a wrapper basically combining AnimationWrapper & OverlayWrapper
*/

const ExitContainer = styled.div`
	position: absolute;
	left: 0;
	right: 0;
	top: -32px;
	cursor: pointer;
	width: 40px;
	height: 40px;

	z-index: 100;
	color: red;
	font-height: 60px;
	font-weight: 800;
	background-color: white;
	padding: 12px;
	border-radius: 50%;
	transition: all ease 0.3s;
	text-align: center;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}

	@media ${device.tablet} {
		left: -32px;
		top: -32px;
	}
`;

const BasicOverlayWrapper = (props) => {
	const {
		status,
		classNames,
		appear,
		mountOnEnter,
		unmountOnExit,
		timeout,
		handleExitClick,
		className,
		children,
		dontShowExit,
	} = props;

	return (
		<>
			<AnimationWrapper
				status={status}
				classNames='basic-overlay'
				appear={appear}
				mountOnEnter={mountOnEnter}
				unmountOnExit={unmountOnExit}
				timeout={timeout}
			>
				<OverlayWrapper className={className} handleExitClick={handleExitClick}>
					{!dontShowExit && (
						<ExitContainer onClick={() => handleExitClick()}>X</ExitContainer>
					)}

					{children}
				</OverlayWrapper>
			</AnimationWrapper>
		</>
	);
};

const TimeoutShape = PropTypes.shape({
	enter: PropTypes.number,
	exit: PropTypes.number,
});

BasicOverlayWrapper.defaultProps = {
	appear: false,
	mountOnEnter: true,
	unmountOnExit: true,
	dontShowExit: false,
	timeout: 1000,
};

BasicOverlayWrapper.propTypes = {
	/**If the children should animate when first loaded  */
	appear: PropTypes.bool,
	/** If the children should be mounted when the css status is toggled on*/
	mountOnEnter: PropTypes.bool,
	/** If the children should be unmounted when the css status is toggled off*/
	unmountOnExit: PropTypes.bool,
	/** Set the timeout for the exit and enter phase*/
	timeout: PropTypes.oneOfType([PropTypes.number, TimeoutShape]),
	/** Sets the status of in or not*/
	status: PropTypes.bool.isRequired,
	/** func to run when user clicks the background of the overlay */
	handleExitClick: PropTypes.func.isRequired,
	/** classname for haing custom css action for overlay*/
	className: PropTypes.string,
	/* Componet to wrap the overlay on*/
	children: PropTypes.element.isRequired,
};

export default BasicOverlayWrapper;
