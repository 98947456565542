import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import {
	StyledCreateJobPostButton,
	StyledBlueCheckMark,
	StyledInformationContainer,
	StyledJobPostTitle,
} from './styles';

const CreateJobPostButton = (props) => {
	const { goToCreateJobPostPage } = props;

	return (
		<StyledCreateJobPostButton onClick={goToCreateJobPostPage}>
			<StyledBlueCheckMark>
				<FontAwesomeIcon size='xs' icon={faPlus} />
			</StyledBlueCheckMark>

			<StyledInformationContainer>
				<StyledJobPostTitle>Create Job Post</StyledJobPostTitle>
			</StyledInformationContainer>
		</StyledCreateJobPostButton>
	);
};

export default CreateJobPostButton;
