import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledChatContainer = styled.div`
	display: flex;
	min-height: calc(100vh - 85px);
	background: ${colors.white};
	box-sizing: border-box;

	@media ${mediaQueries.tablet} {
		flex-direction: row;
		width: calc(100% + 50px);
		margin-left: -25px;
		margin-top: -25px;
		margin-bottom: -50px;
		padding: 0px;
	}
`;

export const StyledChatListContainer = styled.div`
	width: 100%;
	border-right: 1px solid ${colors.table.border};
	display: ${(props) => (props.$showChat ? 'none' : 'block')};

	@media ${mediaQueries.tablet} {
		width: ${(props) => (props.$chatId ? '33.311%' : '25%')};
		display: block;
	}
`;
