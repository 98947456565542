import styled from 'styled-components';

import Colors from 'colors';

export const StyledInformationCard = styled.div`
	word-wrap: break-word;

	border-left: 4px solid ${Colors.primary.new_primary};

	margin-bottom: 1.5rem;
	padding: 0 1rem;
`;

export const StyledInformationHeading = styled.h3`
	font-size: 1rem;
	font-weight: bold;
	color: ${Colors.primary.new_primary};

	margin-bottom: 0.75rem;
`;

export const StyledInformation = styled.p`
	font-size: 0.75rem;
	color: ${Colors.primary.new_primary};

	padding-bottom: 0.25rem;
`;
