import styled from 'styled-components';

import { device } from 'AppComp/styled/StyledMediaQuery';

export const StyledClientProfileContainer = styled.div`
	display: inline-flex;
	flex-wrap: wrap;
	text-align: left;

	max-width: 804px;

	margin: 2rem auto 0 auto;

	@media ${device.laptop} {
		> * {
			&:nth-child(even) {
				margin-right: 0px;
			}
		}
	}
`;
