const reverseParser = (data, filters) => {
	const updatedFilters = JSON.parse(JSON.stringify(filters));

	data.forEach((item) => {
		const traitId = item.id;
		const value = item.value ? item.value.trait_value.replace(/\"/g, '') : null;

		for (const key in updatedFilters) {
			const field = updatedFilters[key];

			if (field.type === 'drop-down') {
				field.options.default.forEach((option) => {
					if (option.traitId === traitId) {
						option.selected =
							value === 'null' || value === null ? !!JSON.parse(value) : !!value;

						if (option.value === 'custom') {
							field.customValue = value === 'null' ? '' : value;
						}
					}
				});
			} else if (field.type === 'text') {
				if (field.traitId === traitId) {
					field.customValue = value === 'null' || value === null ? '' : value;
				}
			} else if (field.type === 'tags') {
				field.options.forEach((option) => {
					if (option.traitId === traitId) {
						option.selected = value === 'true';
					}
				});
			}
		}
	});

	return updatedFilters;
};
export default reverseParser;
