import React, { useEffect, useState, createContext } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import { useFetchSingleJobPostQuery } from '../../api/queries/useFetchSingleJobPostQuery';

export const JobPostContext = createContext();

export const JobPostProvider = ({ children }) => {
	const [hasSetSchedule, setHasSetSchedule] = useState(false);
	const { jobPostId } = useParams();

	const {
		data: jobPost,
		isLoading: isLoadingJobPost,
		refetch: updateJobPost,
	} = useFetchSingleJobPostQuery(jobPostId);

	useEffect(() => {
		if (jobPost) {
			const hasFutureSchedule = jobPost.schedules.some((schedule) => {
				const scheduleStartDate = moment(schedule.start_date, 'YYYY-MM-DD');
				const scheduleEndDate = schedule.end_date
					? moment(schedule.end_date, 'YYYY-MM-DD')
					: null;
				const currentDate = moment();

				switch (schedule.repeat_frequency) {
					case 'one_time':
						return scheduleStartDate.isAfter(currentDate);

					case 'daily':
						return !scheduleEndDate || scheduleEndDate.isAfter(currentDate);

					case 'weekly':
						const daysOfWeek = schedule.days_of_week
							? schedule.days_of_week.split(',')
							: [];
						const dateRange = [];

						for (
							let date = scheduleStartDate.clone();
							scheduleEndDate ? date.isSameOrBefore(scheduleEndDate) : true;
							date.add(1, 'days')
						) {
							dateRange.push(date.isoWeekday());
						}

						return daysOfWeek.some((day) =>
							dateRange.includes(moment(day, 'ddd').isoWeekday())
						);

					case 'every_weekday':
						return (
							currentDate.isoWeekday() <= 5 &&
							(!scheduleEndDate || scheduleEndDate.isAfter(currentDate))
						);

					default:
						return false;
				}
			});

			setHasSetSchedule(hasFutureSchedule);
		}
	}, [jobPost]);

	const value = {
		jobPost,
		isLoadingJobPost,
		hasSetSchedule,
		updateJobPost,
	};

	return <JobPostContext.Provider value={value}>{children}</JobPostContext.Provider>;
};
