import React from 'react';

import { StyledMain, StyledWrapper } from './styles';

const Wrapper = (props) => {
	const { children } = props;

	return (
		<StyledMain>
			<StyledWrapper>{children}</StyledWrapper>
		</StyledMain>
	);
};

export default Wrapper;
