import { useEffect } from 'react';

function usePreventScroll(dependency) {
	useEffect(() => {
		if (dependency) document.body.style.overflow = 'hidden';
		if (!dependency) document.body.style.overflow = 'unset';
	}, [dependency]);
}

export default usePreventScroll;
