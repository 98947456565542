import React from 'react';

import { RowFlex } from 'basicStyles';

import Colors from 'colors';

import Text from 'AppComp/Text';

export const TabTile = (props) => {
	const { label, selectedTab, onClick, stats } = props;

	const active = label === selectedTab;

	return (
		<RowFlex
			style={{
				flex: 1,
				cursor: 'pointer',
				background: active ? Colors.primary.primary : 'none',
				borderRadius: '20px',
			}}
			onClick={() => onClick(label)}
			center
			padding='8px 0'
		>
			<Text
				fontWeight={active ? '700' : '500'}
				color={active ? Colors.fullWhite : Colors.primary.primary}
			>
				{label}
				<span
					style={{
						fontSize: '14px',
						marginLeft: '4px',
						marginBottom: '2px',
					}}
				>
					({stats})
				</span>
			</Text>
		</RowFlex>
	);
};
