import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { ColumnFlex, RowFlex } from 'basicStyles';

import Colors from 'colors';
import styled from 'styled-components';
import api from 'scripts/api';

import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import Title from 'AppComp/Title';

import { PriceRangeSelect } from './CreateJobViews';

import { TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG } from 'constants';

import { CORE_JOB_POST_MODEL } from 'reducers/graphqlModelTypes';

import CreateSchedule from './CreateSchedule';
import JobActivities from './JobActivities';
import MangeSchedule from './ManageSchedule';

// 6am utc yesterday
// get the javascirp date represenation of 8pc utc yesterday

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(8, 0, 0, 0);

// 12pm utc yesterday
const yesterdayNoon = new Date();
yesterdayNoon.setDate(yesterdayNoon.getDate() - 1);
yesterdayNoon.setHours(12, 0, 0, 0);

const JOB_DATE_TILE_TEMPLATE = [
	{
		start: new Date(yesterday),
		end: new Date(yesterdayNoon),
		title: 'Job Opportunity Date',
		allDay: false,
		resource: {
			type: 'job_opportunity_tile',
		},
	},
];

const CreateJobHandler = (props) => {
	console.log('createJobhandler', props);
	const [jobPosts, setJobPosts] = useState([]);

	const [step, setStep] = useState(0);
	const [range, setRange] = useState([0, 0]);
	const [jobActivities, setJobActivities] = useState(null);

	const handleNextView = (viewId, params) => {
		// id: 1, params: { range: [0, 0] }
		if (viewId === 0) {
			setRange(params);
			handleCreateJobPost(params);
		}
	};

	const handleRenderView = () => {
		if (step === 0) return <PriceRangeSelect handleNextView={handleNextView} />;

		//if (step === 2) return <MangeSchedule handleNextView={handleNextView} />;
	};

	const handleCreateJobPost = async (params) => {
		console.log('handleCreateJobPost', range, jobActivities, props.carePlan);
		try {
			// create a mutation
			const { id } = props.carePlan;
			const createJobRes = await api.graph({
				query: `mutation {
					createJobPost(care_plan_id: ${id}, budget_high: ${params[1]}, budget_low: ${params[0]}) {
						${CORE_JOB_POST_MODEL}
					}
				}`,
			});

			if (createJobRes.createJobPost) {
				console.log('createJobRes', createJobRes);

				toast.success('Job post created', TOAST_SUCCESS_CONFIG);
				props.history.push(`/jobpost/${createJobRes.createJobPost.id}`);
			}
			// go to this jobview
		} catch (err) {
			toast.error('Error creating job post', TOAST_ERROR_CONFIG);

			console.error(err);
		}
	};

	return (
		<ColumnFlex
			fullWidth
			padding='16px'
			style={{
				backgroundColor: Colors.theme.primary_background,
				minHeight: 'calc(100vh - 64px)',
			}}
			center
		>
			{handleRenderView()}
		</ColumnFlex>
	);
};

const mapStateToProps = ({ patient }) => {
	return {
		carePlan: patient.carePlan,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateJobHandler);
