import styled from 'styled-components';
import React from 'react';

export const Colors = {
	primary: {
		primary: '#2b69a3',
		secondary: '#3882cc',
		accent: '#619cd6',
		background: '#89b5e1',
		text: '#b1cfec',
		border: '#dae8f6',
		highlight: '#84c4ff',
	},
	secondary: {
		primary: '#1e857b',
		secondary: '#25b1a7',
		accent: '#36d3c8',
		background: '#60dcd4',
		text: '#8ae5df',
		border: '#dae8f6',
		highlight: '#b7f0eb',
	},
	borderColor: '#2b69a64d',
	gray: '#969696',
	white: '#ffffff',
	pageBackground: '#edf6fa',
	warning: '#fa4362',
	forms: {
		fontColor: '#363636',
		formInputBorderColor: '#81b0df',
		placeholderColor: '#dddedf',
	},
	table: {
		header: '#f9fafb',
		border: '#e5e7eb',
		alternative: '#edf6fb',
		text: '#6b7280',
	},
};
const sizes = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopL: '1440px',
	desktop: '1860px',
	desktopL: '2560px',
};

const mediaQueries = {
	mobileS: `(min-width: ${sizes.mobileS})`,
	mobileM: `(min-width: ${sizes.mobileM})`,
	mobileL: `(min-width: ${sizes.mobileL})`,
	tablet: `(min-width: ${sizes.tablet})`,
	laptop: `(min-width: ${sizes.laptop})`,
	laptopL: `(min-width: ${sizes.laptopL})`,
	desktop: `(min-width: ${sizes.desktop})`,
	desktopL: `(min-width: ${sizes.desktopL})`,
};

export const ManageSectionCon = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 40px;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 20px;
`;

export const ManageSectionContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	flex: 1;
`;

export const StyledInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5rem;
	margin-bottom: 20px;
`;

export const InputContainer = (props) => {
	const { children } = props;

	return <StyledInputContainer>{children}</StyledInputContainer>;
};

export const StyledInput = styled.input`
	border: 1px solid #81b0df;
	border-width: 1px;
	box-shadow: none;
	border-radius: 4px;
	color: ${Colors.forms.fontColor};
	line-height: 40px;
	padding: 0 12px;
	width: 100%;
	min-height: 42px;

	font-size: 15px;

	::placeholder {
		color: ${Colors.forms.placeholderColor};
		opacity: 1;

		font-size: 15px;
	}

	:focus {
		outline: thin dotted currentColor;
	}
`;

export const Input = (props) => {
	return <StyledInput {...props} />;
};

export const StyledFormWrapper = styled.form`
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;

	text-align: left;
`;

export const FormWrapper = (props) => {
	const { children, ...restProps } = props;

	return <StyledFormWrapper {...restProps}>{children}</StyledFormWrapper>;
};

export const StyledErrorMessage = styled.p`
	word-wrap: break-word;
	color: ${Colors.warning};
	line-height: 1.7;
	font-size: 12px;
	height: 20px;
`;

export const ErrorMessage = ({ children }) => {
	return <StyledErrorMessage>{children}</StyledErrorMessage>;
};
