import React from 'react';

import { StyledTransparentButton } from './styles';

const TransparentButton = (props) => {
	const { children, ...restProps } = props;

	return <StyledTransparentButton {...restProps}>{children}</StyledTransparentButton>;
};

export default TransparentButton;
