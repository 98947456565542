import React, { Component, Fragment } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Formik, Form, Field } from 'formik';
import PropTypes from 'prop-types';

import { ColumnFlex } from 'basicStyles';
import Colors from 'colors';
import { FORM_COMPONENTS } from 'constants';

import FormButton from './FormButton';
import Text from './Text';
import Title from './Title';
import StyledTextInput from './styled/StyledTextInput';
import { device } from './styled/StyledMediaQuery';

const LoginUserFormCon = styled.div`
	box-shadow: 0 2px 4px 0 rgba(153, 153, 153, 0.5);
	border-radius: 50px;
	width: 85vw;
	max-width: 360px;
	padding: 16px;
	background-color: #ffffff;

	@media ${device.tablet} {
		width: 460px;
		padding: 32px;
		max-width: 560px;
	}
	@media ${device.laptopL} {
		width: 500px;
		padding: 48px;
		max-width: 560px;
	}
`;

const ThemeTitle = styled(Title)`
	font-size: 24px;
	line-height: 26px;
`;

const CustomFormButton = styled(FormButton)`
	border-radius: 50px;
	${({ valid }) =>
		!valid &&
		`
		background-color: white;
		border: 1px solid ${Colors.greenLightTeal};
		p{
			color: ${Colors.greenLightTeal};
		}
	`}

	${({ valid }) =>
		valid &&
		`
		background-color: ${Colors.greenLightTeal};
		color: white;
		p{
			color: white;
		}
	`}
`;

const ThemeText = styled(Text)`
	color: auto;
	transition: color ease 0.3s;
`;

const OnboardFormView = (props) => {
	const {
		formProps: { handleSubmit, isValid },
		theme,
		error,
	} = props;
	return (
		<LoginUserFormCon>
			<form noValidate onSubmit={handleSubmit}>
				<ThemeTitle
					bold
					marginBottom='16px'
					margin='16px 0 32px 0'
					nubtitle
					color={Colors.greenLightTeal}
				>
					{theme.title}
				</ThemeTitle>
				{theme.form.map((d, i) => {
					const ComponentType = d.fieldType
						? FORM_COMPONENTS[d.fieldType]
						: StyledTextInput;
					return (
						<Field
							name={d.name}
							label={d.label}
							component={ComponentType}
							type={d.type}
							key={'o-f-v-' + d.id}
						/>
					);
				})}
				<ColumnFlex fullWidth>
					<CustomFormButton valid={isValid} width='auto' height='45px' type='submit'>
						<ThemeText largeText fontWeight='500'>
							{theme.buttonText}
						</ThemeText>
					</CustomFormButton>
					{error && (
						<Text bold color={Colors.heartRed} margin='16px 0 0 0'>
							{error}
						</Text>
					)}
				</ColumnFlex>
			</form>
		</LoginUserFormCon>
	);
};

const THEME_FORM_PROPTYPE = PropTypes.shape({
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
});

const THEME_PROPTYPE = PropTypes.shape({
	title: PropTypes.string.isRequired,
	form: PropTypes.arrayOf(THEME_FORM_PROPTYPE),
	buttonText: PropTypes.string.isRequired,
	action: PropTypes.string.isRequired,
});

OnboardFormView.propTypes = {
	formProps: PropTypes.object.isRequired,
	theme: THEME_PROPTYPE.isRequired,
	error: PropTypes.string.isRequired,
};

export default OnboardFormView;
