import styled from 'styled-components';

import { colors } from '../../../corev2/utils';

export const StyledStepItem = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	text-align: center;
	font-size: 14px;

	color: ${colors.primary.primary};
`;
