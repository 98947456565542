import React from 'react';

import { StyledInformationCard, StyledInformationHeading, StyledInformation } from './styles';

const InformationCard = ({ heading, information }) => {
	return (
		<StyledInformationCard>
			<StyledInformationHeading>{heading}</StyledInformationHeading>
			<StyledInformation>{information}</StyledInformation>
		</StyledInformationCard>
	);
};

export default InformationCard;
