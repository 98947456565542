const functions = {
	capitalizeFirstLetter: (string) => {
		if (string && string.length) {
			return string.charAt(0).toUpperCase() + string.slice(1);
		}
	},
	formatTime: (date) => {
		const hours = String(date.getHours()).padStart(2, '0');
		const minutes = String(date.getMinutes()).padStart(2, '0');
		return `${hours}:${minutes}`;
	},
};

export default functions;
