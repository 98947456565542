import { useMutation } from '@tanstack/react-query';

import { DOCUMENT_MODEL } from '../../../core/api/models';

import api from '../../../core/utility/graphQL';

const careProUploadDocument = async ({ documentUrls, requiredDocumentId, expirationDate }) => {
	try {
		const query = `mutation {
					careProUploadDocument(
						document_urls: ${api.graphStringify(documentUrls)}
						required_document_id: ${requiredDocumentId}
						${expirationDate ? `expiration_date: "${expirationDate}"` : ''}
					) {
						${DOCUMENT_MODEL}
					}
				}`;

		const data = await api.graph({ query });

		return data.careProUploadDocument;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useCareProUploadDocumentMutation = () => {
	return useMutation({
		mutationKey: ['careProUploadDocument'],
		mutationFn: (values) => {
			return careProUploadDocument(values);
		},
	});
};
