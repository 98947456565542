import React, { useState } from 'react';
import styled from 'styled-components';
import Colors from 'colors';
import { isMobile } from 'react-device-detect';
import useWindowSize from 'scripts/useWindowSize';

import { ColumnFlex, RowFlex, DropShadow } from 'basicStyles';

import { device } from '../../styled/StyledMediaQuery';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import FormButton from 'AppComp/FormButton';
import Icon from 'AppComp/Icon';
import { WelcomeButtonGreen, WelcomeButton } from './WelcomePageViews';
import PaymentError from '../PaymentError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCalendarAlt,
	faIdCardAlt,
	faListAlt,
	faPencilAlt,
	faUniversalAccess,
	faCalendarWeek,
	faBars,
	faInfo,
	faEllipsisV,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import UserOnboardSteps from '../UserOnboardSteps';

import { PROVIDER_NAV_WIDTH } from 'constants';
export const NAV_HEIGHT = 80;

export const LandingCon = styled.div`
	position: relative;
	width: 100%;
	height: auto;
	z-index: ${({ zIndex }) => (zIndex ? zIndex : '101')};

	background-color: white;
`;

export const Con = styled.div`
	height: autl;
	width: 100vw;
	background-color: #ffffff;
	position: relative;
`;

export const LandingImage = styled.div`
	width: 200px;
	height: 200px;
	background-image: url('https://find-your-independents.s3.amazonaws.com/images/landingImageV2.png');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	@media ${device.tablet} {
		width: 400px;
	}
	@media ${device.laptop} {
		width: 400px;
		height: 400px;
		max-width: 400px;
	}
	@media ${device.laptopL} {
		width: 600px;
		height: 600px;
		max-width: 600px;
	}
	@media ${device.desktop} {
		width: 600px;
		height: 600px;
		max-width: 600px;
	}
	@media ${device.desktopL} {
		width: 600px;
		height: 600px;
		max-width: 600px;
	}
`;

export const LandingTitle = styled(Title)`
	font-size: 40px;
	line-height: 56px;
	text-align: center;
	word-break: break-word;
	word-wrap: break-word;
	@media ${device.tablet} {
		font-size: 54px;
		line-height: 60px;
	}
	@media ${device.laptop} {
		font-size: 54px;
		line-height: 60px;
	}
	@media ${device.laptopL} {
		font-size: 64px;
		line-height: 68px;
	}
	@media ${device.desktop} {
		font-size: 72px;
		line-height: 80px;
	}
	@media ${device.desktopL} {
		font-size: 72px;
		line-height: 80px;
	}
`;
const Section = styled.div`
	display: flex;
	width: 100%;
	height: inherit;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

const LandingInnerSection = styled.div`
	width: 100%;

	@media ${device.mobileL} {
		width: calc(98vw - 30px);
	}

	@media ${device.tablet} {
		max-width: 800px;
	}
	@media ${device.laptop} {
		max-width: calc(98vw - ${PROVIDER_NAV_WIDTH});
	}
	@media ${device.laptopL} {
		max-width: calc(98vw - ${PROVIDER_NAV_WIDTH});
	}
	@media ${device.desktop} {
		max-width: calc(98vw - ${PROVIDER_NAV_WIDTH});
	}
	@media ${device.desktopL} {
		max-width: calc(98vw - ${PROVIDER_NAV_WIDTH});
	}
`;

export const LandingContentSec = (props) => {
	return (
		<Section>
			<div
				style={{
					marginTop: '10vh',
				}}
			/>
			<LandingInnerSection>{props.children}</LandingInnerSection>
			<div
				style={{
					marginBottom: '10vh',
				}}
			/>
		</Section>
	);
};

export const LandingContentRow = styled(RowFlex)`
	flex-direction: column;
	@media (max-width: 768px) {
		max-width: 90vw;
		margin: auto;
	}

	@media ${device.laptopL} {
		flex-direction: row;
	}
`;
export const LandingSection = ({ history, patient }) => {
	const handleGetMatched = () => {
		// if (patient) {
		// 	history.push('/search/1');
		// } else {
		// 	history.push('/welcome');
		// }

		if (patient.carePlan) {
			history.push('/welcome');
		} else {
			history.push('/careplan');
		}
	};
	return (
		<LandingCon zIndex={10}>
			<LandingContentSec>
				<LandingContentRow margin='1vh 0 8vh 0' fullWidth justifyStart>
					<ColumnFlex alignCenter style={{ flex: 1 }}>
						<LandingImage />
					</ColumnFlex>
					<ColumnFlex alignCenter justifyCenter style={{ flex: 1 }}>
						<ColumnFlex
							padding='32px 0 0 0'
							style={{ justifyContent: 'flex-start' }}
							alignCenter
						>
							<LandingTitle
								style={{
									fontWeight: '500',
								}}
							>
								Take Back Your
							</LandingTitle>
							<LandingTitle fontWeight='500'>Independence</LandingTitle>
							<Text
								margin='32px 0 0 0'
								maxWidth='540px'
								largeText
								style={{
									fontWeight: '300',
									textAlign: 'center',
								}}
							>
								FYI was created with the goal of providing quality senior care
								services to help our clients remain at home.
							</Text>
							<BlueCircleButton
								style={{
									marginTop: '24px',
								}}
								onClick={() => handleGetMatched()}
							>
								<Text fontWeight='300' curosr color={'#fff'} largeText>
									Find Care
								</Text>
							</BlueCircleButton>
							<RowFlex margin='16px 0 0 0' justifyCenter p fullWidth>
								<ItemsRow history={history} />
							</RowFlex>
						</ColumnFlex>
					</ColumnFlex>
				</LandingContentRow>
			</LandingContentSec>
		</LandingCon>
	);
};

const ContentSec = (props) => {
	return (
		<Section>
			<InnerSection>{props.children}</InnerSection>
		</Section>
	);
};
const InnerSection = styled.div`
	width: 98vw;

	@media ${device.tablet} {
		max-width: 400px;
	}
	@media ${device.laptop} {
		max-width: 800px;
	}
	@media ${device.laptopL} {
		max-width: 1040px;
	}
	@media ${device.desktop} {
		max-width: 1200px;
	}
	@media ${device.desktopL} {
		max-width: 1400px;
	}
`;

const TilesCon = styled.div``;

const Tile = styled.div`
	width: 190px;
	height: 100px;
	cursor: pointer;

	pointer: cursor;
	border-radius: 4px;
	background-color: black;
	margin: 12px 24px;
	transition: all ease 0.3s;
	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}
`;

export const Tiles = ({ history }) => {
	return (
		<RowFlex margin={'auto'} padding='60px 0' wrap alignCenter justifyCenter>
			<OBButtonText
				onClick={() => history.push('/welcome')}
				backgroundColor={Colors.primary.primary}
				text='Get Started!'
				valid
			/>
			<div style={{ margin: '8px' }} />
			<OBButtonText
				onClick={() => history.push('/carepro')}
				backgroundColor={Colors.secondary.green}
				text='Provider Login'
				valid
			/>
			<div style={{ margin: '8px' }} />
		</RowFlex>
	);
};

export const HowItWorks = (props) => {
	return (
		<Con>
			<ContentSec>
				<RowFlex margin='100px 0 0 0' fullWidth justifyCenter>
					<ColumnFlex alignCenter>
						<Title margin='0 0 40px 0' style={{ fontSize: '54px', lineHeight: '60px' }}>
							How It Works
						</Title>
						<StepTile />
						<StepTile />
						<StepTile />
					</ColumnFlex>
				</RowFlex>
			</ContentSec>
		</Con>
	);
};

const StepTitle = styled(Title)`
	font-size: 40px;
	line-height: 50px;
	@media ${device.tablet} {
		font-size: 40px;
		line-height: 50px;
	}
	@media ${device.laptop} {
		font-size: 48px;
		line-height: 58px;
	}
	@media ${device.laptopL} {
		font-size: 48px;
		line-height: 58px;
	}
	@media ${device.desktop} {
		font-size: 64px;
		line-height: 68px;
	}
	@media ${device.desktopL} {
		font-size: 64px;
		line-height: 68px;
	}
`;

const StepTile = (props) => {
	return (
		<ColumnFlex
			margin='32px 0'
			justifyCenter
			alignCenter
			style={{ maxWidth: '800px', width: '90vw' }}
		>
			<StepTitle style={{ fontSize: '48px', lineHeight: '50px' }}>
				1. Create Your Custom Care Plan
			</StepTitle>

			<Text largeText margin='16px 0 12px 0'>
				We can provide you with a number of different services. Our team will develop a plan
				with you and your loved ones that addresses your daily routine.
			</Text>
			<div style={{ width: '100%', height: '2px', backgroundColor: 'black' }} />
		</ColumnFlex>
	);
};

export const HeartTwo = styled.div`
	background-image: url('https://find-your-independents.s3.amazonaws.com/Heart2.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: top left;
	position: absolute;
	left: 0px;
	bottom: 0px;
	width: 500px;
	height: 300px;
	z-index: 1;
	top: 6px;
	-left: 40px;

	@media ${device.laptop} {
		width: 500px;
		height: 694px;
	}
`;

export const InfoSection = (props) => {
	return (
		<Con>
			<LandingContentSec>
				<RowFlex
					margin='80px 0 0 0'
					fullWidth
					style={{ zIndex: '4', position: 'relative', justifyContent: 'center' }}
					alignCenter
					columnMobile
				>
					<div
						style={{
							height: '400px',
							width: '550px',
							backgroundSize: 'contain',
							backgroundRepeat: 'no-repeat',
							backgroundImage:
								'url(https://find-your-independents.s3.amazonaws.com/images/profileTypes.png)',
						}}
					/>
					<ColumnFlex margin='16px' style={{ alignItems: 'flex-end' }}>
						<Title
							margin='0 0 24px 0'
							bold
							color={Colors.secondary.blue}
							style={{
								textAlign: 'right',
								maxWidth: '340px',
								fontSize: '48px',
								lineHeight: '50px',
							}}
						>
							Focused On Flexibility
						</Title>
						<Text
							maxWidth='420px'
							largeText
							style={{ textAlign: 'right', lineHeight: '40px' }}
							color='#424242'
							margin='0 0 24px 0'
						>
							We are proud of our team and its passion to provide quality services.
							With very low turnover of our staff relative to industry standards, it
							is clear that they also enjoy working with us. As a licensed home health
							agency, we have Registered Nurses.
						</Text>

						<OBButtonText
							text='Fill Out Care Plan'
							onClick={() => history.push('/careplan')}
							valid
						/>
					</ColumnFlex>
				</RowFlex>
			</LandingContentSec>
		</Con>
	);
};

export const HeartOne = styled.div`
	background-image: url('https://find-your-independents.s3.amazonaws.com/Heart1.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: top left;
	position: absolute;
	right: 0px;
	bottom: 0px;
	width: 500px;
	height: 300px;
	z-index: 1;
	top: 6px;
	-left: 40px;

	@media ${device.laptop} {
		width: 500px;
		height: 694px;
	}

	@media ${device.laptop} {
		width: 500px;
		height: 694px;
	}
`;

export const InfoSection2 = (props) => {
	return (
		<Con>
			<LandingContentSec>
				<RowFlex
					margin='80px 0 100px 0'
					fullWidth
					style={{ zIndex: '4', position: 'relative', justifyContent: 'center' }}
					alignCenter
					columnMobile
				>
					<ColumnFlex margin='16px' style={{ alignItems: 'flex-start' }}>
						<Title
							margin='0 0 24px 0'
							bold
							color={Colors.secondary.blue}
							style={{
								textAlign: 'left',
								maxWidth: '340px',
								fontSize: '48px',
								lineHeight: '50px',
							}}
						>
							Focused On Flexibility
						</Title>
						<Text
							maxWidth='420px'
							largeText
							style={{ textAlign: 'left', lineHeight: '40px' }}
							color='#424242'
							margin='0 0 24px 0'
						>
							We are proud of our team and its passion to provide quality services.
							With very low turnover of our staff relative to industry standards, it
							is clear that they also enjoy working with us. As a licensed home health
							agency, we have Registered Nurses.
						</Text>
						<OBButtonText
							text='View Caregivers'
							onClick={() => history.push('/careplan')}
							valid
						/>
					</ColumnFlex>
					<div
						style={{
							height: '400px',
							width: '550px',
							backgroundColor: '#FFFFFF',

							backgroundSize: 'contain',
							backgroundRepeat: 'no-repeat',
							backgroundImage:
								'url(	https://find-your-independents.s3.amazonaws.com/images/providersDisplay.png)',
						}}
					/>
				</RowFlex>
			</LandingContentSec>
		</Con>
	);
};

export const LandingContentSecNav = (props) => {
	return (
		<Section>
			<LandingInnerSection style={{ margin: '20px, 0 10px 0' }}>
				{props.children}
			</LandingInnerSection>
		</Section>
	);
};

export const NavCon = styled.div`
	height: 120px;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

export const GreenCircleButton = styled.div`
	background-color: ${Colors.secondary.green};
	border-radius: 50px;
	padding: 8px 32px;
	cursor: pointer;

	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}
`;

export const TransparentButton = styled.button`
	background: none;
	border: none;
	font-size: 32px;
	color: ${Colors.primary.primary};
	cursor: pointer;
	padding: 5px 5px;
	min-width: 55px;
	padding: 0;

	@media ${device.tablet} {
		padding: 12px 32px;
	}
`;

export const BlueBorderCircleButton = styled.div`
	background-color: ${Colors.primary.white};
	border: 1px solid ${Colors.primary.primary};
	border-radius: 50px;
	font-size: 32px;
	color: ${Colors.primary.primary};
	cursor: pointer;
	padding: 5px 5px;

	@media ${device.mobileL} {
		padding: 12px 42px;
	}
`;

export const BlueCircleButton = styled.div`
	background-color: ${Colors.primary.primary};
	border-radius: 50px;
	font-size: 32px;
	cursor: pointer;
	padding: 5px 5px;

	@media ${device.mobileL} {
		padding: 12px 42px;
	}

	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}
`;

export const WhiteCircleButton = styled.div`
	background-color: ${Colors.fullWhite};
	border-radius: 50px;
	padding: 8px 32px;
	cursor: pointer;
	border: 1px solid ${Colors.primary.primary};
	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}
`;

export const TealCircleButton = styled(WhiteCircleButton)`
	background-color: ${Colors.greenTeal};
`;

export const RedCircleButton = styled(WhiteCircleButton)`
	background-color: ${Colors.red};
`;
const logoURl = 'https://find-your-independents.s3.amazonaws.com/images/FYIlogo.png';

export const LandingNavigation = (props) => {
	const {
		savedPaymentMethods,

		patient,
		carePlan,
		toggleOnboardOverlay,
		showNavHandler,
		fetchingClientSession,
	} = props;

	const { width } = useWindowSize();

	const { status, attempts } = fetchingClientSession;

	const history = props.history;

	const [showFailedPayment, setShowFailedPayment] = useState(false);
	const [showCollectPaymentMethod, setShowCollectPaymentMethod] = useState(false);
	const [showCOllectCardForCarePlanMatch, setShowCOllectCardForCarePlanMatch] = useState(false);

	const handlePaymnentErrorClick = () => {
		toast(
			({ closeToast }) => <PaymentError />,

			{
				autoClose: false,
				hideProgressBar: true,
				style: { padding: '2px' },
				draggable: false,
				closeOnClick: false,
				closeButton: true,
				position: 'top-center',
				containerId: 'LargeToast',
				toastId: 'newGeneric',
			}
		);
	};

	const onWelcomePage = history.location.pathname.includes('welcome') ? true : false;

	const loadingSessionStatus = status === false && attempts != 0;
	const showTopNavItems = patient && carePlan && carePlan.completed_platform_onboard;

	const shouldShowNav = patient !== null && loadingSessionStatus && showTopNavItems;
	// we should only show the top nav items after the user has completed the platform onboard and is logged in
	return (
		<LandingCon>
			<LandingContentSecNav>
				<NavCon>
					<img
						src={logoURl}
						onClick={() => history.push('/')}
						style={{
							width: width < 768 ? '100px' : '140px',
							height: '120px',
							cursor: 'pointer',
						}}
					/>
					<RowFlex alignCenter gap='20px'>
						{shouldShowNav && (
							<TransparentButton onClick={() => history.push('/carePlan')}>
								<Text
									fontWeight='300'
									curosr
									color={Colors.primary.primary}
									midLarge
								>
									Care Plan
								</Text>
							</TransparentButton>
						)}
						{shouldShowNav && (
							<TransparentButton onClick={() => history.push('/chat')}>
								<Text
									fontWeight='300'
									curosr
									color={Colors.primary.primary}
									midLarge
								>
									Chat
								</Text>
							</TransparentButton>
						)}
						{shouldShowNav && (
							<TransparentButton onClick={() => history.push('/jobs')}>
								<Text
									fontWeight='300'
									curosr
									color={Colors.primary.primary}
									midLarge
								>
									Care Jobs
								</Text>
							</TransparentButton>
						)}

						{showFailedPayment && (
							<RedCircleButton
								onClick={() => {
									handlePaymnentErrorClick();
								}}
							>
								<Text
									fontWeight='300'
									curosr
									color={Colors.secondary.white}
									midLarge
								>
									Payment Issue
								</Text>
							</RedCircleButton>
						)}

						{shouldShowNav ? (
							<TransparentButton onClick={() => props.showNavHandler()}>
								<div style={{ display: 'flex' }}>
									<FontAwesomeIcon icon={faEllipsisV} />
									<FontAwesomeIcon icon={faEllipsisV} />
									<FontAwesomeIcon icon={faEllipsisV} />
								</div>
							</TransparentButton>
						) : (
							<>
								{!onWelcomePage ? (
									<>
										<GreenCircleButton
											onClick={() => toggleOnboardOverlay(true)}
										>
											<Text
												curosr
												color={Colors.fullWhite}
												midLarge
												fontWeight='300'
											>
												Login
											</Text>
										</GreenCircleButton>
									</>
								) : (
									<Text
										curosr
										color={Colors.primary.primary}
										midLarge
										fontWeight='300'
									>
										Onboarding
									</Text>
								)}
							</>
						)}
					</RowFlex>
				</NavCon>
			</LandingContentSecNav>
		</LandingCon>
	);
};

const OBFormButton = styled(FormButton)`
	width: auto;
	padding: 0 32px;
	height: 80px;
	border-radius: 50px;

	${({ valid, backgroundColor }) =>
		!valid &&
		`
		background-color: white;
		border: 1px solid ${backgroundColor || Colors.greenLightTeal};
		color: ${backgroundColor || Colors.greenLightTeal};

	`}

	${({ valid, backgroundColor }) =>
		valid &&
		`
		background-color: ${backgroundColor || Colors.greenLightTeal};
		color: white;
	`}
`;

export const OBButtonText = (props) => {
	const { backgroundColor, valid, text, onClick } = props;

	return (
		<OBFormButton
			onClick={onClick}
			margin='0 0 20px 0'
			backgroundColor={backgroundColor}
			valid={valid}
		>
			<Title trititle fontWeight='300' color={valid ? Colors.fullWhite : Colors.darkerGray}>
				{text}
			</Title>
		</OBFormButton>
	);
};

const ItemRowCon = styled.div`
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: auto;
	flex-wrap: wrap;
`;

export const ItemColCon2 = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 8px;
	width: 220px;
`;

const ItemColCon = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 16px;
	margin: auto;
	padding: 32px 0;
	width: 100%;
`;
export const ItemsRow = ({ history }) => {
	return (
		<ItemColCon>
			<ColumnFlex center>
				<Text largeText>Or </Text>
				<Text fontWeight='300' largeText>
					Explore our Platform
				</Text>
			</ColumnFlex>

			<RowFlex gap='20px' wrap center>
				<ItemsTile
					icon={faIdCardAlt}
					title='Create Care Job '
					route='/careplan'
					history={history}
				/>

				<ItemsTile
					icon={faListAlt}
					title='View CarePros'
					route='/careplan/'
					history={history}
				/>
			</RowFlex>
		</ItemColCon>
	);
};

/* 
	idea is to have a icon that pop open the menu for easy use
*/
// create component that animates

const ItemCon = styled(ColumnFlex)`
	flex: 1;
	background-color: white;
	border-radius: 12px;
	cursor: pointer;
	min-width: 105px;
	width: 220px;
	padding: 16px 24px;
	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}
`;

const MenuItem = styled(ItemCon)`
	border-radius: 50%;

	${DropShadow(3)}

	height: 50px;
	width: 50px;

	@media ${device.laptop} {
		height: 80px;
		width: 80px;
	}
`;

export const ItemsTile = ({ icon, title, route, history, closeToast }) => {
	const handleClick = () => {
		if (closeToast) {
			closeToast();
		}
		history.push(route);
	};
	return (
		<ItemCon margin='10px 0px 0px 0px' center onClick={() => handleClick()}>
			<Icon color={Colors.secondary.blue} icon={icon} size='2x' />

			<Text
				mediumText
				margin='10px 0 0 0'
				textAlign='center'
				style={{
					textAlign: 'center',
				}}
				color={Colors.secondary.blue}
				fontWeight='300'
			>
				{title}
			</Text>
		</ItemCon>
	);
};

export const MenuTile = ({ icon, title, toggleMenu }) => {
	const sizes = isMobile ? {} : {};
	return (
		<MenuItem margin='10px 0px 0px 0px' center onClick={() => toggleMenu()}>
			<Icon color={Colors.secondary.blue} icon={icon} size={isMobile ? '1x' : '2x'} />
			{/* {!isMobile && (
				<Text
					mediumText
					margin='10px 0 0 0'
					textAlign='center'
					style={{
						textAlign: 'center',
						maxWidth: '80px',
					}}
					color={Colors.secondary.blue}
				>
					{title}
				</Text>
			)} */}
		</MenuItem>
	);
};
