import React, { useState } from 'react';

import {
	OnboardFormContainer,
	OnboardTitle,
	OnboardSubText,
	OnboardButton,
	OnboardFormButton,
	FormItemsLength,
} from '../CareProOnboardViews';
import { ColumnFlex } from 'basicStyles';

import { Formik, Form, Field } from 'formik';
import StyledTextInput, { StrongPasswordTextInput } from 'AppComp/styled/StyledTextInput';
import Colors from 'colors';

import { validatePw } from './validate';
import Title from 'AppComp/Title';

import api from 'scripts/api';
import { toast } from 'react-toastify';

const AccountInfo = (props) => {
	const { handleDataUpdate, careProAccountInfo } = props;

	const [error, setError] = useState(null);

	const [loading, setLoading] = useState(false);

	const handleSubmit = (values, form) => {
		// if we made it here we can assume the validation is correct
		//so we must clean the value before sending it to teh backend
		// remove all non numbers
		values.phone_number = values.phone_number.replace(/\D/g, '');

		// check if number length is 11 - if so remove teh first number to make it 1
		if (values.phone_number.length === 11) {
			values.phone_number = values.phone_number.slice(1);
		}

		const updateData = {
			email: values.email,
			password: values.password,
			phone_number: values.phone_number,
		};

		//handleDataUpdate(updateData, 4);

		handleSendCareProPhoneVerify(updateData);
	};

	const handleSendCareProPhoneVerify = async (careProAccountInfoData) => {
		const phoneNumber = careProAccountInfoData.phone_number;
		const email = careProAccountInfoData.email;

		console.log('email', email);
		console.log('phoneNumber', phoneNumber);
		try {
			setLoading(true);
			setError(null);
			const verifyPhone = await api.graph({
				query: `mutation {
					verifyProviderPhone(phone_number: "${phoneNumber}", email: "${email}") 
				}`,
			});

			if (
				verifyPhone.verifyProviderPhone &&
				verifyPhone.verifyProviderPhone === 'Confirmation Code Sent'
			) {
				toast.success('Phone verification code sent!');

				handleDataUpdate(careProAccountInfoData, 4);
			} else {
				toast.error('Phone verification code not sent!');
			}
			setLoading(false);
		} catch (err) {
			setLoading(false);

			if (Array.isArray(err)) {
				setError(err[0].message);
			} else {
				setError('Something went wrong');
			}
		}
	};

	const handleInitValue = () => {
		const init = {
			email: careProAccountInfo.email,
			password: careProAccountInfo.password,
			phone_number: careProAccountInfo.phone_number,
		};

		return init;
	};

	return (
		<OnboardFormContainer
			style={{
				width: 'fit-content',
			}}
			center
		>
			<OnboardTitle> One Step Closer</OnboardTitle>
			<Formik
				onSubmit={handleSubmit}
				validate={validatePw}
				initialValues={handleInitValue()}
				render={(formProps) => {
					console.log(formProps);
					return (
						<Form noValidate>
							<ColumnFlex
								margin='40px 0 0 0'
								alignCenter
								justifyCenter
								fullWidth
								fullHeight
							>
								<FormItemsLength>
									<Field
										name='email'
										type='text'
										component={StyledTextInput}
										label='Email'
									/>
								</FormItemsLength>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'items-start',
										justifyContent: 'flex-start',
										margin: '20px 0 0 0',
										position: 'relative',
									}}
								>
									<FormItemsLength margin='20px 0 0 0'>
										<Field
											component={StyledTextInput}
											name='phone_number'
											label='Phone Number*'
											type='tel'
										/>
									</FormItemsLength>
								</div>

								<FormItemsLength margin='30px 0 0 0'>
									<Field
										component={StrongPasswordTextInput}
										name='password'
										label='Password*'
										type='password'
									/>
								</FormItemsLength>
								<FormItemsLength margin='20px 0 0 0'>
									<Field
										component={StyledTextInput}
										name='confirm_password'
										label='Confirm Password*'
										type='password'
									/>
								</FormItemsLength>
								{error && (
									<OnboardSubText
										style={{
											color: 'red',
											margin: '20px 0',
										}}
										error
									>
										{error}
									</OnboardSubText>
								)}
								{!loading && (
									<OnboardFormButton
										type={'submit'}
										valid={formProps.isValid}
										text='Next'
									>
										Next
									</OnboardFormButton>
								)}
							</ColumnFlex>
						</Form>
					);
				}}
			/>
		</OnboardFormContainer>
	);
};

export default AccountInfo;
