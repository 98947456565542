import React, { Component, Fragment } from 'react';
import is from 'styled-is';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';

import { device } from './StyledMediaQuery';

import Colors from '../../constants/colors';

export const FlexMixin = (direction = 'column') => {
	return `
        display: flex;
        flex-direction: ${direction};
    `;
};

export const AppContainer = styled.div`
	width: auto;

	${FlexMixin('column')}
	background-color: ${({ backgroundColor }) =>
		backgroundColor || Colors.primary.primary_light_shade};
	min-height: 100vh;
	position: relative;
`;

AppContainer.propTypes = {
	/**Background color for the container */
	backgroundColor: PropTypes.string,
};

export const ColumnFlex = styled.div`
	display: flex;
	flex-direction: column;
	margin: ${(props) => props.margin || '0'};
	padding: ${(props) => props.padding || '0'};
	gap: ${(props) => props.gap || '0'};
	transition: all ease 0.3s;
	${is('fullHeight')`
        height: 100%;
    `}

	${is('fullWidth')`
		width: 100%;
	`}
    ${is('flex')`
        flex: 1;
    `}
	${is('justifyCenter')`
		justify-content: center;
	`}

	${is('relative')`
		position: relative;
	`}

    ${is('center')`
		text-align: center;
    	align-items: center;
    	justify-content: center;
	`}

	${is('spaceBetween')`
		justify-content: space-between;
	`}

	${is('spaceAround')`
		justify-content: space-around;
	`}

    ${is('justifyStart')`
        justify-content: flex-start;
    `}
    ${is('alignStart')`
        align-items: flex-start;
    `}
	${is('alignCenter')`
		align-items: center;
	`}

	${is('alignEnd')`
		align-items: flex-end;
	`}

	${is('overflow')`
		overflow-y: scroll;
	`}


	${is('pointer')`
		cursor: pointer;
	`}

	${is('wordWrap')`
		overflow-wrap: break-word;
		word-wrap: break-word;
		word-break: break-all;
		word-break: break-word;
	`}
`;
ColumnFlex.propTypes = {
	/** Margins that should be added, needs to be in short hand syntax ex margin: top right bottom left; */
	margin: PropTypes.string,
	/** Padding that should be added, needs to be in short hand syntax ex margin: top right bottom left; */
	padding: PropTypes.string,
	/** Forces the flexbox to take up the whole container height, as opposed to flex where it would take up the whole height and width of container*/
	fullHeight: PropTypes.bool,
	/** Centers all items in the middle of the flexbox, including text-align: center*/
	center: PropTypes.bool,
	/** applies justify-content: space-between*/
	spaceBetween: PropTypes.bool,
	/** Applies: justify-content: space-around*/
	spaceAround: PropTypes.bool,
	/** Applies: justify-content: flex-start*/
	justifyStart: PropTypes.bool,
	/** Applies: align-items: flex-start;*/
	alignStart: PropTypes.bool,
	/** Applies align-items: center*/
	alignCenter: PropTypes.bool,
};

export const PageContainer = styled.div`
	background-color: ${(props) => props.bgColor || 'black'};
	height: ${({ height }) => height || 'auto'};
	width: ${({ width }) => width || 'auto'};
	position: relative;

	${FlexMixin()}

	${is('center')`
		align-items: center
	`}
	${is('justifyCenter')`
		justify-content: center;
	`}
`;
PageContainer.propTypes = {
	/**Background color for the container */
	backgroundColor: PropTypes.string,
	/** height applied to container*/
	height: PropTypes.string,
	/** wdith applied to container*/
	width: PropTypes.string,
	/** Applies align-items: center*/
	center: PropTypes.bool,
	/** applies justify-content: center*/
	justifyCenter: PropTypes.bool,
};

export const RowFlex = styled.div`
	display: flex;
	flex-direction: row;
	margin: ${(props) => props.margin || '0'};
	padding: ${(props) => props.padding || '0'};
	gap: ${(props) => props.gap || '0'};
	transition: all ease 0.3s;

	${is('center')`
		text-align: center;
    	align-items: center;
    	justify-content: center;
	`}

	${is('spaceBetween')`
		justify-content: space-between;
	`}

	${is('justifyBetween')`
		justify-content: space-between;
	`}

	${is('spaceAround')`
		justify-content: space-around;
	`}
	${is('fullHeight')`
        height: 100%;
    `}
	${is('alignCenter')`
		align-items: center;
	`}
	${is('alignEnd')`
		align-items: flex-end;
	`}
    ${is('fullWidth')`
        width: 100%;
    `}
    ${is('flex')`
        flex: 1;
    `}

	${is('justifyStart')`
        justify-content: flex-start;
    `}
	${is('justifyCenter')`
        justify-content: center;
    `}
	${is('justifyEnd')`
        justify-content: flex-end;
    `}

	${is('pointer')`
		cursor: pointer;
	`}
	${is('wrap')`
		flex-wrap: wrap;
	`}
	${is('columnMobile')`
		@media ${device.mobileS} {
    		flex-direction: column;
  		}
  		@media ${device.laptop} {
    		flex-direction: row;
  		}

	`}
`;
RowFlex.propTypes = {
	/** Margins that should be added, needs to be in short hand syntax ex margin: top right bottom left; */
	margin: PropTypes.string,
	/** Padding that should be added, needs to be in short hand syntax ex margin: top right bottom left; */
	padding: PropTypes.string,
	/** Centers all items in the middle of the flexbox, including text-align: center*/
	center: PropTypes.bool,
	/** applies justify-content: space-between*/
	spaceBetween: PropTypes.bool,
	/** Applies: justify-content: space-around*/
	spaceAround: PropTypes.bool,
	/** Applies align-items: center*/
	alignCenter: PropTypes.bool,
	/** Applies: width: 100% to container*/
	fullWidth: PropTypes.bool,
	/** applies flex:1 */
	flex: PropTypes.bool,
	/** Converts the column to a row when viewed on anything smaller than a tablet*/
	columnMobile: PropTypes.bool,

	wrap: PropTypes.bool,
};

export const Avatar = styled.div`
	height: ${({ height }) => height || '50px'};
	width: ${({ width }) => width || '50px'};
	margin: ${(props) => props.margin || '0'};
	border-radius: 50%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	background-color: ${({ backgroundColor }) => backgroundColor || '#fafafa'};
	background-image: url('${(props) => props.image_url}');
	border: solid 2px ${(props) => props.color || props.theme.color};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	${is('pointer')`
		cursor: pointer;
	`}
`;
Avatar.propTypes = {
	/** height applied to container*/
	height: PropTypes.string,
	/** wdith applied to container*/
	width: PropTypes.string,
	/** Margins that should be added, needs to be in short hand syntax ex margin: top right bottom left; */
	margin: PropTypes.string,
	/** If an image needs to be displayed pass the string as image_url*/
	image_url: PropTypes.string,
	/** Change the color of the border*/
	color: PropTypes.string,
	/** Any component*/
	children: PropTypes.element,
	/** background color of the Avatar container*/
	backgroundColor: PropTypes.string,
};

export const DropShadow = (level = 1) => {
	switch (level) {
		case 1:
			return 'box-shadow: 0 1px 4px rgba(37, 50, 56, 0.12), 0 1px 3px rgba(37, 50, 56, 0.24);';
			break;
		case 2:
			return 'box-shadow: 0 3px 6px rgba(37, 50, 56, 0.16), 0 3px 6px rgba(37, 50, 56, 0.23);';
			break;
		case 3:
			return 'box-shadow: 0 10px 20px rgba(37, 50, 56, 0.19), 0 6px 6px rgba(37, 50, 56, 0.23);';
			break;
		case 4:
			return 'box-shadow: 0 14px 28px rgba(37, 50, 56, 0.25), 0 10px 10px rgba(37, 50, 56, 0.22);';
			break;
		case 5:
			return 'box-shadow: 0 19px 38px rgba(37, 50, 56, 0.30), 0 15px 12px rgba(37, 50, 56, 0.22);';
			break;
		default:
			return '';
	}
};

export const CenterFlex = `
	align-items: center;
	justify-items: center;
`;

export const BackDropBg = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(4px);
`;

export const ModalPanel = styled(motion.div)`
	position: fixed;
	display: flex;
	height: fit-content;
	width: fit-content;
	z-index: 100;
	left: 0;
	display: flex;
	flex-direction: row;
	top: 0;
	border: 12px;
	gap: 20px;
	top: 50%;
	left: 50%;
	max-width: 90vw;
	transform: translate(-50%, -50%);
	max-height: 100vh;
	padding: 20px 0;
	overflow: auto;
`;

export const MODAL_BACKDROP = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};

export const MODAL_PANEL = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};
