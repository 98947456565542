import React from 'react';
import { Route, Switch } from 'react-router-dom';

import FormTemplates from './components/FormTemplates';
import FormTemplate from './components/FormTemplate';

import { FormTemplateProvider } from './context';
const FormRoutes = () => {
	return (
		<Switch>
			<Route path='/admin/forms'>
				<FormTemplates />
			</Route>
			<Route path='/admin/form/:formId'>
				<FormTemplateProvider>
					<FormTemplate />
				</FormTemplateProvider>
			</Route>
		</Switch>
	);
};

export default FormRoutes;
