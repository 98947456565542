import React, { useEffect, useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { useCheckCareProSessionQuery } from '../../api/queries/useCheckCareProSessionQuery';

import { coreRoutes } from '../../routes/constants';
import { authRoutes } from '../../../auth/routes/constants';
import { documentsRoutes } from '../../../documents/routes/constants';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [authState, setAuthState] = useState({
		appIsLoading: true,
		isAuthenticated: false,
		isRegistryApproved: false,
		hasSetProfessionalStatement: false,
		hasCompletedStripeOnboarding: false,
		isStripeVerified: false,
		hasCompletedPlatformOnboarding: false,
		pendingStripeDocumentRequirements: false,
		completedDocumentApproval: false,
		authCarePro: null,
		statusUpdate: 'Pending Registry Approval',
	});
	const history = useHistory();

	const { data, isLoading, refetch: refreshAuthCarePro } = useCheckCareProSessionQuery();

	// Update authentication and onboarding states
	useEffect(() => {
		const token = getFromLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN);

		if (!isLoading && data) {
			const { careProRegistryStatus, professional_statement, stripeStatus } = data;

			// Update whether the user is authenticated based on token existence
			setAuthState({
				isAuthenticated: !!token,

				appIsLoading: false,

				// Determine if registry approval is granted
				isRegistryApproved: careProRegistryStatus?.registry_approval || false,

				// Check if the user has set a professional statement
				hasSetProfessionalStatement: professional_statement !== null,

				// Verify if Stripe onboarding is completed
				isStripeVerified: stripeStatus?.status === 'verified',

				// Check if Stripe onboarding is not unverified or empty
				hasCompletedStripeOnboarding:
					stripeStatus?.status !== 'unverified' && stripeStatus?.status !== '',

				// Check if the platform onboarding is completed
				hasCompletedPlatformOnboarding: careProRegistryStatus?.platform_onboard || false,

				// Determine if there are pending Stripe document requirements
				pendingStripeDocumentRequirements: (
					stripeStatus?.stripe_data?.currently_due || []
				).some((req) => req.endsWith('.document')),

				// Check if all required documents are approved
				completedDocumentApproval: careProRegistryStatus?.documents_approval || false,

				// Store the CarePro data if a valid token exists
				authCarePro: token ? data : null,

				// Set the status update based on registry approval
				statusUpdate: careProRegistryStatus?.registry_approval
					? 'Approved'
					: 'Pending Registry Approval',
			});
		} else if (!token) {
			// Clear authentication state if no token is found
			setAuthState((prevState) => ({
				...prevState,
				isAuthenticated: false,
				authCarePro: null,
				appIsLoading: false,
			}));
			setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN);
		}
	}, [data, isLoading]);

	// Redirect based on onboarding progress
	useEffect(() => {
		if (authState.isAuthenticated && authState.authCarePro) {
			const { professional_statement, stripeStatus, careProRegistryStatus } =
				authState.authCarePro;

			// Redirect to the professional statement page if not set
			if (professional_statement === null) {
				history.push(authRoutes.onboarding.professionalStatement);
			}
			// Redirect to Stripe onboarding if unverified
			else if (stripeStatus?.status === 'unverified') {
				if (stripeStatus.stripe_data && stripeStatus.stripe_data.currently_due) {
					// ensure that we move the user to docs view after they've completed most of the stripe onboarding

					// check the length of the currently due stripe documents
					// ensure currently due is in model

					if (stripeStatus.stripe_data.currently_due) {
						// if individual.verification.document is in currently due take them to the documents page
						if (
							stripeStatus.stripe_data.currently_due.includes(
								'individual.verification.document'
							)
						) {
							history.push(documentsRoutes.stripeDocuments);
						} else {
							history.push(authRoutes.onboarding.stripe.address);
						}
					}
				} else {
					history.push(authRoutes.onboarding.stripe.address);
				}
			}
			// Redirect to documents page if approval is incomplete
			else if (!careProRegistryStatus?.documents_approval) {
				history.push(coreRoutes.documents);
			}
		}
	}, [authState.isAuthenticated, authState.authCarePro, history]);

	// Authentication methods
	const loginCarePro = (token, carePro) => {
		// Store the token in localStorage and update state
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN, token);
		setAuthState((prevState) => ({
			...prevState,
			isAuthenticated: true,
			authCarePro: carePro,
		}));
	};

	const logoutCarePro = () => {
		// Clear localStorage and reset authentication state
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_AUTH_TOKEN, null);
		setAuthState({
			...authState,
			isAuthenticated: false,
			authCarePro: null,
		});
	};

	const value = {
		...authState,
		loginCarePro,
		logoutCarePro,
		refreshAuthCarePro,
	};

	console.log('value', value);
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
