import React, { Component } from 'react';

import PageLoaderView from 'AppComp/PageLoaderView';

import {
	ProviderSection,
	PatientSection,
	PaymentSection,
	CarePlanSection,
	BasicCalculator,
	AdminReportsSection,
} from './UserModelViews';

import { AgencyView } from './UserModelBlocks';

export default class UserModelController extends Component {
	state = {
		updatedCommissionStatus: false,
	};
	viewsSwitch = () => {
		const {
			userDetail: { user, type },
			admin,
		} = this.props;

		const { updatedCommissionStatus } = this.state;
		switch (type) {
			case 'agencySettings':
				return <AgencyView />;
				break;
			case 'reports':
				return <AdminReportsSection />;
			case 'calculator':
				return <BasicCalculator />;
				break;
			case 'patient':
				return (
					<PatientSection
						handleUpdateFyiCommission={this.handleUpdateFyiCommission}
						dischargeProvider={this.handleDischargeProvider}
						updatedCommissionStatus={updatedCommissionStatus}
						{...user}
					/>
				);
				break;
			case 'provider':
				return (
					<ProviderSection
						{...user}
						handleUpdateFyiCommission={this.handleUpdateFyiCommission}
						dischargeProvider={this.handleDischargeProvider}
						updatedCommissionStatus={updatedCommissionStatus}
						handleVerifyProvider={this.handleVerifyProvider}
						handleUnVerifyProvider={this.handleUnVerifyProvider}
						handleDeletingProvider={this.handleDeletingProvider}
						admin={admin}
					/>
				);
				break;
			case 'payment':
				return <PaymentSection {...user} />;
				break;

			case 'carePlan':
				return (
					<CarePlanSection
						handleUpdateFyiCommission={this.handleUpdateFyiCommission}
						{...user}
						updatedCommissionStatus={updatedCommissionStatus}
						dischargeProvider={this.handleDischargeProvider}
						handleDeletingPatient={this.handleDeletingPatient}
						admin={admin}
					/>
				);
				break;
		}
	};

	handleDeletingProvider = (id) => {
		const { deleteTestUserAccount } = this.props;
		const confirm = window.confirm(
			'Are you sure you want to Delete This Care Provider: USE DURING TESTING ONLY'
		);

		if (confirm) deleteTestUserAccount(id, 'provider');
	};
	handleDeletingPatient = (id) => {
		const { deleteTestUserAccount } = this.props;
		const confirm = window.confirm(
			'Are you sure you want to Delete This Client: USE DURING TESTING ONLY'
		);

		if (confirm) deleteTestUserAccount(id, 'patient');
	};
	renderViews = () => {
		const { userDetail } = this.props;
		if (userDetail.user === null) {
			return <PageLoaderView errorMessage='Loading' />;
		} else {
			return this.viewsSwitch();
		}
	};

	handleUnVerifyProvider = (id) => {
		const { unVerifyProviderOffice } = this.props;

		const confirm = window.confirm('Are you sure you Un-Verify this provider? ');

		if (confirm) unVerifyProviderOffice(id);
	};
	handleVerifyProvider = (id) => {
		const { verifyProviderOffice } = this.props;

		const confirm = window.confirm('Are you sure you Verify this provider? ');

		if (confirm) verifyProviderOffice(id);
	};
	handleDischargeProvider = (id) => {
		const { dischargeProvider } = this.props;

		const confirm = window.confirm('Are you sure you discharge this provider? ');

		if (confirm) dischargeProvider(id);
	};

	handleUpdateFyiCommission = (amount) => {
		const updatedCommission = parseFloat(amount.replace(/\$/g, ''));

		const confirm = window.confirm(
			`Change FYI Commission For This Provider Care Plan to $${updatedCommission}?`
		);

		if (confirm) {
			const {
				userDetail: {
					user: { id },
				},
			} = this.props;

			this.setState({
				updatedCommissionStatus: true,
			});
		} else {
			console.log('stay the same');
		}
	};
}
