import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik-latest';

import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FieldGroup, Input, Label, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton, TransparentButton } from '../../../../../corev2/Buttons';

import ShiftItem from '../ShiftItem';

import { shiftSelectorValidationSchema } from './validation';

import {
	StyledShiftsSelectorContainer,
	StyledShiftsSelectorWrapper,
	StyledShiftSelectionForm,
	ShiftSelectorHeading,
	StyledDateSelector,
	StyledShiftDetails,
	StyledShiftText,
	StyledShiftsContainer,
	StyledTitle,
	StyledButtonContainer,
} from './styles';

import { useJobPost } from '../../hooks/useJobPost';

import { generateShifts } from '../../lib';

const ShiftSelector = (props) => {
	const { schedules, shiftSelectionModalHandler, offerCareProHandler } = props;
	const [shifts, setShifts] = useState([]);
	const [selectedShifts, setSelectedShifts] = useState([]);

	const { jobPost } = useJobPost();

	const toggleShiftSelection = (shiftId) => {
		setSelectedShifts((prevSelectedShifts) => {
			if (prevSelectedShifts.includes(shiftId)) {
				return prevSelectedShifts.filter((id) => id !== shiftId);
			} else {
				return [...prevSelectedShifts, shiftId];
			}
		});
	};

	const formik = useFormik({
		initialValues: {
			jobStartDate: moment().format('YYYY-MM-DD'),
			jobEndDate: moment().add(7, 'day').format('YYYY-MM-DD'),
		},
		// validationSchema: toFormikValidationSchema(shiftSelectorValidationSchema),
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: (values) => {
			const selectedShiftData = selectedShifts.map((shiftId) => {
				const shift = shifts.find((s) => s.id.toString() === shiftId);
				return {
					id: shift.id,
					start_date: shift.startDate,
					start_time: moment(shift.startTime, 'MMMM D, YYYY [at] h:mm A').format(
						'HH:mm:ss'
					),
					end_date: shift.startDate,
					end_time: moment(shift.endTime, 'MMMM D, YYYY [at] h:mm A').format('HH:mm:ss'),
					schedule_id: shift.scheduleId,
				};
			});

			offerCareProHandler(values.jobStartDate, values.jobEndDate, selectedShiftData);
		},
	});

	useEffect(() => {
		if (schedules && formik.values.jobStartDate && formik.values.jobEndDate) {
			const generatedShifts = generateShifts(
				schedules,
				jobPost.jobs,
				formik.values.jobStartDate,
				formik.values.jobEndDate
			);
			setShifts(generatedShifts);

			setSelectedShifts(generatedShifts.map((shift) => shift.id.toString()));

			// Update jobStartDate to the start date of the first available shift
			if (generatedShifts.length > 0) {
				formik.setFieldValue('jobStartDate', generatedShifts[0].startDate);
			}
		}
	}, [schedules, jobPost.jobs, formik.values.jobStartDate, formik.values.jobEndDate]);

	return (
		<StyledShiftsSelectorContainer>
			<StyledShiftsSelectorWrapper>
				<StyledShiftSelectionForm onSubmit={formik.handleSubmit}>
					<ShiftSelectorHeading>Send Offer</ShiftSelectorHeading>

					<StyledDateSelector>
						<FieldGroup>
							<Label>Job Start Date</Label>
							<Input
								type='date'
								name='jobStartDate'
								min={formik.values.jobStartDate}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.jobStartDate}
							/>

							{formik.touched.jobStartDate && formik.errors.jobStartDate && (
								<ErrorMessage>{formik.errors.jobStartDate}</ErrorMessage>
							)}
						</FieldGroup>

						<FieldGroup>
							<Label>Job End Date</Label>
							<Input
								type='date'
								name='jobEndDate'
								min={moment(formik.values.jobStartDate)
									.add(1, 'day')
									.format('YYYY-MM-DD')}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.jobEndDate}
							/>

							{formik.touched.jobEndDate && formik.errors.jobEndDate && (
								<ErrorMessage>{formik.errors.jobEndDate}</ErrorMessage>
							)}
						</FieldGroup>
					</StyledDateSelector>

					<StyledTitle>Available Shifts</StyledTitle>
					<StyledShiftDetails>
						{shifts.length > 0 && (
							<StyledShiftText>
								These are the available shifts from your schedule. Select shifts for
								the next 30 days. Click to deselect shifts that you don't want to
								assign to this CarePro.
							</StyledShiftText>
						)}

						{shifts.length === 0 && (
							<StyledShiftText>
								No upcoming shifts in the selected date range.
							</StyledShiftText>
						)}

						<StyledShiftsContainer>
							{shifts.map((shift) => (
								<ShiftItem
									key={shift.id}
									shift={shift}
									isSelected={selectedShifts.includes(shift.id.toString())}
									onClickHandler={() => toggleShiftSelection(shift.id.toString())}
								/>
							))}
						</StyledShiftsContainer>
					</StyledShiftDetails>

					<StyledButtonContainer>
						<PrimaryButton size='small' type='submit' disabled={shifts.length === 0}>
							Confirm Selection
						</PrimaryButton>

						<TransparentButton onClick={shiftSelectionModalHandler} size='small'>
							Cancel
						</TransparentButton>
					</StyledButtonContainer>
				</StyledShiftSelectionForm>
			</StyledShiftsSelectorWrapper>
		</StyledShiftsSelectorContainer>
	);
};

export default ShiftSelector;
