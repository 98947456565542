import React from 'react';

import Wrapper from '../../Wrapper';

import Avatar from '../components/Avatar';
import Name from '../components/Name';
import Rating from '../../Rating';
import Button from '../components/Button';
import InformationCard from '../components/InformationCard';

import {
	StyledProfileContainer,
	StyledProfileWrapper,
	StyledPersonalInfoContainer,
	StyledPersonalInfoWrapper,
	StyledLocation,
	StyledGender,
	StyledDescription,
	StyledInformationContainer,
	StyledInformationHeading,
	StyledTraitsContainer,
	StyledAbility,
	StyledTraitHeading,
	StyledTraitValue,
} from '../styles';

const ClientProfile = (props) => {
	const { name, traits } = props;

	return (
		<Wrapper>
			<StyledProfileContainer>
				<StyledProfileWrapper>
					<StyledPersonalInfoContainer>
						<Avatar />
						<StyledPersonalInfoWrapper>
							<Name>{name}</Name>
							<Rating />
							<StyledLocation>Lake Raymondport</StyledLocation>
							<StyledGender>Gender | Hourly </StyledGender>
						</StyledPersonalInfoWrapper>
					</StyledPersonalInfoContainer>
					<StyledDescription>
						This is a description This is a description This is a description This is a
						description This is a description This is a description This is a
						description
					</StyledDescription>
					<Button style={{ width: '120px' }} isTransparent>
						Chat
					</Button>
				</StyledProfileWrapper>
				<StyledInformationContainer>
					<StyledInformationHeading>Information</StyledInformationHeading>
					<InformationCard
						heading='Professional Statement'
						information='Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text
				Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text'
					/>
					<InformationCard
						heading='Certificate & Screenings'
						information='Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text
				Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text'
					/>
					{traits.length > 0 && (
						<StyledTraitsContainer>
							<StyledInformationHeading>Care Needs</StyledInformationHeading>

							{traits.map((trait) => (
								<StyledAbility>
									<StyledTraitHeading>{trait.trait}</StyledTraitHeading>
									<StyledTraitValue>{trait.value}</StyledTraitValue>
								</StyledAbility>
							))}
						</StyledTraitsContainer>
					)}
				</StyledInformationContainer>
			</StyledProfileContainer>
		</Wrapper>
	);
};

export default ClientProfile;
