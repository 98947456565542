import React, { useEffect, useState, createContext } from 'react';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { carePlanRoutes } from '../../routes/constants';

export const CarePlanContext = createContext();

const initialCarePlanState = {
	relationship: {
		name: 'relationship',
		label: 'Relationship',
		isCompleted: false,
		data: {
			relationship: '',
		},
		route: carePlanRoutes.onboarding.relationship,
	},
	info: {
		name: 'info',
		label: 'Client',
		isCompleted: false,
		data: {
			firstName: '',
			lastName: '',
			displayName: '',
			dob: '',
			gender: '',
		},
		route: carePlanRoutes.onboarding.info,
	},
	personal: {
		name: 'personal',
		label: 'Personal',
		isCompleted: false,
		data: {
			jobStatement: '',
			weight: '',
			diagnosis: '',
			emergencyContact1Name: '',
			emergencyContact1Relationship: '',
			emergencyContact1Phone: '',
			emergencyContact2Name: '',
			emergencyContact2Relationship: '',
			emergencyContact2Phone: '',
		},
		route: carePlanRoutes.onboarding.personal,
	},
	logistics: {
		name: 'logistics',
		label: 'Logistics',
		isCompleted: false,
		data: {
			address1: '',
			address2: '',
			city: '',
			zip: '',
			state: '',
			codeToEnterHome: '',
			whereToPark: '',
			extraInfo: '',
		},
		route: carePlanRoutes.onboarding.logistics,
	},
	careNeeds: {
		name: 'careNeeds',
		label: 'Care Needs',
		isCompleted: false,
		data: {},
		route: carePlanRoutes.onboarding.careNeeds,
	},
};

export const CarePlanProvider = ({ children }) => {
	const [carePlanState, setCarePlanState] = useState(initialCarePlanState);

	useEffect(() => {
		const localCarePlanState = getFromLocalStorage(localStorageKeys.FYI_CARE_PLAN_STATE);

		if (localCarePlanState) {
			setCarePlanState(localCarePlanState);
		}
	}, []);

	const mutateCarePlanState = (key, value) => {
		setCarePlanState((prevState) => {
			const newState = {
				...prevState,
				[key]: {
					...prevState[key],
					...value,
				},
			};

			setInLocalStorage(localStorageKeys.FYI_CARE_PLAN_STATE, JSON.stringify(newState));
			return newState;
		});
	};

	const resetCarePlanState = () => {
		setInLocalStorage(localStorageKeys.FYI_CARE_PLAN_STATE);
		setCarePlanState(initialCarePlanState);
	};

	const value = {
		carePlanState,
		mutateCarePlanState,
		resetCarePlanState,
	};

	return <CarePlanContext.Provider value={value}>{children}</CarePlanContext.Provider>;
};
