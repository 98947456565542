import styled from 'styled-components';

import Colors from 'colors';

export const StyledName = styled.span`
	font-size: 1.25rem;
	font-weight: 700;
	color: ${Colors.primary.new_primary};

	margin-bottom: 0.25rem;
`;
