import styled from 'styled-components';

import { colors } from '../../../../corev2/utils';

export const StyledPendingStep = styled.div`
	width: 30px;
	height: 30px;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 50%;
	border: 2px solid ${colors.primary.primary};

	color: ${colors.white};
`;
