import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledJobAction = styled.div`
	background: white;
	padding: 20px;

	color: ${colors.primary.primary};

	cursor: pointer;

	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;

	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
`;

export const StyledActionDetails = styled.div``;

export const StyledJobActionTitle = styled.h3`
	font-size: 16px;
	font-weight: bold;
	line-height: 1.4;

	color: ${colors.primary.primary};
`;

export const StyledJobActionDescription = styled.p`
	font-size: 14px;

	color: ${colors.primary.primary};
`;
