import React, { useState, Fragment } from 'react';
import { faInfo, faTimes } from '@fortawesome/free-solid-svg-icons';

import Colors from 'colors';

import { ModelHeader, AlternateSection } from 'AppComp/ModelOverlay';
import Text from 'AppComp/Text';
import Title from 'AppComp/Title';

import Icon from 'AppComp/Icon';
import { RowFlex, ColumnFlex } from 'basicStyles';
import BasicOverlayWrapper from 'AppComp/BasicOverlayWrapper';

import {
	OverlayContainer,
	ModelContainer,
	CustomAlternate,
	ExitContainer,
	Header,
} from './InfoViews';

const InfoHandler = (props) => {
	const { title, text, handleExitClick } = props;

	const renderText = () => {
		return text.map((d, i) => {
			return (
				<Text color='black' mediumText margin='16px 0 0 0'>
					{d}
				</Text>
			);
		});
	};
	return (
		<ModelContainer>
			<ModelHeader>
				<Icon icon={faInfo} color={Colors.white} />
			</ModelHeader>
			<CustomAlternate padding='24px 20px 16px 20px'>
				<Title color={Colors.secondary.green} margin='0 0 12px 0' bold subtitle>
					{title}
				</Title>
				{text && (
					<>
						{Array.isArray(text) ? (
							renderText()
						) : (
							<Text color='black' mediumText margin='8px 0 0 0'>
								{text}
							</Text>
						)}
					</>
				)}

				<Text
					padding='24px'
					nurseInfo
					pointer
					underline
					color={Colors.gray}
					onClick={() => handleExitClick()}
				>
					Exit
				</Text>
			</CustomAlternate>
		</ModelContainer>
	);
};

export default InfoHandler;
