import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { toast } from 'react-toastify';

import { ONBOARD_ADMIN_THEME } from 'constants';
import { receiveAdminInfo, toggleAdminOnboardOverlay } from 'reducers/admin';
import { ADMIN_MODEL } from 'reducers/graphqlModelTypes';

import { ColumnFlex, RowFlex } from 'basicStyles';

import OnboardFormHandler from '../OnboardFormHandler';
import Text from '../Text';

import { validatePatientLogin, validateAdminLogin } from '../../constants/validation';

import api from '../../scripts/api';

const toastErrorConfig = {
	containerId: 'basic',
	autoClose: false,
};

class OnboardAdminHanlder extends Component {
	state = {
		activeTheme: 'login',
		email: '',
	};

	//form func to run when all fields are valid, needs to handle register and login of patient
	handleSubmit = async (values, form) => {
		const { activeTheme } = this.state;
		const { loginAdmin } = this.props;

		if (activeTheme === 'login') {
			const loginAttempt = await this.loginAdmin(values);

			if (loginAttempt) {
				this.setState({ activeTheme: 'verify', email: values.email });
			}
		} else {
			this.handleVerfiedAdmin(this.state.email, values.auth_code);
		}
	};

	loginAdmin = async ({ email, password, registry_id }) => {
		const { receiveAdminInfo, adminLoginSagaDispatch } = this.props;

		let registryId = 1;

		const subdomain = window.location.hostname.split('.')[0];
		if (subdomain === 'findyourindependence') {
			// Render content for sub1
			registryId = 2;
		} else if (subdomain === 'philia') {
			// Render content for sub2
			registryId = 3;
		}

		if (registry_id) {
			registryId = parseInt(registry_id, 10);
		}
		try {
			const loginAttemptRes = await api.graph({
				query: `mutation{
					loginAdmin(
						email: "${email}",
						password: "${password}"
						registry_id: ${registryId}
					) {
						token
						admin {
							${ADMIN_MODEL}
						}
					}
				}`,
			});

			if (loginAttemptRes.loginAdmin) {
				const { token, admin } = loginAttemptRes.loginAdmin;
				localStorage.setItem('user-token', token);

				receiveAdminInfo(admin);
				adminLoginSagaDispatch();

				return true;
			}
		} catch (err) {
			if (Array.isArray(err)) {
				toast.warning(err[0].message, toastErrorConfig);
			} else {
				toast.error('Could not veirfy your login credentials', toastErrorConfig);
			}

			return false;
		}
	};

	handleVerfiedAdmin = async (email, auth_code) => {
		const { receiveAdminInfo, toggleAdminOnboardOverlay, adminLoginSagaDispatch } = this.props;
		try {
			const verifed = await api.graph({
				query: `mutation{
					verifyAdminLogin(email: "${email}", auth_code: "${auth_code}") {
						${ADMIN_MODEL}
					}
				}`,
			});

			toggleAdminOnboardOverlay(false);
		} catch (err) {
			if (Array.isArray(err)) {
				toast.warning(err[0].message, toastErrorConfig);
			} else {
				toast.error('Could not veirfy your Auth Code', toastErrorConfig);
			}
		}
	};
	//handles rendering the inital values for the form to validate
	renderInitalValues = () => {
		const initValues = {
			email: '',
			name: '',
			code: '',
			registry_id: '',
		};

		return initValues;
	};

	//handles changing the theme from login to register for patients
	handleThemeChange = (theme) => {
		this.setState({ activeTheme: theme });
	};

	render() {
		const { error } = this.props;

		const { activeTheme } = this.state;

		return (
			<OnboardFormHandler
				error={error}
				validate={activeTheme === 'login' ? validatePatientLogin : validateAdminLogin}
				handleSubmit={this.handleSubmit}
				renderInitalValues={this.renderInitalValues}
				theme={ONBOARD_ADMIN_THEME[activeTheme]}
			>
				<ColumnFlex fullHeight center>
					<Text
						color='white'
						onClick={() =>
							this.handleThemeChange(ONBOARD_ADMIN_THEME[activeTheme].alternateAction)
						}
					>
						{ONBOARD_ADMIN_THEME[activeTheme].alternateActionText}
					</Text>
				</ColumnFlex>
			</OnboardFormHandler>
		);
	}
}

const mapStateToProps = ({ environment }) => {
	return {
		error: environment.error,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		loginAdmin: (params) => dispatch(loginAdmin(params)),
		receiveAdminInfo: (params) => dispatch(receiveAdminInfo(params)),
		toggleAdminOnboardOverlay: (status) => dispatch(toggleAdminOnboardOverlay(status)),
		adminLoginSagaDispatch: () => dispatch({ type: 'ADMIN_LOGIN' }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardAdminHanlder);
