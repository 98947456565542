export const validatePatientLogin = (values) => {
	const errors = {};
	const err = 'Required';

	if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	if (!values.email) {
		errors.email = err;
	}

	if (!values.password) {
		errors.password = err;
	}

	return errors;
};

export const validatePhoneCode = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.authCode) {
		errors.authCode = err;
	}

	return errors;
};

export const validatePatientInfo = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.first_name) {
		errors.first_name = err;
	}
	if (!values.last_name) {
		errors.last_name = err;
	}

	if (!values.dob) {
		errors.dob = err;
	}

	if (!values.gender) {
		errors.gender = err;
	}

	if (!values.display_name) {
		errors.gender = err;
	}

	return errors;
};
export const validatePatientLocation = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.address_street) {
		errors.address_street = err;
	}
	if (!values.address_zip) {
		errors.address_zip = err;
	}

	if (!values.address_city) {
		errors.address_city = err;
	}

	if (!values.address_state) {
		errors.patientLocation = err;
	}
	if (values.address_state === 'NO_SELECTION') {
		errors.patient_relation = err;
	}

	return errors;
};

export const validatepatientRelation = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.patient_relation) {
		errors.patient_relation = err;
	}

	if (values.patient_relation === 'NO_SELECTION') {
		errors.patient_relation = err;
	}

	return errors;
};

export const validatePatientProfileInfo = (values) => {
	const errors = {};
	const err = 'Required';

	if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	if (!values.email) {
		errors.email = err;
	}

	if (!values.phone_number) {
		errors.phone_number = err;
	}

	return errors;
};
export const validateAdminLogin = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.auth_code) {
		errors.email = err;
	}

	return errors;
};
export const validatePatientRegister = (values) => {
	const errors = {};
	const err = 'Required';

	if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	if (!values.email) {
		errors.email = err;
	}

	if (!values.name) {
		errors.name = err;
	}

	if (!values.phoneNumber) {
		errors.phoneNumber = err;
	}

	if (!values.password) {
		errors.password = err;
	}

	if (!values.confirm_password) {
		errors.confirm_password = err;
	}

	if (values.confirm_password !== values.password) {
		errors.confirm_password = 'Passwords are not identical';
	}

	const password = values.password;

	//If password contains both lower and uppercase characters, increase strength value.
	if (!password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
		errors.password = 'Must contain both lower and uppercase characters';
	}

	//If it has numbers and characters, increase strength value.
	if (!password.match(/([0-9])/)) {
		errors.password = 'Must contain at least 1 number';
	}

	//If it has one special character, increase strength value.
	if (!password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
		errors.password = 'Must contain at least 1 special character';
	}
	if (password.length < 7) {
		errors.password = 'Must be at lest 8 character long';
	}
	return errors;
};

export const validateProviderProfileMessage = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.message) {
		errors.message = err;
	}

	return errors;
};

export const validateConfirmTaxId = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.confirm_ssn) {
		errors.message = err;
	}
	// ensure its 9 digits
	if (values.confirm_ssn) {
		if (values.confirm_ssn.length !== 9) {
			errors.confirm_ssn = 'Must be 9 digits';
		}
	}
	return errors;
};

export const validateCareProPersonalStripe = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.address_line1) {
		errors.address_line1 = err;
	}

	if (!values.address_city) {
		errors.address_city = err;
	}

	if (!values.address_state) {
		errors.address_state = err;
	}

	if (!values.address_postal_code) {
		errors.zip = err;
	}

	return errors;
};

export const validateCareProDobSSnlStripe = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.dob_day) {
		errors.dob_day = err;
	}

	if (!values.dob_month) {
		errors.dob_month = err;
	}

	if (!values.dob_year) {
		errors.dob_year = err;
	}

	if (!values.ssn_last_4) {
		errors.id_number = err;
	} else {
		// ensure its 4 digits
		if (values.ssn_last_4.length !== 4) {
			errors.ssn_last_4 = 'Must be 4 digits';
		}
	}
	// if (!values.id_number) {
	// 	errors.id_number = err;
	// }
	// if (values.id_number) {
	// 	// ensure its exactly 4 digits
	// 	if (values.id_number.length !== 9) {
	// 		errors.id_number = 'Must be 4 digits';
	// 	}
	// }
	return errors;
};

export const validateOnboardFlowAdmin = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.title) {
		errors.first_name = err;
	}

	if (!values.description) {
		errors.last_name = err;
	}

	if (!values.type) {
		errors.last_name = err;
	}

	return errors;
};

export const validateProviderInfoOnboard = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.first_name) {
		errors.first_name = err;
	}

	if (!values.last_name) {
		errors.last_name = err;
	}

	if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	if (!values.email) {
		errors.email = err;
	}

	if (!values.phoneNumber) {
		errors.phoneNumber = err;
	}

	if (!values.password) {
		errors.password = err;
	}

	if (!values.confirmP) {
		errors.confirmP = err;
	}

	if (!values.rate) {
		errors.hourlyRate = err;
	}
	if (!values.careType) {
		errors.careType = err;
	}

	if (!values.gender) {
		errors.gender = err;
	}
	const password = values.password;

	//If password contains both lower and uppercase characters, increase strength value.
	if (!password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
		errors.password = 'Must contain both lower and uppercase characters';
	}

	//If it has numbers and characters, increase strength value.
	if (!password.match(/([0-9])/)) {
		errors.password = 'Must contain at least 1 number';
	}

	//If it has one special character, increase strength value.
	if (!password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
		errors.password = 'Must contain at least 1 special character';
	}
	if (password.length < 7) {
		errors.password = 'Must be at lest 8 character long';
	}

	if (values.confirmP !== values.password) {
		errors.confirmP = 'Passwords are not identical';
	}

	return errors;
};
