import React from 'react';

import { StyledTimeInput, StyledDateTimeInput } from './styles';

function TimeInput({ className, type, isValid = true, ...props }) {
	if (type === 'datetime-local') {
		return <StyledDateTimeInput {...props} />;
	}

	return <StyledTimeInput type='time' className={className} isValid={isValid} {...props} />;
}

export default TimeInput;
