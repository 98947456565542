import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ClientProfilePage from '../pages/ClientProfilePage';

import { profileRoutes } from './constants';

const ProfileRoutes = () => {
	return (
		<Switch>
			<Route path={profileRoutes.clientProfile(':clientId')}>
				<ClientProfilePage />
			</Route>
		</Switch>
	);
};

export default ProfileRoutes;
