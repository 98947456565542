import React, { useState, useEffect } from 'react';
import { ColumnFlex } from 'basicStyles';
import Colors from 'colors';
import { Formik, Form, Field } from 'formik';
import { isMobile } from 'react-device-detect';
import { STATES_OPTIONS } from 'constants';
import { validateCareProPersonalStripe } from 'constants/validation';

import Title from 'AppComp/Title';

import {
	MARGIN_TITLE,
	FormItemsLength,
	OBFormButtonText,
	OBBasicCon,
	OBSubText,
} from 'AppComp/provider/POnboard/OnboardViews';
import Text from 'AppComp/Text';

import StyledTextInput, {
	StyledTextSelectInputV2,
	StyledTextSelectInput,
} from 'AppComp/styled/StyledTextInput';
import Button from 'AppComp/Button';

import {
	CardSection,
	CustomFormButton,
} from 'AppComp/patient/PatientCardCollection/PatientCardCollectionViews';

const FORM_ITEM_LENGTH = isMobile ? '320px' : '420px';

import PageLoaderView from 'AppComp/PageLoaderView';

const Personal = ({ updateProviderVerificationInfo, setIsLoading, isLoading }) => {
	const handleSubmit = (values, form) => {
		console.log('Personal - handleSubmit', values);
		setIsLoading(true);
		updateProviderVerificationInfo({
			address_line1: values.address_line1,
			address_city: values.address_city,
			address_state: values.address_state,
			address_postal_code: values.address_postal_code,
		});
	};

	return (
		<ColumnFlex style={{ maxWidth: FORM_ITEM_LENGTH }} center>
			<Title color={'black'} bold subtitle>
				Address
			</Title>
			<ColumnFlex padding='0 0 12px 0' margin='20px auto 0 auto' center gap='12px'>
				<Text
					style={{
						textAlign: 'center',
					}}
				>
					Let us know where you live so we can verify your identity.
				</Text>
				<Formik
					onSubmit={handleSubmit}
					validate={validateCareProPersonalStripe}
					autoComplete={'off'}
					render={(formProps) => {
						console.log(formProps);
						return (
							<Form noValidate>
								<ColumnFlex alignCenter justifyCenter>
									<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
										<Field
											name='address_line1'
											type='text'
											component={StyledTextInput}
											label='Street Address'
											backgroundColor={'white'}
										/>
									</FormItemsLength>
									<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
										<Field
											name='address_line2'
											type='text'
											component={StyledTextInput}
											backgroundColor={'white'}
											label='Street Address cont.'
										/>
									</FormItemsLength>
									<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
										<Field
											name='address_city'
											type='text'
											backgroundColor={'white'}
											component={StyledTextInput}
											label='City'
										/>
									</FormItemsLength>
									<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
										<Field
											name='address_state'
											component={StyledTextSelectInputV2}
											backgroundColor={'white'}
											label='State'
											options={STATES_OPTIONS}
										/>
									</FormItemsLength>
									<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
										<Field
											name='address_postal_code'
											backgroundColor={'white'}
											type='text'
											component={StyledTextInput}
											label='Zip Code'
										/>
									</FormItemsLength>
									{isLoading ? (
										<PageLoaderView errorMessage='Something went wrong plz contact dev monkeys' />
									) : (
										<CustomFormButton
											style={{
												borderRadius: '24px',
											}}
											valid={formProps.isValid}
											type='submit'
											margin='32px 8px 0 0px'
											backgroundColor={Colors.primary.primary}
											width={'240px'}
											height={isMobile ? '40px' : '60px'}
										>
											<Text
												style={{
													textAlign: 'center',
												}}
												bold
												color={
													formProps.isValid
														? 'white'
														: Colors.primary.primary
												}
												midLarge
											>
												Next
											</Text>
										</CustomFormButton>
									)}
								</ColumnFlex>
							</Form>
						);
					}}
				/>
			</ColumnFlex>
		</ColumnFlex>
	);
};
export default Personal;
