import React from 'react';

import { StyledSecondaryButton } from './styles';

const SecondaryButton = (props) => {
	const { children, ...restProps } = props;

	return <StyledSecondaryButton {...restProps}>{children}</StyledSecondaryButton>;
};

export default SecondaryButton;
