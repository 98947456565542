import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import InputContainer from '../InputContainer';

import { Input, Label, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import editDetailsValidationSchema from './validation';

import { StyledEditDetailsForm } from './styles';

const EditDetailsForm = (props) => {
	const { serverError, initialValues, updateCareProHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => updateCareProHandler(values),
		validationSchema: toFormikValidationSchema(editDetailsValidationSchema),
	});

	return (
		<StyledEditDetailsForm onSubmit={formik.handleSubmit}>
			<InputContainer>
				<Label> Email </Label>

				<Input
					name='email'
					type='email'
					onChange={formik.handleChange}
					placeholder='Email'
					value={formik.values.email}
				/>

				{formik.errors.email && <ErrorMessage> {formik.errors.email} </ErrorMessage>}
			</InputContainer>

			<InputContainer>
				<Label> Phone </Label>

				<Input
					type='tel'
					name='phone'
					onChange={formik.handleChange}
					placeholder='phone'
					value={formik.values.phone}
				/>

				{formik.errors.phone && <ErrorMessage> {formik.errors.phone} </ErrorMessage>}
			</InputContainer>

			<InputContainer>
				<PrimaryButton size='small' type='submit'>
					Edit
				</PrimaryButton>

				{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
			</InputContainer>
		</StyledEditDetailsForm>
	);
};

export default EditDetailsForm;
