import styled from 'styled-components';

import { mediaQueries } from '../../../../../corev2/utils';

export const StyledOnboardingWelcomePage = styled.div`
	padding: 50px 25px;
	margin-bottom: 20px;

	width: 100%;
	max-width: 1100px;

	display: flex;
	flex-direction: column;
	gap: 40px;

	margin: auto;
`;

export const StyledHeadingSection = styled.div`
	width: 100%;
`;

export const StyledOnboardingButtonsContainer = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;
	gap: 20px;
`;
