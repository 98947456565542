import React, { useEffect, useState } from 'react';
import Colors from 'colors';

import { Container, Title, GridContainer, Item, Label, Input } from './CoreView';

import { RowFlex } from 'basicStyles';
import Text from 'AppComp/Text';
const DocumentDataView = (props) => {
	const {
		id,
		user_id,
		uploaded_by,
		document_url,
		uploaded_date,
		document_name,
		status,
		notes,
		rejection_notes,

		expiration_date,
	} = props;

	const renderStatus = () => {
		let colorStatus = Colors.yellow;
		if (status === 'approved') {
			colorStatus = Colors.primary.primary;
		} else if (status === 'rejected') {
			colorStatus = Colors.red;
		}
		return (
			<RowFlex gap='4px' alignCenter justifyStart>
				<div
					style={{
						backgroundColor: colorStatus,
						borderRadius: '50%',
						width: '20px',
						height: '20px',
					}}
				/>
				<Text color={colorStatus} bold>
					{status}
				</Text>
			</RowFlex>
		);
		b;
	};

	return (
		<>
			<Title>{`${document_name}`}</Title>
			<GridContainer columns={3}>
				<Item>
					<Label>Uploaded By</Label>
					<Input>{uploaded_by}</Input>
				</Item>
				<Item>
					<Label>Uploaded Date</Label>
					<Input>{uploaded_date}</Input>
				</Item>
				<Item>
					<Label>Status</Label>
					<Input>{renderStatus()}</Input>
				</Item>
				<Item>
					<Label>Rejection Notes</Label>
					<Input>{rejection_notes || 'N/A'}</Input>
				</Item>
			</GridContainer>
		</>
	);
};

export default DocumentDataView;
