import React, { useEffect, useState } from 'react';
import moment from 'moment';
import changeCase from 'change-case';
import Text from 'AppComp/Text';

import Colors from 'colors';
import styled from 'styled-components';
import { RowFlex, ColumnFlex } from '../../styled/Basic';

import {
	TextInfo,
	Section,
	PaymentInfo,
	CarePlanInfo,
	ProviderInfo,
} from 'AppComp/admin/UserModel/UserModelBlocks';

const BasicContainer = styled.div`
	align-items: center;
	text-align: center;
`;

const StatusCircle = styled.div`
	width: 16px;
	height: 16px;
	border-radius: 50%;
`;

export const BasicPaymentView = (props) => {
	const { hours, provider_payout, timestamp, carePlan } = props;
	return (
		<div
			style={{
				borderRadius: '16px',
				backgroundColor: Colors.theme.dark_accent_background,
				padding: '20px',
				margin: '8px 0',
				cursor: 'pointer',
			}}
		>
			<RowFlex spaceBetween margin='0 0 8px 0'>
				<RowFlex alignCenter gap='8px'>
					<Text bold midLarge color={'black'}>
						${provider_payout / 100}
					</Text>
				</RowFlex>
				<ColumnFlex>
					<Text mediumText bold color={'black'}>
						{moment.unix(timestamp).format('MM/DD LT')}
					</Text>
				</ColumnFlex>
			</RowFlex>
			<RowFlex spaceBetween>
				{props.carePlan && (
					<Text mediumText color={'black'}>
						{`${carePlan.first_name} ${carePlan.last_name}`}
					</Text>
				)}
				<RowFlex gap='4px'>
					<Text mediumText color={'black'}>
						{hours} hours
					</Text>
				</RowFlex>
			</RowFlex>
		</div>
	);
};
