import React from 'react';
import { Route, Switch } from 'react-router-dom';

import DocumentsPage from '../pages/DocumentsPage';

import { documentsRoutes } from './constants';

const DocumentsRoutes = () => {
	return (
		<Switch>
			<Route path={documentsRoutes.home}>
				<DocumentsPage />
			</Route>
		</Switch>
	);
};

export default DocumentsRoutes;
