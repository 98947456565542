import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CARE_PRO_MODEL } from '../../../core/api/models';

const setPlatformOnboard = async () => {
	try {
		const data = await api.graph({
			query: `mutation {
				setPlatformOnboard {
                    ${CARE_PRO_MODEL}
                }
			}`,
		});

		return data.setPlatformOnboard;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useSetPlatformOnboardMutation = () => {
	return useMutation({
		mutationKey: ['setPlatformOnboard'],
		mutationFn: (values) => {
			return setPlatformOnboard(values);
		},
	});
};
