import React from 'react';

import { ColumnFlex } from 'basicStyles';

import api from 'scripts/api';
import {
	PROVIDER_MODEL_V2,
	STRIPE_STATUS_MODEL,
	CARE_PRO_REGISTRY_STATUS_MODEL,
} from 'reducers/graphqlModelTypes';

import Text from '../Text';
import OnboardFormHandler from '../OnboardFormHandler';

import { validatePatientLogin } from '../../constants/validation';

export const onboardProviderTheme = {
	title: 'Login Provider',
	form: [
		{
			name: 'email',
			label: 'Email',
			type: 'text',
			id: '0',
		},
		{
			name: 'password',
			label: 'Password',
			type: 'password',
			id: '1',
		},
	],
	buttonText: 'Login',
	alternateAction: '',
	alternateActionText: '',
};

const OnboardProvider = (props) => {
	const { closeLoginOpenSignup, error, history } = props;

	const handleSubmit = (values, form) => {
		//something someting
		const { handleProviderLogin, receiveError, toggleForgotPasswordOverlay, handleExitClick } =
			props;

		const { email, password } = values;

		let registryId = 1;

		const subdomain = window.location.hostname.split('.')[0];
		if (subdomain === 'findyourindependence') {
			// Render content for sub1
			registryId = 2;
		} else if (subdomain === 'philia') {
			// Render content for sub2
			registryId = 3;
		}

		return new Promise(function (resolve, reject) {
			api.graph({
				query: `mutation {
					loginProvider(
						email: "${email}",
						password: "${password}"
						registry_id: ${registryId}
						
					) {
							token,
							provider {
								${PROVIDER_MODEL_V2}
								stripeStatus {
									${STRIPE_STATUS_MODEL}
								}
								careProRegistryStatus {
									${CARE_PRO_REGISTRY_STATUS_MODEL}
								}
							}
						}
				}`,
			})
				.then((res) => {
					console.log('loginProvider res: ', res);
					if (res.loginProvider.token) {
						// save token to localhost
						localStorage.setItem('user-token', res.loginProvider.token);
					}
					handleProviderLogin(res.loginProvider.provider);

					if (res.loginProvider.provider.careProRegistryStatus) {
						const careProRegistryStatus =
							res.loginProvider.provider.careProRegistryStatus;

						if (careProRegistryStatus.platform_onboard === false) {
							history.push('/carepro-welcome');
						}
					}
				})
				.catch((err) => {
					console.log('loginProvider -> err, ', err);

					if (Array.isArray(err)) {
						receiveError(err[0].message);
					} else {
						receiveError('Something went wrong connecting');
						//receiveError(err);
					}
				});
		});
	};

	const renderInitalValues = () => {
		return {
			email: '',
			password: '',
		};
	};
	const toggleForgotPassword = () => {
		const { handleExitClick, toggleForgotPasswordOverlay } = props;

		handleExitClick();
		toggleForgotPasswordOverlay(true);
	};

	return (
		<OnboardFormHandler
			handleSubmit={handleSubmit}
			validate={validatePatientLogin}
			renderInitalValues={renderInitalValues}
			theme={onboardProviderTheme}
			error={error}
		>
			<ColumnFlex fullHeight center>
				<Text
					fontStyle='bold'
					largeText
					pointer
					underline
					color='white'
					onClick={() => history.push('/carepro-welcome')}
				>
					Create Account
				</Text>
			</ColumnFlex>
			<Text
				mediumText
				pointer
				color='white'
				underline
				margin='24px 0 0 0'
				onClick={() => toggleForgotPassword(false)}
			>
				Forgot Password?
			</Text>
		</OnboardFormHandler>
	);
};

export default OnboardProvider;
