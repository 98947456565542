import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { DropShadow } from 'basicStyles';
import Button from 'AppComp/Button';
import Colors from 'colors';

export const ActiveButton = styled(Button)`
	background-color: ${Colors.yellow};
	width: 180px;
	height: 68px;
	border-radius: 50px;
	position: fixed;
	z-index: 1000;
	top: 2vh;
	right: 5vw;
	margin: 0 0 20px 0;
	@media (max-width: 768px) {
		width: 140px;
		right: 16px;
	}
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ##e5e5e5;
	align-items: center;
	position: relative;
`;