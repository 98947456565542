import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { push } from 'connected-react-router';

import Colors from 'colors';

import { ModelContainer } from 'AppComp/EventModel';

import Text from 'AppComp/Text';
import Title from 'AppComp/Title';

import { ColumnFlex } from 'basicStyles';
import { toast } from 'react-toastify';

import {
	ListParentCon,
	ListCon,
	ListItem,
	ListItemSubItem,
	TEMP_DATA,
} from './UserOnboardStepsStyles';
import api from '../../../scripts/api';
import { toastSuccessConfig } from '../../../reducers/provider';
import CollectCardHandler from '../CollectCard';

const UserOnboardStpesHander = (props) => {
	if (props.accountStatus === null) {
		return 'Not logged in';
	}

	const { closeToast } = props;

	const handleResendEmail = async () => {
		try {
			const graphResponse = await api.graph({
				query: `mutation {
					reSendUserEmailVerification
				}`,
			});
			toast.success('Email Sent', toastSuccessConfig);
			console.log('graphResponse', graphResponse);
		} catch (err) {
			console.log('err', err);
		}
	};

	const handleRedirectToCarePlan = () => {
		console.log(' is this urrning');
		props.pushRoute('/careplan');
		props.closeToast();
	};

	const handleRedirectToProviders = () => {
		props.pushRoute('/search/1');
		props.closeToast();
	};
	const hanldeOpenCollectCard = () => {
		toast(({ closeToast }) => <CollectCardHandler closeToast={closeToast} />, {
			autoClose: false,
			hideProgressBar: true,
			style: { padding: '2px' },
			draggable: false,
			closeOnClick: false,
			closeButton: true,
			position: 'top-center',
			containerId: 'LargeToast',
		});
		props.closeToast();
		console.log('is this runnign');
	};
	// email verification check
	const emailVerifed =
		props.accountStatus && props.accountStatus.valid_email === true ? true : false;

	/// careplan verification check
	const verifedCarePlan = {
		patient_personal_details_submitted:
			props.accountStatus && props.accountStatus.patient_personal_details_submitted
				? props.accountStatus.patient_personal_details_submitted
				: false,
		patient_location_details_submitted:
			props.accountStatus && props.accountStatus.patient_location_details_submitted
				? props.accountStatus.patient_location_details_submitted
				: false,
	};

	const verifedCarePlanStatus = Object.values(verifedCarePlan).every((item) => item === true);

	console.log('verifedCarePlan', verifedCarePlan);
	// provider & admin message check

	const conversationStatus =
		props.conversation && props.conversation.messages.length > 0 ? true : false;

	// payment method check
	const paymentMethodStatus =
		props.accountStatus && props.accountStatus.payment_method_verification ? true : false;
	return (
		<ModelContainer>
			<ColumnFlex fullWidth justifyStart alignCenter gap='12px' padding='16px 0px 8px 0px'>
				<ListParentCon fullWidth>
					<ListCon>
						<ColumnFlex padding='0 0 6px 0' fullWidth center>
							<Title color={Colors.greenLightTeal} bold subtitle>
								User Onboard Steps
							</Title>
						</ColumnFlex>
						<ColumnFlex
							style={{
								maxWidth: '320px',
							}}
							padding='0 0 12px 0'
							margin='auto'
							center
						>
							<Text
								style={{
									textAlign: 'center',
								}}
							>
								Before we can match you with the best care provider, we need to have
								a few things in order.
							</Text>
						</ColumnFlex>

						<ListItem
							completed={emailVerifed}
							amountOfTask={1}
							title={TEMP_DATA[0].title}
						>
							<ListItemSubItem completed={emailVerifed}>
								<Text
									style={{
										textAlign: 'left',
									}}
								>
									Check your email for a verification link
								</Text>
								{!emailVerifed && (
									<Text
										onClick={() => handleResendEmail()}
										style={{
											textAlign: 'left',
										}}
										subtext
										underline
										pointer
									>
										(Resend Email)
									</Text>
								)}
							</ListItemSubItem>
						</ListItem>
						<ListItem
							amountOfTask={3}
							completed={verifedCarePlanStatus}
							title={TEMP_DATA[1].title}
						>
							<ListItemSubItem completed={true}>
								<Text
									style={{
										textAlign: 'left',
									}}
								>
									Visit your Care Plan
								</Text>
								<Text
									onClick={() => handleRedirectToCarePlan()}
									style={{
										textAlign: 'left',
									}}
									subtext
									underline
									pointer
								>
									(Take me there)
								</Text>
							</ListItemSubItem>
							{TEMP_DATA[1].subItems.map((item, index) => {
								return (
									<ListItemSubItem
										completed={verifedCarePlan[item.verifyKey]}
										key={index}
									>
										<Text
											style={{
												textAlign: 'left',
											}}
										>
											{item.text}
										</Text>
									</ListItemSubItem>
								);
							})}
						</ListItem>
						<ListItem
							completed={conversationStatus}
							amountOfTask={2}
							title={TEMP_DATA[2].title}
						>
							<ListItemSubItem completed={conversationStatus}>
								<Text
									style={{
										textAlign: 'left',
									}}
								>
									View care givers near you
								</Text>
								<Text
									onClick={() => handleRedirectToProviders()}
									style={{
										textAlign: 'left',
									}}
									subtext
									underline
									pointer
								>
									(Take me there)
								</Text>
							</ListItemSubItem>
							{TEMP_DATA[2].subItems.map((item, index) => {
								return (
									<ListItemSubItem completed={conversationStatus} key={index}>
										<Text
											style={{
												textAlign: 'left',
											}}
										>
											{item.text}
										</Text>
									</ListItemSubItem>
								);
							})}
						</ListItem>
						<ListItem
							completed={paymentMethodStatus}
							amountOfTask={1}
							title={TEMP_DATA[3].title}
						>
							<ListItemSubItem completed={paymentMethodStatus}>
								<Text
									style={{
										textAlign: 'left',
									}}
								>
									Add a card to your account
								</Text>
								<Text
									onClick={() => hanldeOpenCollectCard()}
									style={{
										textAlign: 'left',
									}}
									subtext
									underline
									pointer
								>
									(Add card)
								</Text>
							</ListItemSubItem>
						</ListItem>
					</ListCon>
				</ListParentCon>
				<Text onClick={() => closeToast()} subtext pointer underline>
					Exit
				</Text>
			</ColumnFlex>
		</ModelContainer>
	);
};

const mapStateToProps = ({ patient, router }, props) => {
	return {
		patient: patient.patient,
		accountStatus: patient.accountStatus,
		conversation: patient.conversation,
		location: router.location,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		pushRoute: (route) => dispatch(push(route)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(UserOnboardStpesHander);
