import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { PAYMENT_METHOD_MODEL } from '../../../core/api/models';

const updateDefaultPaymentMethod = async ({ sourceId }) => {
	try {
		const data = await api.graph({
			query: `mutation {
				        updatePatietnDefaultPaymentMethod( payment_method_id: "${sourceId}") {
				        ${PAYMENT_METHOD_MODEL}
				}
			}`,
		});

		return data.updatePatietnDefaultPaymentMethod;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useUpdateDefaultPaymentMethodMutation = () => {
	return useMutation({
		mutationKey: ['updateDefaultPaymentMethod'],
		mutationFn: (values) => {
			return updateDefaultPaymentMethod(values);
		},
	});
};
