import styled from 'styled-components';

import { mediaQueries } from '../../../../../corev2/utils';

export const StyledJobPostsPage = styled.div`
	padding: 50px 25px;
	margin-bottom: 20px;

	width: 100%;
	max-width: 1100px;

	display: flex;
	flex-direction: column;
	gap: 40px;

	margin: auto;

	@media ${mediaQueries.tablet} {
		flex-direction: row;
	}
`;

export const StyledHeadingSection = styled.div`
	width: 100%;

	@media ${mediaQueries.tablet} {
		width: 33%;
	}
`;

export const StyledJobPostsContainer = styled.div`
	width: 100%;

	display: flex;
	flex-direction: column;

	gap: 20px;

	@media ${mediaQueries.tablet} {
		width: 67%;
	}
`;
