import { toast } from 'react-toastify';

export const Notification = (content, type, options) => {
	switch (type) {
		case 'success':
			return toast.success(content, {
				position: 'top-right',
				...options,
			});

		case 'error':
			return toast.error(content, {
				position: 'top-right',
				...options,
			});

		case 'warn':
			return toast.warn(content, {
				position: 'top-right',
				...options,
			});

		case 'info':
			return toast.info(content, {
				position: 'top-right',
				...options,
			});

		default:
			return toast(content, {
				position: 'top-right',
				...options,
			});
	}
};
