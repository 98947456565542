import styled from 'styled-components';
import React from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import Icon from 'AppComp/Icon';

export const Colors = {
	primary: {
		primary: '#2b69a3',
		secondary: '#3882cc',
		accent: '#619cd6',
		background: '#89b5e1',
		text: '#b1cfec',
		border: '#dae8f6',
		highlight: '#84c4ff',
	},
	secondary: {
		primary: '#1e857b',
		secondary: '#25b1a7',
		accent: '#36d3c8',
		background: '#60dcd4',
		text: '#8ae5df',
		border: '#dae8f6',
		highlight: '#b7f0eb',
	},
	borderColor: '#2b69a64d',
	gray: '#969696',
	white: '#ffffff',
	pageBackground: '#edf6fa',
	warning: '#fa4362',
	forms: {
		fontColor: '#363636',
		formInputBorderColor: '#81b0df',
		placeholderColor: '#dddedf',
	},
	table: {
		header: '#f9fafb',
		border: '#e5e7eb',
		alternative: '#edf6fb',
		text: '#6b7280',
	},
};
const sizes = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopL: '1440px',
	desktop: '1860px',
	desktopL: '2560px',
};

const mediaQueries = {
	mobileS: `(min-width: ${sizes.mobileS})`,
	mobileM: `(min-width: ${sizes.mobileM})`,
	mobileL: `(min-width: ${sizes.mobileL})`,
	tablet: `(min-width: ${sizes.tablet})`,
	laptop: `(min-width: ${sizes.laptop})`,
	laptopL: `(min-width: ${sizes.laptopL})`,
	desktop: `(min-width: ${sizes.desktop})`,
	desktopL: `(min-width: ${sizes.desktopL})`,
};

export const ManageSectionCon = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 40px;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 20px;
`;

export const ManageSectionContent = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
	flex: 1;
`;

export const StyledInputContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 0.5rem;
`;

export const StyledInputInnerContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 5px;
`;
export const InputContainer = (props) => {
	const { children } = props;

	return <StyledInputContainer>{children}</StyledInputContainer>;
};

export const StyledInput = styled.input`
	border: 1px solid #81b0df;
	border-width: 1px;
	box-shadow: none;
	border-radius: 4px;
	color: ${Colors.forms.fontColor};
	line-height: 40px;
	padding: 0 12px;
	width: 100%;
	min-height: 42px;

	font-size: 15px;

	::placeholder {
		color: ${Colors.forms.placeholderColor};
		opacity: 1;

		font-size: 15px;
	}

	:focus {
		outline: thin dotted currentColor;
	}
`;

export const Input = (props) => {
	return <StyledInput {...props} />;
};

export const StyledFormWrapper = styled.form`
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 20px;
	text-align: left;
`;

export const FormWrapper = (props) => {
	const { children, ...restProps } = props;

	return <StyledFormWrapper {...restProps}>{children}</StyledFormWrapper>;
};

export const StyledErrorMessage = styled.p`
	word-wrap: break-word;
	color: ${Colors.warning};
	line-height: 1.7;
	font-size: 12px;
	height: 20px;
`;

export const ErrorMessage = ({ children }) => {
	return <StyledErrorMessage>{children}</StyledErrorMessage>;
};

const RadioCon = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const ULStyles = styled.ul`
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
	li {
		display: flex;
		flex-direction: row;
		gap: 15px;
		align-items: center;
	}
`;
// radio input with a large circle to select
const RadioCustomInput = styled.input;

const LabelStyling = styled.label`
	font-size: 1rem;
	color: #000;
	font-weight: 500;
	line-height: 0.9;
`;

export const RadioFormComp = (props) => {
	const { title, options, value, name, onChange, error, onBlur } = props;

	const renderOptions = () => {
		return options.map((d, i) => {
			return (
				<li key={i}>
					<input
						name={name}
						type='radio'
						value={d}
						checked={d === value}
						onChange={onChange}
					/>
					<LabelStyling>{d}</LabelStyling>
				</li>
			);
		});
	};
	return (
		<RadioCon>
			<h3
				style={{
					letterSpacing: '.1rem',
				}}
			>
				{title}
			</h3>
			<StyledInputInnerContainer>
				<ULStyles>{renderOptions()}</ULStyles>
				<ErrorMessage>{error && error}</ErrorMessage>
			</StyledInputInnerContainer>
		</RadioCon>
	);
};

// i need a checkbox option thats styled like the radion option but in a row format for all the options
const CheckboxCon = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

export const CheckboxFormComp = (props) => {
	const { title, options, value = [], name, onChange, error } = props;

	const handleCheckboxChange = (event) => {
		const { value: checkboxValue, checked } = event.target;

		// Create a copy of the current value array
		let newValue = [...value];

		if (checked) {
			// Add the checkbox value to the array if it's checked
			newValue.push(checkboxValue);
		} else {
			// Remove the checkbox value from the array if it's unchecked
			newValue = newValue.filter((v) => v !== checkboxValue);
		}

		// Set the new array of selected values in Formik
		onChange({ target: { name, value: newValue } });
	};

	const renderOptions = () => {
		return options.map((d, i) => {
			return (
				<li key={i}>
					<input
						name={name}
						type='checkbox'
						value={d}
						checked={value && value.includes(d)}
						onChange={handleCheckboxChange}
					/>
					<LabelStyling>{d}</LabelStyling>
				</li>
			);
		});
	};
	return (
		<CheckboxCon>
			<h3
				style={{
					letterSpacing: '.1rem',
				}}
			>
				{title}
			</h3>
			<StyledInputInnerContainer>
				<ULStyles>{renderOptions()}</ULStyles>
				<ErrorMessage>{error && error}</ErrorMessage>
			</StyledInputInnerContainer>
		</CheckboxCon>
	);
};

export const TextFormComp = (props) => {
	const { title, value, name, onChange, error, placeholder, onBlur } = props;
	return (
		<StyledInputContainer>
			<h3
				style={{
					letterSpacing: '.1rem',
				}}
			>
				{title}
			</h3>
			<StyledInputInnerContainer>
				<StyledInput
					onBlur={onBlur}
					placeholder={placeholder}
					name={name}
					value={value}
					onChange={onChange}
				/>
				<ErrorMessage>{error && error}</ErrorMessage>
			</StyledInputInnerContainer>
		</StyledInputContainer>
	);
};

const TraitOptionsCon = styled.div`
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
`;

const OptionCon = styled.div`
	display: flex;
	flex-direction: column;
	gap: 0px;
`;

const TextOptionTrait = (props) => {
	const { arrayHelpers, name, onChange, value, error, index } = props;
	return (
		<StyledInputContainer>
			<TraitOptionsCon>
				<StyledInput
					placeholder={`Enter an option ${index + 1}`}
					text='text'
					name={name}
					value={value}
					onChange={onChange}
				/>
				<Icon icon={faTimes} color='red' onClick={() => arrayHelpers.remove(index)} />
			</TraitOptionsCon>
			<ErrorMessage>{error && error}</ErrorMessage>
		</StyledInputContainer>
	);
};
export const MultiOptionsComp = (props) => {
	const { formik, arrayHelpers, error } = props;
	const renderOptions = () => {
		if (formik.values.options && formik.values.options.length > 0) {
			return formik.values.options.map((option, index) => {
				return (
					<TextOptionTrait
						key={index}
						arrayHelpers={arrayHelpers}
						name={`options[${index}]`}
						value={option}
						index={index}
						onChange={formik.handleChange}
					/>
				);
			});
		}
	};
	return (
		<StyledInputContainer>
			<h3
				style={{
					letterSpacing: '.1rem',
				}}
			>
				Options
			</h3>
			<OptionCon>{renderOptions()}</OptionCon>

			<SubSubmitButton
				onClick={() => arrayHelpers.push('')}
				style={{
					margin: 'auto',
				}}
				type='button'
			>
				<p>Add an Option</p>
			</SubSubmitButton>
			<ErrorMessage>{error && error}</ErrorMessage>
		</StyledInputContainer>
	);
};

// need a select drop down with the same style as the StyledInput
const SelectInput = styled.select`
	border: 1px solid #81b0df;
	border-width: 1px;
	box-shadow: none;
	border-radius: 4px;
	color: ${Colors.forms.fontColor};
	line-height: 40px;
	padding: 5px 12px;
	width: 100%;
	min-height: 42px;

	font-size: 15px;

	::placeholder {
		color: ${Colors.forms.placeholderColor};
		opacity: 1;

		font-size: 15px;
	}

	:focus {
		outline: thin dotted currentColor;
	}
`;

export const SelectFormComp = (props) => {
	const { title, value, name, onChange, error, options } = props;

	const renderOptions = () => {
		return options.map((d, i) => {
			return (
				<option key={i} value={d}>
					{d}
				</option>
			);
		});
	};

	return (
		<StyledInputContainer>
			<h3
				style={{
					letterSpacing: '.1rem',
				}}
			>
				{title}
			</h3>
			<StyledInputInnerContainer>
				<SelectInput name={name} value={value} onChange={onChange}>
					{renderOptions()}
				</SelectInput>

				<ErrorMessage>{error && error}</ErrorMessage>
			</StyledInputInnerContainer>
		</StyledInputContainer>
	);
};

// now i need a textarea with that same styling but max its' height at liek 400px but takes it's contaier width
const TextAreaInput = styled.textarea`
	border: 1px solid #81b0df;
	border-width: 1px;
	box-shadow: none;
	border-radius: 4px;
	color: ${Colors.forms.fontColor};
	padding: 5px 12px;
	width: 100%;
	min-height: 42px;
	font-size: 15px;
	resize: none;
	height: 150px;
	::placeholder {
		color: ${Colors.forms.placeholderColor};
		opacity: 1;

		font-size: 15px;
	}

	:focus {
		outline: thin dotted currentColor;
	}
`;

export const TextAreaFormComp = (props) => {
	const { title, value, name, onChange, error, placeholder, onBlur } = props;

	return (
		<StyledInputContainer>
			<h3
				style={{
					letterSpacing: '.1rem',
				}}
			>
				{title}
			</h3>
			<TextAreaInput
				placeholder={placeholder}
				name={name}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
			/>
			<ErrorMessage>{error && error}</ErrorMessage>
		</StyledInputContainer>
	);
};

export const SubmitButtonCon = styled.button`
	background-color: ${Colors.primary.primary};
	color: white;
	border: none;
	padding: 15px 20px;
	border-radius: 24px;
	cursor: pointer;
	min-width: 200px;
`;

export const SubmitButton = ({ children, style, onClick, type }) => {
	return (
		<SubmitButtonCon type={type} onClick={onClick} style={style}>
			{children}
		</SubmitButtonCon>
	);
};

export const StyledDateTimeInput = styled.input`
	border: 1px solid #81b0df;
	border-width: 1px;
	box-shadow: none;
	border-radius: 4px;
	color: ${Colors.forms.fontColor};
	line-height: 40px;
	padding: 5px 12px;
	width: 100%;
	min-height: 42px;

	font-size: 15px;

	::placeholder {
		color: ${Colors.forms.placeholderColor};
		opacity: 1;

		font-size: 15px;
	}

	:focus {
		outline: thin dotted currentColor;
	}
`;

export const StyledTimeInput = styled.input`
	border: 1px solid #81b0df;
	border-width: 1px;
	box-shadow: none;
	border-radius: 4px;
	color: ${Colors.forms.fontColor};
	line-height: 40px;
	padding: 5px 12px;
	width: 100%;
	min-height: 42px;

	font-size: 15px;

	::placeholder {
		color: ${Colors.forms.placeholderColor};
		opacity: 1;

		font-size: 15px;
	}

	:focus {
		outline: thin dotted currentColor;
	}
`;

export const TimeInputComp = (props) => {
	const { type, title, value, name, onChange, error, placeholder, onBlur } = props;

	if (type === 'datetime-local') {
		return (
			<StyledInputContainer>
				<h3
					style={{
						letterSpacing: '.1rem',
					}}
				>
					{title}
				</h3>
				<StyledDateTimeInput
					type='datetime-local'
					name={name}
					value={value}
					onChange={onChange}
					onBlur={onBlur}
					readonly
				/>
				<ErrorMessage>{error && error}</ErrorMessage>
			</StyledInputContainer>
		);
	}

	return (
		<StyledInputContainer>
			<h3
				style={{
					letterSpacing: '.1rem',
				}}
			>
				{title}
			</h3>
			<StyledTimeInput
				type='time'
				name={name}
				value={value}
				onChange={onChange}
				onBlur={onBlur}
				readonly
			/>
			<ErrorMessage>{error && error}</ErrorMessage>
		</StyledInputContainer>
	);
};
