import React from 'react';

import { StyledInformationTile, StyledIcon, StyledTitle, StyledDescription } from './styles';

const InformationTile = (props) => {
	const { icon, title, description } = props;

	return (
		<StyledInformationTile>
			<StyledIcon icon={icon} />
			<StyledTitle>{title}</StyledTitle>
			<StyledDescription>{description}</StyledDescription>
		</StyledInformationTile>
	);
};

export default InformationTile;
