import styled from 'styled-components';

import Colors from 'colors';

import { device } from 'AppComp/styled/StyledMediaQuery';

export const StyledTileContainer = styled.div`
	width: 100%;
	padding: 24px 18px;
	background-color: white;
	border: 1px solid ${Colors.primary.new_primary};
	border-radius: 16px;
	float: left;

	margin-right: 0px;
	margin-bottom: 16px;

	display: flex;
	flex-direction: column;

	@media ${device.tablet} {
		margin-right: 16px;
		flex-direction: row;
	}

	@media ${device.laptop} {
		width: 394px;
	}
`;

export const StyledAvatarContainer = styled.div`
	width: 80px;
	height: 80px;
	min-width: 80px;
	border-radius: 50%;
	background-color: white;
	border: 1px solid ${Colors.primary.new_primary};

	margin: auto;

	@media ${device.tablet} {
		margin: 0;
	}
`;

export const StyledPersonalInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 8px;
	width: 100%;
	text-align: center;

	@media ${device.tablet} {
		text-align: left;
	}
`;

export const StyledPersonalInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: auto;

	justify-content: space-between;

	@media ${device.tablet} {
		flex-direction: row;

		margin: 0;
	}
`;

export const StyledPersonalInfo = styled.div`
	display: flex;
	flex-direction: column;

	margin-bottom: 0.25rem;
`;

export const StyledName = styled.span`
	font-size: 1.25rem;
	font-weight: 700;
	color: ${Colors.primary.new_primary};

	margin-bottom: 0.25rem;
`;

export const StyledRate = styled.span`
	color: ${Colors.primary.new_primary};

	font-size: 1.5rem;
	font-weight: 700;
`;

export const StyledHour = styled.span`
	font-size: 0.8rem;
`;

export const StyledLocation = styled.span`
	font-size: 0.8rem;
	color: ${Colors.primary.new_primary};

	margin-bottom: 0.25rem;
`;

export const StyledDescriptionContainer = styled.div`
	display: flex;
	max-width: 276px;
	flex-direction: column;
	margin: auto;

	font-size: 0.8rem;
	word-wrap: break-word;

	@media ${device.tablet} {
		margin: 0;
	}
`;

export const StyledGender = styled.div`
	color: ${Colors.primary.new_primary};

	margin-bottom: 0.5rem;
`;

export const StyledDescription = styled.p`
	color: ${Colors.primary.new_primary};
`;

export const StyledButtonContainer = styled.div`
	margin-top: 1rem;
`;

export const StyledButton = styled.button`
	width: 80px;
	height: 30px;

	margin-right: 0.5rem;

	background: ${({ isTransparent }) => (isTransparent ? 'inherit' : Colors.primary.new_primary)};
	border: 1px solid ${Colors.primary.new_primary};
	border-radius: 18px;

	font-size: 0.75rem;
	color: ${({ isTransparent }) => (isTransparent ? Colors.primary.new_primary : 'white')};

	@media ${device.tablet} {
		width: 100px;
		height: 40px;

		font-size: 0.875rem;
	}
`;
