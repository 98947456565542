import React, { useState, Component, Fragment } from 'react';
import styled from 'styled-components';
import is from 'styled-is';
import Colors from 'colors';
import ProgressBar from '@ramonak/react-progress-bar';
import AnimateHeight from 'react-animate-height';
import {
	faExclamationTriangle,
	faChevronDown,
	faChevronUp,
	faPlusCircle,
	faCircle,
	faUserNurse,
	faHeart,
} from '@fortawesome/free-solid-svg-icons';
import colors from 'colors';

import { ColumnFlex, DropShadow, RowFlex } from 'basicStyles';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';
import Button from 'AppComp/Button';

export const PROVIDER_ONBOARD_CON_PADDING = '0 58px';

const ChatContainer = styled(ColumnFlex)`
	height: inherit;
	width: inherit;
	transition: opacity ease 0.3s;
	opacity: 0;
	padding: 0 0 0px 0;
	overflow: hidden;
	overflow-y: scroll;
	&.p-c-enter {
		opacity: 1;
	}

	&.p-c-enter-done {
		opacity: 1;
	}

	&.p-c-exit {
		opacity: 0;
	}

	&.p-c-exit-done {
		opacity: 0;
	}
`;

export const Walkthrough = ({ history, location, toggleOnboardOverlay }) => {
	const [loadedStatus, updateLoaderStatus] = useState(0);

	const handleCare = () => {
		history.push('/careplan');
		updateOverlayStatus(false);
	};
	const handleFit = () => {
		history.push('/search/1');
		updateOverlayStatus(false);
	};
	const handleLogin = () => {
		toggleOnboardOverlay(true);
		updateOverlayStatus(false);
	};
	return (
		<ChatContainer alignCenter>
			<Title
				margin='32px 0 24px 0'
				color='#1e857b'
				subtitle
				bold
				padding={`${PROVIDER_ONBOARD_CON_PADDING}, 0`}
			>
				Welcome to the Community!
			</Title>
			<Text color='#636363' margin='0 0 12px 0' padding={PROVIDER_ONBOARD_CON_PADDING}>
				You're Welcomed to look around and acustom your self to the platform.
			</Text>
			<Text color='#636363' margin='0 0 12px 0' padding={PROVIDER_ONBOARD_CON_PADDING}>
				Before We Get Started We Must Verify a Few Things
			</Text>
			<Tile icon={faPlusCircle} title='1) Create Your CarePlan' completed={false}>
				<Text gray margin='8px 0' subtitle>
					Longer short brief description about the area of the page
				</Text>
				<Text margin='8px 0' mediumText>
					Create Your CarePlan
				</Text>
				<Text margin='8px 0' mediumText>
					Create Your CarePlan
				</Text>
				<Text margin='8px 0' mediumText>
					Create Your CarePlan
				</Text>
				<Button onClick={() => handleCare()} backgroundColor='#1e857b'>
					Start CarePlan
				</Button>
			</Tile>
			<Tile icon={faUserNurse} title='2) Find The Perfect Fit' completed={false}>
				<Text gray margin='8px 0' subtitle>
					Longer short brief description about the area of the page
				</Text>
				<Text margin='8px 0' mediumText>
					Create Your CarePlan
				</Text>
				<Text margin='8px 0' mediumText>
					Create Your CarePlan
				</Text>
				<Text margin='8px 0' mediumText>
					Create Your CarePlan
				</Text>
				<Button onClick={() => handleFit()} margin='16px 0' backgroundColor='#1e857b'>
					Find Your Match
				</Button>
			</Tile>
			<Tile icon={faHeart} title='Start Your Journey' completed={false}>
				<Text gray margin='8px 0' subtitle>
					Longer short brief description about the area of the page
				</Text>
				<Text margin='8px 0' mediumText>
					Create Your CarePlan
				</Text>
				<Text margin='8px 0' mediumText>
					Create Your CarePlan
				</Text>
				<Text margin='8px 0' mediumText>
					Create Your CarePlan
				</Text>
				<Button
					onClick={() => handleLogin()}
					margin='16px 0'
					backgroundColor={Colors.secondary.blue}
				>
					Create Account
				</Button>
			</Tile>
		</ChatContainer>
	);
};

const Tile = ({ title, children, completed, icon }) => {
	const [active, updateActive] = useState(completed || false);
	return (
		<ColumnFlex fullWidth padding='0 16px'>
			<RowFlex
				padding='12px 0'
				onClick={() => updateActive(!active)}
				fullWidth
				spaceBetween
				alignCenter
			>
				<RowFlex alignCenter>
					<Icon
						size='2x'
						icon={icon}
						color={active ? colors.secondary.green : colors.black}
					/>
					<Text
						largeText
						margin='0 0 0 16px'
						color={active ? colors.secondary.green : colors.black}
					>
						{title}
					</Text>
				</RowFlex>
				<Icon
					size='2x'
					icon={active ? faChevronUp : faChevronDown}
					color={active ? colors.secondary.green : colors.black}
				/>
			</RowFlex>
			<AnimateHeight duration={500} height={active ? 'auto' : '0%'}>
				<ColumnFlex alignCenter fullWidth padding='0 16px'>
					{children}
				</ColumnFlex>
			</AnimateHeight>
		</ColumnFlex>
	);
};
