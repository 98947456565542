import moment from 'moment';

const generateEventText = (type) => {
	switch (type) {
		case 'available':
			return 'AVAILABLE';
		case 'pending':
			return 'ASSIGNED';
		case 'offered':
			return 'OFFERED';
		case 'clocked_in':
			return 'CLOCKED IN';
		case 'clocked_out':
			return 'CLOCKED OUT';
		case 'submitted':
			return 'SUBMITTED';
		case 'canceled':
			return 'SHIFT CANCELED';
		case 'paid':
			return 'PAID';
		default:
			return 'AVAILABLE';
	}
};

// Helper function to check if an event has a matching exception
const hasException = (event, exceptions) => {
	return exceptions.some((exception) => {
		const exceptionStart = moment(`${exception.start_date}T${exception.start_time}`);
		const exceptionEnd = moment(`${exception.end_date}T${exception.end_time}`);
		const eventStart = moment(event.start);
		const eventEnd = moment(event.end);

		return eventStart.isSameOrAfter(exceptionStart) && eventEnd.isSameOrBefore(exceptionEnd);
	});
};

export const generateEvents = (schedules, jobs, currentDate, view) => {
	const events = [];

	// Helper function to check if a date is within the schedule date range
	const isWithinDateRange = (date, scheduleStartDate, scheduleEndDate) => {
		return (
			date.isSameOrAfter(scheduleStartDate, 'day') &&
			(!scheduleEndDate || date.isSameOrBefore(scheduleEndDate, 'day'))
		);
	};

	// Combine all shifts from jobs into one array
	const allShifts = jobs.flatMap((job) => job.shifts);

	const generateRecurringEventsForPeriod = (
		startPeriod,
		endPeriod,
		schedule,
		filterDays = null
	) => {
		const scheduleStartDate = moment(schedule.start_date, 'YYYY-MM-DD');
		const scheduleEndDate = schedule.end_date ? moment(schedule.end_date, 'YYYY-MM-DD') : null;

		for (let date = startPeriod.clone(); date.isBefore(endPeriod); date.add(1, 'days')) {
			if (!isWithinDateRange(date, scheduleStartDate, scheduleEndDate)) continue;

			if (filterDays) {
				const dayOfWeek = date.format('ddd');
				if (!filterDays.includes(dayOfWeek)) continue;
			}

			const eventStartDate = moment(`${date.format('YYYY-MM-DD')}T${schedule.start_time}`);
			const eventEndDate = moment(`${date.format('YYYY-MM-DD')}T${schedule.end_time}`);

			// Temporarily push events without setting the type
			events.push({
				title: '',
				start: eventStartDate.toDate(),
				end: eventEndDate.toDate(),
				allDay: false,
				resource: { type: null, schedule },
			});
		}
	};

	// Process each schedule in the schedules array
	schedules.forEach((schedule) => {
		if (!schedule.active) return;

		if (schedule.repeat_frequency === 'one_time') {
			const startDateTimeString = `${moment(schedule.start_date).format('YYYY-MM-DD')}T${
				schedule.start_time
			}`;
			const scheduleStartDateTime = new Date(startDateTimeString);

			const endDateTimeString = `${moment(schedule.start_date).format('YYYY-MM-DD')}T${
				schedule.end_time
			}`;
			const scheduleEndDateTime = new Date(endDateTimeString);

			events.push({
				title: '',
				start: scheduleStartDateTime,
				end: scheduleEndDateTime,
				allDay: false,
				resource: { type: null, schedule },
			});
		}

		// Handle weekly recurring events
		else if (schedule.repeat_frequency === 'weekly') {
			const daysOfWeek = schedule.days_of_week.split(',');

			if (view === 'month') {
				const startOfCurrentMonth = moment(currentDate).startOf('month');
				const endOfCurrentMonth = moment(currentDate).endOf('month');
				generateRecurringEventsForPeriod(
					startOfCurrentMonth,
					endOfCurrentMonth,
					schedule,
					daysOfWeek
				);
			} else if (view === 'week') {
				const startOfWeek = moment(currentDate).startOf('week');
				const endOfWeek = moment(currentDate).endOf('week');
				generateRecurringEventsForPeriod(startOfWeek, endOfWeek, schedule, daysOfWeek);
			} else if (view === 'day') {
				const startOfDay = moment(currentDate).startOf('day');
				const endOfDay = moment(currentDate).endOf('day');
				generateRecurringEventsForPeriod(startOfDay, endOfDay, schedule, daysOfWeek);
			}
		}

		// Handle daily recurring events
		else if (schedule.repeat_frequency === 'daily') {
			if (view === 'month') {
				const startOfCurrentMonth = moment(currentDate).startOf('month');
				const endOfCurrentMonth = moment(currentDate).endOf('month');
				generateRecurringEventsForPeriod(startOfCurrentMonth, endOfCurrentMonth, schedule);
			} else if (view === 'week') {
				const startOfWeek = moment(currentDate).startOf('week');
				const endOfWeek = moment(currentDate).endOf('week');
				generateRecurringEventsForPeriod(startOfWeek, endOfWeek, schedule);
			} else if (view === 'day') {
				const startOfDay = moment(currentDate).startOf('day');
				const endOfDay = moment(currentDate).endOf('day');
				generateRecurringEventsForPeriod(startOfDay, endOfDay, schedule);
			}
		}

		// Handle every_weekday recurring events (Mon-Fri)
		else if (schedule.repeat_frequency === 'every_weekday') {
			const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri'];
			if (view === 'month') {
				const startOfCurrentMonth = moment(currentDate).startOf('month');
				const endOfCurrentMonth = moment(currentDate).endOf('month');
				generateRecurringEventsForPeriod(
					startOfCurrentMonth,
					endOfCurrentMonth,
					schedule,
					weekdays
				);
			} else if (view === 'week') {
				const startOfWeek = moment(currentDate).startOf('week');
				const endOfWeek = moment(currentDate).endOf('week');
				generateRecurringEventsForPeriod(startOfWeek, endOfWeek, schedule, weekdays);
			} else if (view === 'day') {
				const startOfDay = moment(currentDate).startOf('day');
				const endOfDay = moment(currentDate).endOf('day');
				generateRecurringEventsForPeriod(startOfDay, endOfDay, schedule, weekdays);
			}
		}
	});

	events.forEach((event) => {
		const schedule = event.resource.schedule;

		// Apply job shifts to determine the event's type
		const matchedShift = allShifts.find((shift) => {
			const shiftStart = moment(`${shift.start_date}T${shift.start_time}`).toDate();
			const shiftEnd = moment(`${shift.end_date}T${shift.end_time}`).toDate();
			return (
				shift.schedule_id === schedule.id &&
				event.start >= shiftStart &&
				event.end <= shiftEnd
			);
		});
		// Apply shift status if a matching shift is found
		if (matchedShift) {
			event.resource.type = matchedShift.status;
			event.resource.shift = matchedShift;
		} else {
			event.resource.type = 'available';
		}

		// Update event title based on the determined type
		event.title = generateEventText(event.resource.type);
	});

	// Filter out events with exceptions
	const filteredEvents = events.filter((event) => {
		const schedule = event.resource.schedule;
		return !hasException(event, schedule.schedule_exceptions || []);
	});

	// Sort the events by start time
	filteredEvents.sort((a, b) => new Date(a.start) - new Date(b.start));

	return filteredEvents;
};
