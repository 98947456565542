import React, { useState } from 'react';

import {
	OnboardFormContainer,
	OnboardTitle,
	OnboardSubText,
	OnboardButton,
} from '../CareProOnboardViews';
import { ColumnFlex } from 'basicStyles';

import Text from 'AppComp/Text';
const Welcome = (props) => {
	const { handleDataUpdate } = props;

	const handleContinue = () => {
		handleDataUpdate({}, 2);
	};
	return (
		<OnboardFormContainer
			style={{
				width: 'fit-content',
			}}
			center
		>
			<OnboardTitle>Welcome to the FYI CarePro Community!</OnboardTitle>
			<ColumnFlex
				style={{
					maxWidth: '400px',
				}}
				center
				gap='30px'
			>
				<OnboardSubText>
					No more long waits to get paid by a payroll company… So if you are tired of
					being paid weekly or bi-weekly … Join the FYI marketplace.
				</OnboardSubText>
				<OnboardSubText>
					FYI is a marketplace web service. You are in control at FYI. You are
					self-empowered to schedule your workday and be in charge of setting your own pay
					rates that you, and your clients agree upon together.
				</OnboardSubText>
			</ColumnFlex>
			<OnboardButton onClick={() => handleContinue()}>Continue</OnboardButton>
		</OnboardFormContainer>
	);
};

export default Welcome;
