import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { DOCUMENT_REQUIREMENTS } from '../../../core/api/models';

const fetchDocumentRequirements = async () => {
	try {
		const data = await api.graph({
			query: `
				query {
					fetchClientDocumentRequirements {
						${DOCUMENT_REQUIREMENTS}
					}
				}
			`,
		});

		return data.fetchClientDocumentRequirements;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchDocumentRequirements = () => {
	return useQuery({
		queryKey: ['fetchDocumentRequirements'],
		queryFn: () => {
			return fetchDocumentRequirements();
		},
		retry: false,
	});
};
