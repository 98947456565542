import React, { useState, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { THEME } from 'constants';
import {
	checkUserSession,
	changePatientPassword,
	forgotPatientPassword,
	toggleOnboardOverlay,
	updatePaymentAuthModelStatus,
	updateCardCollectModelStatus,
} from 'reducers/patient';

import { toggleForgotPasswordOverlay, toggleChangePasswordOverlay } from 'reducers/environment';
import { receiveUserDetails } from 'reducers/admin';

import PatientNavigation from './PatientNavigation';
import PatientProfile from './PatientProfile';
import WelcomePage from './WelcomePage';
import PatientChatModel from './PatientChatModel';
import PaymentError from './PaymentError';
import CollectCard from './CollectCard';
import UserModel from '../admin/UserModel';
import Documents from './Documents';
import Payments from './Payments';

import ForgotPassword from './ForgotPassword';
import ChangePassword from './ChangePassword';
import ClientChatHandler from './ClientChat/ClientChatHandler';
import CareProProfile from './CareProProfile';
import CareNeedsPage from './CareNeedsPage';
import Profiles from '../core/Profiles';
import ClientSchedule from './ClientSchedule';
import Jobs from './Jobs';
import CreateJob from './CreateJob';
import JobPost from './JobPost';
import CarePlan from './CarePlan';
import ClientOffer from './ClientOffer';
import ClientOnboard from './ClientOnboard';
import RegistryOnboard from './RegistryOnboard';

import Walk from './Walk';

import { ColumnFlex, RowFlex, DropShadow } from 'basicStyles';

//import ProviderProfile from '../provider/ProviderProfile';

import BasicOverlayWrapper from '../BasicOverlayWrapper';
import { Section, WebsiteNav } from './WelcomePage/WelcomePageViews';
import OnboardPatientHanlder from './OnboardPatientHanlder';

import useClientAuth from 'scripts/useClientAuth';

const PatientRouteHandler = (props) => {
	const {
		checkUserSession,
		location,
		history,

		conversation,

		toggleForgotPasswordOverlay,
		showChangePasswordOverlay,
		changePasswordStatus,
		changePatientPassword,
		showForgotPasswordOverlay,
		forgotPatientPassword,
		forgotPasswordStatus,
		toggleChangePasswordOverlay,
		toggleOnboardOverlay,
		patient,
		carePlan,
		fetchingClientSession,
		receiveUserDetails,
		userDetail,
	} = props;

	const [showNav, setShowNav] = useState(false);

	useEffect(() => {
		// check if local storage has a user token
		// if not dont' check the session

		const token = localStorage.getItem('user-token');
		if (token) checkUserSession();

		if (location.pathname === '/password') toggleChangePasswordOverlay(true);
	}, []);

	const { isAuthenticated, hasCarePlan } = useClientAuth();

	const showNavHandler = () => {
		setShowNav((prevValue) => !prevValue);
	};

	console.log('fetchingClientSession', fetchingClientSession);
	// feels a bit more complicated than it should but we'll see
	const { status, attempts } = fetchingClientSession;
	const token = localStorage.getItem('user-token');

	const shouldShowWelcome = true;

	console.log('!isAuthenticated', !isAuthenticated);

	console.log('shouldShowWelcome', shouldShowWelcome);

	return (
		<ThemeProvider theme={THEME.patient}>
			<BasicOverlayWrapper
				status={userDetail.status}
				appear
				handleExitClick={() =>
					receiveUserDetails({ status: false, user: null, type: null })
				}
			>
				<UserModel
					userDetail={userDetail}
					handleExitClick={() =>
						receiveUserDetails({ status: false, user: null, type: null })
					}
				/>
			</BasicOverlayWrapper>

			<BasicOverlayWrapper
				status={showChangePasswordOverlay}
				classNames='basic-overlay'
				appear
				handleExitClick={() => toggleChangePasswordOverlay(false)}
			>
				<ChangePassword
					handleExitClick={() => toggleChangePasswordOverlay(false)}
					passwordStatus={changePasswordStatus}
					changePassword={changePatientPassword}
				/>
			</BasicOverlayWrapper>
			<BasicOverlayWrapper
				status={showForgotPasswordOverlay}
				classNames='basic-overlay'
				appear
				handleExitClick={() => toggleForgotPasswordOverlay(false)}
			>
				<ForgotPassword
					forgotPassword={forgotPatientPassword}
					forgotPasswordStatus={forgotPasswordStatus}
				/>
			</BasicOverlayWrapper>
			<>
				{conversation && <PatientChatModel conversation={conversation} />}
				<RegistryOnboard />
				<WebsiteNav
					showNav={showNav}
					fetchingClientSession={fetchingClientSession}
					showNavHandler={showNavHandler}
					history={history}
				/>

				<PatientNavigation
					history={history}
					showNav={showNav}
					showNavHandler={showNavHandler}
				/>

				<Switch>
					<Route
						exact
						path='/'
						children={(props) => {
							return (
								<WelcomePage
									toggleOnboardOverlay={toggleOnboardOverlay}
									{...props}
									patient={patient}
									checkUserSession={checkUserSession}
								/>
							);
						}}
					/>
					<Route
						path='/careplan'
						children={(props) => {
							return <CarePlan {...props} />;
						}}
					/>
					<Route
						path='/careplan/info'
						children={(props) => {
							return <CarePlan {...props} />;
						}}
					/>
					<Route
						path='/careplan/logistics'
						children={(props) => {
							return <CarePlan {...props} />;
						}}
					/>
					<Route
						path='/careplan/personal'
						children={(props) => {
							return <CarePlan {...props} />;
						}}
					/>
					<Route
						path='/careplan/careneeds'
						children={(props) => {
							return <CarePlan {...props} />;
						}}
					/>
					<Route
						exact
						path='/clientschedule'
						children={(props) => {
							return <ClientSchedule {...props} />;
						}}
					/>
					<Route
						exact
						path='/verifyAccount'
						children={(props) => {
							return (
								<WelcomePage
									toggleOnboardOverlay={toggleOnboardOverlay}
									{...props}
									patient={patient}
									checkUserSession={checkUserSession}
								/>
							);
						}}
					/>

					<Route
						exact
						path='/password'
						children={(props) => {
							return (
								<WelcomePage
									toggleOnboardOverlay={toggleOnboardOverlay}
									{...props}
								/>
							);
						}}
					/>
					{/* <Route
						path='/welcome'
						children={(props) => {
							return <ClientOnboard {...props} />;
						}}
					/> */}

					<Route
						path='/welcome'
						children={(props) => {
							return shouldShowWelcome ? (
								<ClientOnboard {...props} />
							) : (
								<Redirect to='/careplan' />
							);
						}}
					/>
					<Route
						path='/jobpost/:jobPostId/carePro/:careProId/offer'
						exact
						children={(props) => {
							return <ClientOffer {...props} />;
						}}
					/>
					<Route
						path='/jobpost/:jobPostId/carePro/:careProId'
						exact
						children={(props) => {
							return (
								<CareProProfile
									toggleOnboardOverlay={toggleOnboardOverlay}
									{...props}
								/>
							);
						}}
					/>

					<Route
						path='/jobpost/:jobPostId'
						children={(props) => {
							return <JobPost {...props} />;
						}}
					/>

					<Route
						path='/viewprovider/:careProId'
						children={(props) => {
							return (
								<CareProProfile
									showJobSection
									toggleOnboardOverlay={toggleOnboardOverlay}
									{...props}
								/>
							);
						}}
					/>

					<Route
						path='/shift/:shiftId'
						children={(props) => {
							return <p>FIX ME</p>;
						}}
					/>

					<Route
						exact
						path={['/profile']}
						children={(props) => {
							return (
								<PatientProfile
									toggleOnboardOverlay={toggleOnboardOverlay}
									{...props}
								/>
							);
						}}
					/>
					<Route
						exact
						path={['/payments']}
						children={(props) => {
							return <Payments {...props} />;
						}}
					/>
					<Route
						exact
						path='/documents'
						children={(props) => {
							return isAuthenticated ? <Documents {...props} /> : <Redirect to='/' />;
						}}
					/>
					<Route
						path={['/login']}
						children={(props) => {
							return isAuthenticated ? (
								<Redirect to='/' />
							) : (
								<OnboardPatientHanlder {...props} />
							);
						}}
					/>
					<Route
						path={['/chat/:conversation_id', '/chat']}
						children={(props) => {
							return <ClientChatHandler {...props} />;
						}}
					/>
					<Route
						path='/jobs'
						children={(props) => {
							return isAuthenticated ? <Jobs {...props} /> : <Redirect to='/login' />;
						}}
					/>
					{/* <Route path='/createjob' children={(props) => <CreateJob {...props} />} /> */}
				</Switch>
			</>
		</ThemeProvider>
	);
};

const mapStateToProps = ({ admin, patient, environment }, props) => {
	return {
		patient: patient.patient,
		accountStatus: patient.accountStatus,
		carePlan: patient.carePlan,
		conversation: patient.conversation,
		showForgotPasswordOverlay: environment.showForgotPasswordOverlay,
		forgotPasswordStatus: environment.forgotPasswordStatus,

		showChangePasswordOverlay: environment.showChangePasswordOverlay,
		changePasswordStatus: environment.changePasswordStatus,

		showPaymentAuthModel: patient.showPaymentAuthModel,
		showCollectCardModel: patient.showCollectCardModel,

		userDetail: admin.userDetail,

		fetchingClientSession: patient.fetchingClientSession,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		checkUserSession: () => dispatch(checkUserSession()),

		toggleChangePasswordOverlay: (status) => dispatch(toggleChangePasswordOverlay(status)),
		forgotPatientPassword: (email) => dispatch(forgotPatientPassword(email)),
		changePatientPassword: (password) => dispatch(changePatientPassword(password)),
		toggleForgotPasswordOverlay: (status) => dispatch(toggleForgotPasswordOverlay(status)),
		toggleOnboardOverlay: (status) => dispatch(toggleOnboardOverlay(status)),
		updatePaymentAuthModelStatus: (status) => dispatch(updatePaymentAuthModelStatus(status)),
		updateCardCollectModelStatus: (status) => dispatch(updateCardCollectModelStatus(status)),
		receiveUserDetails: (params) => dispatch(receiveUserDetails(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientRouteHandler);
