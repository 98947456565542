import moment from 'moment';

export const sortShifts = (shifts) => {
	shifts.sort((a, b) => {
		const dateComparison = moment(a.startDate).diff(moment(b.startDate));
		return dateComparison !== 0
			? dateComparison
			: moment(a.startTime, 'HH:mm').diff(moment(b.startTime, 'HH:mm'));
	});

	return shifts;
};
