import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';

import {
	StyledOnboardingButton,
	StyledBlueCheckMark,
	StyledInformationContainer,
	StyledPlatformOnboardingTitle,
} from './styles';

const OnboardingButton = (props) => {
	const { text, onClickHandler } = props;

	return (
		<StyledOnboardingButton onClick={onClickHandler}>
			<StyledBlueCheckMark>
				<FontAwesomeIcon size='xs' icon={faUserPlus} />
			</StyledBlueCheckMark>

			<StyledInformationContainer>
				<StyledPlatformOnboardingTitle>{text}</StyledPlatformOnboardingTitle>
			</StyledInformationContainer>
		</StyledOnboardingButton>
	);
};

export default OnboardingButton;
