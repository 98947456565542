import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ChatPage from '../pages/ChatPage';

import { chatRoutes } from './constants';

const ChatRoutes = () => {
	return (
		<Switch>
			<Route path={chatRoutes.home} exact>
				<ChatPage />
			</Route>

			<Route path={chatRoutes.chatRoom(':chatId')} exact>
				<ChatPage />
			</Route>
		</Switch>
	);
};

export default ChatRoutes;
