import styled from 'styled-components';

import Colors from 'colors';

export const StyledPricingContainer = styled.div`
	margin: 3rem auto 0 auto;

	border-top: 4px solid #eaf3ff;

	padding-top: 1rem;
`;

export const StyledHeading = styled.h3`
	font-size: 1.25rem;
	color: ${Colors.primary.new_primary};
`;

export const StyledSection = styled.section`
	margin-top: 0.75rem;

	border-bottom: 1px solid ${Colors.primary.new_primary};
`;

export const StyledPricingWrapper = styled.div`
	display: flex;
	justify-content: space-between;

	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
`;

export const StyledPriceItem = styled.span`
	font-size: 0.75rem;
	color: ${Colors.primary.new_primary};
`;

export const StyledPrice = styled.span`
	font-size: 0.75rem;
	color: ${Colors.primary.new_primary};
`;

export const StyledTotal = styled.span`
	font-size: 1.25rem;
	font-weight: 700;
	color: ${Colors.primary.new_primary};

	margin-top: 0.25rem;
`;

export const StyledTotalPrice = styled.span`
	font-size: 1.25rem;
	font-weight: 700;
	color: ${Colors.primary.new_primary};

	margin-top: 0.25rem;
`;

export const StyledCalculatorContainer = styled.div`
	display: flex;
	justify-content: center;

	margin-top: 2rem;
`;

export const StyledCalculatorWrapper = styled.div`
	display: flex;
	align-items: center;

	text-align: center;

	margin: auto;
`;

export const StyledCalculatorButton = styled.button`
	width: 30px;
	height: 30px;

	border-radius: 50%;
	border: none;

	background-color: ${Colors.primary.new_primary};
`;

export const StyledCalculatorValueContainer = styled.div`
	display: flex;
	flex-direction: column;

	text-align: center;

	position: relative;

	margin: 0.75rem 1rem 0 1rem;
`;

export const StyledCalculatedValue = styled.span`
	font-size: 2rem;
	color: ${Colors.primary.new_primary};
`;

export const StyledCalculatedValueLabel = styled.span`
	font-size: 0.75rem;
	color: ${Colors.primary.new_primary};

	position: relative;
	margin: auto;
`;
