import styled from 'styled-components';

import { colors } from '../../utils';

export const StyledErrorMessage = styled.p`
	word-wrap: break-word;
	color: ${colors.warning};
	line-height: 1.7;
	font-size: 12px;
`;
