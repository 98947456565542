import { useEffect } from 'react';

function usePreventScroll(dependency) {
	useEffect(() => {
		// Store the current overflow style to restore it later
		const originalOverflow = document.body.style.overflow;

		// Set the overflow based on the dependency
		if (dependency) {
			document.body.style.overflow = 'hidden';
		}

		// Cleanup: restore the original overflow style on unmount or when dependency changes
		return () => {
			document.body.style.overflow = originalOverflow;
		};
	}, [dependency]);
}

export default usePreventScroll;
