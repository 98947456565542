import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TransparentButton } from '../../../../../../corev2/Buttons';
import PageHeading from '../../../../core/components/PageHeading';
import AuthCodeForm from '../../../components/AuthCodeForm';

import { useAuth } from '../../../../core/hooks/useAuth';
import { useOnboarding } from '../../../hooks/useOnboarding';
import { useRegisterUserMutation } from '../../../api/mutations/useRegisterUserMutation';

import { authRoutes } from '../../../routes/constants';

import { StyledAuthCodePage } from './styles';

const AuthCodePage = () => {
	const history = useHistory();

	const [serverError, setServerError] = useState('');

	const { onboardingState, mutateOnboardingState, resetOnboardingState } = useOnboarding();
	const { loginUser } = useAuth();
	const registerUserMutation = useRegisterUserMutation();

	const authCodeHandler = async (values) => {
		try {
			const { token, user } = await registerUserMutation.mutateAsync({
				email: onboardingState.accountInfo.data.email,
				password: onboardingState.accountInfo.data.password,
				phone: onboardingState.accountInfo.data.phone,
				firstName: onboardingState.personalInfo.data.firstName,
				lastName: onboardingState.personalInfo.data.lastName,
				displayName: onboardingState.personalInfo.data.displayName,
				authCode: onboardingState.authCode.data.authCode,
			});

			await loginUser(token, user);

			mutateOnboardingState('authCode', {
				...onboardingState.authCode,
				data: {
					code: values.code,
				},
				isCompleted: true,
			});

			resetOnboardingState();
		} catch (error) {
			setServerError(error[0].message);
			console.error('Auth code verification / Registration failed', error);
		}
	};

	const backButtonHandler = () => {
		history.push(authRoutes.onboarding.accountInfo);
	};

	return (
		<StyledAuthCodePage>
			<PageHeading>Auth Code</PageHeading>
			<AuthCodeForm
				initialValues={{
					authCode: onboardingState.authCode.data.authCode,
				}}
				authCodeHandler={authCodeHandler}
				serverError={serverError}
			/>

			<TransparentButton size='small' onClick={backButtonHandler}>
				Miss typed?
			</TransparentButton>
		</StyledAuthCodePage>
	);
};

export default AuthCodePage;
