import React, { useEffect, useState } from 'react';
import changeCase from 'change-case';

import { SubTitle, Container, Title, GridContainer, Item, Label, Input } from './CoreView';

const CareProRegistryStatusDataView = (props) => {
	const { documents_approval, registry_approval, platform_onboard } = props;
	return (
		<>
			<Title>CarePro Registry Status</Title>
			<GridContainer columns={3}>
				<Item>
					<Label>Documents Approval</Label>
					<Input>{documents_approval ? 'Approved' : 'Unapproved'}</Input>
				</Item>
				<Item>
					<Label>Registry Approval</Label>
					<Input>{registry_approval ? 'Approved' : 'Unapproved'}</Input>
				</Item>
				<Item>
					<Label>Platform Onboard</Label>
					<Input>{platform_onboard ? 'Approved' : 'Unapproved'}</Input>
				</Item>
			</GridContainer>
		</>
	);
};

export default CareProRegistryStatusDataView;
