import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { ColumnFlex, RowFlex, BackDropBg } from 'basicStyles';
import Colors from 'colors';

import {
	OnboardFormContainer,
	OnboardTitle,
	OnboardButton,
} from 'AppComp/provider/CareProOnboard/CareProOnboardViews';

const PrivacyPolicy = (props) => {
	return (
		<ColumnFlex
			fullWidth
			padding='16px'
			style={{
				backgroundColor: Colors.theme.primary_background,
				minHeight: 'calc(100vh - 120px)',
			}}
			alignCenter
			gap='32px'
		>
			<OnboardFormContainer center>
				<ColumnFlex
					style={{
						whiteSpace: 'pre-wrap',
						wordBreak: 'break-word',
						textAlign: 'left',
					}}
					padding='0 0 12px 0'
					margin='20px '
					center
					className='privacy-policy'
				>
					<p
						style={{
							marginBottom: '8pt',
							textAlign: 'center',
							fontSize: '11pt',
							backgroundColor: '#ffffff',
						}}
					>
						<strong>KPPD Privacy Notice</strong>
					</p>
					<p
						style={{
							marginBottom: '8pt',
							textAlign: 'justify',
							fontSize: '11pt',
							backgroundColor: '#ffffff',
						}}
					>
						<em>Last Updated/Effective Date:</em> 7/30/24
					</p>
					<p
						style={{
							marginBottom: '8pt',
							textAlign: 'justify',
							fontSize: '11pt',
							backgroundColor: '#ffffff',
						}}
					>
						KPPD Software Dev LLC and its affiliates and subsidiaries (“
						<strong>KPPD</strong>,” “<strong>we</strong>,” “<strong>us</strong>,” or “
						<strong>our</strong>”) respect your privacy. This Privacy Notice describes
						the processing of Personal Information (defined below) that is provided,
						collected, or disclosed in the course of providing our products or services
						to you, including our proprietary verification and matching platform and
						related services (“<strong>Services</strong>”). This Privacy Notice is also
						applicable to any other platforms that link to this Privacy Notice. It also
						describes rights you may have under applicable laws.
					</p>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>
						<a id='_Hlk116891433'>
							This Privacy Notice is available to consumers with disabilities. To
							access this Privacy Notice in an alternative downloadable format, please
							click{' '}
						</a>
						<a id='_cmntref1'>
							<span style={{ backgroundColor: '#ffff00' }}>here</span>
						</a>
						<a href='#_cmnt1' style={{ textDecoration: 'none' }}>
							<span className='CommentReference'>[P1]</span>
						</a>
						.
					</p>
					<p style={{ textAlign: 'justify', lineHeight: '16.5pt' }}>
						<strong>
							<span style={{ fontSize: '11pt' }}>1.</span>
						</strong>
						<span
							style={{
								width: '9.75pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<strong>
							<span style={{ fontSize: '11pt' }}>
								Personal Information We Collect
							</span>
						</strong>
					</p>
					<p style={{ textAlign: 'justify', lineHeight: '16.5pt' }}>
						<strong>
							<span style={{ fontSize: '11pt' }}>&#xa0;</span>
						</strong>
					</p>
					<p style={{ textAlign: 'justify', lineHeight: '13.5pt' }}>
						<span style={{ fontSize: '11pt' }}>
							We may collect a range of Personal Information. “
						</span>
						<strong>
							<span style={{ fontSize: '11pt' }}>Personal Information</span>
						</strong>
						<span style={{ fontSize: '11pt' }}>
							” means information that uniquely identifies, relates to, describes, or
							is reasonably capable of being associated with or linked to you.{' '}
						</span>
					</p>
					<p style={{ textAlign: 'justify', lineHeight: '13.5pt' }}>
						<span style={{ fontSize: '11pt' }}>&#xa0;</span>
					</p>
					<p style={{ textAlign: 'justify', lineHeight: '13.5pt' }}>
						<span style={{ fontSize: '11pt' }}>
							The types of Personal Information we collect may{' '}
						</span>
						<a id='_cmntref2'>
							<span style={{ fontSize: '11pt' }}>include</span>
						</a>
						<a href='#_cmnt2' style={{ textDecoration: 'none' }}>
							<span className='CommentReference'>[P2]</span>
						</a>
						<span style={{ fontSize: '11pt' }}>:</span>
						<span className='FootnoteReference' style={{ fontSize: '7.33pt' }}>
							{' '}
						</span>
					</p>
					<p style={{ textAlign: 'justify', lineHeight: '13.5pt' }}>
						<span style={{ fontSize: '11pt' }}>&#xa0;</span>
					</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='ListParagraph'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									Contact Information –
								</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								{' '}
								If you submit an inquiry, register for an account, or provide
								information on or through our Services, we may collect your contact
								information. This may include your name, address, email address,
								demographic information, and phone number.
							</span>
							<span style={{ fontFamily: "'Times New Roman'" }}>&#xa0; </span>
						</li>
					</ul>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>
						<strong>&#xa0;</strong>
					</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='ListParagraph'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									Application and Licensure Information
								</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								{' '}
								– In order for an agency or registry to verify your credentials and
								experience, we may collect your resume, photo, qualifications,
								assessments, vaccinations, and professional credentials/licensure,
								and any additional information that may be contained in background
								checks and drug screenings. We may collect certain information in
								order to verify your identity, such as your Social Security number
								and date of birth. We may also collect Personal Information about
								the job or shift you have accepted or declined.
							</span>
						</li>
					</ul>
					<p style={{ fontSize: '11pt' }}>&#xa0;</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='ListParagraph'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									Business Information
								</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								{' '}
								– If you are an individual using the Services on behalf of an agency
								or registry, we may collect information about your role within the
								agency or registry, including your name, title, and employer.
							</span>
						</li>
					</ul>
					{
						// where we left off
					}
					<p style={{ fontSize: '11pt' }}>&#xa0;</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='ListParagraph'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									Geolocation Data
								</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								{' '}
								– Once you have indicated that you have started a shift, geolocation
								data will be collected in order to verify your location.
							</span>
						</li>
					</ul>
					<p className='ListParagraph' style={{ fontSize: '11pt' }}>
						<em>&#xa0;</em>
					</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='ListParagraph'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>Preferences</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								{' '}
								– When using the Services, we may collect information related to
								your shifts, schedules, and availability.
							</span>
						</li>
					</ul>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>
						<em>&#xa0;</em>
					</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='ListParagraph'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									Banking Information
								</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								{' '}
								– In order for us to submit payments to you on behalf of an agency
								or registry, we may use third-party service providers who may
								collect your banking information, including the name of your bank
								and applicable account and routing numbers.{' '}
							</span>
						</li>
					</ul>
					<p className='ListParagraph' style={{ fontSize: '11pt' }}>
						<em>&#xa0;</em>
					</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='ListParagraph'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									Usage Information –
								</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								{' '}
								When you use the Services, we may automatically record information,
								including your Internet Protocol address (IP Address), geolocation
								of your device, browser type, referring URLs (e.g., the website you
								visited before coming to our website), domain names associated with
								your internet service provider, and any other information regarding
								your interaction with our website.
							</span>
						</li>
					</ul>
					<p style={{ textAlign: 'justify', lineHeight: '13.5pt' }}>
						<strong>
							<span style={{ fontSize: '11pt' }}>&#xa0;</span>
						</strong>
					</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='ListParagraph'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								lineHeight: '13.5pt',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									Communication Information –
								</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								{' '}
								We may collect Personal Information contained within your
								communications with us, including audio, electronic, or visual
								information, screen sharing views, and any files provided to us via
								email,{' '}
							</span>
							<a id='_cmntref3'>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									chat functionality
								</span>
							</a>
							<a href='#_cmnt3' style={{ textDecoration: 'none' }}>
								<span
									className='CommentReference'
									style={{ fontFamily: "'Times New Roman'" }}
								>
									[P3]
								</span>
							</a>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								, social media, telephone, or otherwise. Where permitted by
								applicable law, we may collect and maintain records of calls and
								chats with our agents, representatives, or employees via message,
								chat, post, or similar functionality.
							</span>
						</li>
					</ul>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>&#xa0;</p>
					<h1 style={{ fontSize: '11pt' }}>
						<strong>2.</strong>
						<span
							style={{
								width: '9.75pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<strong>How We Collect Personal Information </strong>
					</h1>
					<h1 style={{ marginLeft: '36pt', marginBottom: '0pt', fontSize: '11pt' }}>
						<span style={{ fontFamily: 'Symbol' }}></span>
						<span
							style={{
								width: '12.94pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<em>Directly From You –</em>
						<strong> </strong>We collect Personal Information that you provide to us
						<strong>, </strong>for example, if you choose to contact us, choose to be
						matched with an agency or registry through the Services, request information
						from us, or sign up to receive updates.
					</h1>
					<h1
						style={{
							marginLeft: '0pt',
							marginBottom: '0pt',
							textIndent: '0pt',
							fontSize: '11pt',
						}}
					>
						<strong>&#xa0;</strong>
					</h1>
					<h1 style={{ marginLeft: '36pt', marginBottom: '0pt', fontSize: '11pt' }}>
						<span style={{ fontFamily: 'Symbol' }}></span>
						<span
							style={{
								width: '12.94pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<em>From Third Parties –</em> We may collect Personal Information from third
						parties including, business partners, job sites, subcontractors, advertising
						networks, analytics providers, and search information providers, who may
						provide us with Personal Information about you.
					</h1>
					<h1
						style={{
							marginLeft: '0pt',
							marginBottom: '0pt',
							textIndent: '0pt',
							fontSize: '11pt',
						}}
					>
						&#xa0;
					</h1>
					<h1 style={{ marginLeft: '36pt', marginBottom: '0pt', fontSize: '11pt' }}>
						<span style={{ fontFamily: 'Symbol' }}></span>
						<span
							style={{
								width: '12.94pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<em>Through Online Tracking Technologies –</em> We use cookies and similar
						technologies to collect Personal Information related to activity on our
						website.&#xa0; For additional information regarding our use of these
						technologies, see the <em>Cookies and Tracking Technologies</em> section
						below.
					</h1>
					<h1 style={{ marginLeft: '22.5pt', marginBottom: '0pt', fontSize: '11pt' }}>
						&#xa0;
					</h1>
					<h1 style={{ fontSize: '11pt' }}>
						<strong>3.</strong>
						<span
							style={{
								width: '9.75pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<a id='_Hlk146531306'>
							<strong>How We </strong>
						</a>
						<a id='_cmntref4'>
							<strong>Use </strong>
						</a>
						<a href='#_cmnt4' style={{ textDecoration: 'none' }}>
							<span className='CommentReference'>[P4]</span>
						</a>
						<strong>Personal Information</strong>
					</h1>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>
						To the extent permitted by applicable law, we use Personal Information:
					</p>
					<p className='NoSpacing' style={{ textAlign: 'justify', fontSize: '11pt' }}>
						<strong>&#xa0;</strong>
					</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='NoSpacing'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									To provide and personalize our Services
								</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								, such as providing our Services, processing payments, providing
								customer service, maintaining or servicing accounts,
								verifying/validating your Personal Information, creating and
								maintaining business records, verifying eligibility, and undertaking
								or providing similar services.
							</span>
						</li>
					</ul>
					{
						// next section
					}
					<p className='NoSpacing' style={{ textAlign: 'justify', fontSize: '11pt' }}>
						<strong>&#xa0;</strong>
					</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='NoSpacing'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									For internal research and development,
								</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								{' '}
								such as testing and verifying the quality of our Services, improving
								the quality of our Services, and creating new Services.
							</span>
						</li>
					</ul>
					<p className='NoSpacing' style={{ textAlign: 'justify', fontSize: '11pt' }}>
						<strong>&#xa0;</strong>
					</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='NoSpacing'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									For marketing,
								</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								{' '}
								such as sending information about our Services, including using your
								information to send you messages, notices, newsletters, surveys,
								promotions, or news about events.
							</span>
						</li>
					</ul>
					<p className='NoSpacing' style={{ textAlign: 'justify', fontSize: '11pt' }}>
						<strong>&#xa0;</strong>
					</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='NoSpacing'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									For communicating with you,
								</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								{' '}
								such as responding to your questions and comments.
							</span>
						</li>
					</ul>
					<p className='NoSpacing' style={{ textAlign: 'justify', fontSize: '11pt' }}>
						<strong>&#xa0;</strong>
					</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='NoSpacing'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									For legal, security, or safety reasons,
								</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								{' '}
								such as protecting our and our users’ safety, property, or rights;
								complying with legal requirements; enforcing our terms, conditions,
								and policies; detecting, preventing, and responding to security
								incidents; and protecting against malicious, deceptive, fraudulent,
								or illegal activity.
							</span>
						</li>
					</ul>
					<p className='NoSpacing' style={{ textAlign: 'justify', fontSize: '11pt' }}>
						<strong>&#xa0;</strong>
					</p>
					<ul style={{ margin: '0pt', paddingLeft: '0pt' }}>
						<li
							className='NoSpacing'
							style={{
								marginLeft: '28.06pt',
								textAlign: 'justify',
								paddingLeft: '7.94pt',
								fontFamily: 'serif',
								fontSize: '11pt',
							}}
						>
							<em>
								<span style={{ fontFamily: "'Times New Roman'" }}>
									As part of a corporate transaction,
								</span>
							</em>
							<span style={{ fontFamily: "'Times New Roman'" }}>
								{' '}
								such as in connection with the sale of part or all of our assets or
								business, the acquisition of part or all of another business or
								another business’ assets, or another corporate transaction,
								including bankruptcy.
							</span>
						</li>
					</ul>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>&#xa0;</p>
					<h1 style={{ marginBottom: '0pt', fontSize: '11pt' }}>
						<strong>4.</strong>
						<span
							style={{
								width: '9.75pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<strong>How We Disclose Personal Information</strong>
					</h1>
					<h1 style={{ marginBottom: '0pt', textIndent: '0pt', fontSize: '11pt' }}>
						<strong>&#xa0;</strong>
					</h1>
					<h1 style={{ marginBottom: '0pt', fontSize: '11pt' }}>
						We may disclose your Personal Information in the following circumstances:
					</h1>
					<h1 style={{ marginBottom: '0pt', textIndent: '0pt', fontSize: '11pt' }}>
						<strong>&#xa0;</strong>
					</h1>
					<h1 style={{ marginLeft: '36pt', marginBottom: '0pt', fontSize: '11pt' }}>
						<span style={{ fontFamily: 'Symbol' }}></span>
						<span
							style={{
								width: '12.94pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<em>Service Providers –</em> We disclose your Personal Information with
						third party service providers as necessary to enable them to support our
						Services or other aspects of our business. This includes third parties that
						assist us with verifying your professional credentials/licensure, performing
						background checks and drug screens, and those that help us process
						payments.&#xa0;&#xa0;
					</h1>
					<h1
						style={{
							marginLeft: '0pt',
							marginBottom: '0pt',
							textIndent: '0pt',
							fontSize: '11pt',
						}}
					>
						<strong>&#xa0;</strong>
					</h1>
					<h1 style={{ marginLeft: '36pt', marginBottom: '0pt', fontSize: '11pt' }}>
						<span style={{ fontFamily: 'Symbol' }}></span>
						<span
							style={{
								width: '12.94pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<em>Agencies and Registries</em> – In order to provide our Services, we
						disclose Personal Information to agencies and registries in order to enable
						the matching process.
					</h1>
					<h1
						style={{
							marginLeft: '0pt',
							marginBottom: '0pt',
							textIndent: '0pt',
							fontSize: '11pt',
						}}
					>
						&#xa0;
					</h1>
					<h1 style={{ marginLeft: '36pt', marginBottom: '0pt', fontSize: '11pt' }}>
						<span style={{ fontFamily: 'Symbol' }}></span>
						<span
							style={{
								width: '12.94pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<em>Affiliates and Subsidiaries –</em> We may disclose your information to
						the KPPD family of companies for their and our business purposes and for
						marketing purposes, including to provide you information about the products
						or services we think may interest you<strong>.</strong>
					</h1>
					<h1
						style={{
							marginLeft: '0pt',
							marginBottom: '0pt',
							textIndent: '0pt',
							fontSize: '11pt',
						}}
					>
						<strong>&#xa0;</strong>
					</h1>
					<h1 style={{ marginLeft: '36pt', marginBottom: '0pt', fontSize: '11pt' }}>
						<span style={{ fontFamily: 'Symbol' }}></span>
						<span
							style={{
								width: '12.94pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<em>Legal Obligation or Safety Reasons –</em>
						<strong> </strong>We may disclose Personal Information to a third party when
						we have a good faith belief that such disclosure of Personal Information is
						reasonably necessary to (a) satisfy or comply with any requirement of law,
						regulation, legal process, or enforceable governmental request, (b) enforce
						or investigate a potential violation of any agreement you have with us, (c)
						detect, prevent, or otherwise respond to fraud, security or technical
						concerns, (d) support auditing and compliance functions, or (e) protect the
						rights, property, or safety of KPPD, its employees and clients, or the
						public against harm.
					</h1>
					<h1
						style={{
							marginLeft: '0pt',
							marginBottom: '0pt',
							textIndent: '0pt',
							fontSize: '11pt',
						}}
					>
						&#xa0;
					</h1>
					<h1 style={{ marginLeft: '36pt', marginBottom: '0pt', fontSize: '11pt' }}>
						<span style={{ fontFamily: 'Symbol' }}></span>
						<span
							style={{
								width: '12.94pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<em>Merger or Change of Control –</em>
						<strong> </strong>We may disclose Personal Information to third parties as
						necessary if we are involved in a merger, acquisition, or any other
						transaction involving a change of control in our business, including but not
						limited to, a bankruptcy or similar proceeding. Where legally required, we
						will give you prior notice prior to such disclosure.
					</h1>
					<h1
						style={{
							marginLeft: '36pt',
							marginBottom: '0pt',
							textIndent: '0pt',
							fontSize: '11pt',
						}}
					>
						&#xa0;
					</h1>
					<h1 style={{ fontSize: '11pt' }}>
						<strong>5.</strong>
						<span
							style={{
								width: '9.75pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<strong>Cookies and Other Tracking Technologies </strong>
					</h1>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>
						We and our service providers may use cookies and similar technologies to
						collect usage and browser information about how you use our website.&#xa0;
						We process the information collected through such technologies, which may
						include or be combined with Personal Information, to help operate certain
						features of our website, to enhance your experience through personalization,
						and to help us better understand the features of our website that you and
						other users are most interested in.&#xa0;
					</p>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>&#xa0;</p>
					<p style={{ textAlign: 'justify' }}>
						<span style={{ fontSize: '11pt' }}>
							We may allow or enable third parties to collect Personal Information in
							order to provide their{' '}
						</span>
						<a id='_cmntref5'>
							<span style={{ fontSize: '11pt' }}>
								interest-based advertising services
							</span>
						</a>
						<a href='#_cmnt5' style={{ textDecoration: 'none' }}>
							<span className='CommentReference'>[P5]</span>
						</a>
						<span style={{ fontSize: '11pt' }}>
							, and we may use Google Ads and other third parties to serve ads for our
							Services across various websites. To opt-out of this type of advertising
							by Google, to customize your ad preferences, or to limit Google’s
							collection or use of your data, visit{' '}
						</span>
						<a
							href='https://www.google.com/safetycenter/tools'
							style={{ textDecoration: 'none' }}
						>
							<u>
								<span style={{ fontSize: '11pt', color: '#000000' }}>
									Google’s Safety Center
								</span>
							</u>
						</a>
						<span style={{ fontSize: '11pt' }}> and </span>
						<a
							href='https://www.google.com/settings/ads'
							style={{ textDecoration: 'none' }}
						>
							<u>
								<span style={{ fontSize: '11pt', color: '#000000' }}>
									Google’s Ad Settings
								</span>
							</u>
						</a>
						<span style={{ fontSize: '11pt' }}> and follow </span>
						<a
							href='https://support.google.com/ads/answer/2662922?hl=en&amp;ref_topic=7048998'
							style={{ textDecoration: 'none' }}
						>
							<u>
								<span style={{ fontSize: '11pt', color: '#000000' }}>
									Google’s personalized ad opt-out instructions
								</span>
							</u>
						</a>
						<span style={{ fontSize: '11pt' }}>
							. Opting out will not affect your use of our Services.
						</span>
					</p>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>&#xa0;</p>
					<p style={{ textAlign: 'justify' }}>
						<span style={{ fontSize: '11pt' }}>
							We use analytics services, including Google Analytics, to assist us with
							analyzing our website traffic. These services utilize cookies and other
							technologies that collect your Personal Information.
						</span>
						<span style={{ fontSize: '11pt' }}>&#xa0; </span>
						<span style={{ fontSize: '11pt' }}>
							To learn more about how Google uses data, visit{' '}
						</span>
						<a
							href='https://www.google.com/policies/privacy'
							style={{ textDecoration: 'none' }}
						>
							<u>
								<span style={{ fontSize: '11pt', color: '#000000' }}>
									Google’s Privacy Policy
								</span>
							</u>
						</a>
						<span style={{ fontSize: '11pt' }}> and Google’s page on “</span>
						<a
							href='https://www.google.com/policies/privacy/partners'
							style={{ textDecoration: 'none' }}
						>
							<u>
								<span style={{ fontSize: '11pt', color: '#000000' }}>
									How Google uses data when you use our partners’ websites or apps
								</span>
							</u>
						</a>
						<span style={{ fontSize: '11pt' }}>.” You may download the </span>
						<a
							href='https://tools.google.com/dlpage/gaoptout'
							style={{ textDecoration: 'none' }}
						>
							<u>
								<span style={{ fontSize: '11pt', color: '#000000' }}>
									Google Analytics Opt-out Browser Add-on
								</span>
							</u>
						</a>
						<span style={{ fontSize: '11pt' }}>
							{' '}
							for each web browser you use, but this does not prevent the use of other
							analytics tools. To learn more about Google Analytics cookies, visit{' '}
						</span>
						<a
							href='https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage'
							style={{ textDecoration: 'none' }}
						>
							<u>
								<span style={{ fontSize: '11pt', color: '#000000' }}>
									Google Analytics Cookie Usage on Websites
								</span>
							</u>
						</a>
						<span style={{ fontSize: '11pt' }}>. </span>
					</p>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>&#xa0;</p>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>
						In order to manage your preferences with respect to these technologies, most
						browsers provide you with the ability to block, delete, or disable cookies,
						and your mobile device may allow you to disable transmission of unique
						identifiers and location data. If you choose to reject cookies or block
						device identifiers, some features of our Services may not be available, or
						some functionality may be limited or unavailable.&#xa0; Please review the
						help pages of your browser or mobile device for assistance with changing
						your settings. We do not respond to Do Not Track (“<strong>DNT</strong>”)
						signals sent to us by your browser at this time.
					</p>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>&#xa0;</p>
					{
						// even more next section
					}
					<h1 style={{ fontSize: '11pt' }}>
						<strong>6.</strong>
						<span
							style={{
								width: '9.75pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<strong>How Long We Keep Your Personal Information</strong>
					</h1>
					<p className='BodyText' style={{ fontSize: '11pt' }}>
						We will retain each category of your Personal Information for as long as
						necessary to fulfill the purposes described in this Privacy Notice, unless
						otherwise required by applicable laws. Criteria we will use to determine how
						long we will retain your Personal Information include whether we need your
						Personal Information to provide you with our Services you have requested; we
						continue to have a relationship with you; you have requested information or
						Services from us; we have a legal right or obligation to continue to retain
						your Personal Information; we have an obligation to a third party that
						involves your Personal Information; our retention or recordkeeping policies
						and obligations dictate that we retain your Personal Information; we have an
						interest in providing you with Personal Information about our Services; or
						we have another business purpose for retaining your Personal Information.
					</p>
					<h1 style={{ fontSize: '11pt' }}>
						<strong>7.</strong>
						<span
							style={{
								width: '9.75pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<strong>How We Protect Personal Information</strong>
					</h1>
					<p style={{ textAlign: 'justify', lineHeight: '13.5pt' }}>
						<span style={{ fontSize: '11pt' }}>
							We use commercially reasonable efforts to protect the confidentiality
							and security of Personal Information. However, despite these efforts to
							store Personal Information in a secure environment, we cannot guarantee
							the security of Personal Information during its transmission or its
							storage on our systems.
						</span>
					</p>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>&#xa0;</p>
					<h1 style={{ fontSize: '11pt' }}>
						<strong>8.</strong>
						<span
							style={{
								width: '9.75pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<strong>International Use of our Services</strong>
					</h1>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>
						Our website is hosted in the United States. If you are visiting our website
						from outside of the United States, please note that by providing your
						Personal Information, it is being transferred to, stored, collected, or
						processed in the United States, where our servers are located and operated.
					</p>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>&#xa0;</p>
					<p
						className='ListParagraph'
						style={{
							marginLeft: '18pt',
							textIndent: '-18pt',
							textAlign: 'justify',
							lineHeight: '16.5pt',
						}}
					>
						<strong>
							<span style={{ fontSize: '11pt' }}>9.</span>
						</strong>
						<span
							style={{
								width: '9.75pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;{' '}
						</span>
						<strong>
							<span style={{ fontSize: '11pt' }}>Children’s Privacy </span>
						</strong>
					</p>
					<p
						className='ListParagraph'
						style={{ marginLeft: '18pt', textAlign: 'justify', lineHeight: '16.5pt' }}
					>
						<strong>
							<span style={{ fontSize: '11pt' }}>&#xa0;</span>
						</strong>
					</p>
					<p className='BodyText' style={{ fontSize: '11pt' }}>
						We do not knowingly collect or solicit any Personal Information from
						children under the age of 13 without verified written parental consent. In
						the event that we learn that we have collected Personal Information from a
						child under the age of 13, we will promptly take steps to delete that
						information. If you are a parent or legal guardian and think your child has
						given us their Personal Information, you can email us at{' '}
						<a id='_Hlk170376081'>[</a>
						<a id='_cmntref6'>
							<span style={{ backgroundColor: '#ffff00' }}>email</span>
						</a>
						<a href='#_cmnt6' style={{ textDecoration: 'none' }}>
							<span className='CommentReference'>[P6]</span>
						</a>
						].
					</p>
					<p
						className='ListParagraph'
						style={{
							marginLeft: '18pt',
							textIndent: '-18pt',
							textAlign: 'justify',
							lineHeight: '16.5pt',
						}}
					>
						<strong>
							<span style={{ fontSize: '11pt' }}>10.</span>
						</strong>
						<span
							style={{
								width: '4.25pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;{' '}
						</span>
						<strong>
							<span style={{ fontSize: '11pt' }}>Links to Third-Party Websites</span>
						</strong>
					</p>
					<p style={{ textAlign: 'justify', lineHeight: '16.5pt' }}>
						<strong>
							<span style={{ fontSize: '11pt' }}>&#xa0;</span>
						</strong>
					</p>
					<p
						style={{
							marginBottom: '8pt',
							textAlign: 'justify',
							fontSize: '11pt',
							backgroundColor: '#ffffff',
						}}
					>
						We are not responsible for the practices employed by any websites or
						services linked to or from our Services, including the information or
						content contained within them. We encourage you to investigate and ask
						questions before disclosing Personal Information to third parties, since any
						Personal Information disclosed will be handled in accordance with the
						applicable third party’s privacy notice.
					</p>
					<p
						className='ListParagraph'
						style={{
							marginLeft: '18pt',
							textIndent: '-18pt',
							textAlign: 'justify',
							lineHeight: '13.5pt',
						}}
					>
						<span style={{ fontSize: '11pt' }}>
							<strong>11.</strong>
						</span>
						<span
							style={{
								width: '4.25pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;{' '}
						</span>
						<strong>
							<span style={{ fontSize: '11pt' }}>
								Your Privacy Rights and How to Exercise Them{' '}
							</span>
						</strong>
					</p>
					<p
						className='ListParagraph'
						style={{ marginLeft: '18pt', textAlign: 'justify', lineHeight: '13.5pt' }}
					>
						<span style={{ fontSize: '11pt' }}>&#xa0;</span>
					</p>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>
						<a id='_Hlk114221226'>
							Depending on where you live, you may have the right to exercise certain
							controls and choices regarding our collection, use, and disclosure of
							your Personal Information. If you believe you may the right to exercise
							certain controls and choices, please submit a request to [
							<span style={{ backgroundColor: '#ffff00' }}>email</span>]. Please note
							that we may need to verify your identity before considering a request
							and that we may deny certain requests, or only fulfill some in part, as
							permitted or required by law.
						</a>
					</p>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>&#xa0;</p>
					<p style={{ textAlign: 'justify', fontSize: '11pt' }}>
						To opt out of marketing emails, please email us at [
						<span style={{ backgroundColor: '#ffff00' }}>email</span>] or by following
						the instructions included in the email or text correspondence. Please note
						that, even if you unsubscribe from certain correspondence, we may still need
						to contact you with important transactional or administrative information,
						as permitted by law.&#xa0; Additionally, if you withdraw your consent or
						object to processing of your Personal Information, or if you choose not to
						provide certain Personal Information, we may be unable to provide some or
						all of our Services to you.<a id='_Hlk114140798'></a>
					</p>
					<p
						className='ListParagraph'
						style={{
							marginLeft: '18pt',
							textIndent: '-18pt',
							textAlign: 'justify',
							lineHeight: '16.5pt',
						}}
					>
						<strong>
							<span style={{ fontSize: '11pt' }}>12.</span>
						</strong>
						<span
							style={{
								width: '4.25pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;{' '}
						</span>
						<strong>
							<span style={{ fontSize: '11pt' }}>Changes to this Privacy Notice</span>
						</strong>
						<strong>
							<span style={{ fontSize: '11pt' }}>&#xa0; </span>
						</strong>
					</p>
					<p
						style={{
							marginTop: '15pt',
							marginBottom: '8pt',
							textAlign: 'justify',
							fontSize: '11pt',
							backgroundColor: '#ffffff',
						}}
					>
						Please note that we may modify or update this Privacy Notice from time to
						time, so please review it periodically. We will provide you notice if
						material changes are made. Unless otherwise indicated, any changes to this
						Privacy Notice will apply immediately upon posting to our websites.&#xa0;
					</p>
					<p
						className='ListParagraph'
						style={{
							marginLeft: '18pt',
							textIndent: '-18pt',
							textAlign: 'justify',
							lineHeight: '16.5pt',
						}}
					>
						<strong>
							<span style={{ fontSize: '11pt' }}>13.</span>
						</strong>
						<span
							style={{
								width: '4.25pt',
								font: "7pt 'Times New Roman'",
								display: 'inline-block',
							}}
						>
							&#xa0;&#xa0;{' '}
						</span>
						<strong>
							<span style={{ fontSize: '11pt' }}>Contact Us</span>
						</strong>
					</p>
					<p style={{ textAlign: 'justify', lineHeight: '16.5pt' }}>
						<span style={{ fontSize: '11pt' }}>&#xa0;</span>
					</p>
					<h1 style={{ marginLeft: '0pt', textIndent: '0pt', fontSize: '11pt' }}>
						If you have any questions about our practices or this Privacy Notice, please
						contact us at [
						<span style={{ backgroundColor: '#ffff00' }}>support@fyi.care</span>]. You
						may also write to us at
						<span style={{ backgroundColor: '#ffff00' }}>
							10400 Griffin Road, Suite #210, Cooper City, Florida 33328
						</span>
						.
					</h1>
					<h1 style={{ marginLeft: '0pt', textIndent: '0pt', fontSize: '11pt' }}>
						&#xa0;
					</h1>
					<div style={{ clear: 'both' }}>
						<p className='Footer'>
							<span style={{ width: '234pt', display: 'inline-block' }}>&#xa0;</span>
						</p>
					</div>
				</ColumnFlex>
			</OnboardFormContainer>
		</ColumnFlex>
	);
};

export default PrivacyPolicy;
