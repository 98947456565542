import React, { useState } from 'react';
import PageLoaderView from 'AppComp/PageLoaderView';

import {
	OnboardFormContainer,
	OnboardTitle,
	OnboardSubText,
	OnboardButton,
	OnboardFormButton,
	FormItemsLength,
} from '../CareProOnboardViews';
import { ColumnFlex } from 'basicStyles';

import { Formik, Form, Field } from 'formik';
import StyledTextInput, { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';
import Colors from 'colors';

import { validateFullSsn } from './validation';

//id_number

const ConfirmIdNumber = (props) => {
	const { updateProviderVerificationInfo, setIsLoading, isLoading } = props;

	const handleSubmit = (values, form) => {
		console.log('Personal - handleSubmit', values);
		setIsLoading(true);
		updateProviderVerificationInfo({
			id_number: values.id_number,
		});
	};

	return (
		<OnboardFormContainer
			style={{
				width: 'fit-content',
			}}
			center
		>
			<OnboardTitle>Please submit your full SSN</OnboardTitle>
			<OnboardSubText>
				Stripe could not verify your identity with the information provided. Please submit
				your full SSN to continue.
			</OnboardSubText>
			<Formik
				onSubmit={handleSubmit}
				validate={validateFullSsn}
				autoComplete={'off'}
				render={(formProps) => {
					console.log(formProps);
					return (
						<Form noValidate>
							<ColumnFlex padding='20px 0 0 0' alignCenter justifyCenter>
								<FormItemsLength>
									<Field
										name='id_number'
										type='password'
										component={StyledTextInput}
										label='SSN'
										backgroundColor={'white'}
									/>
								</FormItemsLength>
								{isLoading ? (
									<PageLoaderView errorMessage='Something went wrong plz contact dev monkeys' />
								) : (
									<OnboardFormButton
										backgroundColor={Colors.primary.primary}
										type='submit'
										valid={formProps.isValid}
									>
										Submit
									</OnboardFormButton>
								)}
							</ColumnFlex>
						</Form>
					);
				}}
			/>
		</OnboardFormContainer>
	);
};

export default ConfirmIdNumber;
