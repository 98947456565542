import styled from 'styled-components';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Colors from 'colors';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';

export const SectionDisplay = styled.div`
	background-color: white;
	display: flex;
	flex-direction: column;
	border-radius: 8px;
	padding: 20px;
	gap: 15px;
	@media (min-width: 700px) {
		padding: 40px;
	}
	margin: 0 auto 0 auto;
	width: 100%;
	max-width: 670px;
`;

export const InnerSectionDisplay = styled.div`
	border-radius: 10px;
	gap: 20px;
	width: 100%;
	height: fit-content;
	display: flex;
	flex-direction: column;
	@media (min-width: 700px) {
	}
`;

export const SubmitButtonCon = styled.button`
	background-color: ${Colors.primary.primary};
	color: white;
	border: none;
	padding: 15px 20px;
	border-radius: 24px;
	cursor: pointer;
	min-width: 200px;
`;

export const SubmitButton = ({ children, style, onClick, type }) => {
	return (
		<SubmitButtonCon type={type} onClick={onClick} style={style}>
			{children}
		</SubmitButtonCon>
	);
};

export const SubSubmitButtonCon = styled.button`
	background-color: ${Colors.primary.primary};
	color: white;
	border: none;
	padding: 10px 15px;
	border-radius: 24px;
	cursor: pointer;
	min-width: 200px;
`;

export const SubSubmitButton = ({ children, style, onClick, type }) => {
	return (
		<SubSubmitButtonCon type={type} onClick={onClick} style={style}>
			{children}
		</SubSubmitButtonCon>
	);
};
