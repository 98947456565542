import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import Text from 'AppComp/Text';
import { ColumnFlex } from 'basicStyles';
import Button from 'AppComp/Button';
import Title from 'AppComp/Title';
import Colors from 'colors';
import PageLoaderView from 'AppComp/PageLoaderView';

const FORM_ITEM_LENGTH = isMobile ? '320px' : '420px';

const TOS = ({ updateProviderVerificationInfo, setIsLoading, isLoading }) => {
	const handleClick = () => {
		// get the current time in unix

		const unixTime = Math.floor(Date.now() / 1000).toString();
		setIsLoading(true);
		updateProviderVerificationInfo({
			tos_acceptance_ip_date: unixTime,
		});
	};
	return (
		<ColumnFlex style={{ maxWidth: FORM_ITEM_LENGTH }} center>
			<Title color={'black'} bold subtitle>
				Terms of Service
			</Title>
			<ColumnFlex
				style={{
					//wrap all text
					whiteSpace: 'pre-wrap',
					wordBreak: 'break-word',
				}}
				padding='0 0 12px 0'
				margin='20px '
				center
				gap='12px 0'
			>
				<h2>1. Introduction&nbsp;</h2>
				<p
					style={{
						//wrap all text
						whiteSpace: 'pre-wrap',
						wordBreak: 'break-all',
					}}
				>
					Welcome to <strong>BOILERPLATE Inc. </strong>(“<strong>Company</strong>”, “
					<strong>we</strong>”, “<strong>our</strong>”, “<strong>us</strong>”)! Our
					mission is to provide secure, efficient and organized file collection and
					storage systems that delight customers and optimize business operations. Please
					grab a cup of coffee and carefully read the following pages. It will take around
					20 minutes.&nbsp;
				</p>
				<p>
					These Terms of Service (“<strong>Terms</strong>”, “
					<strong>Terms of Service</strong>”) govern your use and access to our websites
					located at
					<a href='https://boilerplate.co/'> https://boilerplate.co, </a>
					<a href='https://beta.boilerplate.co/'>https://beta.boilerplate.co, </a>
					<a href='https://app.boilerplate.co/'>https://app.boilerplate.co, </a>and any
					related sites operated by BOILERPLATE Inc. (“<strong>Services</strong>
					”).&nbsp; Our Privacy Policy governs your use of our Services and explains how
					we collect, safeguard and disclose information that results from your use of our
					websites. Please read it here https://boilerplate.co/privacy.&nbsp;
				</p>
				<p>
					Your agreement with us includes these Terms and our Privacy Policy (“
					<strong>Agreements</strong>”). By using our Services, you are agreeing to be
					bound by these Agreements. These Agreements apply to all visitors, users and
					others who wish to access or use our Services. If you do not agree with (or
					cannot comply with) the Agreements, then you may not use our Services. Please
					let us know by emailing at support@boilerplate.co so we can try to find a
					solution.&nbsp;
				</p>
				<h2>2. Content and Conduct</h2>
				<p>
					Our Services allow you to post, link, store, scan, share and otherwise make
					available certain information, text, forms, graphics, files, or other material
					(“<strong>Content</strong>”). When you use our Services, you provide us access
					to these Content. These Terms do not give us any rights to your Content except
					for the limited rights that enable us to offer our Services.&nbsp;
				</p>
				<p>
					By transmitting Content on or through our Services, you represent and warrant
					that: (i) Content is yours (you own it) and/or you have the right to use it and
					(ii) that the posting of your Content on or through Service does not violate the
					privacy rights, publicity rights, copyrights, contract rights or any other
					rights of any person or entity. We reserve the right to terminate the account of
					anyone found to be infringing on a copyright.&nbsp;
				</p>
				<p>
					Boilerplate does not review any user generated content on our site unless you
					give us consent to. You are responsible for ensuring all Content transmitted
					through our Services are not in violation of any laws and do not wrongfully
					infringe upon the intellectual property of others.&nbsp;
				</p>
				<p>
					Any Content submitted through Boilerplate is at the discretion of its users. If
					at any time a requestor or recipient of Boilerplate Services does not feel
					comfortable submitting content through Boilerplate, they have the right to
					coordinate an alternative means to submit the information outside of
					Boilerplate. Requestors of information and file completion through the
					Boilerplate platform shall not make Boilerplate the only option to submit
					requested files and information.
				</p>
				<p>
					You retain any and all of your rights to any Content you submit, post or
					transmit on or through Service and you are responsible for protecting those
					rights. We take no responsibility and assume no liability for Content you or any
					third party transmits or stores on or through Service.&nbsp;
				</p>
				<p>
					However, we need your permission to do things like hosting your Content, backing
					it up, and sharing it when you ask us to. Our Services also provide you with
					features like tracking, commenting, sharing, searching, OCR scanning, image
					thumbnails, document previews, sorting and organization, storage, and other
					customized functions to help reduce clutter and ensure quality. To provide these
					and other features, Boilerplate accesses and stores your Content. You hereby
					give us permission to do those things, and this permission extends to our
					affiliates and trusted third parties we work with.&nbsp;
				</p>
				<h2>3. Disclaimer</h2>
				<p>
					Boilerplate may occasionally provide self-service draft templates for client
					use. Our templates are content intended for private use only. They do not
					constitute legal advice. We do not review any information you provide us, nor do
					we offer any opinions, legal or otherwise, regarding the information you
					provide. Use of our website does not constitute an attorney-client
					relationship.&nbsp;
				</p>
				<h2>4. Your Responsibilities</h2>
				<p>
					Your use of our Services must comply with all applicable laws, including
					intellectual property laws, publicity laws, contract laws, export control laws
					and regulations. Content in the Services may be protected by others’
					intellectual property rights. Please do not copy, upload, download, or share
					content unless you have the right to do so. Boilerplate may review your conduct
					and content for compliance with these Terms. We are not responsible for the
					content people upload and share via our Services.&nbsp;
				</p>
				<p>
					You are responsible to back up your Content. Boilerplate is not responsible for
					any loss of data due to any failure to back up your Content.&nbsp;
				</p>
				<p>You must be at least 18 years of age to use our Services.&nbsp;</p>
				<h2>5. Account Registration&nbsp;</h2>
				<p>
					When you create an account with us, you guarantee that you are above the age of
					18, and that the information you provide us is accurate, complete, and current
					at all times. Inaccurate, incomplete, or obsolete information may result in the
					immediate termination of your account on Service.&nbsp;
				</p>
				<p>
					You are responsible for maintaining the confidentiality of your account and
					password, including but not limited to the restriction of access to your
					computer and/or account. You agree to accept responsibility for any and all
					activities or actions that occur under your account and/or password, whether
					your password is with our Service or a third-party service. You must notify us
					immediately upon becoming aware of any breach of security or unauthorized use of
					your account.&nbsp;
				</p>
				<p>
					You may not use as a username the name of another person or entity or that is
					not lawfully available for use, a name or trademark that is subject to any
					rights of another person or entity other than you, without appropriate
					authorization. You may not use as a username any name that is offensive, vulgar
					or obscene.&nbsp;
				</p>
				<p>
					We reserve the right to refuse service, terminate accounts, remove or edit
					content, or cancel orders in our sole discretion.&nbsp;
				</p>
				<h2>6. Authorized Users&nbsp;</h2>
				<p>
					In connection with use of your Account, you may authorize others (“Authorized
					User(s)”) to use the Services on your behalf. Each Authorized User will create
					and make use of a username and password. You are responsible for keeping the
					username and password confidential and ensure that they are not disclosed to any
					third party. You are responsible for any act or omission of your Authorized
					Users. Boilerplate is not responsible for activities that occur under your
					Account using your username and password, including any loss or deletion of your
					Content. You acknowledge that your Authorized Users have full access and
					management privileges of your Account(s), any personal data associated with your
					Account, and your Content.&nbsp;
				</p>
				<p>
					Boilerplate reserves the right to terminate or suspend your Account, or the
					access of any Authorized User, for any reason, including if any registration
					information is inaccurate, untrue or incomplete, or if any Authorized User fails
					to maintain the security of any access credentials.&nbsp;
				</p>
				<h2>7. Communications&nbsp;</h2>
				<p>
					By creating an Account to use our Services, you agree to subscribe to
					newsletters, marketing or promotional materials and other information we may
					send. However, you may opt out of receiving any, or all, of these communications
					from us by following the unsubscribe link or by emailing at
					unsubscribe@boilerplate.co.&nbsp;
				</p>
				<h2>8. Subscriptions&nbsp;</h2>
				<p>
					Our Services are billed on a subscription basis (“
					<strong>Subscription(s)</strong>”). You will be billed in advance on a recurring
					and periodic basis (“<strong>Billing Cycle</strong>”). Billing cycles are set
					either on a monthly or annual basis, depending on the type of subscription plan
					you select when purchasing a Subscription. There may also be additional charges,
					including set up and maintenance fees, custom services, storage, and usage-based
					fees.&nbsp;
				</p>
				<p>
					At the end of each Billing Cycle, your Subscription will automatically renew
					under the exact same conditions unless you cancel it or BOILERPLATE Inc. cancels
					it. You may cancel your Subscription renewal either through your online account
					management page or by contacting BOILERPLATE Inc. customer support team.&nbsp;
				</p>
				<p>
					A valid payment method, including credit card or PayPal, is required to process
					the payment for your subscription. You shall provide BOILERPLATE Inc. with
					accurate and complete billing information including full name, address, state,
					zip code, telephone number, and a valid payment method information. By
					submitting such payment information, you automatically authorize BOILERPLATE
					Inc. to charge all Subscription fees incurred through your account to any such
					payment instruments.&nbsp;
				</p>
				<p>
					Should automatic billing fail to occur for any reason, BOILERPLATE Inc. will
					issue an electronic invoice indicating that you must proceed manually, within a
					certain deadline date, with the full payment corresponding to the billing period
					as indicated on the invoice.&nbsp;
				</p>
				<h2>9. Enterprise Accounts&nbsp;</h2>
				<p>
					If you purchase and register an account under our Enterprise Subscription
					option, you must use your Account in compliance with your organization’s terms
					and policies. Boilerplate Enterprise accounts establish a business relationship
					between Boilerplate and your organization, and thus are subject to your
					organization’s control. Your administrator may be able to access, disclose,
					restrict, or remove information in or from your Enterprise account. They may
					also be able to restrict or terminate your Enterprise access to the Enterprise
					account. If you plan to convert an existing personal account to an Enterprise
					Account, please contact support.&nbsp;
				</p>
				<h2>10. Free Trial&nbsp;</h2>
				<p>
					BOILERPLATE Inc. may, at its sole discretion, offer a Subscription with a free
					trial for a limited period of time or limited amount of system activity (“
					<strong>Free Trial</strong>”).&nbsp;
				</p>
				<p>
					You may be required to enter your billing information in order to sign up for
					Free Trial.&nbsp;
				</p>
				<p>
					If you enter your billing information when signing up for Free Trial, you will
					not be charged by BOILERPLATE Inc. until Free Trial has expired. On the last day
					of Free Trial period, unless you cancelled your Subscription, you will be
					automatically charged the applicable Subscription fees for the type of
					Subscription you have selected.&nbsp;
				</p>
				<p>
					At any time and without notice, BOILERPLATE Inc. reserves the right to (i)
					modify Terms of Service of Free Trial offer, or (ii) cancel such Free Trial
					offer. After the trial expires, if you do not select a paid plan, access to
					files and information stored in our system may be terminated.
				</p>
				<h2>11. Fee Changes&nbsp;</h2>
				<p>
					BOILERPLATE Inc., in its sole discretion and at any time, may modify
					Subscription fees and usage allowances for the Subscriptions. Any Subscription
					fee change will become effective at the end of the then-current Billing
					Cycle.&nbsp;
				</p>
				<p>
					BOILERPLATE Inc. will provide you with a reasonable prior notice of any change
					in Subscription fees to give you an opportunity to terminate your Subscription
					before such change becomes effective.&nbsp;
				</p>
				<p>
					Your continued use of Service after Subscription fee change comes into effect
					constitutes your agreement to pay the modified Subscription fee amount.&nbsp;
				</p>
				<h2>12. Refunds&nbsp;</h2>
				<p>
					You may cancel your Subscription at any time. Refunds are only issued if you
					cancel your subscription within 14 days of signing up for, upgrading to, or
					renewing a Subscription.&nbsp;
				</p>
				<h2>13. Contests, Sweepstakes and Promotions&nbsp;</h2>
				<p>
					Any contests, sweepstakes or other promotions (collectively, “
					<strong>Promotions</strong>”) made available through Service may be governed by
					rules that are separate from these Terms of Service. If you participate in any
					Promotions, please review the applicable rules as well as our Privacy Policy. If
					the rules for a Promotion conflict with these Terms of Service, Promotion rules
					will apply.&nbsp;
				</p>
				<h2>14. Prohibited Uses&nbsp;</h2>
				<p>
					You agree to not misuse our Service or help anyone else do so. You may use our
					Services only for lawful purposes and in accordance with these Terms. For
					example, the following uses are strictly prohibited:&nbsp;
				</p>
				<p>
					(a) In any way that violates any applicable national or international law or
					regulation.&nbsp;
				</p>
				<p>
					(b) For the purpose of exploiting, harming, or attempting to exploit or harm
					minors in any way by exposing them to inappropriate content or otherwise.&nbsp;
				</p>
				<p>
					(c) To transmit, or procure the sending of, any advertising or promotional
					material, including any “junk mail”, “chain letter,” “spam,” or any other
					similar solicitation.&nbsp;
				</p>
				<p>
					(d) To impersonate or attempt to impersonate Company, a Company employee,
					another user, or any other person or entity.&nbsp;
				</p>
				<p>
					(e) In any way that infringes upon the rights of others, or in any way is
					illegal, threatening, fraudulent, or harmful, or in connection with any
					unlawful, illegal, fraudulent, or harmful purpose or activity.&nbsp;
				</p>
				<p>
					(f) To engage in any other conduct that restricts or inhibits anyone’s use or
					enjoyment of Service, or which, as determined by us, may harm or offend Company
					or users of Service or expose them to liability.&nbsp;
				</p>
				<p>Additionally, you agree not to:&nbsp;</p>
				<p>
					(a) Use Service in any manner that could disable, overburden, damage, or impair
					Service or interfere with any other party’s use of Service, including their
					ability to engage in real time activities through Service.&nbsp;
				</p>
				<p>
					(b) Use any robot, spider, or other automatic device, process, or means to
					access Service for any purpose, including monitoring or copying any of the
					material on Service.&nbsp;
				</p>
				<p>
					(c) Use any manual process to monitor or copy any of the material on our
					Services or for any other unauthorized purpose without our prior written
					consent.&nbsp;
				</p>
				<p>
					(d) Use any device, software, or routine that interferes with the proper working
					of Service.&nbsp;
				</p>
				<p>
					(e) Introduce any viruses, trojan horses, worms, logic bombs, or other material
					which is malicious or technologically harmful.&nbsp;
				</p>
				<p>
					(f) Attempt to gain unauthorized access to, interfere with, damage, or disrupt
					any parts of Service, the server on which Service is stored, or any server,
					computer, or database connected to Service.&nbsp;
				</p>
				<p>
					(g) Attack Service via a denial-of-service attack or a distributed
					denial-of-service attack.&nbsp;
				</p>
				<p>(h) Take any action that may damage or falsify Company rating.&nbsp;</p>
				<p>(i) Probe, scan, or test the vulnerability of our system or network.&nbsp;</p>
				<p>
					(j) Breach or otherwise circumvent any security or authentication
					measures.&nbsp;
				</p>
				<p>
					(k) Otherwise attempt to interfere with or reverse engineering the proper
					working of Service.&nbsp;
				</p>
				<h2>15. Analytics&nbsp;</h2>
				<p>
					We may use third-party Service Providers to monitor and analyze the use of our
					Service. A list of some of our third-party Service Provides is as follows:&nbsp;
				</p>
				<h3>Google Analytics&nbsp;</h3>
				<p>
					Google Analytics is a web analytics service offered by Google that tracks and
					reports website traffic. Google uses the data collected to track and monitor the
					use of our Service. This data is shared with other Google services. Google may
					use the collected data to contextualise and personalise the ads of its own
					advertising network.&nbsp;
				</p>
				<p>
					For more information on the privacy practices of Google, please visit the Google
					Privacy Terms web page:
					<a href='https://policies.google.com/privacy?hl=en'>
						{' '}
						https://policies.google.com/privacy?hl=en&nbsp;
					</a>
				</p>
				<p>
					We also encourage you to review the Google's policy for safeguarding your
					data:&nbsp;
				</p>
				<p>
					<a href='https://support.google.com/analytics/answer/6004245'>
						https://support.google.com/analytics/answer/6004245.&nbsp;
					</a>
				</p>
				<p>
					<strong>Firebase&nbsp;</strong>
				</p>
				<p>Firebase is an analytics service provided by Google Inc.&nbsp;</p>
				<p>
					You may opt-out of certain Firebase features through your mobile device
					settings, such as your device advertising settings or by following the
					instructions provided by Google in their Privacy Policy:{' '}
					<a href='https://policies.google.com/privacy?hl=en'>
						https://policies.google.com/privacy?hl=en&nbsp;
					</a>
				</p>
				<p>
					For more information on what type of information Firebase collects, please visit
					the Google Privacy Terms web page:{' '}
					<a href='https://policies.google.com/privacy?hl=en'>
						https://policies.google.com/privacy?hl=en&nbsp;
					</a>
				</p>
				<h3>Mixpanel&nbsp;</h3>
				<p>Mixpanel is provided by Mixpanel Inc.&nbsp;</p>
				<p>
					You can prevent Mixpanel from using your information for analytics purposes by
					opting-out.&nbsp;
				</p>
				<p>
					To opt-out of Mixpanel service, please visit this page:{' '}
					<a href='https://mixpanel.com/optout/'>https://mixpanel.com/optout/&nbsp;</a>
				</p>
				<p>
					For more information on what type of information Mixpanel collects, please visit
					the Terms of Use page of Mixpanel:
					<a href='https://mixpanel.com/terms/'> https://mixpanel.com/terms/&nbsp;</a>
				</p>
				<h2>
					<strong>16. No Use By Minors&nbsp;</strong>
				</h2>
				<p>
					Service is intended only for access and use by individuals at least eighteen
					(18) years old. By accessing or using any of the Company, you warrant and
					represent that you are at least eighteen (18) years of age and with the full
					authority, right, and capacity to enter into this agreement and abide by all of
					the terms and conditions of Terms. If you are not at least eighteen (18) years
					old, you are prohibited from both the access and usage of Service.&nbsp;
				</p>
				<h2>17. Intellectual Property&nbsp;</h2>
				<p>
					Service and its original content (excluding Content provided by users), features
					and functionality are and will remain the exclusive property of BOILERPLATE Inc.
					and its licensors. Service is protected by copyright, trademark, and other laws
					of the United States and foreign countries. Our trademarks and trade dress may
					not be used in connection with any product or service without the prior written
					consent of BOILERPLATE Inc.&nbsp;
				</p>
				<h2>18. Copyright Policy&nbsp;</h2>
				<p>
					We respect the intellectual property rights of others. It is our policy to
					respond to any claim that Content posted on Service infringes on the copyright
					or other intellectual property rights (“
					<strong>Infringement</strong>”) of any person or entity.&nbsp;
				</p>
				<p>
					If you are a copyright owner, or authorized on behalf of one, and you believe
					that the copyrighted work has been copied in a way that constitutes copyright
					infringement, please submit your claim via email to legal@boilerplate.co, with
					the subject line: “Copyright Infringement” and include in your claim a detailed
					description of the alleged Infringement as detailed below, under “DMCA Notice
					and Procedure for Copyright Infringement Claims”&nbsp;
				</p>
				<p>
					You may be held accountable for damages (including costs and attorneys' fees)
					for misrepresentation or bad-faith claims on the infringement of any Content
					found on and/or through Service on your copyright.&nbsp;
				</p>
				<h2>
					<strong>19. DMCA Notice and Procedure for Copyright Infringement Claims</strong>
					&nbsp;
				</h2>
				<p>
					Boilerplate respects the intellectual property of our users and expects you to
					do the same. In accordance with with Digital Millennium Copyright Act of 1998,
					available at{' '}
					<a href='http://www.copyright.gov/legislation/dmca.pdf'>
						http://www.copyright.gov/legislation/dmca.pdf
					</a>
					, Boilerplate will respond expeditiously to claims of copyright infringement
					committed using Boilerplate Services if such claims are reported to
					Boilerplate’s Designated Copyright Agent.&nbsp;
				</p>
				<p>
					If you are a copyright owner, you may submit a notification pursuant to the
					Digital Millennium Copyright Act (DMCA) by providing our designated Copyright
					Agent with the following information in writing (see 17 U.S.C 512(c)(3) for
					further detail):&nbsp;
				</p>
				<p>
					(a) an electronic or physical signature of the person authorized to act on
					behalf of the owner of the copyright's interest;&nbsp;
				</p>
				<p>
					(b) a description of the copyrighted work that you claim has been infringed,
					including the URL&nbsp;
				</p>
				<p>
					(i.e., web page address) of the location where the copyrighted work exists or a
					copy of the copyrighted work;&nbsp;
				</p>
				<p>
					(c) identification of the URL or other specific location on Service where the
					material that you claim is infringing is located;&nbsp;
				</p>
				<p>
					(d) your address, telephone number, and email address; (e) a statement by you
					that you have a good faith belief that the disputed use is not authorized by the
					copyright owner, its agent, or the law;&nbsp;
				</p>
				<p>
					(f) a statement by you, made under penalty of perjury, that the above
					information in your notice is accurate and that you are the copyright owner or
					authorized to act on the copyright owner's behalf.&nbsp;
				</p>
				<p>You can contact our Copyright Agent via email at legal@boilerplate.co&nbsp;</p>
				<h2>20. Error Reporting and Feedback&nbsp;</h2>
				<p>
					You may provide us directly at support@boilerplate.co with information and
					feedback concerning errors, suggestions for improvements, ideas, problems,
					complaints, and other matters related to our Service (“
					<strong>Feedback</strong>”). You acknowledge and agree that: (i) you shall not
					retain, acquire or assert any intellectual property right or other right, title
					or interest in or to the Feedback; (ii) Company may have development ideas
					similar to the Feedback; (iii) Feedback does not contain confidential
					information or proprietary information from you or any third party; and (iv)
					Company is not under any obligation of confidentiality with respect to the
					Feedback. In the event the transfer of the ownership to the Feedback is not
					possible due to applicable mandatory laws, you grant Company and its affiliates
					an exclusive, transferable, irrevocable, free-of-charge, sub-licensable,
					unlimited and perpetual right to use (including copy, modify, create derivative
					works, publish, distribute and commercialize) Feedback in any manner and for any
					purpose.&nbsp;
				</p>
				<h2>21. Links To Other Websites&nbsp;</h2>
				<p>
					Our Services may contain links to third party web sites or services that are not
					owned or controlled by Boilerplate Inc.&nbsp;
				</p>
				<p>
					Boilerplate Inc. has no control over, and assumes no responsibility for the
					content, privacy policies, or practices of any third party web sites or
					services. We do not warrant the offerings of any of these entities/individuals
					or their websites.&nbsp;
				</p>
				<p>
					YOU ACKNOWLEDGE AND AGREE THAT BOILERPLATE INC. SHALL NOT BE RESPONSIBLE OR
					LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE
					CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR
					SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR
					SERVICES.&nbsp;
				</p>
				<p>
					WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY
					THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.&nbsp;
				</p>
				<h2>22. Disclaimer Of Warranty&nbsp;</h2>
				<p>
					THESE SERVICES ARE PROVIDED BY BOILERPLATE INC. ON AN “AS IS” AND “AS AVAILABLE”
					BASIS. BOILERPLATE INC. MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND,
					EXPRESS OR IMPLIED, AS TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION,
					CONTENT OR MATERIALS INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF
					THESE SERVICES, THEIR CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT
					YOUR SOLE RISK.&nbsp;
				</p>
				<p>
					NEITHER BOILERPLATE INC. NOR ANY PERSON ASSOCIATED WITH IT MAKES ANY WARRANTY OR
					REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY,
					ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING,
					NEITHER COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT
					THE SERVICES, THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
					SERVICES WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS
					WILL BE CORRECTED, THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE
					FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES
					OR ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR
					EXPECTATIONS.&nbsp;
				</p>
				<p>
					COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
					STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
					MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.&nbsp;
				</p>
				<p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE&nbsp;</p>
				<p>EXCLUDED OR LIMITED UNDER APPLICABLE LAW.&nbsp;</p>
				<h2>23. Limitation Of Liability&nbsp;</h2>
				<p>
					EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS,
					EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL,
					OR CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS' FEES AND ALL
					RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON
					APPEAL, IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER
					IN AN ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT
					OF OR IN CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM
					FOR PERSONAL INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY
					VIOLATION BY YOU OF ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR
					REGULATIONS, EVEN IF COMPANY HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF
					SUCH DAMAGE. EXCEPT AS PROHIBITED BY LAW, IF THERE IS LIABILITY FOUND ON THE
					PART OF COMPANY, IT WILL BE LIMITED TO THE AMOUNT PAID FOR THE PRODUCTS AND/OR
					SERVICES, AND UNDER NO CIRCUMSTANCES WILL THERE BE CONSEQUENTIAL OR PUNITIVE
					DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF PUNITIVE,
					INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR LIMITATION OR EXCLUSION MAY
					NOT APPLY TO YOU.
				</p>
				<h2>24. Termination&nbsp;</h2>
				<p>
					We may terminate or suspend your account and bar access to our Services
					immediately, without prior notice or liability, under our sole discretion, for
					any reason whatsoever and without limitation, including but not limited to a
					breach of Terms.&nbsp;
				</p>
				<p>
					If you wish to terminate your account, you may simply discontinue using our
					Services.&nbsp;
				</p>
				<p>
					All provisions of Terms which by their nature should survive termination shall
					survive termination, including, without limitation, ownership provisions,
					warranty disclaimers, indemnity and limitations of liability. Termination of an
					account may result in losing access to files and information stored on our
					system.&nbsp;
				</p>
				<h2>25. User Data&nbsp;</h2>
				<p>
					Boilerplate will maintain certain data that you transmit to our websites for the
					purpose of managing the performance of our Services, as well as data relating to
					your use of the websites. Although we perform regular backups of data, you are
					solely responsible for all data that you transmit or that relates to any
					activity you have undertaken using our Services. Boilerplate has no liability to
					you for any loss or corruption of any such data, and you hereby waive any right
					of action against us arising from any such loss or corruption of data.&nbsp;
				</p>
				<h2>26. Force Majeure and Discontinuation of Services&nbsp;</h2>
				<p>
					We might decide to discontinue our Services in response to unforeseen
					circumstances beyond Boilerplate’s control. Any disruption or discontinuation of
					Services will not be considered a breach of this Agreement if such disruption or
					discontinuation is caused by natural disasters, epidemics, pandemics and
					quarantines, war, terrorist attacks and other instances of violence, cyber
					attacks such as DoS, DDoS, MitM, SQL injection, phishing attacks, or any other
					malicious introduction of viruses and disabling devices caused by third parties,
					actions taken by government authorities such as changes in laws, regulations, or
					orders, strikes and work slow-downs, shortages of power, supplies,
					infrastructure or transportation, and any acts beyond Boilerplate’s reasonable
					control.
				</p>
				<h2>27. Dispute Resolution</h2>
				<p>
					Let’s address your concerns without a formal legal case first. You hereby agree
					to try to resolve any legal dispute informally with Boilerplate before
					commencing a formal legal claim against Boilerplate. You may reach us at{' '}
					<a href='mailto:legal@boilerplate.co'>legal@boilerplate.co</a>. We will try to
					resolve the dispute informally by contacting you via email.&nbsp;
				</p>
				<h2>28. Governing Law&nbsp;</h2>
				<p>
					These Terms shall be governed and construed in accordance with the laws of State
					of Delaware without regard to its conflict of law provisions. However, some
					countries have laws that require agreements to be governed by local laws of the
					user domicile. This paragraph does not override those laws.&nbsp;
				</p>
				<h2>29. Changes To Service&nbsp;</h2>
				<p>
					We reserve the right to withdraw or amend our Service, and any service or
					material we provide via Service, in our sole discretion without notice. We will
					not be liable if for any reason all or any part of Service is unavailable at any
					time or for any period. From time to time, we may restrict access to some parts
					of Service, or the entire Service, to users, including registered users.&nbsp;
				</p>
				<h2>30. Amendments To Terms&nbsp;</h2>
				<p>
					We may amend Terms at any time by posting the amended terms on this site. It is
					your responsibility to review these Terms periodically.&nbsp;
				</p>
				<p>
					Your continued use of the Platform following the posting of revised Terms means
					that you accept and agree to the changes. You are expected to check this page
					frequently so you are aware of any changes, as they are binding on you.&nbsp;
				</p>
				<p>
					By continuing to access or use our Service after any revisions become effective,
					you agree to be bound by the revised terms. If you do not agree to the new
					terms, you are no longer authorized to use Service.&nbsp;
				</p>
				<h2>31. Waiver And Severability&nbsp;</h2>
				<p>
					No waiver by Company of any term or condition set forth in Terms shall be deemed
					a further or continuing waiver of such term or condition or a waiver of any
					other term or condition, and any failure of Company to assert a right or
					provision under Terms shall not constitute a waiver of such right or
					provision.&nbsp;
				</p>
				<p>
					If any provision of Terms is held by a court or other tribunal of competent
					jurisdiction to be invalid, illegal or unenforceable for any reason, such
					provision shall be eliminated or limited to the minimum extent such that the
					remaining provisions of Terms will continue in full force and effect.&nbsp;
				</p>
				<h2>32. Acknowledgement&nbsp;</h2>
				<p>
					BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE
					READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
				</p>
				<h2>33. Contact Us&nbsp;</h2>
				<p>
					Please send your feedback, comments, requests for technical support: By email:
					useragreement@boilerplate.co.
				</p>
			</ColumnFlex>
			{isLoading ? (
				<PageLoaderView errorMessage='Something went wrong plz contact dev monkeys' />
			) : (
				<Button
					margin='40px 8px 0 0px'
					onClick={() => handleClick()}
					backgroundColor={Colors.primary.primary}
					borderRadius={'24px'}
					width={'240px'}
					height={isMobile ? '40px' : '60px'}
				>
					<Text
						style={{
							textAlign: 'center',
						}}
						bold
						color={'white'}
						midLarge
					>
						I Agree
					</Text>
				</Button>
			)}
		</ColumnFlex>
	);
};

export default TOS;
