import React, { Component, Fragment } from 'react';
import { Hearts } from 'react-loader-spinner';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';

import { ColumnFlex } from 'basicStyles';
import Color from 'colors';

import Text from './Text';

class PageLoaderChildView extends Component {
	state = {
		showError: false,
	};

	componentDidMount() {
		const { timeOut } = this.props;

		this.loaderTimer = setTimeout(() => this.toggleStateErr(), timeOut);
	}

	componentWillUnmount() {
		if (this.loaderTimer) {
			clearTimeout(this.loaderTimer);
		}
	}

	toggleStateErr = () => {
		this.setState({
			showError: true,
		});
	};

	render() {
		const { showError } = this.state;

		const { children, height } = this.props;

		return (
			<ColumnFlex
				style={{
					height: height || '100vh',
				}}
				fullHeight
				flex
				fullWidth
				center
			>
				{showError ? children : <Hearts color={Color.blue} height={100} width={100} />}
			</ColumnFlex>
		);
	}
}

PageLoaderChildView.defaultProps = {
	timeOut: 500,
};

PageLoaderChildView.propTypes = {
	children: PropTypes.element.isRequired,
	timeOut: PropTypes.number,
};
export default withTheme(PageLoaderChildView);

/**
v: 0.0.2
@brgarciarivas
PageLoaderChildView takes a mandatory react element as a child to render when you want to show sonething after 5s has passed once this componet was PageLoaderChildView
The motovation behind making this component was to show the onboard overlay after 5s which was a decent estimate on max time it would take to check the session of user
*/
