import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { STRIPE_STATUS_MODEL, CARE_PRO_REGISTRY_STATUS_MODEL } from '../../../core/api/models';

const updateCareProStripeInformation = async (values) => {
	try {
		const data = await api.graph({
			query: `mutation {
				updateProviderStripeInformation(
					updateValues: ${api.graphStringify(values)},
				    ) {
            		stripeStatus {
              			${STRIPE_STATUS_MODEL}
            		}
            		careProRegistryStatus {
              			${CARE_PRO_REGISTRY_STATUS_MODEL}
            		}
				}
			}`,
		});

		return data.updateProviderStripeInformation;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useUpdateCareProStripeInformationMutation = () => {
	return useMutation({
		mutationKey: ['updateCareProStripeInformation'],
		mutationFn: (values) => {
			return updateCareProStripeInformation(values);
		},
	});
};
