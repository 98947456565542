import styled from 'styled-components';

export const StyledCareNeedsPage = styled.div`
	display: flex;
	flex-direction: column;
`;

export const StyledButtonContainer = styled.div`
	display: flex;

	flex-direction: column;
	margin-top: 2rem;
`;
