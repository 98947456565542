/* 
  this file is a bit more complicatd than the default document handler since we need to update the parent onbaord component
  
  - the only way the user can pass this screen and move forward to the registry onboarding
  - - they hit the skip button and we update the care plan model 
  - - they submit all the required documents
  - - - this should only be called once, we can determine from checking if all the doc are at least attempted to be uplaoded
  - - - we check if the completed_platform_onboard is fale or null and if so update it
*/

import React, { useState, Fragment, useEffect } from 'react';

import Document from '../Documents';

import Text from 'AppComp/Text';
import api from 'scripts/api';
import { toast } from 'react-toastify';
import { TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG } from 'constants';

import { CARE_NEEDS_MODEL, CAREPLAN_MODEL_V2 } from 'reducers/graphqlModelTypes';
import OnboardSubNav from './OnboardSubnav';

// this component will only handle updating the progress of the nav bar based
const DocumentOnboard = (props) => {
	const {
		uploadedDocuments,
		clientDocumentRequirements,
		receivePatientCarePlan,
		carePlan,
		handleUpdateSectionProgress,
	} = props;

	const [documentSubNav, setDocumentSubNav] = useState([]);
	useEffect(() => {
		// check if all the required documeents have been uploaded
		// does not matter if they have been approved

		calculateSubNavTilesStatus();

		shouldUpdateCarePlan();
	}, [uploadedDocuments, clientDocumentRequirements]);

	const shouldUpdateCarePlan = () => {
		// check if all the required documents have been uploaded
		// if so we can update the care plan
		// if not we do nothing
		if (clientDocumentRequirements.length === 0) return;
		const requiredDocs = clientDocumentRequirements.filter((doc) => doc.required);

		const uploadedDocsLookUp = uploadedDocuments.reduce((acc, doc) => {
			acc[doc.document_name] = doc;

			return acc;
		}, {});

		const allDocsUploaded = requiredDocs.every((doc) => uploadedDocsLookUp[doc.name]);

		console.log('allDocsUploaded', allDocsUploaded);
		if (allDocsUploaded) {
			handleUserChangeStep();
		}
	};
	const calculateSubNavTilesStatus = () => {
		// based on the requried documents we create the subnav so the user knows what to do

		// do the same for the uploaded documents
		console.log('uploadedDocuments', uploadedDocuments);
		console.log('clientDocumentRequirements', clientDocumentRequirements);
		const uploadedDocsLookUp = uploadedDocuments.reduce((acc, doc) => {
			acc[doc.document_name] = doc;

			return acc;
		}, {});

		console.log('uploadedDocsLookUp', uploadedDocsLookUp);
		const subNavItems = [];
		clientDocumentRequirements.forEach((doc) => {
			if (doc.required) {
				const completed = uploadedDocsLookUp[doc.name] ? true : false;
				subNavItems.push({
					id: doc.id,
					title: doc.name,
					completed: completed,
				});
			}
		});

		setDocumentSubNav(subNavItems);

		// loop through subNavItems and see how many items are completed

		const completedItems = subNavItems.filter((item) => item.completed).length;

		// update the progress of the document section
		const percent = completedItems / subNavItems.length;
		console.log('percent', percent);
		handleUpdateSectionProgress(subNavItems.length / completedItems, 'DOCUMENTS');
	};

	const handleUserChangeStep = async () => {
		// if the user changes step we can remove them from the "welcome onboard progress"
		// and move them to the registry onboard process

		// call handleCompleteCarePlanPlatformOnboard and update the carePlan

		try {
			const res = api.graph({
				query: `mutation{
          handleCompleteCarePlanPlatformOnboard(
            care_plan_id: ${carePlan.id}
          ){
            completed_platform_onboard
          }
          
        }`,
			});

			if (res.handleCompleteCarePlanPlatformOnboard) {
				toast.success('Completed Platform Onboard!', TOAST_SUCCESS_CONFIG);

				const updatedCarePlan = {
					...carePlan,
					completed_platform_onboard: true,
				};

				receivePatientCarePlan(updatedCarePlan);
			}
		} catch (err) {
			toast.error('Something went wrong', TOAST_ERROR_CONFIG);
			throw new Error(err);
		}
	};

	const handleSubNavTileClick = (id) => {
		// do nothing
	};
	//handleCompleteCarePlanPlatformOnboard
	return (
		<>
			<OnboardSubNav
				currentStep={'null'}
				steps={documentSubNav}
				handleSubNavTileClick={handleSubNavTileClick}
				showArrowsBetween={false}
				descriptionText='Please Upload Required Documents:'
			/>

			<Document />
			<Text onClick={() => handleUserChangeStep()}>Skip for the time being</Text>
		</>
	);
};

export default DocumentOnboard;
