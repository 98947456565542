import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CARE_PRO_MODEL } from '../../../core/api/models';

const searchCareProsByCarePlan = async ({ carePlanId, page }) => {
	try {
		const data = await api.graph({
			query: `{
				searchProvidersByCarePlan(
						page: ${page},
						carePlanId: ${carePlanId}
				){
					providers {
						${CARE_PRO_MODEL}
					
						blockedTime {
							id,
							day_of_week,
							start_time,
							end_time,
						},
					}
				}
			}`,
		});

		return data.searchProvidersByCarePlan.providers;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useSearchCareProsByCarePlanQuery = (carePlanId, page) => {
	return useQuery({
		queryKey: ['searchCareProsByCarePlan', carePlanId, page],
		queryFn: () => {
			return searchCareProsByCarePlan({ carePlanId, page });
		},
	});
};
