import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const addExternalCardSource = async ({ externalCardTokenId }) => {
	try {
		const data = await api.graph({
			query: `mutation {
                addExternalCardSource(
                    external_card_token_id: "${externalCardTokenId}"
                ) {
                    id
                }
            }`,
		});

		return data.addExternalCardSource;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useAddExternalCardSourceMutation = () => {
	return useMutation({
		mutationKey: ['addExternalCardSource'],
		mutationFn: (values) => {
			return addExternalCardSource(values);
		},
	});
};
