import { z } from 'zod';

export const expirationDateValidationSchema = z.object({
	expirationDate: z
		.string()
		.nonempty({ message: 'Expiration date is required.' })
		.refine((date) => new Date(date) > new Date(), {
			message: 'Expiration date must be in the future.',
		}),
});
