import React from 'react';
import { Hearts } from 'react-loader-spinner';

import { colors } from '../utils';

import { StyledLoader } from './styles';

const Loader = () => {
	return (
		<StyledLoader>
			<Hearts color={colors.primary.primary} height={100} width={100} />
		</StyledLoader>
	);
};

export default Loader;
