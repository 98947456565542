import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { providerSendMessage } from 'reducers/provider';
import { ParentContainer } from '@appComp/provider/ProviderHomeDashboard';

import CareProConversations from './CareProConversations';
import ChatView from './ChatView';

import { CONVERSATION_MODEL, MESSAGE_MODEL } from 'reducers/graphqlModelTypes';

import api from 'scripts/api';
import useWindowSize from 'scripts/useWindowSize';

const CareProChatHandler = (props) => {
	const { providerSendMessage, push } = props;
	const [conversations, setConversations] = useState([]);
	const [isCreatingInitialChat, setIsCreatingInitialChat] = useState(false);
	const [selectedConversation, setSelectedConversation] = useState(null);
	const [conversationsFetched, setConversationsFetched] = useState(false);

	const history = useHistory();
	const { width } = useWindowSize();
	const { conversation_id } = useParams();

	useEffect(() => {
		if (selectedConversation) {
			const conversation = conversations.find((d) => d.id === selectedConversation.id);
			if (conversation) {
				setSelectedConversation(conversation);
			}
		}
	}, [conversations]);

	useEffect(() => {
		if (conversationsFetched) {
			const doesAChatWithAdminExist = conversations.some(
				(conversation) => conversation.admin_id
			);

			if (!doesAChatWithAdminExist && !isCreatingInitialChat) {
				setIsCreatingInitialChat(true);
				createInitialChatWithAdmin().finally(() => setIsCreatingInitialChat(false));
			}
		}
	}, [conversationsFetched, conversations]);

	useEffect(() => {
		if (selectedConversation) {
			push(selectedConversation.id);
		}
	}, [selectedConversation, push]);

	useEffect(() => {
		const conversationIdCheck = location.pathname.split('/');
		if (conversationIdCheck.length >= 4) {
			const convoCheckId = parseInt(conversationIdCheck[3], 10);
			const conversation = conversations.find((d) => d.id === convoCheckId);
			if (conversation) {
				setSelectedConversation(conversation);
			}
		}
	}, [location.pathname, conversations]);

	useEffect(() => {
		const fetchProviderSession = async () => {
			try {
				const res = await api.graph({
					query: `{
						checkProviderSession {
							conversations {
								${CONVERSATION_MODEL}
								messages {
									${MESSAGE_MODEL}
								}
							}
						}
					}`,
				});
				setConversations(res.checkProviderSession.conversations);
				setConversationsFetched(true);
			} catch (err) {
				console.error('Error fetching provider session:', err);
				setConversationsFetched(true); // Still set to true to allow createInitialChatWithAdmin attempt in case of an error
			}
		};
		fetchProviderSession();
	}, []);

	const createInitialChatWithAdmin = async () => {
		try {
			const res = await api.graph({
				query: `mutation {
					careProCreateAdminConversation {
						${CONVERSATION_MODEL}
					}
				}`,
			});
			setConversations((prevConversations) => [
				...prevConversations,
				res.careProCreateAdminConversation,
			]);
		} catch (err) {
			console.error('Error creating initial chat with admin:', err);
		}
	};

	const backButtonHandler = () => {
		history.push('/carepro/chat');
	};

	const handleSendMessage = async (message) => {
		providerSendMessage({
			conversation_id: selectedConversation.id,
			text: message,
		});
	};

	return (
		<ParentContainer
			style={{
				flex: 1,
				display: 'flex',
				flexDirection: 'row',
			}}
		>
			{width < 1024 && conversation_id && (
				<ChatView
					selectedConversation={selectedConversation}
					handleSendMessage={handleSendMessage}
					providerSendMessage={providerSendMessage}
					loggedInUser={'carePro'}
					backButtonHandler={backButtonHandler}
				/>
			)}
			{width < 1024 && !conversation_id && (
				<CareProConversations
					setSelectedConversation={setSelectedConversation}
					selectedConversation={selectedConversation}
					conversations={conversations}
					loggedInUser={'carePro'}
				/>
			)}
			{!(width < 1024) && (
				<>
					<CareProConversations
						setSelectedConversation={setSelectedConversation}
						selectedConversation={selectedConversation}
						conversations={conversations}
						loggedInUser={'carePro'}
					/>
					<ChatView
						selectedConversation={selectedConversation}
						handleSendMessage={handleSendMessage}
						providerSendMessage={providerSendMessage}
						loggedInUser={'carePro'}
						backButtonHandler={backButtonHandler}
					/>
				</>
			)}
		</ParentContainer>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		providerSendMessage: (params) => dispatch(providerSendMessage(params)),
		push: (chatId) => dispatch(push(`/carepro/chat/${chatId}`)),
	};
};

export default connect(null, mapDispatchToProps)(CareProChatHandler);
