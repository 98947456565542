import React, { useState, useRef } from 'react';

import styled from 'styled-components';
import Colors from 'colors';

import { AnimatePresence, motion } from 'framer-motion';
import {
	faHome,
	faUserCircle,
	faTimes,
	faCalendarAlt,
	faCalendarWeek,
	faFile,
	faMailBulk,
	faMoneyBill,
	faHandHoldingHeart,
	faBriefcase,
} from '@fortawesome/free-solid-svg-icons';

import { RowFlex, Avatar, ColumnFlex } from 'basicStyles';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';

export const NavTileFramer = (props) => {
	const { title, icon, history, setShowMobileNav, isMobile, push, route, index } = props;
	const active = false;

	const [isActive, setIsActive] = useState(active);

	const handleOnClick = () => {
		push(route);
		setShowMobileNav(false);
	};

	return (
		<motion.div {...framerText(index)}>
			<RowFlex
				onMouseEnter={() => setIsActive(true)}
				onMouseLeave={() => setIsActive(false)}
				padding='0px 0 0 16px'
				fullWidth
				alignCenter
				gap='12px'
				style={{
					height: '60px',
					width: '240px',
					borderBottom: isActive ? `2px solid ${Colors.fullWhite}` : `2px solid #EDF6FB`,
					transition: 'all 0.3s ease',
					cursor: 'pointer',
					backgroundColor: !isActive ? Colors.primary.primary : '#5EAFE8',
				}}
				onClick={() => handleOnClick()}
			>
				<Icon size={'1x'} color={'white'} icon={icon} />
				<Text fontStyle='semi-bold' mediumText color={'white'}>
					{title}
				</Text>
			</RowFlex>
		</motion.div>
	);
};

export const BackDropBg = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(4px);
`;

const SideBarPanel = styled(motion.div)`
	position: fixed;
	display: flex;
	height: 100vh;
	width: fit-content;
	z-index: 100;
	left: 0;
	display: flex;
	flex-direction: column;
	background-color: ${Colors.primary.primary};
	top: 0;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
`;
const MobileSideBar = ({
	history,
	verification,
	mobileNav,
	setShowMobileNav,
	showMobileNav,
	provider,
}) => {
	return (
		<>
			<AnimatePresence mode='wait' initial={false}>
				{showMobileNav && (
					<>
						<BackDropBg
							onClick={() => setShowMobileNav(false)}
							{...framerSidebarBackground}
							aria-hidden='true'
						></BackDropBg>
						<SideBarPanel aria-label='Sidebar' {...framerSidebarPanel}>
							<ColumnFlex padding='16px 0 0 0' gap='0px'>
								<NavTileFramer
									icon={faHome}
									title='Home'
									route='/carepro'
									history={history}
									push={history.push}
									index={0}
									setShowMobileNav={setShowMobileNav}
								/>
								<NavTileFramer
									icon={faCalendarAlt}
									title='Calendar'
									route='/carepro/calendar'
									history={history}
									push={history.push}
									setShowMobileNav={setShowMobileNav}
									index={1}
								/>
								<NavTileFramer
									icon={faCalendarWeek}
									title='Shift'
									route='/carepro/shift'
									history={history}
									push={history.push}
									setShowMobileNav={setShowMobileNav}
									index={2}
								/>
								{/* <NavTileFramer
									icon={faTimes}
									title='Blocked Times'
									route='/carepro/blocked'
									history={history}
									setShowMobileNav={setShowMobileNav}
									push={history.push}
									index={3}
								/> */}
								<NavTileFramer
									icon={faHandHoldingHeart}
									title='Care Abilities'
									route='/carepro/care-abilities'
									setShowMobileNav={setShowMobileNav}
									history={history}
									push={history.push}
									index={5}
								/>
								<NavTileFramer
									icon={faBriefcase}
									title='Job Posts'
									route='/carepro/job-posts'
									setShowMobileNav={setShowMobileNav}
									history={history}
									push={history.push}
									index={6}
								/>
								<NavTileFramer
									icon={faUserCircle}
									title='Profile'
									route='/carepro/profile'
									setShowMobileNav={setShowMobileNav}
									history={history}
									push={history.push}
									index={7}
								/>
								<NavTileFramer
									icon={faMailBulk}
									title='Chat'
									route='/carepro/chat'
									history={history}
									push={history.push}
									setShowMobileNav={setShowMobileNav}
									index={8}
								/>

								<NavTileFramer
									icon={faFile}
									title='Documents'
									route='/carepro/documents'
									history={history}
									push={history.push}
									setShowMobileNav={setShowMobileNav}
									index={11}
								/>
								<NavTileFramer
									icon={faMoneyBill}
									title='Payments'
									route='/carepro/payments'
									history={history}
									push={history.push}
									setShowMobileNav={setShowMobileNav}
									index={12}
								/>
							</ColumnFlex>
						</SideBarPanel>
					</>
				)}
			</AnimatePresence>
		</>
	);
};

const framerSidebarBackground = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};

const framerSidebarPanel = {
	initial: { x: '-100%' },
	animate: { x: 0 },
	exit: { x: '-100%' },
	transition: { duration: 0.3 },
};

const framerText = (delay) => {
	return {
		initial: { opacity: 0, x: -50 },
		animate: { opacity: 1, x: 0 },
		transition: {
			delay: 0.5 + delay / 10,
		},
	};
};

const framerIcon = {
	initial: { scale: 0 },
	animate: { scale: 1 },
	transition: {
		type: 'spring',
		stiffness: 260,
		damping: 20,
		delay: 1.5,
	},
};

export default MobileSideBar;
