import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CARE_PRO_MODEL, CARE_PRO_REVIEW_MODEL } from '../../../core/api/models';

const fetchSelectedCarePro = async ({ careProId }) => {
	try {
		const data = await api.graph({
			query: `{
				provider(
					id: ${careProId}
				) {
					${CARE_PRO_MODEL}
					averageReview,
				
					blockedTime {
						id,
						day_of_week,
						start_time,
						end_time,
					},
				
					reviews {
						${CARE_PRO_REVIEW_MODEL}
						carePlan {
							coordinator {
								name
							}
						}
					}
				}
			}`,
		});

		return data.provider;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchSelectedCareProQuery = (careProId) => {
	return useQuery({
		queryKey: ['fetchSelectedCarePro', careProId],
		queryFn: () => {
			return fetchSelectedCarePro({ careProId });
		},
		enabled: !!careProId,
	});
};
