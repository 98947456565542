import React, { useState, useEffect } from 'react';
import Title from '../../Title';
import { Formik, Form, Field } from 'formik';
import Colors from '../../../constants/colors';
import {
	MARGIN_TITLE,
	FormItemsLength,
	FORM_ITEM_LENGTH,
	OBFormButtonText,
	OBBasicCon,
} from './OnboardViews';
import { ColumnFlex } from '../../styled/Basic';
import { connect } from 'react-redux';

import api from '../../../scripts/api';

import Text from 'AppComp/Text';

import { REGISTER_USER_TYPES } from 'reducers/paitentReducerHelper';
import { receivePatient } from 'reducers/patient';

import { validate6 } from './validation';
import StyledTextInput from 'AppComp/styled/StyledTextInput';
import Icon from 'AppComp/Icon';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import PageLoaderView from '../../PageLoaderView';
import { toast } from 'react-toastify';
import { toastSuccessConfig } from '../../../reducers/provider';

const OBPhoneConfirmation = (props) => {
	const {
		userAccountInfo,
		setClientAccountOnboardStep,
		carePlan,
		receivePatient,
		loginUserStart,
		renderCurrentStep,
	} = props;
	const [loading, _loading] = useState(false);
	const [err, _err] = useState(null);

	const handleSubmit = (values, form) => {
		_loading(true);
		handleRegisterUser(values.authCode.trim());
	};

	const handleRegisterUser = async (authCode) => {
		try {
			console.log('props.state', props.state);
			console.log('handleRegisterUser -> userAccountInfo', userAccountInfo);

			const register = await api.graph({
				query: `mutation{
					registerUser(
						email: "${userAccountInfo.email}",
						password: "${userAccountInfo.password}"
						name: "${userAccountInfo.first_name} ${userAccountInfo.last_name}",
						phoneNumber: "${userAccountInfo.phone}",
						auth_code: "${authCode}",
						display_name: "${userAccountInfo.display_name || ''}",
						registry_id: ${userAccountInfo.registry_id}
					
					) {
						token
						user {
							${REGISTER_USER_TYPES}
						}
					
					}
				}`,
			});

			console.log('register', register);

			if (register.registerUser.token) {
				localStorage.setItem('user-token', register.registerUser.token);
			}
			if (register.registerUser.user) {
				receivePatient(register.registerUser.user);

				loginUserStart();

				renderCurrentStep();
			}

			_loading(false);
		} catch (err) {
			_loading(false);
			console.log('handleVerifyUser err', err);

			if (Array.isArray(err)) {
				_err(err[0].message);
			} else {
				_err('Something went wrong');
			}
		}
	};

	const resendCode = async () => {
		try {
			const verifyPhone = await api.graph({
				query: `mutation {
					verifyUserPhone(phone_number: "${userAccountInfo.phone}", email: "${userAccountInfo.email}")
				}`,
			});
			toast.success('Code resent', toastSuccessConfig);
			_loading(false);
		} catch (err) {
			_loading(false);

			if (Array.isArray(err)) {
				_err(err[0].message);
			} else {
				_err('Something went wrong');
			}
		}
	};
	return (
		<OBBasicCon
			style={{
				maxWidth: '420px',
			}}
		>
			{/* <Icon size='3x' color={Colors.greenLightTeal} icon={faPhone} /> */}
			<Title
				style={{ textAlign: 'center' }}
				maxWidth={'320px'}
				margin={MARGIN_TITLE}
				subtitle
			>
				FYI values your privacy - We just want to be sure it is you !
			</Title>
			<Text nurseInfo gray>
				Please type the numerical confirmation code that has been texted to you.
			</Text>
			<div style={{ marginBottom: '10px' }} />
			<Formik
				onSubmit={handleSubmit}
				autoComplete={'off'}
				validate={validate6}
				render={(formProps) => {
					console.log(formProps);
					return (
						<Form noValidate>
							<ColumnFlex alignCenter justifyCenter fullWidth fullHeight>
								{err && (
									<Text margin='20px 0 0 0 ' bold error>
										{err}
									</Text>
								)}
								<FormItemsLength margin='30px 0 0 0' width={FORM_ITEM_LENGTH}>
									<Field
										component={StyledTextInput}
										name='authCode'
										label='Auth Code'
										type='text'
										backgroundColor='white'
									/>
								</FormItemsLength>

								{loading ? (
									<PageLoaderView errorMessage='Something seems to be wrong with our connection' />
								) : (
									<OBFormButtonText
										backgroundColor={Colors.teal}
										type='submit'
										valid={formProps.isValid}
										text='Next'
									/>
								)}

								<Text
									style={{
										cursor: 'pointer',
									}}
									onClick={() => resendCode()}
									margin='20px 0 0 0'
									underline
									nurseInfo
									gray
									cursor
									pointer
								>
									Re Send Text
								</Text>
							</ColumnFlex>
						</Form>
					);
				}}
			/>
			<div style={{ marginBottom: '10px' }} />
			<Text pointer onClick={() => setClientAccountOnboardStep(2)} subtitle underline>
				Miss typed? Change Email Or Phone Number
			</Text>
		</OBBasicCon>
	);
};

const mapStateToProps = ({ provider }) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		receivePatient: (params) => dispatch(receivePatient(params)),
		loginUserStart: () => dispatch({ type: 'PATIENT_LOGIN' }),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OBPhoneConfirmation);
