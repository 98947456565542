import React from 'react';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';

import { FormContainer, Input, Label, Select, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../FormWrapper';
import InputContainer from '../InputContainer';

import personalInfoFormValidationSchema from './validation';

const PersonalInfoForm = (props) => {
	const { initialValues, personalInfoFormHandler } = props;

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: (values) => personalInfoFormHandler(values),
		validationSchema: toFormikValidationSchema(personalInfoFormValidationSchema),
		enableReinitialize: true,
	});

	return (
		<FormContainer>
			<FormWrapper onSubmit={formik.handleSubmit}>
				<InputContainer>
					<Label> First Name </Label>

					<Input
						name='firstName'
						type='text'
						onChange={formik.handleChange}
						placeholder='First Name'
						value={formik.values.firstName}
					/>

					{formik.errors.firstName && (
						<ErrorMessage> {formik.errors.firstName} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Last Name </Label>

					<Input
						name='lastName'
						type='text'
						onChange={formik.handleChange}
						placeholder='Last Name'
						value={formik.values.lastName}
					/>

					{formik.errors.lastName && (
						<ErrorMessage> {formik.errors.lastName} </ErrorMessage>
					)}
				</InputContainer>

				<InputContainer>
					<Label> Gender </Label>

					<Select
						name='gender'
						onChange={formik.handleChange}
						value={formik.values.gender}
					>
						<option>Gender</option>
						<option value='male'>Male</option>
						<option value='female'>Female</option>
						<option value='transgender'>Transgender</option>
						<option value='non-binary'>Non-binary / Non-conforming</option>
						<option value='prefer-not-to-respond'>Prefer not to responsd</option>
					</Select>

					{formik.errors.gender && <ErrorMessage> {formik.errors.gender} </ErrorMessage>}
				</InputContainer>

				<InputContainer>
					<Label> Display Name </Label>

					<Input
						name='displayName'
						type='text'
						onChange={formik.handleChange}
						placeholder='Display Name'
						value={formik.values.displayName}
					/>
					{formik.errors.displayName && (
						<ErrorMessage> {formik.errors.displayName} </ErrorMessage>
					)}
				</InputContainer>

				<PrimaryButton type='submit' size='small'>
					Next
				</PrimaryButton>
			</FormWrapper>
		</FormContainer>
	);
};

export default PersonalInfoForm;
