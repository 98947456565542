import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { SHIFT_MODEL } from '../../../core/api/models';

const startShift = async ({ shiftId, lat, long }) => {
	try {
		const data = await api.graph({
			query: `mutation {
						startShift ( 
							shift_id: ${shiftId},
							location_lat: ${lat},
							location_long: ${long},
						) {
							${SHIFT_MODEL}
						}
					}`,
		});

		return data.startShift;
	} catch (err) {
		console.error(err);
		throw new Error(err);
	}
};

export const useStartShiftMutation = () => {
	return useMutation({
		mutationKey: ['startShift'],
		mutationFn: (values) => {
			return startShift(values);
		},
	});
};
