import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import { DEFAULT_TOAST_OPTIONS } from '../../../components/App';

import api from '../../../scripts/api';

import { receiveError, showInfoModel } from '../../environment';

import {
	PROVIDER_MODEL_V2,
	CAREPLAN_MODEL_V2,
	USER_MODEL_V2,
	PAYMENT_TYPE_MODEL,
	PATIENT_MODEL_V2,
	CORE_JOB_MODEL,
	SHIFT_BARE_MODEL,

} from '../../graphqlModelTypes';

import {
	receivePayments,
	receiveLiteCarePlan,
	receiveUserDetails,
	receiveCarePlansAction,
} from '../actions';

/* 
	
*/

export function dischargeProvider(id) {
	throw new Error('Fix this - Berny');
}

export function fetchPayment(id) {
	return (dispatch) => {
		api.graph({
			query: `{
				fetchPayment(payment_id: ${id}) {
					${PAYMENT_TYPE_MODEL}
					user{
						${USER_MODEL_V2}
					},
					provider {
						${PROVIDER_MODEL_V2}
					}
					shift {
						status
					}
					

				}
			}`,
		})
			.then((res) => {
				dispatch(
					receiveUserDetails({
						status: true,
						user: res.fetchPayment,
						type: 'payment',
					})
				);
			})
			.catch((err) => {
				console.log('fetchPayment err', err);
			});
	};
}

export function fetchAdminsCarePlans() {
	return (dispatch) => {
		api.graph({
			query: `{
				fetchAdminCarePlans {
					${CAREPLAN_MODEL_V2}
				  jobs {
						${CORE_JOB_MODEL}
					}
					coordinator {
						${USER_MODEL_V2}
					}
					shifts {
						${SHIFT_BARE_MODEL}
					}
				
				}
			}`,
		})
			.then((res) => {
				if (res.fetchAdminCarePlans) {
					dispatch(receiveLiteCarePlan(res.fetchAdminCarePlans));
				}
			})
			.catch((err) => {
				console.log('fetchAdminsCarePlans err', err);

				toast.error(
					'Somethign Went Wrong Collecting This information. Please Try Again, If the problem continues Please contact Tech For Help',
					DEFAULT_TOAST_OPTIONS
				);
			});
	};
}

export function fetchCarePlanModel(id) {
	return (dispatch) => {
		api.graph({
			query: `{
				carePlan(id: ${id}) {
					${CAREPLAN_MODEL_V2}
					jobs {
						${CORE_JOB_MODEL}
					}
					
					shifts {
						${SHIFT_BARE_MODEL}
					}
					payments {
						${PAYMENT_TYPE_MODEL}
					},
				
				
					coordinator {
						${PATIENT_MODEL_V2}
						verification {
							valid,
							error_code,
							error_reason,
							patient_personal_details_submitted,
							patient_location_details_submitted,
							payment_method_verification,
							
							error_code,
							error_reason,
							valid_email,
							valid_phone,
							missing_information
						},
					},
				
				}
			}`,
		})
			.then((res) => {
				dispatch(
					receiveUserDetails({
						status: true,
						user: res.carePlan,
						type: 'carePlan',
					})
				);
			})
			.catch((err) => {
				console.log('fetchCarePlanModel err', err);
			});
	};
}

export function allPayments() {
	return (dispatch) => {
		api.graph({
			query: `{
				allPayments{
					${PAYMENT_TYPE_MODEL}
					user{
						${USER_MODEL_V2}
					},
					provider {
						${PROVIDER_MODEL_V2}
					}
					shift {
						${SHIFT_BARE_MODEL}
					}
					
					carePlan {
						${CAREPLAN_MODEL_V2}
					
					}
				}
			}`,
		})
			.then((res) => {
				dispatch(receivePayments(res.allPayments));
			})
			.catch((err) => {
				console.log('allPayments err', err);
			});
	};
}

export function fetchCarePlan(carePlanId) {
	return (dispatch) => {
		api.graph({
			query: `{
				carePlan(id: ${carePlanId}) {
					${CAREPLAN_MODEL_V2}
					coordinator {
						${USER_MODEL_V2}
					},
					adminProviderConversations {
						id,
						provider {
							${PROVIDER_MODEL_V2}
						},
						messages {
							${MESSAGE_MODEL}
						}
					},
				
				}
			}`,
		})
			.then((payload) => {
				dispatch(receiveCarePlansAction([payload.carePlan]));
			})
			.catch((err) => {
				console.log('receiveCarePlan err', err);
				// Handle error
			});
	};
}
