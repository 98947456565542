import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';

import { colors } from '../../corev2/utils';

import { StyledRow } from './styles';

const Rating = ({ rating }) => {
	return (
		<StyledRow>
			{[...Array(rating)].map((_, i) => (
				<FontAwesomeIcon
					key={i}
					size='sm'
					color={colors.primary.new_primary}
					icon={faHeart}
				/>
			))}
		</StyledRow>
	);
};

export default Rating;
