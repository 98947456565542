import styled from 'styled-components';

import Colors from 'colors';

import { device } from 'AppComp/styled/StyledMediaQuery';

export const StyledContainer = styled.div`
	min-width: 100%;
	min-height: 80vh;

	padding: 0;

	border-radius: 2rem;

	@media ${device.tablet} {
		padding: 1.5rem;
	}

	@media ${device.laptop} {
		> * {
			&:nth-child(even) {
				margin-right: 0px;
			}
		}
	}
`;
