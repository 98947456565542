import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CONVERSATION_MODEL, MESSAGE_MODEL } from '../../../core/api/models';

const contactClient = async ({ careProId, clientId }) => {
	try {
		const data = await api.graph({
			query: `mutation{
				userContactCarePro (
					provider_id: ${careProId},
					user_id: ${clientId},
				) {
						${CONVERSATION_MODEL}
						messages {
							${MESSAGE_MODEL}
						}
					},
			}`,
		});

		return data.userContactCarePro;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useContactClientMutation = () => {
	return useMutation({
		mutationKey: ['contactClient'],
		mutationFn: (values) => {
			return contactClient(values);
		},
	});
};
