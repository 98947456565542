import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';

import { fetchAllClients } from 'reducers/provider';

import Wrapper from '../../core/Wrapper';
import Tiles from '../../core/Tiles';

import { StyledClientTilesContainer } from './styles';

const ClientsPage = (props) => {
	const { clients, fetchAllClients } = props;

	const history = useHistory();

	const clientClickHandler = (clientId) => {
		history.push('/carepro/clients/' + clientId);
	};

	useEffect(() => {
		fetchAllClients();
	}, []);

	return (
		<Wrapper>
			<StyledClientTilesContainer>
				{clients.map((client) => {
					return (
						<Tiles.ClientTile
							{...client}
							buttonText='Chat'
							clientClickHandler={clientClickHandler}
						/>
					);
				})}
			</StyledClientTilesContainer>
		</Wrapper>
	);
};

const mapStateToProps = ({ provider }) => {
	return {
		clients: provider.clients,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchAllClients: () => dispatch(fetchAllClients()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsPage);
