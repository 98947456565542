export const validate1 = (values) => {
	const errors = {};
	const err = 'Please Select a Care Type';

	if (values.relation_to_client === 'ignore') {
		errors.careType = err;
	}

	if (!values.first_name) {
		errors.firstName = err;
	} else if (/\d/.test(values.first_name)) {
		errors.first_name = numErr;
	}

	if (!values.last_name) {
		errors.lastName = err;
	} else if (/\d/.test(values.last_name)) {
		errors.last_name = numErr;
	}

	return errors;
};

export const validate2 = (values) => {
	const errors = {};
	const err = 'Please Select a Care Type';

	return errors;
};

export const validate3 = (values) => {
	const errors = {};
	const err = 'Please Select a Care Type';

	if (!values.address_zip) {
		errors.address_zip = err;
	}

	return errors;
};

export const validate4 = (values) => {
	const errors = {};
	const err = 'Required';
	const numErr = 'Cannot contain numbers';

	if (!values.firstName) {
		errors.firstName = err;
	} else if (/\d/.test(values.firstName)) {
		errors.firstName = numErr;
	}

	if (!values.lastName) {
		errors.lastName = err;
	} else if (/\d/.test(values.lastName)) {
		errors.lastName = numErr;
	}
	return errors;
};

export const validate5 = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.email) {
		errors.email = err;
	}

	if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
		errors.email = 'Invalid email address';
	}

	if (!values.phoneNumber) {
		errors.phoneNumber = err;
	}

	if (!values.password) {
		errors.password = err;
	}

	if (!values.confirm_password) {
		errors.confirm_password = err;
	}

	const password = values.password;

	if (!values.password) {
		errors.phoneNumber = err;
	} else {
		//If password contains both lower and uppercase characters, increase strength value.
		if (!password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
			errors.password = 'Must contain both lower and uppercase characters';
		}

		//If it has numbers and characters, increase strength value.
		if (!password.match(/([0-9])/)) {
			errors.password = 'Must contain at least 1 number';
		}

		//If it has one special character, increase strength value.
		if (!password.match(/([!,%,&,@,#,$,^,*,?,_,~])/)) {
			errors.password = 'Must contain at least 1 special character';
		}
		if (password.length < 7) {
			errors.password = 'Must be at lest 8 character long';
		}
		if (values.confirm_password !== values.password) {
			errors.confirm_password = 'Passwords are not identical';
		}
	}

	return errors;
};

export const validate6 = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.authCode) {
		errors.authCode = err;
	}

	return errors;
};

export const validate7 = (values) => {
	const errors = {};
	const err = 'Required';
	const numErr = 'Cannot contain numbers';
	const ageErr = 'Must be 30 years or older';

	if (!values.firstName) {
		errors.firstName = err;
	} else if (/\d/.test(values.firstName)) {
		errors.firstName = numErr;
	}

	if (!values.lastName) {
		errors.lastName = err;
	} else if (/\d/.test(values.lastName)) {
		errors.lastName = numErr;
	}

	if (!values.dob) {
		errors.dob = err;
	} else {
		const birthDate = new Date(values.dob);
		const currentDate = new Date();
		const age = currentDate.getFullYear() - birthDate.getFullYear();

		if (age < 30) {
			errors.dob = ageErr;
		}
	}

	if (!values.gender) {
		errors.gender = err;
	}
	if (!values.displayName) {
		errors.gender = err;
	}
	return errors;
};

export const validateCarePersonal = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.jobStatement) {
		errors.jobStatement = err;
	}

	if (!values.weight) {
		errors.weight = err;
	}

	if (!values.diagnosis) {
		errors.diagnosis = err;
	}

	if (!values.contact1Name) {
		errors.contact1Name = err;
	}

	if (!values.contact1Relationship) {
		errors.contact1Relationship;
	}

	if (!values.contact1Phone) {
		errors.contact1Phone = err;
	}

	return errors;
};

export const validateLogistics = (values) => {
	const errors = {};
	const err = 'Required';

	if (!values.address) {
		errors.address1 = err;
	}

	if (!values.city) {
		errors.city = err;
	}

	if (!values.state) {
		errors.state = err;
	}

	if (!values.zip) {
		errors.zip = err;
	}

	return errors;
};
