import React from 'react';
import styled from 'styled-components';

import Colors from 'colors';
import { Avatar } from 'basicStyles';

import FormButton from 'AppComp/FormButton';

import { ParentContainer } from '../ProviderHomeDashboard/ProviderHomeDashboardView';

/*
 * - TextInputCon
 * - CusotmFieldSet
 * - CustomFormButton
 */

export const TextInputCon = styled.div`
	width: 100%;
`;

export const CusotmFieldSet = styled.fieldset`
	display: block;
	margin-inline-start: 0;
	margin-inline-end: 0;
	padding-block-start: 0;
	padding-inline-start: 0;
	padding-inline-end: 0;
	padding-block-end: 0;
	min-inline-size: min-content;
	border-image: initial;
	border: none;

	${({ disabledStatus }) =>
		disabledStatus &&
		`
		.main_label {
			top: -1.2rem;
			left: 4px;
			color: ${Colors.gray};
			font-size: 0.80rem;
		}
	`}
`;

export const CustomFormButton = styled(FormButton)`
	width: 180px;
	height: 70px;
	border-radius: 50px;

	${({ valid }) =>
		!valid &&
		`
		background-color: white;
		border: 1px solid ${Colors.greenLightTeal};
		color: ${Colors.greenLightTeal};

	`}

	${({ valid }) =>
		valid &&
		`
		background-color: ${Colors.greenLightTeal};
		color: white;
	`}
`;

export const Container = styled(ParentContainer)`
	overflow-y: visible;
	padding: 40px 40px 0px 40px;
`;

export const CustomAvatar = styled(Avatar)`
	cursor: pointer;
	background-size: cover;
	background-position: center;
`;
