export const jobPostsRoutes = {
	home: '/client/job-posts',
	create: '/client/job-posts/create',
	jobPost: (jobPostId) => `/client/job-posts/` + jobPostId,
	jobPostSchedule: (jobPostId) => `/client/job-posts/${jobPostId}/schedule`,
	jobPostOffer: (jobPostId, careProId) =>
		`/client/job-posts/${jobPostId}/offer/care-pro/${careProId}`,
	viewOfferPage: (jobPostId, jobOfferId) =>
		`/client/job-posts/${jobPostId}/offer/view/${jobOfferId}`,
};
