import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from 'colors';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import changeCase from 'change-case';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';
import AnimationWrapper from 'AppComp/AnimationWrapper';
import OverlayWrapper from 'AppComp/OverlayWrapper';
import ModelOverlay from 'AppComp/ModelOverlay';

import { RowFlex, ColumnFlex, Avatar } from 'basicStyles';

import FLowStep from './FLowStep';
const Handler = (props) => {
	const [onboardNeeds, setOnboardNeeds] = useState([]);

	const [showFlowStepModel, setShowFlowStepModel] = useState(false);

	const { renderRows, renderLegend, title, justifyContent, leftPadding } = props;

	const renderUserNeeds = () => {
		return onboardNeeds.map((step, index) => {
			return (
				<FlowStep
					key={index}
					step={index + 1}
					title={step.title}
					description={step.description}
					type={step.type}
				/>
			);
		});
	};

	const handleSubmitStep = (step) => {
		// add the new step to the list
		const latestSteps = [...onboardNeeds];

		// add the new step to the list
		latestSteps.push({
			title: step.title,
			description: step.description,
			type: step.type,
		});

		// update state
		setOnboardNeeds(latestSteps);

		//close model
		setShowFlowStepModel(false);
	};
	const handleExit = () => {
		setShowFlowStepModel(false);
	};
	return (
		<Con>
			<AnimationWrapper status={showFlowStepModel} classNames='basic-overlay' appear>
				<OverlayWrapper handleExitClick={handleExit}>
					<ModelOverlay>
						<FLowStep
							handleSubmitStep={handleSubmitStep}
							handleExitClick={handleExit}
						/>
					</ModelOverlay>
				</OverlayWrapper>
			</AnimationWrapper>
			<TableContainer>
				<Header justifyContent={justifyContent} leftPadding={'16px'}>
					<Text bold color='#FFFFFF' mediumText>
						Define User Requirements
					</Text>
				</Header>
				<ColumnFlex alignCenter>
					<ColumnFlex
						style={{
							borderRadius: '16px',
							cursor: 'pointer',
						}}
						margin='10px 0 0 0'
						alignCenter
						onClick={() => setShowFlowStepModel(true)}
					>
						<Avatar
							style={{
								backgroundColor: 'white',
							}}
							color={Colors.greenLightTeal}
							height='140px'
							width='140px'
						>
							<Icon size='4x' color={Colors.greenLightTeal} icon={faPlus} />
						</Avatar>
						<Text margin='10px' color={Colors.greenLightTeal} mediumText>
							Add a new requirement
						</Text>
					</ColumnFlex>
					<ColumnFlex
						style={{
							width: '600px',
						}}
						center
					>
						{renderUserNeeds()}
					</ColumnFlex>
				</ColumnFlex>
			</TableContainer>
		</Con>
	);
};

export default Handler;

const FlowStep = (props) => {
	const { step, title, description, type } = props;

	return (
		<RowFlex style={{ width: '400px' }}>
			<ColumnFlex alignCenter>
				<div
					style={{
						width: '4px',
						height: '10px',

						backgroundColor: Colors.greenLightTeal,
						margin: '0 0 0 0',
					}}
				/>
				<Avatar
					style={{
						backgroundColor: 'white',
					}}
					color={Colors.greenLightTeal}
					height='80px'
					width='80px'
				>
					<Text bold color={Colors.greenLightTeal} largeText>
						{step}
					</Text>
				</Avatar>
				<div
					style={{
						width: '4px',
						height: '10px',

						backgroundColor: Colors.greenLightTeal,
						margin: '0 0 0 0',
					}}
				/>
			</ColumnFlex>
			<ColumnFlex
				style={{
					flex: 1,
				}}
				margin='10px 0 0 0'
				padding='0 10px'
				itemsStart
			>
				<RowFlex fullWidth itemsCenter spaceBetween>
					<Text bold color={Colors.primary.primary} midLarge>
						{title}
					</Text>
					<Text color={'black'} mediumText fontWeight='thin'>
						{type}
					</Text>
				</RowFlex>
				<Text style={{ textAlign: 'left' }} color={'black'} mediumText fontWeight='thin'>
					{changeCase.sentenceCase(description)}
				</Text>
			</ColumnFlex>
		</RowFlex>
	);
};
export const Con = styled.div`
	flex: 1;
	margin: 16px;
`;

const TableContainer = styled.div`
	background: #ffffff;
	margin: 5px 0px;
	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	border-radius: 10px;
`;

const LeftPadding = '0px';

const Header = styled(RowFlex)`
	padding-left: ${({ leftPadding }) => leftPadding || LeftPadding};
	padding-right: ${({ leftPadding }) => leftPadding || LeftPadding};
	justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
	background-color: ${Colors.blue};
	align-items: center;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	height: 64px;
`;
