import React, { Component } from 'react';

import { ACCEPTED_CARDS } from 'constants';
import api from 'scripts/api';

import { Card, StyledTokenCon, SavedTokenInput } from './PatientSettingViews';

export default class PatientSettingController extends Component {
	state = {
		editProfileInfo: false,
		error: null,
	};

	handleSubmit = (values, form) => {
		const {
			updatePatient,
			patient: { id },
		} = this.props;

		const params = {
			userId: id,
			userInput: {
				email: values.email,
				phone_number: values.phone_number,
				address: '',
			},
		};

		updatePatient(params);

		this.setState({ editProfileInfo: false });
	};

	renderInitalValues = () => {
		const {
			patient: { email, phone_number, address },
		} = this.props;

		const initialValues = {
			email: email,
			phone_number: phone_number,
		};

		return initialValues;
	};
}
