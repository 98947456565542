import styled from 'styled-components';

export const StyledCarePlanOnboardingPage = styled.div`
	display: flex;
	flex-direction: column;

	width: fit-content;
	margin: auto;
`;

export const StyledStepsContainer = styled.div`
	margin-bottom: 3rem;
`;
