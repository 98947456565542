import { createAction, handleActions } from 'redux-actions';
import { push } from 'connected-react-router';

import {
	receiveForgotPasswordStatus,
	receiveNewPasswordStatus,
	receiveError,
	showInfoModel,
} from 'reducers/environment';

//provider.js
import api from '../scripts/api';

import { setTotalPages, setCurrentPage } from './patient';
import {
	PAGINATED_PROVIDERS_MODEL_V2,
	PROVIDER_MODEL_V2,
	UNMATCHED_PROVIDER_MODEL_V2,
	CAREPLAN_MODEL_V2,
	PATIENT_MODEL_V2,
	PROVIDER_REVIEW_MODEL,
	VERIFICATION_MODEL,
	STRIPE_VERIFICATION_INFO_MODEL,
	CONVERSATION_MODEL,
	MESSAGE_MODEL,
	DOCUMENT_MODEL,
	CORE_JOB_MODEL,
	PAYOUT_METHOD_MODEL,
	USER_MODEL_V2,
	UNMATCHED_USER_MODEL_V2,
	PAYMENT_TYPE_MODEL,
	PAYOUT_MODEL,
	SHIFT_MODEL,
	STRIPE_STATUS_MODEL,
	CARE_PRO_REGISTRY_STATUS_MODEL,
	CAREPR0_PAYOUT_METHOD,
	DOCUMENT_REQUIREMENTS,
} from './graphqlModelTypes';
import { toast } from 'react-toastify';

import { receiveCareProDocumentRequirements } from './admin/actions';
/*******************
PROVIDER STATE
*******************/
const initialState = {
	provider: null,
	selectedProvider: null,
	currentProviderContent: null,
	conversations: [],

	hourlyRate: null,
	liveInRate: null,
	verification: null,
	registering: false,
	providers: [],
	caregivers: [],
	client: null,
	clients: [],
	certifications: [],
	careplanPatient: [],

	blockedTimes: [],

	jobs: [],

	payments: [],
	payouts: [],

	stripeVerificationInfo: null,
	payoutMethods: [],

	selectedConversationType: null,
	selectedConversation: null,
	stepsProgress: 0,
	uploadedDocuments: [],
	fetchingCareProSession: {
		status: false,
		attempts: 0,
		error: false,
		errorMessage: null,
	},
};

export const receiveSelectedConversation = createAction('RECEIVE_SELECTED_CONVERSATION');
export const receiveSelectedConversationType = createAction('RECEIVE_SELECTED_CONVERSATION_TYPE');

export const switchProviderContent = createAction('SWITCH_PROVIDER_CONTENT');

export const toggleProviderRegistering = createAction('TOGGLE_PROVIDER_REGISTERING');

export const receiveSelectedProvider = createAction('RECEIVE_SELECTED_PROVIDER');
export const receiveSearchProviders = createAction('RECEIVE_SEARCH_PROVIDERS');
export const receiveCareplanPatient = createAction('RECEIVE_CAREPLAN_PATIENTS');
export const receiveProviderInfo = createAction('RECEIVE_PROVIDER_INFO');
export const receiveProviderConversations = createAction('RECEIVE_PROVIDER_CONVERSATIONS');
export const receiveUploadedDocuments = createAction('RECEIVE_UPLOADED_DOCUMENTS');

export const receiveProviderBlockedTimes = createAction('RECEIVE_PROVIDER_BLOCKED_TIMES');
export const receiveVerification = createAction('RECEIVE_VERIFICATION');

export const receiveClientProfile = createAction('RECEIVE_CLIENT_PROFILE');
export const receiveClients = createAction('RECEIVE_CLIENTS');
export const receivePayments = createAction('RECEIVE_PAYMENTS');
export const receivePayouts = createAction('RECEIVE_PAYOUTS');

export const receiveStripeVerificationInfo = createAction('RECEIVE_STRIPE_VERIFICATION_INFO');
export const receiveProviderPayOutMethods = createAction('RECEIVE_PROVIDER_PAYOUT_METHODS');

export const receiveStepsProgress = createAction('RECEIVE_STEPS_PROGRESS');

export const receiveFetchingCareProSession = createAction('RECEIVE_FETCHING_CAREPRO_SESSION');

export const toastErrorConfig = {
	containerId: 'basic',
	autoClose: false,
};

export const toastSuccessConfig = {
	containerId: 'basic',
	autoClose: true,
};
export default handleActions(
	{
		[receiveFetchingCareProSession](state, { payload }) {
			return {
				...state,
				fetchingCareProSession: {
					...state.fetchingClientSession,
					...payload,
				},
			};
		},
		[receiveUploadedDocuments](state, { payload }) {
			return {
				...state,
				uploadedDocuments: payload,
			};
		},
		[receiveStepsProgress](state, { payload }) {
			return {
				...state,
				stepsProgress: payload,
			};
		},

		[receiveSelectedConversationType](state, { payload }) {
			return {
				...state,
				selectedConversationType: payload,
			};
		},
		[receiveStripeVerificationInfo](state, { payload }) {
			return {
				...state,
				stripeVerificationInfo: payload,
			};
		},

		[receiveClients](state, { payload }) {
			return {
				...state,
				clients: payload,
			};
		},
		[receiveClientProfile](state, { payload }) {
			return {
				...state,
				client: payload,
			};
		},

		[receiveVerification](state, { payload }) {
			return {
				...state,
				verification: payload,
			};
		},
		[receiveProviderPayOutMethods](state, { payload }) {
			return {
				...state,
				payoutMethods: payload,
			};
		},
		[receivePayments](state, { payload }) {
			return {
				...state,
				payments: payload,
			};
		},
		[receivePayouts](state, { payload }) {
			return {
				...state,
				payouts: payload,
			};
		},

		[toggleProviderRegistering](state, { payload }) {
			return {
				...state,
				registering: payload,
			};
		},

		[receiveProviderBlockedTimes](state, { payload }) {
			return {
				...state,
				blockedTimes: payload,
			};
		},

		[receiveProviderConversations](state, { payload }) {
			return {
				...state,
				conversations: payload,
			};
		},

		[receiveSearchProviders](state, { payload }) {
			return {
				...state,
				caregivers: payload,
			};
		},
		[receiveSelectedProvider](state, { payload }) {
			return {
				...state,
				selectedProvider: payload,
			};
		},
		[switchProviderContent](state, { payload }) {
			return {
				...state,
				currentProviderContent: payload,
			};
		},
		[receiveCareplanPatient](state, { payload }) {
			return {
				...state,
				careplanPatient: payload,
			};
		},
		[receiveProviderInfo](state, { payload }) {
			return {
				...state,
				provider: payload,
			};
		},
	},
	initialState
);

export function updateProviderRate({ live_in_rate, hourly_rate, rate_type }) {
	return (dispatch, getState) => {
		api.graph({
			query: `mutation {
				updateProviderRate(live_in_rate: ${live_in_rate}, hourly_rate: ${hourly_rate}, rate_type: "${rate_type}") {
					${PROVIDER_MODEL_V2},
					
				}
			}`,
		})
			.then((res) => {
				dispatch(receiveError(null));
				if (res) {
					const { provider } = getState().provider;
					const _tempP = {
						...provider,
						care_type: res.updateProviderRate.care_type,
					};

					dispatch(receiveProviderInfo(_tempP));
				} else {
					console.log('somethign went wrong');
				}
			})
			.catch((err) => {
				console.log('updateProviderRate err', err);

				if (Array.isArray(err)) {
					dispatch(receiveError(err[0].message));
				} else {
					dispatch(receiveError('Something went wrong'));
				}
			});
	};
}

export function verifyProviderPhone({ phoneNumber, email }) {
	console.log('patient reducer -> verfiyUserPhone', phoneNumber, email);
	return (dispatch) => {
		api.graph({
			query: `mutation {
				verifyUserPhone(phone_number: "${phoneNumber}", email: "${email}")
			}`,
		})
			.then((res) => {
				dispatch(receiveError(null));
				if (res) {
					dispatch(receivePhoneValidationStatus(true));
				} else {
					console.log('somethign went wrong');
				}
			})
			.catch((err) => {
				console.log('verifyUserPhone err', err);

				if (Array.isArray(err)) {
					dispatch(receiveError(err[0].message));
				} else {
					dispatch(receiveError('Something went wrong'));
				}
			});
	};
}

export function changeProviderPassword(password) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				changeProviderPassword(password: "${password}") {
					id
				}
			}`,
		})
			.then((res) => {
				dispatch(
					receiveNewPasswordStatus({
						status: true,
						message: 'Password has been updated',
					})
				);
			})
			.catch((err) => {
				console.log('err');

				dispatch(
					receiveNewPasswordStatus({
						status: false,
						message: 'Something went wrong please try again',
					})
				);
			});
	};
}

export function forgotProviderPassword(email) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				forgotPassword(
					email: "${email}",
					account_type: "provider"
				)
			}`,
		})
			.then((res) => {
				dispatch(
					receiveForgotPasswordStatus({
						status: true,
						message: 'Password reset link has been sent, Please check your email',
					})
				);
			})
			.catch((err) => {
				console.log('err');

				dispatch(
					receiveForgotPasswordStatus({
						status: false,
						message: 'Something went wrong please make sure you have the right email',
					})
				);
			});
	};
}

export function addBlockedTime({ blockedTimes, providerId, handleExitClick }) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				addBlockedTime(
					blockedTime: ${api.graphStringify(blockedTimes)},
					provider_id: ${providerId}
				) {
					blockedTime {
						id,
						day_of_week,
						start_time,
						end_time,
					}
				}
			}`,
		})
			.then((res) => {
				dispatch(receiveProviderBlockedTimes(res.addBlockedTime.blockedTime));

				const toastConfig = {
					containerId: 'basic',
					autoClose: true,
				};
				toast.success('Blocked time has been added', toastConfig);
				handleExitClick();
			})
			.catch((err) => {
				console.log('addBlockedTime -> err', err);

				const toastErrorConfig = {
					containerId: 'basic',
					autoClose: false,
				};

				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);
				} else {
					toast.error(
						'Something went wrong please try again if the error perist contact support',
						toastErrorConfig
					);
				}
			});
	};
}

export function deleteBlockedTime({ providerId, blockedTimeId, closeToast }) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				deleteBlockedTime(
					blocked_time_id: ${blockedTimeId},
					provider_id: ${providerId}
				) {
					blockedTime {
						id,
						day_of_week,
						start_time,
						end_time,
					}
				}
			}`,
		})
			.then((res) => {
				dispatch(receiveProviderBlockedTimes(res.deleteBlockedTime.blockedTime));
				const toastConfig = {
					containerId: 'basic',
					autoClose: true,
				};
				toast.success('Blocked time has been deleted', toastConfig);
				closeToast();
			})
			.catch((err) => {
				toast.error('Something went wrong please try again', toastErrorConfig);
				console.log('deleteBlockedTime -> err', err);
			});
	};
}

export function updateProviderInfo(profileInfo) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				updateProivderInfo(
					profile_info: ${api.graphStringify(profileInfo)}
				) {
					${PROVIDER_MODEL_V2}
				}
			}`,
		})
			.then((res) => {
				dispatch(receiveProviderInfo(res.updateProivderInfo));
				toast.success('Updated Profile Information', toastSuccessConfig);
			})
			.catch((err) => {
				console.log('updateProviderInfo -> err', err);
			});
	};
}

export function providerSendMessage({ conversation_id, text }) {
	console.log('providerSendMessage', conversation_id, text);
	return (dispatch, getState) => {
		api.graph({
			query: `mutation{
				providerSendMessage(
					conversation_id: ${conversation_id},
					text: "${text}"
				) 
			}`,
		})
			.then((res) => {
				console.log('providerSendMessage', res);
			})
			.catch((err) => {
				console.log('providerSendMessage err', err);
			});
	};
}

export function logoutProvider() {
	return (dispatch) => {
		api.graph({
			query: `mutation{
				logoutProvider
			}`,
		})
			.then((res) => {
				localStorage.removeItem('user-token');

				dispatch({ type: 'PROVIDER_LOGOUT' });
				dispatch(push('/carepro'));
			})
			.catch((err) => {
				console.log('logoutProvider -> err, ', err);
			});
	};
}

/* 
TODO: UDPATE
*/

export function handleProviderLogin(provider) {
	return (dispatch) => {
		//dispatch(receiveProviderConversations(provider.conversations));
		//dispatch(receiveProviderPayOutMethods(provider.payoutMethods));

		dispatch(receiveProviderInfo(provider));
		dispatch({ type: 'PROVIDER_LOGIN' });
	};
}

/*
 * function for CarePro to create a conversation between them and a user (who represents a client)
 */
export function careProContactUser({ careProId, userId }) {
	return (dispatch, getState) => {
		api.graph({
			query: `mutation{
				userContactCarePro (
					provider_id: ${careProId},
					user_id: ${userId},
				) {
						${CONVERSATION_MODEL}
						messages {
							${MESSAGE_MODEL}
						}
					},
			}`,
		})
			.then((res) => {
				// if the res is succesfull
				if (res.userContactCarePro) {
					// add the conversation to the list of conversations
					dispatch(
						receiveProviderConversations([
							...getState().provider.conversations,
							res.userContactCarePro,
						])
					);
					// select the conversation

					//dispatch({ type: '_CONVERSATION_UPDATED' });

					dispatch(push(`/provider/chat/${res.userContactCarePro.id}`));
				}
			})
			.catch((err) => {
				console.log('fetchUserConversation -> err', err);
			});
	};
}

export function verifyCareProEmail({ user_id, hashed_key }) {
	return (dispatch) => {
		api.graph({
			query: `mutation{
				verifyCareProEmail(
					user_id: ${user_id},
					hashed_key: "${hashed_key}"
				)  {
					token,
					provider {
						${PROVIDER_MODEL_V2},
						verification {
							${VERIFICATION_MODEL}
						},
						stripeVerificationInfo {
							${STRIPE_VERIFICATION_INFO_MODEL}
						}
						
					
	
						blockedTime {
							id,
							day_of_week,
							start_time,
							end_time,
						},
					
				
					
						conversations {
							${CONVERSATION_MODEL}
							messages {
								${MESSAGE_MODEL}
							}
						},
					
					}
				}
				
			}`,
		})
			.then((res) => {
				console.log('verifyCareProEmail res: ', res);
				if (res.verifyCareProEmail.token) {
					// save token to localhost
					localStorage.setItem('user-token', res.verifyCareProEmail.token);
					toast.success('Email Verified', toastSuccessConfig);
					handleProviderLogin(res.verifyCareProEmail.provider);
				}
			})
			.catch((err) => {
				console.log('verifyCareProEmail -> err, ', err);

				if (Array.isArray(err)) {
					receiveError(err[0].message);
				} else {
					receiveError('Something went wrong connecting');
					//receiveError(err);
				}
			});
	};
}

export async function checkProviderVerification() {
	try {
		return await api.graph({
			query: `{
				checkProviderSession {
					stripeStatus {
						${STRIPE_STATUS_MODEL}
					}
					careProRegistryStatus {
						${CARE_PRO_REGISTRY_STATUS_MODEL}
					}
				}
			}`,
		});
	} catch (err) {
		console.log('checkProviderVerification -> err', err);
		return null;
	}
}

/**************************
	Function to check for an existing session linked to a provider
**************************/

export function checkProviderSession() {
	return (dispatch, getState) => {
		const { fetchingCareProSession } = getState().provider;
		dispatch(receiveFetchingCareProSession({ ...fetchingCareProSession, status: true }));

		api.graph({
			query: `{
				checkProviderSession {
					${PROVIDER_MODEL_V2},
					stripeStatus {
						${STRIPE_STATUS_MODEL}
					}
					careProRegistryStatus {
						${CARE_PRO_REGISTRY_STATUS_MODEL}
					}
					conversations {
						${CONVERSATION_MODEL}
						messages {
							${MESSAGE_MODEL}

						}
					},

					payoutMethods {
						${CAREPR0_PAYOUT_METHOD}
					}
				
				
				}
			}`,
		})
			.then((res) => {
				dispatch(handleProviderLogin(res.checkProviderSession));

				// get the current loading state
				const { fetchingCareProSession } = getState().provider;
				// update the loading state
				dispatch(
					receiveFetchingCareProSession({
						error: false,
						status: false,
						attempts: fetchingCareProSession.attempts + 1,
					})
				);

				console.log('0x08', res.checkProviderSession.careProRegistryStatus);
				// check if they are still onboarding
				if (res.checkProviderSession.careProRegistryStatus) {
					const careProRegistryStatus = res.checkProviderSession.careProRegistryStatus;

					if (careProRegistryStatus.platform_onboard === false) {
						dispatch(push('/carepro-welcome'));
					}
				}
			})
			.catch((err) => {
				console.log('checkProviderSession -> err, ', err);

				// remove the local storage data
				const token = localStorage.getItem('user-token');
				if (token) {
					// remove failed token
					localStorage.removeItem('user-token');
				}
				// check local storage for session data
				// const sessionData = localStorage.getItem('provider-session-data');
				// if (sessionData) {
				// 	dispatch(handleProviderLogin(JSON.parse(sessionData)));
				// }

				const { fetchingCareProSession } = getState().provider;

				receiveFetchingCareProSession({
					error: true,
					errorMessage: 'Something went wrong please notify support',
					status: false,
					attempts: fetchingCareProSession.attempts + 1,
				});
			});
	};
}

export function addProviderPaymentMethod({ token }) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				addExternalCardSource( external_card_token_id: "${token}") {
					${PAYOUT_METHOD_MODEL}
				}
			}`,
		})
			.then((res) => {
				console.log('addProviderPaymentMethod', res);
				toast.success('New payout method added.', toastSuccessConfig);
				dispatch(receiveProviderPayOutMethods(res.addExternalCardSource));
			})
			.catch((err) => {
				console.log('updatepatientdefaultPaymentMethod err', err);
				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);
				}
			});
	};
}

export function updateProviderDefaultPaymentMethod({ source_id }) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				changeDefaultPayoutSource( external_card_token_id: "${source_id}") {
					${PAYOUT_METHOD_MODEL}
				}
			}`,
		})
			.then((res) => {
				console.log('updateProviderDefaultPaymentMethod', res);
				toast.success('Default payment method updated.', toastSuccessConfig);
				dispatch(receiveProviderPayOutMethods(res.changeDefaultPayoutSource));
			})
			.catch((err) => {
				console.log('updateProviderDefaultPaymentMethod err', err);
				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);
				}
			});
	};
}

export function deleteProviderPaymentMethod({ source_id }) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				removeExternalCard(
					external_card_token_id: "${source_id}"
				) {
					${PAYOUT_METHOD_MODEL}
				}
			}`,
		})
			.then((res) => {
				console.log(res);
				toast.success('Payout method deleted.', toastSuccessConfig);
				dispatch(receiveProviderPayOutMethods(res.removeExternalCard));
			})
			.catch((err) => {
				console.log('deletePaymentMethod err', err);
				if (Array.isArray(err)) {
					toast.error(err[0].message, toastErrorConfig);
				} else {
					toast.error('Something went wrong please notify support', toastErrorConfig);
				}
			});
	};
}

export function fetchProviderPayments() {
	return (dispatch) => {
		api.graph({
			query: `{
				fetchProviderPayments{
					${PAYMENT_TYPE_MODEL}
					user{
						${USER_MODEL_V2}
					},
					provider {
						${PROVIDER_MODEL_V2}
					}
				}
			}`,
		})
			.then((res) => {
				dispatch(receivePayments(res.fetchProviderPayments));
			})
			.catch((err) => {
				console.log('fetchProviderPayments err', err);
			});
	};
}

export function fetchProviderPayouts() {
	return (dispatch) => {
		api.graph({
			query: `{
				fetchProviderPaysOuts{
					${PAYOUT_MODEL}
				}
			}`,
		})
			.then((res) => {
				dispatch(receivePayouts(res.fetchProviderPaysOuts));
			})
			.catch((err) => {
				console.log('fetchProviderPaysOuts err', err);
			});
	};
}

//Fetch a provider based on an id
// REQUIRES: ID: NUMBER
export function fetchSelectedProvider(id) {
	console.log('is this running');
	return (dispatch) => {
		api.graph({
			query: `{
				provider(
					id: ${id}
				) {
					${UNMATCHED_PROVIDER_MODEL_V2}
					averageReview,
				
					blockedTime {
						id,
						day_of_week,
						start_time,
						end_time,
					},
				
					reviews {
						${PROVIDER_REVIEW_MODEL}
						carePlan {
							coordinator {
								name
							}
						}
					}
				}
			}`,
		})
			.then((res) => {
				console.log('fetchSelectedProvider res', res);
				dispatch(receiveSelectedProvider(res.provider));
			})
			.catch((err) => {
				console.log('fetchSelectedProvider -> err', err);
			});
	};
}

//Fetches all the searchProviders
export function searchProvidersByCarePlan({ page = 1, carePlanId }) {
	return (dispatch) => {
		api.graph({
			query: `{
				searchProvidersByCarePlan(
						page: ${page},
						carePlanId: ${carePlanId}
				){
					${PAGINATED_PROVIDERS_MODEL_V2}
					providers {
						${UNMATCHED_PROVIDER_MODEL_V2}
					
						blockedTime {
							id,
							day_of_week,
							start_time,
							end_time,
						},
					}
				}
			}`,
		})
			.then((res) => {
				console.log('searchProvidersByCarePlan', res);
				dispatch(receiveSearchProviders(res.searchProvidersByCarePlan.providers));
				dispatch(setTotalPages(res.searchProvidersByCarePlan.totalPages));
				//Need to add one to page since its 0 index on the back end
				dispatch(setCurrentPage(res.searchProvidersByCarePlan.page));
			})
			.catch((err) => {
				console.log('searchProvidersByCarePlan -> err', err);
			});
	};
}
const handleShowingErrorToast = (err) => {
	if (Array.isArray(err)) {
		toast.error(err[0].message, toastErrorConfig);
	} else {
		toast.error(
			'Something went wrong please try again if the error perist contact support',
			toastErrorConfig
		);
	}
};

export function createProviderStripeAccount() {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				createProviderStripeAccount {
					verification {
						${VERIFICATION_MODEL}
					},
					stripeVerificationInfo {
						${STRIPE_VERIFICATION_INFO_MODEL}
					}
				}
			}`,
		})
			.then((res) => {
				const data = res.createProviderStripeAccount;
				if (data.verification) dispatch(receiveVerification(data.verification));
				if (data.stripeVerificationInfo)
					dispatch(receiveStripeVerificationInfo(data.stripeVerificationInfo));
			})
			.catch((err) => {
				console.log('updateProviderVerificationInfo -> err', err);
				//handleShowingErrorToast(err);
			});
	};
}

export function updateProviderVerificationInfo(inputs) {
	console.log('updateProviderVerificationInfo -> inputs', inputs);

	return (dispatch) => {
		api.graph({
			query: `mutation {
				updateProviderStripeInformation(
					updateValues: ${api.graphStringify(inputs)},
				) {
					verification {
						${VERIFICATION_MODEL}
					},
					stripeVerificationInfo {
						${STRIPE_VERIFICATION_INFO_MODEL}
					}
				}
			}`,
		})
			.then((res) => {
				const data = res.updateProviderStripeInformation;
				if (data.verification) dispatch(receiveVerification(data.verification));
				if (data.stripeVerificationInfo)
					dispatch(receiveStripeVerificationInfo(data.stripeVerificationInfo));
				toast.success('Updated', toastSuccessConfig);
			})
			.catch((err) => {
				console.log('updateProviderVerificationInfo -> err', err);
				handleShowingErrorToast(err);
			});
	};
}

export function fetchCareProUploadedDocuments() {
	return (dispatch) => {
		api.graph({
			query: `{
				fetchCareProUploadedDocuments {
					${DOCUMENT_MODEL}
				}
			}`,
		})
			.then((res) => {
				dispatch(receiveUploadedDocuments(res.fetchCareProUploadedDocuments));
			})
			.catch((err) => {
				console.log('fetchCareProUploadedDocuments -> err', err);
			});
	};
}
export function careProUploadDocument({ document_url, document_name }) {
	return (dispatch) => {
		api.graph({
			query: `mutation {
				careProUploadDocument(
					document_url: "${document_url}",
					document_name: "${document_name}"
				) {
					${DOCUMENT_MODEL}
				
					
				}
			}`,
		})
			.then((res) => {
				const data = res.careProUploadDocument;

				if (data) {
					dispatch(receiveUploadedDocuments(data));
					toast.success(
						'Document Uploaded (pending office approval)',
						toastSuccessConfig
					);
				}
			})
			.catch((err) => {
				console.log('careProUploadedDocument -> err', err);
				handleShowingErrorToast(err);
			});
	};
}
export function searchProviders(page = 1) {
	return (dispatch) => {
		api.graph({
			query: `{
				searchProviders(
						page: ${page},
				){
					${PAGINATED_PROVIDERS_MODEL_V2}
					providers {
						${PROVIDER_MODEL_V2}
						verification {
							${VERIFICATION_MODEL}
						},
					
					
						blockedTime {
							id,
							day_of_week,
							start_time,
							end_time,
						},
					}
				}
			}`,
		})
			.then((res) => {
				console.log('searchProviders res', res);

				dispatch(receiveSearchProviders(res.searchProviders.providers));
				dispatch(setTotalPages(res.searchProviders.totalPages));
				//Need to add one to page since its 0 index on the back end
				dispatch(setCurrentPage(res.searchProviders.page));
			})
			.catch((err) => {
				console.log('searchProviders -> err', err);
			});
	};
}

export function fetchAllClients() {
	return (dispatch) => {
		api.graph({
			query: `{
				fetchAllPatients{
					${UNMATCHED_USER_MODEL_V2}
				}
			}`,
		})
			.then((res) => {
				dispatch(receiveClients(res.fetchAllPatients));
			})
			.catch((err) => {
				console.log('fetchAllPatients err', err);
			});
	};
}

/*
 * updateSetTraits func
 * Takes
 * @set_id: int!
 * @traits
 */

export function updateSetTraits({ traits, set_id }) {
	return (dispatch, getState) => {
		api.graph({
			query: `mutation{
				updateSetTraits(
					set_id: ${set_id},
					traits: ${api.graphStringify(traits)}
				) {
					id
					value {
						trait_value
					}
				}
			}`,
		}).then((res) => {
			console.log('updateSetTraits', res.updateSetTraits);
		});
	};
}

export function fetchClientProfile(clientId) {
	return (dispatch) => {
		api.graph({
			query: `{
				user(id: ${clientId}) {
					${UNMATCHED_USER_MODEL_V2}
				}
			}`,
		})
			.then((res) => {
				dispatch(receiveClientProfile(res.user));
			})
			.catch((err) => {
				console.log('user err', err);
			});
	};
}

export function fetchCareProDocumentRequirement() {
	return (dispatch) => {
		api.graph({
			query: `{
				fetchCareProDocumentRequirement {
					${DOCUMENT_REQUIREMENTS}
				}
			}`,
		})
			.then((res) => {
				if (res.fetchCareProDocumentRequirement) {
					dispatch(
						receiveCareProDocumentRequirements(res.fetchCareProDocumentRequirement)
					);
				}
			})
			.catch((err) => {
				console.log('registryFetchCareProDocumentRequirement err', err);
			});
	};
}
