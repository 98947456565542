import styled from 'styled-components';

import { colors, mediaQueries } from '../utils';

export const StyledRow = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	justify-content: center;
	color: ${colors.primary.primary};

	@media ${mediaQueries.tablet} {
		justify-content: left;
	}
`;
