import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

import { fetchPatient, searchPatients } from 'reducers/admin';

import SetTable from 'AppComp/SetTable';

import PatientSearchController from './PatientSearchController';
import { SetTableContainer } from '../ManageDisputes/AdminDisputesView';

class ProviderSearchHandler extends PatientSearchController {
	render() {
		return (
			<SetTableContainer>
				<SetTable
					title='Search for Patient'
					renderRows={this.renderRows}
					renderLegend={this.renderLegend}
					leftPadding='16px'
				>
					{this.renderSearchBar()}
				</SetTable>
			</SetTableContainer>
		);
	}
}

const mapStateToProps = ({ admin }) => {
	return {
		patients: admin.patients,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		searchPatients: (input) => dispatch(searchPatients(input)),
		fetchPatient: (id) => dispatch(fetchPatient(id)),
		goToClient: (id) => dispatch(push('/admin/clients/' + id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderSearchHandler);
