import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

import Colors from 'colors';

import { device } from 'AppComp/styled/StyledMediaQuery';
import Icon from 'AppComp/Icon';
import { Avatar } from 'AppComp/styled/Basic';
import Text from 'AppComp/Text';
import { connect } from 'react-redux';

const STEPS_INITIAL = [
	{ id: 1, name: 'Account', valid: false },

	{ id: 2, name: 'Contact ', valid: false },
	{ id: 3, name: 'Verification ', valid: false, pending: false },
	{
		id: 4,
		name: 'Certification ',

		valid: false,
	},
	{ id: 5, name: 'Membership ', valid: true },
	{ id: 6, name: 'Profile ', valid: false },
];
const Steps = ({ stepsProgress, goToStep, provider, stripeVerificationInfo, verification }) => {
	const [step, setStep] = useState(1);

	const [steps, setSteps] = useState(STEPS_INITIAL);

	useEffect(() => {
		renderCurrentStep();
	}, [stripeVerificationInfo, provider, verification]);

	const renderCurrentStep = () => {
		if (provider === null) {
		} else {
			const pendingVerificationCardPayments =
				verification.card_payments === 'pending' ? true : false;

			//const pendingVerificationCardPayments = true;

			const pendingVerificationPayouts = verification.transfers === 'pending' ? true : false;
			//const pendingVerificationPayouts = true;

			const pending = pendingVerificationCardPayments || pendingVerificationPayouts;

			const stripeVerify =
				verification.details_submitted &&
				verification.charges_enabled &&
				verification.payouts_enabled
					? true
					: false;
			console.log('stripeVerify', stripeVerify);

			const provider = provider !== null;

			const email = verification.valid_email ? true : false;
			const adminVerification = verification.admin_verification ? true : false;
			const membership = true;

			const completedSteps = [provider, email, stripeVerify, adminVerification, membership];

			const runStepFunc = completedSteps.findIndex((step) => step === false);
			if (runStepFunc !== -1) {
				setStep(runStepFunc + 1);
			}

			// loop through completedSteps and set the steps to valid for each step
			const newSteps = steps.map((step, index) => {
				const valid = completedSteps[index];

				if (step.id === 3) {
					return { ...step, valid, pending };
				} else {
					const valid = completedSteps[index];
					return { ...step, valid };
				}
			});
			setSteps(newSteps);
		}
	};

	return (
		<nav
			style={{
				marginTop: '20px',
				marginBottom: '10px',
			}}
		>
			<OrderedList role='list'>
				{steps.map((_step, index) => (
					<ListItem
						goToStep={goToStep}
						key={_step.name}
						step={step}
						{..._step}
						index={index}
						stepsProgress={stepsProgress}
					/>
				))}
			</OrderedList>
		</nav>
	);
};

const OrderedList = styled.ol`
	margin-top: 20px;
	border-radius: 2px;

	display: flex;
	flex-direction: column;
	padding: 0;
	list-style: none;
`;

const ListItem = (props) => {
	const { id, valid, index, name, step, goToStep, stepsProgress, pending } = props;

	console.log('stepsProgress', stepsProgress);
	return (
		<ListItemStyle valid={step === id ? true : false}>
			{valid ? (
				<>
					<Icon size='3x' icon={faCheck} color={Colors.emerald} />
					<Text
						style={{
							textAlign: 'center',
						}}
						color={Colors.emerald}
						mediumText
					>
						{name}
					</Text>
					{pending && (
						<Text
							style={{
								textAlign: 'center',
							}}
							color={Colors.emerald}
							mediumText
						>
							(pending)
						</Text>
					)}
				</>
			) : (
				<>
					{step === id ? (
						<>
							<div
								style={{
									backgroundColor: 'white',
									height: '60px',
									width: '60px',
									borderRadius: '50%',
								}}
							>
								<CircularProgressbarWithChildren
									value={stepsProgress}
									maxValue={1}
									styles={{
										path: {
											stroke: Colors.emerald,
										},
										backgroundColor: 'white',
									}}
								>
									<Text
										style={{
											textAlign: 'center',
										}}
										color={step === id ? Colors.teal : 'black'}
										midLarge
									>
										{id}
									</Text>
								</CircularProgressbarWithChildren>
							</div>
							<Text
								style={{
									textAlign: 'center',
								}}
								color={step === id ? 'white' : 'white'}
								mediumText
								bold={step === id ? true : false}
							>
								{name}
							</Text>
							{pending && (
								<Text
									style={{
										textAlign: 'center',
									}}
									color={Colors.fullWhite}
									mediumText
								>
									(pending)
								</Text>
							)}
						</>
					) : (
						<>
							<Avatar
								height={'40px'}
								width={'40px'}
								backgroundColor={'white'}
								color={Colors.primary.primary}
							>
								<Text
									style={{
										textAlign: 'center',
									}}
									color={step === id ? Colors.teal : Colors.primary.primary}
									midLarge
								>
									{id}
								</Text>
							</Avatar>
							<Text
								style={{
									textAlign: 'center',
								}}
								color={step === id ? 'white' : 'white'}
								mediumText
								bold={step === id ? true : false}
							>
								{name}
							</Text>
						</>
					)}
				</>
			)}
		</ListItemStyle>
	);
};

const InValidStep = styled.div`
  border: 1px solid ${Colors.primary.primary};
  border-radius
`;
const ListItemStyle = styled.li`
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 8px;
	align-items: center;
	padding: 16px 24px;
	border-bottom: ${({ valid }) => (valid ? `3px solid ${Colors.primary.primary}` : 'none')};
`;

const mapStateToProps = ({ provider }) => {
	return {
		provider: provider.provider,
		stripeVerificationInfo: provider.stripeVerificationInfo,
		verification: provider.verification,
		stepsProgress: provider.stepsProgress,
	};
};
const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Steps);
