import React, { useState } from 'react';
import { connect } from 'react-redux';
import api from 'scripts/api';
import { showInfoModel } from 'reducers/environment';

import Colors from 'colors';

import { InfoButton } from 'AppComp/Info';
import Text from 'AppComp/Text';

import Title from 'AppComp/Title';
import Button from 'AppComp/Button';
import BasicOverlayWrapper from 'AppComp/BasicOverlayWrapper';
import InvoiceExportModel from 'AppComp/InvoiceExportModel';

import { PHDColumn, ParentContainer, PHWhiteCon } from '../ProviderHomeDashboard';
export const PROVIDER_ONBOARD_CON_PADDING = '4px 16px';

const SettingsPageHandler = (props) => {
	const { conversation, verification, showInfoModel } = props;

	const [showExportInvoiceOverlay, updateExportInvoiceOverlayStatus] = useState(false);

	const createConnectedAccountLoginLink = async () => {
		try {
			const graphResponse = await api.graph({
				query: `mutation {
					createConnectedAccountLoginLink
				}`,
			});

			window.open(graphResponse.createConnectedAccountLoginLink, '_blank').focus();
		} catch (err) {
			console.log('createConnectedAccountLoginLink err', err);
		}
	};

	const handleInfo = () => {
		showInfoModel({
			show: true,
			text: 'Provider Stripe Info',
			title: 'Provider Stripe Info',
		});
	};

	const handleStripeMoreInfo = () => {
		showInfoModel({
			show: true,
			title: 'We Partner With Stripe..',
			text: 'FYI is a marketplace that give you the opportunity to create your own personal business profile. Clients that are looking for Care Providers will be able to search FYI and perhaps select you! Our annual fees are reasonable. They can be upgraded at any time, and you pay only the difference. Each tier allows you to enhance the business profile that you want potential clients to be able to see.',
		});
	};

	const createProviderStripeAccountLink = async (type) => {
		throw new Error('Fix This');
	};

	const createConnectedAccountUpdate = async () => {
		try {
			const graphResponse = await api.graph({
				query: `mutation {
					createConnectedAccountLoginLink
				}`,
			});

			if (graphResponse.createConnectedAccountLoginLink) {
				window.open(graphResponse.createConnectedAccountLoginLink, '_blank');
			}
		} catch (err) {
			console.log('createConnectedAccountLoginLink err', err);
		}
	};

	const stripeVerify =
		verification.details_submitted &&
		verification.charges_enabled &&
		verification.payouts_enabled
			? true
			: false;
	return (
		<ParentContainer>
			<BasicOverlayWrapper
				status={showExportInvoiceOverlay}
				handleExitClick={() => updateExportInvoiceOverlayStatus(false)}
				appear
			>
				<InvoiceExportModel type='provider' />
			</BasicOverlayWrapper>

			<PHDColumn>
				<Title color={Colors.greenLightTeal} margin='0 0 20px 0' fontWeight='700'>
					Stripe Dashboard
				</Title>
				<PHWhiteCon>
					<Text padding='8px' darkGray largeText>
						Access your payment information and more
					</Text>
					{stripeVerify && (
						<Button
							width='180px'
							height='70px'
							margin='10px 0 0px 0'
							padding='8px'
							borderRadius={'50px'}
							backgroundColor={Colors.secondary.green}
							onClick={() => createConnectedAccountLoginLink()}
						>
							<Text bold color='white'>
								Manage Stipe
							</Text>
						</Button>
					)}

					{verification.details_submitted &&
						!verification.charges_enabled &&
						!verification.payouts_enabled && (
							<>
								<Text largeText margin='12px' bold color={Colors.black}>
									Stripe Verification
								</Text>
								<Text
									color='#636363'
									margin='16x 0 12px 0'
									padding={PROVIDER_ONBOARD_CON_PADDING}
									subtext
									underline
									pointer
									onClick={() => handleStripeMoreInfo()}
								>
									Why is this required ?
								</Text>
								<Text
									color='#636363'
									margin='16px 0 12px 0'
									padding={PROVIDER_ONBOARD_CON_PADDING}
								>
									Please Take The Time to Add Any Remaning Information
								</Text>
								<Text
									color='#636363'
									margin='0 0 12px 0'
									padding={PROVIDER_ONBOARD_CON_PADDING}
								>
									{verification.error_reason}
								</Text>
								<Button
									width='180px'
									height='70px'
									padding='8px'
									borderRadius={'50px'}
									margin='12px 0 16px 0'
									backgroundColor={Colors.primary.primary}
									onClick={() => createConnectedAccountUpdate()}
								>
									Verify Info
								</Button>

								<Text margin='8px' />
							</>
						)}
					{!verification.details_submitted && (
						<>
							<Text largeText margin='12px' bold color={Colors.black}>
								Stripe Verification
							</Text>
							<Text
								color='#636363'
								margin='16x 0 12px 0'
								padding={PROVIDER_ONBOARD_CON_PADDING}
								subtext
								underline
								pointer
								onClick={() => handleStripeMoreInfo()}
							>
								Why is this required ?
							</Text>
							<Text
								color='#636363'
								margin='16x 0 12px 0'
								padding={PROVIDER_ONBOARD_CON_PADDING}
							>
								Please Take The Time to Verify Your Self With Stripe (Our Payment
								Processer)
							</Text>
							<Button
								width='180px'
								height='70px'
								padding='8px'
								borderRadius={'50px'}
								margin='12px 0 16px 0'
								backgroundColor={Colors.primary.primary}
								onClick={() => createProviderStripeAccountLink()}
							>
								Verify
							</Button>
							<Text margin='8px' />
						</>
					)}
				</PHWhiteCon>
			</PHDColumn>
		</ParentContainer>
	);
};

const mapStateToProps = ({ provider }, props) => {
	return {
		provider: provider.provider,
		conversation: provider.conversation,
		verification: provider.verification,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		showInfoModel: (params) => dispatch(showInfoModel(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPageHandler);
