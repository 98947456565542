import React, { useEffect, useState, createContext } from 'react';
import { useParams } from 'react-router-dom';

import { useAuth } from '../../../core/hooks/useAuth';
import { useFetchSingleJobPostQuery } from '../../api/queries/useFetchSingleJobPostQuery';

export const JobPostContext = createContext();

export const JobPostProvider = ({ children }) => {
	const [jobStatus, setJobStatus] = useState('open');
	const { jobPostId } = useParams();
	const { authCarePro } = useAuth();

	const {
		data: jobPost,
		isLoading: isLoadingJobPost,
		refetch: updateJobPost,
	} = useFetchSingleJobPostQuery(jobPostId);

	const determineJobStatus = () => {
		if (jobPost && authCarePro) {
			if (
				jobPost.jobs.some((job) => job.status === 'offer' && job.offer_status === 'offered')
			) {
				setJobStatus('offered');
			} else if (
				jobPost.applications.some(
					(application) =>
						application.status === 'applied' &&
						application.provider_id === authCarePro.id
				)
			) {
				setJobStatus('applied');
			} else if (
				jobPost.applications.some(
					(application) =>
						application.status === 'invited' &&
						application.provider_id === authCarePro.id
				)
			) {
				setJobStatus('invited');
			} else if (
				jobPost.jobs.some((job) => job.status === 'job' && job.job_status === 'active')
			) {
				setJobStatus('active');
			} else if (
				jobPost.jobs.some(
					(job) => job.status === 'offer' && job.offer_status === 'rejected'
				)
			) {
				setJobStatus('inactive');
			} else {
				setJobStatus('open');
			}
		}
	};

	useEffect(() => {
		determineJobStatus();
	}, [jobPost, authCarePro]);

	const updateJobStatus = (status) => {
		setJobStatus(status);
	};

	const value = {
		jobPost,
		isLoadingJobPost,
		jobStatus,
		updateJobPost,
		updateJobStatus,
	};

	return <JobPostContext.Provider value={value}>{children}</JobPostContext.Provider>;
};
