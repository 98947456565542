import moment from 'moment';

import { sortShifts } from './sortShifts';

/**
 *
 * @param {*} schedules
 * @param {*} jobs
 * @param {*} startDate
 * @param {*} endDate
 * @returns shifts
 */

export const generateShifts = (schedules, jobs, startDate, endDate) => {
	const shifts = [];
	const currentDateTime = moment();

	/**
	 * Job start and end dates that are being passed to the function.
	 * For each job, the start and end dates are to be considered when generating shifts for that period
	 */
	const jobStart = moment(startDate, 'YYYY-MM-DD').startOf('day');
	const jobEnd = moment(endDate, 'YYYY-MM-DD').endOf('day');

	/**
	 * Flatten shifts from each job in jobs array.
	 */
	const existingShifts = jobs.flatMap((job) =>
		job.shifts.map((shift) => ({
			startDate: shift.start_date,
			startTime: shift.start_time,
			endDate: shift.end_date,
			endTime: shift.end_time,
			status: shift.status,
			scheduleId: shift.schedule_id,
		}))
	);

	/**
	 * Main loop to iterate through all schedules and generate shifts.
	 */

	schedules.forEach((schedule) => {
		if (!schedule.active) return;

		const { start_date, start_time, end_date, end_time, repeat_frequency, days_of_week } =
			schedule;

		const scheduleStartDate = moment.max(moment(start_date, 'YYYY-MM-DD'), jobStart);
		const scheduleEndDate = end_date
			? moment.min(moment(end_date, 'YYYY-MM-DD'), jobEnd)
			: jobEnd;

		const addShift = (date) => {
			const startDateTime = moment(`${date.format('YYYY-MM-DD')}T${start_time}`);
			const endDateTime = moment(`${date.format('YYYY-MM-DD')}T${end_time}`);

			// Check for client schedule exceptions
			const isClientException = schedule.schedule_exceptions.some(
				(exception) =>
					exception.type === 'CLIENT_SCHEDULE_EXCEPTION' &&
					moment(exception.start_date).isSame(startDateTime, 'day') &&
					exception.start_time === start_time
			);

			const isExistingShift = existingShifts.some((existingShift) => {
				const shiftStart = moment(
					`${existingShift.startDate}T${existingShift.startTime}`,
					'YYYY-MM-DDTHH:mm'
				);
				const shiftEnd = moment(
					`${existingShift.endDate}T${existingShift.endTime}`,
					'YYYY-MM-DDTHH:mm'
				);

				const sameSchedule = existingShift.scheduleId === schedule.id;
				const withinStart = startDateTime.isSameOrAfter(shiftStart);
				const withinEnd = endDateTime.isSameOrBefore(shiftEnd);
				const notCanceled = existingShift.status !== 'CANCELED';

				return sameSchedule && withinStart && withinEnd && notCanceled;
			});

			// Push shift if there are no exceptions, it is not utilized, and does not overlap with non-canceled existing shifts
			if (!isClientException && !isExistingShift && startDateTime.isAfter(currentDateTime)) {
				shifts.push({
					id: shifts.length + 1,
					title: 'Care Session',
					startDate: startDateTime.format('YYYY-MM-DD'),
					startTime: startDateTime.format('MMMM D, YYYY [at] h:mm A'),
					endDate: endDateTime.format('YYYY-MM-DD'),
					endTime: endDateTime.format('MMMM D, YYYY [at] h:mm A'),
					scheduleId: schedule.id,
				});
			}
		};

		if (repeat_frequency === 'one_time') {
			const oneTimeDate = moment(`${start_date}T${start_time}`);
			if (oneTimeDate.isBetween(scheduleStartDate, scheduleEndDate, 'day', '[]')) {
				addShift(oneTimeDate);
			}
		} else if (repeat_frequency === 'daily') {
			for (
				let date = scheduleStartDate.clone();
				date.isSameOrBefore(scheduleEndDate);
				date.add(1, 'days')
			) {
				addShift(date);
			}
		} else if (repeat_frequency === 'weekly') {
			const daysOfWeek = days_of_week ? days_of_week.split(',') : [];
			for (
				let date = scheduleStartDate.clone();
				date.isSameOrBefore(scheduleEndDate);
				date.add(1, 'days')
			) {
				if (daysOfWeek.includes(date.format('ddd'))) {
					addShift(date);
				}
			}
		} else if (repeat_frequency === 'every_weekday') {
			for (
				let date = scheduleStartDate.clone();
				date.isSameOrBefore(scheduleEndDate);
				date.add(1, 'days')
			) {
				if (date.isoWeekday() >= 1 && date.isoWeekday() <= 5) {
					addShift(date);
				}
			}
		}
	});

	return sortShifts(shifts);
};
