import React, { Component, Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import Colors from 'colors';

import { RowFlex } from 'basicStyles';
import { InfoButton } from 'AppComp/Info';

import Text from 'AppComp/Text';

import { ModelContainer, AlternateSection } from 'AppComp/ModelOverlay';

import { Header } from 'AppComp/EditScheduleView';

import Button from 'AppComp/Button';
import {
	DisputeShower,
	DisputeTimeSlot,
	DisputeSummaryWeekCon,
} from 'AppComp/admin/ManageDisputes/AdminDisputeSummaryView';

import BlockedTimeController from './BlockedTimeController';

export default class BlockedTimeHandler extends BlockedTimeController {
	render() {
		const { startDate, endDate, selectedDaysOfWeek } = this.state;

		console.log('selectedDaysOfWeek', selectedDaysOfWeek);
		return (
			<ModelContainer>
				<Header text='Add Unavailable time' color={Colors.memebrship.gold} />
				<AlternateSection height='auto' padding='0 24px'>
					<DisputeShower title='Blocked Time' margin='12px 0 16px 0' fullWidth>
						<RowFlex alignCenter fullWidth spaceBetween>
							<RowFlex alignCenter>
								<DisputeTimeSlot
									color={Colors.darkerGray}
									time={startDate}
									onChange={this.onChangeStartDate}
								/>
								<Text color='black' margin='0 8px 0 8px'>
									-
								</Text>
								<DisputeTimeSlot
									color={Colors.darkerGray}
									time={endDate}
									onChange={this.onChangeEndDate}
								/>
							</RowFlex>
							<Text color='black' margin='0 8px 0 8px'>
								Submit time you're unavailable to work
							</Text>
						</RowFlex>
					</DisputeShower>
					<DisputeShower title='Repeat On Days' margin='12px 0 16px 0' fullWidth>
						<DisputeSummaryWeekCon>{this.renderWeekTiles()}</DisputeSummaryWeekCon>
					</DisputeShower>
				</AlternateSection>
				<Button
					text='Update Schedule'
					color={Colors.memebrship.gold}
					onClick={() => this.handleSubmit()}
				/>
			</ModelContainer>
		);
	}
}
