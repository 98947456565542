import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import {
	CARE_PRO_MODEL,
	STRIPE_STATUS_MODEL,
	CARE_PRO_REGISTRY_STATUS_MODEL,
} from '../../../core/api/models';

const loginCarePro = async ({ email, password, registryId }) => {
	try {
		const data = await api.graph({
			query: `mutation{
				loginProvider(
					email: "${email}",
					password: "${password}",
					registry_id: ${registryId}
				) {
					token
					provider {
						${CARE_PRO_MODEL}

						stripeStatus {
							${STRIPE_STATUS_MODEL}
						}
							
						careProRegistryStatus {
							${CARE_PRO_REGISTRY_STATUS_MODEL}
						}
					}
				},
			}`,
		});

		return data.loginProvider;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useLoginCareProMutation = () => {
	return useMutation({
		mutationKey: ['loginCarePro'],
		mutationFn: (values) => {
			return loginCarePro(values);
		},
	});
};
