import React from 'react';

import {
	StyledTileContainer,
	StyledAvatarContainer,
	StyledPersonalInfoContainer,
	StyledPersonalInfoWrapper,
	StyledPersonalInfo,
	StyledName,
	StyledRate,
	StyledLocation,
	StyledDescriptionContainer,
	StyledButtonContainer,
	StyledButton,
} from '../styles';

const ClientTile = (props) => {
	const {
		history,
		id,
		name,
		background_check_status,
		distanceToUser,
		averageRating,
		first_name,
		last_name,

		gender,
		care_type,
		image_url,
		display_name,
		professional_statement,
		buttonText,
		onSelectCarePro,
		hourly_rate,
		clientClickHandler,
	} = props;

	return (
		<StyledTileContainer>
			<StyledAvatarContainer />

			<StyledPersonalInfoContainer>
				<StyledPersonalInfoWrapper>
					<StyledPersonalInfo>
						<StyledName>{`${name}`}</StyledName>
					</StyledPersonalInfo>
					{/* <StyledRate>{`${address_street} ${address_street_2}, ${address_city}, ${address_state} ${address_zip}`}</StyledRate> */}
				</StyledPersonalInfoWrapper>
				<StyledLocation>
					{/* {`${new Date(start_time).toLocaleDateString('en-GB', {
						day: '2-digit',
						month: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
						hour12: true,
					})} - ${new Date(end_time).toLocaleDateString('en-GB', {
						day: '2-digit',
						month: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
						hour12: true,
					})}`} */}
				</StyledLocation>
				<StyledDescriptionContainer></StyledDescriptionContainer>
				<StyledButtonContainer>
					<StyledButton onClick={() => {}}>{buttonText}</StyledButton>
					<StyledButton isTransparent onClick={() => clientClickHandler(id)}>
						View
					</StyledButton>
				</StyledButtonContainer>
			</StyledPersonalInfoContainer>
		</StyledTileContainer>
	);
};

export default ClientTile;
