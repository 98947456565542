import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';

import { ColumnFlex } from 'basicStyles';
import useWindowSize from 'scripts/useWindowSize';
import api from 'scripts/api';

import { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';
import StyledTextInput from 'AppComp/styled/StyledTextInput';

import { CARE_PLAN_ACTIONS } from './CarePlanParent';
import {
	FORM_ITEM_LENGTH,
	CarePlanComponentsFormSubmitButton,
	FormItemsLength,
	FormButtonStyled,
} from './CarePlanviews';

import { GENDER } from 'constants';
import { CARE_NEEDS_MODEL } from 'reducers/graphqlModelTypes';

const ClientInfo = (props) => {
	const { carePlan, carePlanSet, updateSetTraits, setCarePlanAction, loading } = props;
	const [initialValues, setInitialValues] = useState(null);
	const { width } = useWindowSize();

	useEffect(() => {
		renderInitialValues(carePlanSet);
	}, [carePlanSet]);

	const renderInitialValues = (careNeedsSet) => {
		console.log('Initial values - careNeedsSet', careNeedsSet);

		const lookup = careNeedsSet.setData.reduce((acc, item) => {
			acc[item.id] = {
				trait_id: item.id,
				type: item.trait_type,
				value: item.value ? item.value.trait_value : null,
			};
			return acc;
		}, {});

		console.log('lookup', lookup);
		setInitialValues({
			firstName: lookup[60].value,
			lastName: lookup[61].value,
			displayName: lookup[62].value,
			dob: `${lookup[65].value}-${String(lookup[64].value).padStart(2, '0')}-${String(
				lookup[63].value
			).padStart(2, '0')}`, // Construct the date string here
			gender: lookup[66].value || null,
		});
	};

	const handleSubmit = (values, form) => {
		console.log('form', form);
		// get the differnce from values and initialValues
		const latestValues = values;
		const initValues = initialValues;

		const updateParams = [
			{ trait_id: 60, value: values.firstName },
			{ trait_id: 61, value: values.lastName },
			{ trait_id: 62, value: values.displayName },
			{ trait_id: 63, value: new Date(values.dob).getDate().toString() },
			{
				trait_id: 64,
				value: new Date(values.dob).toLocaleDateString('default', {
					month: '2-digit',
				}),
			},
			{ trait_id: 65, value: new Date(values.dob).getFullYear().toString() },
			{ trait_id: 66, value: values.gender },
		];

		updateSetTraits(updateParams);
	};

	if (!initialValues) {
		return <div>Loading...</div>;
	}

	console.log('initialValues', initialValues);
	return (
		<ColumnFlex
			style={{
				height: 'fit-content',
				backgroundColor: 'white',
				borderRadius: '12px',
			}}
			padding={width < 768 ? 0 : '60px 80px'}
			center
			gap='32px'
		>
			<ColumnFlex fullWidth center gap='8px'>
				<Title nubtitle fontWeight='700'>
					Tell us about the person who needs care
				</Title>
			</ColumnFlex>
			<ColumnFlex
				fullWidth
				style={{
					flex: 1,
					borderRadius: '24px',
				}}
				center
			>
				<Formik
					onSubmit={handleSubmit}
					initialValues={initialValues}
					enableReinitialize={true}
					autoComplete={'off'}
					render={(formProps) => (
						<Form noValidate>
							<ColumnFlex alignCenter justifyCenter fullWidth fullHeight>
								<FormItemsLength
									margin='30px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='firstName'
										component={StyledTextInput}
										label='First Name *'
										type='text'
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='lastName'
										component={StyledTextInput}
										label='Last Name *'
										type='text'
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='displayName'
										component={StyledTextInput}
										label='Display Name *'
										type='text'
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										label='Date of Birth*'
										name='dob'
										type='date'
										component={StyledTextInput}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<FormItemsLength
									margin='10px 0 0 0'
									width={width <= 768 ? '100%' : FORM_ITEM_LENGTH}
								>
									<Field
										name='gender'
										component={StyledTextSelectInputV2}
										label='Gender'
										options={GENDER}
										backgroundColor='white'
									/>
								</FormItemsLength>

								<CarePlanComponentsFormSubmitButton
									isValid={formProps.isValid}
									loading={loading}
									carePlan={carePlan}
									handleBack={() =>
										setCarePlanAction(CARE_PLAN_ACTIONS.CARE_PLAN_HOME)
									}
								/>

								{/* <FormButtonStyled
									backgroundColor={Colors.primary.primary}
									width={'300px'}
									height={'70px'}
									valid={formProps.isValid}
									borderRadius='32px'
									type='submit'
								>
									<Text
										fontWeight='500'
										color={formProps.isValid ? 'white' : Colors.primary.primary}
										largeText
									>
										{carePlan.completed_care_plan ? 'Update' : 'Continue'}
									</Text>
								</FormButtonStyled>

								{carePlan.completed_care_plan && (
									<Text
										margin='12px 0 0 0'
										onClick={() =>
											setCarePlanAction(CARE_PLAN_ACTIONS.CARE_PLAN_HOME)
										}
										style={{ cursor: 'pointer', marginTop: '16px' }}
										color={Colors.primary.primary}
										largeText
									>
										Back
									</Text>
								)} */}
							</ColumnFlex>
						</Form>
					)}
				/>
			</ColumnFlex>
		</ColumnFlex>
	);
};

export default ClientInfo;
