import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import is from 'styled-is';
import changeCase from 'change-case';

import { RowFlex, Avatar, DropShadow, ColumnFlex } from '../styled/Basic';

import Text from '../Text';
import Title from '../Title';
import Colors from '../../constants/colors';

import { formateMomentDate } from '../../scripts';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	width: 95%;

	${is('spaceBetween')`
		justify-content: space-between;
	`}
`;

const CustomRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	align-self: flex-start;
	flex-direction: ${({ flexDirection }) => flexDirection};
	justify-content: ${({ justify }) => justify};
	align-items: flex-start;
	margin-bottom: 16px;
`;

const CustomAvatar = styled(Avatar)`
	height: 80px;
	width: 80px;
	margin-right: none;
`;

const ChatBubble = styled.div`
	margin-left: 12px;
	margin-right: 12px;
	margin-bottom: 12px;
	border-radius: 12px;
	padding: 20px;
	${DropShadow(2)}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	margin: ${(props) => props.margin || '0'};
`;

const ChatMessageTile = (props) => {
	const {
		justify,
		flexDirection,
		image_url,
		timestamp,
		type,
		text,
		style,
		email_text,
		sent_by_admin,
	} = props;

	const renderText = () => {
		if (type === 'email') {
			return email_text;
		} else {
			return text;
		}
	};

	return (
		<CustomRow alignSelf={justify} flexDirection={flexDirection} style={style}>
			{image_url && (
				<Column>
					<CustomAvatar image_url={image_url} />
				</Column>
			)}
			<ColumnFlex>
				<ChatBubble
					style={{
						backgroundColor: sent_by_admin === 1 ? Colors.lightBlue : Colors.fullWhite,
					}}
					provider={type === 'provider'}
					user={type === 'user'}
				>
					<Text mediumText>{renderText()}</Text>
				</ChatBubble>
				<Text padding='0 10px' margin='0 0 8px 0' nurseInfo gray>
					{`${formateMomentDate(
						timestamp,
						'MM/DD hh:mm a'
					)} From ${changeCase.sentenceCase(type)}`}
				</Text>
			</ColumnFlex>
		</CustomRow>
	);
};

export default ChatMessageTile;
