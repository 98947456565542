import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { ColumnFlex, RowFlex } from '../../styled/Basic';
import Colors from 'colors';
import moment from 'moment';
import changeCase from 'change-case';

import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';

import { SELECTED_TAB_VIEW } from './ProviderHomeLandingHandler';
import {
	faMailBulk,
	faMapMarker,
	faChevronRight,
	faCalendarAlt,
	faMars,
	faVenus,
	faUser,
	faClipboard,
} from '@fortawesome/free-solid-svg-icons';
// create a styled compoent that will gray out the background and prevent clicks
const GrayOut = styled.div`
	filter: blur(4px);
	pointer-events: none;
`;

const toastSuccessConfig = {
	containerId: 'basic',
	autoClose: true,
};

export const ValidCon = (props) => {
	const isValid = props.valid;

	const handleInValid = () => {
		toast.info('Please complete your verification before continuing', toastSuccessConfig);
	};
	return (
		<div
			style={{
				position: 'relative',
			}}
		>
			{isValid ? (
				<>{props.children}</>
			) : (
				<div onClick={() => handleInValid()}>
					<GrayOut>{props.children}</GrayOut>
				</div>
			)}
		</div>
	);
};

const TileContainer = styled(RowFlex)`
	background-color: ${Colors.theme.dark_accent_background};
	max-width: 640px;
	width: 100%;
	justify-content: space-between;
	padding: 16px 20px 16px 8px;
	height: 160px;
	border-radius: 16px;
	gap: 16px;
	cursor: pointer;
`;

import { TabTile } from 'AppComp/patient/JobPost/JobPostViews';
export const SectionTabs = (props) => {
	const { selectedTab, handleSelectTab, totalInvited, totalOffered, totalApplied } = props;

	return (
		<RowFlex fullWidth>
			<TabTile
				label={SELECTED_TAB_VIEW.Invited}
				selectedTab={selectedTab}
				onClick={handleSelectTab}
				stats={totalInvited}
			/>
			<TabTile
				label={SELECTED_TAB_VIEW.Applied}
				selectedTab={selectedTab}
				onClick={handleSelectTab}
				stats={totalApplied}
			/>
			<TabTile
				label={SELECTED_TAB_VIEW.Offered}
				selectedTab={selectedTab}
				onClick={handleSelectTab}
				stats={totalOffered}
			/>
		</RowFlex>
	);
};
export const JobInviteTile = (props) => {
	console.log('jobInvite tiel', props);
	const { id, provider_id, job_post_id, jobPost, onClick } = props;
	const { budget_low, budget_high, status, care_plan_id, created_at, carePlan } = jobPost;
	const { dob, gender, address_city, address_zip, first_name, last_name } = carePlan;

	const convertDOBToReadableAge = (dob) => {
		// dob is in the format of 'YYYY-MM-DD'
		const age = moment().diff(dob, 'years');
		return `${age} years`;
	};

	return (
		<>
			<TileContainer onClick={() => onClick(props)}>
				<ColumnFlex
					style={{
						height: '100%',
						flex: 1,
						backgroundColor: 'white',
						borderRadius: '16px',
					}}
					gap='12px'
					center
					padding='0 12px'
				>
					<RowFlex alignCenter gap='4px'>
						{/* <Icon icon={faMailBulk} size='1.5x' color={Colors.greenLightTeal} /> */}
						<Text bold midLarge color={Colors.greenLightTeal}>
							Job Post Invite
						</Text>
					</RowFlex>

					<ColumnFlex>
						<Text bold midLarge color={'black'}>
							{`$${budget_low} - $${budget_high}`}
						</Text>
					</ColumnFlex>
					<Text subtitle fontStyle='thin'>
						per hour
					</Text>
				</ColumnFlex>
				<RowFlex
					alignCenter
					gap='8px'
					style={{
						flex: 3,
					}}
				>
					<ColumnFlex
						style={{
							flex: 1,
							//backgroundColor: 'white',
							borderRadius: '8px',
							width: '100%',
							height: '100%',
						}}
						padding='12px 0'
						alignEnd
						spaceBetween
					>
						<RowFlex
							style={{
								justifyContent: 'flex-end',
							}}
							gap='12px'
							fullWidth
							alignEnd
						>
							<Text mediumText>{first_name || 'N/A'}</Text>
							<Text mediumText>-</Text>
							<Text mediumText>{`Received: ${new Date(
								created_at
							).toLocaleDateString()}`}</Text>
						</RowFlex>
						<RowFlex
							style={{
								justifyContent: 'flex-end',
							}}
							gap='12px'
							fullWidth
							alignEnd
						>
							<Text mediumText>{`Area: ${address_city} / ${address_zip}`}</Text>
							<Text mediumText>-</Text>
							<Text mediumText>X days a week</Text>
						</RowFlex>
						<RowFlex
							style={{
								justifyContent: 'flex-end',
							}}
							gap='12px'
							fullWidth
							alignEnd
						>
							<Text mediumText>{changeCase.sentenceCase(gender)}</Text>
							<Text mediumText>-</Text>
							<Text mediumText>{convertDOBToReadableAge(dob)}</Text>
						</RowFlex>
					</ColumnFlex>
					<Icon
						style={{
							cursor: 'pointer',
						}}
						icon={faChevronRight}
						size='2x'
						color={Colors.greenLightTeal}
					/>
					{/* <ColumnFlex
							gap='8px'
							fullWidth
							alignEnd
							style={{ flex: 1, height: '100%' }}
						>
							<RowFlex
								style={{
									flex: 1,
								}}
								padding='8px'
								justifyBetween
							>
								<RowFlex gap='8px'>
									<Icon
										size='1x'
										color={Colors.greenLightTeal}
										icon={faMapMarker}
									/>
									<Text> {`${address_city} - ${address_zip}`}</Text>
								</RowFlex>

								<RowFlex gap='8px'>
									<Icon
										size='1x'
										color={Colors.greenLightTeal}
										icon={faCalendarAlt}
									/>
									<Text>4 Days a week</Text>
								</RowFlex>
							</RowFlex>
							<RowFlex
								style={{
									flex: 1,
								}}
								padding='8px'
								justifyBetween
							>
								<RowFlex gap='8px'>
									<Icon
										size='1x'
										color={Colors.greenLightTeal}
										icon={gender === 'female' ? faVenus : faMars}
									/>

									<Text> {gender}</Text>
								</RowFlex>

								<RowFlex gap='8px'>
									<Icon size='1x' color={Colors.greenLightTeal} icon={faUser} />
									<Text>{convertDOBToReadableAge(dob)}</Text>
								</RowFlex>
							</RowFlex>
						</ColumnFlex> */}
				</RowFlex>
			</TileContainer>
		</>
	);
};

export const JobApplicationTile = (props) => {
	const { id, provider_id, job_post_id, jobPost, onClick } = props;
	const { budget_low, budget_high, status, care_plan_id, created_at, carePlan } = jobPost;
	const { dob, gender, address_city, address_zip, first_name } = carePlan;

	const convertDOBToReadableAge = (dob) => {
		// dob is in the format of 'YYYY-MM-DD'
		const age = moment().diff(dob, 'years');
		return `${age} years`;
	};

	return (
		<TileContainer onClick={() => onClick(props)}>
			<ColumnFlex
				style={{
					height: '100%',
					flex: 1,
					backgroundColor: 'white',
					borderRadius: '16px',
				}}
				gap='12px'
				center
				padding='0 12px'
			>
				<RowFlex alignCenter gap='4px'>
					{/* <Icon icon={faMailBulk} size='1.5x' color={Colors.greenLightTeal} /> */}
					<Text bold midLarge color={Colors.greenLightTeal}>
						Job Application
					</Text>
				</RowFlex>

				<ColumnFlex>
					<Text bold midLarge color={'black'}>
						{`$${budget_low} - $${budget_high}`}
					</Text>
				</ColumnFlex>
				<Text subtitle fontStyle='thin'>
					per hour
				</Text>
			</ColumnFlex>
			<RowFlex
				alignCenter
				gap='8px'
				style={{
					flex: 3,
				}}
			>
				<ColumnFlex
					style={{
						flex: 1,
						//backgroundColor: 'white',
						borderRadius: '8px',
						width: '100%',
						height: '100%',
					}}
					padding='12px 0'
					alignEnd
					spaceBetween
				>
					<RowFlex
						style={{
							justifyContent: 'flex-end',
						}}
						gap='12px'
						fullWidth
						alignEnd
					>
						<Text mediumText>{first_name || 'N/A'}</Text>
						<Text mediumText>-</Text>
						<Text mediumText>{`Received: ${new Date(
							created_at
						).toLocaleDateString()}`}</Text>
					</RowFlex>
					<RowFlex
						style={{
							justifyContent: 'flex-end',
						}}
						gap='12px'
						fullWidth
						alignEnd
					>
						<Text mediumText>{`Area: ${address_city} / ${address_zip}`}</Text>
						<Text mediumText>-</Text>
						<Text mediumText>X days a week</Text>
					</RowFlex>
					<RowFlex
						style={{
							justifyContent: 'flex-end',
						}}
						gap='12px'
						fullWidth
						alignEnd
					>
						<Text mediumText>{changeCase.sentenceCase(gender)}</Text>
						<Text mediumText>-</Text>
						<Text mediumText>{convertDOBToReadableAge(dob)}</Text>
					</RowFlex>
				</ColumnFlex>
				<Icon
					style={{
						cursor: 'pointer',
					}}
					icon={faChevronRight}
					size='2x'
					color={Colors.greenLightTeal}
				/>
			</RowFlex>
		</TileContainer>
	);
};

export const JobOfferTile = (props) => {
	const { hourly_amount, status, created_at, carePlan, onClick } = props;

	const { dob, gender, address_city, address_zip, first_name } = carePlan;

	const convertDOBToReadableAge = (dob) => {
		// dob is in the format of 'YYYY-MM-DD'
		const age = moment().diff(dob, 'years');
		return `${age} years`;
	};

	return (
		<TileContainer onClick={() => onClick(props)}>
			<ColumnFlex
				style={{
					height: '100%',
					flex: 1,
					backgroundColor: 'white',
					borderRadius: '16px',
				}}
				gap='12px'
				center
				padding='0 12px'
			>
				<RowFlex alignCenter gap='4px'>
					{/* <Icon icon={faMailBulk} size='1.5x' color={Colors.greenLightTeal} /> */}
					<Text bold midLarge color={Colors.greenLightTeal}>
						Job Offer
					</Text>
				</RowFlex>

				<ColumnFlex>
					<Text bold midLarge color={'black'}>
						{`$${hourly_amount / 100}`}
					</Text>
				</ColumnFlex>
				<Text subtitle fontStyle='thin'>
					per hour
				</Text>
			</ColumnFlex>

			<RowFlex
				alignCenter
				gap='8px'
				style={{
					flex: 3,
				}}
			>
				<ColumnFlex
					style={{
						flex: 1,
						//backgroundColor: 'white',
						borderRadius: '8px',
						width: '100%',
						height: '100%',
					}}
					padding='12px 0'
					alignEnd
					spaceBetween
				>
					<RowFlex
						style={{
							justifyContent: 'flex-end',
						}}
						gap='12px'
						fullWidth
						alignEnd
					>
						<Text mediumText>{first_name || 'N/A'}</Text>
						<Text mediumText>-</Text>
						<Text mediumText>{`Received: ${new Date(
							created_at
						).toLocaleDateString()}`}</Text>
					</RowFlex>
					<RowFlex
						style={{
							justifyContent: 'flex-end',
						}}
						gap='12px'
						fullWidth
						alignEnd
					>
						<Text mediumText>{`Area: ${address_city} / ${address_zip}`}</Text>
						<Text mediumText>-</Text>
						<Text mediumText>X days a week</Text>
					</RowFlex>
					<RowFlex
						style={{
							justifyContent: 'flex-end',
						}}
						gap='12px'
						fullWidth
						alignEnd
					>
						<Text mediumText>{changeCase.sentenceCase(gender)}</Text>
						<Text mediumText>-</Text>
						<Text mediumText>{convertDOBToReadableAge(dob)}</Text>
					</RowFlex>
				</ColumnFlex>
				<Icon
					style={{
						cursor: 'pointer',
					}}
					icon={faChevronRight}
					size='2x'
					color={Colors.greenLightTeal}
				/>
			</RowFlex>
		</TileContainer>
	);
};
