import React, { useEffect } from 'react';
import qsueryString from 'query-string';
import { connect } from 'react-redux';

import { verifyUserEmail } from 'reducers/patient';

import { ColumnFlex } from 'basicStyles';

import Text from 'AppComp/Text';

import { Section } from './WelcomePageViews';

import { LandingSection, Tiles } from './WelcomePageStyles';
import Colors from '../../../constants/colors';

const WelcomePageHandler = (props) => {
	const { history, patient, checkUserSession } = props;

	useEffect(() => {
		if (props.location.pathname === '/verifyAccount') {
			const queryParams = qsueryString.parse(location.search, { arrayFormat: 'comma' });

			const user_id = queryParams.user_id ? parseInt(queryParams.user_id, 10) : 0;
			const params = {
				user_id: user_id,
				hashed_key: queryParams.hashed_key,
			};
			verifyUserEmail(params);
		}
	}, []);

	return (
		<ColumnFlex
			style={{
				backgroundColor: Colors.theme.primary_background,
			}}
			fullWidth
		>
			<LandingSection patient={patient} history={history} />
			<Section height='auto' backgroundColor={'white'}>
				{/* <Tiles history={history} /> */}
			</Section>

			{/* <InfoSection />
			<InfoSection2 /> */}

			{/* <Text margin='24px' /> */}
		</ColumnFlex>
	);
};

const mapStateToProps = ({ patient }) => {
	return {
		patient: patient,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		verifyUserEmail: (params) => dispatch(verifyUserEmail(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePageHandler);
