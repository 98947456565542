import React, { useState } from 'react';

import {
	OnboardFormContainer,
	OnboardTitle,
	OnboardSubText,
	OnboardButton,
	OnboardFormButton,
	FormItemsLength,
} from '../CareProOnboardViews';
import { ColumnFlex } from 'basicStyles';

import { Formik, Form, Field } from 'formik';
import StyledTextInput, { StrongPasswordTextInput } from 'AppComp/styled/StyledTextInput';
import Colors from 'colors';

import { validateAuth } from './validate';
import Title from 'AppComp/Title';
import PageLoaderView from 'AppComp/PageLoaderView';

import api from 'scripts/api';
import { toast } from 'react-toastify';

import { toastErrorConfig, toastSuccessConfig } from 'reducers/provider';

import {
	PROVIDER_MODEL_V2,
	STRIPE_STATUS_MODEL,
	CARE_PRO_REGISTRY_STATUS_MODEL,
	VERIFICATION_MODEL,
	PAYOUT_METHOD_MODEL,
} from 'reducers/graphqlModelTypes';

const PhoneVerify = (props) => {
	const {
		handleSubNavTileClick,
		renderCurrentStep,
		handleProviderLogin,
		handleDataUpdate,
		careProAccountInfo,
	} = props;

	const [err, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleSubmit = (values, form) => {
		const updateData = {};

		//handleDataUpdate(updateData, 4);

		handleRegister(values.auth_code.trim());
	};

	const handleRegister = async (auth_code) => {
		try {
			setLoading(true);
			setError(null);
			const { email, password, first_name, last_name, phone_number, display_name, gender } =
				careProAccountInfo;

			let registryId = 1;

			const subdomain = window.location.hostname.split('.')[0];
			if (subdomain === 'findyourindependence') {
				// Render content for sub1
				registryId = 2;
			} else if (subdomain === 'philia') {
				// Render content for sub2
				registryId = 3;
			}

			const registerRes = await api.graph({
				query: `mutation {
          registerProvider(
            email: "${email}",
            password: "${password}",
 		        first_name: "${first_name}",
 		        last_name: "${last_name}",
            phone_number: "${phone_number}"
            auth_code: "${auth_code.trim()}",
						gender: "${gender}",
						display_name: "${display_name}",
						registry_id: ${registryId}
 	        ) {
            token,
            provider {
              ${PROVIDER_MODEL_V2},
						
            }
          }
        }`,
			});

			// we now need to create the stripe account for the carePro
			if (registerRes.registerProvider) {
				const { token } = registerRes.registerProvider;
				localStorage.setItem('user-token', token);

				//renderCurrentStep()
				//handleProviderLogin(token, provider);

				const stripeAccountRes = await api.graph({
					query: `mutation {
            createProviderStripeAccount {
							${PROVIDER_MODEL_V2},
							stripeStatus {
								${STRIPE_STATUS_MODEL}
							}
							careProRegistryStatus {
								${CARE_PRO_REGISTRY_STATUS_MODEL}
							}
            }
          }`,
				});
				setLoading(false);
				if (stripeAccountRes.createProviderStripeAccount) {
					const { createProviderStripeAccount } = registerRes;

					handleProviderLogin(createProviderStripeAccount);
					handleDataUpdate(careProAccountInfo, 5);
				} else {
					toast.error('Something went wrong, please try again', toastErrorConfig);
				}
				//createProviderStripeAccount;
			}
		} catch (err) {
			console.log('registerProvider -> err', err);
			setLoading(false);
			if (Array.isArray(err)) {
				toast.error([0].message, toastErrorConfig);
				setError(err[0].message);
			} else {
				setError('Please try again');
				toast.error('Please try again', toastErrorConfig);
			}
		}
	};

	const resendCode = async () => {
		try {
			const verifyPhone = await api.graph({
				query: `mutation {
					verifyProviderPhone(phone_number: "${careProAccountInfo.phone_number}", email: "${careProAccountInfo.email}") 
				}`,
			});
			toast.success('Code resent', toastSuccessConfig);
		} catch (err) {
			console.log('resendCode -> err', err);
			setError('Please try again');
			toast.error('Please try again', toastErrorConfig);
		}
	};

	const phoneParsing = (phone_number) => {
		// Convert the number to a string and pad with a leading zero if necessary
		let numberStr = phone_number.toString().padStart(9, '0');

		// Use a regex to format the number as (012) 345-6789
		return numberStr.replace(/(\d{3})(\d{3})(\d{3})/, '($1) $2-$3');
	};
	return (
		<OnboardFormContainer
			style={{
				width: 'fit-content',
			}}
			center
		>
			<OnboardTitle
				style={{
					maxWidth: '600px',
				}}
			>
				FYI values your privacy - We just want to be sure it is you !
			</OnboardTitle>
			<OnboardSubText>
				{`Please type the numerical confirmation code that has been texted to you ${phoneParsing(
					careProAccountInfo.phone_number
				)}`}
			</OnboardSubText>

			<Formik
				onSubmit={handleSubmit}
				autoComplete={'off'}
				validate={validateAuth}
				render={(formProps) => {
					console.log(formProps);
					return (
						<Form noValidate>
							<ColumnFlex alignCenter justifyCenter fullWidth fullHeight>
								<FormItemsLength>
									<Field
										component={StyledTextInput}
										name='auth_code'
										label='Auth Code'
										type='text'
									/>
								</FormItemsLength>

								{loading ? (
									<PageLoaderView errorMessage='This may take a min, please hold off from refreshing' />
								) : (
									<OnboardFormButton
										backgroundColor={Colors.teal}
										type='submit'
										valid={formProps.isValid}
										text='Next'
									>
										Next
									</OnboardFormButton>
								)}
								{err && (
									<OnboardSubText
										style={{ color: 'red' }}
										margin='20px 0 0 0 '
										bold
										error
									>
										{err}
									</OnboardSubText>
								)}
								{!loading && (
									<OnboardSubText
										onClick={() => resendCode()}
										margin='20px 0 0 0'
										style={{ cursor: 'pointer', textDecoration: 'underline' }}
									>
										Re Send Text
									</OnboardSubText>
								)}
							</ColumnFlex>
						</Form>
					);
				}}
			/>
			<div style={{ marginBottom: '10px' }} />
			<OnboardSubText
				onClick={() => handleSubNavTileClick(3)}
				style={{
					textDecoration: 'underline',
					cursor: 'pointer',
				}}
			>
				Miss typed? Change Email Or Phone Number
			</OnboardSubText>
		</OnboardFormContainer>
	);
};

export default PhoneVerify;
