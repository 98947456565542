import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useAuth } from '../hooks/useAuth';
import { coreRoutes } from '../../core/routes/constants';

const PrivateRoute = ({ component: Component, ...rest }) => {
	const { isAuthenticated } = useAuth();

	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? <Component {...props} /> : <Redirect to={coreRoutes.root} />
			}
		/>
	);
};

export default PrivateRoute;
