import React, { useState, Component, Fragment } from 'react';
import styled from 'styled-components';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import is from 'styled-is';
import Colors from 'colors';

import AnimationWrapper from 'AppComp/AnimationWrapper';
import { ColumnFlex, DropShadow } from 'basicStyles';
import Icon from 'AppComp/Icon';
import { Walkthrough } from './WalkViews';

const Container = styled.div`
	border-radius: 50%;
	height: 80px;
	width: 80px;
	transition: all 0.4s ease-in-out 0.3s;
	position: fixed;
	background-color: ${Colors.greenLightTeal};
	bottom: 20px;
	left: 10px;
	z-index: 1000;
	cursor: pointer;
	${DropShadow(3)}

	${is('active')`
		height: 90vh;
		width: 450px;
		border-radius: 6px;
		transition: all .4s ease-in-out;
    background-color: ${Colors.fullWhite};
	`}
  ${is('bounce')`
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	  transform: scale(1);
	  animation: pulse 2s infinite;
	`}
  
 

	@keyframes pulse {
		0% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
		}

		70% {
			transform: scale(1);
			box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
		}

		100% {
			transform: scale(0.95);
			box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
		}
	}
`;

const IconContainer = styled(ColumnFlex)`
	transition: opacity ease 0.3s;
	opacity: 0;

	&.p-i-appear {
		transition: opacity ease 0.3s;
		opacity: 1;
	}
	&.p-i-enter {
		transition: opacity ease 0.3s;
		opacity: 0;
	}
	&.pi-enter-active {
		transition: opacity ease 0.3s;
		opacity: 1;
	}
	&.p-i-enter-done {
		transition: opacity ease 0.3s;
		opacity: 1;
	}

	&.p-i-exit {
		transition: opacity ease 0.3s;
		opacity: 1;
	}

	&.p-i-exit-acitve {
		transition: opacity ease 0.3s;
		opacity: 1;
	}
	&.p-i-exit-done {
		transition: opacity ease 0.3s;
		opacity: 0;
	}
`;

const WalkHandler = ({ history, location, toggleOnboardOverlay }) => {
	const [showOverlay, updateOverlayStatus] = useState(false);

	return (
		<Container bounce={!showOverlay} active={showOverlay}>
			<AnimationWrapper status={showOverlay} classNames='p-c'>
				<Walkthrough
					history={history}
					location={location}
					toggleOnboardOverlay={toggleOnboardOverlay}
					updateOverlayStatus={updateOverlayStatus}
				/>
			</AnimationWrapper>
			<AnimationWrapper status={!showOverlay} classNames='p-i' appear>
				<IconContainer fullHeight center onClick={() => updateOverlayStatus(!showOverlay)}>
					<Icon size='2x' icon={faHeart} color={Colors.fullWhite} />
				</IconContainer>
			</AnimationWrapper>
		</Container>
	);
};

export default WalkHandler;
