import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchProviderPayments, fetchProviderPayouts } from 'reducers/provider';

import Table from '../../corev2/Table';

import { StyledPaymentsPage, StyledHeading, StyledTableContainer } from './styles';

const PaymentsPage = (props) => {
	const [paymentsData, setPaymentsData] = useState([]);
	const [showTransactionsType, setShowTransactionsType] = useState('payments');
	const { payments, payouts, fetchProviderPayments, fetchProviderPayouts } = props;

	useEffect(() => {
		if (showTransactionsType === 'payments') {
			fetchProviderPayments();
		}

		if (showTransactionsType === 'payouts') {
			fetchProviderPayouts();
		}
	}, [showTransactionsType]);

	useEffect(() => {
		if (true) {
			const formattedPaymentsData = payments.map((payment) => [
				moment.unix(payment.timestamp).format('MM/DD/YYYY'),
				payment.hourly_rate.toString(),
				payment.total_time_worked?.toString() || '',
				payment.subtotal?.toString() || '',
				payment.fyi_share?.toString() || '',
				payment.provider_payout?.toString() || '',
				payment.user.name?.toString() || '',
				payment.provider.display_name?.toString() || '',
			]);

			setPaymentsData(formattedPaymentsData);
		}
	}, [payments]);

	const headers = [
		'Date',
		'Hourly Rate',
		'Hours',
		'Subtotal',
		'FYI Share',
		'Provider Payout',
		'User',
		'Provider',
	];

	return (
		<StyledPaymentsPage>
			<StyledHeading>Payments</StyledHeading>

			<StyledTableContainer>
				<Table headers={headers} rows={paymentsData} />
			</StyledTableContainer>
		</StyledPaymentsPage>
	);
};

const mapStateToProps = ({ provider }) => {
	return {
		payments: provider.payments,
		payouts: provider.payouts,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchProviderPayments: () => dispatch(fetchProviderPayments()),
		fetchProviderPayouts: () => dispatch(fetchProviderPayouts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsPage);
