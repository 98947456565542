import React, { useState } from 'react';

import {
	OnboardFormContainer,
	OnboardTitle,
	OnboardSubText,
	OnboardButton,
	OnboardFormButton,
	FormItemsLength,
} from '../CareProOnboardViews';
import { ColumnFlex } from 'basicStyles';

import { Formik, Form, Field } from 'formik';
import { validateProviderInfo } from './validate';
import StyledTextInput, { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';
import Colors from 'colors';

const GENDERS = [
	{
		value: '',
		label: '',
	},
	{
		value: 'female',
		label: 'Female',
	},
	{
		value: 'male',
		label: 'Male',
	},
];

const PersonalInfo = (props) => {
	const { handleDataUpdate, careProAccountInfo } = props;

	const handleSubmit = (values, form) => {
		const updateData = {
			first_name: values.first_name,
			last_name: values.last_name,
			gender: values.gender,
			display_name: values.display_name,
		};

		handleDataUpdate(updateData, 3);
	};

	const handleInitValue = () => {
		const init = {
			first_name: careProAccountInfo.first_name,
			last_name: careProAccountInfo.last_name,
			gender: careProAccountInfo.gender,
			display_name: careProAccountInfo.display_name,
		};

		return init;
	};

	return (
		<OnboardFormContainer
			style={{
				width: 'fit-content',
			}}
			center
		>
			<OnboardTitle>Tell us about a little about yourself</OnboardTitle>
			<Formik
				onSubmit={handleSubmit}
				autoComplete={'off'}
				validate={validateProviderInfo}
				initialValues={handleInitValue()}
				render={(formProps) => {
					console.log(formProps);
					return (
						<Form noValidate>
							<ColumnFlex
								alignCenter
								justifyCenter
								margin='10px 0 0 0'
								fullWidth
								fullHeight
								gap='20px'
							>
								<FormItemsLength>
									<Field
										name='first_name'
										type='text'
										component={StyledTextInput}
										label='First Name'
									/>
								</FormItemsLength>
								<FormItemsLength>
									<Field
										name='last_name'
										type='text'
										component={StyledTextInput}
										label='Last Name'
									/>
								</FormItemsLength>
								<FormItemsLength>
									<Field
										component={StyledTextSelectInputV2}
										name='gender'
										label='Gender'
										options={GENDERS}
									/>
								</FormItemsLength>
								<FormItemsLength>
									<Field
										name='display_name'
										type='text'
										component={StyledTextInput}
										label='Display Name'
									/>
								</FormItemsLength>

								<OnboardFormButton
									backgroundColor={Colors.primary.primary}
									type='submit'
									valid={formProps.isValid}
								>
									Next
								</OnboardFormButton>
							</ColumnFlex>
						</Form>
					);
				}}
			/>
		</OnboardFormContainer>
	);
};

export default PersonalInfo;
