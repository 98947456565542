import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from 'AppComp/Button';
import Colors from 'colors';

export const Container = styled.div`
	padding: 20px;
	font-family: Arial, sans-serif;
	background-color: #f0f4f8;
	min-height: 100vh;
`;

export const PageContainer = styled.div`
	padding: 20px;
	font-family: Arial, sans-serif;
	background-color: #f0f4f8;
	min-height: 100vh;
`;

export const Title = styled.h1`
	text-align: center;
	margin-bottom: 20px;
	font-size: 2em;
`;

export const SubTitle = styled.h1`
	text-align: center;
	margin-bottom: 20px;
	font-size: 1.6em;
`;
export const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(${(props) => props.columns}, 1fr);
	gap: 20px;
	margin-bottom: 40px;
`;

//	border: 1px solid #ccc;

export const Item = styled.div`
	border-radius: 4px;
	padding: 10px;
	background-color: white;
`;
// const ItemCon = (props) => {
// 	const { children  } = props;

// 	return <ItemCon>
// 		{children}
// 	</ItemCon>
// }

export const Label = styled.h6`
	margin-bottom: 5px;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.4;
`;

export const Input = styled.h3`
	font-size: 16px;
	font-weight: 700;
	line-height: 1.4;
`;

const OnboardButtonStyle = styled(Button)`
	min-width: 240px;
	height: 70px;
	border-radius: 50px;
	box-shadow: none;

	${({ valid, backgroundColor }) =>
		!valid &&
		`
		background-color: white;
		border: 1px solid ${backgroundColor || Colors.primary.primary};
		color: ${backgroundColor || Colors.primary.primary};

	`}

	${({ valid, backgroundColor }) =>
		valid &&
		`
		background-color: ${backgroundColor || Colors.primary.primary};
		color: white;
	`}
`;

export const DataViewButton = (props) => {
	const { backgroundColor, children, margin, onClick } = props;

	return (
		<OnboardButtonStyle
			margin={margin || '0'}
			backgroundColor={backgroundColor}
			valid={true}
			onClick={onClick}
		>
			<Title
				style={{
					margin: 0,
				}}
				trititle
				color={Colors.fullWhite}
			>
				{children}
			</Title>
		</OnboardButtonStyle>
	);
};
