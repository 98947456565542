import moment from 'moment';
import { z } from 'zod';

export const shiftSelectorValidationSchema = z
	.object({
		startDate: z
			.string()
			.refine((val) => moment(val, 'YYYY-MM-DD', true).isValid(), {
				message: 'Start date is required and must be a valid date.',
			})
			.refine((val) => moment(val, 'YYYY-MM-DD').isSameOrAfter(moment().startOf('day')), {
				message: 'Start date cannot be in the past.',
			}),
		endDate: z.string().refine((val) => moment(val, 'YYYY-MM-DD', true).isValid(), {
			message: 'End date is required and must be a valid date.',
		}),
	})
	.refine(
		(data) => moment(data.endDate, 'YYYY-MM-DD').isAfter(moment(data.startDate, 'YYYY-MM-DD')),
		{
			message: 'End date must be after start date.',
			path: ['endDate'],
		}
	);
