import React from 'react';
import styled from 'styled-components';
import is from 'styled-is';

import colors from 'colors';

const Button = styled.div`
	border-radius: ${({ borderRadius }) => borderRadius || '4px'};
	color: ${({ color }) => color || 'white'};
	border: ${({ border }) => border || 'none'};
	width: ${({ width }) => width || '150px'};
	height: ${({ height }) => height || '40px'};
	background-color: ${({ backgroundColor }) => backgroundColor || 'initial'};
	display: ${({ display }) => display || 'flex'};
	margin: ${(props) => props.margin || '0'};
	padding: ${(props) => props.padding || '0'};
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
	transition: all ease 0.3s;
	text-align: center;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}

	${is('primary')`

    `}
	${is('secondary')`

    `}
    ${is('invalid')`
        background-color: white;

    `}

    ${is('gradient')`
        background: linear-gradient(to right, #58186C, #C55161);
    `}

    ${is('hidden')`
        background: linear-gradient(to right, #58186C, #C55161);
    `}

    ${is('roundBack')`
        box-shadow: none
        opacity: .5
    `}
`;

const StyledButton = styled.input`
	border-radius: ${({ borderRadius }) => borderRadius || '4px'};
	color: ${({ color }) => color || 'white'};
	border: ${({ border }) => border || 'none'};
	width: ${({ width }) => width || '150px'};
	height: ${({ height }) => height || '40px'};
	background-color: ${({ backgroundColor }) => backgroundColor || 'initial'};
	display: ${({ display }) => display || 'flex'};
	margin: ${(props) => props.margin || '0'};
	padding: ${(props) => props.padding || '0'};
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.3);
	transition: all ease 0.3s;
	text-align: center;
	align-items: center;
	justify-content: center;
	cursor: pointer;

	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}

	${is('primary')`

    `}
	${is('secondary')`

    `}
    ${is('invalid')`
        background-color: white;

    `}

    ${is('gradient')`
        background: linear-gradient(to right, #58186C, #C55161);
    `}

    ${is('hidden')`
        background: linear-gradient(to right, #58186C, #C55161);
    `}

    ${is('roundBack')`
        box-shadow: none
        opacity: .5
    `}
`;

const StyledCheckBoxContainer = styled.div`
	width: 100%;
	padding-bottom: 10px;
`;

export const FormCheckboxButton = (props) => {
	const { type, name, value, icons, onChange, active, id, text } = props;
};
export default Button;
