import React, { Fragment } from 'react';

import StepItem from './StepItem';
import CompletedStep from './StepItem/CompletedStep';

import { StyledSteps, StyledDivider } from './styles';

const Steps = (props) => {
	const { steps } = props;

	return (
		<StyledSteps>
			{Object.entries(steps).map(([key, step], index) => (
				<Fragment key={key}>
					<StepItem isCompleted={step.isCompleted}>{step.label}</StepItem>
					{index < Object.keys(steps).length - 1 && <StyledDivider />}
				</Fragment>
			))}
		</StyledSteps>
	);
};

export { StepItem, CompletedStep };
export default Steps;
