import React, { Component, Fragment } from 'react';
import { Hearts } from 'react-loader-spinner';
import { withTheme } from 'styled-components';

import { ColumnFlex } from 'basicStyles';
import Color from 'colors';

import Text from './Text';

class PageLoaderView extends Component {
	state = {
		showError: false,
	};

	componentDidMount() {
		this.loaderTimer = setTimeout(() => this.toggleStateErr(), 8000);
	}

	componentWillUnmount() {
		if (this.loaderTimer) {
			clearTimeout(this.loaderTimer);
		}
	}

	toggleStateErr = () => {
		this.setState({
			showError: true,
		});
	};

	render() {
		const { showError } = this.state;

		const { errorMessage, theme } = this.props;

		return (
			<ColumnFlex fullHeight flex fullWidth center>
				{showError ? (
					<Text color='black'>{errorMessage}</Text>
				) : (
					<Hearts color={Color.primary.primary} height={100} width={100} />
				)}
			</ColumnFlex>
		);
	}
}

export default withTheme(PageLoaderView);
