import React, { useState } from 'react';
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from '@stripe/react-stripe-js';

import InputContainer from '../InputContainer';
import { ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import { StyledCardForm, StyledCardLabel } from './styles';

const PayoutMethodForm = (props) => {
	const { addPayoutMethodHandler } = props;
	const stripe = useStripe();
	const elements = useElements();

	const [isSubmitting, setIsSubmitting] = useState(false);
	const [error, setError] = useState('');

	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsSubmitting(true);
		setError('');

		if (!stripe || !elements) {
			setError('Stripe or Elements not properly initialized');
			setIsSubmitting(false);
			return;
		}

		const cardElement = elements.getElement(CardNumberElement);
		if (!cardElement) {
			setError('CardElement not found');
			setIsSubmitting(false);
			return;
		}

		const { error, token } = await stripe.createToken(cardElement, { currency: 'usd' });
		if (error) {
			console.error('Error creating token:', error.message);
			setError(error.message);
			setIsSubmitting(false);
		} else {
			await addPayoutMethodHandler(token.id);
			cardElement.clear();
			elements.getElement(CardExpiryElement).clear();
			elements.getElement(CardCvcElement).clear();
			setIsSubmitting(false);
		}
	};

	return (
		<StyledCardForm onSubmit={handleSubmit}>
			<InputContainer>
				<StyledCardLabel htmlFor='cardNumber'>Card Number</StyledCardLabel>
				<CardNumberElement
					id='cardNumber'
					options={{ style: { base: { fontSize: '16px' } } }}
				/>
			</InputContainer>

			<InputContainer>
				<StyledCardLabel htmlFor='expirationDate'>Expiration Date</StyledCardLabel>
				<CardExpiryElement
					id='expirationDate'
					options={{ style: { base: { fontSize: '16px' } } }}
				/>
			</InputContainer>

			<InputContainer>
				<StyledCardLabel htmlFor='cvc'>CVC</StyledCardLabel>
				<CardCvcElement id='cvc' options={{ style: { base: { fontSize: '16px' } } }} />
			</InputContainer>

			<InputContainer>
				<PrimaryButton
					size='small'
					type='submit'
					disabled={!stripe || !elements || isSubmitting}
				>
					{isSubmitting ? 'Processing...' : 'Add Payout Method'}
				</PrimaryButton>

				{error && <ErrorMessage>{error}</ErrorMessage>}
			</InputContainer>
		</StyledCardForm>
	);
};

export default PayoutMethodForm;
