import React, { useEffect, useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { useCheckUserSessionQuery } from '../../api/queries/useCheckUserSessionQuery';

import { coreRoutes } from '../../routes/constants';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [isRegistryApproved, setIsRegistryApproved] = useState(false);
	const [authUser, setAuthUser] = useState(null);
	const [hasDefaultPaymentMethod, setHasDefaultPaymentMethod] = useState(false);
	const [statusUpdate, setStatusUpdate] = useState(null);
	const [hasCompletedPlatformOnboarding, setHasCompletedPlatformOnboarding] = useState(false);
	const [appIsLoading, setAppIsLoading] = useState(true);

	const history = useHistory();

	const { data, isLoading } = useCheckUserSessionQuery();

	useEffect(() => {
		if (!isLoading) {
			setAppIsLoading(false);
		}
	}, [isLoading]);

	useEffect(() => {
		if (authUser) {
			if (authUser.carePlan && authUser.carePlan.completed_platform_onboard) {
				if (authUser.carePlan.completed_platform_onboard) {
					setHasCompletedPlatformOnboarding(true);
				}

				if (!authUser.carePlan.documents_approval) {
					setStatusUpdate(
						'Pending registry approval: please upload requested documents.'
					);
					history.push(coreRoutes.documents);
				}
			}

			setHasDefaultPaymentMethod(
				authUser.payment_methods.some((method) => method.default_card === 1)
			);
		}
	}, [authUser]);

	useEffect(() => {
		const token = getFromLocalStorage(localStorageKeys.FYI_AUTH_TOKEN);

		if (token && data) {
			if (data.carePlan && data.carePlan.completed_platform_onboard) {
				setHasCompletedPlatformOnboarding(true);
			} else {
				setHasCompletedPlatformOnboarding(false);
			}

			setAuthUser(data);
			setIsAuthenticated(true);
		} else {
			setAuthUser(null);
			setIsAuthenticated(false);
		}
	}, [data]);

	const loginUser = (token, user) => {
		setInLocalStorage(localStorageKeys.FYI_AUTH_TOKEN, token);
		setAuthUser(user);
		setIsAuthenticated(true);
	};

	const logoutUser = () => {
		setInLocalStorage(localStorageKeys.FYI_AUTH_TOKEN);
		setAuthUser(null);
		setIsAuthenticated(false);
	};

	const modifyAuthUser = (key, value) => {
		setAuthUser((prevAuthUser) => ({
			...prevAuthUser,
			[key]: value,
		}));
	};

	const changeRegistryApproval = (approval, status) => {
		setIsRegistryApproved(approval);
		if (status) setStatusUpdate(status);
	};

	const value = {
		isAuthenticated,
		isRegistryApproved,
		hasCompletedPlatformOnboarding,
		hasDefaultPaymentMethod,
		authUser,
		statusUpdate,
		appIsLoading,
		loginUser,
		logoutUser,
		changeRegistryApproval,
		modifyAuthUser,
	};

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
