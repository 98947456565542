import React, { useState } from 'react';
import MultiRangeSlider from 'multi-range-slider-react';

import { ColumnFlex, RowFlex } from 'basicStyles';

import Colors from 'colors';
import styled from 'styled-components';

import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import Title from 'AppComp/Title';

export const ButtonTing = styled.div`
	background-color: ${Colors.primary.primary};
	border-radius: 50px;
	padding: 12px 42px;

	cursor: pointer;

	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}
`;

export const InputItemsContainer = styled(ColumnFlex)`
	border-radius: 16px;
	padding: 32px 48px;
	background-color: white;
	min-height: 200px;
	width: fit-content;
`;

export const PriceRangeSelect = ({ min, max, handleNextView }) => {
	const [minValue, set_minValue] = useState(15);
	const [maxValue, set_maxValue] = useState(35);

	const handleInput = (e) => {
		set_minValue(e.minValue);
		set_maxValue(e.maxValue);
	};

	return (
		<InputItemsContainer>
			<ColumnFlex gap='16px' center>
				<ColumnFlex gap='8px' center>
					<Title bold subtitle>
						How much are you looking to spend?
					</Title>
					<Text>The average price in your area is $20 - $35</Text>
				</ColumnFlex>
				<ColumnFlex
					margin='44px 0 0 0'
					style={{
						width: '100%',
					}}
				>
					<div center gap='2px'>
						<MultiRangeSlider
							style={{
								border: 'none',
								boxShadow: 'none',
								paddingBottom: '8px',
							}}
							min={8}
							max={50}
							ruler={false}
							step={5}
							label={false}
							minValue={minValue}
							barInnerColor={Colors.greenLightTeal}
							maxValue={maxValue}
							onInput={(e) => {
								handleInput(e);
							}}
						/>
						<Text
							style={{
								color: Colors.darkerGray,
							}}
							fontStyle='italic'
						>
							Per Hour
						</Text>
					</div>
				</ColumnFlex>
				<ButtonTing
					onClick={() => {
						handleNextView(0, [minValue, maxValue]);
					}}
					style={{
						borderRadius: '32px',
						width: '180px',
						height: '50px',
					}}
				>
					<Text fontWeight='500' cursor color={'white'} largeText>
						Continue
					</Text>
				</ButtonTing>
			</ColumnFlex>
		</InputItemsContainer>
	);
};
