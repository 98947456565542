import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	padding: 20px;
	font-family: Arial, sans-serif;
	background-color: #f0f4f8;
	min-height: 100vh;
`;

const Title = styled.h1`
	text-align: center;
	margin-bottom: 20px;
	font-size: 2em;
`;

const GridContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(${(props) => props.columns}, 1fr);
	gap: 20px;
	margin-bottom: 40px;
`;

const Item = styled.div`
	border: 1px solid #ccc;
	border-radius: 4px;
	padding: 10px;
	background-color: white;
`;

const Label = styled.h6`
	margin-bottom: 5px;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.4;
`;

const Input = styled.h3`
	font-size: 16px;
	font-weight: 700;
	line-height: 1.4;
`;

const ShiftPage = () => {
	return (
		<Container>
			<Title>Shift</Title>

			<GridContainer columns={5}>
				<Item>
					<Label>Status</Label>
					<Input>Active</Input>
				</Item>
				<Item>
					<Label>Approved Date</Label>
					<Input>May 22, 2024</Input>
				</Item>
				<Item>
					<Label>Start Time</Label>
					<Input>9:00 AM</Input>
				</Item>
				<Item>
					<Label>End Time</Label>
					<Input>6:00 PM</Input>
				</Item>
				<Item>
					<Label>Hourly Rate</Label>
					<Input>$35.00</Input>
				</Item>
				<Item>
					<Label>Live In</Label>
					<Input>No</Input>
				</Item>
				<Item>
					<Label>Lat</Label>
					<Input>--</Input>
				</Item>
				<Item>
					<Label>Long</Label>
					<Input>--</Input>
				</Item>
				<Item>
					<Label>FYI Commission Rate</Label>
					<Input></Input>
				</Item>
			</GridContainer>

			<GridContainer columns={4}>
				<Item>
					<Label>Status</Label>
					<Input>Active</Input>
				</Item>
				<Item>
					<Label>Approved Date</Label>
					<Input>May 22, 2024</Input>
				</Item>
				<Item>
					<Label>Start Time</Label>
					<Input>9:00 AM</Input>
				</Item>
				<Item>
					<Label>End Time</Label>
					<Input>6:00 PM</Input>
				</Item>
				<Item>
					<Label>Hourly Rate</Label>
					<Input>$35.00</Input>
				</Item>
				<Item>
					<Label>Live In</Label>
					<Input>No</Input>
				</Item>
				<Item>
					<Label>Lat</Label>
					<Input>--</Input>
				</Item>
				<Item>
					<Label>Long</Label>
					<Input>--</Input>
				</Item>
				<Item>
					<Label>FYI Commission Rate</Label>
					<Input></Input>
				</Item>
			</GridContainer>

			<GridContainer columns={3}>
				<Item>
					<Label>Status</Label>
					<Input>Active</Input>
				</Item>
				<Item>
					<Label>Approved Date</Label>
					<Input>May 22, 2024</Input>
				</Item>
				<Item>
					<Label>Start Time</Label>
					<Input>9:00 AM</Input>
				</Item>
				<Item>
					<Label>End Time</Label>
					<Input>6:00 PM</Input>
				</Item>
				<Item>
					<Label>Hourly Rate</Label>
					<Input>$35.00</Input>
				</Item>
				<Item>
					<Label>Live In</Label>
					<Input>No</Input>
				</Item>
				<Item>
					<Label>Lat</Label>
					<Input>--</Input>
				</Item>
				<Item>
					<Label>Long</Label>
					<Input>--</Input>
				</Item>
				<Item>
					<Label>FYI Commission Rate</Label>
					<Input></Input>
				</Item>
			</GridContainer>

			<GridContainer columns={2}>
				<Item>
					<Label>Disputed Start Time</Label>
					<Input></Input>
				</Item>
				<Item>
					<Label>Disputed End Time</Label>
					<Input></Input>
				</Item>
				<Item>
					<Label>Dispute Text</Label>
					<Input></Input>
				</Item>
				<Item>
					<Label>Payment ID</Label>
					<Input></Input>
				</Item>
				<Item>
					<Label>Time of Dispute</Label>
					<Input></Input>
				</Item>
				<Item>
					<Label>Signature URL</Label>
					<Input></Input>
				</Item>
				<Item>
					<Label>Dispute Cause</Label>
					<Input></Input>
				</Item>
				<Item>
					<Label>Stripe Payment Intent ID</Label>
					<Input>23435462</Input>
				</Item>
				<Item>
					<Label>Stripe Error</Label>
					<Input></Input>
				</Item>
				<Item>
					<Label>Stripe Error Code</Label>
					<Input></Input>
				</Item>
				<Item>
					<Label>Rate Type</Label>
					<Input></Input>
				</Item>
				<Item>
					<Label>Failed Payment Attempts</Label>
					<Input>0</Input>
				</Item>
				<Item>
					<Label>Stripe Error Message</Label>
					{/* <Textarea readOnly></Textarea> */}
				</Item>
			</GridContainer>
		</Container>
	);
};

export default ShiftPage;
