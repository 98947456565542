import React, { useState, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { CustomAvatar } from 'AppComp/provider/ProfileInfo/ProfileInfoViews';
import Icon from 'AppComp/Icon';

import { faFileUpload, faEye } from '@fortawesome/free-solid-svg-icons';
import Text from 'AppComp/Text';
import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';
import { Hearts } from 'react-loader-spinner';
import Colors from 'colors';

import styled from 'styled-components';
import { toast } from 'react-toastify';

import Button from 'AppComp/Button';
import OverlaySlider from 'AppComp/OverlaySlider';
import FileView from 'AppComp/FileView';

import { TOAST_ERROR_CONFIG } from 'constants';

// remove all the basic input styles
export const ExpirationInput = styled.input`
	border-radius: 8px;
	padding: 4px 12px;
	border: none;
	margin: 0 0 0 8px;
`;

export const thumbsContainer = {
	display: 'flex',
	flexDirection: 'row',
	flexWrap: 'wrap',
	marginTop: 16,
};

export const thumb = {
	display: 'inline-flex',
	borderRadius: 2,
	border: '1px solid #eaeaea',
	marginBottom: 8,
	marginRight: 8,
	width: 100,
	height: 100,
	padding: 4,
	boxSizing: 'border-box',
	cursor: 'pointer',
};

export const thumbInner = {
	display: 'flex',
	minWidth: 0,
	overflow: 'hidden',

	cursor: 'pointer',
};

const img = {
	display: 'block',
	width: 'auto',
	height: '100%',
};

const Upload = (props) => {
	console.log('props', props);

	const [newImage, setNewImage] = useState(false);

	const [loading, setLoading] = useState(false);

	const [checkFiles, setCheckFiles] = useState([]);

	const [filesToUpload, setFilesToUpload] = useState([]);

	const [expirationDate, setExpirationDate] = useState(null);

	const [newImageUrl, setNewImageUrl] = useState(
		props.uploaded ? props.uploaded.document_url : ''
	);

	const handleDroppedFile = (acceptedFiles) => {
		console.log('acceptedFiles', acceptedFiles);
		var imageTypes = [
			'image/heic',
			'image/webp',
			'image/png',
			'image/bmp',
			'image/jpg',
			'image/jpeg',
			'application/pdf',
		];

		// ensure all the files are within the imageTypes array

		const fileCheck = acceptedFiles.filter((file) => {
			if (!imageTypes.includes(file.type)) {
				return true;
			}

			return false;
		});

		if (fileCheck.length > 0) {
			toast.error('File type not supported', TOAST_ERROR_CONFIG);
			setLoading(false);
			return;
		}

		setCheckFiles(
			acceptedFiles.map((file) =>
				Object.assign(file, {
					preview: URL.createObjectURL(file),
				})
			)
		);

		setFilesToUpload(acceptedFiles);
	};

	const uploadFilesToS3 = async () => {
		try {
			setLoading(true);
			const identifyPoolId = 'us-east-1:66059173-f49e-413c-840a-3be0bd12aa8f';
			const bucketRegion = 'us-east-1';
			const bucketName = 'documents-uploads-2';

			AWS.config.update({
				region: bucketRegion,
				credentials: new AWS.CognitoIdentityCredentials({
					IdentityPoolId: identifyPoolId,
				}),
			});

			const s3 = new AWS.S3({
				apiVersion: '2012-10-17',
				params: { Bucket: bucketName },
			});

			// Update the UI to indicate loading
			// Make sure you have a state setter for loading

			const uploadPromises = filesToUpload.map((file) => {
				console.log('file', file);
				const key = `${Date.now()}-${file.name}`; // This ensures a unique key for each file

				const fileType = file.type;
				return new Promise((resolve, reject) => {
					s3.upload(
						{
							Key: key,
							Body: file,
							ACL: 'public-read',
							ContentType: fileType,
						},
						(err, data) => {
							if (err) {
								reject(err);
							} else {
								resolve(data);
							}
						}
					);
				});
			});

			Promise.all(uploadPromises)
				.then((results) => {
					console.log('All files uploaded:', results);
					// Update the UI to indicate success
					const filesLocations = results.map((result) => result.Location);
					handleUploadCallback(filesLocations);
				})
				.catch((errors) => {
					console.error('Errors during upload:', errors);
					// Update the UI to indicate error
					setLoading(false);
				});
		} catch (err) {
			console.log('err', err);
		}
	};
	const handleUploadCallback = async (filesLocations) => {
		//once the call back is called we either should show an error or run handleSignatureUpdate to pass the info back to the parent and handle removing the signature pad
		try {
			// ensue if there is an expiration date that it is parsed for sql date format

			console.log('0x01', props.required_document_id);
			if (expirationDate) {
				const date = new Date(expirationDate);
				const sqlDate = date.toISOString().slice(0, 19).replace('T', ' ');
				const uploadedThing = await props.handleUploadCallBackParent(
					filesLocations,
					props.requiredDocumentId,
					setLoading,
					sqlDate
				);
				if (uploadedThing) {
					setCheckFiles([]);
					setFilesToUpload([]);
					setExpirationDate(null);
				}
				setLoading(false);
			} else {
				const callBackRes = await props.handleUploadCallBackParent(
					filesLocations,
					props.requiredDocumentId,
					setLoading
				);
				setLoading(false);
			}
		} catch (err) {
			setLoading(false);
			console.log('handleUploadCallback - err', err);
		}
	};
	const testError = (e) => {
		console.log('testError, e', e);
	};

	const thumbs = checkFiles.map((file) => {
		console.log('file', file);
		return (
			<div style={thumb} key={file.name}>
				<div style={thumbInner}>
					{/* <FileViewer
						fileType={file.type.split('/').pop()}
						filePath={file.preview}
						onError={testError}
					/> */}

					<FileView preview file={file.preview} />
					{/* <FileView file={file.preview} /> */}
				</div>
			</div>
		);
	});

	console.log('checkFiles', checkFiles);

	console.log('props requires_expiration', props.requires_expiration);
	return (
		<ColumnFlex
			style={{
				width: 'fit-content',
			}}
			alignCenter
		>
			{loading ? (
				<Hearts color={Colors.primary.primary} height={100} width={100} />
			) : (
				<>
					{checkFiles.length > 0 ? (
						<>
							<aside style={thumbsContainer}>{thumbs}</aside>
						</>
					) : (
						<Dropzone onDrop={(acceptedFiles) => handleDroppedFile(acceptedFiles)}>
							{({ getRootProps, getInputProps }) => (
								<CustomAvatar
									height='200px'
									width='350px'
									margin='20px 0px 16px 0'
									borderRadius='4px'
									style={{
										borderRadius: '8px',
									}}
									border={Colors.greenLightTeal}
									image_url={
										props.uploaded ? props.uploaded.document_url : newImageUrl
									}
									{...getRootProps()}
								>
									<input {...getInputProps()} />
									{newImageUrl !== null && <Icon icon={faFileUpload} size='2x' />}

									<Text margin='8px 0 0 0' largeText>
										Uploaded File(s)
									</Text>
								</CustomAvatar>
							)}
						</Dropzone>
					)}

					{props.requires_expiration && (
						<RowFlex margin='12px 0 0 0' alignCenter gap='4px'>
							<Text bold>Expiration Date:</Text>
							<ExpirationInput
								type='date'
								id='start'
								name='trip-start'
								onChange={(e) => setExpirationDate(e.target.value)}
							></ExpirationInput>
						</RowFlex>
					)}

					{filesToUpload.length > 0 && (
						<ColumnFlex gap='8px'>
							<Button
								style={{
									borderRadius: '24px',
								}}
								valid={true}
								type='submit'
								margin='10px 0 0 0 '
								backgroundColor={Colors.primary.primary}
								width={'140px'}
								height={'40px'}
								onClick={() => uploadFilesToS3()}
							>
								<Text
									style={{
										textAlign: 'center',
									}}
									bold
									color={'white'}
									midLarge
								>
									Upload
								</Text>
							</Button>
							<Button
								style={{
									borderRadius: '24px',
								}}
								valid={true}
								type='submit'
								margin='10px 0 0 0 '
								backgroundColor={Colors.red}
								width={'140px'}
								height={'40px'}
								onClick={() => {
									setCheckFiles([]);
									setFilesToUpload([]);
								}}
							>
								<Text
									style={{
										textAlign: 'center',
									}}
									bold
									color={'white'}
									midLarge
								>
									Cancel
								</Text>
							</Button>
						</ColumnFlex>
					)}
				</>
			)}
		</ColumnFlex>
	);
};
export default Upload;

export const DisplayUploadedDocument = (props) => {
	console.log('props', props);
	const [visible, setOverlayVisible] = useState(false);
	const [initIndex, setInitIndex] = useState(0);

	console.log('props', props);
	const testError = (e) => {
		console.log('testError, e', e);
	};

	const thumbs = props.uploaded.uploadedDocuments.map((file, i) => {
		console.log('i', i);
		return (
			<div
				onClick={() => {
					setInitIndex(i);
					setOverlayVisible(true);
				}}
				style={thumb}
				key={i}
			>
				<div style={thumbInner}>
					<FileView file={file.document_url} />
				</div>
			</div>
		);
	});

	const images = props.uploaded.uploadedDocuments.map((file) => file.document_url);
	return (
		<>
			<RowFlex>{thumbs}</RowFlex>
			<OverlaySlider
				initIndex={initIndex}
				images={images}
				visible={visible}
				setVisible={setOverlayVisible}
			/>
		</>
	);
};

export const SingeDisplayUploadedDocument = (props) => {
	console.log('props', props);
	const [visible, setOverlayVisible] = useState(false);
	const [initIndex, setInitIndex] = useState(0);

	console.log('props', props);
	const testError = (e) => {
		console.log('testError, e', e);
	};

	const thumbs = props.uploaded.uploadedDocuments.map((file, i) => {
		console.log('i', i);
		return (
			<div
				onClick={() => {
					setInitIndex(i);
					setOverlayVisible(true);
				}}
				style={thumb}
				key={i}
			>
				<div style={thumbInner}>
					<FileView file={file.document_url} />
				</div>
			</div>
		);
	});

	const images = props.uploaded.uploadedDocuments.map((file) => file.document_url);
	return (
		<>
			{thumbs}
			<OverlaySlider
				initIndex={initIndex}
				images={images}
				visible={visible}
				setVisible={setOverlayVisible}
			/>
		</>
	);
};
