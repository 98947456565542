import React, { useState } from 'react';
import styled from 'styled-components';
import Colors from 'colors';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import AnimateHeight from 'react-animate-height';
import is from 'styled-is';

import { ColumnFlex, RowFlex, Avatar } from '../../styled/Basic';
import { RadioRoot, RadioFill } from '../Onboard/OnboardViews';
import { device } from '../../styled/StyledMediaQuery';
import { isMobile } from 'react-device-detect';

import Icon from 'AppComp/Icon';

import {
	faChevronDown,
	faChevronRight,
	faCheck,
	faHourglass,
	faTimesCircle,
	faUpload,
} from '@fortawesome/free-solid-svg-icons';

export const RadioInput = styled.div`
	opacity: 0;
	z-index: 2;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	cursor: pointer;

	&:focus {
		outline: none;
	}

	${is('checked')`
  & ~ ${RadioFill} {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    transition: width 0.2s ease-out, height 0.2s ease-out;

    &::before {
      opacity: 1;
      transition: opacity 1s ease;
    }
  }
`}
`;

const RadioRow = styled(ColumnFlex)`
	padding: 2px 10px;
	width: 80vw;
	max-width: 750px;
	min-height: 74px;
	border: solid 1px ${Colors.gray};
	border-radius: 30px;
	background-color: white;

	@media ${device.laptop} {
		padding: 10px 20px 20px 20px;
		min-height: 84px;
	}
`;
export const OptionSection = (props) => {
	const [selected, _selected] = useState(false);

	const { title, children, editForm } = props;
	const handleChange = () => {
		if (editForm && selected) {
			console.log('shoudl not close since in edit mode');
		} else {
			_selected(!selected);
		}
	};

	return (
		<ColumnFlex margin='10px 0'>
			<RadioRow justifyStart>
				<RowFlex margin='10px 0px 0 0px' alignCenter>
					<RadioRoot>
						<RadioInput
							onClick={() => handleChange()}
							type={'checkbox'}
							checked={selected}
						/>
						<RadioFill />
					</RadioRoot>
					<ColumnFlex flex>
						<Text
							bold
							padding='0 0 0 10px'
							largeText
							color={editForm ? Colors.secondary.blue : 'black'}
						>
							{title}
						</Text>
					</ColumnFlex>
				</RowFlex>
				<AnimateHeight duration={500} height={selected ? 'auto' : '0%'}>
					<ColumnFlex
						style={{
							flexWrap: 'wrap',
						}}
						margin={isMobile ? '10px 0 0 0' : '10px 0px 0px 20px'}
					>
						{children}
					</ColumnFlex>
				</AnimateHeight>
			</RadioRow>
		</ColumnFlex>
	);
};

export const OptionSectionCareProDoc = (props) => {
	const [selected, _selected] = useState(false);

	const { document_name, children, status } = props;
	const handleChange = () => {
		_selected(!selected);
	};

	const renderStatus = () => {
		if (props.message) {
			return props.message;
		}

		if (status === 'pending') {
			return 'Pending Approval';
		} else if (status === 'approved') {
			return 'Approved';
		} else if (status === 'rejected') {
			return 'Rejected';
		} else if (status === 'not_uploaded') {
			return 'Not Uploaded';
		}
	};
	const renderIconStatus = () => {
		if (status === 'pending') {
			return <Icon icon={faHourglass} size='xl' color={Colors.yellow} />;
		} else if (status === 'approved') {
			return <Icon icon={faCheck} size='xl' color={Colors.secondary.green} />;
		} else if (status === 'rejected') {
			return <Icon icon={faTimesCircle} size='xl' color={Colors.red} />;
		} else if (status === 'not_uploaded') {
			return <Icon icon={faUpload} size='xl' color={Colors.heartRed} />;
		}
	};

	const renderColor = () => {
		if (status === 'pending') {
			return Colors.yellow;
		} else if (status === 'approved') {
			return Colors.secondary.green;
		} else if (status === 'rejected') {
			return Colors.red;
		} else if (status === 'not_uploaded') {
			return Colors.heartRed;
		}
	};

	return (
		<RadioRow
			style={{
				maxWidth: 'none',
				borderRadius: '16px',
				width: '611px',
				height: 'min-content',
				padding: 0,
				border: 'none',
				backgroundColor: 'white',
			}}
			justifyStart
		>
			<RowFlex
				style={{
					alignItems: 'flex-start',
				}}
				spaceBetween
				fullWidth
				padding='20px 20px 10px 20px'
				gap='10px'
			>
				<Avatar backgroundColor={'#FAFAFA'} color='#DDDDDD' width='60px' height='60px'>
					{renderIconStatus()}
				</Avatar>
				<ColumnFlex style={{ flex: 1 }} gap='10px'>
					<Text bold padding='0 0 0 0' largeText color={Colors.secondary.blue}>
						{document_name}{' '}
					</Text>
					<span
						style={{
							fontWeight: '300',
							fontSize: '14px',
							color: renderColor(),
						}}
					>
						{`(${renderStatus()})`}
					</span>
					<RowFlex alignCenter gap='20px'>
						{props.required && (
							<Text fontStyle='italic' bold>
								- Required
							</Text>
						)}
						{props.requires_expiration && (
							<Text fontStyle='italic' bold>
								- Requires Expiration
							</Text>
						)}
					</RowFlex>
					<AnimateHeight duration={500} height={selected ? 'auto' : '0%'}>
						<ColumnFlex
							style={{
								flexWrap: 'wrap',
							}}
						>
							{children}
						</ColumnFlex>
					</AnimateHeight>
				</ColumnFlex>
				<Icon
					onClick={() => handleChange()}
					style={{
						cursor: 'pointer',
					}}
					icon={selected ? faChevronDown : faChevronRight}
					size='2xl'
					color={Colors.secondary.blue}
				/>
			</RowFlex>
		</RadioRow>
	);
};

export const OptionSectionDocs = (props) => {
	const [selected, _selected] = useState(false);

	const { title, children } = props;
	const handleChange = () => {
		_selected(!selected);
	};

	return (
		<ColumnFlex margin='10px 0'>
			<RadioRow justifyStart>
				<RowFlex margin='10px 0px 0 0px' alignCenter>
					<RadioRoot>
						<RadioInput
							onClick={() => handleChange()}
							type={'checkbox'}
							checked={selected}
						/>
						<RadioFill />
					</RadioRoot>
					<ColumnFlex flex>
						<Text bold padding='0 0 0 10px' largeText color={Colors.secondary.blue}>
							{title}
						</Text>
					</ColumnFlex>
				</RowFlex>
				<AnimateHeight duration={500} height={selected ? 'auto' : '0%'}>
					<ColumnFlex
						style={{
							flexWrap: 'wrap',
						}}
						margin={isMobile ? '10px 0 0 0' : '10px 0px 0px 20px'}
					>
						{children}
					</ColumnFlex>
				</AnimateHeight>
			</RadioRow>
		</ColumnFlex>
	);
};
