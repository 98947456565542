import React, { useState } from 'react';
import { STATES_OPTIONS } from 'constants';
import PageLoaderView from 'AppComp/PageLoaderView';

import {
	OnboardFormContainer,
	OnboardTitle,
	OnboardSubText,
	OnboardButton,
	OnboardFormButton,
	FormItemsLength,
} from '../CareProOnboardViews';
import { ColumnFlex, RowFlex } from 'basicStyles';

import { Formik, Form, Field } from 'formik';
import StyledTextInput, { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';
import Colors from 'colors';

import { validateCareProDobSSnlStripe } from './validation';

import { MONTHS } from 'constants';

const BirthDate = (props) => {
	const { updateProviderVerificationInfo, setIsLoading, isLoading } = props;

	const handleSubmit = (values, form) => {
		console.log('Personal - handleSubmit', values);

		// get the last 4 digits of the id_number
		const last_4 = values.id_number.slice(-4);
		setIsLoading(true);
		updateProviderVerificationInfo({
			dob_day: values.dob_day,
			dob_month: values.dob_month,
			dob_year: values.dob_year,
			id_number: values.id_number,
			ssn_last_4: last_4,
		});
	};

	const renderPossibleAge = () => {
		// starting 18 years ago should end at 118 years ago
		const currentYear = new Date().getFullYear();
		const years = [
			{
				value: null,
				label: null,
			},
		];

		for (let i = 18; i < 118; i++) {
			years.push({
				value: currentYear - i,
				label: currentYear - i,
			});
		}

		return years;
	};

	const renderPossibleDays = () => {
		const days = [
			{
				value: null,
				label: null,
			},
		];

		for (let i = 1; i < 33; i++) {
			days.push({
				value: i,
				label: i,
			});
		}

		return days;
	};

	return (
		<OnboardFormContainer
			style={{
				width: 'fit-content',
			}}
			center
		>
			<OnboardTitle> Personal Info</OnboardTitle>
			<OnboardSubText>Please enter your date of birth</OnboardSubText>
			{process.env.ENVIRONMENT === 'DEVELOPMENT' && (
				<OnboardSubText>Testing last 4 SSN: 0000</OnboardSubText>
			)}

			<Formik
				onSubmit={handleSubmit}
				validate={validateCareProDobSSnlStripe}
				autoComplete={'off'}
				render={(formProps) => {
					console.log(formProps);
					return (
						<Form noValidate>
							<ColumnFlex padding='20px 0 0 0' alignCenter justifyCenter>
								<FormItemsLength>
									<Field
										name='dob_month'
										type='text'
										component={StyledTextSelectInputV2}
										options={MONTHS}
										label='Month'
										backgroundColor={'white'}
									/>
								</FormItemsLength>

								<FormItemsLength>
									<Field
										name='dob_day'
										type='text'
										component={StyledTextSelectInputV2}
										options={renderPossibleDays()}
										label='Day'
										backgroundColor={'white'}
									/>
								</FormItemsLength>
								<FormItemsLength>
									<Field
										name='dob_year'
										type='year'
										component={StyledTextSelectInputV2}
										options={renderPossibleAge()}
										label='Year'
										backgroundColor={'white'}
									/>
								</FormItemsLength>

								<FormItemsLength>
									<Field
										name='id_number'
										type='password'
										component={StyledTextInput}
										label='Full SSN'
										backgroundColor={'white'}
									/>
								</FormItemsLength>

								{isLoading ? (
									<PageLoaderView errorMessage='Something went wrong plz contact dev monkeys' />
								) : (
									<OnboardFormButton
										backgroundColor={Colors.primary.primary}
										type='submit'
										valid={formProps.isValid}
									>
										Next
									</OnboardFormButton>
								)}
							</ColumnFlex>
						</Form>
					);
				}}
			/>
		</OnboardFormContainer>
	);
};

export default BirthDate;
