import { createGlobalStyle } from 'styled-components';

//Fonts.jsx

import LatoRegular from '../../fonts/Lato-Regular.eot';
import LatoRegularTTF from '../../fonts/Lato-Regular.ttf';
import LatoRegularWOFF from '../../fonts/Lato-Regular.woff';
import LatoRegularWoff2 from '../../fonts/Lato-Regular.woff2';

export const GlobalStyles = createGlobalStyle`
     @font-face {
        font-family: 'Lato Regular';
        font-style: normal;
        font-weight: normal;
        src: url(${LatoRegular});
        src: local('Lato-Regular'), url(${LatoRegularWoff2}) format('woff2'), /* Super Modern Browsers */
       url(${LatoRegularWOFF}) format('woff'), /* Modern Browsers */
       url(${LatoRegularTTF}) format('ttf'), /* Safari, Android, iOS */
    }
    html, body {
    font-family: Lato Regular, sans-serif;
  }
    * {
        -webkit-overflow-scrolling: touch;
    }
`;

/*
@font-face {
    font-family: AvenirLight;
    src: url(../../fonts/Avenir-Light.ttf);
}

@font-face {
    font-family: AvenirRoman;
    src: url(../../fonts/Avenir-Roman.ttf);
}

@font-face {
    font-family: AvenirMedium;
    src: url(../../fonts/Avenir-Medium.ttf);
}

@font-face {
    font-family: AvenirHeavy;
    src: url(../../fonts/Avenir-Heavy.ttf);
}



@font-face {
    font-family: HelveticaLight;
    src: url(./fonts/Helvetica-light.woff);
}


@font-face {
    font-family: Helvetica;
    src: url(${Helvetica});
}
*/
