import React from 'react';
import { useParams } from 'react-router-dom';

import { ClientProfile } from '../../../../../corev2/Profiles';

import { useFetchClientQuery } from '../../api/queries/useFetchClientQuery';

const ClientProfilePage = () => {
	const { clientId } = useParams();

	const { data: client, isLoading: isLoadingClient } = useFetchClientQuery(clientId);

	if (isLoadingClient) return <>Loading</>;

	return <ClientProfile name={client.name} traits={[]} />;
};

export default ClientProfilePage;
