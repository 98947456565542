import React from 'react';
import styled from 'styled-components';
import is from 'styled-is';
import PropTypes from 'prop-types';

import { device } from './styled/StyledMediaQuery';

import Colors from '../constants/colors';

const Img = styled.img`
	margin: ${(props) => props.margin || '0'};
	max-width: ${(props) => props.maxWidth || 'none'};

	${is('nurseImg')`
		width: 46px;
        height: 46px;
        border-radius: 23px;
        border: 2px solid black;
    `}
`;

Img.propTypes = {
	nurseImg: PropTypes.bool,
};

export default Img;
