import React, { Component, Fragment } from 'react';

const CARE_TYPE = [
	{
		value: 'hourly',
		label: 'Hourly',
	},
	{
		value: 'live_in',
		label: 'Live In',
	},
];

const GENDERS = [
	{
		value: 'female',
		label: 'Female',
	},
	{
		value: 'male',
		label: 'Male',
	},
];

export default class ProfileInfoController extends Component {
	state = {
		editProfileInfo: false,
		newImageUrl: '',
		newImage: false,
	};

	initialValues = () => {
		const {
			provider: {
				image_url,
				email,
				first_name,
				last_name,
				phone_number,
				professional_statement,
				display_name,
				gender,
			},
		} = this.props;

		const { newImage, newImageUrl } = this.state;

		const currentGender = GENDERS.find((d, i) => {
			return d.value == gender;
		});

		const initValues = {
			email: email,
			first_name: first_name,
			last_name: last_name,
			phone_number: phone_number,
			professional_statement: professional_statement || '',
			image_url: image_url,
			gender: currentGender.value,
			display_name: display_name || '',
		};

		return initValues;
	};

	handleSubmit = (values, form) => {
		console.log('handleSubmit -> values', values);

		const {
			updateProviderInfo,
			provider: { image_url },
		} = this.props;

		const { newImage, newImageUrl } = this.state;

		const updateProviderInfoParams = {
			email: values.email,
			phone_number: values.phone_number,
			professional_statement: values.professional_statement,
			first_name: values.first_name,
			last_name: values.last_name,
			image_url: newImage ? newImageUrl : image_url,
			display_name: values.display_name || '',
		};

		console.log('where si thsi place holder', updateProviderInfoParams);

		updateProviderInfo(updateProviderInfoParams);

		this.setState({
			editProfileInfo: false,
			newImage: false,
			newImageUrl: '',
		});
	};

	handleDroppedFile = (acceptedFiles) => {
		console.log('handleDroppedFile -> acceptedFiles', acceptedFiles);

		var imageTypes = ['image/png', 'image/gif', 'image/bmp', 'image/jpg', 'image/jpeg'];

		var fileType = acceptedFiles[0].type;

		if (imageTypes.includes(fileType)) {
			const identifyPoolId = 'us-east-2:8aa03690-fa6e-4aab-8429-22b9b6270602';
			const bucketRegion = 'us-east-2';
			const bucketName = 'desir';

			this.setState({
				loading: true,
			});

			AWS.config.update({
				region: bucketRegion,
				credentials: new AWS.CognitoIdentityCredentials({
					IdentityPoolId: identifyPoolId,
				}),
			});

			const s3 = new AWS.S3({
				apiVersion: '2012-10-17',
				params: { Bucket: bucketName },
			});

			const key = acceptedFiles[0].name;

			s3.upload(
				{
					Key: key,
					Body: acceptedFiles[0],
					ACL: 'public-read',
				},
				this.handleUploadCallback
			);
		} else {
			window.alert('dropped file is not an image');
		}
	};

	handleUploadCallback = (err, data) => {
		//once the call back is called we either should show an error or run handleSignatureUpdate to pass the info back to the parent and handle removing the signature pad

		if (err) {
			console.log('somethign went wrong', err);
			this.setState({
				error: 'Something Went wrong please check file type and try again',
			});
		} else {
			console.log('data', data);

			const { Location, Key } = data;
			console.log('Location', Location);
			this.setState({
				newImageUrl: Location,
				newImage: true,
			});
		}
	};
}
