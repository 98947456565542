import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CLIENT_MODEl, PAYMENT_METHOD_MODEL } from '../../../core/api/models';

const updateUser = async ({ userId, userInputs }) => {
	try {
		const data = await api.graph({
			query: `mutation{
				    updatePatient(
					    userId: ${userId},
					    userInput: ${api.graphStringify(userInputs)}
				    ) {
					    ${CLIENT_MODEl}
					    payment_methods {
						    ${PAYMENT_METHOD_MODEL}
					    },
				}
			}`,
		});

		return data.updatePatient;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useUpdateUserMutation = () => {
	return useMutation({
		mutationKey: ['updateUser'],
		mutationFn: (values) => {
			return updateUser(values);
		},
	});
};
