import React from 'react';
import Colors from 'colors';

import {
	FormDisplaySection,
	StyledFormPage,
	StyledPageHeadingContainer,
	PageHeading,
	FormItemsContainer,
	FormItem,
} from './styles';

import Text from 'AppComp/Text';

import useFetchRegistryTraitTemplates from '../api/query/useFetchRegistryTraitTemplates';

const FormTemplates = () => {
	const { data: formTemplates, isLoading } = useFetchRegistryTraitTemplates();

	console.log('formTemplates', formTemplates);

	return (
		<StyledFormPage>
			<StyledPageHeadingContainer>
				<PageHeading styles={{ padding: '20px 0 0 0' }}>Form Templates</PageHeading>
			</StyledPageHeadingContainer>
			<FormDisplaySection>
				<Text fontWeight='500' color={Colors.primary.primary} largeText>
					All Form Templates
				</Text>
				<Text fontWeight='300' largeText>
					Manage Form Templates here, Click on any to view the form in detail
				</Text>

				<FormItemsContainer>
					{isLoading ? (
						<Text>Loading...</Text>
					) : (
						<>
							{formTemplates.map((form) => (
								<FormItem key={form.id} {...form} />
							))}
						</>
					)}
				</FormItemsContainer>
			</FormDisplaySection>
		</StyledFormPage>
	);
};

export default FormTemplates;
