import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
	StyledJobPostsCategory,
	StyledCategoryIcon,
	StyledInformationContainer,
	StyledJobPostTitle,
	StyledJobPostDescription,
} from './styles';

const JobPostsCategory = (props) => {
	const { title, description, goToJobPostsCategoryPage, icon } = props;

	return (
		<StyledJobPostsCategory onClick={goToJobPostsCategoryPage}>
			<StyledCategoryIcon>
				<FontAwesomeIcon size='xs' icon={icon} />
			</StyledCategoryIcon>

			<StyledInformationContainer>
				<StyledJobPostTitle>{title}</StyledJobPostTitle>

				<StyledJobPostDescription>{description}</StyledJobPostDescription>
			</StyledInformationContainer>
		</StyledJobPostsCategory>
	);
};

export default JobPostsCategory;
