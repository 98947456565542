import React, { Component, Fragment } from 'react';
import styled from 'styled-components';

import useWindowSize from 'scripts/useWindowSize';

import { faUserMd, faChild, faCircle, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import Colors from 'colors';

import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';
import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';

const ConversationTileContainer = styled(RowFlex)`
	width: ${({ width }) => (width < 1024 ? `100%` : `320px`)};
	padding: 0px 16px 0px 8px;
	border-radius: 8px;
	min-height: 90px;
	max-height: 90px;
	cursor: pointer;
	transition: all ease 0.2s;
	${({ active }) =>
		active &&
		`
		border: solid 2px ${Colors.greenLightTeal};
	`}
`;
const ConversationTile = ({ conversation, setSelectedConversation, active, loggedInUser }) => {
	const {
		id,
		user_type,
		unread_message,
		latest_message_timestamp,
		user_id,
		provider_id,
		admin_id,
		page,

		latest_message,
	} = conversation;

	const { width } = useWindowSize();

	const userName = '';

	if (loggedInUser === 'patient' || loggedInUser === 'admin') {
		const { provider } = conversation;

		if (provider === null) {
			return null;
		}
	}
	const renderUserName = () => {
		const { user, provider, admin } = conversation;
		// convo is with the admin since the provider is login

		if (loggedInUser === 'carePro') {
			if (user_type === 'care_provider') {
				return `Admin`;
			} else if (user_type === 'carePro') {
				// means convo with user
				return `${user.name}`;
			}
		} else if (loggedInUser === 'patient') {
			if (user_type === 'patient') {
				return `${admin.name} (Admin)`;
			} else if (user_type === 'carePro') {
				// means convo with user
				return `${provider.displayName || 'N/A'}`;
			}
		} else if (loggedInUser === 'admin') {
			console.log('conversation', conversation);
			if (user_type === 'patient') {
				return `${user.name}`;
			} else if (user_type === 'carePro') {
				// means convo with user
				return `${provider.first_name} ${provider.last_name}`;
			}
		}
	};
	return (
		<ConversationTileContainer
			onClick={() => setSelectedConversation(conversation)}
			fullWidth
			spaceBetween
			alignStart
			active={active}
			width={width}
		>
			<ColumnFlex
				style={{
					width: '26px',
				}}
				padding='24px 6px 0 6px'
			>
				<Icon
					icon={faCircle}
					size='1x'
					color={unread_message === 1 ? Colors.primary.primary : Colors.fullWhite}
				/>
				<Text margin='6px' />
			</ColumnFlex>
			<ColumnFlex
				style={{
					borderTop: 'solid 1px #e0e0e0',
				}}
				padding='16px 0px'
				fullWidth
			>
				<RowFlex fullWidth spaceBetween>
					<RowFlex gap='8px'>
						<Icon
							icon={user_type === 'patient' ? faChild : faUserMd}
							size='1x'
							color={Colors.teal}
							style={{
								padding: '4px 0 0 0',
							}}
						/>
						<Text
							color={Colors.secondary.black}
							mediumText
							fontWeight='thin'
							style={{
								whiteSpace: 'nowrap',
							}}
						>
							{renderUserName()}
						</Text>
					</RowFlex>

					<Text
						style={{
							textAlign: 'right',
						}}
						subtext
						gray
						margin='4px 8px 0 0'
					>
						{latest_message_timestamp
							? moment.unix(latest_message_timestamp).format('MM/DD h:mm A')
							: 'N/A'}
					</Text>
				</RowFlex>
				<Text color={Colors.secondary.black} margin='4px 0 0 0' subtitle>
					{latest_message ?? 'N/A'}
				</Text>
			</ColumnFlex>
		</ConversationTileContainer>
	);
};

export default ConversationTile;
