import styled from 'styled-components';

import { colors } from '../../corev2/utils';

export const StyledSteps = styled.div`
	display: flex;
	justify-content: space-between;

	position: relative;
`;

export const StyledDivider = styled.div`
	height: 1px;
	background-color: ${colors.primary.primary};
	flex-grow: 1;
	margin: 0 10px;

	position: relative;
	top: 28px;
`;
