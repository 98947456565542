import styled from 'styled-components';

import { mediaQueries } from '../../../../../corev2/utils';

export const StyledPlatformOnboardingPage = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	margin: auto;

	@media (${mediaQueries.tablet}) {
		width: fit-content;
	}
`;

export const StyledStepsContainer = styled.div`
	margin-top: 2rem;
	margin-bottom: 3rem;

	@media (${mediaQueries.tablet}) {
		margin-top: 0;
	}
`;
