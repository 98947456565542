import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProfessionalStatementpage from '../pages/ProfessionalStatementPage';
import LoginPage from '../pages/LoginPage';
import PlatformOnboardingPage, {
	PersonalInfoPage,
	AccountInfoPage,
	AuthCodePage,
} from '../pages/PlatformOnboardingPage';
import StripeOnboardingPage, {
	AddressPage,
	PayoutsPage,
	PersonalInfoPage as StripePersonalInfoPage,
	TOSPage,
} from '../pages/StripeOnboardingPage';
import OnboardingWelcomePage from '../pages/OnboardingWelcomePage';

import PrivateRoute from '../../core/routes/PrivateRoute';

import { authRoutes } from './constants';
import SkillsInfoPage from '../../skills/components/SkillsInfoPage';

const AuthRoutes = () => {
	return (
		<Switch>
			<Route path={authRoutes.onboarding.home} exact>
				<OnboardingWelcomePage />
			</Route>

			<Route path={authRoutes.login}>
				<LoginPage />
			</Route>

			<PrivateRoute
				path={authRoutes.onboarding.professionalStatement}
				component={ProfessionalStatementpage}
				exact
			/>

			<PrivateRoute path={authRoutes.onboarding.skills} component={SkillsInfoPage} exact />

			<Route path={authRoutes.onboarding.platform.home}>
				<PlatformOnboardingPage>
					<Switch>
						<Route path={authRoutes.onboarding.platform.personalInfo}>
							<PersonalInfoPage />
						</Route>

						<Route path={authRoutes.onboarding.platform.accountInfo}>
							<AccountInfoPage />
						</Route>

						<Route path={authRoutes.onboarding.platform.authCode}>
							<AuthCodePage />
						</Route>
					</Switch>
				</PlatformOnboardingPage>
			</Route>

			<Route path={authRoutes.onboarding.stripe.home}>
				<StripeOnboardingPage>
					<Switch>
						<Route path={authRoutes.onboarding.stripe.address}>
							<AddressPage />
						</Route>

						<Route path={authRoutes.onboarding.stripe.payouts}>
							<PayoutsPage />
						</Route>

						<Route path={authRoutes.onboarding.stripe.personal}>
							<StripePersonalInfoPage />
						</Route>

						<Route path={authRoutes.onboarding.stripe.tos}>
							<TOSPage />
						</Route>
					</Switch>
				</StripeOnboardingPage>
			</Route>
		</Switch>
	);
};

export default AuthRoutes;
