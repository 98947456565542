import React from 'react';

import { StyledPageHeading } from './styles';

const PageHeading = (props) => {
	const { children } = props;

	return <StyledPageHeading>{children}</StyledPageHeading>;
};

export default PageHeading;
