import React from 'react';
import { useHistory } from 'react-router-dom';

import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { authRoutes } from '../../../auth/routes/constants';

import { PrimaryButton, TransparentButton } from '../../../../../corev2/Buttons';
import InformationTile from '../../components/InformationTile';

import {
	StyledHomeContainer,
	StyledCopyContainer,
	StyledIntroContainer,
	StyledHeading,
	StyledDescription,
	StyledButtonsWrapper,
	StyledHeroImageContainer,
	StyledHeroImage,
	StyledInformationTilesContainer,
} from './styles';

const HomePage = () => {
	const history = useHistory();

	const heroImImageSrc =
		'https://find-your-independents.s3.amazonaws.com/images/landingImageV2.png';

	const goToOnboardingPage = () => {
		history.push(authRoutes.onboarding.home);
	};
	return (
		<StyledHomeContainer>
			<StyledCopyContainer>
				<StyledIntroContainer>
					<StyledHeading>Take Back Your Independence</StyledHeading>
					<StyledDescription>
						FYI was created with the goal of providing quality senior care services to
						help our clients remain at home.
					</StyledDescription>

					<StyledButtonsWrapper>
						<PrimaryButton onClick={goToOnboardingPage} size='small'>
							Get Started
						</PrimaryButton>
						<TransparentButton size='small'>Learn More</TransparentButton>
					</StyledButtonsWrapper>
				</StyledIntroContainer>
				<StyledHeroImageContainer>
					<StyledHeroImage src={heroImImageSrc}></StyledHeroImage>
				</StyledHeroImageContainer>
			</StyledCopyContainer>

			<StyledInformationTilesContainer>
				<InformationTile
					title='Heading'
					description='Li Europan lingues es membres del sam familie. Lor separat existentie es un myth.
				Por scientie, musica, sport etc, litot'
					icon={faCopy}
				></InformationTile>
				<InformationTile
					title='Heading'
					description='Li Europan lingues es membres del sam familie. Lor separat existentie es un myth.
				Por scientie, musica, sport etc, litot'
					icon={faCopy}
				></InformationTile>
				<InformationTile
					title='Heading'
					description='Li Europan lingues es membres del sam familie. Lor separat existentie es un myth.
			Por scientie, musica, sport etc, litot'
					icon={faCopy}
				></InformationTile>
			</StyledInformationTilesContainer>
		</StyledHomeContainer>
	);
};

export default HomePage;
