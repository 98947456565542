import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Colors from 'colors';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { showInfoModel } from 'reducers/environment';
import { AnimatePresence, motion } from 'framer-motion';
import { push } from 'connected-react-router';

import { ColumnFlex, RowFlex, Avatar } from '../../styled/Basic';
import api from 'scripts/api';

import usePreventScroll from 'scripts/usePreventScroll';

import { ParentContainer, PHWhiteCon, PHDColumn } from '../ProviderHomeDashboard';
import Title from 'AppComp/Title';
import Icon from 'AppComp/Icon';
import Text from 'AppComp/Text';
import { InfoButton } from 'AppComp/Info';

import { JOB_STATUS_ENUM, APPLICATION_STATUS_ENUM } from 'constants';

import { device } from '../../styled/StyledMediaQuery';

import { NAVIGATION, JOB_OFFER_STATUS_ENUM } from 'constants';
import { isMobile } from 'react-device-detect';
import Payouts from '../Payouts';

import { CORE_APPLICATION_MODEL, CORE_JOB_MODEL } from 'reducers/graphqlModelTypes';
import { careProContactUser } from 'reducers/provider';

import { faCheck } from '@fortawesome/free-solid-svg-icons';

const providerRoutes = NAVIGATION.provider;

const StepNavItem = (props) => {
	const { completed, onClick, title } = props;

	return (
		<ColumnFlex center onClick={onClick} gap='10px'>
			<ColumnFlex
				style={{
					borderRadius: '50%',
					border: `solid 2px ${Colors.primary.primary}`,
					height: '30px',
					width: '30px',
					backgroundColor: completed ? Colors.primary.primary : 'transparent',
				}}
				center
			>
				{completed && <Icon size={'xl'} icon={faCheck} color='white' />}
			</ColumnFlex>
			<Text color={Colors.primary.primary}>{title}</Text>
		</ColumnFlex>
	);
};

import {
	JobInviteTile,
	SectionTabs,
	JobApplicationTile,
	JobOfferTile,
} from './ProviderHomeLandingViews';
import CarePlanInfo from './CarePlanInfo';
import { set } from 'lodash';
export const SELECTED_TAB_VIEW = {
	Invited: 'Invited',
	Applied: 'Applied',
	Offered: 'Offered',
};

export const SELECTED_ACTION_VIEW = {
	APPLICATION: 'APPLICATION',
	JOB: 'JOB',
};
const ProviderHomeLanding = (props) => {
	const { provider } = props;
	const [jobs, setJobs] = useState([]);
	const [applications, setApplications] = useState([]);

	const [selectedTab, setSelectedTab] = useState(SELECTED_TAB_VIEW.Invited);

	const [totalInvited, setTotalInvited] = useState(0);
	const [totalApplied, setTotalApplied] = useState(0);
	const [totalOffered, setTotalOffered] = useState(0);

	const [selectedCarePlan, setSelectedCarePlan] = useState(null);
	const [selectedAction, setSelectedAction] = useState(null);
	const [showCarePlanInfoModel, setShowCarePlanInfoModel] = useState(false);
	const [selectedActionView, setSelectedActionView] = useState(null);

	usePreventScroll(showCarePlanInfoModel);

	useEffect(() => {
		fetchJobsAndApplications();
	}, []);

	const pendingVerificationCardPayments = false;

	const pendingVerificationPayouts = false;

	const handleSelectTab = (tab) => {
		setSelectedTab(SELECTED_TAB_VIEW[tab]);
	};

	const fetchJobsAndApplications = async () => {
		try {
			const res = await api.graph({
				query: `{
          checkProviderSession{
						jobs {
							${CORE_JOB_MODEL}
						}
						applications {
							${CORE_APPLICATION_MODEL}
						}
          }
        }`,
			});

			console.log('res', res);

			if (res.checkProviderSession) {
				// set state
				setJobs(res.checkProviderSession.jobs);
				setApplications(res.checkProviderSession.applications);

				// get stats for above for tabs
				const totalInvited = res.checkProviderSession.applications.filter(
					(application) => application.status === APPLICATION_STATUS_ENUM.INVITED
				).length;
				const totalApplied = res.checkProviderSession.applications.filter(
					(application) => application.status === APPLICATION_STATUS_ENUM.APPLIED
				).length;
				const totalOffered = res.checkProviderSession.jobs.filter(
					(job) =>
						job.status === JOB_STATUS_ENUM.OFFER &&
						job.offer_status === JOB_OFFER_STATUS_ENUM.OFFERED
				).length;

				// set tab info
				setTotalInvited(totalInvited);
				setTotalApplied(totalApplied);
				setTotalOffered(totalOffered);
			}
		} catch (err) {
			console.log('err', err);
		}
	};

	const handleApplicationInviteClick = (application) => {
		// set state
		setSelectedCarePlan(application.jobPost.carePlan);
		setSelectedAction(application);
		setShowCarePlanInfoModel(true);
		setSelectedActionView(SELECTED_ACTION_VIEW.APPLICATION);
	};
	const handleOfferClick = (job) => {
		// set state
		setSelectedCarePlan(job.carePlan);
		setSelectedAction(job);
		setShowCarePlanInfoModel(true);
		setSelectedActionView(SELECTED_ACTION_VIEW.JOB);
	};

	const handleExitClickModal = () => {
		setSelectedCarePlan(null);
		setSelectedAction(null);
		setShowCarePlanInfoModel(false);
	};
	const handleExitModalWithRefresh = () => {
		setShowCarePlanInfoModel(false);

		fetchJobsAndApplications();
		setSelectedCarePlan(null);
		setSelectedAction(null);
	};
	const renderInvited = () => {
		// get all the invites for a job post
		const invites = applications.filter((application) => {
			if (application.status === APPLICATION_STATUS_ENUM.INVITED) return application;
		});

		return invites.map((invite) => {
			return (
				<JobInviteTile onClick={handleApplicationInviteClick} key={invite.id} {...invite} />
			);
		});
	};

	const renderApplied = () => {
		// get all the application by this CarePro
		const applied = applications.filter((application) => {
			if (application.status === APPLICATION_STATUS_ENUM.APPLIED) return application;
		});

		return applied.map((application) => {
			return (
				<JobApplicationTile
					onClick={handleApplicationInviteClick}
					key={application.id}
					{...application}
				/>
			);
		});
	};

	const renderJobOffers = () => {
		// get all the job offers
		const offers = jobs.filter((job) => {
			if (
				job.status === JOB_STATUS_ENUM.OFFER &&
				job.offer_status === JOB_OFFER_STATUS_ENUM.OFFERED
			)
				return job;
		});

		return offers.map((offer) => {
			return <JobOfferTile onClick={handleOfferClick} key={offer.id} {...offer} />;
		});
	};
	const renderInviteApplicationsOffers = () => {
		// get all offers from jobs

		let items = [];
		let noItemsText = '';
		if (selectedTab === SELECTED_TAB_VIEW.Invited) {
			items = renderInvited();
			noItemsText = 'No Invites';
		} else if (selectedTab === SELECTED_TAB_VIEW.Applied) {
			items = renderApplied();
			noItemsText = 'No Applications';
		} else if (selectedTab === SELECTED_TAB_VIEW.Offered) {
			items = renderJobOffers();
			noItemsText = 'No Offers';
		}

		if (items.length > 0) {
			return (
				<>
					<ColumnFlex
						style={{
							borderRadius: '16px',

							padding: '20px 10px 10px 10px',
						}}
						fullWidth
						margin='10px 0 12px 0'
						alignCenter
						gap='16px'
					>
						{items}
					</ColumnFlex>
				</>
			);
		} else {
			return (
				<>
					<ColumnFlex
						style={{
							borderRadius: '16px',
							backgroundColor: Colors.theme.dark_accent_background,
							padding: '20px 10px 10px 10px',
						}}
						fullWidth
						margin='10px 0 12px 0'
						alignCenter
					>
						<Avatar
							style={{
								backgroundColor: 'white',
							}}
							color={Colors.theme.dark_accent_background}
							height='140px'
							width='140px'
						>
							<Icon size='4x' color={Colors.greenLightTeal} icon={faPlus} />
						</Avatar>
						<Text margin='10px' color={Colors.greenLightTeal} mediumText>
							{noItemsText}
						</Text>
					</ColumnFlex>
				</>
			);
		}
	};

	const renderProviderPendingText = () => {
		if (provider && provider.careProRegistryStatus) {
			// check if any of the status are in false
			const registryStatus = provider.careProRegistryStatus.registry_approval;
			const documentStatus = provider.careProRegistryStatus.documents_approval;
			const stripeStatus = provider.stripeStatus.status === 'verified';
			const stripeStatusPending = provider.stripeStatus.status === 'pending';

			if (!registryStatus || !documentStatus || !stripeStatus) {
				return (
					<ColumnFlex gap='20px'>
						<Text fontWeight='100' color={Colors.black} largeText>
							Looks like theres still some pending items for your account.
						</Text>

						{!documentStatus && (
							<RowFlex
								gap='10px'
								style={{
									alignItems: 'flex-start',
								}}
							>
								<div
									style={{
										height: '15px',
										width: '15px',
										borderRadius: '50%',
										border: `solid 2px ${Colors.primary.primary}`,
										backgroundColor: 'white',
										marginTop: '5px',
									}}
								/>

								<a
									href={'/carepro/documents'}
									style={{
										textDecoration: 'none',
										width: '100%',
									}}
								>
									<Text
										underline
										pointer
										fontWeight='500'
										color={Colors.primary.primary}
										largeText
										bold
									>
										Please complete the document verification process.
									</Text>
								</a>
							</RowFlex>
						)}

						{!stripeStatus && (
							<>
								{stripeStatusPending ? (
									<RowFlex
										gap='10px'
										style={{
											alignItems: 'flex-start',
										}}
									>
										<div
											style={{
												height: '15px',
												width: '15px',
												borderRadius: '50%',
												border: `solid 2px ${Colors.primary.primary}`,
												backgroundColor: 'white',
												marginTop: '5px',
											}}
										/>
										<Text fontWeight='100' color={Colors.black} largeText>
											Please be patient your banking information is currently
											being verified.
										</Text>
									</RowFlex>
								) : (
									<RowFlex
										gap='10px'
										style={{
											alignItems: 'flex-start',
										}}
									>
										<div
											style={{
												height: '15px',
												width: '15px',
												borderRadius: '50%',
												border: `solid 2px ${Colors.primary.primary}`,
												backgroundColor: 'transparent',
												marginTop: '5px',
											}}
										/>
										<a
											href={'/carepro/verify-banking'}
											style={{
												textDecoration: 'none',
												width: '100%',
											}}
										>
											<Text
												underline
												pointer
												bold
												fontWeight='500'
												color={Colors.primary.primary}
												largeText
											>
												Please complete the banking verification process.
												Your account is currently unverified.
											</Text>
										</a>
									</RowFlex>
								)}
							</>
						)}
						{!registryStatus && (
							<Text fontWeight='100' color={Colors.black} largeText>
								Please be patient as our team is reviewing your application and will
								get back to you soon.
							</Text>
						)}
					</ColumnFlex>
				);
			} else {
				return null;
			}
		}
	};
	return (
		<ParentContainer
			style={{
				minHeight: '100vh',
			}}
		>
			<AnimatePresence>
				{showCarePlanInfoModel && (
					<CarePlanInfo
						handleExitModalWithRefresh={handleExitModalWithRefresh}
						carePlan={selectedCarePlan}
						selectedAction={selectedAction}
						handleExitClickModal={handleExitClickModal}
						selectedActionView={selectedActionView}
						provider={props.provider}
						conversations={props.conversations}
						pushToChat={props.pushToChat}
						careProContactUser={props.careProContactUser}
					/>
				)}
			</AnimatePresence>
			<RowFlex
				columnMobile
				fullWidth
				padding='20px 0 0 0'
				style={{
					maxWidth: '1200px',
				}}
			>
				<PHDColumn
					style={{
						flex: 1,
					}}
					gap='24px'
				>
					<Title fontWeight='100' nubtitle color={Colors.primary.primary}>
						Welcome CarePro
					</Title>
					<Text fontWeight='100' color={Colors.black} largeText>
						You can view your job applications, payments, and schedule.
					</Text>
					{renderProviderPendingText()}
					<ColumnFlex></ColumnFlex>
				</PHDColumn>
				<PHDColumn
					style={{
						flex: 1,
					}}
					gap='24px'
				>
					{(pendingVerificationCardPayments || pendingVerificationPayouts) && (
						<Text color={Colors.yellow} largeText>
							Your Stripe information is undergoing verification.
						</Text>
					)}
					<ColumnFlex
						style={{
							flex: 1,
						}}
						gap='20px'
						wrap
						fullWidth
					>
						<PHWhiteCon
							style={{
								minWidth: '100%',

								flex: 3,
								backgroundColor: 'white',
								maxHeight: '800px',
								overflowY: 'auto',
							}}
						>
							<RowFlex fullWidth padding='0px 10px'>
								<SectionTabs
									selectedTab={selectedTab}
									handleSelectTab={handleSelectTab}
									totalInvited={totalInvited}
									totalOffered={totalOffered}
									totalApplied={totalApplied}
								/>
							</RowFlex>
							{/* <Title
							subtitle
							color={Colors.greenLightTeal}
							margin='0 0 20px 0'
							fontWeight='700'
						>
							Invites/Applications/Offers
						</Title> */}

							{renderInviteApplicationsOffers()}
						</PHWhiteCon>
						{/* <PHWhiteCon
							style={{
								minWidth: isMobile ? '100%' : '440px',

								flex: 3,
								backgroundColor: 'white',
							}}
						>
							<Title
								subtitle
								color={Colors.greenLightTeal}
								margin='0 0 20px 0'
								fontWeight='700'
							>
								Active Jobs
							</Title>

							<ColumnFlex
								style={{
									borderRadius: '16px',
									backgroundColor: Colors.theme.dark_accent_background,
									padding: '20px 10px 10px 10px',
								}}
								fullWidth
								margin='10px 0 12px 0'
								alignCenter
							>
								<Avatar
									style={{
										backgroundColor: 'white',
									}}
									color={Colors.theme.dark_accent_background}
									height='140px'
									width='140px'
								>
									<Icon size='4x' color={Colors.greenLightTeal} icon={faPlus} />
								</Avatar>
								<Text margin='10px' color={Colors.greenLightTeal} mediumText>
									Apply to be Matched
								</Text>
							</ColumnFlex>
						</PHWhiteCon> */}
						<PHWhiteCon
							style={{
								minWidth: '100%',

								flex: 3,
								backgroundColor: 'white',
							}}
						>
							<Title
								subtitle
								color={Colors.greenLightTeal}
								margin='0 0 20px 0'
								fontWeight='700'
							>
								Today's Schedule
							</Title>

							<ColumnFlex
								style={{
									borderRadius: '16px',
									backgroundColor: Colors.theme.dark_accent_background,
									padding: '20px 10px 10px 10px',
								}}
								fullWidth
								margin='10px 0 12px 0'
								alignCenter
							>
								<Avatar
									style={{
										backgroundColor: 'white',
									}}
									color={Colors.theme.dark_accent_background}
									height='140px'
									width='140px'
								>
									<Icon size='4x' color={Colors.greenLightTeal} icon={faPlus} />
								</Avatar>
								<Text margin='10px' color={Colors.greenLightTeal} mediumText>
									Apply to be Matched
								</Text>
							</ColumnFlex>
						</PHWhiteCon>
					</ColumnFlex>
					<RowFlex
						style={{
							flex: 1,
						}}
						gap='20px'
						wrap
						fullWidth
						columnMobile
					></RowFlex>
				</PHDColumn>
			</RowFlex>
		</ParentContainer>
	);
};

const mapStateToProps = ({ provider }, props) => {
	return {
		provider: provider.provider,
		conversations: provider.conversations,
		verification: provider.verification,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		showInfoModel: (info) => dispatch(showInfoModel(info)),
		pushToChat: (conversationId) => dispatch(push(`/provider/chat/${conversationId}`)),
		careProContactUser: (params) => dispatch(careProContactUser(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderHomeLanding);
