import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { JOB_POST_MODEL } from '../../../core/api/models';

const fetchCarePlanJobPosts = async ({ carePlanId }) => {
	try {
		const data = await api.graph({
			query: `{
					fetchCarePlanJobPosts(care_plan_id: ${carePlanId}) {
						${JOB_POST_MODEL}
					}
				}`,
		});

		return data.fetchCarePlanJobPosts;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchCarePlanJobPostsQuery = (carePlanId) => {
	return useQuery({
		queryKey: ['fetchCarePlanJobPosts', carePlanId],
		queryFn: () => {
			return fetchCarePlanJobPosts({ carePlanId });
		},
	});
};
