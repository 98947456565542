import React, { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import styled from 'styled-components';

import Colors from 'colors';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import api from 'scripts/api';

import { ColumnFlex, RowFlex, Avatar, BackDropBg } from 'basicStyles';

import { CARE_PLAN_ACTIONS } from './CarePlanParent';
import CarePlanActionsFilter from './CarePlanActionsFilter';

import Icon from 'AppComp/Icon';
import useWindowSize from 'scripts/useWindowSize';

import { PATIENT_CARE_PLAN_DETAIL_INFO } from 'constants';

const framerSidebarBackground = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};

const TileStyled = styled.div`
	box-shadow: 0 1px 4px rgba(37, 50, 56, 0.12), 0 1px 3px rgba(37, 50, 56, 0.24);
	height: 200px;
	width: 180px;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 30px;
	background-color: ${Colors.grey};
	margin: 10px;
	transition: all ease 0.3s;
	cursor: pointer;
	position: relative;

	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}
`;

export const Tile = (props) => {
	const { tile, carePlanAction } = props;

	return (
		<TileStyled onClick={() => props.updateSelectedTile(carePlanAction)}>
			<div
				style={{
					width: '40px',
					height: '30px',
					position: 'absolute',
					right: 0,
					margin: 'auto',
					left: 0,
					top: '-20px',
					borderRadius: '4px',
					backgroundColor: Colors.secondary.blue,
				}}
			/>
			<Avatar
				style={{
					backgroundColor: 'white',
				}}
				color={Colors.greenLightTeal}
				height='120px'
				width='120px'
			>
				<Icon size='4x' color={Colors.greenLightTeal} icon={tile.icon} />
			</Avatar>
			<Text
				margin='10px'
				style={{ textAlign: 'center' }}
				color={Colors.greenLightTeal}
				mediumText
			>
				{tile.text}
			</Text>
		</TileStyled>
	);
};

const CarePlanHome = (props) => {
	const { patient, carePlan, moveToStep, history } = props;
	const [showcarePlanActionsModal, setShowCarePlanActionsModal] = useState(false);
	const { width } = useWindowSize();

	const carePlanActionsModalHandler = () => {
		setShowCarePlanActionsModal((prevValue) => !prevValue);
	};

	const saveCarePlanActions = (value) => {
		console.log(value);
	};

	const updateSelectedTile = (action) => {
		moveToStep(action);
	};

	return (
		<>
			<ColumnFlex
				style={{
					height: 'fit-content',
					backgroundColor: 'white',
					borderRadius: '12px',
				}}
				padding={width < 768 ? '60px 0px' : '60px 60px'}
				center
				gap='32px'
			>
				<ColumnFlex fullWidth center gap='8px'>
					<Title nubtitle fontWeight='700'>
						Manage Your Care Plan
					</Title>
				</ColumnFlex>
				<ColumnFlex
					fullWidth
					style={{
						flex: 1,
						borderRadius: '24px',
					}}
					center
					gap='32px'
				>
					<RowFlex columnMobile>
						<Tile
							updateSelectedTile={updateSelectedTile}
							tile={PATIENT_CARE_PLAN_DETAIL_INFO[0]}
							carePlanAction={CARE_PLAN_ACTIONS.CLIENT_INFO}
						/>
						<Tile
							updateSelectedTile={updateSelectedTile}
							tile={PATIENT_CARE_PLAN_DETAIL_INFO[1]}
							carePlanAction={CARE_PLAN_ACTIONS.CARE_PERSONAL}
						/>
						<Tile
							updateSelectedTile={updateSelectedTile}
							tile={PATIENT_CARE_PLAN_DETAIL_INFO[2]}
							carePlanAction={CARE_PLAN_ACTIONS.LOGISTICS}
						/>
						<Tile
							updateSelectedTile={updateSelectedTile}
							tile={PATIENT_CARE_PLAN_DETAIL_INFO[3]}
							carePlanAction={CARE_PLAN_ACTIONS.CARE_NEEDS}
						/>
					</RowFlex>
					{/* <Button
						backgroundColor={Colors.primary.primary}
						width={'300px'}
						height={'70px'}
						borderRadius='32px'
						style={{
							shadow: 'none',
						}}
						onClick={() => history.push('/createjob')}
					>
						<Text fontWeight='500' color={'white'} largeText>
							Create Job Post
						</Text>
					</Button>

					<Button
						onClick={carePlanActionsModalHandler}
						backgroundColor={Colors.primary.primary}
						width={'300px'}
						height={'70px'}
						margin='auto'
						borderRadius='32px'
						style={{
							boxShadow: 'none',
						}}
					>
						<Text fontWeight='500' color={'white'} largeText>
							CarePlan Actions
						</Text>
					</Button> */}

					<AnimatePresence>
						{showcarePlanActionsModal && (
							<>
								<BackDropBg
									{...framerSidebarBackground}
									onClick={carePlanActionsModalHandler}
									aria-hidden='true'
								></BackDropBg>
								<div
									style={{
										position: 'fixed',
										top: '40%',
										zIndex: 100,
										left: '50%',
									}}
								>
									<CarePlanActionsFilter
										saveCarePlanActions={saveCarePlanActions}
									/>
								</div>
							</>
						)}
					</AnimatePresence>
				</ColumnFlex>
			</ColumnFlex>
			{/* <ClientSchedule carePlan={carePlan} /> */}
		</>
	);
};

export default CarePlanHome;
