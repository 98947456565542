import React from 'react';

import {
	StyledCareNeedsContainer,
	StyledAbility,
	StyledTraitHeading,
	StyledTraitValue,
} from './styles';

const CareNeeds = (props) => {
	const { traits } = props;

	return (
		<StyledCareNeedsContainer>
			{traits.map((trait) => (
				<StyledAbility>
					<StyledTraitHeading>{trait.trait}</StyledTraitHeading>
					<StyledTraitValue>{trait.value}</StyledTraitValue>
				</StyledAbility>
			))}
		</StyledCareNeedsContainer>
	);
};

export default CareNeeds;
