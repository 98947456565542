import React from 'react';

import { StyledLabel } from './styles';

const Label = (props) => {
	const { children } = props;

	return <StyledLabel>{children}</StyledLabel>;
};

export default Label;
