import React from 'react';

import {
	StyledNavFilterContainer,
	StyledNavItem,
	StyledItemText,
	StyledNavItemStat,
} from '../styles';

const NavFilter = (props) => {
	const { items, navSelectorHandler } = props;

	const itemsArray = Object.entries(items).map(([key, item]) => ({
		...item,
		key,
	}));

	return (
		<StyledNavFilterContainer>
			{itemsArray.map((item) => (
				<StyledNavItem
					active={item.active}
					onClick={() => navSelectorHandler(item.key, 'active', !item.active)}
					key={item.key}
				>
					<StyledItemText active={item.active}>
						{item.text} <StyledNavItemStat>({item.stat})</StyledNavItemStat>
					</StyledItemText>
				</StyledNavItem>
			))}
		</StyledNavFilterContainer>
	);
};

export default NavFilter;
