import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { PrimaryButton, DangerButton } from '../../../../../corev2/Buttons';
import { ClientTile } from '../../../../../corev2/Tiles';
import Loader from '../../../../../corev2/Loader';

import PageHeading from '../../../core/components/PageHeading';
import ShiftItem from '../../../core/components/ShiftItem';
import CareNeeds from '../../components/CareNeeds';
import JobAction from '../../components/JobAction';
import JobOfferGuide from '../../components/JobOfferGuide';

import { coreRoutes } from '../../../core/routes/constants';
import { jobPostsRoutes } from '../../routes/constants';
import { profileRoutes } from '../../../profiles/routes/constants';

import { JobPostProvider } from '../../context/JobPostContext';

import { useAuth } from '../../../core/hooks/useAuth';
import { useJobPost } from '../../hooks/useJobPost';

import { useApplyToJobPostMutation } from '../../api/mutations/useApplyToJobPostMutation';
import { useRespondToJobOfferMutation } from '../../api/mutations/useRespondToJobOfferMutation';
import { useContactClientMutation } from '../../api/mutations/useContactClientMutation';

import {
	StyledJobPostPage,
	StyledPageHeadingContainer,
	StyledJobSection,
	StyledActionsContainer,
	StyledAction,
	StyledActionHeading,
	StyledStatusWrapper,
	StyledStatusIconContainer,
	StyledStatus,
	StyledMainContainer,
	StyledMainWrapper,
	StyledShiftsContainer,
} from './styles';

import { functions } from '../../../../../corev2/utils';

import { formatIncomingShift, sortShifts, transcribeTraits } from '../../../core/lib';

const JobPostContent = (props) => {
	const [shifts, setShifts] = useState([]);
	const history = useHistory();

	const { authCarePro } = useAuth();
	const { jobPost, jobStatus, isLoadingJobPost, updateJobPost } = useJobPost();

	const applyToJobPost = useApplyToJobPostMutation();
	const respondToJobOffer = useRespondToJobOfferMutation();
	const contactClient = useContactClientMutation();

	useEffect(() => {
		if (jobPost) {
			const matchingJob = jobPost.jobs.find((job) => job.job_post_id === jobPost.id);
			if (matchingJob) {
				const formattedShifts = matchingJob.shifts.map((shift) =>
					formatIncomingShift(shift)
				);

				setShifts(sortShifts(formattedShifts));
			}
		}
	}, [jobPost]);

	const applyToJobPostHandler = async () => {
		try {
			const data = await applyToJobPost.mutateAsync({
				jobPostId: jobPost.id,
				careProId: authCarePro.id,
			});

			updateJobPost();
		} catch (error) {
			console.error('Applying to job post failed.', error);
		}
	};

	const respondToJobOfferHandler = async (status) => {
		try {
			const job = jobPost.jobs[0];

			await respondToJobOffer.mutateAsync({
				jobId: job.id,
				status: status,
				careProId: authCarePro.id,
			});

			updateJobPost();
		} catch (error) {
			console.error('Applying to job post failed.', error);
		}
	};

	const chatWithClientHandler = async (clientId) => {
		try {
			const data = await contactClient.mutateAsync({ careProId: authCarePro.id, clientId });

			history.push(coreRoutes.chat);
		} catch (error) {
			console.error('Initiating chat with client failed.', error);
		}
	};

	const viewClientProfileHandler = (clientId) => {
		history.push(profileRoutes.clientProfile(clientId));
	};

	const goToSchedulePage = () => {
		history.push(jobPostsRoutes.jobPostSchedule(jobPost.id));
	};

	if (isLoadingJobPost) {
		return <Loader />;
	}

	return (
		<>
			<StyledPageHeadingContainer>
				<PageHeading>{jobPost.title}</PageHeading>
			</StyledPageHeadingContainer>

			<JobOfferGuide />

			<StyledJobSection>
				<StyledActionsContainer>
					<StyledAction>
						<StyledActionHeading>Status</StyledActionHeading>

						<StyledStatusWrapper>
							<StyledStatusIconContainer>
								<FontAwesomeIcon icon={faExclamationCircle} size='lg' />
							</StyledStatusIconContainer>

							<StyledStatus>
								{functions.capitalizeFirstLetter(jobStatus)}
							</StyledStatus>
						</StyledStatusWrapper>
					</StyledAction>

					<JobAction
						title='Schedule'
						description='View the care schedule for this job to manage your availability.'
						goToPage={goToSchedulePage}
					/>

					{jobStatus === 'invited' && (
						<StyledAction>
							<PrimaryButton size='small' onClick={applyToJobPostHandler}>
								Apply
							</PrimaryButton>
						</StyledAction>
					)}

					{jobStatus === 'offered' && (
						<StyledAction>
							<PrimaryButton
								size='small'
								onClick={() => respondToJobOfferHandler('accepted')}
							>
								Accept
							</PrimaryButton>
							<DangerButton
								size='small'
								onClick={() => respondToJobOfferHandler('rejected')}
							>
								Reject
							</DangerButton>
						</StyledAction>
					)}
				</StyledActionsContainer>

				<StyledMainContainer>
					<StyledMainWrapper>
						<StyledActionHeading>Client</StyledActionHeading>

						<ClientTile
							key={jobPost.carePlan.coordinator.id}
							id={jobPost.carePlan.coordinator.id}
							name={jobPost.carePlan.coordinator.name}
							hourlyRate='12'
							jobDescription={jobPost.description}
							customButtonText='Chat'
							onCustomButtonClick={() =>
								chatWithClientHandler(jobPost.carePlan.coordinator.id)
							}
							viewClientProfileHandler={viewClientProfileHandler}
						/>
					</StyledMainWrapper>

					<StyledMainWrapper>
						<StyledActionHeading>Care Needs</StyledActionHeading>

						<CareNeeds traits={[]} />
					</StyledMainWrapper>

					{shifts.length > 0 && (
						<StyledMainWrapper>
							<StyledActionHeading>Shifts Offered</StyledActionHeading>

							<StyledShiftsContainer>
								{shifts.map((shift) => (
									<ShiftItem
										key={shift.id}
										shift={shift}
										isSelected={true}
										onClickHandler={() => {}}
									/>
								))}
							</StyledShiftsContainer>
						</StyledMainWrapper>
					)}
				</StyledMainContainer>
			</StyledJobSection>
		</>
	);
};

const JobPostPage = () => {
	return (
		<JobPostProvider>
			<StyledJobPostPage>
				<JobPostContent />
			</StyledJobPostPage>
		</JobPostProvider>
	);
};

export default JobPostPage;
