import React, { createContext, useReducer, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { useFetchShiftQuery } from '../../api/queries/useFetchShiftQuery';

const ShiftContext = createContext();

const ShiftProvider = ({ children }) => {
	const { shiftId } = useParams();

	const {
		data: shift,
		isLoading: isLoadingShift,
		refetch: refreshShift,
	} = useFetchShiftQuery({ shiftId: shiftId });

	return (
		<ShiftContext.Provider value={{ shift, isLoadingShift, refreshShift }}>
			{children}
		</ShiftContext.Provider>
	);
};

export const useShiftContext = () => useContext(ShiftContext);
export default ShiftProvider;
