import React from 'react';
import styled from 'styled-components';
import Colors from 'colors';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import { device } from 'AppComp/styled/StyledMediaQuery';

import { ColumnFlex, RowFlex } from '../../styled/Basic';

import FormButton from '../../FormButton';
export const MARGIN_TITLE = '20px 0 10px 0';
export const MARGIN_ITEMS = '10px 0 10px 0';

export const FORM_ITEM_LENGTH = '260px';
export const FormItemsLength = styled.div`
	width: ${({ width }) => width};
	margin: ${({ margin }) => margin ?? MARGIN_ITEMS};
	color: black;

	@media ${device.tablet} {
		max-height: 90vh;
	}
`;

export const OBFormButton = styled(FormButton)`
	width: 180px;
	height: 70px;
	border-radius: 50px;

	${({ valid, backgroundColor }) =>
		!valid &&
		`
		background-color: white;
		border: 1px solid ${backgroundColor || Colors.greenLightTeal};
		color: ${backgroundColor || Colors.greenLightTeal};

	`}

	${({ valid, backgroundColor }) =>
		valid &&
		`
		background-color: ${backgroundColor || Colors.greenLightTeal};
		color: white;
	`}
`;

export const OBBasicCon = styled(ColumnFlex)`
	padding: 20px 100px 20px 100px;
	max-width: 640px;
	margin: auto;
	border-radius: 12px;
	background-color: white;
	align-items: center;
	justify-items: center;
`;
export const OBFormButtonText = (props) => {
	const { backgroundColor, valid, type, text } = props;

	return (
		<OBFormButton
			margin='0 0 20px 0'
			backgroundColor={backgroundColor}
			type={type}
			valid={valid}
		>
			<Title trititle color={valid ? Colors.fullWhite : Colors.darkerGray}>
				{text}
			</Title>
		</OBFormButton>
	);
};

export const OBButtonText = (props) => {
	const { backgroundColor, valid, text, onClick } = props;

	return (
		<OBFormButton
			onClick={onClick}
			margin='0 0 20px 0'
			backgroundColor={backgroundColor}
			valid={valid}
		>
			<Title trititle color={valid ? Colors.fullWhite : Colors.darkerGray}>
				{text}
			</Title>
		</OBFormButton>
	);
};

export const OBTitle = (props) => {
	return (
		<Title style={{ textAlign: 'center' }} maxWidth={'320px'} margin={MARGIN_TITLE} subtitle>
			{props.text}
		</Title>
	);
};
export const OBSubText = (props) => {
	return (
		<Text nurseInfo gray>
			{props.text}
		</Text>
	);
};
export const CustomRadioInput = styled.input`
	margin-right: 8px;
	input[type='radio'] {
		border: 0px;
		width: 100%;
		height: 2em;
	}
`;

const RadioRow = styled(RowFlex)`
	padding: 10px 20px;
	width: 380px;
	height: 80px;
	border: solid 1px ${Colors.gray};
	border-radius: 30px;
	background-color: white;
`;
export const WhatIs = (props) => {
	const { form, field, type, custom, title, text } = props;

	return (
		<RadioRow spaceBetween alignCenter margin='10px'>
			<ColumnFlex>
				<Text bold largeText color='black'>
					{title}
				</Text>
				<Text mediumText color='black'>
					{text}
				</Text>
			</ColumnFlex>
			<RadioRoot>
				<label>
					{name}
					<RadioInput {...field} type={'checkbox'} checked={form.values[field.name]} />
					<RadioFill />
				</label>
			</RadioRoot>
		</RadioRow>
	);
};

export const RadioTileSelect = (props) => {
	const selected = props.selectedItems.find((d, i) => {
		return d === props.name;
	});

	const bgColor = selected ? Colors.primary.primary : '#FAFAFA';

	return (
		<RadioRow
			style={{ cursor: 'pointer' }}
			onClick={() => props.handleTileClick(props.name)}
			spaceBetween
			alignCenter
			margin='10px'
		>
			<ColumnFlex>
				<Text bold mediumText color='black'>
					{props.title}
				</Text>
				<Text subtitle color='black'>
					{props.text}
				</Text>
			</ColumnFlex>
			<RadioRoot backgroundColor={bgColor} />
		</RadioRow>
	);
};
export const BackText = (props) => {
	return (
		<Text providerOnboard onClick={props.onClick} pointer fontWeight={'thin'} underline>
			Back
		</Text>
	);
};
export const RadioRoot = styled.div`
	margin: 5px;
	cursor: pointer;
	width: ${(props) => (props.size ? props.size : 35)}px;
	height: ${(props) => (props.size ? props.size : 35)}px;
	position: relative;
	border-radius: 100%;
	border: 1px solid ${(props) => (props.borderColor ? props.borderColor : '#DDD')};
	background: ${(props) => (props.backgroundColor ? props.backgroundColor : '#FAFAFA')};

	label {
		margin-left: 0px;
	}
	&::before {
		content: '';

		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		box-sizing: border-box;
		pointer-events: none;
		z-index: 0;
	}
`;

export const RadioFill = styled.div`
	background: ${Colors.greenLightTeal};
	width: 0;
	height: 0;
	border-radius: 100%;
	position: absolute;
	top: 50%;
	right: -25%;
	transform: translate(-50%, -50%);
	transition: width 0.2s ease-in, height 0.2s ease-in;
	pointer-events: none;
	z-index: 1;

	&::before {
		content: '';
		opacity: 0;
		width: calc(20px - 4px);
		position: absolute;
		height: calc(20px - 4px);
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 1px solid ${Colors.greenLightTeal};
		border-radius: 100%;
	}
`;

export const RadioInput = styled.input`
	opacity: 0;
	z-index: 2;
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
	margin: 0;
	cursor: pointer;

	&:focus {
		outline: none;
	}

	&:checked {
		& ~ ${RadioFill} {
			width: calc(100% - 8px);
			height: calc(100% - 8px);
			transition: width 0.2s ease-out, height 0.2s ease-out;

			&::before {
				opacity: 1;
				transition: opacity 1s ease;
			}
		}
	}
`;
