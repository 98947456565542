import styled from 'styled-components';

import { mediaQueries } from '../../../../../corev2/utils';

export const StyledFormWrapper = styled.form`
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;

	text-align: left;

	@media (${mediaQueries.tablet}) {
		width: 500px;
	}
`;
