import React from 'react';

import Modal from '../../../core/components/Modal';
import ShiftItem from '../ShiftItem';
import { DangerButton, TransparentButton } from '../../../../../corev2/Buttons';

import { StyledFormContainer, StyledFormTitle, StyledButtonsContainer } from './styles';

const CancelShiftForm = (props) => {
	const { shift, cancelShiftHandler, cancelShiftModalHandler } = props;

	return (
		<Modal>
			<StyledFormContainer>
				<StyledFormTitle>Cancel Shift</StyledFormTitle>

				<ShiftItem shift={shift} />

				<StyledButtonsContainer>
					<DangerButton size='small' onClick={() => cancelShiftHandler(shift.id)}>
						Cancel Shift
					</DangerButton>

					<TransparentButton size='small' onClick={cancelShiftModalHandler}>
						Close
					</TransparentButton>
				</StyledButtonsContainer>
			</StyledFormContainer>
		</Modal>
	);
};

export default CancelShiftForm;
