import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { toast } from 'react-toastify';

import Colors from 'colors';
import { TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG } from 'constants';

import api from 'scripts/api';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';

import { ParentContainer, PHWhiteCon, PHDColumn } from 'AppComp/provider/ProviderHomeDashboard';
import { ColumnFlex, RowFlex, Avatar } from 'AppComp/styled/Basic';

import { SHIFT_MODEL } from 'reducers/graphqlModelTypes';

import ShiftNotes from './ShiftNotes';
import ShiftTimeControl from './ShiftTimeControl';
import CareActivities from '../CareActivities';

import { ClientTile } from './ShiftViews';

const INIT_LOCATION = {
	status: false,
	error: null,
	latitude: null,
	longitude: null,
	loading: false,
	errorText: null,
};
const ShiftParent = (props) => {
	const [location, setLocation] = useState(INIT_LOCATION);

	const [client, setClient] = useState(null);
	const [carePlan, setCarePlan] = useState(null);

	const [jobs, setJobs] = useState(null);
	const [shift, setShift] = useState(null);

	const [createdShifts, setCreatedShifts] = useState([]);

	const [loadingClock, setLoadingClock] = useState(false);

	useEffect(() => {
		fetchCreatedShifts();
		fetchActiveShift();
	}, []);

	const handleUpdateLocalShift = (shift) => {
		setShift(shift);

		setCarePlan(shift.job.carePlan);

		setJobs(shift.job);
		setClient(shift.job.carePlan);
	};
	const fetchCreatedShifts = async () => {
		try {
			const res = await api.graph({
				query: `{
					fetchShiftsForCarePro {
						${SHIFT_MODEL}
					}
				}`,
			});
			if (res.fetchShiftsForCarePro) {
				console.log('fetchShiftsForCarePro', res.fetchShiftsForCarePro);
				setCreatedShifts(res.fetchShiftsForCarePro);
			}
		} catch (err) {
			console.log('fetchCreatedShifts err', err);
			throw new Error(err);
		}
	};

	const deselectShift = async () => {
		try {
			if (shift === null) return;
			const res = await api.graph({
				query: `mutation {
					deselectShift(shift_id: ${shift.id}) {
						${SHIFT_MODEL}
					}
				}`,
			});
			if (res.deselectShift) {
				setShift(null);
				setLocation(INIT_LOCATION);
				setClient(null);
				setCarePlan(null);

				toast.success('Deselected Shift', TOAST_SUCCESS_CONFIG);
			}
		} catch (err) {
			console.log('deselectShift err', err);
		}
	};
	const fetchActiveShift = async () => {
		try {
			const res = await api.graph({
				query: `{
          checkForActiveShift{
            ${SHIFT_MODEL}
          }
        }`,
			});

			if (res.checkForActiveShift) {
				handleUpdateLocalShift(res.checkForActiveShift);

				setLocation({
					status: true,
					error: null,
					latitude: res.checkForActiveShift.location_lat,
					longitude: res.checkForActiveShift.location_long,
					loading: false,
				});
			} else {
				console.log('no active shift');
			}
		} catch (err) {
			console.log('fetchActiveShift err', err);

			//toast.error('Something went wrong uploading, please try again', TOAST_ERROR_CONFIG);

			console.log(err);
		}
	};
	const handleSuccesRequest = (clockIn) => {
		setLocation({
			loading: true,
			status: false,
			error: null,
			latitude: null,
			longitude: null,
		});

		handleLocation(clockIn);
	};
	const handleLocation = (clockIn) => {
		const { status, error } = location;

		setLoadingClock(true);
		console.log('handleLocation clock in ');
		const clockIn_ = clockIn;
		const success = (position) => {
			console.log('success', position);
			//console.log('this.clockin', this.clockIn_);
			console.log('clockIn_', clockIn_);
			setLocation({
				status: true,
				error: null,
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
				loading: false,
			});
			if (clockIn) {
				handleClockIn(position.coords.latitude, position.coords.longitude);
			} else {
				handleEndShift(position.coords.latitude, position.coords.longitude);
			}
		};

		const errorHandler = (error) => {
			setLoadingClock(false);
			let status = '';

			console.log('errorHandler', error);
			switch (error.code) {
				case error.PERMISSION_DENIED:
					status = 'User denied the request for Geolocation.';
					break;
				case error.POSITION_UNAVAILABLE:
					status = 'Location information is unavailable.';
					break;
				case error.TIMEOUT:
					status = 'The request to get user location timed out.';
					break;
				case error.UNKNOWN_ERROR:
					status = 'An unknown error occurred.';
					break;
			}

			toast.error(status, TOAST_ERROR_CONFIG);
			setLocation({
				status: false,
				error: status,
				latitude: null,
				longitude: null,
				loading: false,
				errorText: status,
			});
		};

		if (!navigator.geolocation) {
			setLocation({
				status: false,
				error: 'Geolocation is not supported by your browser, please contact admin for help',
				latitude: null,
				longitude: null,
				loading: false,
			});

			toast.error(
				'Geolocation is not supported by your browser, please contact admin for help',
				TOAST_ERROR_CONFIG
			);
		} else {
			console.log('yeet yeet');
			var options = {
				enableHighAccuracy: true,
				timeout: 15000,
				maximumAge: 0,
			};
			navigator.geolocation.getCurrentPosition(success, errorHandler, options);
		}
	};

	const selectShift = async (shift) => {
		try {
			const res = await api.graph({
				query: `mutation {
          selectShift(
            shift_id: ${shift.id}

          ) {
            	${SHIFT_MODEL}
			}
		}`,
			});
			if (res.selectShift) {
				setShift(res.selectShift);
				toast.success('Selected Shift', TOAST_SUCCESS_CONFIG);

				setCarePlan(res.selectShift.job.carePlan);
				setClient(res.selectShift.job.carePlan);
			}
		} catch (err) {
			console.log('selectShift err', err);

			if (Array.isArray(err)) {
				// get the error message from the error array
				const error = err[0].message;
				// get the error message
				toast.error(error, TOAST_ERROR_CONFIG);
			}
		}
	};
	const createShift = async (id) => {
		try {
			const res = await api.graph({
				query: `mutation {
          createShift(
            job_id: ${id}
          
          ) {
            ${SHIFT_MODEL}
          }
        }`,
			});

			if (res.createShift) {
				setShift(res.createShift);
				//toast.success('Created Shift', TOAST_SUCCESS_CONFIG);
			}
		} catch (err) {
			console.log('createShift err', err);
			// check if err is an array
			if (Array.isArray(err)) {
				// get the error message from the error array
				const error = err[0].message;
				// get the error message
				toast.error(error, TOAST_ERROR_CONFIG);
			}
		}
	};

	const handleClockIn = async (lat, long) => {
		try {
			const res = await api.graph({
				query: `mutation {
          startShift(
            shift_id: ${shift.id},
						location_lat: ${lat},
            location_long: ${long},
          ) {
            ${SHIFT_MODEL}
          }
        }`,
			});

			if (res.startShift) {
				setShift(res.startShift);
				toast.success('Clocked In', TOAST_SUCCESS_CONFIG);
			}
			setLoadingClock(false);
		} catch (err) {
			console.log('handleClockIn err', err);
			setLoadingClock(false);
		}
	};

	const handleEndShift = async (lat, long) => {
		try {
			const res = await api.graph({
				query: `mutation {
					endShift(
						shift_id: ${shift.id},
						location_lat: ${lat},
            location_long: ${long},
					) {
						${SHIFT_MODEL}
					}
				}`,
			});

			if (res.endShift) {
				setShift(res.endShift);
				toast.success('Clocked Out', TOAST_SUCCESS_CONFIG);
				setLoadingClock(false);
			}
		} catch (err) {
			console.log('endShift err', err);
			setLoadingClock(false);
		}
	};

	const handleUpdateShift = async (params) => {
		try {
			const updateShiftRes = await api.graph({
				query: `mutation {
					updateShift(
						shift_id: ${shift.id}
						shift_input: ${api.graphStringify(params)}
					) {
						${SHIFT_MODEL}
					}
				}`,
			});

			if (updateShiftRes.updateShift) {
				toast.success('Updated Shift', TOAST_SUCCESS_CONFIG);
				handleUpdateLocalShift(updateShiftRes.updateShift);

				return true;
			}
		} catch (err) {
			console.log('handleUpdateShift err', err);

			//throw new Error(err);
		}
	};

	const handleCancelShift = async () => {
		try {
			const cancelRes = await api.graph({
				query: `mutation {
					cancelShift(
						shift_id: ${shift.id}
					) {
						${SHIFT_MODEL}
					}
				}`,
			});

			if (cancelRes.cancelShift) {
				toast.success('Cancelled Shift', TOAST_SUCCESS_CONFIG);
				setShift(null);
				setLocation(INIT_LOCATION);
				setClient(null);
				setCarePlan(null);
			}
		} catch (err) {
			console.log('handleClockIn err', err);
		}
	};

	const handleSubmitShift = async () => {
		try {
			const res = await api.graph({
				query: `mutation {
					submitShift(
						shift_id: ${shift.id}
					) {
						${SHIFT_MODEL}
					}
				}`,
			});

			if (res.submitShift) {
				toast.success('Submitted Shift', TOAST_SUCCESS_CONFIG);

				setShift(null);
				setLocation(INIT_LOCATION);
				setClient(null);
				setCarePlan(null);
			}
		} catch (err) {
			console.log('hanldeSubmitShift err', err);

			// check if err is an array
			if (Array.isArray(err)) {
				// get the error message from the error array
				const error = err[0].message;
				// get the error message
				toast.error(error, TOAST_ERROR_CONFIG);
			} else {
				toast.error(
					'Something went wrong, please contact FYI team if issue persist',
					TOAST_ERROR_CONFIG
				);
			}
		}
	};
	console.log('shift parnet - shift', shift);

	const renderCreatedShifts = () => {
		if (createdShifts.length === 0) {
			return (
				<Text
					style={{
						textAlign: 'center',
						fontSize: '16px',
						fontWeight: '700',
					}}
				>
					No Shifts Scheduled
				</Text>
			);
		} else {
			return createdShifts.map((shift, index) => {
				return (
					<ClientTile
						key={index}
						shift={shift}
						handleShiftSelection={selectShift}
						createShift={createShift}
					/>
				);
			});
		}
	};
	return (
		<ParentContainer
			style={{
				minHeight: '100vh',
			}}
		>
			<PHDColumn>
				<Title bold nubtitle color={Colors.secondary.green}>
					Manage Shift
				</Title>
				<RowFlex margin='16px 0 0 0' gap='16px'>
					<ColumnFlex margin='16px 0 0 0' gap='16px'>
						{shift === null && (
							<>
								<PHWhiteCon
									style={{
										maxWidth: isMobile ? '100%' : '800px',

										flex: 3,
										backgroundColor: 'white',
									}}
								>
									<Title
										subtitle
										color={Colors.greenLightTeal}
										margin='0 0 20px 0'
										fontWeight='700'
									>
										{client ? 'Selected Shift' : 'Select a Shift to Start'}
									</Title>
									<ColumnFlex
										style={{
											borderRadius: '16px',

											padding: '20px 10px 10px 10px',
										}}
										fullWidth
										margin='10px 0 12px 0'
										alignCenter
									>
										{renderCreatedShifts()}
									</ColumnFlex>
								</PHWhiteCon>
								{/* <ColumnFlex
								style={{
									maxWidth: isMobile ? '100%' : '440px',
									flex: 1,
								}}
								gap='16px'
							>
							
							</ColumnFlex> */}
							</>
						)}

						{shift && (
							<ShiftTimeControl
								shift={shift}
								handleUpdateLocalShift={handleUpdateLocalShift}
								handleUpdateShift={handleUpdateShift}
								handleCancelShift={handleCancelShift}
								handleEndShift={handleEndShift}
								handleClockIn={handleClockIn}
								handleSubmitShift={handleSubmitShift}
								handleSuccesRequest={handleSuccesRequest}
								loadingClock={loadingClock}
								deselectShift={deselectShift}
							/>
						)}
					</ColumnFlex>
					<ColumnFlex margin='16px 0 0 0' gap='16px'>
						{shift && <CareActivities shift={shift} />}
					</ColumnFlex>
				</RowFlex>
			</PHDColumn>
		</ParentContainer>
	);
};

const mapStateToProps = ({ provider }, props) => {
	return {
		provider: provider.provider,
		careplanPatient: provider.careplanPatient,
		jobs: provider.jobs,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftParent);
