import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledMain = styled.main`
	width: 100%;
	min-height: 100vh;

	margin: 0 auto;

	background: ${colors.pageBackground};

	padding: 0;

	display: flex;
	justify-content: center;

	@media (${mediaQueries.tablet}) {
		padding: 25px 25px 50px 25px;
	}
`;

export const StyledWrapper = styled.div`
	width: 100%;
	max-width: 1900px;
`;
