import React from 'react';
import { connect } from 'react-redux';
import Colors from 'colors';

import AutoSizer from 'react-virtualized-auto-sizer';

import { Calendar, Views } from 'react-big-calendar';

import { DAYS_OF_WEEK } from 'constants';

import { addBlockedTime, deleteBlockedTime } from 'reducers/provider';
import { showInfoModel } from 'reducers/environment';

import AnimationWrapper from 'AppComp/AnimationWrapper';
import ModelOverlay from 'AppComp/ModelOverlay';
import OverlayWrapper from 'AppComp/OverlayWrapper';
import Text from 'AppComp/Text';
import { RowFlex } from 'basicStyles';

import {
	CalendarHeader,
	CustomTimeSlotWrapper,
	CalendarEventTile,
	MalCon,
	CalendarBlockedEventTile,
} from 'AppComp/CalendarViews';
import { CAL_LOCALIZER } from '../../App';

import { ActiveButton, Container } from './BlockedViews';

import { ParentContainer, PHDColumn } from '../ProviderHomeDashboard';

import BlockedTime from '../BlockedTime';
import BlockedController from './BlockedController';
import Title from 'AppComp/Title';
import { isMobile } from 'react-device-detect';
// import { Container } from '../../admin/AdminChatView';

class BlockedHandler extends BlockedController {
	render() {
		const { showBlockedTimeModel } = this.state;

		const { addBlockedTime, provider, conversation } = this.props;

		return (
			<ParentContainer
				style={{
					height: '100vh',
					padding: '20px 32px 32px 32px',
				}}
			>
				{/* <InfoButton onClick={() => this.handleInfo()} /> */}
				<AnimationWrapper status={showBlockedTimeModel} classNames='basic-overlay' appear>
					<OverlayWrapper handleExitClick={this.updateBlockedOverlay}>
						<ModelOverlay>
							<BlockedTime
								handleExitClick={this.updateBlockedOverlay}
								addBlockedTime={addBlockedTime}
								provider={provider}
							/>
						</ModelOverlay>
					</OverlayWrapper>
				</AnimationWrapper>
				<RowFlex alignCenter spaceBetween>
					<Container
						style={{
							width: '60vw',
						}}
					>
						<Title color={Colors.yellow} margin='0 10vw 20px 0' fontWeight='700'>
							Your Weekly Blocked Times
						</Title>
					</Container>
					<Container
						style={{
							width: '10vw',
						}}
					>
						{!isMobile && (
							<ActiveButton onClick={() => this.updateBlockedOverlay()}>
								<Text bold color='white'>
									Add Blocked Time
								</Text>
							</ActiveButton>
						)}
					</Container>
				</RowFlex>

				<AutoSizer>
					{({ height, width }) => (
						<MalCon
							style={{
								height: height,
								width: width,
							}}
						>
							<Calendar
								localizer={CAL_LOCALIZER}
								events={this.renderEvents()}
								onSelectEvent={this.handleEventClick}
								defaultView='week'
								scrollToTime={new Date()}
								formats={{
									dayFormat: (date, localizer) => {
										return DAYS_OF_WEEK[date.getDay()];
									},
								}}
								components={{
									timeSlotWrapper: CustomTimeSlotWrapper,
									toolbar: () => <div />,
									event: CalendarBlockedEventTile,
									week: {
										event: CalendarBlockedEventTile,
									},
								}}
							/>
						</MalCon>
					)}
				</AutoSizer>
			</ParentContainer>
		);
	}
}

const mapStateToProps = ({ patient, provider }, props) => {
	return {
		provider: provider.provider,
		blockedTimes: provider.blockedTimes,
		conversation: provider.conversation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addBlockedTime: (params) => dispatch(addBlockedTime(params)),
		deleteBlockedTime: (params) => dispatch(deleteBlockedTime(params)),
		showInfoModel: (info) => dispatch(showInfoModel(info)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockedHandler);
