import React, { useState } from 'react';
import { Formik, Form, Field } from 'formik';

import { PrimaryButton, TransparentButton } from '../../../../../corev2/Buttons';
import ShiftNote from './ShiftNote';

import {
	StyledShiftWrapper,
	StyledShiftContainer,
	StyledTitle,
	StyledShiftDetails,
	StyledLabel,
	StyledFieldGroup,
	StyledFieldLabel,
	StyledInputField,
	StyledButtonGroup,
	StyledTextArea,
	StyledTextWithBg,
	StyledValue,
	StyledShiftFormContainer,
} from './styles';

import { useShiftContext } from '../../context/ShiftContext';

const ShiftNotes = ({ handleUpdateShiftData }) => {
	const { shift } = useShiftContext();

	const handleUpdateNotes = async (values) => {
		try {
			handleUpdateShiftData({ notes: values.notes });
		} catch (err) {
			throw new Error(err);
		}
	};
	return (
		<Formik
			onSubmit={handleUpdateNotes}
			initialValues={{
				notes: shift.notes,
			}}
			autoComplete={'off'}
			render={(formProps) => {
				console.log(formProps);
				console.log(formProps.values.notes !== shift.notes);
				return (
					<StyledShiftFormContainer onSubmit={formProps.handleSubmit}>
						<StyledTitle>Notes</StyledTitle>
						<ShiftNote>
							<StyledTextArea
								onChange={formProps.handleChange}
								value={formProps.values.notes}
								name='notes'
								label=''
							/>
						</ShiftNote>

						{formProps.values.notes !== shift.notes && (
							<StyledButtonGroup>
								<PrimaryButton type='submit' size='small'>
									Update Shift
								</PrimaryButton>
							</StyledButtonGroup>
						)}
					</StyledShiftFormContainer>
				);
			}}
		/>
	);
};

export default ShiftNotes;
