import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import colors from 'colors';

import {
	toggleNewMessageStatus,
	toggleNewMessageProvider,
	fetchUserDetails,
	fetchAdminConversations,
	fetchAdminConversation,
	adminSendMessageToConversation,
} from 'reducers/admin';

import PageLoaderView from '../../PageLoaderView';

import { ParentContainer } from '@appComp/provider/ProviderHomeDashboard';

import CareProConversations from 'AppComp/provider/CareProChat/CareProConversations';

import ChatView from 'AppComp/provider/CareProChat/ChatView';

const AdminChatHandler = (props) => {
	console.log('AdminCHatHandler', props);

	const { conversations, fetchAdminConversations, adminSendMessageToConversation } = props;

	const [selectedConversation, setSelectedConversation] = useState(null);

	useEffect(() => {
		if (selectedConversation) {
			// find the conversation in the list of conversations
			const conversation = conversations.find((d) => d.id === selectedConversation.id);
			if (conversation) {
				setSelectedConversation(conversation);
			}
		}
	}, [conversations]);

	useEffect(() => {
		fetchAdminConversations();
	}, []);

	const handleSendMessage = async (message) => {
		adminSendMessageToConversation({
			conversation_id: selectedConversation.id,
			text: message,
		});
	};

	const _conversations = conversations.filter(
		(d) => d.user_type === 'care_provider' || d.user_type === 'patient'
	);

	return (
		<>
			{conversations > 0 ? (
				<PageLoaderView errorMessage='Something went wrong please reload the page and try again' />
			) : (
				<ParentContainer
					style={{
						flex: 1,
						display: 'flex',
						flexDirection: 'row',
						backgroundColor: colors.theme.primary_background,
						padding: '0 12px',
					}}
				>
					<CareProConversations
						setSelectedConversation={setSelectedConversation}
						selectedConversation={selectedConversation}
						conversations={_conversations}
						loggedInUser={'admin'}
					/>

					<ChatView
						selectedConversation={selectedConversation}
						handleSendMessage={handleSendMessage}
						loggedInUser={'admin'}
					/>
				</ParentContainer>
			)}
		</>
	);
};

const mapStateToProps = ({ admin }, props) => {
	return {
		conversationType: admin.conversationType,
		conversations: admin.conversations,

		selectedCarePlanId: admin.selectedCarePlanId,
		selectedConversation: admin.selectedConversation,
		selectedConversationType: admin.selectedConversationType,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		toggleNewMessageStatus: (carePlanId) => dispatch(toggleNewMessageStatus(carePlanId)),
		toggleNewMessageProvider: (carePlanId, adminProviderConvoId) =>
			dispatch(toggleNewMessageProvider(carePlanId, adminProviderConvoId)),
		fetchUserDetails: (params) => dispatch(fetchUserDetails(params)),
		fetchAdminConversations: () => dispatch(fetchAdminConversations()),
		adminSendMessageToConversation: (params) =>
			dispatch(adminSendMessageToConversation(params)),
		fetchAdminConversation: (conversation_id) =>
			dispatch(fetchAdminConversation(conversation_id)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminChatHandler);
