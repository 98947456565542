import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledCarePlanItem = styled.div`
	display: flex;
	box-sizing: border-box !important ;

	flex-direction: column;
	align-items: center;
	row-gap: 8px;
	border: 1px solid ${colors.primary.primary};
	border-radius: 8px;
	padding: 15px;
	background-color: ${colors.white};
	text-align: center;
	cursor: pointer;

	@media ${mediaQueries.mobileL} {
		padding: 20px;
	}

	@media ${mediaQueries.tablet} {
		padding: 25px;
	}

	@media ${mediaQueries.laptop} {
		padding: 30px;
	}
`;

export const StyledTitle = styled.h3`
	box-sizing: border-box !important;

	font-size: 15px;
	font-weight: bolder;
	color: ${colors.primary.primary};
`;

export const StyledDescription = styled.p`
	box-sizing: border-box !important;

	font-size: 14px;
	color: ${colors.primary.primary};
`;
