import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { CLIENT_MODEl } from '../../../core/api/models';

const fetchClient = async ({ clientId }) => {
	try {
		const data = await api.graph({
			query: `{
					user(id: ${clientId}) {
						${CLIENT_MODEl}
					}
				}`,
		});

		return data.user;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchClientQuery = (clientId) => {
	return useQuery({
		queryKey: ['fetchClient', clientId],
		queryFn: () => {
			return fetchClient({ clientId });
		},
	});
};
