import React, { useEffect, useState } from 'react';

import { useElements, useStripe, CardNumberElement } from '@stripe/react-stripe-js';
import { Hearts } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { isMobile } from 'react-device-detect';

import AnimateHeight from 'react-animate-height';
import { ColumnFlex, RowFlex } from 'basicStyles';
import { ACCEPTED_CARDS } from 'constants';

import Colors from 'colors';

import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';
import Button from 'AppComp/Button';

import { toastErrorConfig } from 'reducers/provider';

import { CareplanDetailFormView } from '../../patient/CarePlanDetail/CarePlanDetailView';
import { SavedTokenInput, CardIconCon } from '../../patient/PatientSetting/PatientSettingViews';

import {
	CardSection,
	CustomFormButton,
} from 'AppComp/patient/PatientCardCollection/PatientCardCollectionViews';

const CardHandler = (props) => {
	const [collectCardInfo, setCollectCardInfo] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [addPaymentMethodFormIsValid, setAddPaymentMethodFormIsValid] = useState(false);
	const [err, updateErr] = useState('');

	const {
		user,
		paymentMethods,
		addPaymentMethod,
		updateDefaultPaymentMethod,
		deletePaymentMethod,
	} = props;

	const elements = useElements();
	const stripe = useStripe();

	useEffect(() => {
		setCollectCardInfo(false);
	}, [paymentMethods]);

	const addPaymentMethodFormValidityHandler = (validity) => {
		setAddPaymentMethodFormIsValid(validity);
	};

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();
		setIsLoading(true);
		if (!stripe || (!elements && userStripe)) {
			// Stripe.js has not yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}
		try {
			const result = await stripe.createToken(elements.getElement(CardNumberElement), {
				currency: 'usd',
			});

			if (result.error) {
				setIsLoading(false);
				updateErr(result.error.message);
			} else {
				setIsLoading(false);
				addPaymentMethod({
					token: result.token.id,
				});
			}
		} catch (err) {
			console.log('err', err);
			setIsLoading(false);
			updateErr('Something went wrong please try again');
			toast.error('Something went wrong please try again', toastErrorConfig);
		}
	};

	const updateCollectCardInfoStatus = (value) => {
		setCollectCardInfo(value);
	};

	const renderAcceptedCards = () => {
		return ACCEPTED_CARDS.map((d, i) => {
			return (
				<CardIconCon key={'card-key-' + d.id}>
					<Icon size='3x' color={Colors.primary.primary} icon={d.icon} />
				</CardIconCon>
			);
		});
	};

	const renderSavedTokensInputs = () => {
		return paymentMethods.map((d, i) => {
			return (
				<SavedTokenInput
					key={'sti-' + d.id}
					primary={d.default_card === 1}
					{...d}
					user={user}
					deletePaymentMethod={deletePaymentMethod}
					updateUserDefaultPaymentMethod={updateDefaultPaymentMethod}
				/>
			);
		});
	};

	return (
		<CareplanDetailFormView height='auto'>
			<ColumnFlex alignCenter padding='32px'>
				<RowFlex alignCenter>
					<Text color={Colors.teal} largeText margin='0 0 12px 0 '>
						Payment Method
					</Text>
				</RowFlex>
				<Text darkGray margin='0 0 12px 0 ' mediumText>
					In Order to Provide our best service we must have a card on file...
				</Text>
				{paymentMethods.length > 0 && (
					<>
						<Text color='green' mediumText margin='0px 0 6px 0'>
							Default Card
						</Text>
						<Text darkGray subtitle margin='0px 0 24px 0'>
							Select a card to make it your default card
						</Text>
						{renderSavedTokensInputs()}
						{!collectCardInfo && (
							<Button
								onClick={() => updateCollectCardInfoStatus(true)}
								backgroundColor={Colors.primary.primary}
								margin='24px 0 0 0'
							>
								Add New Card
							</Button>
						)}
					</>
				)}
				<AnimateHeight duration={250} height={collectCardInfo ? 'auto' : '0%'}>
					<ColumnFlex center>
						<ColumnFlex center>
							<Text darkGray mediumText margin='32px 0 24px 0'>
								Please Input Your Debit Card Information Here
							</Text>
							<Text darkGray mediumText margin='8px 0 24px 0'>
								Test: 4000-0566-5566-5556
							</Text>
							{err && (
								<Text error mediumText margin='8px 0 24px 0'>
									{err}
								</Text>
							)}
							<RowFlex columnMobile gap='8px'>
								{renderAcceptedCards()}
							</RowFlex>
						</ColumnFlex>
						{collectCardInfo && (
							<ColumnFlex
								padding='0 0 12px 0'
								margin='20px auto 0 auto'
								center
								gap='12px'
							>
								<form onSubmit={handleSubmit}>
									<CardSection
										addPaymentMethodFormValidityHandler={
											addPaymentMethodFormValidityHandler
										}
									/>
									<RowFlex center>
										{isLoading && (
											<Hearts
												color={Colors.primary.primary}
												height={50}
												width={50}
											/>
										)}
										{!isLoading && (
											<CustomFormButton
												style={{
													borderRadius: '24px',
												}}
												type='submit'
												margin='32px 8px 0 0px'
												backgroundColor={Colors.primary.primary}
												width={'240px'}
												height={isMobile ? '40px' : '60px'}
												valid={addPaymentMethodFormIsValid}
											>
												<Text
													style={{
														textAlign: 'center',
													}}
													bold
													color={
														addPaymentMethodFormIsValid
															? 'white'
															: 'black'
													}
													midLarge
												>
													Add Payment Method
												</Text>
											</CustomFormButton>
										)}
									</RowFlex>
									<Text
										onClick={() => updateCollectCardInfoStatus(false)}
										pointer
										underline
										darkGray
										subtitle
										margin='16px 0 24px 0'
									>
										Cancel
									</Text>
								</form>
							</ColumnFlex>
						)}
					</ColumnFlex>
				</AnimateHeight>
			</ColumnFlex>
		</CareplanDetailFormView>
	);
};

export default CardHandler;
