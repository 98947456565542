import React from 'react';
import { Formik, Form, Field } from 'formik';
import { faPencilAlt, faFileUpload } from '@fortawesome/free-solid-svg-icons';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';

import { ColumnFlex, RowFlex, DropShadow } from 'basicStyles';
import Colors from 'colors';

import Title from 'AppComp/Title';
import Icon from 'AppComp/Icon';
import Text from 'AppComp/Text';

import { device } from 'AppComp/styled/StyledMediaQuery';

import StyledTextInput, { TextArea, StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';

import ProfileInfoController from './ProfileInfoController';
import {
	CusotmFieldSet,
	CustomFormButton,
	TextInputCon,
	Container,
	CustomAvatar,
} from './ProfileInfoViews';

import { PHDColumn, PHWhiteCon } from '../ProviderHomeDashboard';

import { BasicChild } from '../ProviderHomeLanding/ProviderHomeLandingHandler';

const GENDERS = [
	{
		value: 'female',
		label: 'Female',
	},
	{
		value: 'male',
		label: 'Male',
	},
];

const CARE_TYPE = [
	{
		value: 'hourly',
		label: 'Hourly',
	},
	{
		value: 'live_in',
		label: 'Live In',
	},
];

const SettingPageConChild = styled.div`
	border-radius: 16px;
	flex: 1;
	background-color: white;
	height: min-content;
	@media ${device.mobileS} {
	}
`;
export default class ProfileInfoHandler extends ProfileInfoController {
	render() {
		const { editProfileInfo, newImageUrl, newImage } = this.state;

		const {
			provider: { image_url },
		} = this.props;

		return (
			<SettingPageConChild>
				<Formik
					onSubmit={this.handleSubmit}
					enableReinitialize
					initialValues={this.initialValues()}
				>
					{(formProps) => (
						<Form style={{ padding: '0px' }} noValidate>
							<CusotmFieldSet
								disabled={!editProfileInfo}
								disabledStatus={!editProfileInfo}
							>
								<PHWhiteCon style={{ maxWidth: '950px' }}>
									<ColumnFlex>
										<RowFlex
											padding='12px 16px 32px 16px'
											gap='10px'
											columnMobile
											spaceBetween
										>
											<Text darkGray bold largeText>
												Edit your profile information here:
											</Text>
											{!editProfileInfo && (
												<RowFlex margin='0 0 0 0' alignCenter>
													<CustomAvatar
														height='30px'
														width='30px'
														onClick={() =>
															this.setState({
																editProfileInfo: !editProfileInfo,
															})
														}
														color={
															editProfileInfo
																? Colors.greenLightTeal
																: Colors.gray
														}
													>
														<Icon icon={faPencilAlt} size='sm' />
													</CustomAvatar>
													<Text
														color={
															editProfileInfo
																? Colors.greenLightTeal
																: Colors.gray
														}
														onClick={() =>
															this.setState({
																editProfileInfo: !editProfileInfo,
															})
														}
														margin='0 0px 0 8px'
														pointer
														underline
													>
														Edit Profile Info
													</Text>
												</RowFlex>
											)}
										</RowFlex>
										<ColumnFlex
											style={{
												borderRadius: '16px',
												backgroundColor: 'white',
												padding: '16px',
											}}
											columnMobile
											gap='20px'
										>
											<Dropzone
												onDrop={(acceptedFiles) =>
													this.handleDroppedFile(acceptedFiles)
												}
											>
												{({ getRootProps, getInputProps }) => (
													<CustomAvatar
														height='120px'
														width='120px'
														margin='20px 32px 0 0'
														border={Colors.greenLightTeal}
														image_url={
															editProfileInfo
																? newImage
																	? newImageUrl
																	: ''
																: image_url
														}
														{...getRootProps()}
													>
														<input {...getInputProps()} />
														{editProfileInfo && !newImage && (
															<Icon icon={faFileUpload} size='2x' />
														)}
													</CustomAvatar>
												)}
											</Dropzone>

											<ColumnFlex>
												<TextInputCon>
													<Field
														component={StyledTextInput}
														name='first_name'
														label='First Name'
														type='text'
													/>
													<Field
														component={StyledTextInput}
														name='last_name'
														label='Last Name'
														type='text'
													/>{' '}
													<Field
														component={StyledTextInput}
														name='display_name'
														label='Display Name'
														type='text'
													/>
													<Field
														component={StyledTextInput}
														name='email'
														label='Email'
														type='text'
													/>
													<Field
														component={StyledTextInput}
														name='phone_number'
														label='Phone Number'
														autocomplete='off'
														type='text'
													/>
													<Field
														component={StyledTextSelectInputV2}
														name='gender'
														label='Gender*'
														options={GENDERS}
													/>
												</TextInputCon>
											</ColumnFlex>

											<ColumnFlex margin=''>
												<TextInputCon>
													<Field
														component={TextArea}
														name='professional_statement'
														label='Your Statement'
														type='text'
														rows='10'
													/>
												</TextInputCon>
											</ColumnFlex>
											{editProfileInfo && (
												<CustomFormButton
													backgroundColor={Colors.teal}
													type='submit'
													valid={formProps.isValid}
												>
													Update Profile
												</CustomFormButton>
											)}
										</ColumnFlex>
									</ColumnFlex>
								</PHWhiteCon>
							</CusotmFieldSet>
						</Form>
					)}
				</Formik>
			</SettingPageConChild>
		);
	}
}
