import { z } from 'zod';

const accountInfoFormValidationSchema = z
	.object({
		email: z.string().email('Invalid email address'),
		phone: z.string().refine(
			(val) => {
				const regex = /^(1\s?)?(\d{3}[-\s.]?)?\d{3}[-\s.]?\d{4}$/;
				return regex.test(val);
			},
			{
				message:
					'Invalid phone number format. Must be xxx-xxx-xxxx, xxxxxxxxxx, or 1xxxxxxxxxx.',
			}
		),
		password: z.string().min(6, 'Password must be at least 6 characters long.'),
		confirmPassword: z.string(),
	})
	.refine((data) => data.password === data.confirmPassword, {
		message: 'Passwords do not match.',
		path: ['confirmPassword'],
	});

export default accountInfoFormValidationSchema;
