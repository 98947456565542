import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import environment from './environment';
import { handleAdminActions } from './admin';
import patient from './patient';
import provider from './provider';
import careplan from './careplan';
import model from './model';

export default (history) => {
	var appReducer = combineReducers({
		admin: handleAdminActions,
		careplan,
		environment,
		patient,
		model,
		router: connectRouter(history),
		provider,
	});

	return (state, action) => {
		if (action.type === 'ADMIN_LOGOUT') {
			state.admin = undefined;
		}

		if (action.type === 'PROVIDER_LOGOUT') {
			state.provider = undefined;
		}

		if (action.type === 'PATIENT_LOGOUT') {
			state.patient = undefined;
		}

		return appReducer(state, action);
	};
};
