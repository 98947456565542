import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { faLocationArrow, faPlus } from '@fortawesome/free-solid-svg-icons';
import { browserName, isAndroid, isIOS } from 'react-device-detect';
import { Hearts } from 'react-loader-spinner';
import moment from 'moment';

import { ColumnFlex, RowFlex } from 'basicStyles';
import { faInfoCircle, faStar, faHeart } from '@fortawesome/free-solid-svg-icons';

import Colors from 'colors';

import Icon from '../Icon';
import Text from '../Text';
import Button from '../Button';

import { TimeCon, TimeInput } from '../admin/ManageDisputes/AdminDisputeSummaryView';

export const Divider = styled.div`
	width: 100%;
	height: 2px;
	background-color: #e5e5e5;
	margin: 12px 0px;
`;

export const Container = styled.div`
	width: 100vw;
	padding: 14px;
	border-radius: 8px;
	max-width: 500px;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	height: 100%;
`;

const EmptyImageCon = styled.div`
	width: 150px;
	height: 150px;
	border: solid black 1px;
	border-radius: 8px;
	background-color: ${Colors.lightGrey};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

const IconCon = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: ${Colors.fullWhite};
	border-radius: 50%;

	height: 50px;
	width: 50px;
`;

export const PatientImagePreview = styled.div`
	background-image: url('${({ imageUrl }) => imageUrl}');
	background-position: center;
	background-size: contain;
	height: 200px;
	background-repeat: no-repeat;
	width: 100%;
	margin: 16px 0 24px 0;
`;

const LoaderDiv = styled(ColumnFlex)`
	height: 100px;
	margin-bottom: 12px;
`;

const HelpPhoto = styled.div`
	height: 200px;
	margin: 8px 0 8px 0;
	background-image: url('${({ imageUrl }) => imageUrl}');
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
`;
export const HelpInfo = (props) => {
	const { close } = props;

	return (
		<ColumnFlex>
			<Text color={Colors.darkerGray} margin='8px 0 8px 0'>
				Once you've verifed the following is enabled, please restart the browser
			</Text>
			<Text color={Colors.darkerGray} margin='8px 0 8px 0'>
				Please ensure you're using one of the following 3 browers: Safari, Google Chrome,
				Firefox.
			</Text>
			<Text color={Colors.darkerGray} margin='8px 0 8px 0'>
				This device is using: {browserName}
			</Text>
			{isIOS && (
				<>
					<Text color={Colors.darkerGray}>Iphone Instructions</Text>
					<Text margin='8px' nurseInfo>
						Go to your device’s Settings and scroll down to Privacy.
					</Text>
					<HelpPhoto imageUrl='https://media.screensteps.com/image_assets/assets/001/054/665/original/227ba2ab-6b1c-4a08-b7d3-9a3e2ea233d5.png' />
					<Text margin='8px' nurseInfo>
						Tap Location Services.
					</Text>
					<HelpPhoto imageUrl='https://media.screensteps.com/image_assets/assets/000/551/463/original/0360298b-a4ca-44b6-b7cd-c2fabad88b11.png' />
					<Text margin='8px' nurseInfo>
						Toggle the switch to On. Any apps that may use location services will
						populate underneath.
					</Text>
					<HelpPhoto imageUrl='https://media.screensteps.com/image_assets/assets/001/054/664/original/8cc59219-1778-40d8-8e29-fd375a8f3ca7.png' />
					<Text margin='8px' nurseInfo>
						Scroll down to your browser ({browserName}) and ensure that location
						services are turned on.
					</Text>
					<HelpPhoto imageUrl='https://media.screensteps.com/image_assets/assets/000/551/459/original/518bd1d5-3335-4df6-aad6-7adb598b0ac3.png' />
					<HelpPhoto imageUrl='https://media.screensteps.com/image_assets/assets/000/551/460/original/58f54a56-eeef-4a29-89d9-7d1a7b79e9fe.png' />
				</>
			)}
			{isAndroid && (
				<>
					<Text color={Colors.darkerGray}>Android Instructions</Text>
					<Text margin='8px' nurseInfo>
						Go to your device’s Settings menu, then select Location. Be sure that it is
						toggled on.
					</Text>
					<HelpPhoto imageUrl='https://media.screensteps.com/image_assets/assets/000/551/464/original/187304f3-0cc4-45b5-a4e3-63687752c169.png' />
					<Text margin='8px' nurseInfo>
						Also, check that you have location settings turned on for your browser
						specifically. Open the browser’s menu (upper right corner).
					</Text>
					<HelpPhoto imageUrl='https://media.screensteps.com/image_assets/assets/000/551/461/original/50123b7d-6c28-487c-81b8-d1ff61e0420a.png' />
					<Text margin='8px' nurseInfo>
						Scroll down to navigate to the Location menu.
					</Text>
					<HelpPhoto imageUrl='https://media.screensteps.com/image_assets/assets/000/551/458/original/bcf30138-f246-4699-a686-8855971e9e42.png' />
					<Text margin='8px' nurseInfo>
						Toggle the Location switch on.
					</Text>
					<HelpPhoto imageUrl='https://media.screensteps.com/image_assets/assets/000/551/457/original/f76f1b72-3b27-4f4d-b0f7-d64664867ed9.png' />
					<Text margin='8px' nurseInfo>
						You can then view any websites you’ve allowed and blocked by expanding the
						corresponding menus and adjust location access settings for each site.
					</Text>
					<HelpPhoto imageUrl='https://media.screensteps.com/image_assets/assets/001/054/662/original/4cb28e52-0496-40d7-a9cb-16cb6c9d938d.png' />
					<Text margin='8px' nurseInfo>
						Refresh FYI website to view after allowing your location. The page will then
						load if your location has not been geo-blocked.
					</Text>
				</>
			)}

			<TextCenter onClick={() => close()} cursor underline>
				Close info
			</TextCenter>
		</ColumnFlex>
	);
};

// Prompt user to request geo location
export const RequestUserLocation = (props) => {
	const { handleSuccesRequest, location, handleRejectRequest } = props;

	const requestLocation = () => {
		const permission = window.confirm('Allow FYI to request access to location?');

		if (permission) {
			//call back success
			handleSuccesRequest();
		} else {
			handleRejectRequest();
			// call back failure
		}
	};

	return (
		<ColumnFlex fullWidth center>
			<LoaderDiv center>
				{location.loading ? (
					<Hearts color={Colors.primary} height={100} width={100} />
				) : (
					<>
						<Icon size='2x' color={Colors.primary} icon={faLocationArrow} />
						<Text underline onClick={() => requsetLocataion()} margin='10px 0 16px 0'>
							Allow Access to Location?
						</Text>
						{location.error !== null && <Text error>{location.error}</Text>}
					</>
				)}
			</LoaderDiv>
		</ColumnFlex>
	);
};

import { faMars, faVenus, faHouseMedical } from '@fortawesome/free-solid-svg-icons';

const SelectedPatientView = (props) => {
	//console.log('SelectedPatientView -> props', props);
	const {
		onClick,
		carePlan: {
			first_name,
			last_name,
			display_name,
			care_type,
			dob,
			address_street,
			address_street_2,
			address_city,
			address_state,
			address_zip,
			gender,
			user,
		},
		shift,
	} = props;

	const { name, phone_number } = user;

	const handleShowGoogleMapUrlFromAddress = () => {
		const address = `${address_street}, ${address_street_2},${address_city}, ${address_state},${address_zip}`;
		const googleMapUrl = `https://www.google.com/maps/search/?api=1&query=${address}`;
		return googleMapUrl;
	};

	const renderAddress = () => {
		const address = `${address_street} ${address_street_2} ${address_city} ${address_state} ${address_zip}`;
		return address;
	};

	const convertDOBToReadableAge = (dob) => {
		// dob is in the format of 'YYYY-MM-DD'
		const age = moment().diff(dob, 'years');
		return `${age} years`;
	};

	console.log('shift - ', shift);
	return (
		<ColumnFlex padding='12px' alignStart fullWidth>
			<RowFlex margin='4px 0 12px 0'>
				<Text fontStyle='italic' margin='0 8px 0 0' mediumText darkGray>
					Name:
				</Text>
				<Text mediumText>{`${first_name} ${last_name}`}</Text>
			</RowFlex>
			<RowFlex margin='4px 0 12px 0'>
				<Text fontStyle='italic' margin='0 8px 0 0' mediumText darkGray>
					DOB:
				</Text>
				<Text mediumText>{dob}</Text>
			</RowFlex>
			<RowFlex margin='4px 0 12px 0'>
				<Text fontStyle='italic' margin='0 8px 0 0' mediumText darkGray>
					Display Name:
				</Text>
				<Text mediumText>{display_name}</Text>
			</RowFlex>
			{/* <RowFlex margin='0 0 12px 0x'>
				<Text fontStyle='italic' margin='0 8px 0 0' mediumText darkGray>
					Email:
				</Text>
				<Text mediumText>{email}</Text>
			</RowFlex> */}
			<RowFlex margin='4px 0 12px 0'>
				<Text fontStyle='italic' margin='0 8px 0 0' mediumText darkGray>
					Care Type:
				</Text>
				<Text mediumText>{care_type}</Text>
			</RowFlex>
			<RowFlex margin='4px 0 12px 0'>
				<Text fontStyle='italic' margin='0 8px 0 0' mediumText darkGray>
					Location:
				</Text>
				<a href={handleShowGoogleMapUrlFromAddress()} target='_blank'>
					<Text underline mediumText>
						{renderAddress()}
					</Text>
				</a>
			</RowFlex>
			<RowFlex margin='4px 0 12px 0'>
				<Text fontStyle='italic' margin='0 8px 0 0' mediumText darkGray>
					Gender:
				</Text>
				<Text margin='0 0 8px 0' color='black' mediumText>
					{gender === 'MALE' ? (
						<Icon icon={faMars} size='1x' />
					) : (
						<Icon icon={faVenus} size='1x' />
					)}
					<span
						style={{
							marginLeft: '8px',
							fontStyle: 'italic',
						}}
					>
						{gender ? gender.substring(0, 1) : ''}
					</span>
				</Text>
			</RowFlex>
			<RowFlex margin='4px 0 12px 0'>
				<Text fontStyle='italic' margin='0 8px 0 0' mediumText darkGray>
					Age:
				</Text>
				<Text mediumText>{convertDOBToReadableAge(dob)}</Text>
			</RowFlex>
			<RowFlex margin='4px 0 12px 0'>
				<Text fontStyle='italic' margin='0 8px 0 0' mediumText darkGray>
					POC: Name
				</Text>
				<Text mediumText>{name}</Text>
			</RowFlex>
			<RowFlex margin='4px 0 12px 0'>
				<Text fontStyle='italic' margin='0 8px 0 0' mediumText darkGray>
					POC: Phone Number
				</Text>
				<Text mediumText>{phone_number}</Text>
			</RowFlex>
			<RowFlex margin='4px 0 12px 0'>
				<Text fontStyle='italic' margin='0 8px 0 0' mediumText darkGray>
					CarePlan Notes:
				</Text>
				<Text mediumText>N/A</Text>
			</RowFlex>

			{shift !== null && shift !== undefined ? (
				<>
					{shift.status === 'created' ? (
						<Text
							fontStyle='italic'
							margin='8px 0 12px 0'
							darkGray
							pointer
							underline
							onClick={() => onClick()}
						>
							Change Client Selection
						</Text>
					) : (
						<Text fontStyle='italic' margin='8px 0 12px 0' darkGray>
							Cant Change Client Once Clocked In
						</Text>
					)}
				</>
			) : (
				<Text
					fontStyle='italic'
					margin='8px 0 12px 0'
					darkGray
					pointer
					underline
					onClick={() => onClick()}
				>
					Change Client Selection
				</Text>
			)}
		</ColumnFlex>
	);
};

export const PatientImageView = ({ carePlan, onClick, shift }) => {
	return <SelectedPatientView carePlan={carePlan} shift={shift} onClick={onClick} />;
};

/**
	CustomTimeSlot was inspired by DisputeTimeSlot in "src/components/admin/AdminDisputeSummaryView"
	is basically a copy pasta of DisputeTimeSlot with the addition of not hard coding the type and format,
	because this way it allows CustomTimeSlot to handle any date format that you pass down to it

	** what is still hardcoded is the style of the input **

	- Added min & max range
*/
export const CustomTimeSlot = (props) => {
	const { time, color, onChange, type, min, max } = props;

	// const format = 'hh:mm';
	// console.log('time ', time)
	// const now = moment(time, 'hhmm').format('HH:mm');
	// console.log('now', now);

	return (
		<TimeCon color={color}>
			<TimeInput onChange={onChange} value={time} type={type} required min={min} max={max} />
			{
				// <TimePicker
				// 	showSecond={false}
				//
				// 	onChange={onChange}
				// 	use12Hours
				// 	inputReadOnly
				// />
			}
		</TimeCon>
	);
};

export const SubmitButton = styled(Button)`
	width: 100%;
	height: 70px;
	border-radius: 0;
	max-width: 500px;
	transition: all ease 0.3s;
	border-radius: 8px;
	color: white;
	${({ valid }) =>
		!valid &&
		`
		background-color: #9ACCC8;
		color: black;

	`}

	${({ valid }) =>
		valid &&
		`
		background-image: linear-gradient(90deg, #219086 0%, #2BAFA4 95%);
		color: white;

	`}
`;

export const DateTimeSlot = (props) => {};

export const TextCenter = styled(Text)`
	text-align: center;
`;

export const CustomRadioInput = styled.input`
	margin-right: 8px;
`;

export const ActivityRadioInupt = (props) => {
	const { value, label, id, onChange } = props;

	return (
		<RowFlex alignCenter margin='8px'>
			<CustomRadioInput name={id} type='checkbox' checked={value} onChange={onChange} />
			<Text mediumText color='black'>
				{label}
			</Text>
		</RowFlex>
	);
};

const StarContainer = styled.div`
	padding: 4px;
`;
export const StarIcon = ({ handleStarSelection, index, selectedIndex }) => {
	return (
		<StarContainer onClick={() => handleStarSelection(index)}>
			<Icon
				size='3x'
				color={selectedIndex >= index ? Colors.heartRed : Colors.gray}
				icon={faHeart}
			/>
		</StarContainer>
	);
};
