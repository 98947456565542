import React from 'react';
import { Formik, Form, Field } from 'formik';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import AnimateHeight from 'react-animate-height';
import { isMobile } from 'react-device-detect';

import { ColumnFlex, RowFlex, Avatar } from 'basicStyles';
import Colors from 'colors';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import FormButton from 'AppComp/FormButton';
import Icon from 'AppComp/Icon';
import StyledTextInput from 'AppComp/styled/StyledTextInput';

import PatientSettingController from './PatientSettingController';

import { validatePatientProfileInfo } from '../../../constants/validation';

import {
	FormInputCon,
	CusotmFieldSet,
	CustomRow,
	Divider,
	CardContainer,
	CardFormCon,
	CustomFormButton,
	CurosrRow,
} from './PatientSettingViews';

export default class PatientSettingHandler extends PatientSettingController {
	render() {
		const { editProfileInfo, creditError, showPaymentForm } = this.state;

		const { logoutUser, toggleChangePasswordOverlay, savedPaymentMethods } = this.props;

		console.log('PatientSettingHandler', this.props);

		return (
			<ColumnFlex padding={isMobile ? '12px' : '32px'}>
				<Title color={Colors.secondary.black} margin='0 0 18px 0' subtitle>
					Welcome Back
				</Title>
				<Text darkGray mediumText>
					This is the Settings section for patients such as yourself. To edit any of the
					data listed below including changing payment methods. Please press the pencil
					button to the top right to update the fields
				</Text>
				<RowFlex alignCenter spaceBetween margin='32px 0 32px 0 '>
					<Text color={Colors.secondary.blue} largeText>
						Profile Information
					</Text>
					<CurosrRow
						alignCenter
						onClick={() => this.setState({ editProfileInfo: !editProfileInfo })}
					>
						<Avatar
							height='30px'
							width='30px'
							color={editProfileInfo ? Colors.secondary.blue : Colors.gray}
						>
							<Icon icon={faPencilAlt} size='sm' />
						</Avatar>
						<Text
							color={editProfileInfo ? Colors.secondary.blue : Colors.gray}
							subtitle
							margin='0 0 0 12px'
						>
							Edit Information
						</Text>
					</CurosrRow>
				</RowFlex>
				<Formik
					onSubmit={this.handleSubmit}
					initialValues={this.renderInitalValues()}
					validate={validatePatientProfileInfo}
					render={(formProps) => {
						return (
							<Form noValidate>
								<CusotmFieldSet
									disabled={!editProfileInfo}
									disabledStatus={!editProfileInfo}
								>
									<RowFlex center>
										<FormInputCon width={isMobile ? '280px' : '360px'}>
											<Field
												component={StyledTextInput}
												name='email'
												label='Email'
												type='text'
											/>
										</FormInputCon>
									</RowFlex>
									<RowFlex center>
										<FormInputCon width={isMobile ? '280px' : '360px'}>
											<Field
												component={StyledTextInput}
												name='phone_number'
												autocomplete='off'
												label='Phone Number'
												type='number'
											/>
										</FormInputCon>
									</RowFlex>
									<RowFlex center>{editProfileInfo}</RowFlex>
									<AnimateHeight
										duration={250}
										height={editProfileInfo ? 'auto' : '0%'}
									>
										<CustomFormButton
											backgroundColor={Colors.secondary.blue}
											type='submit'
											valid={formProps.isValid}
										>
											Update Profile
										</CustomFormButton>
									</AnimateHeight>
								</CusotmFieldSet>
							</Form>
						);
					}}
				/>
			</ColumnFlex>
		);
	}
}

//PatientSettingHandler = injectStripe(PatientSettingHandler);
