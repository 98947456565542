import styled from 'styled-components';

import { mediaQueries } from '../../../../../../corev2/utils';

export const StyledPayoutsPage = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
	padding: 15px;
	margin: auto;

	text-align: center;

	@media (${mediaQueries.tablet}) {
		width: fit-content;
	}
`;
