import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { SCHEDULE_EXCEPTION } from '../../../core/api/models';

const addScheduleException = async ({
	startDate,
	startTime,
	endDate,
	endTime,
	scheduleId,
	jobPostId,
	carePlanId,
	jobId,
	type,
}) => {
	try {
		const data = await api.graph({
			query: `mutation {
                addScheduleException(
                    start_date: "${startDate}",
                    start_time: "${startTime}",
                    end_date: "${endDate}",
                    end_time: "${endTime}",
                    schedule_id: ${scheduleId},
                    job_post_id: ${jobPostId},
                    care_plan_id: ${carePlanId},
                    ${jobId ? `job_id: ${jobId},` : ''}
                    type: "${type}"
                ) {
                    ${SCHEDULE_EXCEPTION}
                }
            }`,
		});

		return data.deactivateSchedule;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useAddScheduleException = () => {
	return useMutation({
		mutationKey: ['addScheduleException'],
		mutationFn: (values) => {
			return addScheduleException(values);
		},
	});
};
