import React from 'react';

import { CompletedStep } from '../../../../..//corev2/Steps';

import {
	StyledJobPost,
	StyledInformationContainer,
	StyledJobPostTitle,
	StyledJobPostDescription,
} from './styles';

const JobPost = (props) => {
	const { title, description, goToJobPostPage } = props;

	return (
		<StyledJobPost onClick={goToJobPostPage}>
			<CompletedStep />

			<StyledInformationContainer>
				<StyledJobPostTitle>{title}</StyledJobPostTitle>

				<StyledJobPostDescription>{description}</StyledJobPostDescription>
			</StyledInformationContainer>
		</StyledJobPost>
	);
};

export default JobPost;
