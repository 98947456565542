import { useQuery } from '@tanstack/react-query';

import api from '../../core/utility/graphQL';

export const MIN_TRAIT_TEMPLATE_MODEL = `
  id,
  template_label,
  registry_id,
  template_type,
  description,
`;

export const TRAIT_MODEL = `
  id,
  label,
  required,
  active,
  trait_type,
  description,
  meta_data,
  section_id
`;

export const TRAIT_TEMPLATE_SECTION_MODEL = `
  id,
  label,
  order_index,
  traits {
      ${TRAIT_MODEL}
  }
`;

const TRAIT_TEMPLATE_MODEL = `
  ${MIN_TRAIT_TEMPLATE_MODEL},
  sections {
    ${TRAIT_TEMPLATE_SECTION_MODEL}
  }
`;

export const FORM_ANSWERS_MODEL = `
  id,
  trait_value,
  trait_id,
  version,
  trait_type
`;
export const FORM_MODEL = `
  id,
  trait_template_id,
  trait_template {
    ${TRAIT_TEMPLATE_MODEL}
  }
  form_answers {
    ${FORM_ANSWERS_MODEL}
  }

`;
const fetchCareProSkills = async () => {
	try {
		const data = await api.graph({
			query: `{
        checkProviderSession {

          skills {
            ${FORM_MODEL}
          }
        }
      }`,
		});

		return data.checkProviderSession.skills;
	} catch (err) {
		console.log(err);
		throw new Error(err);
	}
};

const useFetchCareProSkills = () => {
	return useQuery({
		queryKey: ['fetchCareProSkills'],
		queryFn: () => {
			return fetchCareProSkills();
		},
		retry: false,
	});
};

export default useFetchCareProSkills;
