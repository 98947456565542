import React, { useEffect, useState, createContext } from 'react';

import {
	setInLocalStorage,
	localStorageKeys,
	getFromLocalStorage,
} from '../../../core/utility/localStorage';

import { authRoutes } from '../../routes/constants';

export const StripeOnboardingContext = createContext();

const initialStripeOnboardingState = {
	address: {
		name: 'address',
		label: 'Address',
		isCompleted: false,
		data: {
			addressLine1: '',
			addressLine2: '',
			city: '',
			state: '',
			zip: '',
		},
		route: authRoutes.onboarding.stripe.address,
	},
	payouts: {
		name: 'payouts',
		label: 'Payouts',
		isCompleted: false,
		data: {},
		route: authRoutes.onboarding.stripe.payouts,
	},
	personal: {
		name: 'personal',
		label: 'Date of Birth & SSN',
		isCompleted: false,
		data: {
			month: '',
			day: '',
			year: '',
			ssn: '',
		},
		route: authRoutes.onboarding.stripe.personal,
	},
	tos: {
		name: 'tos',
		label: 'TOS',
		isCompleted: false,
		data: {},
		route: authRoutes.onboarding.stripe.tos,
	},
};

export const StripeOnboardingProvider = ({ children }) => {
	const [stripeOnboardingState, setStripeOnboardingState] = useState(
		initialStripeOnboardingState
	);

	useEffect(() => {
		const localStripeOnboardingState = getFromLocalStorage(
			localStorageKeys.FYI_CARE_PRO_STRIPE_ONBOARDING_STATE
		);

		if (localStripeOnboardingState) {
			setStripeOnboardingState(localStripeOnboardingState);
		}
	}, []);

	const mutateStripeOnboardingState = (key, value) => {
		setStripeOnboardingState((prevState) => {
			const newState = {
				...prevState,
				[key]: {
					...prevState[key],
					...value,
				},
			};

			setInLocalStorage(
				localStorageKeys.FYI_CARE_PRO_STRIPE_ONBOARDING_STATE,
				JSON.stringify(newState)
			);
			return newState;
		});
	};

	const resetStripeOnboardingState = () => {
		setInLocalStorage(localStorageKeys.FYI_CARE_PRO_STRIPE_ONBOARDING_STATE);
		setStripeOnboardingState(initialStripeOnboardingState);
	};

	const value = {
		stripeOnboardingState,
		mutateStripeOnboardingState,
		resetStripeOnboardingState,
	};

	return (
		<StripeOnboardingContext.Provider value={value}>
			{children}
		</StripeOnboardingContext.Provider>
	);
};
