export const navItemsModifier = (navItems, item, modifier, value) => {
	let updatedNavItems;

	switch (modifier) {
		case 'active':
			updatedNavItems = Object.keys(navItems).reduce((acc, key) => {
				acc[key] = {
					...navItems[key],
					active: key === item ? value : false,
				};
				return acc;
			}, {});
			break;

		case 'stat':
			updatedNavItems = Object.keys(navItems).reduce((acc, key) => {
				acc[key] = {
					...navItems[key],
					stat: value[key] !== undefined ? value[key] : navItems[key].stat,
				};
				return acc;
			}, {});
			break;

		default:
			updatedNavItems = { ...navItems };
			break;
	}

	return updatedNavItems;
};
