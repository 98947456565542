import styled from 'styled-components';

import { colors } from '../utils';

const getWidthByHeaderLength = (header) => {
	const minWidth = 110; // Minimum width for small headers
	const charWidth = 10; // Approximate width per character
	const additionalWidth = 10; // Padding or extra space

	return `${Math.max(minWidth, header.length * charWidth + additionalWidth)}px`;
};

export const StyledTableContainer = styled.div`
	overflow-x: auto;

	text-align: center;
`;

export const StyledTable = styled.table`
	border-collapse: collapse;
	display: table;
	margin: 0;
	width: 100%;
	max-width: 100%;
	overflow-x: auto;
	padding: 0;
	table-layout: fixed;
	text-align: left;

	white-space: nowrap;
	height: auto;
`;

export const StyledTableHeader = styled.thead``;

export const StyledTableHeaderCell = styled.th`
	width: ${({ header }) => getWidthByHeaderLength(header)};
	min-width: ${({ header }) => getWidthByHeaderLength(header)};

	box-shadow: 0 1px 0 0 ${colors.table.border};
	border-left: none;
	cursor: pointer;

	background-color: ${colors.table.header};
	border: 1px solid ${colors.table.border};

	box-sizing: border-box;
	color: ${colors.table.text};
	outline: none;
	padding: 14px 10px 14px 24px;
	position: relative;
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;

	line-height: 1.7;
`;

export const StyledTableHeaderCellWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	font-size: 15px;
	font-weight: bold;
`;

export const StyledTableBody = styled.tbody`
	text-align: center;
`;

export const StyledTableRow = styled.tr`
	&:nth-child(odd) {
		background: white;
	}

	&:nth-child(even) {
		background: ${colors.pageBackground};
	}
`;

export const StyledTableBodyCell = styled.td`
	font-size: 15px;
	text-align: center;

	border: 1px solid ${colors.table.border};
	padding: 14px 24px;
`;

export const StyledNoDataText = styled.span`
	margin: auto;

	position: relative;
	top: 40px;
`;
