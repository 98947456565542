import React from 'react';

import { StyledJobStat, StyledStatTitle, StyledJobStatValue } from './styles';

const JobStat = (props) => {
	const { title, stat, isActive, goToPage } = props;

	return (
		<StyledJobStat isActive={isActive} onClick={goToPage}>
			<StyledStatTitle>{title}</StyledStatTitle>

			<StyledJobStatValue>{stat}</StyledJobStatValue>
		</StyledJobStat>
	);
};

export default JobStat;
