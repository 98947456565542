import React, { Component, Fragment } from 'react';
import PT from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import is from 'styled-is';

import { ColumnFlex, RowFlex, Avatar } from 'basicStyles';
import Colors from 'colors';

import { ModelContainer, ModelTitle, ModelButton } from '../../ModelOverlay';

import { faMars, faVenus, faHouseMedical } from '@fortawesome/free-solid-svg-icons';

import SetTable from '../../SetTable';
import Text from '../../Text';
import Icon from 'AppComp/Icon';

//AdminDisputeSummaryView.jsx

//User info for patient and provider
export const DisputeShower = (props) => {
	const { title, children, flex, margin, fullWidth } = props;

	return (
		<ColumnFlex flex={flex} alignStart margin={margin} fullWidth={fullWidth}>
			<Text fontStyle='italic' darkGray largeText margin='0 0 12px 0'>
				{title}
			</Text>
			{children}
			<Divider />
		</ColumnFlex>
	);
};

//Divider for UserPersonInfo
const Divider = styled.div`
	width: 100%;
	height: 2px;
	background-color: #e5e5e5;
	margin-top: 12px;
`;

export const SummaryButton = (props) => {
	return (
		<ModelButton id='AdminDisputesViewButton' valid>
			<div />
			<Text largeText>Update Summary</Text>
			<div />
		</ModelButton>
	);
};

export const SummaryHeader = (props) => {
	return (
		<ModelTitle>
			<div />
			<Text largeText>Summary</Text>
			<div />
		</ModelTitle>
	);
};

/**
	UserPersonInfo is used in two different screens, one shows the avatar the other does not (ProviderTimesheetPatientSelectionView does not)
	in order to reuse this component i added (brgarciairivas) a check if a imageUrl is passed load the avatar, if not don't show
*/

export const UserPersonInfo = (props) => {
	const { name, phone, imageUrl } = props;
	return (
		<RowFlex alignCenter style={{ cursor: 'pointer' }}>
			{imageUrl && <Avatar height='70px' width='70px' image_url='' />}
			<ColumnFlex margin='0 0 0 12px'>
				<Text margin='0 0 8px 0' color='black' mediumText>
					{name}
				</Text>
				<Text mediumText darkGray>
					{phone}
				</Text>
			</ColumnFlex>
		</RowFlex>
	);
};

UserPersonInfo.propTypes = {
	name: PT.string.isRequired,
	phone: PT.string.isRequired,
	imageUrl: PT.string.isRequired,
};
export const ShiftClientSelection = ({ carePlan, user, imageUrl }) => {
	const { first_name, last_name, gender, dob } = carePlan;

	const convertDOBToReadableAge = (dob) => {
		// dob is in the format of 'YYYY-MM-DD'
		const age = moment().diff(dob, 'years');
		return `${age} years`;
	};
	return (
		<RowFlex spaceBetween fullWidth alignCenter style={{ cursor: 'pointer' }}>
			<RowFlex>
				<Avatar height='70px' width='70px' image_url={imageUrl} />
				<ColumnFlex margin='0 0 0 12px'>
					<Text margin='0 0 8px 0' color='black' mediumText>
						{`${first_name} ${last_name}`}
					</Text>
					<Text mediumText darkGray>
						{user.phone_number}
					</Text>
				</ColumnFlex>
			</RowFlex>

			<ColumnFlex margin='0 0 0 12px'>
				<RowFlex>
					<Text margin='0 0 8px 0' color='black' mediumText>
						{gender === 'MALE' ? (
							<Icon icon={faMars} size='1x' />
						) : (
							<Icon icon={faVenus} size='1x' />
						)}
						<span
							style={{
								marginLeft: '8px',
								fontStyle: 'italic',
							}}
						>
							{gender ? gender.substring(0, 1) : ''}
						</span>
					</Text>
				</RowFlex>

				<Text mediumText darkGray>
					{convertDOBToReadableAge(dob)}
				</Text>
			</ColumnFlex>
		</RowFlex>
	);
};

//Row set up for the users info
export const UsersSectionInfo = (props) => {
	const { children } = props;
	return (
		<RowFlex fullWidth center margin='0 0 12px 0'>
			{children}
		</RowFlex>
	);
};

export const PointlessdivtoWasteRam = styled.div`
	width: 20px;
	height: 10px;
`;

export const TimeCon = styled.div`
	background-color: white;
	border-radius: 16px;
	border: solid 1px ${({ color }) => color || 'white'};

	color: ${({ color }) => color || 'white'};
	cursor: pointer;

	.rc-time-picker {
		width: 140px;
	}
	.rc-time-picker-input {
		background-color: #e5e5e5;
		padding: 0;
		border: none;
		font-size: 16px;
		color: ${({ color }) => color || 'white'};
	}
`;

export const TimeDisplay = (props) => {
	const { time, color } = props;

	return (
		<TimeCon color={color}>
			<Text largeText fontWeight='100'>
				{time}
			</Text>
		</TimeCon>
	);
};

export const TimeInput = styled.input`
	width: inherit;
	height: inherit;
	border: none;
	width: 240px;
	font-size: 28px;
	padding: 8px 16px;
	border-radius: 16px;
`;
export const DisputeTimeSlot = (props) => {
	const { time, color, onChange, disabled } = props;

	const format = 'hh:mm';

	const now = moment(time, 'hhmm').format('HH:mm');

	return (
		<TimeCon color={color}>
			<TimeInput onChange={onChange} value={now} type='time' required disabled={disabled} />
			{
				// <TimePicker
				// 	showSecond={false}
				//
				// 	onChange={onChange}
				// 	use12Hours
				// 	inputReadOnly
				// />
			}
		</TimeCon>
	);
};

export const weekData = [
	// {
	// 	id: 0,
	// 	title: 'S'
	// },
	{
		id: 1,
		title: 'S',
	},
	{
		id: 2,
		title: 'M',
	},
	{
		id: 3,
		title: 'T',
	},
	{
		id: 4,
		title: 'W',
	},
	{
		id: 5,
		title: 'R',
	},
	{
		id: 6,
		title: 'F',
	},
	{
		id: 7,
		title: 'S',
	},
];

export const WeekDayCon = styled.div`
	height: 60px;

	flex: 1;
	background-color: ${Colors.grey};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transition: all ease 0.3s;

	${
		'' /* border-right: solid 1px ${Colors.gray};
	border-left: solid 1px ${Colors.gray}; */
	}
	${is('available')`
	    background-color: ${Colors.fullWhite};
		color: black;
		cursor: pointer;
	`}

	${is('active')`
	    background-color: ${({ theme }) => theme.color || Colors.teal};} ;
		color: white;
		cursor: pointer;
	`}

	${is('first')`
		border-top-left-radius: 12px;
		border-bottom-left-radius: 12px;
	`}

	${is('last')`
		border-top-right-radius: 12px;
		border-bottom-right-radius: 12px;
	`}
`;

export const DisputeSummaryWeekCon = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	border-radius: 12px;
	border: solid 1px ${Colors.gray};
	width: 100%;
`;

//Not the cleanest but straight forward i guess
export const DisputeSummaryWeekView = (props) => {
	const renderWeekTiles = () => {
		return weekData.map((d, i) => {
			//state the basic vars
			let active = null;
			let available = null;
			let first = null;
			let last = null;

			const {
				activeDay,
				dispute: { timesheets },
			} = props;

			//run a func on each timesheet per day
			timesheets.forEach((x, i) => {
				// format the timesheet date
				const dayIndex = moment(x.date).day();

				//Comapre the current timesheet date to the current weekData day
				if (dayIndex === d.id) {
					// if the weekdata day is found inside any of the timesheets show that it's availble to click to
					available = true;
				}
			});

			//format the active date for comparison
			const activeDayIndex = moment(activeDay.date).day();
			// if the current weekData day is the same as the curretnly selected day show a ui change
			if (activeDayIndex === d.id) active = true;

			//cheap way to handle making the border correct for the first and last child of DisputeSummaryWeekCon
			if (d.id === 1) first = true;
			if (d.id === 7) last = true;

			return (
				<WeekDayCon
					first={first}
					last={last}
					key={'wdc-' + d.id}
					active={active}
					available={available}
					onClick={() => props.handleClick(d.id)}
				>
					<Text>{d.title}</Text>
				</WeekDayCon>
			);
		});
	};

	return <DisputeSummaryWeekCon>{renderWeekTiles()}</DisputeSummaryWeekCon>;
};
