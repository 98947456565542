import React from 'react';

import { FormContainer, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import FormWrapper from '../FormWrapper';
import InputContainer from '../InputContainer';

const TOS = (props) => {
	const { tosHandler, serverError } = props;

	const submitHandler = (event) => {
		event.preventDefault();
		tosHandler();
	};

	return (
		<FormContainer>
			<FormWrapper>
				<h2>Effective Date: 7/30/24</h2>
				<p>
					These Terms of Use (“Terms”) govern your access to the proprietary verification
					and matching platform operated by KPPD Software Dev LLC (“KPPD”) (collectively,
					the “Platform”). BY ACCESSING OR USING THE PLATFORM, YOU (“YOU”) AGREE THAT YOU
					HAVE READ AND UNDERSTAND THESE TERMS. IF YOU DO NOT AGREE WITH THESE TERMS, DO
					NOT ACCESS OR USE THE PLATFORM.
				</p>
				<p>
					We may modify these Terms at any time. All changes will be effective immediately
					upon posting to the Platform. Material changes will be conspicuously posted on
					the Platform or otherwise communicated to you. By using the Platform after
					changes are posted, you agree to those changes.
				</p>
				<br />
				<br />
				<h2>Content</h2>
				<p>
					The Platform, including its text, audio, video, graphics, charts, photographs,
					interfaces, icons, software, computer code, data, trademarks, logos, slogans,
					documentation, other components and content, and the design, selection, and
					arrangement of content, and all intellectual property rights in the foregoing
					(collectively, the “Content”) are exclusively the property of KPPD or, as
					applicable, its vendors or licensors. Except for the rights expressly granted to
					you in the next section, KPPD reserves all other rights in and to the Platform
					and Content, including all intellectual property rights.
				</p>
				<br />
				<br />
				<h2>Use Rights</h2>
				<p>
					You may only use the Platform or Content for your personal, non-exclusive use in
					the United States, so long as you comply with these Terms, all other terms
					posted throughout the Platform as applicable to you (if any), and all applicable
					laws. You may only use the Platform and the Content for their intended purposes
					for which they are made available to you by KPPD.
				</p>
				<br />
				<br />
				<h2>Accounts</h2>
				<p>
					In order to use most aspects of the Platform, you must register for and maintain
					an active personal user account (“Account”). You:
				</p>
				<ul>
					<li>
						must be at least 18 years of age, or the age of legal majority in your
						jurisdiction (if different than 18), to obtain an Account,
					</li>
					<li>may only possess one Account, and</li>
					<li>
						may not assign or otherwise transfer your Account to any other person or
						entity.
					</li>
				</ul>
				<p>
					You are responsible for all activity that occurs under your Account, and you
					agree to maintain the security and secrecy of your Account credentials at all
					times. Do not share your Account or passwords with any other person. Each person
					using an Account must use their own separate log-in information. Do not access
					or attempt to access any Account that is not yours. If you believe that your
					Account has been compromised, you must immediately contact us. We may disable
					any Account at any time in our sole discretion for any or no reason, including
					if, in our opinion, you have violated any provision of these Terms. Account
					registration may require you to submit certain personal information and
					information related to your interest in a particular job (“Account
					Information”). You are responsible for providing accurate Account Information.
					KPPD may disable or delete your account if after registration your Account is
					not confirmed (where applicable), your Account is unused and remains inactive
					for an extended period of time, if we detect someone may have used it without
					your permission and we are unable to confirm your ownership of the account, or
					where we are required to do so under applicable law. To the maximum extent
					permitted by applicable law, KPPD assumes no liability for such loss of access
					and use and will have no obligations related to such loss. For more information
					regarding KPPD’s use of your personal information, please see our{' '}
					<span
						style={{
							color: 'blue',
							cursor: 'pointer',
						}}
						onClick={() => handlePrivacyClick()}
					>
						Privacy Notice.
					</span>
				</p>
				<br />
				<br />
				<h2>Care Opportunity Listings</h2>
				<p>
					Through the Platform, KPPD may make available third-party listings (“Care
					Opportunity Listings”). Care Opportunity Listings are created and provided by
					third parties over whom KPPD exercises no control. You acknowledge and
					understand that KPPD has no control over the content of Care Opportunity
					Listings, links to or from Care Opportunity Listings, or any conditions third
					parties might impose once you have submitted a bid or signified your interested
					in a Care Opportunity Listing. KPPD has no obligation to screen any Care
					Opportunity Listings and may exclude or remove any Care Opportunity Listings
					from the Platform. You understand and agree that KPPD has no obligation to
					present you with any or all Care Opportunity Listings. We cannot confirm the
					accuracy or completeness of any Care Opportunity Listing or other information
					submitted by any agency or registry, including the identity of such entity. KPPD
					assumes no responsibility, and disclaims all liability for the content,
					accuracy, completeness, legality, reliability, or availability of any Care
					Opportunity Listings, or other information submitted by any agency or registry.
					Care Opportunity Listings may expire or otherwise be removed by the time you
					accept an opportunity or submit your bid for the opportunity, in which cases
					your acceptance or bid will be void. KPPD has no responsibility for expired or
					removed Care Opportunity Listings or for delivering applications prior to a Care
					Opportunity Listing’s expiration or removal. KPPD may store your bid requests
					and related information regardless of whether a Care Opportunity Listing has
					been closed or is no longer available on the Platform. By using the Platform,
					you agree that KPPD is not responsible for the content of an agency or
					registry’s care opportunity description or requirements. Agencies, registries,
					caregivers and care seekers are solely responsible for compliance with all
					applicable laws, including the Fair Credit Reporting Act and similar laws,
					anti-discrimination laws such as Title VII of the Civil Rights Act of 1964, as
					amended, the Age Discrimination in Employment Act, the Americans with
					Disabilities Act, and any applicable data protection or privacy laws. If you
					have questions or concerns about an agency or registry’s Care Opportunity
					Listing, please contact the agency or registry directly. KPPD does not guarantee
					the validity of a care opportunity offer and is not responsible for any decision
					that is made by a care seeker with regard to your acceptance or rejection for a
					particular opportunity. You are solely responsible for verifying the accuracy of
					any agency or registry’s posting of a care opportunity offer.
				</p>
				<br />
				<br />
				<h2>Care Opportunity Listing Functionality</h2>
				<p>
					KPPD may provide independent functionality to assist you. KPPD may reformat Care
					Opportunity Listings so that you may read them more clearly. The placement of a
					Care Opportunity Listing on a dedicated page or website is not a representation
					regarding the nature of the offer for legal purposes or a representation
					regarding the attributes of an particular agency, registry, or care seeker. KPPD
					does not guarantee that bidding on opportunities through a dedicated page or
					website will lead to a better bid experience or acceptance from an agency or
					registry’s clients.
				</p>
				<br />
				<br />
				<h2>Service Hours</h2>
				<p>
					The Care Opportunity Listing will include information regarding the time
					commitment anticipated for the specific opportunity, including which days and
					hours the client is seeking personal care services (“Anticipated Schedule”).
					Once you have accepted an opportunity, the Anticipated Schedule will appear in
					your calendar on the Platform. Once you are ready to start the Anticipated
					Schedule, the Platform will provide functionality that allows you to indicate to
					the applicable care seeker that you have started and ended your Anticipated
					Schedule. Any issues that arise with the Anticipated Schedule or scheduling must
					be handled between you and the applicable care seeker.
				</p>
				<br />
				<br />
				<h2>Communications</h2>
				<p>
					Through the Platform, you will be able to view, send, store, and receive
					communications related to the Care Opportunity Listings. When an agency,
					registry or care seeker views, sends, stores or receives communications or
					materials to KPPD, KPPD may inform you about such actions. Additionally, we may
					also inform you that an agency, registry or care seeker has taken other actions
					with regards to your bid. For example, we may inform you that an agency,
					registry, or care seeker has invited you to bid on a specific opportunity or
					that a care seeker has accepted or rejected your bid. KPPD may also enable
					messaging services within the Platform in order for you to connect with an
					agency, registry or care seeker for which you have bid on a Care Opportunity
					Listing (“KPPD Messaging”). You understand that communicating through KPPD
					Messaging and/or other communication options on or through the Platform shall be
					limited to the purpose of bidding on a Care Opportunity Listing or coordinating
					details related to an accepted opportunity (i.e., scheduling). KPPD reserves the
					right, in its sole discretion, to turn off or disable KPPD Messaging or other
					communication options at any time without prior notice and for any or no reason.
				</p>
				<br />
				<br />
				<h2>Payments</h2>
				<p>
					The Care Opportunity Listing will also provide information regarding the care
					seeker’s offer of compensation for a potential opportunity. Once you have
					submitted your invoice for personal care services provided, your payment will be
					collected from the care seeker and transferred to our third-party payment
					processor, Stripe. You are subject to the Stripe Connected Account Agreement,
					which includes the Stripe Services Agreement (collectively, the “Stripe Terms”),
					and you agree to be bound by the Stripe Terms, which may be modified from time
					to time. You also agree to provide accurate and complete information about you
					and authorize KPPD to share it and transaction information with Stripe for the
					purposes of facilitating the payment processing services provided by Stripe.
					KPPD reserves the right to switch payment processing vendors or use alternate or
					backup vendors in its discretion. Once your compensation has been transferred to
					your Stripe Connected Account, you are responsible for transferring any funds to
					your bank account. YOU ACKNOWLEDGE AND AGREE THAT KPPD SHALL HAVE NO
					RESPONSIBILITY OR LIABILITY WITH REGARD TO A CARE SEEKER’S PAYMENT OBLIGATIONS
					TO YOU.
				</p>
				<br />
				<br />
				<h2>Privacy</h2>
				<p>
					We process personal information in order to provide the Platform and our
					services. We process your personal information in accordance with our Privacy
					Notice.
				</p>
				<br />
				<br />
				<h2>Use of Marks</h2>
				<p>
					KPPD owns certain trademarks, names, logos, insignia, or service marks
					(“Marks”). You do not have the right to use any Marks except as expressly agreed
					to in writing by KPPD. In addition, the Platform may contain third-party marks
					and third-party copyrighted materials, which are the property of their
					respective owners. Nothing in these Terms grants to you any rights in or to
					those third-party marks or materials without such third-party's consent.
				</p>
				<br />
				<br />
				<h2>Intellectual Property Rights</h2>
				<p>
					The Platform and Content are protected by copyright, trademark, and other
					intellectual property laws. Any unauthorized use of any trademarks, trade dress,
					copyrighted materials, or any other intellectual property belonging to KPPD or
					any third party is strictly prohibited and may be prosecuted to the fullest
					extent of the law.
				</p>
				<br />
				<br />
				<h2>Compliance with Laws</h2>
				<p>
					In connection with your access to and use of the Platform, you are responsible
					for complying with all applicable laws, regulations, and rules of all relevant
					jurisdictions, including all applicable rules regarding online conduct.
				</p>
				<br />
				<br />
				<h2>Children's Information</h2>
				<p>
					The Platform is not directed at children under the age of eighteen (18) years
					old. If you are under eighteen (18) years old, you must immediately stop using
					the Platform.
				</p>
				<br />
				<br />
				<h2>Restrictions on Your Use of the Platform</h2>
				<ul>
					<li>
						You will not copy, duplicate, sell, publish, post, license, rent,
						distribute, modify, translate, adapt, reverse-engineer, or create derivative
						works of the Platform or Content without KPPD’s prior written consent.
					</li>
					<li>You will not use the Platform for unlawful purposes.</li>
					<li>
						You will not submit inaccurate, incomplete, or out-of-date information via
						the Platform, commit fraud or falsify information in connection with your
						use of the Platform.
					</li>
					<li>
						You will not engage in data mining or similar data gathering or extraction
						activities from the Platform. You will not use the Platform to harvest email
						addresses, names, or other information of the users of the Platform or to
						spam other users of the Platform.
					</li>
					<li>
						You will not access, use, or copy any portion of the Platform or Content
						through the use of indexing agents, spiders, scrapers, bots, web crawlers,
						or other automated devices or mechanisms.
					</li>
					<li>
						You will not use the Platform to post, transmit, input, upload, or otherwise
						provide any information or material that contains any viruses, worms, Trojan
						horses, malware, ransomware, adware, or other harmful computer code that may
						disable, damage, impair, or otherwise interfere with the Platform, the
						servers used to make the Platform available, or any other network,
						computers, hardware, software or systems.
					</li>
					<li>
						You will not engage in activities that aim to render the Platform or
						associated services inoperable or to make their use more difficult.
					</li>
					<li>
						You may not frame, mirror, or circumvent the navigational structure of any
						part of the Platform.
					</li>
					<li>
						You may not upload, distribute, transmit, or post anything to or through the
						Platform that: (i) is fraudulent, libelous, obscene, pornographic, indecent,
						violent, offensive, hate speech, harassing, threatening, defamatory, harms
						another person, or the like; (ii) invades the privacy of another or includes
						the confidential or proprietary information of another; or (iii) is
						protected by intellectual property rights without the express prior written
						consent of the owner of such intellectual property rights.
					</li>
					<li>
						You may not engage in any conduct while using the Platform that KPPD
						considers inappropriate, unauthorized, or contrary to the intended purpose
						of the Platform.
					</li>
				</ul>
				<br />
				<br />
				<h2>Feedback and Other Content Submitted By You</h2>
				<p>
					If you submit comments or feedback to us regarding the Platform or its Content,
					or any other comments, questions, requests, content or information that is not
					personal information ("Feedback"), we may use any comments and feedback that you
					send us in our discretion and without attribution or compensation to you.
				</p>
				<br />
				<br />
				<h2>NO WARRANTY</h2>
				<p>
					THE PLATFORM AND CONTENT ARE PROVIDED “AS IS,” “AS AVAILABLE,” AND WITHOUT ANY
					WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, KPPD
					EXPRESSLY DISCLAIMS ALL WARRANTIES OF ANY KIND WITH RESPECT TO THE PLATFORM AND
					CONTENT, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE IMPLIED WARRANTIES
					OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT,
					AND ANY WARRANTIES ARISING FROM STATUTE, SUCH AS COURSE OF PERFORMANCE, COURSE
					OF DEALING OR USAGE IN TRADE.
				</p>
				<p>
					KPPD MAKES COMMERCIALLY REASONABLE EFFORTS TO ENSURE THAT ALL CONTENT ON THE
					PLATFORM IS ACCURATE AND RELIABLE, BUT NEITHER ACCURACY NOR RELIABILITY ARE
					GUARANTEED. KPPD DOES NOT WARRANT OR GUARANTEE THE QUALITY, COMPLETENESS,
					TIMELINESS, OR AVAILABILITY OF THE PLATFORM OR CONTENT. KPPD DOES NOT WARRANT OR
					GUARANTEE THAT THE PLATFORM OR CONTENT WILL BE UNINTERRUPTED OR ERROR-FREE, THAT
					ANY DEFECTS IN THE PLATFORM OR CONTENT WILL BE CORRECTED, OR THAT THE PLATFORM
					OR THE SERVERS THAT MAKE THE PLATFORM AVAILABLE ARE FREE OF VIRUSES OR OTHER
					HARMFUL CONDITIONS OR COMPONENTS.
				</p>
				<p>
					WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, WE DO NOT WARRANT OR ENDORSE
					ANY THIRD-PARTY CONTENT.
				</p>
				<br />
				<br />
				<h2>LIMITATION OF LIABILITY</h2>
				<p>
					TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL KPPD OR ITS
					DIRECTORS, OFFICERS, OWNERS, EMPLOYEES, CONTRACTORS, REPRESENTATIVES,
					CONSULTANTS, VOLUNTEERS, AGENTS, SUPPLIERS, ATTORNEYS OR LICENSORS (TOGETHER,
					“KPPD PARTY(IES)”) BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
					EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL LOSS OR DAMAGE, ARISING OUT OF OR IN
					CONNECTION WITH THE PLATFORM OR CONTENT, OR YOUR ACCESS TO OR USE OF, OR
					INABILITY TO ACCESS OR USE, THE PLATFORM OR CONTENT, REGARDLESS OF THE FORM OF
					ACTION, WHETHER THE CLAIM IS BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
					STRICT LIABILITY, WARRANTY, OR OTHERWISE, AND EVEN IF A KPPD PARTY HAS EXPRESS
					KNOWLEDGE OF THE POSSIBILITY OF THE LOSS OR DAMAGE.
				</p>
				<p>
					YOUR SOLE AND EXCLUSIVE REMEDY IS TO STOP ACCESSING AND USING THE PLATFORM OR
					CONTENT.
				</p>
				<p>
					WITHOUT LIMITING THE FOREGOING, IN NO EVENT WILL THE AGGREGATE LIABILITY OF THE
					KPPD PARTIES ARISING OUT OF OR IN CONNECTION WITH THE PLATFORM OR CONTENT, OR
					YOUR ACCESS TO OR USE OF, OR INABILITY TO ACCESS OR USE, THE PLATFORM OR CONTENT
					EXCEED $100 U.S.D., EVEN IF ANY REMEDY PROVIDED FAILS OF ITS ESSENTIAL PURPOSE.
				</p>
				<p>
					SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES,
					SO SOME OF THE ABOVE EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU.
				</p>
				<h2>INDEMNIFICATION</h2>
				<p>
					YOU SHALL INDEMNIFY, DEFEND AND HOLD HARMLESS THE KPPD PARTIES FROM AND AGAINST
					ALL LOSSES, CLAIMS, LIABILITIES, DEMANDS, COMPLAINTS, ACTIONS, DAMAGES,
					JUDGMENTS, SETTLEMENTS, FINES, PENALTIES, EXPENSES, AND COSTS (INCLUDING WITHOUT
					LIMITATION REASONABLE ATTORNEYS’ FEES) THAT ARISE OUT OF OR IN CONNECTION WITH
					(A) YOUR VIOLATION OF APPLICABLE LAWS, (B) YOUR MISUSE OF THE PLATFORM OR ANY
					CONTENT, AND (C) YOUR BREACH OF THESE TERMS OR ANY OTHER TERMS ON THE PLATFORM.
					WE RESERVE, AND YOU GRANT TO US, THE EXCLUSIVE RIGHT TO ASSUME THE DEFENSE AND
					CONTROL OF ANY MATTER SUBJECT TO INDEMNIFICATION BY YOU (SUBJECT TO YOUR
					CONTINUING INDEMNIFICATION).
				</p>
				<br />
				<br />
				<h2>Third-Party Websites and Content</h2>
				<p>
					The Platform may link to, or be linked to, websites not maintained or controlled
					by KPPD. Those links are provided as a convenience to the visitors of our
					Platform. KPPD is not responsible for examining or evaluating the content or
					accuracy of third-party websites linked through the Platform. KPPD does not
					warrant or endorse any third-party website or any products or services made
					available through those websites. When leaving the Platform, it is the terms and
					privacy policy of that third party that govern your use of the third-party site
					(and such third-party's use of your personal information), not these Terms.
				</p>
				<p>
					The Platform may also contain certain third-party Content. We provide
					third-party content for your convenience, not as an endorsement. The presence of
					third-party Content does not mean that KPPD has reviewed the third-party Content
					or that there is any association between KPPD and any third party. You access
					third-party Content at your sole risk. KPPD has no responsibility for any
					third-party Content. Nothing in these Terms grants you any rights to any
					third-party Content.
				</p>
				<h2>Linking to the Platform</h2>
				<p>
					You are prohibited from linking to the Platform on your website or elsewhere
					without the prior express written consent of KPPD. If KPPD grants you a right to
					link to the Platform, certain terms may apply, and KPPD reserves the right to
					revoke such consent at any time. You are responsible for any costs incurred by
					KPPD in enforcing its rights under this Section.
				</p>
				<h2>Use in the United States</h2>
				<p>
					The Platform is intended for use in the United States only. We do not guarantee
					that use of the Platform will be available or permitted in any location other
					than the United States. If you choose to access the Platform from a location
					other than the United States, you do so at your own risk. THE EXISTENCE OF THE
					PLATFORM OR ANY CONTENT SHALL NOT BE CONSTRUED AS KPPD OR THE KPPD PARTIES
					OFFERING SUCH PLATFORM OR CONTENT TO PERSONS IN JURISDICTIONS WHERE THE
					PROVISION OF SUCH PLATFORM OR CONTENT IS PROHIBITED BY LAW.
				</p>
				<br />
				<br />
				<h2>Termination</h2>
				<p>
					If you violate applicable laws or these Terms, you are immediately prohibited
					from further use of the Platform or Content, and we may restrict your access to
					your Account or the Platform or Content. KPPD may suspend or terminate your
					Account, the Platform or any Content, in whole or in part, at any time in its
					sole discretion for any reason. KPPD shall not be liable to you or anyone else
					for any damages arising from or related to KPPD’s suspension or termination of
					your Account or access to the Platform or the Content, or in the event KPPD
					modifies, discontinues or restricts the availability of your Account, the
					Platform or the Content (in whole or in part).
				</p>
				<h2>Platform Unavailability</h2>

				<p>
					Without limiting the generality of the previous section, the Platform or Content
					may be unavailable or limited for various reasons, and we shall not be liable to
					you for any such unavailability, including without limitation (a) hardware,
					software, server, network, or telecommunications failures, (b) severe weather,
					war, riot, act of God, fire, earthquake, strike, labor shortage, etc., (c)
					regulatory restrictions and other acts of government, (d) interruptions due to
					utility and power companies, and (e) interruptions due to hacking or other
					malicious intrusion.
				</p>
				<h2>Cooperation with Law Enforcement</h2>
				<p>
					KPPD will cooperate with law enforcement if you are suspected of having violated
					applicable laws. YOU WAIVE AND HOLD KPPD AND THE KPPD PARTIES HARMLESS FOR ANY
					COOPERATION WITH, OR DISCLOSURE OF YOUR INFORMATION TO, LAW ENFORCEMENT RELATING
					TO YOUR SUSPECTED VIOLATION OF APPLICABLE LAWS.
				</p>
				<br />
				<br />
				<h2>Governing Law</h2>
				<p>
					These Terms will be governed by and construed, interpreted, and enforced in
					accordance with the laws of the State of Florida without reference to its
					conflicts or choice of law principles. Any arbitration or court proceeding will
					take place in Broward County, Florida and you hereby consent to the exclusive
					jurisdiction and venue of the state or Federal courts in Broward County,
					Florida. You irrevocably submit and consent to the personal jurisdiction of such
					courts. Any cause of action or other claim brought by you with respect to the
					Platform or Content must be commenced within one year after the cause of action
					or claim arises.
				</p>
				<br />
				<br />
				<h2>Assignment</h2>
				<p>
					We may assign our rights and delegate our duties under these Terms at any time
					to any party without notice to you. You may not assign your rights or delegate
					your duties under these Terms without our prior written consent. These Terms
					inure to the benefit of KPPD’s successors and assigns.
				</p>
				<br />
				<br />

				<h2>Entire Agreement</h2>
				<p>
					These Terms and any terms posted throughout the Platform (if any) are the entire
					agreement between you and KPPD with respect to your access to and use of the
					Platform.
				</p>
				<br />
				<br />

				<h2>Waiver</h2>
				<p>
					KPPD’s failure to enforce any provision of these Terms will not constitute a
					waiver of that provision or any other provision. Any waiver of any provision of
					these Terms will be effective only if in writing and signed by KPPD.
				</p>
				<br />
				<br />
				<h2>Severability</h2>
				<p>
					If any provision of these Terms is held invalid, void, or unenforceable, that
					provision will be severed from the remaining provisions and the remaining
					provisions will remain in full force and effect. The headings in these Terms are
					for convenience only and do not affect the interpretation of these Terms.
				</p>
				<br />
				<br />
				<h2>Electronic Communications</h2>
				<p>
					These Terms and any other documentation, agreements, notices, or communications
					between you and KPPD may be provided to you electronically to the extent
					permissible by law.
				</p>
				<br />
				<br />
				<h2>Contact Us</h2>
				<p>
					Please direct any questions and concerns regarding these Terms to us at
					support@fyi.care
				</p>
				<br />
				<br />
				<br />

				<InputContainer>
					<PrimaryButton onClick={submitHandler} size='small'>
						Accept
					</PrimaryButton>

					{serverError && <ErrorMessage> {serverError} </ErrorMessage>}
				</InputContainer>
			</FormWrapper>
		</FormContainer>
	);
};

export default TOS;
