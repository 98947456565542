import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const respondToJobOffer = async ({ jobId, status, careProId }) => {
	try {
		const data = await api.graph({
			query: `mutation {
                        careProJobOfferResponse(
                            job_id: ${jobId},
                            response_status: "${status}"
                            provider_id: ${careProId}
                            ) {
                                id
                            }
                    }`,
		});

		return data.careProJobOfferResponse;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useRespondToJobOfferMutation = () => {
	return useMutation({
		mutationKey: ['respondToJobOffer'],
		mutationFn: (values) => {
			return respondToJobOffer(values);
		},
	});
};
