import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { STEP_PROGRESS_KEYS } from '../CareProOnboardParent';

import OnboardSubNav from '../../../patient/ClientOnboard/OnboardSubnav';
import { ColumnFlex } from 'basicStyles';

import api from 'scripts/api';

import BankInfo from './BankInfo';
import BirthDate from './BirthDate';
import DocumentVerify from './DocumentVerify';
import TOS from './TOS';
import Personal from './Personal';

import useWindowSize from 'scripts/useWindowSize';

import {
	OnboardFormContainer,
	OnboardTitle,
	OnboardSubText,
	OnboardButton,
	OnboardFormButton,
	FormItemsLength,
} from '../CareProOnboardViews';

import { toastErrorConfig, toastSuccessConfig } from 'reducers/provider';

import {
	PROVIDER_MODEL_V2,
	STRIPE_STATUS_MODEL,
	CARE_PRO_REGISTRY_STATUS_MODEL,
} from 'reducers/graphqlModelTypes';
import ConfirmIdNumber from './ConfirmIdNumber';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const CARE_PRO_BANK_ONBOARD_SUB_NAV = [
	{
		id: 1,
		title: 'Welcome',
		completed: false,
	},
	{
		id: 2,
		title: 'Home Info',
		completed: false,
	},
	{
		id: 3,
		title: 'Payout Info',
		completed: false,
	},
	{
		id: 4,
		title: 'Personal Info',
		completed: false,
	},
	{
		id: 5,
		title: 'TOS',
		completed: false,
	},
	// {
	// 	id: 5,
	// 	title: 'Profile',
	// 	completed: false,
	// },
	// {
	// 	id: 5,
	// 	title: 'Pending',
	// 	completed: false,
	// },
];

const BankingOnboard = (props) => {
	const { receiveProviderInfo, provider, handleUpdateSectionProgress, renderCurrentStep } = props;

	const { width } = useWindowSize();

	const [step, setStep] = useState(0);

	const [bankingSubNab, setBankSubNav] = useState(CARE_PRO_BANK_ONBOARD_SUB_NAV);

	const [careProBankOnboardStep, setCareProBankingOnboardStep] = useState(1);

	const [isLoading, setIsLoading] = useState(false);

	const handleDataUpdate = (step) => {
		if (step) {
			setCareProBankingOnboardStep(step);

			switch (step) {
				case 2:
					const _bankingSubNab = bankingSubNab;
					_bankingSubNab[0].completed = true;
					setBankSubNav(_bankingSubNab);
					handleUpdateSectionProgress(0.2, STEP_PROGRESS_KEYS.BANKING);

					break;
				case 3:
					handleUpdateSectionProgress(0.4, STEP_PROGRESS_KEYS.BANKING);

					break;
				case 4:
					handleUpdateSectionProgress(0.6, STEP_PROGRESS_KEYS.BANKING);

					break;
				case 6:
					handleUpdateSectionProgress(0.8, STEP_PROGRESS_KEYS.BANKING);

					break;
				default:
					break;
			}
		}
	};

	useEffect(() => {
		determineStep();
	}, [provider]);

	const handleSubNavTileClick = (id) => {
		console.log('tile clicked id', id);
		// based on the account sub nav we can only move to views that have been completed or the first view after the last completion

		// flip the arr and get the first item that is completed that would be the most recent

		const _bankingSubNab = bankingSubNab;
		const reversedSubNav = _bankingSubNab.slice().reverse();
		const mostRecentCompleted = reversedSubNav.find((item) => item.completed);
		console.log('mostRecentCompleted', mostRecentCompleted);

		if (mostRecentCompleted) {
			if (id <= mostRecentCompleted.id + 1) {
				setCareProBankingOnboardStep(id);
			} else {
				toast.error('Please complete the previous step', toastErrorConfig);
			}
		} else {
			toast.error('Please complete the previous step', toastErrorConfig);
		}
	};

	const determineStep = () => {
		const { stripeStatus } = provider;
		if (stripeStatus) {
			const {
				stripe_data,
				status,
				payouts_enabled,
				charges_enabled,
				card_payments,
				transfers_enabled,
			} = stripeStatus;

			const _bankingSubNab = bankingSubNab;

			const { currently_due } = stripe_data;

			const address = currently_due.filter((item) => item.includes('address'));
			console.log('address', address);
			if (address.length > 0) {
				bankingSubNab[0].completed = true;

				handleUpdateSectionProgress(0.2, STEP_PROGRESS_KEYS.BANKING);
				setCareProBankingOnboardStep(1);
				setBankSubNav(_bankingSubNab);

				return;
			}

			const externalAccount = currently_due.filter((item) =>
				item.includes('external_account')
			);

			console.log('externalAccount', externalAccount);
			if (externalAccount.length > 0) {
				bankingSubNab[0].completed = true;
				bankingSubNab[1].completed = true;

				handleUpdateSectionProgress(0.4, STEP_PROGRESS_KEYS.BANKING);
				setBankSubNav(_bankingSubNab);
				setCareProBankingOnboardStep(3);

				return;
			}

			// check if any of the personal info items are missing
			const personalInfo = currently_due.filter((item) => item.includes('dob'));
			const ssn = currently_due.filter((item) => item.includes('ssn_last_4'));
			if (personalInfo.length > 0 || ssn.length > 0) {
				bankingSubNab[0].completed = true;
				bankingSubNab[1].completed = true;
				bankingSubNab[2].completed = true;

				handleUpdateSectionProgress(0.6, STEP_PROGRESS_KEYS.BANKING);
				setBankSubNav(_bankingSubNab);
				setCareProBankingOnboardStep(4);
				return;
			}

			const tos = currently_due.filter((item) => item.includes('tos_acceptance'));

			if (tos.length > 0) {
				bankingSubNab[0].completed = true;
				bankingSubNab[1].completed = true;
				bankingSubNab[2].completed = true;
				bankingSubNab[3].completed = true;

				handleUpdateSectionProgress(0.8, STEP_PROGRESS_KEYS.BANKING);
				setBankSubNav(_bankingSubNab);
				setCareProBankingOnboardStep(5);
				return;
			}

			bankingSubNab[0].completed = true;
			bankingSubNab[1].completed = true;
			bankingSubNab[2].completed = true;
			bankingSubNab[3].completed = true;
			bankingSubNab[4].completed = true;

			/*
			const fullSsn = currently_due.filter((item) => item.includes('individual.id_number'));
			if (fullSsn.length > 0) {
				setCareProBankingOnboardStep(6);
				handleUpdateSectionProgress(0.95, STEP_PROGRESS_KEYS.BANKING);

				return;
			}

			// check if we need to upload documents for verification
			const documentUpload = currently_due.filter((item) => item.includes('document'));

			if (documentUpload.length > 0) {
				setCareProBankingOnboardStep(7);
				handleUpdateSectionProgress(0.95, STEP_PROGRESS_KEYS.BANKING);
			}
			*/
			handleUpdateSectionProgress(1, STEP_PROGRESS_KEYS.BANKING);
			setBankSubNav(_bankingSubNab);

			// if we make it here we can assume all data has been collected
			renderCurrentStep();
		}
	};
	const renderView = () => {
		switch (careProBankOnboardStep) {
			case 1:
				return <WelcomePaymentScreen handleDataUpdate={handleDataUpdate} />;

			case 2:
				return (
					<Personal
						isLoading={isLoading}
						updateProviderVerificationInfo={updateProviderVerificationInfo}
						handleDataUpdate={handleDataUpdate}
						setIsLoading={setIsLoading}
					/>
				);
			case 3:
				return (
					<Elements stripe={stripePromise}>
						<BankInfo
							isLoading={isLoading}
							updateProviderVerificationInfo={updateProviderVerificationInfo}
							handleDataUpdate={handleDataUpdate}
							setIsLoading={setIsLoading}
						/>
					</Elements>
				);
			case 4:
				return (
					<BirthDate
						isLoading={isLoading}
						updateProviderVerificationInfo={updateProviderVerificationInfo}
						handleDataUpdate={handleDataUpdate}
						setIsLoading={setIsLoading}
					/>
				);
			case 5:
				return (
					<TOS
						isLoading={isLoading}
						updateProviderVerificationInfo={updateProviderVerificationInfo}
						handleDataUpdate={handleDataUpdate}
						setIsLoading={setIsLoading}
					/>
				);
			case 6:
				return (
					<ConfirmIdNumber
						isLoading={isLoading}
						updateProviderVerificationInfo={updateProviderVerificationInfo}
						setIsLoading={setIsLoading}
					/>
				);
			case 7:
				return (
					<Elements stripe={stripePromise}>
						<DocumentVerify
							isLoading={isLoading}
							updateProviderVerificationInfo={updateProviderVerificationInfo}
							setIsLoading={setIsLoading}
							stripeStatus={provider.stripeStatus}
							provider={provider}
						/>
					</Elements>
				);
			default:
				return <>default</>;
		}
	};

	const updateProviderVerificationInfo = async (inputs) => {
		console.log('updateProviderVerificationInfo - inputs', inputs);
		try {
			const res = await api.graph({
				query: `mutation {
					updateProviderStripeInformation(
						updateValues: ${api.graphStringify(inputs)},
					) {
            stripeStatus {
              ${STRIPE_STATUS_MODEL}
            }
            careProRegistryStatus {
              ${CARE_PRO_REGISTRY_STATUS_MODEL}
            }
					}
				}`,
			});

			const data = res.updateProviderStripeInformation;
			console.log('updateProviderVerificationInfo-data', data);
			if (data.errors && data.errors.length > 0) {
				toast.error(data.errors[0].message, toastErrorConfig);
			} else {
				// update the provider
				receiveProviderInfo({
					...provider,
					stripeStatus: data.stripeStatus,
					careProRegistryStatus: data.careProRegistryStatus,
				});

				// if currently due items are empty then we can move to the next step
				if (data.stripeStatus.stripe_data.currently_due.length === 0) {
					console.log('test this');
					renderCurrentStep();
				}
			}

			setIsLoading(false);
		} catch (err) {
			console.log('err', err);
			setIsLoading(false);
			if (err[0] && err[0].message) {
				toast.error(err[0].message, toastErrorConfig);
			}

			console.log('updateProviderVerificationInfo - err', err);
		}
	};

	console.log('provider', provider);
	return (
		<>
			{width > 868 && (
				<OnboardSubNav
					currentStep={careProBankOnboardStep}
					steps={bankingSubNab}
					handleSubNavTileClick={handleSubNavTileClick}
					showArrowsBetween={true}
					descriptionText=''
				/>
			)}

			{renderView()}
		</>
	);
};

export default BankingOnboard;

const WelcomePaymentScreen = (props) => {
	const { handleDataUpdate } = props;

	const handleContinue = () => {
		handleDataUpdate(2);
	};

	return (
		<OnboardFormContainer style={{ width: '340px' }} center>
			<OnboardTitle>Payment</OnboardTitle>
			<ColumnFlex padding='0 0 12px 0' margin='20px auto 0 auto' center gap='12px'>
				<OnboardSubText
					style={{
						textAlign: 'center',
					}}
				>
					FYI partners with Stripe to pay you. We’ll need to collect additional
					information to process your pay.
				</OnboardSubText>
			</ColumnFlex>
			<OnboardButton onClick={() => handleContinue()}>Continue</OnboardButton>
		</OnboardFormContainer>
	);
};
