import React, { useState } from 'react';

import styled from 'styled-components';
import Colors from 'colors';

import { AnimatePresence, motion } from 'framer-motion';
import {
	faHome,
	faUserCircle,
	faCalendarAlt,
	faCalendarWeek,
	faFile,
	faMailBulk,
	faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';

import { RowFlex, ColumnFlex } from 'basicStyles';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';

export const NavTileFramer = (props) => {
	const { title, icon, history, showNavHandler, isMobile, push, route, index } = props;
	const active = false;

	const [isActive, setIsActive] = useState(active);

	const handleOnClick = () => {
		push(route);
		showNavHandler();
	};

	return (
		<motion.div {...framerText(index)}>
			<RowFlex
				onMouseEnter={() => setIsActive(true)}
				onMouseLeave={() => setIsActive(false)}
				padding='0px 0 0 16px'
				fullWidth
				alignCenter
				gap='12px'
				style={{
					height: '60px',
					width: '240px',
					borderBottom: isActive ? `2px solid ${Colors.fullWhite}` : `2px solid #EDF6FB`,
					transition: 'all 0.3s ease',
					cursor: 'pointer',
					backgroundColor: !isActive ? Colors.primary.primary : '#5EAFE8',
				}}
				onClick={() => handleOnClick()}
			>
				<Icon size={'1x'} color={'white'} icon={icon} />
				<Text fontStyle='semi-bold' mediumText color={'white'}>
					{title}
				</Text>
			</RowFlex>
		</motion.div>
	);
};

export const BackDropBg = styled(motion.div)`
	position: fixed;
	top: 120px;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	background-color: rgba(0, 0, 0, 0.5);
	backdrop-filter: blur(4px);
`;

const SideBarPanel = styled(motion.div)`
	position: fixed;
	display: flex;
	width: fit-content;
	z-index: 100;
	right: 0;
	display: flex;
	flex-direction: column;
	background-color: ${Colors.primary.primary};
	top: 120px;
	border-top-right-radius: 12px;
	border-bottom-right-radius: 12px;
`;

const PatientNavigation = ({ history, showNav, showNavHandler }) => {
	return (
		<>
			<AnimatePresence mode='wait' initial={false}>
				{showNav && (
					<>
						<BackDropBg
							onClick={showNavHandler}
							{...framerSidebarBackground}
							aria-hidden='true'
						></BackDropBg>
						<SideBarPanel aria-label='Sidebar' {...framerSidebarPanel}>
							<ColumnFlex padding='16px 0 0 0' gap='0px'>
								<NavTileFramer
									icon={faHome}
									title='Home'
									route='/'
									history={history}
									push={history.push}
									index={0}
									showNavHandler={showNavHandler}
								/>
								<NavTileFramer
									icon={faCalendarAlt}
									title='Schedule'
									route='/clientschedule'
									history={history}
									push={history.push}
									showNavHandler={showNavHandler}
									index={1}
								/>
								<NavTileFramer
									icon={faMailBulk}
									title='Chat'
									route='/chat'
									history={history}
									push={history.push}
									showNavHandler={showNavHandler}
									index={3}
								/>
								<NavTileFramer
									icon={faUserCircle}
									title='Profile'
									route='/profile'
									showNavHandler={showNavHandler}
									history={history}
									push={history.push}
									index={4}
								/>
								<NavTileFramer
									icon={faFile}
									title='Documents'
									route='/documents'
									history={history}
									push={history.push}
									showNavHandler={showNavHandler}
									index={5}
								/>
								<NavTileFramer
									icon={faMoneyBill}
									title='Payments'
									route='/payments'
									history={history}
									push={history.push}
									showNavHandler={showNavHandler}
									index={6}
								/>
							</ColumnFlex>
						</SideBarPanel>
					</>
				)}
			</AnimatePresence>
		</>
	);
};

const framerSidebarBackground = {
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0, transition: { delay: 0.2 } },
	transition: { duration: 0.3 },
};

const framerSidebarPanel = {
	initial: { y: '-100%' },
	animate: { y: 0 },
	exit: { y: '-100%' },
	transition: { duration: 0.3 },
};

const framerText = (delay) => {
	return {
		initial: { opacity: 0, x: -50 },
		animate: { opacity: 1, x: 0 },
		transition: {
			delay: 0.5 + delay / 10,
		},
	};
};

const framerIcon = {
	initial: { scale: 0 },
	animate: { scale: 1 },
	transition: {
		type: 'spring',
		stiffness: 260,
		damping: 20,
		delay: 1.5,
	},
};

export default PatientNavigation;
