import React, { useState } from 'react';
import moment from 'moment';

import useWindowSize from 'scripts/useWindowSize';

import SetTable from 'AppComp/SetTable';
import Text from 'AppComp/Text';
import Button from 'AppComp/Button';
import Colors from 'colors';

const PaymentsTable = (props) => {
	const { payments } = props;
	const { width } = useWindowSize();

	const renderLegend = () => {
		return (
			<>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Date
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Hourly Rate
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Hours
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Subtotal
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						FYI share
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Provider Payout
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						User
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon>
					<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
						Provider
					</Text>
				</SetTable.ColumnCon>
				<SetTable.ColumnCon />
			</>
		);
	};

	const renderRows = () => {
		return payments.map((d, i) => {
			console.log('d.timesteamp', d.timestamp);
			return (
				<SetTable.RowAction key={'ptc-' + d.id}>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							{moment.unix(d.timestamp).format('MM/DD/YYYY')}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							${d.hourly_rate / 100}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							{d.hours}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							${d.subtotal / 100}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							${d.fyi_share / 100}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text fontStyle='italic' color='#969696' fontWeight='100' subtitle pointer>
							${d.provider_payout / 100}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text
							onClick={() => fetchUserDetails({ type: 'patient', id: d.user.id })}
							fontStyle='italic'
							color='#969696'
							fontWeight='100'
							subtitle
							pointer
							underline
						>
							{d.user.name}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Text
							onClick={() =>
								fetchUserDetails({ type: 'provider', id: d.provider.id })
							}
							fontStyle='italic'
							color='#969696'
							underline
							fontWeight='100'
							subtitle
							pointer
						>
							{d.provider.displayName}
						</Text>
					</SetTable.ColumnCon>
					<SetTable.ColumnCon>
						<Button
							color={Colors.blue}
							width={width < 768 ? 40 : 0}
							// onClick={() => fetchUserDetails({ type: 'payment', id: d.id })}
						>
							View Info
						</Button>
					</SetTable.ColumnCon>
				</SetTable.RowAction>
			);
		});
	};

	return (
		<SetTable
			title='Payments'
			renderLegend={renderLegend}
			renderRows={renderRows}
			leftPadding='16px'
		/>
	);
};

export default PaymentsTable;
