import React, { useState } from 'react';

import {
	OnboardFormContainer,
	OnboardTitle,
	OnboardSubText,
	OnboardButton,
	OnboardFormButton,
	FormItemsLength,
} from '../CareProOnboardViews';
import { ColumnFlex } from 'basicStyles';

import { faPencilAlt, faFileUpload } from '@fortawesome/free-solid-svg-icons';

import { Formik, Form, Field } from 'formik';
import Colors from 'colors';

import { validateAuth } from './validate';
import Title from 'AppComp/Title';
import PageLoaderView from 'AppComp/PageLoaderView';

import api from 'scripts/api';
import { toast } from 'react-toastify';

import { toastErrorConfig, toastSuccessConfig } from 'reducers/provider';

import { isMobile } from 'react-device-detect';
import Dropzone from 'react-dropzone';
import Icon from 'AppComp/Icon';

import StyledTextInput, { TextArea } from 'AppComp/styled/StyledTextInput';
import useWindowSize from 'scripts/useWindowSize';

import {
	PROVIDER_MODEL_V2,
	STRIPE_STATUS_MODEL,
	CARE_PRO_REGISTRY_STATUS_MODEL,
	VERIFICATION_MODEL,
} from 'reducers/graphqlModelTypes';

import { CustomAvatar } from '../../ProfileInfo/ProfileInfoViews';
const Profile = (props) => {
	const { setOnboardStep, renderCurrentStep, provider, receiveProviderInfo } = props;

	const [newImage, setNewImage] = useState(false);
	const [newImageUrl, setNewImageUrl] = useState('');

	const [isLoading, setIsLoading] = useState(false);
	const { width } = useWindowSize();

	const handleSubmit = (values, form) => {
		console.log('Personal - handleSubmit', values);
		setIsLoading(true);

		const updateProviderInfoParams = {
			professional_statement: values.professional_statement,
		};

		if (newImageUrl) {
			updateProviderInfoParams.image_url = newImageUrl;
		}
		updateProviderProfileInfo(updateProviderInfoParams);
	};

	const handleValidation = (values) => {
		const errors = {};
		const err = 'Required';

		if (!values.professional_statement) {
			errors.professional_statement = err;
		}

		if (values.professional_statement && values.professional_statement.length > 500) {
			errors.professional_statement = 'Exceeded character limit (500)';
		}

		return errors;
	};

	const handleDroppedFile = (acceptedFiles) => {
		var imageTypes = ['image/png', 'image/gif', 'image/bmp', 'image/jpg', 'image/jpeg'];

		var fileType = acceptedFiles[0].type;

		if (imageTypes.includes(fileType)) {
			const identifyPoolId = 'us-east-2:8aa03690-fa6e-4aab-8429-22b9b6270602';
			const bucketRegion = 'us-east-2';
			const bucketName = 'desir';

			// this.setState({
			// 	loading: true,
			// });

			AWS.config.update({
				region: bucketRegion,
				credentials: new AWS.CognitoIdentityCredentials({
					IdentityPoolId: identifyPoolId,
				}),
			});

			const s3 = new AWS.S3({
				apiVersion: '2012-10-17',
				params: { Bucket: bucketName },
			});

			const key = acceptedFiles[0].name;

			s3.upload(
				{
					Key: key,
					Body: acceptedFiles[0],
					ACL: 'public-read',
				},
				handleUploadCallback
			);
		} else {
			window.alert('dropped file is not an image');
		}
	};

	const handleUploadCallback = (err, data) => {
		//once the call back is called we either should show an error or run handleSignatureUpdate to pass the info back to the parent and handle removing the signature pad

		if (err) {
			console.log('something went wrong', err);
		} else {
			console.log('data', data);

			const { Location, Key } = data;
			console.log('Location', Location);
			setNewImage(true);
			setNewImageUrl(Location);
		}
	};

	const updateProviderProfileInfo = async (inputs) => {
		try {
			const res = await api.graph({
				query: `mutation {
					updateProviderInfo(
						profile_info: ${api.graphStringify(inputs)},
					) {
            professional_statement
						
					}
				}`,
			});

			const data = res.updateProviderInfo;
			console.log('updateProviderProfileInfo-data', data);

			if (data) {
				toast.success('Profile info updated', toastSuccessConfig);

				// update the provider info with that statement
				const newProvider = {
					...provider,
					professional_statement: data.professional_statement,
				};

				receiveProviderInfo(newProvider);

				setIsLoading(false);
				setOnboardStep(2);
			}
		} catch (err) {
			setIsLoading(false);
			console.log('updateProviderProfileInfo - err', err);
		}
	};

	return (
		<OnboardFormContainer
			style={{
				width: 'fit-content',
			}}
			center
		>
			<OnboardTitle
				style={{
					maxWidth: '600px',
				}}
			>
				Personalize Your Profile
			</OnboardTitle>

			<OnboardSubText>
				Enter a personal statement that prospective clients can view to learn more about
				you.
			</OnboardSubText>

			<Formik
				onSubmit={handleSubmit}
				autoComplete={'off'}
				validate={handleValidation}
				render={(formProps) => {
					console.log(formProps);
					return (
						<Form noValidate>
							<ColumnFlex alignCenter justifyCenter>
								<Dropzone
									onDrop={(acceptedFiles) => handleDroppedFile(acceptedFiles)}
								>
									{({ getRootProps, getInputProps }) => (
										<CustomAvatar
											height='120px'
											width='120px'
											margin='20px 0px 0 0'
											border={Colors.greenLightTeal}
											image_url={
												true ? (newImage ? newImageUrl : '') : image_url
											}
											{...getRootProps()}
										>
											<input {...getInputProps()} />
											{!newImage && <Icon icon={faFileUpload} size='2x' />}
										</CustomAvatar>
									)}
								</Dropzone>
								<FormItemsLength margin='20px 0 0 0'>
									<div
										style={{
											marginTop: '10px',
											textAlign: 'right',
											color: 'gray',
											fontSize: '12px',
										}}
									>
										{formProps.values.professional_statement
											? `${formProps.values.professional_statement.length}/500`
											: '0/500'}
									</div>

									<Field
										component={TextArea}
										name='professional_statement'
										label='Your Statement'
										type='text'
										rows='10'
										maxLength='500'
									/>
								</FormItemsLength>

								{isLoading ? (
									<PageLoaderView errorMessage='Something went wrong plz contact dev monkeys' />
								) : (
									<OnboardFormButton
										type={'submit'}
										valid={formProps.isValid}
										text='Next'
									>
										Next
									</OnboardFormButton>
								)}
							</ColumnFlex>
						</Form>
					);
				}}
			/>
		</OnboardFormContainer>
	);
};

export default Profile;
