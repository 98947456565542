import React, { useState, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { ColumnFlex, RowFlex } from 'basicStyles';
import Colors from 'colors';
import ProgressBar from '@ramonak/react-progress-bar';
import { useParams, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

/*
 * the general idea is that the parent will handle what care plan action to show
 * there are limited amount of current actions that can be shown based on the status of the creation/management of the CarePlan
 *
 * account with no CarePlan associated with it will have to create a CarePlan
 * * once the CarePlan is created the next step is to create a job post for this carePlan
 *
 * account with a CarePlan associated with it will have to manage the careplan
 * * managing  a carePlan is pretty straight forward we still are unsure about those designs
 *  * there are some fields that will be required when it comes to being able to set a job post t
 *
 * *
 */

import { receivePatientInfo } from 'reducers/patient';

import CarePlanHome from './CarePlanHome';

import CareProfile from './CareProfile';
import CareNeedsPage from '../CareNeedsPage';

import ClientInfo from './ClientInfo';
import CarePersonal from './CarePersonal';
import Logistics from './Logistics';

import { TOAST_SUCCESS_CONFIG, TOAST_ERROR_CONFIG } from 'constants';

import { receivePatientCarePlan } from 'reducers/patient';

import api from 'scripts/api';

export const CARE_PLAN_ACTIONS = {
	CARE_PLAN_HOME: 'CARE_PLAN_HOME',
	CLIENT_INFO: 'CLIENT_INFO',
	CARE_PERSONAL: 'CARE_PERSONAL',
	LOGISTICS: 'LOGISTICS',
	CARE_NEEDS: 'CARE_NEEDS',
};

const CarePlanParent = (props) => {
	const { patient, carePlan, history, receivePatientInfo } = props;

	const [carePlanAction, setCarePlanAction] = useState(CARE_PLAN_ACTIONS.CARE_PLAN_HOME);

	const [carePlanSet, setCarePlanSet] = useState(null);
	const [carePlanNeeds, setCarePlanNeeds] = useState(null);

	const historyting = useHistory();

	console.log('historyting', historyting);
	useEffect(() => {
		// check if carePlan is not empty in the patient key from props
		// check if there's a carePlan in the patient obj

		parseSets();
	}, [patient, carePlan]);

	useEffect(() => {
		// check the pahtname to properly show the correct carePlanAction
		checkSubRoute();
	}, [historyting]);
	// check if there is a subroute /careplan/whatever
	const checkSubRoute = () => {
		const subRoute = history.location.pathname.split('/')[2];

		switch (subRoute) {
			case CARE_PLAN_ACTIONS.CARE_NEEDS:
				setCarePlanAction(CARE_PLAN_ACTIONS.CARE_NEEDS);
				break;
			case CARE_PLAN_ACTIONS.LOGISTICS:
				setCarePlanAction(CARE_PLAN_ACTIONS.LOGISTICS);
				break;
			case CARE_PLAN_ACTIONS.CARE_PERSONAL:
				setCarePlanAction(CARE_PLAN_ACTIONS.CARE_PERSONAL);
				break;
			case CARE_PLAN_ACTIONS.CLIENT_INFO:
				setCarePlanAction(CARE_PLAN_ACTIONS.CLIENT_INFO);
				break;
			default:
				setCarePlanAction(CARE_PLAN_ACTIONS.CARE_PLAN_HOME);
		}
	};

	const parseSets = () => {
		if (carePlan == null) {
			return;
		}
		const carePlanSetData = carePlan.carePlanSet.setData;

		const carePlanNeedsData = carePlan.careNeedsSet.setData;

		const _carePlanSetData = carePlanSetData.map((item) => {
			return {
				...item,
				value: item.value
					? {
							...item.value,
							trait_value: JSON.parse(item.value.trait_value),
					  }
					: null,
			};
		});

		const _carePlanNeedsData = carePlanSetData.map((item) => {
			return {
				...item,
				value: item.value
					? {
							...item.value,
							trait_value: JSON.parse(item.value.trait_value),
					  }
					: null,
			};
		});

		setCarePlanNeeds({
			...carePlan.careNeedsSet,
			setData: _carePlanNeedsData,
		});
		setCarePlanSet({
			...carePlan.carePlanSet,
			setData: _carePlanSetData,
		});
	};

	// update the carePlanSet with the new values
	const updateSetTraits = async (traits) => {
		console.log('Mutating carePlanSetId', traits);

		const carePlanSetId = carePlan.carePlanSet.id;

		// remove any null values from traits

		const filteredTraits = traits.filter((trait) => trait.value !== null);
		try {
			const response = await api.graph({
				query: `mutation {
						updateSetTraits (
							set_id: ${carePlanSetId},
							traits: ${api.graphStringify(filteredTraits)},
						) {
							id
							value {
								trait_value
							}
						}
					}`,
			});

			console.log('Mutation response', response.updateSetTraits);

			if (response.updateSetTraits) {
				toast.success('Care Profile Updated', TOAST_SUCCESS_CONFIG);

				// update the redux state of the carePlan so everything is in sync

				const newCarePlanSet = response.updateSetTraits;

				const updatedCarePlan = {
					...carePlan,
					carePlanSet: {
						...carePlan.carePlanSet,
						setData: newCarePlanSet,
					},
				};

				receivePatientCarePlan(updatedCarePlan);

				if (carePlan.completed_care_plan) {
					// move to the next screen

					setCarePlanAction(CARE_PLAN_ACTIONS.CARE_PERSONAL);
				}
			}
		} catch (err) {
			toast.error('Care Profile could not be updated', TOAST_ERROR_CONFIG);
			console.log(err);
		}
	};

	useEffect(() => {
		history.push(`/careplan/${carePlanAction}`);
	}, [carePlanAction]);

	const moveToStep = (step) => {
		setCarePlanAction(CARE_PLAN_ACTIONS[step]);
	};

	const renderPages = () => {
		// check if carePlanSet is empty
		if (carePlanSet == null) {
			return <div>Loading...</div>;
		}
		switch (carePlanAction) {
			case CARE_PLAN_ACTIONS.CLIENT_INFO:
				return (
					<ClientInfo
						carePlanSet={carePlanSet}
						updateSetTraits={updateSetTraits}
						setCarePlanAction={setCarePlanAction}
						carePlan={carePlan}
					/>
				);
			case CARE_PLAN_ACTIONS.CARE_PERSONAL:
				return (
					<CarePersonal
						carePlanSet={carePlanSet}
						updateSetTraits={updateSetTraits}
						setCarePlanAction={setCarePlanAction}
						carePlan={carePlan}
					/>
				);
			case CARE_PLAN_ACTIONS.LOGISTICS:
				return (
					<Logistics
						carePlanSet={carePlanSet}
						updateSetTraits={updateSetTraits}
						setCarePlanAction={setCarePlanAction}
						carePlan={carePlan}
					/>
				);
			case CARE_PLAN_ACTIONS.CARE_NEEDS:
				return <CareNeedsPage />;
			case CARE_PLAN_ACTIONS.CARE_PLAN_HOME:
				return (
					<CarePlanHome
						patient={patient}
						carePlan={carePlan}
						moveToStep={moveToStep}
						receivePatientCarePlan={props.receivePatientCarePlan}
						history={history}
					/>
				);
		}
		//return <WelcomeToCarePlan patient={patient} moveToStep={moveToStep} carePlan={carePlan} />;
	};

	return (
		<ColumnFlex
			fullWidth
			padding='16px'
			style={{
				backgroundColor: Colors.theme.primary_background,
				minHeight: 'calc(100vh - 120px)',
			}}
			alignCenter
			justifyCenter
		>
			<>{renderPages()}</>
		</ColumnFlex>
	);
};

const mapStateToProps = ({ patient }) => {
	return {
		carePlan: patient.carePlan,
		patient: patient.patient,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		receivePatientCarePlan: (carePlan) => dispatch(receivePatientCarePlan(carePlan)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CarePlanParent);
