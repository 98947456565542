import React from 'react';

import { PrimaryButton, SecondaryButton } from '../../../corev2/Buttons';

import Avatar from '../components/Avatar';
import Name from '../components/Name';
import Rating from '../../Rating';
import InformationCard from '../components/InformationCard';
import Pricing from '../components/Pricing';

import { functions } from '../../utils';

import {
	StyledWrapper,
	StyledProfileContainer,
	StyledProfileWrapper,
	StyledPersonalInfoContainer,
	StyledPersonalInfoWrapper,
	StyledLocation,
	StyledGender,
	StyledDescription,
	StyledOfferButtonContainer,
	StyledReminderTextContainer,
	StyledReminderText,
	StyledInformationContainer,
	StyledInformationHeading,
	StyledTraitsContainer,
	StyledAbility,
	StyledTraitHeading,
	StyledTraitValue,
} from '../styles';

const CareProProfile = ({ carePro, chatHandler, traits }) => {
	const { displayName, gender, care_type, professional_statement, averageReview, hourly_rate } =
		carePro;

	return (
		<StyledWrapper>
			<StyledProfileContainer>
				<StyledProfileWrapper>
					<StyledPersonalInfoContainer>
						<Avatar />
						<StyledPersonalInfoWrapper>
							<Name>{`${displayName}`}</Name>

							<Rating rating={averageReview} />
							<StyledLocation>Lake Raymondport</StyledLocation>
							<StyledGender>
								{functions.capitalizeFirstLetter(gender)} |{' '}
								{care_type === 'hourly' ? 'Hourly' : 'Live In'}
							</StyledGender>
						</StyledPersonalInfoWrapper>
					</StyledPersonalInfoContainer>
					<StyledDescription>
						{professional_statement ? professional_statement : 'N/A'}
					</StyledDescription>
					<SecondaryButton size='small' onClick={() => chatHandler(carePro.id)}>
						Chat
					</SecondaryButton>
					<Pricing hourlyRate={hourly_rate} />
					<StyledOfferButtonContainer>
						<PrimaryButton size='small'>Send Offer</PrimaryButton>
					</StyledOfferButtonContainer>
					<StyledReminderTextContainer>
						<StyledReminderText>Make sure to confirm your schedule</StyledReminderText>
					</StyledReminderTextContainer>
				</StyledProfileWrapper>
				<StyledInformationContainer>
					<StyledInformationHeading>Information</StyledInformationHeading>
					<InformationCard
						heading='Professional Statement'
						information={professional_statement ? professional_statement : 'N/A'}
					/>
					<InformationCard
						heading='Certificate & Screenings'
						information='Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text
				Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text'
					/>
					{traits.length > 0 && (
						<StyledTraitsContainer>
							<StyledInformationHeading>Care Abilities</StyledInformationHeading>

							{traits.map((trait) => (
								<StyledAbility>
									<StyledTraitHeading>{trait.trait}</StyledTraitHeading>
									<StyledTraitValue>{trait.value}</StyledTraitValue>
								</StyledAbility>
							))}
						</StyledTraitsContainer>
					)}
				</StyledInformationContainer>
			</StyledProfileContainer>
		</StyledWrapper>
	);
};

export default CareProProfile;
