import React from 'react';
import styled from 'styled-components';
import is from 'styled-is';
import Colors from '../constants/colors';
import Text from './Text';

//cosnt StyledCheckboxContainer

const StyledCheckbox = styled.input``;

const StyledCheckBoxContainer = styled.div`
	width: 100%;
	padding-bottom: 10px;
`;

const StyledCheckboxInput = (props) => {
	const { type, name, value, icons, onChange, active, id, text } = props;

	return (
		<StyledCheckBoxContainer>
			<StyledCheckbox
				type={type}
				name={name}
				value={value}
				checked={active}
				onChange={onChange}
				id={id}
			/>
			<Text inline subtitle padding='0px 0px 0px 5px'>
				{text}
			</Text>
			<span>{icons()}</span>
		</StyledCheckBoxContainer>
	);
};

export default StyledCheckboxInput;
