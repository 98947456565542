import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from 'scripts/api';
import { isMobile } from 'react-device-detect';

import { CORE_JOB_POST_MODEL } from 'reducers/graphqlModelTypes';

import { PHWhiteCon } from '../ProviderHomeDashboard';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';

import { RowFlex, ColumnFlex } from 'basicStyles';

import { ButtonTing } from '../../provider/ProviderHomeLanding/CarePlanInfo';
import { TOAST_SUCCESS_CONFIG, TOAST_ERROR_CONFIG } from 'constants';

import Colors from 'colors';

const ApplyToJobPostPage = (props) => {
	const { provider } = props;
	const [jobPost, setJobPost] = useState(null);
	const { jobPostId } = useParams();

	const fetchJobPost = async () => {
		try {
			const jobPost = await api.graph({
				query: `{
					fetchSingleJobPost(job_post_id: ${jobPostId}) {
						${CORE_JOB_POST_MODEL}
					}
				}`,
			});

			if (jobPost.fetchSingleJobPost) {
				setJobPost(jobPost.fetchSingleJobPost);
			}
		} catch (err) {
			throw new Error(err);
		}
	};

	const applyToJobPost = async () => {
		try {
			const applyToJobPostRes = await api.graph({
				query: `mutation {
					applyToJobPost(
						job_post_id: ${jobPostId},
						provider_id: ${provider.id}
					){
						id
					}
				}`,
			});

			if (applyToJobPostRes.applyToJobPost) {
				toast.success('Applied to Job', TOAST_SUCCESS_CONFIG);
			}
		} catch (err) {
			toast.error('Error carePro applying to jobpost', TOAST_ERROR_CONFIG);
			console.log('err', err);
		}
	};

	useEffect(() => {
		if (jobPostId) fetchJobPost();
	}, [jobPostId]);
	console.log(jobPost);

	if (!jobPost) return <>Loading...</>;

	return (
		<PHWhiteCon
			style={{
				flex: 3,
				minWidth: isMobile ? '100%' : '440px',
				backgroundColor: 'white',
			}}
		>
			<Title subtitle color={Colors.greenLightTeal} margin='0 0 20px 0' fontWeight='700'>
				Apply to Job
			</Title>
			<RowFlex fullWidth gap='16px' justifyBetween>
				<ColumnFlex
					style={{
						borderRadius: '16px',
						backgroundColor: Colors.theme.dark_accent_background,
						padding: '20px',
					}}
					margin='10px 0 12px 0'
					alignCenter
				>
					<RowFlex fullWidth>
						<ColumnFlex gap='4px'>
							<Text midLarge fontStyle='thin'>
								Budget range
							</Text>
							<Text bold largeText color={'black'}>
								{`$${jobPost.budget_low} - $${jobPost.budget_high}`}
							</Text>
							<Text midLarge fontStyle='thin'>
								per hour
							</Text>
						</ColumnFlex>
					</RowFlex>
				</ColumnFlex>
				<ColumnFlex
					style={{
						flex: 1,
					}}
					center
					gap='8px'
				>
					<ButtonTing
						onClick={() => applyToJobPost()}
						style={{
							borderRadius: '32px',
							width: '180px',
							height: '50px',
							backgroundColor: Colors.primary.primary,
						}}
					>
						<Text fontWeight='500' cursor color={'white'} largeText>
							Apply
						</Text>
					</ButtonTing>
				</ColumnFlex>
			</RowFlex>
		</PHWhiteCon>
	);
};

const mapStateToProps = ({ provider }) => {
	return {
		provider: provider.provider,
	};
};

export default connect(mapStateToProps)(ApplyToJobPostPage);
