import React, { Fragment, Component, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { Section } from '../UserModel/UserModelBlocks';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import Colors from 'colors';
import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';

import Text from 'AppComp/Text';

import { FormItemsLength } from 'AppComp/provider/POnboard/OnboardViews';
import StyledTextInput, { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';
import { ActivityRadioInupt } from 'AppComp/patient/CarePlanInfo/CarePlanInfoViews';
import { CustomFormButton } from 'AppComp/patient/PatientCardCollection/PatientCardCollectionViews';

import { ValidateAttributeInput } from './AttributeTabValidation';
const toastErrorConfig = {
	containerId: 'basic',
	autoClose: false,
};

// this till will represent an individual tile, it should never self determine it's state it should soley send out the edit and react to the passedown state

const TemplateTraitTile = (props) => {
	const {
		id,
		active,
		attribute_trait_id,
		attributeTrait,
		deactivateTraitFromTemplate,
		traitTemplateId,
	} = props;
	const { label, description, trait_type } = attributeTrait;

	const handleClick = () => {
		deactivateTraitFromTemplate({
			trait_template_helper_id: attribute_trait_id,
			trait_template_id: traitTemplateId,
		});
	};
	return (
		<RowFlex
			padding='12px 16px'
			style={{
				width: '100%',
				backgroundColor: Colors.fullWhite,
				borderRadius: '8px',
				alignItems: 'center',
			}}
		>
			<ColumnFlex
				style={{
					width: '100%',
					backgroundColor: Colors.fullWhite,
					borderRadius: '8px',
				}}
				alignCenter
				spaceBetween
				margin='8px 0 0 0'
				padding='0px 16px 0 8px'
				gap='16px 0'
			>
				<RowFlex fullWidth spaceBetween>
					<Text midLarge margin='0 8px 0 0'>
						<span style={{ fontWeight: 'bold' }}>{label}:</span>{' '}
						{description || 'No description'}
					</Text>
				</RowFlex>
				<RowFlex fullWidth gap='16px'>
					<RowFlex alignCenter>
						<Text margin='0 8px 0 0'>
							<span style={{ fontWeight: 'bold' }}>Required:</span>{' '}
						</Text>
						<div
							onClick={() => handleClick()}
							style={{
								width: '20px',
								cursor: 'pointer',
								height: '20px',
								borderRadius: '20%',
								backgroundColor: active ? Colors.greenLightTeal : Colors.heartRed,
							}}
						/>
					</RowFlex>
					<Text margin='0 8px 0 0'>
						<span style={{ fontWeight: 'bold' }}>Type:</span> {trait_type}
					</Text>
					<Text margin='0 8px 0 0'>
						<span style={{ fontWeight: 'bold' }}>Trait ID:</span> {attributeTrait.id}
					</Text>
				</RowFlex>
			</ColumnFlex>
		</RowFlex>
	);
};

export default TemplateTraitTile;
