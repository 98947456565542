import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';
import { faCheck, faCheckCircle, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import Colors from 'colors';

import { ModelContainer } from 'AppComp/EventModel';
import Button from 'AppComp/Button';
import Text from 'AppComp/Text';
import { device } from 'AppComp/styled/StyledMediaQuery';
import Title from 'AppComp/Title';
import Icon from 'AppComp/Icon';
import { GreenCircleButton, OBButtonText } from '../WelcomePage/WelcomePageStyles';
import { toast } from 'react-toastify';
import UserOnboardSteps from '../UserOnboardSteps';

import { ColumnFlex, RowFlex } from 'basicStyles';
import { DropShadow } from '../../styled/Basic';

export const ListParentCon = styled(ColumnFlex)`
	padding: 16px 4px 4px 4px;
	height: auto;
	@media ${device.tablet} {
		padding: 0px 16px;
	}
`;

export const ListCon = styled.div`
	border-radius: 16px;
	background-color: ${Colors.grey};
	height: auto;
	width: 100%;
	padding: 6px 2px;
	flex-direction: column;
	display: flex;
	gap: 6px;

	@media ${device.tablet} {
		padding: 12px 8px;
	}
`;

export const ListItem_ = styled.div`
	background-color: #ffffff;
	border-radius: 32px;
	width: 100%;
	min-height: 62px;
	${DropShadow(2)}

	:hover {
		box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 4px 0 rgba(0, 0, 0, 0.3);
	}
	:active {
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 1px 3px 0 rgba(0, 0, 0, 0.3);
	}
`;

const logoURl = 'https://find-your-independents.s3.amazonaws.com/images/fyiLogoCropped.png';

const WelcomeTextCon = styled(ColumnFlex)`
	background-color: ${Colors.fullWhite};
	padding: 28px 16px 24px 16px;
	width: 100%;
	border-radius: 16px;
`;
export const ListItemSubItem = (props) => {
	const { completed, text, type } = props;
	return (
		<RowFlex style={{}} alignCenter justifyStart gap='16px'>
			<div
				style={{
					width: '20px',
					height: '20px',
					borderRadius: '6px',
					backgroundColor: '#D6D6D6',
					position: 'relative',
				}}
			>
				{props.completed && (
					<div
						style={{
							position: 'absolute',
							margin: 'auto',
							left: '-2px',
							top: '-4px',
						}}
					>
						<Icon size='lg' color={Colors.greenLightTeal} icon={faCheck} />
					</div>
				)}
			</div>
			{props.children}
		</RowFlex>
	);
};

// array of text
const ListTextTypes = ({ type, text }) => {
	if (type === 'link') {
		<Text
			underline
			pointer
			color={'#0000EE'}
			style={{
				textAlign: 'left',
			}}
		>
			{text}
		</Text>;
	}
	return (
		<Text
			style={{
				textAlign: 'left',
			}}
		>
			{text}
		</Text>
	);
};
export const ListItem = (props) => {
	const [isActive, setIsActiveStatus] = useState(false);

	return (
		<ListItem_>
			<ColumnFlex fullWidth center padding='4px 0px'>
				<RowFlex
					style={{ height: '50px' }}
					onClick={() => setIsActiveStatus(!isActive)}
					fullWidth
					alignCenter
					padding='0 0 0 12px'
					gap='12px'
				>
					<div
						style={{
							width: '28px',
							height: '28px',
							borderRadius: '50%',
							//backgroundColor: props.completed ? Colors.greenLightTeal : '#D6D6D6',
							backgroundColor: '#D6D6D6',

							position: 'relative',
						}}
					>
						{props.completed && (
							<div
								style={{
									position: 'absolute',
									margin: 'auto',
									left: '-8px',
									top: '-12px',
								}}
							>
								<Icon size='3x' color={Colors.greenLightTeal} icon={faCheck} />
							</div>
						)}
					</div>
					<RowFlex alignCenter padding='0 16px 0 0' fullWidth spaceBetween>
						<RowFlex gap='2px'>
							<Title color={Colors.darkBlue} trititle bold>
								{props.title}
							</Title>
							<Text
								subtitle
								color={Colors.darkBlue}
							>{`(${props.amountOfTask})`}</Text>
						</RowFlex>
						{!isActive && <Icon size='1x' color={'black'} icon={faEllipsisH} />}
					</RowFlex>
				</RowFlex>
				<AnimateHeight
					style={{
						width: '100%',
					}}
					duration={500}
					height={isActive ? 'auto' : 0}
				>
					<ColumnFlex fullWidth justifyStart gap='8px' padding='10px 0 16px 32px'>
						{props.children}
					</ColumnFlex>
				</AnimateHeight>
			</ColumnFlex>
		</ListItem_>
	);
};

export const TEMP_DATA = [
	{
		title: 'Verify your email',
		subItems: [
			{
				text: 'Check your email for a verification link',
			},
		],
	},
	{
		title: 'Fill Out Your Care Plan',
		subItems: [
			{
				text: 'Fill out personal Info for who needs care',
				verifyKey: 'patient_personal_details_submitted',
			},
			{
				text: 'Fill out where the care is needed',
				verifyKey: 'patient_location_details_submitted',
			},
		],
	},
	{
		title: 'Find the right fit for you',
		subItems: [
			{
				text: 'Talk to an admin about a specfic provider',
			},
		],
	},

	{
		title: 'Add a Payment Source',
		subItems: [
			{
				text: 'Add a card to your account',
			},
		],
	},
];

export const UserRegisterWelcomeToast = (props) => {
	const handleClick = () => {
		props.closeToast();

		toast(({ closeToast }) => <UserOnboardSteps closeToast={closeToast} />, {
			autoClose: false,
			hideProgressBar: true,
			style: { padding: '2px' },
			draggable: false,
			closeOnClick: false,
			closeButton: true,
			position: 'top-right',
			containerId: 'LargeToast',
			toastId: '',
		});
	};
	return (
		<ModelContainer>
			<ColumnFlex fullWidth justifyStart alignCenter gap='12px' padding='4px 0px 8px 0px'>
				<div
					style={{
						backgroundImage: `url("${logoURl}")`,
						backgroundRepeat: 'no-repeat',
						height: '60px',
						width: '60px',
						backgroundSize: 'contain',
						backgroundPosition: 'center center',
						cursor: 'pointer',
					}}
				/>
				<ListParentCon fullWidth>
					<ListCon>
						<ColumnFlex gap='8px' padding='0px 0' fullWidth center>
							<Title color={Colors.greenLightTeal} bold subtitle>
								Welcome Home
							</Title>
							<WelcomeTextCon center gap='20px'>
								<ColumnFlex
									gap='16px'
									style={{
										width: '300px',
									}}
								>
									<Text
										style={{
											textAlign: 'center',
										}}
									>
										We are so excited to have you on board! We are here to help
										you throughout your journey.
									</Text>
								</ColumnFlex>

								<GreenCircleButton onClick={() => handleClick()}>
									<Text fontWeight='600' curosr color={Colors.fullWhite} midLarge>
										Get Started
									</Text>
								</GreenCircleButton>
								<GreenCircleButton onClick={() => handleClick()}>
									<Text fontWeight='600' curosr color={Colors.fullWhite} midLarge>
										Exit
									</Text>
								</GreenCircleButton>
							</WelcomeTextCon>
						</ColumnFlex>
					</ListCon>
				</ListParentCon>
			</ColumnFlex>
		</ModelContainer>
	);
};
