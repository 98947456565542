import styled from 'styled-components';

import { colors, mediaQueries } from '../../../../../corev2/utils';

export const StyledJobStats = styled.div`
	width: 100%;
	padding: 15px;
	border: 1px solid ${colors.forms.formInputBorderColor};
	border-radius: 8px;
	background: ${colors.white};
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	gap: 10px;

	@media ${mediaQueries.tablet} {
		flex-direction: row;
		padding: 20px;
		justify-content: space-between;
		flex-wrap: nowrap;
		gap: 10px;
	}
`;
