//CarePlanDetailViews.jsx
import React from 'react';
import styled from 'styled-components';
import is from 'styled-is';

import { ColumnFlex } from 'basicStyles';
import Colors from 'colors';

export const CarePlanSection = styled.div`
	background: ${Colors.fullWhite};

	border-radius: 12px;
	width: 100%;
	height: ${({ height }) => height || '90vh'};
	margin-bottom: 16px;
	position: relative;

	${is('hideCalHeader')`
		.mbsc-cal-hdr {
			display: none !important;
		}
	`}
`;

export const CareplanDetailFormView = (props) => {
	const { children, height, hideCalHeader } = props;

	return (
		<CarePlanSection height={height} hideCalHeader={hideCalHeader}>
			{children}
		</CarePlanSection>
	);
};

export const ContentContainer = styled(ColumnFlex)`
	width: 100%;
	max-width: 1140px;
	position: relative;
	z-index: 1000;
`;

//Green Container
export const GreenCon = styled.div`
	width: 100%;
	height: 40vh;
	background-color: ${Colors.primary.primary};
	position: absolute;
	z-index: 1;
	top: 0;
`;

export const RelativeColumn = styled(ColumnFlex)`
	position: relative;
`;
