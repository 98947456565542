import React, { useState } from 'react';
import Title from '../../Title';
import { Formik, Form, Field } from 'formik';
import Colors from '../../../constants/colors';
import {
	MARGIN_TITLE,
	FormItemsLength,
	FORM_ITEM_LENGTH,
	OBFormButtonText,
	OBBasicCon,
	OBSubText,
} from './OnboardViews';
import { ColumnFlex } from '../../styled/Basic';
import Icon from 'AppComp/Icon';

import { validateCareType } from './validation';
import { faHandHoldingHeart } from '@fortawesome/free-solid-svg-icons';

import { StyledTextSelectInputV2, FormatTextInput } from 'AppComp/styled/StyledTextInput';
import { BackText } from '../../patient/Onboard/OnboardViews';

const CARE_TYPE = [
	{
		value: '',
		label: '',
		rateLabel: 'Select a Care Type',
	},
	{
		value: 'hourly',
		label: 'Hourly',
		rateLabel: 'Hourly Rate',
	},
	{
		value: 'live_in',
		label: 'Live In',
		rateLabel: 'Live In Rate',
	},
	{
		value: 'both',
		label: 'Both',
		rateLabel: 'Rate',
	},
];

const POB2CareType = (props) => {
	const [isValidOverride, setValidOverride] = useState(false);

	const handleSubmit = (values, form) => {
		console.log('Values before validation:', values);
		// Handle validation logic from validateCareType
		const errors = validateCareType(values);

		// Additional validation for 0 or "$0" values
		if (
			(values.careType === 'hourly' &&
				(values.hourly_rate === 0 || values.hourly_rate === '')) ||
			(values.careType === 'live_in' &&
				(values.live_in_rate === 0 || values.live_in_rate === '')) ||
			(values.careType === 'both' &&
				(values.hourly_rate === 0 || values.hourly_rate === '') &&
				(values.live_in_rate === 0 || values.live_in_rate === ''))
		) {
			form.setErrors({
				...errors,
				hourly_rate: 'Rate must be greater than 0',
				live_in_rate: 'Rate must be greater than 0',
			});

			// Set isValid to false when tarifas are 0
			form.setTouched({ hourly_rate: true, live_in_rate: true });
			form.setValues({
				...values,
				hourly_rate: values.hourly_rate === 0 ? '' : values.hourly_rate,
				live_in_rate: values.live_in_rate === 0 ? '' : values.live_in_rate,
			});

			return;
		}

		// Check if there are any errors
		if (Object.keys(errors).length > 0) {
			// If there are errors, set the form errors and return without proceeding
			form.setErrors(errors);
			return;
		}

		// Remove the $ sign and convert to number
		if (values.hourly_rate !== null && values.hourly_rate !== undefined) {
			values.hourly_rate = values.hourly_rate.replace('$', '');
			values.hourly_rate = Number(values.hourly_rate);
			// If the value is 0, set the field to null
			if (values.hourly_rate === 0) {
				values.hourly_rate = null;
			} else {
				// Convert to cents
				values.hourly_rate = values.hourly_rate * 100;
			}
		}

		if (values.live_in_rate !== null && values.live_in_rate !== undefined) {
			values.live_in_rate = values.live_in_rate.replace('$', '');
			values.live_in_rate = Number(values.live_in_rate);
			// If the value is 0, set the field to null
			if (values.live_in_rate === 0) {
				values.live_in_rate = null;
			} else {
				// Convert to cents
				values.live_in_rate = values.live_in_rate * 100;
			}
		}

		const newState = {
			...props.state,
			care_type: values.careType,
			hourlyRate: values.hourly_rate || 0,
			liveInRate: values.live_in_rate || 0,
		};

		props._UPS(newState);
		props.ChangeLocation(2);
	};

	const handleInitValue = () => {
		// check if props.state care_type & rate are not null
		if (props.state.care_type && props.state.rate) {
			if (!isValidOverride) {
				setValidOverride(true);
			}

			const initialValues = {
				careType: props.state.care_type,
				rate: props.state.rate,
			};

			if (props.state.care_type === 'both') {
				initialValues.hourly_rate = props.state.hourlyRate || '';
				initialValues.live_in_rate = props.state.liveInRate || '';
			}

			return initialValues;
		} else {
			return {};
		}
	};

	return (
		<OBBasicCon
			style={{
				maxWidth: '420px',
			}}
		>
			{/* <Icon size='3x' color={Colors.greenLightTeal} icon={faHandHoldingHeart} /> */}
			<Title margin={MARGIN_TITLE} bold subtitle>
				Tell us about your self
			</Title>
			<OBSubText text='What type of care and pay are you looking for?' />
			<Formik
				onSubmit={handleSubmit}
				autoComplete={'off'}
				validate={validateCareType}
				enableReinitialize={false}
				initialValues={handleInitValue()}
				render={(formProps) => {
					console.log(formProps);
					return (
						<Form noValidate>
							<ColumnFlex
								padding='20px 0 0 0'
								alignCenter
								justifyCenter
								fullWidth
								fullHeight
							>
								<FormItemsLength width={FORM_ITEM_LENGTH}>
									<Field
										name='careType'
										component={StyledTextSelectInputV2}
										label='Care Type'
										options={CARE_TYPE}
									/>
								</FormItemsLength>

								{(formProps.values.careType === CARE_TYPE[3].value ||
									formProps.values.careType === CARE_TYPE[1].value) && (
									<FormItemsLength width={FORM_ITEM_LENGTH}>
										<Field
											component={FormatTextInput}
											name='hourly_rate'
											label={'Hourly Rate'}
											type='currency'
											bigForm={formProps}
										/>
									</FormItemsLength>
								)}

								{(formProps.values.careType === CARE_TYPE[3].value ||
									formProps.values.careType === CARE_TYPE[2].value) && (
									<FormItemsLength width={FORM_ITEM_LENGTH}>
										<Field
											component={FormatTextInput}
											name='live_in_rate'
											label={'Live In Rate'}
											type='currency'
											bigForm={formProps}
										/>
									</FormItemsLength>
								)}
								<OBFormButtonText
									backgroundColor={Colors.primary.primary}
									type='submit'
									valid={isValidOverride || formProps.isValid}
									text='Next'
								/>
							</ColumnFlex>
						</Form>
					);
				}}
			/>
			<BackText onClick={() => props.ChangeLocation(0)}>Back</BackText>
		</OBBasicCon>
	);
};

export default POB2CareType;
