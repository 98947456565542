import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { Field } from 'formik';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';

import { RowFlex, DropShadow } from 'basicStyles';

const CustomForm = styled.form`
	${DropShadow(2)}
	background-color: white;
	transition: all ease-in-out 0.3s;
	margin-block-end: 0;
	padding: 12px;
`;

const Input = styled.input`
	width: 100%;
`;

const CustomFormButton = styled.button`
	border: none;
	background-color: white;
	font-size: 12px;
`;

const LoaderCon = styled.div`
	height: 30px;
	width: 30px;
	margin-left: 8px;
`;
const CustomTextInput = (props) => {
	const { field, placeholder, maxLength, form, updateTextLength } = props;

	return (
		<Input
			{...field}
			onChange={(event) => {
				console.log('event', event);

				const { value } = event.target;
				const length = value.length;
				updateTextLength(length);
				form.setFieldValue(field.name, value, true);
			}}
			placeholder={placeholder}
			autocomplete='nope'
			maxLength={maxLength}
		/>
	);
};

const PatientChatHandlerMessage = (props) => {
	const { form, updateTextLength, textLength } = props;

	return (
		<CustomForm id='msg-tile' noValidate onSubmit={form.handleSubmit} autocomplete='off'>
			<RowFlex alignItems='center'>
				<Field
					maxLength='140'
					updateTextLength={updateTextLength}
					name='text'
					component={CustomTextInput}
					placeholder='Type & Press enter'
				/>
				<CustomFormButton type='submit'>
					<LoaderCon>
						<CircularProgressbarWithChildren
							strokeWidth={7}
							styles={buildStyles({})}
							value={(textLength / 140) * 100}
						>
							{textLength !== 0.1 ? `${textLength}` : ''}
						</CircularProgressbarWithChildren>
					</LoaderCon>
				</CustomFormButton>
			</RowFlex>
		</CustomForm>
	);
};

export default PatientChatHandlerMessage;
