import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';

import {
	updatePatient,
	logoutUser,
	checkUserSession,
	deletePaymentMethod,
	receivePaymentMethods,
	updatePatietnDefaultPaymentMethod,
	fetchUserSavedPayments,
} from 'reducers/patient';
import { showInfoModel, toggleChangePasswordOverlay } from 'reducers/environment';
import Colors from 'colors';

import PageLoaderView from 'AppComp/PageLoaderView';
import Title from 'AppComp/Title';
import Scroll from 'AppComp/Scroll';
import Button from 'AppComp/Button';

import BasicOverlayWrapper from 'AppComp/BasicOverlayWrapper';
import InvoiceExportModel from 'AppComp/InvoiceExportModel';

import { InfoButton } from 'AppComp/Info';

import PatientSetting from '../PatientSetting';

import PatientCardCollection from '../PatientCardCollection';

import { RelativeColumn, CareplanDetailFormView } from '../CarePlanDetail/CarePlanDetailView';
import { WebsiteNav, BasicChildContainer } from '../WelcomePage/WelcomePageViews';

const PatientProfileHandler = (props) => {
	useEffect(() => {
		props.fetchUserSavedPayments();
	}, []);
	const {
		checkUserSession,
		showInfoModel,
		patient,
		updatePatient,
		logoutUser,
		toggleChangePasswordOverlay,
		savedPaymentMethods,
		deletePaymentMethod,
		receivePaymentMethods,
		updatePatietnDefaultPaymentMethod,
		history,
		toggleOnboardOverlay,
	} = props;

	const [showExportInvoiceOverlay, updateExportInvoiceOverlay] = useState(false);

	const renderInfo = () => {
		showInfoModel({
			show: true,
			title: 'View Your Profile',
			text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Iam in altera philosophiae parte. Propter nos enim illam, non propter eam nosmet ipsos diligimus. Non est enim vitium in oratione solum, sed etiam in moribus. Non enim quaero quid verum, sed quid cuique dicendum sit.',
		});
	};

	if (patient == null) {
	}
	return (
		<RelativeColumn
			style={{
				backgroundColor: Colors.theme.primary_background,
			}}
			flex
			fullWidth
			alignCenter
		>
			<BasicOverlayWrapper
				status={showExportInvoiceOverlay}
				handleExitClick={() => updateExportInvoiceOverlay(false)}
				appear
			>
				<InvoiceExportModel type='patient' />
			</BasicOverlayWrapper>
			{/* <InfoButton onClick={() => renderInfo()} /> */}

			<Scroll idName='PatientsSearchPage' />

			<BasicChildContainer>
				<CareplanDetailFormView height='auto'>
					{patient !== null && patient.id ? (
						<>
							<PatientSetting
								receivePaymentMethods={receivePaymentMethods}
								patient={patient}
								updatePatient={updatePatient}
								logoutUser={logoutUser}
								savedPaymentMethods={savedPaymentMethods}
								toggleChangePasswordOverlay={toggleChangePasswordOverlay}
							/>
						</>
					) : (
						<PageLoaderView errorMessage='Not signed in' />
					)}
				</CareplanDetailFormView>
			</BasicChildContainer>
			<BasicChildContainer>
				<CareplanDetailFormView height='auto'>
					{patient !== null && patient.id ? (
						<>
							<PatientCardCollection
								savedPaymentMethods={savedPaymentMethods}
								updatePatient={updatePatient}
								patient={patient}
								deletePaymentMethod={deletePaymentMethod}
								updatePatietnDefaultPaymentMethod={
									updatePatietnDefaultPaymentMethod
								}
							/>
						</>
					) : (
						<PageLoaderView errorMessage='Not signed in' />
					)}
				</CareplanDetailFormView>

				{/* <CareplanDetailFormView height={'auto'}>
						<PaymentExport />
					</CareplanDetailFormView> */}
			</BasicChildContainer>
			{patient !== null && patient.id && (
				<>
					<Button
						onClick={() => updateExportInvoiceOverlay(true)}
						backgroundColor={Colors.greenLightTeal}
						margin='16px'
						width='180px'
					>
						Export Invoice Data
					</Button>
					<Button
						onClick={() => toggleChangePasswordOverlay(true)}
						backgroundColor={Colors.blue}
						margin='16px'
						width='180px'
					>
						Change Password
					</Button>
					<Button
						onClick={() => logoutUser()}
						backgroundColor={Colors.red}
						margin='16px'
						width='180px'
					>
						Log Out
					</Button>
				</>
			)}
		</RelativeColumn>
	);
};

const mapStateToProps = ({ patient }) => {
	return {
		carePlan: patient.carePlan,
		patient: patient.patient,
		savedPaymentMethods: patient.savedPaymentMethods,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		updatePatient: (params) => dispatch(updatePatient(params)),
		fetchUserSavedPayments: () => dispatch(fetchUserSavedPayments()),
		logoutUser: () => dispatch(logoutUser()),
		checkUserSession: () => dispatch(checkUserSession()),
		showInfoModel: (info) => dispatch(showInfoModel(info)),
		toggleChangePasswordOverlay: (status) => dispatch(toggleChangePasswordOverlay(status)),
		deletePaymentMethod: (params) => dispatch(deletePaymentMethod(params)),
		receivePaymentMethods: (paymentMethod) => dispatch(receivePaymentMethods(paymentMethod)),
		updatePatietnDefaultPaymentMethod: (params) =>
			dispatch(updatePatietnDefaultPaymentMethod(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientProfileHandler);

/*
 * So this profile page is going to be split into two section a top level
 * - the top level will consit of the schedule just for viewing what you have upcoming
 * the second section will consit of updating any personal infomation
 */
