import styled from 'styled-components';

export const StyledLoginPage = styled.div`
	display: flex;
	flex-direction: column;

	width: fit-content;
	padding: 15px;
	margin: auto;

	text-align: center;
`;
