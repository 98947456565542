import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const applyToJobPost = async ({ jobPostId, careProId }) => {
	try {
		const data = await api.graph({
			query: `mutation {
					applyToJobPost(
						job_post_id: ${jobPostId},
						provider_id: ${careProId}
					){
						id
					}
				}`,
		});

		return data.applyToJobPost;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useApplyToJobPostMutation = () => {
	return useMutation({
		mutationKey: ['applyToJobPost'],
		mutationFn: (values) => {
			return applyToJobPost(values);
		},
	});
};
