import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { push } from 'connected-react-router';

import { ColumnFlex, RowFlex } from 'basicStyles';

import Colors from 'colors';

import Text from 'AppComp/Text';
import Button from 'AppComp/Button';

import NavSelector, { navItemsModifier } from '../../core/NavSelector';

import { StyledContainer } from './styles';

import api from 'scripts/api';

import { TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG } from 'constants';

import { PROVIDER_MODEL_V2, CORE_JOB_POST_MODEL, CORE_JOB_MODEL } from 'reducers/graphqlModelTypes';

/* 
	the jonbs handler should show the current jobs you have listed and the ability to start a new job
*/

import { JobPostTile } from './JobsView';

import JobPostTileTing from './JobPostTile';

import Tiles from '../../core/Tiles';

export const NAV_ITEMS = {
	jobPosts: {
		text: 'Job Posts',
		stat: 0,
		active: true,
	},
	jobs: {
		text: 'Jobs',
		stat: 0,
		active: false,
	},
	offers: {
		text: 'Offers',
		stat: 0,
		active: false,
	},
	rejected: {
		text: 'Rejected',
		stat: 0,
		active: false,
	},
};

const JobsHandler = (props) => {
	const { carePlan, conversations, pushToChat } = props;
	const [jobs, setJobs] = useState([]);
	const [offers, setOffers] = useState([]);
	const [jobPosts, setJobPosts] = useState([]);
	const [rejectedJobs, setRejectedJobs] = useState([]);

	//state for nav items
	const [navItems, setNavItems] = useState(NAV_ITEMS);

	const navSelectorHandler = (item, modifier, value) => {
		const updatedNavItems = navItemsModifier(navItems, item, modifier, value);

		setNavItems(updatedNavItems);
	};

	useEffect(() => {
		if (carePlan) {
			fetchCarePlanJobPosts();
			fetchCarePlanJobs();
		}
	}, [carePlan]);

	useEffect(() => {
		const offered = offers.filter(
			(job) => job.status === 'offer' && job.offer_status === 'offered'
		);
		const stats = {
			jobPosts: jobPosts.length,
			jobs: jobs.length,
			offers: offered.length,
			rejected: rejectedJobs.length,
		};

		const updatedNavItems = navItemsModifier(navItems, null, 'stat', stats);
		setNavItems(updatedNavItems);
	}, [jobPosts, jobs, offers]);

	const history = useHistory();

	const handleCreateJobPost = async () => {
		console.log('handleCreateJobPost');
		try {
			// create a mutation
			const { id } = carePlan;
			const registry_id = 1; // Fetch registry_id from patient
			const createJobRes = await api.graph({
				query: `mutation {
					createJobPost(care_plan_id: ${id}, registry_id: ${registry_id}) {
						${CORE_JOB_POST_MODEL}
					}
				}`,
			});

			if (createJobRes.createJobPost) {
				toast.success('Job post created', TOAST_SUCCESS_CONFIG);
				fetchCarePlanJobPosts();
			}
			// go to this jobview
		} catch (err) {
			toast.error('Error creating job post', TOAST_ERROR_CONFIG);

			console.error(err);
		}
	};

	const fetchCarePlanJobPosts = async () => {
		const { id } = props.carePlan;
		try {
			const fetchCarePlanJobPosts = await api.graph({
				query: `{
					fetchCarePlanJobPosts(care_plan_id: ${id}) {
						${CORE_JOB_POST_MODEL}
					}
				}`,
			});

			console.log('fetchCarePlanJobPosts', fetchCarePlanJobPosts);

			if (fetchCarePlanJobPosts.fetchCarePlanJobPosts) {
				if (fetchCarePlanJobPosts.fetchCarePlanJobPosts.length == 0) {
					handleCreateJobPost();
				} else {
					setJobPosts(fetchCarePlanJobPosts.fetchCarePlanJobPosts);
				}
			}
		} catch (err) {
			console.error(err);

			toast.error('Error fetching job posts', TOAST_ERROR_CONFIG);
			return;
		}
	};

	const fetchCarePlanJobs = async () => {
		const { id } = props.carePlan;
		try {
			const fetchCarePlanJobs = await api.graph({
				query: `{
					fetchCarePlanJobs(care_plan_id: ${id}) {
						${CORE_JOB_MODEL}

						provider {
							${PROVIDER_MODEL_V2}
						},
					}
				}`,
			});
			console.log('fetchCarePlanJobs', fetchCarePlanJobs);
			if (fetchCarePlanJobs.fetchCarePlanJobs) {
				const carePlanJobs = fetchCarePlanJobs.fetchCarePlanJobs;
				const carePlanActiveJobs = carePlanJobs.filter(
					(job) => job.status === 'job' && job.job_status === 'active'
				);
				const carePlanJobOffers = carePlanJobs.filter(
					(job) => job.status === 'offer' && job.offer_status === 'offered'
				);

				const rejectedJobs = carePlanJobs.filter(
					(job) => job.status === 'offer' && job.offer_status === 'rejected'
				);

				setRejectedJobs(rejectedJobs);
				setJobs(carePlanActiveJobs);
				setOffers(carePlanJobOffers);
			}
		} catch (err) {
			console.error(err);
			toast.error('Error fetching job posts', TOAST_ERROR_CONFIG);
			return;
		}
	};

	const careProClickHandler = (jobPostId, careProId) => {
		history.push('/jobPost/' + jobPostId + '/carePro/' + careProId);
	};

	const handleLinkToChat = async (careProId) => {
		try {
			// this needs an extra handler since there is a chance the user won't have a conversation with the CarePro yet

			// check existing conversations and find the one with the carePro
			const conversation = conversations.find(
				(convo) => convo.provider && convo.provider.id === careProId
			);

			if (conversation) {
				pushToChat(conversation.id);
			} else {
				// ensure user (alias patient) is logged in
				if (patient === null) {
					return;
				}

				userContactCarePro({
					careProId: careProId,
					userId: patient.id,
				});
			}
		} catch (err) {
			console.log('err', err);
		}
	};

	if (jobPosts.length === 0) return null;
	return (
		<ColumnFlex
			fullWidth
			padding='16px'
			style={{
				backgroundColor: Colors.theme.primary_background,
				minHeight: 'calc(100vh - 120px)',
			}}
			alignCenter
		>
			<RowFlex
				wrap
				fullWidth
				center
				gap='16px'
				style={{
					flex: 1,
					maxWidth: '868px',
				}}
				columnMobile
			>
				<NavSelector items={navItems} navSelectorHandler={navSelectorHandler} />
				<Button
					backgroundColor={Colors.primary.primary}
					width='300px'
					height='70px'
					margin='0'
					borderRadius='32px'
					onClick={() => history.push('/createjob')}
					style={{
						boxShadow: 'none',
					}}
				>
					<Text fontWeight='500' color='white' largeText>
						Create New Job Post
					</Text>
				</Button>
				<StyledContainer>
					{navItems.jobPosts.active && (
						<ColumnFlex
							fullWidth
							center
							alignCenter
							gap='16px'
							style={{
								flex: 1,
								marginTop: '1rem',
							}}
						>
							<RowFlex gap='8px' center fullWidth wrap>
								{jobPosts.map((jobPost) => (
									<JobPostTileTing
										key={jobPost.id}
										{...jobPost}
										onClick={(type) => history.push(`/jobpost/${jobPost.id}`)}
									/>
								))}
							</RowFlex>
						</ColumnFlex>
					)}

					{navItems.jobs.active && (
						<>
							{jobs.length > 0 ? (
								jobs.map((job) => {
									return (
										<Tiles.JobTile
											key={job.id}
											buttonText='Chat'
											{...job.provider}
											onSelectCarePro={(carePro) => {
												handleLinkToChat(carePro.id);
											}}
											careProClickHandler={() =>
												careProClickHandler(
													job.job_post_id,
													job.provider_id
												)
											}
										/>
									);
								})
							) : (
								<p>No jobs found.</p>
							)}
						</>
					)}

					{navItems.offers.active && (
						<>
							{offers.length > 0 ? (
								offers
									.filter(
										(job) =>
											job.status === 'offer' && job.offer_status === 'offered'
									)
									.map((job) => {
										return (
											<Tiles.OfferTile
												key={job.id}
												buttonText='Chat'
												{...job.provider}
												onSelectCarePro={(carePro) => {
													handleLinkToChat(carePro.id);
												}}
												careProClickHandler={() =>
													careProClickHandler(
														job.job_post_id,
														job.provider_id
													)
												}
											/>
										);
									})
							) : (
								<p> No offers found.</p>
							)}
						</>
					)}
				</StyledContainer>
			</RowFlex>
		</ColumnFlex>
	);
};

const mapStateToProps = ({ patient }) => {
	return {
		patient: patient.patient,
		carePlan: patient.carePlan,
		conversations: patient.conversations,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		pushToChat: (conversationId) => dispatch(push(`/chat/${conversationId}`)),
		userContactCarePro: (params) => dispatch(userContactCarePro(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(JobsHandler);
