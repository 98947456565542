import React, { useLayoutEffect } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

import CarePlanOnboardingPage, {
	RelationshipPage as RelationshipOnboardingPage,
	InfoPage as InfoOnboardingPage,
	PersonalPage as PersonalOnboardingPage,
	LogisticsPage as LogisticsOnboardingPage,
	CareNeedsPage as CareNeedsOnboardingPage,
} from '../pages/CarePlanOnboardingPage';

import CarePlanManagementPage, {
	InfoPage as InfoManagementPage,
	PersonalPage as PersonalManagementPage,
	LogisticsPage as LogisticsManagementPage,
	CareNeedsPage as CareNeedsManagementPage,
} from '../pages/CarePlanManagementPage';

import { carePlanRoutes } from './constants';
import { useAuth } from '../../core/hooks/useAuth';

const CarePlanRoutes = () => {
	const history = useHistory();
	const { hasCompletedPlatformOnboarding } = useAuth();

	useLayoutEffect(() => {
		if (location.pathname === carePlanRoutes.home) {
			if (hasCompletedPlatformOnboarding) {
				history.replace(carePlanRoutes.management.home);
			} else {
				history.replace(carePlanRoutes.onboarding.home);
			}
		}
	}, [hasCompletedPlatformOnboarding, history, location.pathname]);

	return (
		<Switch>
			<Route path={carePlanRoutes.management.info} component={InfoManagementPage} />
			<Route path={carePlanRoutes.management.personal} component={PersonalManagementPage} />
			<Route path={carePlanRoutes.management.logistics} component={LogisticsManagementPage} />
			<Route path={carePlanRoutes.management.careNeeds} component={CareNeedsManagementPage} />

			<Route path={carePlanRoutes.management.home} component={CarePlanManagementPage} exact />

			<Route path={carePlanRoutes.onboarding.home}>
				<CarePlanOnboardingPage>
					<Switch>
						<Route
							path={carePlanRoutes.onboarding.relationship}
							component={RelationshipOnboardingPage}
						/>
						<Route
							path={carePlanRoutes.onboarding.info}
							component={InfoOnboardingPage}
						/>
						<Route
							path={carePlanRoutes.onboarding.personal}
							component={PersonalOnboardingPage}
						/>
						<Route
							path={carePlanRoutes.onboarding.logistics}
							component={LogisticsOnboardingPage}
						/>
						<Route
							path={carePlanRoutes.onboarding.careNeeds}
							component={CareNeedsOnboardingPage}
						/>
					</Switch>
				</CarePlanOnboardingPage>
			</Route>
		</Switch>
	);
};

export default CarePlanRoutes;
