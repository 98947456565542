import React, { useState } from 'react';

import styled from 'styled-components';
import Color from 'colors';

import ShiftAdl from './ShiftAdl';

import ShiftNotes from './ShiftNotes';

import { ParentContainer, PHWhiteCon, PHDColumn } from 'AppComp/provider/ProviderHomeDashboard';

import { ColumnFlex, RowFlex } from 'AppComp/styled/Basic';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';

import Colors from 'colors';
import { isMobile } from 'react-device-detect';

import Button from 'AppComp/Button';

import { TextInfo } from 'AppComp/admin/UserModel/UserModelBlocks';

import TimerText from 'AppComp/TimerText';

import AnimateHeight from 'react-animate-height';
import Icon from 'AppComp/Icon';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { Hearts } from 'react-loader-spinner';
import 'aws-sdk/dist/aws-sdk';
const AWS = window.AWS;

import { ShiftSignatureCollection } from './ShiftViews';
import { StyledDateTimeInput } from 'AppComp/core/Forms/TimeInput/styles';
/* 
 - so the general gist is that we have a overall view that the user is going to mianly interact with when it comes to the status of the shift
 - they will clock in and out here
 - the view will udpate
 - they will collect the signature
 - they will confirm the time stamps of start and stop 
 - - they should be able to updatte the start and stop to show the right view 
*/

const ShiftTimeControl = (props) => {
	//console.log('shiftimecontrol', props);

	const [startTime, setStartTime] = useState(null);
	const [endTime, setEndTime] = useState(null);

	const {
		shift,
		handleCancelShift,
		handleEndShift,
		handleClockIn,
		handleUpdateLocalShift,
		handleUpdateShift,
		handleSubmitShift,
		handleSuccesRequest,
		loadingClock,
		deselectShift,
	} = props;

	const renderTotalTimeElasped = () => {
		if (shift.clock_in_time && shift.clock_out_time) {
			// get the difference between clock_in_time and clock_out_time
			const diff = new Date(shift.clock_out_time) - new Date(shift.clock_in_time);
			const minutes = Math.floor(diff / 1000 / 60);
			const hours = Math.floor(minutes / 60);
			const days = Math.floor(hours / 24);

			const time = `${days} days, ${hours} hours, ${minutes % 60} minutes`;
			console.log('time', time);

			return <TextInfo title='Shift Length' value={time} />;
		}
	};

	const parseDateForInput = (date) => {
		let year = date.getFullYear();
		let month = (date.getMonth() + 1).toString().padStart(2, '0');
		let day = date.getDate().toString().padStart(2, '0');
		let hours = date.getHours().toString().padStart(2, '0');
		let minutes = date.getMinutes().toString().padStart(2, '0');

		return `${year}-${month}-${day}T${hours}:${minutes}`;
	};

	const renderStatusButtons = () => {
		if (loadingClock) {
			return <Hearts color={Color.primary.primary} height={100} width={100} />;
		}
		if (shift.status === 'selected') {
			return (
				<Button
					style={{
						borderRadius: '24px',
					}}
					valid={true}
					type='submit'
					margin='10px 0 0 0 '
					backgroundColor={Colors.primary.primary}
					width={'200px'}
					height={'60px'}
					onClick={() => handleSuccesRequest(true)}
				>
					<Text
						style={{
							textAlign: 'center',
						}}
						bold
						color={'white'}
						midLarge
					>
						Start Shift
					</Text>
				</Button>
			);
		} else if (shift.status === 'clocked_in') {
			return (
				<Button
					style={{
						borderRadius: '24px',
					}}
					valid={true}
					type='submit'
					margin='10px 0 0 0 '
					backgroundColor={Colors.greenLightTeal}
					width={'200px'}
					height={'60px'}
					onClick={() => handleSuccesRequest(false)}
				>
					<Text
						style={{
							textAlign: 'center',
						}}
						bold
						color={'white'}
						midLarge
					>
						Clock Out
					</Text>
				</Button>
			);
		} else if (shift.status === 'clocked_out') {
			// ensure that the start_time & end_time are not null

			const start_time = shift.start_time;
			const end_time = shift.end_time;
			const signature = shift.client_signature_url;
			if (start_time && end_time && signature) {
				// show the submit button
				return (
					<Button
						style={{
							borderRadius: '24px',
						}}
						valid={true}
						type='submit'
						margin='10px 0 0 0 '
						backgroundColor={Colors.primary.primary}
						width={'200px'}
						height={'60px'}
						onClick={() => handleSubmitShift()}
					>
						<Text
							style={{
								textAlign: 'center',
							}}
							bold
							color={'white'}
							midLarge
						>
							Submit Shift
						</Text>
					</Button>
				);
			} else {
				if (!start_time) {
					return (
						<Text
							style={{
								textAlign: 'center',
							}}
							bold
							midLarge
						>
							Start & Stop Time not confirmed
						</Text>
					);
				} else if (!end_time) {
					return (
						<Text
							style={{
								textAlign: 'center',
							}}
							bold
							midLarge
						>
							End Time is not confirmed
						</Text>
					);
				} else if (!signature) {
					return (
						<Text
							style={{
								textAlign: 'center',
							}}
							bold
							midLarge
						>
							Signature is not collected
						</Text>
					);
				}
			}
		}
	};

	const handleConfirmTime = async () => {
		try {
			/*
			 * this had a major change once we altered the charging format
			 * the start and end time are whats was schedueld
			 * the clock_in_time and clock_out_time are the actual times that the CarePro clocked in
			 * * start and end time are already in UTC format
			 * * clock_in_time and clock_out_time are in local time and need to be updated to UTC
			 * * if the user does not change the input of the confirmation time then we get it form teh model since that has already been updated
			 * * though this assumes the shift model is already in utc format?
			 */
			const updateParams = {
				start_time: shift.clock_in_time,
				end_time: shift.clock_out_time,
				confirmed_shift_time: true,
			};

			console.log('startTime', startTime);
			console.log('endTime', endTime);

			// determine if the user has changed the start and end time
			if (startTime) {
				// convert datetime-local to utc
				const start = new Date(startTime);
				const startUTC = new Date(start.getTime() + start.getTimezoneOffset() * 60000);
				updateParams.start_time = startUTC;
			}

			if (startTime) {
				// convert datetime-local to utc
				const start = new Date(startTime);
				const startUTC = new Date(start.getTime() + start.getTimezoneOffset() * 60000);
				updateParams.start_time = startUTC;
			}

			if (endTime) {
				// convert datetime-local to utc
				const end = new Date(endTime);
				const endUTC = new Date(end.getTime() + end.getTimezoneOffset() * 60000);
				updateParams.end_time = endUTC;
			}

			await handleUpdateShift(updateParams);

			console.log('updateParams', updateParams);
		} catch (err) {
			console.log('err', err);
		}
	};

	const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Get user's time zone

	return (
		<>
			<PHWhiteCon
				style={{
					maxWidth: isMobile ? '100%' : '440px',
					backgroundColor: Colors.lightGrey,
					flex: 3,
					backgroundColor: 'white',
				}}
			>
				<Title subtitle color={Colors.greenLightTeal} margin='0 0 00px 0' fontWeight='700'>
					Shift Details <span>({shift.status})</span>
				</Title>
				<ColumnFlex
					style={{
						borderRadius: '16px',

						padding: '0px 10px 10px 10px',
					}}
					fullWidth
					margin='10px 0 12px 0'
				>
					<TextInfo
						title='Scheduled Clock In @'
						value={new Date(shift.start_time).toLocaleDateString('en-US', {
							timeZone: userTimezone,
							year: 'numeric',
							month: 'long',
							day: 'numeric',
							hour: '2-digit',
							minute: '2-digit',
							second: '2-digit',
						})}
					/>

					<TextInfo
						title='Scheduled Clock Out @'
						value={new Date(shift.end_time).toLocaleDateString('en-US', {
							timeZone: userTimezone,
							year: 'numeric',
							month: 'long',
							day: 'numeric',
							hour: '2-digit',
							minute: '2-digit',
							second: '2-digit',
						})}
					/>
					{shift.clock_in_time && (
						<TextInfo
							title='Actual Clock In @'
							value={new Date(shift.clock_in_time).toLocaleDateString('en-US', {
								timeZone: userTimezone,
								year: 'numeric',
								month: 'long',
								day: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
								second: '2-digit',
							})}
						/>
					)}

					{shift.clock_out_time && (
						<TextInfo
							title='Actual Clock Out @'
							value={new Date(shift.clock_out_time).toLocaleDateString('en-US', {
								timeZone: userTimezone,
								year: 'numeric',
								month: 'long',
								day: 'numeric',
								hour: '2-digit',
								minute: '2-digit',
								second: '2-digit',
							})}
						/>
					)}

					{shift.status === 'clocked_in' && (
						<TextInfo title='Elapsed Time'>
							<TimerText startDate={new Date(shift.clock_in_time)} />
						</TextInfo>
					)}

					{shift.status !== 'selected' && renderTotalTimeElasped()}
					<AnimateHeight
						duration={500}
						height={shift.status === 'clocked_out' ? 'auto' : '0'}
					>
						<PHWhiteCon
							style={{
								maxWidth: isMobile ? '100%' : '440px',
								backgroundColor: Colors.lightGrey,
								flex: 3,
								backgroundColor: 'white',
								width: '100%',
							}}
						>
							<RowFlex fullWidth spaceBetween alignCenter>
								<Title
									subtitle
									color={Colors.greenLightTeal}
									margin='0 0 00px 0'
									fontWeight='700'
								>
									Confirm Time
								</Title>
							</RowFlex>

							<ColumnFlex
								style={{
									borderRadius: '16px',
									backgroundColor: Colors.lightGrey,
									padding: '10px ',
								}}
								fullWidth
								margin='10px 0 12px 0'
							>
								<Text padding='0 8px'>Start Time</Text>
								<StyledDateTimeInput
									value={
										startTime ||
										parseDateForInput(new Date(shift.clock_in_time))
									}
									type='datetime-local'
									onChange={(e) => setStartTime(e.target.value)}
								/>
								<Text margin='10px 0 ' />
								<Text padding='0 8px'>End Time</Text>
								<StyledDateTimeInput
									value={
										endTime || parseDateForInput(new Date(shift.clock_out_time))
									}
									type='datetime-local'
									onChange={(e) => setEndTime(e.target.value)}
								/>
								{shift.status === 'clocked_out' && !shift.confirmed_shift_time && (
									<ColumnFlex padding='12px 0' gap='10px'>
										<Button
											style={{
												borderRadius: '24px',
											}}
											onClick={() => handleConfirmTime()}
											margin='auto'
											valid={true}
											type='submit'
											backgroundColor={Colors.greenLightTeal}
											width={'200px'}
											height={'40px'}
										>
											<Text
												style={{
													textAlign: 'center',
												}}
												bold
												color={'white'}
												mediumText
											>
												Confirm Shift Time
											</Text>
										</Button>
									</ColumnFlex>
								)}
								{shift.status === 'clocked_out' && shift.confirmed_shift_time && (
									<RowFlex alignCenter padding='12px 0' center fullWidth>
										<Icon
											icon={faCheck}
											color={Colors.greenLightTeal}
											size='2x'
										/>
										<Text
											bold
											padding='0 0 0 10px'
											largeText
											color={Colors.secondary.blue}
										>
											Confirmed
										</Text>
									</RowFlex>
								)}
							</ColumnFlex>
						</PHWhiteCon>
					</AnimateHeight>
					<ShiftAdl shift={shift} />
					{shift.status !== 'created' && (
						<ShiftNotes shift={shift} handleUpdateLocalShift={handleUpdateLocalShift} />
					)}
					<AnimateHeight
						duration={500}
						height={shift.status === 'clocked_out' ? 'auto' : '0%'}
					>
						<PHWhiteCon
							style={{
								maxWidth: isMobile ? '100%' : '440px',
								backgroundColor: Colors.lightGrey,
								flex: 3,
								backgroundColor: 'white',
							}}
						>
							<RowFlex fullWidth spaceBetween alignCenter>
								<Title
									subtitle
									color={Colors.greenLightTeal}
									margin='0 0 00px 0'
									fontWeight='700'
								>
									Signature/Feedback
								</Title>
							</RowFlex>

							<ColumnFlex
								style={{
									borderRadius: '16px',
									backgroundColor: Colors.lightGrey,
									padding: '10px ',
								}}
								fullWidth
								margin='10px 0 12px 0'
							>
								<ShiftSignatureCollection
									handleUpdateLocalShift={handleUpdateLocalShift}
									shift={shift}
									handleUpdateShift={handleUpdateShift}
								/>
							</ColumnFlex>
						</PHWhiteCon>
					</AnimateHeight>
					<ColumnFlex fullWidth center gap='12px'>
						{renderStatusButtons()}
						{shift.status === 'selected' && (
							<Button
								style={{
									borderRadius: '24px',
									border: `solid 2px ${Colors.yellow}`,
								}}
								valid={true}
								type='submit'
								margin='10px 0 0 0 '
								backgroundColor={'transparent'}
								width={'200px'}
								height={'60px'}
								onClick={() => deselectShift()}
							>
								<Text
									style={{
										textAlign: 'center',
									}}
									bold
									color={Colors.yellow}
									midLarge
								>
									Deselect Shift
								</Text>
							</Button>
						)}
					</ColumnFlex>
				</ColumnFlex>
			</PHWhiteCon>

			<PHWhiteCon
				style={{
					maxWidth: isMobile ? '100%' : '440px',
					backgroundColor: 'transparent',
					flex: 3,
				}}
			>
				<ColumnFlex
					style={{
						borderRadius: '16px',
						//backgroundColor: Colors.lightGrey,
						padding: '10px ',
					}}
					fullWidth
					center
					margin='10px 0 12px 0'
				>
					<Button
						onClick={() => handleCancelShift()}
						style={{
							borderRadius: '24px',
							border: `solid 2px ${Colors.red}`,
						}}
						valid={true}
						type='submit'
						margin='10px 0 0 0 '
						backgroundColor={'transparent'}
						width={'200px'}
						height={'60px'}
					>
						<Text
							style={{
								textAlign: 'center',
							}}
							bold
							color={Colors.red}
							midLarge
						>
							Cancel Shift
						</Text>
					</Button>
				</ColumnFlex>
			</PHWhiteCon>
		</>
	);
};

export default ShiftTimeControl;
