import React, { Fragment, Component, useState } from 'react';
import styled from 'styled-components';
import Text from 'AppComp/Text';
import AnimateHeight from 'react-animate-height';
import { faInfo, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import changeCase from 'change-case';
import moment from 'moment';

import { ColumnFlex, RowFlex, DropShadow } from 'basicStyles';
import Colors from 'colors';
import Icon from 'AppComp/Icon';
import Button from 'AppComp/Button';
import { device } from 'AppComp/styled/StyledMediaQuery';

const PADDING = '8px 44px';

/*
	* EventModelViews Exports*

	
	
	
	- ModelHeader

	
*/

export const ModelContainer = styled.div`
	margin: 16px 0 16px 0;
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 85vw;
	max-height: 85vh;
	background-color: white;
	align-items: center;
	border-radius: 8px;
	overflow: hidden;
	overflow-y: auto;
	@media ${device.tablet} {
		width: 520px;
		max-height: 90vh;
	}
`;

export const Divider = styled.div`
	width: 60%;
	margin: 16px auto 0 auto;
	height: 1.5px;
	background-color: gray;
`;

export const ModelTitle = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: ${({ color }) => color || '#2BAFA4'};
	height: 54px;
	padding: 0 8px;
	width: 100%;
`;

const CustomRow = styled(RowFlex)`
	cursor: pointer;
`;

export const DisputeShower = (props) => {
	const { title, children, flex, margin, fullWidth } = props;

	return (
		<ColumnFlex flex={flex} margin={margin} fullWidth={fullWidth}>
			<Text fontStyle='italic' darkGray largeText margin='0 0 12px 0'>
				{title}
			</Text>
			{children}
		</ColumnFlex>
	);
};

export const ModelHeader = (props) => {
	const { title, color } = props;

	return (
		<ModelTitle color={color}>
			<div />
			<Text color='white' largeText>
				{title}
			</Text>
			<div />
		</ModelTitle>
	);
};
