import styled from 'styled-components';

export const StyledCalendarFormContainer = styled.div`
	background-color: #ffffff;
	padding: 20px;
	z-index: 999 !important;
	box-sizing: border-box;
	border-radius: 10px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	width: 100%;
	max-width: 390px;
	font-size: 25px;
`;

export const StyledInnerCalendarFormWrapper = styled.div`
	border: 1px solid rgba(43, 105, 166, 0.3);
	border-radius: 10px;
	padding: 32px 32px 16px;
	background-color: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px; /* Simplified spacing */
`;

export const StyledFormTitle = styled.h2`
	font-size: 18px;
	color: #2b69a3;
	text-align: center;
	margin-bottom: 20px;
	font-weight: 700;
	letter-spacing: 2px;
`;

export const StyledWeekDays = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	margin-bottom: 10px;
`;

export const StyledDayButton = styled.button`
	border: 1px solid rgb(224, 224, 224);
	border-radius: 16px;
	box-sizing: border-box;
	color: rgb(43, 105, 163);
	cursor: pointer;
	font-size: 15px;
	font-weight: 400;
	padding: 4px 10px;
	min-width: 28px;
	text-align: center;
	transition: background-color 0.15s linear;
	background-color: ${(props) => (props.isSelected ? '#e0ebf7' : 'transparent')};

	&:hover {
		background-color: #e0ebf7;
	}

	&:selected {
		background-color: red !important;
	}
`;

export const StyledButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
	margin-top: 10px;
`;
