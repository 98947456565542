import React from 'react';
import styled from 'styled-components';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { connect } from 'react-redux';
import { ColumnFlex } from 'basicStyles';
import {
	updateProviderInfo,
	updateProviderRate,
	logoutProvider,
	addProviderPaymentMethod,
	updateProviderDefaultPaymentMethod,
	deleteProviderPaymentMethod,
} from 'reducers/provider';
import { showInfoModel } from 'reducers/environment';
import Colors from 'colors';
import useWindowSize from 'scripts/useWindowSize';

import { InfoButton } from 'AppComp/Info';
import Title from 'AppComp/Title';
import Button from 'AppComp/Button';
import Text from 'AppComp/Text';

import ProfileInfo from '../ProfileInfo';

import Rate from '../Rate';

import { ParentContainer, PHDColumn } from '../ProviderHomeDashboard';
import CardHandler from '../Payouts/CardHandler';
import { RowFlex } from '../../styled/Basic';

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);

const OverflowColumn = styled(ColumnFlex)`
	overflow-y: scroll;
`;
const ProviderProfileHandler = (props) => {
	const { width } = useWindowSize();

	const {
		provider,
		updateProviderInfo,
		addProviderPaymentMethod,
		updateProviderDefaultPaymentMethod,
		deleteProviderPaymentMethod,
		liveInRate,
		hourlyRate,
		updateProviderRate,
		logoutProvider,
		payoutMethods,
	} = props;

	const handleInfo = () => {
		showInfoModel({
			show: true,
			text: 'Provider Profile Info',
			title: 'Provider Profile Info',
		});
	};

	const handleLogout = () => {
		logoutProvider();
	};
	return (
		<ParentContainer flex>
			{/* <InfoButton onClick={() => handleInfo()} />
			{conversation && <ProviderChatModel conversation={conversation} />} */}
			<PHDColumn
				style={{
					backgroundColor: 'auto',
				}}
			>
				<RowFlex
					style={{
						flexDirection: width > 1000 ? 'row' : 'column',
					}}
					gap='20px'
					columnMobile
				>
					<ProfileInfo provider={provider} updateProviderInfo={updateProviderInfo} />
					<ColumnFlex gap='32px'>
						<Rate
							updateProviderRate={updateProviderRate}
							liveInRate={liveInRate}
							hourlyRate={hourlyRate}
							provider={provider}
						/>
						<Elements stripe={stripePromise}>
							<CardHandler
								addPaymentMethod={addProviderPaymentMethod}
								updateDefaultPaymentMethod={updateProviderDefaultPaymentMethod}
								deletePaymentMethod={deleteProviderPaymentMethod}
								paymentMethods={payoutMethods}
								userId={provider.id}
								user={provider}
							/>
						</Elements>
						<RowFlex fullWidth center>
							<Button
								backgroundColor={Colors.red}
								width={'300px'}
								height={'70px'}
								borderRadius='32px'
								onClick={() => handleLogout()}
								style={{
									shadow: 'none',
								}}
							>
								<Text fontWeight='500' color={'white'} largeText>
									Logout
								</Text>
							</Button>
						</RowFlex>
					</ColumnFlex>
				</RowFlex>
			</PHDColumn>
		</ParentContainer>
	);
};

const mapStateToProps = ({ provider }, props) => {
	return {
		provider: provider.provider,
		payoutMethods: provider.payoutMethods,
		certifications: provider.certifications,
		conversation: provider.conversation,
		liveInRate: provider.liveInRate,
		hourlyRate: provider.hourlyRate,
	};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {
		updateProviderInfo: (params) => dispatch(updateProviderInfo(params)),
		updateProviderRate: (params) => dispatch(updateProviderRate(params)),
		addProviderPaymentMethod: (params) => dispatch(addProviderPaymentMethod(params)),
		updateProviderDefaultPaymentMethod: (params) =>
			dispatch(updateProviderDefaultPaymentMethod(params)),
		deleteProviderPaymentMethod: (params) => dispatch(deleteProviderPaymentMethod(params)),
		logoutProvider: () => dispatch(logoutProvider()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ProviderProfileHandler);
