import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { userContactCarePro } from 'reducers/patient';
import { fetchSelectedProvider } from 'reducers/provider';

import abilities from 'constants/abilities.json';

import { transcribeTraits } from 'scripts';

import Profiles from '../../core/Profiles';

const CareProProfileHandler = (props) => {
	const { patient, selectedProvider, conversations, fetchSelectedProvider } = props;

	const [traits, setTraits] = useState([]);

	const { careProId } = useParams();
	const { push } = useHistory();

	useEffect(() => {
		fetchSelectedProvider(careProId);
	}, []);

	useEffect(() => {
		if (selectedProvider) {
			setTraits(transcribeTraits(selectedProvider.careAbilitySet.setData, abilities));
		}
	}, [selectedProvider]);

	if (props.selectedProvider === null) {
		return null;
	}

	const chatHandler = async (careProId) => {
		try {
			// this needs an extra handler since there is a chance the user won't have a conversation with the CarePro yet

			// check existing conversations and find the one with the carePro
			const conversation = conversations.find(
				(convo) => convo.provider && convo.provider.id === careProId
			);
			if (conversation) {
				push('/chat/' + conversation.id);
			} else {
				// ensure user (alias patient) is logged in
				if (patient === null) {
					return;
				}

				userContactCarePro({
					careProId: careProId,
					userId: patient.id,
				});
			}
		} catch (err) {
			console.log('err', err);
		}
	};

	return (
		<Profiles.CareProProfile
			carePro={selectedProvider}
			traits={traits}
			chatHandler={chatHandler}
		/>
	);
};

const mapStateToProps = ({ patient, provider }) => {
	return {
		patient: patient.patient,
		conversations: patient.conversations,
		selectedProvider: provider.selectedProvider,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchSelectedProvider: (id) => dispatch(fetchSelectedProvider(id)),
		userContactCarePro: (params) => dispatch(userContactCarePro(params)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CareProProfileHandler);
