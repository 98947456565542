import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import is from 'styled-is';

import Colors from '../constants/colors';
import { device } from './styled/StyledMediaQuery';

const Title = styled.p`
	font-size: 40px;
	line-height: 50px;

	@media ${device.tablet} {
		font-size: 54px;
		line-height: 56px;
	}
	@media ${device.laptopL} {
		font-size: 64px;
		line-height: 68px;
	}

	color: ${({ color }) => color || Colors.textBlack};
	margin: ${(props) => props.margin || '0'};
	padding: ${(props) => props.padding || '0'};
	max-width: ${(props) => props.maxWidth || 'none'};
	font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
	font-style: ${({ fontStyle }) => fontStyle || 'normal '};
	font-family: ${({ fontFamily }) => fontFamily || 'Helvetica'};

	${is('nubtitle')`
		font-size: 28px;
		line-height: 34px;

		@media ${device.tablet} {
			font-size: 32px;
			line-height: 36px;
		}
		@media ${device.laptopL} {
			font-size: 40px;
			line-height: 44px;
		}
`}
	${is('subtitle')`
        font-size: 20px;
        line-height: 22px;

				@media ${device.tablet} {
					font-size: 24px;
					line-height: 26px;
				}
				@media ${device.laptopL} {
					font-size: 30px;
        line-height: 32px;
				}
    `}
	${is('trititle')`
        font-size: 16px;
        line-height: 20px;

				@media ${device.tablet} {
					font-size: 20px;
					line-height: 22px;
				}
				@media ${device.laptopL} {
					font-size: 24px;
					line-height: 26px;
				}
    `}

    ${is('bold')`
        font-weight: 600;
    `}

    ${is('pointer')`
		cursor: pointer;
	`}
	${is('underline')`
		text-decoration: underline;
	`}
    ${is('overflow')`
    	overflow: hidden;
    	text-overflow: ellipsis;
    `}
`;
export default Title;
