import styled from 'styled-components';

import { mediaQueries } from '../../../../../corev2/utils';

export const StyledCreateJobPostPage = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	padding: 15px;
	margin: auto;

	@media ${mediaQueries.tablet} {
		width: fit-content;
	}
`;
