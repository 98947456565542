import styled from 'styled-components';

export const StyledPaymentsPage = styled.div`
	width: 100%;

	text-align: center;

	position: relative;
`;

export const StyledHeading = styled.h1``;

export const StyledTableContainer = styled.div`
	width: 80%;
	position: relative;
	margin: 2rem auto 0 auto;
`;
