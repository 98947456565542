export const localStorageKeys = {
	FYI_CARE_PRO_AUTH_TOKEN: 'FYI_CARE_PRO_AUTH_TOKEN',
	FYI_CARE_PRO_LEGACY_AUTH_TOKEN: 'user-token',
	FYI_CARE_PRO_PLATFORM_OBOARDING_STATE: 'FYI_CARE_PRO_OBOARDING_STATE',
	FYI_CARE_PRO_STRIPE_ONBOARDING_STATE: 'FYI_CARE_PRO_STRIPE_ONBOARDING_STATE',
};

export const setInLocalStorage = (key, value) => {
	if (value) {
		localStorage.setItem(key, value);
	} else {
		localStorage.removeItem(key);
	}
};

export const getFromLocalStorage = (key) => {
	const value = localStorage.getItem(key);

	if (value) {
		try {
			return JSON.parse(value);
		} catch (err) {
			return value;
		}
	}

	return null;
};
