import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { Section } from '../admin/UserModel/UserModelBlocks';
import { ModelContainer } from '../EventModel';
import Button from '../Button';
import Text from '../Text';

import { ColumnFlex, RowFlex } from 'basicStyles';
import Colors from 'colors';
import api from 'scripts/api';

const Holder = () => {
	const [state, setState] = useState([
		{
			startDate: null,
			endDate: new Date(),
			key: 'selection',
		},
	]);

	const handleFetchingReport = async () => {
		await api.getCSV('reports?type=invoice');
	};
	return (
		<ModelContainer>
			<Section defaultActive title={'Invoice Report Generator'}>
				<ColumnFlex alignCenter>
					<Text margin='12px 0'>Please Select a date range for report</Text>
					<DateRange
						editableDateInputs={true}
						onChange={(item) => setState([item.selection])}
						moveRangeOnFirstSelection={false}
						ranges={state}
					/>
					<Button
						width='auto'
						margin='12px 0 12px 0'
						padding='0 8px'
						onClick={() => handleFetchingReport()}
						backgroundColor={Colors.blue}
					>
						Generate Invoice Report
					</Button>
				</ColumnFlex>
			</Section>
		</ModelContainer>
	);
};

export default Holder;
