import React, { useEffect, useState } from 'react';

import { Container, Title, GridContainer, Item, Label, Input } from './CoreView';

const CareProDataView = (props) => {
	const {
		id,
		email,
		displayName,
		gender,
		image_url,
		phone_number,
		background_check_status,
		professional_statement,
		first_name,
		last_name,
		care_type,
		account_status,
		display_name,
		hourly_rate,
		live_in_rate,
		careAbilitySet,
	} = props;

	return (
		<>
			<Title>Care Pro</Title>
			<GridContainer columns={4}>
				<Item>
					<Label>Id</Label>
					<Input>{id}</Input>
				</Item>
				<Item>
					<Label>Email</Label>
					<Input>{email}</Input>
				</Item>
				<Item>
					<Label>Display Name</Label>
					<Input>{displayName}</Input>
				</Item>
				<Item>
					<Label>gender</Label>
					<Input>{gender}</Input>
				</Item>
				<Item>
					<Label>Phone Number</Label>
					<Input>{phone_number}</Input>
				</Item>
				<Item>
					<Label>First Name</Label>
					<Input>{first_name}</Input>
				</Item>
				<Item>
					<Label>Last Name</Label>
					<Input>{last_name}</Input>
				</Item>
				<Item>
					<Label>Account Status</Label>
					<Input>{account_status}</Input>
				</Item>
				<Item>
					<Label>Display Name</Label>
					<Input>{display_name}</Input>
				</Item>
			</GridContainer>

			<GridContainer columns={2}>
				<Item>
					<Label>Professional Statement</Label>
					<Input>{professional_statement}</Input>
				</Item>
			</GridContainer>
		</>
	);
};

export default CareProDataView;
