import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Hearts } from 'react-loader-spinner';

import Colors from 'colors';
import FormButton from 'AppComp/FormButton';
import Text from 'AppComp/Text';
export const FORM_ITEM_LENGTH = '360px';
export const MARGIN_ITEMS = '10px 0 10px 0';

export const FormItemsLength = styled.div`
	width: ${({ width }) => width};
	margin: ${({ margin }) => margin ?? MARGIN_ITEMS};
	color: black;
`;

export const FormButtonStyled = styled(FormButton)`
	transition: all 0.3s;
	${({ valid, backgroundColor }) =>
		!valid &&
		`
		background-color: white;
		border: 1px solid ${backgroundColor || Colors.greenLightTeal};
		color: ${backgroundColor || Colors.greenLightTeal};

	`}

	${({ valid, backgroundColor }) =>
		valid &&
		`
		background-color: ${backgroundColor || Colors.greenLightTeal};
		color: white;
	`}
`;

export const CarePlanComponentsFormSubmitButton = (props) => {
	const { isValid, loading, carePlan, handleBack } = props;
	return (
		<>
			{loading ? (
				<Hearts color={Colors.blue} height={100} width={100} />
			) : (
				<>
					<FormButtonStyled
						backgroundColor={Colors.primary.primary}
						width={'300px'}
						height={'70px'}
						valid={isValid}
						borderRadius='32px'
						type='submit'
					>
						<Text
							fontWeight='500'
							color={isValid ? 'white' : Colors.primary.primary}
							largeText
						>
							{carePlan && carePlan.completed_care_plan ? 'Update' : 'Continue'}
						</Text>
					</FormButtonStyled>
					{carePlan && carePlan.completed_care_plan && (
						<Text
							margin='12px 0 0 0'
							onClick={() => handleBack()}
							style={{ cursor: 'pointer', marginTop: '16px' }}
							color={Colors.primary.primary}
							largeText
						>
							Back
						</Text>
					)}
				</>
			)}
		</>
	);
};
