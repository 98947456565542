import React, { useState, useEffect, useRef } from 'react';

import SignaturePad from 'react-signature-canvas';

import { PrimaryButton, TransparentButton } from '../../../../../corev2/Buttons';

import {
	StyledShiftWrapper,
	StyledShiftContainer,
	StyledTitle,
	StyledShiftDetails,
	StyledLabel,
	StyledFieldGroup,
	StyledFieldLabel,
	StyledInputField,
	StyledButtonGroup,
	StyledTextArea,
	StyledTextWithBg,
	StyledValue,
	StyledSignatureArea,
} from './styles';

function dataURItoBlob(dataURI, type = 'image/jpeg') {
	var binary = atob(dataURI.split(',')[1]);

	var arr = [];

	for (var i = 0; i < binary.length; i++) {
		arr.push(binary.charCodeAt(i));
	}

	return new Blob([new Uint8Array(arr)], { type });
}

const ShiftSignature = ({ handleUpdateShiftData }) => {
	const [trimmedDataURL, setTrimmedDataURL] = useState(null);
	const sigPad = useRef({});

	const [sigWidth, setSigWidth] = useState(null);

	const [isLoading, setIsLoading] = useState(false);

	const handleSave = () => {
		const urlData = sigPad.current.getTrimmedCanvas().toDataURL('image/jpeg');

		//console.log('urlData', urlData);
		setTrimmedDataURL(urlData);

		const identifyPoolId = 'us-east-1:66059173-f49e-413c-840a-3be0bd12aa8f';
		const bucketRegion = 'us-east-1';
		const bucketName = 'fyi-stage-bucket-sigs';

		const blobData = dataURItoBlob(urlData);

		AWS.config.update({
			region: bucketRegion,
			credentials: new AWS.CognitoIdentityCredentials({
				IdentityPoolId: identifyPoolId,
			}),
		});

		// shiftId::providerMatchId::providerId
		const signName = `${1}::${1}::${1}.jpeg`;

		const s3 = new AWS.S3({
			apiVersion: '2012-10-17',
			params: { Bucket: bucketName },
		});

		s3.upload(
			{
				Key: signName,
				Body: blobData,
				ACL: 'public-read',
				ContentType: 'image/jpeg',
			},
			handleUploadCallback
		);
	};

	const handleUploadCallback = (err, data) => {
		//once the call back is called we either should show an error or run handleSignatureUpdate to pass the info back to the parent and handle removing the signature pad
		//const { handleSignatureUpdate } = this.props;
		if (err) {
			console.log('somethign went wrong', err);
			//toast.error('Something went wrong, please try again', toastErrorConfig);
			// handleSignatureUpdate(
			// 	'https://www.signwell.com/assets/vip-signatures/courtney-love-signature-f4f978da27e809c02a68ad1eedceb8d51b0ec6834790f9effdc2db053495d83a.svg'
			// );
		} else {
			console.log('data', data);
			const { Location } = data;

			const updateParams = { client_signature_url: Location };

			handleUpdateShiftData(updateParams);
		}
	};

	const clear = () => {
		sigPad.current.clear();
	};

	// useEffect that will keep track of the width of the StyledSignatureArea
	useEffect(() => {
		const sigPadCon = document.getElementById('StyledSignatureAreaCon');

		if (!sigPadCon) return;
		// set the width of the signature pad to the width of the container
		const sigPadConWidth = sigPadCon.offsetWidth;
		setSigWidth(sigPadConWidth);
		// have an event listener that will update the width of the signature pad when the window is resized
		window.addEventListener('resize', () => {
			console.log('sigPadCon', sigPadCon);

			const sigPadConWidth = sigPadCon.offsetWidth;
			setSigWidth(sigPadConWidth);
		});

		return () => {
			window.removeEventListener('resize', () => {
				const sigPadConWidth = sigPadCon.offsetWidth;
				setSigWidth(sigPadConWidth);
			});
		};
	}, []);

	return (
		<StyledShiftContainer>
			<StyledTitle>Signature </StyledTitle>
			<StyledShiftDetails>
				<StyledSignatureArea
					id='StyledSignatureAreaCon'
					style={{
						width: '100%',
						height: '100%',
						minHeight: '400px',
					}}
				>
					{sigWidth !== null && (
						<SignaturePad
							canvasProps={{ width: sigWidth - 2, height: '400px' }}
							backgroundColor='white'
							ref={(ref) => {
								sigPad.current = ref;
							}}
						/>
					)}
				</StyledSignatureArea>

				<StyledButtonGroup>
					<PrimaryButton onClick={() => handleSave()} size='small'>
						Save
					</PrimaryButton>
					<TransparentButton onClick={() => clear()} size='small'>
						Clear
					</TransparentButton>
				</StyledButtonGroup>
			</StyledShiftDetails>
		</StyledShiftContainer>
	);
};

export default ShiftSignature;
