import styled from 'styled-components';

import { mediaQueries, colors } from '../../../../../corev2/utils';

export const StyledContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	min-height: 100vh;
`;

export const StyledWelcomeSection = styled.section`
	text-align: center;
	margin-bottom: 30px;
	background-color: ${colors.white};
	width: 100%;
	box-sizing: border-box;
	max-width: 1000px;
	border: 1px solid #2b69a3;
	border-radius: 10px;
	padding: calc(8px + 1.5625vw);
`;

export const StyledWelcomeHeading = styled.h2`
	font-size: 18px;
	font-weight: 700;
	color: ${colors.primary.primary};
	margin-bottom: 8px;
	letter-spacing: 2px;
	text-transform: uppercase;
	text-align: left;
`;

export const StyledWelcomeText = styled.p`
	font-size: 16px;
	color: ${colors.primary.primary};
	margin-bottom: 15px;
	text-align: left;
`;

export const StyledContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 1000px;
	justify-content: space-between;
	box-sizing: border-box;

	@media ${mediaQueries.tablet} {
		flex-direction: row;
	}
`;

export const StyledAccountSection = styled.div`
	flex: 0.5;
	box-sizing: border-box;
	background: ${colors.white};
	padding: 20px;
	border-radius: 10px;
	margin-right: 0;
	margin-bottom: 20px;
	border: 1px solid ${colors.primary.primary};
	text-align: left;
	letter-spacing: 2px;
	height: fit-content;

	@media ${mediaQueries.tablet} {
		margin-right: 20px;
		margin-bottom: 0px;
	}
`;

export const StyledInnerAccountSection = styled.div`
	display: flex;
	flex-direction: column;
	background: ${colors.white};
	flex: 1;
	padding: 30px;
	border-radius: 10px;
	margin-right: 0;
	margin-bottom: 20px;
	border: 1px solid ${colors.primary.primary};
	text-align: left;

	@media ${mediaQueries.tablet} {
		margin-right: 20px;
		margin-bottom: 0px;
	}
`;

export const StyledPaymentSection = styled.div`
	flex: 1;
	background-color: ${colors.white};
	box-sizing: border-box;
	padding: 20px;
	border-radius: 10px;
	margin-right: 0px;
	margin-bottom: 20px;
	border: 1px solid ${colors.primary.primary};
	text-align: left;
	letter-spacing: 2px;

	@media ${mediaQueries.tablet} {
		margin-bottom: 0;
	}
`;

export const StyledInnerPaymentSection = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: ${colors.white};
	padding: 20px;
	border-radius: 10px;
	margin-right: 0;
	margin-bottom: 20px;
	border: 1px solid ${colors.primary.primary};
	text-align: left;

	@media (max-width: 768px) {
		margin-right: 20px;
		margin-bottpm: 0;
	}
`;

export const StyledSectionTitle = styled.h3`
	font-size: 15px;
	font-weight: bold;
	color: ${colors.primary.primary};
	margin-bottom: 20px;
`;

export const StyledFormGroup = styled.div`
	margin-right: 20px;
	margin-bottom: 20px;
`;

export const StyledLabel = styled.label`
	font-size: 14px;
	color: ${colors.primary.primary};
	display: block;
	margin-bottom: 5px;
`;

export const StyledInput = styled.input`
	width: 100%;
	padding: 15px;
	font-size: 14px;
	margin-bottom: 10px;
	box-shadow: none;
	line-height: 40px;
	padding: 0 12px;
	min-height: 42px;

	font-size: 15px;

	::placeholder {
		opacity: 1;
		font-size: 15px;
	}

	:focus {
		outline: thin dotted currentColor;
		border: none;
	}
`;

export const StyledButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 1rem;
`;

export const StyledPaymentInfoText = styled.p`
	font-size: 14px;
	color: #2b69a3;
	margin-bottom: 15px;
	letter-spacing: 0px;
`;

export const StyledPaymentMethodsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 20px;
	justify-content: space-between;
	align-items: flex-start;
	margin-top: 30px;
	margin-bottom: 30px;
	width: 100%;
`;
