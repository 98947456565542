import React, { useState } from 'react';

import AnimateHeight from 'react-animate-height';
import styled from 'styled-components';

import { faChevronRight, faChevronDown, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import Colors from 'colors';
import { ColumnFlex, RowFlex } from 'basicStyles';
import { device } from 'AppComp/styled/StyledMediaQuery';

import { ProviderTileRowFlex } from '../ProviderSearch/ProviderSearchViews';

import {
	ProviderBottomText,
	ProviderBottomCell,
	ProviderIconDiv,
} from '../../provider/ProviderProfileStyledComponents';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import Icon from 'AppComp/Icon';
import Button from 'AppComp/Button';

export const SectionTopRight = styled(ProviderTileRowFlex)`
	max-width: 800px;
	width: 100%;
	@media ${device.mobileS} {
		width: 100%;
	}
	@media ${device.mobileL} {
		width: 100%;
	}
	@media ${device.laptop} {
		width: '20vw';
	}
	@media ${device.desktopL} {
		width: '20vw';
	}
`;
export const CareProJobOfferSection = (props) => {
	const handlePlusAction = () => {
		// add 100 to hourly
		props.handleHourlyVal(props.hourly + 100);
	};
	const handleMinusAction = () => {
		// subtract 100 from hourly
		props.handleHourlyVal(props.hourly - 100);
	};
	return (
		<SectionTopRight
			style={{
				height: 'fit-content',
				padding: '0',
			}}
		>
			<RowFlex
				fullWidth
				style={{
					backgroundColor: 'white',
					padding: '16px 10px',
				}}
			>
				<Title
					subtitle
					color={Colors.primary.primary}
					padding='0 0px'
					margin='0 0 20px 0'
					fontWeight='700'
				>
					Job Offer
				</Title>
			</RowFlex>
			<ColumnFlex padding='0 16px' fullWidth center>
				<RowFlex center gap='16px'>
					<div
						onClick={handleMinusAction}
						style={{
							cursor: 'pointer',
							padding: '12px',
							borderRadius: '50%',
							border: `2px solid ${Colors.secondary.green}`,
						}}
					>
						<Icon icon={faMinus} size='2x' color={Colors.secondary.green} />
					</div>

					<ColumnFlex>
						<PriceInput hourly={props.hourly} handleHourlyVal={props.handleHourlyVal} />
						<Text subtitle>per hour</Text>
					</ColumnFlex>
					<div
						onClick={handlePlusAction}
						style={{
							cursor: 'pointer',
							padding: '12px',
							borderRadius: '50%',
							border: `2px solid ${Colors.secondary.green}`,
						}}
					>
						<Icon icon={faPlus} size='2x' color={Colors.secondary.green} />
					</div>
				</RowFlex>
				<ColumnFlex margin='8px 0 0 0' gap='8px' padding='16px 0' fullWidth center>
					<Button
						style={{
							borderRadius: '24px',
						}}
						valid={true}
						type='submit'
						backgroundColor={Colors.primary.primary}
						width={'140px'}
						height={'40px'}
					>
						<Text
							style={{
								textAlign: 'center',
							}}
							bold
							color={'white'}
							midLarge
						>
							Send Offer
						</Text>
					</Button>
					<Text subtitle>Make sure to confirm your schedule </Text>
				</ColumnFlex>
			</ColumnFlex>
		</SectionTopRight>
	);
};

const PriceInputStyled = styled.input`
	background-color: transparent;
	border: none;
	font-family: ${({ fontFamily }) => fontFamily || 'Helvetica'};
	text-align: center;
	color: ${Colors.secondary.green};
	font-size: 30px;
	line-height: 50px;

	width: 120px;

	@media ${device.tablet} {
		width: 200px;
		font-size: 44px;
		line-height: 56px;
	}
	@media ${device.laptopL} {
		font-size: 54px;
		line-height: 68px;
	}
`;
export const PriceInput = (props) => {
	const displayVal = () => {
		// const displayRegex = /[^0-9.,$]/g;
		// const _display =
		// 	props.hourly.indexOf('.') == value.length - 1
		// 		? value.replace(displayRegex, '')
		// 		: '$' +
		// 		  formattedValue.toLocaleString('en', {
		// 				maximumFractionDigits: 2,
		// 		  });
		// ensure to dispaly at least two decimal places
		// return _display;

		// convert cents to dollars
		const dollars = props.hourly / 100;

		// convert dollars number to string and ensure we show 2 decimal places
		return '$' + dollars.toFixed(2);
	};
	const updateVale = (e) => {
		const { value } = e.target;

		//keep numbers and period
		const resultRegex = /[^0-9.]/g;
		console.log('value', value);
		let formattedValue = parseFloat(value.replace(resultRegex, ''));
		console.log('resultRegex', formattedValue);

		if (isNaN(formattedValue)) {
			formattedValue = 0;
		}

		props.handleHourlyVal(formattedValue * 100);
	};
	return <PriceInputStyled type='string' value={displayVal()} onChange={updateVale} />;
};

export const CareProSection = (props) => {
	const [active, setActive] = useState(false);

	const { title, children, icon } = props;
	return (
		<ProviderBottomCell fullWidth>
			<ProviderIconDiv center>
				<Icon icon={icon} size='1x' color={Colors.secondary.green} />
			</ProviderIconDiv>

			<ProviderBottomText>
				<RowFlex fullWidth spaceBetween onClick={() => setActive(!active)} pointer>
					<Text
						largeText
						color={active ? Colors.secondary.green : Colors.gray}
						padding='0px 0px 10px 0px'
					>
						{title}
					</Text>
					<Icon
						color={Colors.secondary.green}
						icon={active ? faChevronDown : faChevronRight}
					/>
				</RowFlex>
				<AnimateHeight duration={500} height={active ? 'auto' : '0%'}>
					<ColumnFlex padding='0 16px'>{children}</ColumnFlex>

					{/* <Text>
											{selectedProvider.professional_statement
												? selectedProvider.professional_statement
												: 'N/A'}
										</Text> */}
				</AnimateHeight>
			</ProviderBottomText>
		</ProviderBottomCell>
	);
};
