import React, { Fragment, Component, useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { Section } from '../UserModel/UserModelBlocks';
import { Formik, Form, Field } from 'formik';
import styled from 'styled-components';
import Colors from 'colors';
import { RowFlex, ColumnFlex } from 'AppComp/styled/Basic';

import Text from 'AppComp/Text';

import { FormItemsLength } from 'AppComp/provider/POnboard/OnboardViews';
import StyledTextInput, { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';
import { ActivityRadioInupt } from 'AppComp/patient/CarePlanInfo/CarePlanInfoViews';
import { CustomFormButton } from 'AppComp/patient/PatientCardCollection/PatientCardCollectionViews';

import { ValidateAttributeInput } from './AttributeTabValidation';
const toastErrorConfig = {
	containerId: 'basic',
	autoClose: false,
};

const ITEM_TYPE = [
	{
		value: null,
		label: 'Select Type',
	},
	{
		value: 'string',
		label: 'String',
	},
	{
		label: 'True/False',
		value: 'boolean',
	},

	{
		value: 'int',
		label: 'Number',
	},
	{
		value: 'array',
		label: 'Range',
	},
];

const AttributesTab = (props) => {
	const { createAttributeTrait, attributeTraits } = props;

	const handleSubmit = (values, form) => {
		console.log('AttributesTab - handleSubmit', values);

		// ensure all values are valid

		// this should only run after validation runs right
		if (values.type === null) {
			toast.error('Please select a type', toastErrorConfig);
		}
		createAttributeTrait({
			form,
			callBackFn: handleClearForm,
			...values,
			required: values.required ? true : false,
		});
	};

	const handleClearForm = (form) => {
		form.setValues({
			label: '',
			required: false,
			description: '',
			type: '',
		});
	};

	return (
		<Section defaultActive title={'Attribute Traits'}>
			<ColumnFlex
				fullWidth
				padding='16px'
				style={{
					backgroundColor: Colors.littleBitDarkerLightGray,
					borderRadius: '10px',
				}}
			>
				<Formik
					onSubmit={handleSubmit}
					validate={ValidateAttributeInput}
					render={(formProps) => {
						console.log(formProps);
						return (
							<Form
								noValidate
								style={{
									marginBottom: 0,
									marginTop: '8px',
								}}
							>
								<ColumnFlex alignCenter justifyCenter>
									<FormItemsLength margin='0' width={'100%'}>
										<Field
											name={'label'}
											type='text'
											backgroundColor={Colors.fullWhite}
											component={StyledTextInput}
											label={'Label'}
										/>
									</FormItemsLength>
									<FormItemsLength margin='0' width={'100%'}>
										<Field
											name={'description'}
											type='text'
											backgroundColor={Colors.fullWhite}
											component={StyledTextInput}
											label={'Description'}
										/>
									</FormItemsLength>
									<FormItemsLength margin='0' width={'100%'}>
										<Field
											name='type'
											component={StyledTextSelectInputV2}
											options={ITEM_TYPE}
											label='Requirement Type'
											backgroundColor={Colors.fullWhite}
										/>
									</FormItemsLength>
									<RowFlex fullWidth gap='16px'>
										<div
											style={{
												padding: '8px 16px',
												borderRadius: '12px',
												backgroundColor: 'white',
												height: 'min-content',
											}}
										>
											<Field
												component={ActivityRadioInupt}
												name='required'
												label='Required'
												type='checkbox'
											/>
										</div>
									</RowFlex>
									<CustomFormButton
										style={{
											borderRadius: '24px',
										}}
										valid={formProps.isValid}
										type='submit'
										margin='10px 0 0 0 '
										backgroundColor={Colors.primary.primary}
										width={'140px'}
										height={'40px'}
									>
										<Text
											style={{
												textAlign: 'center',
											}}
											bold
											color={
												formProps.isValid ? 'white' : Colors.primary.primary
											}
											midLarge
										>
											Add
										</Text>
									</CustomFormButton>
								</ColumnFlex>
							</Form>
						);
					}}
				/>
			</ColumnFlex>
			{attributeTraits.length > 0 && (
				<ColumnFlex
					fullWidth
					margin='8px 0 0 0'
					padding='16px'
					alignCenter
					style={{
						backgroundColor: Colors.littleBitDarkerLightGray,
						borderRadius: '10px',
					}}
				>
					{attributeTraits.reverse().map((attribute, index) => {
						return (
							<ColumnFlex
								style={{
									width: '100%',
									backgroundColor: Colors.fullWhite,
									borderRadius: '8px',
								}}
								key={attribute.id}
								alignCenter
								spaceBetween
								margin='8px 0 0 0'
								padding='12px 16px'
								gap='16px 0'
							>
								<RowFlex fullWidth spaceBetween>
									<Text midLarge margin='0 8px 0 0'>
										<span style={{ fontWeight: 'bold' }}>
											{attribute.label}:
										</span>{' '}
										{attribute.description || 'No description'}
									</Text>
								</RowFlex>
								<RowFlex fullWidth gap='16px'>
									<Text margin='0 8px 0 0'>
										<span style={{ fontWeight: 'bold' }}>Required:</span>{' '}
										{attribute.required ? 'Yes' : 'No'}
									</Text>
									<Text margin='0 8px 0 0'>
										<span style={{ fontWeight: 'bold' }}>Type:</span>{' '}
										{attribute.trait_type}
									</Text>
								</RowFlex>
							</ColumnFlex>
						);
					})}
				</ColumnFlex>
			)}
		</Section>
	);
};

export default AttributesTab;
