import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Form, Field } from 'formik';
import Colors from 'colors';
import { toast } from 'react-toastify';

import { TOAST_ERROR_CONFIG, TOAST_SUCCESS_CONFIG } from 'constants';
import { RELATION } from 'constants';

import Title from 'AppComp/Title';
import Text from 'AppComp/Text';
import { TextArea } from 'AppComp/styled/StyledTextInput';

import { ColumnFlex } from 'basicStyles';
import useWindowSize from 'scripts/useWindowSize';
import api from 'scripts/api';

import { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';
import StyledTextInput, { StrongPasswordTextInput } from 'AppComp/styled/StyledTextInput';

import { CARE_PLAN_ACTIONS } from './CarePlanOnboard';
import {
	FORM_ITEM_LENGTH,
	MARGIN_ITEMS,
	FormItemsLength,
	FormButtonStyled,
	CarePlanComponentsFormSubmitButton,
} from '../CarePlan/CarePlanviews';

import { GENDER } from 'constants';
import { CARE_NEEDS_MODEL } from 'reducers/graphqlModelTypes';

const CarePlanWelcome = (props) => {
	const { carePlan, loading, createCarePlan, moveToStep } = props;

	const [initialValues, setInitialValues] = useState(null);
	const { width } = useWindowSize();

	useEffect(() => {
		renderInitialValues();
	}, [carePlan]);

	const renderInitialValues = (careNeedsSet) => {
		console.log('Initial values - careNeedsSet', careNeedsSet);

		setInitialValues({
			relation_to_client: carePlan ? carePlan.relation_to_client : null,
		});
	};

	const handleSubmit = (values, form) => {
		console.log('form', form);
		const relation_to_client = values.relation_to_client;

		createCarePlan(relation_to_client);
	};

	// if (!initialValues) {
	// 	return <div>Loading...</div>;
	// }

	return (
		<ColumnFlex
			style={{
				height: 'fit-content',
				backgroundColor: 'white',
				borderRadius: '12px',
				maxWidth: '760px',
			}}
			padding={width < 768 ? 0 : '60px 80px'}
			center
			gap='32px'
		>
			<ColumnFlex fullWidth center gap='8px'>
				<Title nubtitle fontWeight='700'>
					Tell us about the relation between you and the person who needs care
				</Title>
			</ColumnFlex>
			<ColumnFlex
				fullWidth
				style={{
					flex: 1,
					borderRadius: '24px',
				}}
				center
			>
				<Formik
					onSubmit={handleSubmit}
					initialValues={initialValues}
					enableReinitialize={true}
					autoComplete={'off'}
					render={(formProps) => (
						<Form noValidate>
							<ColumnFlex alignCenter justifyCenter fullWidth fullHeight>
								<FormItemsLength width={'400px'}>
									<Field
										name='relation_to_client'
										component={StyledTextSelectInputV2}
										label='Relationship to the person who needs care'
										options={RELATION}
										backgroundColor='white'
									/>
								</FormItemsLength>
								<CarePlanComponentsFormSubmitButton
									isValid={formProps.isValid}
									loading={loading}
									carePlan={carePlan}
									handleBack={console.log(CARE_PLAN_ACTIONS.CARE_PLAN_HOME)}
								/>
								{/* <FormButtonStyled
									backgroundColor={Colors.primary.primary}
									width={'300px'}
									height={'70px'}
									valid={formProps.isValid}
									borderRadius='32px'
									type='submit'
								>
									<Text
										fontWeight='500'
										color={formProps.isValid ? 'white' : Colors.primary.primary}
										largeText
									>
										{carePlan && carePlan.completed_care_plan
											? 'Update'
											: 'Continue'}
									</Text>
								</FormButtonStyled>
								{carePlan && carePlan.completed_care_plan && (
									<Text
										margin='12px 0 0 0'
										onClick={() => moveToStep(CARE_PLAN_ACTIONS.CARE_PLAN_HOME)}
										style={{ cursor: 'pointer', marginTop: '16px' }}
										color={Colors.primary.primary}
										largeText
									>
										Back
									</Text>
								)} */}
							</ColumnFlex>
						</Form>
					)}
				/>
			</ColumnFlex>
		</ColumnFlex>
	);
};

export default CarePlanWelcome;
