import styled from 'styled-components';

import { colors } from '../../../../../corev2/utils';

export const StyledInformationTile = styled.div`
	display: flex;
	flex-direction: column;
`;

export const StyledIconContainer = styled.div`
	width: auto;
	height: 60px;
	color: ${colors.primary.primary};

	align-self: flex-start;

	margin-bottom: 10px;
`;

export const StyledTitle = styled.h3`
	font-size: 20px;
	color: ${colors.primary.primary};
	margin: 10px 0;
`;

export const StyledDescription = styled.p`
	font-size: 15px;
	line-height: 1.7;
	color: ${colors.primary.primary};

	margin: 0 0 1.2em;
`;
