import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div`
	flex: 1;
`;
const Input = styled.input`
	width: 100%;
	border: none;
`;

const CustomTextInput = (props) => {
	const { field, placeholder, maxLength, form, updateTextLength } = props;
	return (
		<InputContainer>
			<Input
				{...field}
				onChange={(event) => {
					const { value } = event.target;
					const length = value.length;
					updateTextLength(length);
					form.setFieldValue(field.name, value, true);
				}}
				maxLength={maxLength}
				placeholder={placeholder}
				autocomplete='nope'
			/>
		</InputContainer>
	);
};

export default CustomTextInput;
