import React, { useState, useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import Dropzone from 'react-dropzone';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import Webcam from 'react-webcam';
import { Hearts } from 'react-loader-spinner';

import { ColumnFlex } from 'basicStyles';

import Icon from 'AppComp/Icon';

import {
	OnboardFormContainer,
	OnboardTitle,
	OnboardSubText,
	OnboardButton,
	OnboardFormButton,
	FormItemsLength,
} from '../CareProOnboardViews';

import Colors from 'colors';

import Text from 'AppComp/Text';

import {
	CusotmFieldSet,
	TextInputCon,
	Container,
	CustomAvatar,
} from 'AppComp/provider/ProfileInfo/ProfileInfoViews';

const DocumentVerify = (props) => {
	const { isLoading, provider, setIsLoading, stripeStatus } = props;
	const { stripe_data } = stripeStatus;

	const [error, setError] = useState(null);

	// image src url to preview for the user their selection
	const [frontImage, setFrontImage] = useState(null);
	const [backImage, setBackImage] = useState(null);

	// upload to api
	const [uploadedFrontImage, setUploadedFrontImage] = useState(null);
	const [uploadedBackImage, setUploadedBackImage] = useState(null);

	// confirmation of image uploads to api
	const [confirmedFrontImage, setConfirmedFrontImage] = useState(false);
	const [confirmedBackImage, setConfirmedBackImage] = useState(false);

	const [showCamera, setShowCamera] = useState(false);

	const [windowSize, setWindowSize] = useState({
		width: 0,
		height: 0,
	});

	const webcamRef = React.useRef(null);

	const capture = React.useCallback(() => {
		const imageSrc = webcamRef.current.getScreenshot();
		handleImageScreenShot(imageSrc);
	}, [webcamRef, frontImage, backImage]);

	// get the screen width and height

	useEffect(() => {
		setWindowSize({ width: window.innerWidth, height: window.innerHeight });
	}, []);

	useEffect(() => {
		if (stripe_data !== null) {
			if (stripe_data.front_image !== null) {
				setConfirmedFrontImage(true);
			}

			if (stripe_data.back_image !== null) {
				setConfirmedBackImage(true);
			}
		}
	}, [stripe_data]);

	const handleUploadImagesToStripeNew = async (imageData, type) => {
		try {
			const blobFetch = await fetch(imageData);
			const blobData = await blobFetch.blob();

			const formData = new FormData();
			formData.append('file', blobData, 'file.jpeg');
			formData.append('providerId', provider.id);
			formData.append('type', type);
			const res = await fetch(`${process.env.SITE_API_ROOT}/careProImageVerificationUpload`, {
				method: 'POST',
				body: formData,
			});

			return true;
			console.log('data', data);
		} catch (err) {
			console.log('err', err);
			throw new Error('Error uploading image to stripe');
		}
	};

	const handleUploadFront = async () => {
		try {
			setIsLoading(true);
			const frontImageRes = await handleUploadImagesToStripeNew(frontImage, 'front');

			if (!frontImageRes) {
				throw new Error('Error uploading image to stripe');
			}

			setConfirmedFrontImage(true);
			setIsLoading(false);
			uploadedFrontImage(true);
		} catch (err) {
			setIsLoading(false);
			setError('Error uploading front image');
		}
	};

	const handleUploadBack = async () => {
		try {
			const backImageRes = await handleUploadImagesToStripeNew(backImage, 'back');

			setIsLoading(true);
			setConfirmedBackImage(true);
			setUploadedBackImage(true);
		} catch (err) {
			setIsLoading(false);
			setError('Error uploading back image');
		}
	};

	const renderImage = (imageData) => {
		return imageData;
	};

	const renderFrontImage = () => {
		return renderImage(frontImage);
	};
	const renderBackImage = () => {
		return renderImage(backImage);
	};

	const _renderUploadImagesText = () => {
		// refactoring by uploading one image at a time and then checking if the upload was successful
		// so in order of steps that can go on
		// 1) nothing is uploaded (user probably go just got here)
		// 2) front image is uploaded (but not sent to stripe api yet)
		// 3) front image action
		// 3.1) user confirms front image
		// 3.2) user retakes front image
		// 4) (after succesfull front image upload to api) back image is uploaded (but not sent to stripe api yet)
		// 5) back image action
		// 5.1) user confirms back image
		// 5.2) user retakes back image
		// 6) (after succesfull back image upload to api) move user to membership page

		if (frontImage === null) {
			return (
				<Text
					style={{
						textAlign: 'center',
					}}
					margin='12px 0 12px 0'
				>
					Lets start by uploading front of your ID. Please take a picture of the front
					Document.
				</Text>
			);
		}

		if (frontImage !== null && confirmedFrontImage === false) {
			return (
				<Text
					style={{
						textAlign: 'center',
					}}
					margin='12px 0 12px 0'
				>
					Confirm front image or retake/reupload
				</Text>
			);
		}

		if (confirmedFrontImage && confirmedBackImage && backImage === null) {
			return (
				<Text
					style={{
						textAlign: 'center',
					}}
					margin='12px 0 12px 0'
				>
					Please take a picture of the back of your Document.
				</Text>
			);
		}

		if (
			confirmedFrontImage &&
			confirmedBackImage &&
			backImage !== null &&
			confirmedBackImage === false
		) {
			return (
				<Text
					style={{
						textAlign: 'center',
					}}
					margin='12px 0 12px 0'
				>
					Please take a picture of the back of your Document.
				</Text>
			);
		}
		return (
			<Text
				style={{
					textAlign: 'center',
				}}
				margin='12px 0 12px 0'
			>
				Confirm the back image or retake/reupload
			</Text>
		);
	};
	const renderUploadImagesText = () => {
		/*
		 possible states
		 1) both images we're uploaded
		 2) front image was uploaded
		 3) back image was uploaded
		 4) no images we're uploaded & something went wrong
		 5) all images uploaded and waiting for check session to update the view (should take user to certification view)
		 6) upload process just started ("attempting to upload first image")
		*/

		if (!didSubmit) {
			return (
				<Text
					style={{
						textAlign: 'center',
					}}
					margin='12px 0 12px 0'
				>
					Please either take a picture of the front and back of your ID or upload front
					and back images.
				</Text>
			);
		}

		if (didSubmit && !didUpload) {
			return (
				<Text
					style={{
						textAlign: 'center',
					}}
					margin='12px 0 12px 0'
				>
					Please review images are correct and legible before submitting.
				</Text>
			);
		}

		if (didSubmit && didUpload && !wasSuccessfulUpload && !isLoading) {
			return (
				<Text
					style={{
						textAlign: 'center',
						color: 'red',
					}}
					margin='0 0 8px 0'
				>
					Something went wrong uploading your images. Please try again.
				</Text>
			);
		}
		if (didSubmit && didUpload && !uploadedFrontImage) {
			return (
				<Text
					style={{
						textAlign: 'center',
					}}
					margin='12px 0 12px 0'
				>
					Uploading front image...
				</Text>
			);
		}

		if (didSubmit && didUpload && uploadedFrontImage && !uploadedBackImage) {
			return (
				<Text
					style={{
						textAlign: 'center',
					}}
					margin='12px 0 12px 0'
				>
					Uploading back image...
				</Text>
			);
		}

		if (didSubmit && didUpload && uploadedFrontImage && uploadedBackImage) {
			return (
				<Text
					style={{
						textAlign: 'center',
					}}
					margin='12px 0 12px 0'
				>
					Verifying your documents...
				</Text>
			);
		}
	};

	const renderInstructionsTextInCamera = () => {
		console.log('frontImage', frontImage);
		if (confirmedFrontImage === false) {
			return <OnboardSubText>Please take a picture of the front of your ID.</OnboardSubText>;
		} else if (confirmedFrontImage === true) {
			return <OnboardSubText>Please take a picture of the back of your ID.</OnboardSubText>;
		}
	};

	const handleImageScreenShot = (imgData) => {
		if (confirmedFrontImage === false) {
			setFrontImage(imgData);
		} else if (confirmedFrontImage === true) {
			setBackImage(imgData);
		}

		setShowCamera(false);
		return;
	};

	const handleDroppedFile = async (acceptedFiles, front) => {
		var imageTypes = ['image/png', 'image/gif', 'image/bmp', 'image/jpg', 'image/jpeg'];

		console.log('acceptedFiles', acceptedFiles);

		var fileType = acceptedFiles[0].type;

		// helper function for converting to base64
		function arrayBufferToBase64(buffer) {
			let binary = '';
			const bytes = new Uint8Array(buffer);
			const len = bytes.byteLength;
			for (let i = 0; i < len; i++) {
				binary += String.fromCharCode(bytes[i]);
			}
			return window.btoa(binary);
		}

		// helper function for formatting the base64 string to be used in an image tag
		function arrayBufferToBase64JPEG(buffer) {
			return 'data:image/jpeg;base64,' + arrayBufferToBase64(buffer);
		}

		if (imageTypes.includes(fileType)) {
			try {
				const file = acceptedFiles[0];
				const reader = new FileReader();
				let imageUrl = null;
				reader.onabort = () => console.log('file reading was aborted');
				reader.onerror = () => console.log('file reading has failed');
				reader.onload = () => {
					// Do whatever you want with the file contents
					console.log('reader.result', reader);
					const binaryStr = reader.result;

					imageUrl = arrayBufferToBase64JPEG(binaryStr);

					console.log(imageUrl);
					if (front) {
						setFrontImage(imageUrl);
					} else {
						setBackImage(imageUrl);
					}
				};
				reader.readAsArrayBuffer(file);
			} catch (err) {
				console.log('err', err);
			}
		} else {
			window.alert('dropped file is not an image');
		}
	};

	console.log('0x01', showCamera);
	console.log('window size', windowSize);
	return (
		<OnboardFormContainer>
			<OnboardTitle
				style={{
					textAlign: 'center',
				}}
			>
				Please upload documents to verify your identity
			</OnboardTitle>
			<OnboardSubText>
				We need to verify your identity to ensure the safety of our community.
			</OnboardSubText>
			<OnboardSubText>{_renderUploadImagesText()}</OnboardSubText>
			<ColumnFlex gap='10px' center>
				{confirmedFrontImage === false && (
					<ColumnFlex gap='20px' alignCenter>
						<div
							style={{
								borderRadius: '22px',
								border: `2px solid ${Colors.primary.primary}`,
								width: '300px',
								height: '180px',
								backgroundColor: Colors.lightGrey,
							}}
						>
							{frontImage === null && (
								<Dropzone
									onDrop={(acceptedFiles) =>
										handleDroppedFile(acceptedFiles, true)
									}
								>
									{({ getRootProps, getInputProps }) => (
										<CustomAvatar
											height='100%'
											width='100%'
											margin='0px 0px 0 0'
											style={{
												borderRadius: '22px',
												border: `none`,
											}}
											{...getRootProps()}
										>
											<Icon
												icon={faFileUpload}
												color={Colors.primary.primary}
												size='2x'
											/>
											<Text
												style={{
													textAlign: 'center',
												}}
												mediumText
												margin='10px 0 12px 0'
											>
												Drop or click to upload front image
											</Text>
											<input {...getInputProps()} />
										</CustomAvatar>
									)}
								</Dropzone>
							)}

							{frontImage !== null && (
								<img
									style={{
										borderRadius: '12px',
									}}
									height={'99%'}
									width={'99%'}
									src={renderFrontImage()}
									alt='Encoded Image Front'
								/>
							)}
						</div>
						{frontImage === null ? (
							<OnboardButton onClick={() => setShowCamera(true)}>
								Take Front Picture
							</OnboardButton>
						) : (
							<>
								{isLoading ? (
									<>
										<Hearts
											color={Colors.primary.primary}
											height={70}
											width={70}
										/>
									</>
								) : (
									<>
										<OnboardButton onClick={() => setShowCamera(true)}>
											Retake Photo
										</OnboardButton>
										<OnboardButton
											onClick={() => handleUploadFront(true)}
											height={isMobile ? '40px' : '60px'}
										>
											Confirm Front Photo
										</OnboardButton>
									</>
								)}
							</>
						)}
					</ColumnFlex>
				)}
				{confirmedFrontImage === true && setConfirmedBackImage == false && (
					<ColumnFlex gap='20px' alignCenter>
						<div
							style={{
								borderRadius: '22px',
								border: `2px solid ${Colors.primary.primary}`,
								width: '300px',
								height: '180px',
								backgroundColor: Colors.lightGrey,
							}}
						>
							{backImage === null && (
								<Dropzone
									onDrop={(acceptedFiles) =>
										handleDroppedFile(acceptedFiles, false)
									}
								>
									{({ getRootProps, getInputProps }) => (
										<CustomAvatar
											height='100%'
											width='100%'
											margin='0px 0px 0 0'
											style={{
												borderRadius: '22px',
												border: `none`,
											}}
											{...getRootProps()}
										>
											<Icon
												icon={faFileUpload}
												color={Colors.primary.primary}
												size='2x'
											/>
											<Text
												style={{
													textAlign: 'center',
												}}
												mediumText
												margin='10px 0 12px 0'
											>
												Drop or click to upload back image
											</Text>
											<input {...getInputProps()} />
										</CustomAvatar>
									)}
								</Dropzone>
							)}

							{backImage !== null && (
								<img
									style={{
										borderRadius: '12px',
									}}
									height={'99%'}
									width={'99%'}
									src={renderBackImage()}
									alt='Encoded Image Front'
								/>
							)}
						</div>
						{backImage === null ? (
							<Button
								margin='20px 0 0 0 '
								onClick={() => setShowCamera(true)}
								backgroundColor={Colors.primary.primary}
								borderRadius={'24px'}
								width={'240px'}
								height={isMobile ? '40px' : '60px'}
							>
								<Text
									style={{
										textAlign: 'center',
									}}
									color={'white'}
									midLarge
								>
									Take Back Picture
								</Text>
							</Button>
						) : (
							<>
								{isLoading ? (
									<>
										<Loader
											type='Hearts'
											color={Colors.primary.primary}
											height={70}
											width={70}
										/>
									</>
								) : (
									<>
										<OnboardButton onClick={() => setShowCamera(true)}>
											Retake Photo
										</OnboardButton>
										<OnboardButton onClick={() => handleUploadBack(true)}>
											Confirm Back Photo
										</OnboardButton>
									</>
								)}
							</>
						)}
					</ColumnFlex>
				)}
				{showCamera && (
					<div
						className='0x04'
						style={{
							display: 'flex',
							position: 'absolute',
							// width: windowSize.width,
							// height: windowSize.height + ,
							zIndex: 100,
							top: 0,
							backgroundColor: 'rgba(0,0,0,0.5)',
						}}
					>
						<ColumnFlex
							className='0x03'
							padding='0 0 12px 0'
							margin='20px auto 0 auto'
							center
							gap='12px'
							style={{
								position: 'relative',
							}}
						>
							<Webcam
								audio={false}
								height={windowSize.height}
								ref={webcamRef}
								screenshotFormat='image/jpeg'
								width={windowSize.width}
								style={{
									borderRadius: '12px',
									margin: 'auto',
								}}
								videoConstraints={{
									width: windowSize.width - windowSize.width * 0.2,
									height: windowSize.height * 0.7,
									facingMode: isMobile ? { exact: 'environment' } : 'user',
								}}
							/>
							<div
								style={{
									position: 'absolute',
									top: '140px',
									left: 0,
									right: 0,
									zIndex: 1000,
									margin: 'auto',
									// add a blur backgorund so the user can read
									backgroundColor: 'rgba(255,255,255,0.5)',
									width: 'fit-content',
									padding: '10px 20px',
									borderRadius: '12px',
									// have a blur background
								}}
							>
								{renderInstructionsTextInCamera()}
							</div>
							<div
								style={{
									position: 'absolute',
									bottom: '140px',
									left: 0,
									right: 0,
									zIndex: 1000,
									margin: 0,
									// have a blur background
								}}
							>
								<OnboardButton margin='auto' onClick={capture}>
									Capture
								</OnboardButton>
								<Text
									onClick={() => setShowCamera(false)}
									style={{
										textAlign: 'center',
										cursor: 'pointer',
									}}
									margin='16px 0 0 0'
									underline
									cursor
									color={'black'}
									midLarge
								>
									Exit
								</Text>
							</div>

							{/* <button >Capture photo</button> */}
						</ColumnFlex>
					</div>
				)}
			</ColumnFlex>
		</OnboardFormContainer>
	);
};

export default DocumentVerify;
