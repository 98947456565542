import { useQuery } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

import { SHIFT_MODEL } from '../../../core/api/models';

const fetchCareProActiveShift = async () => {
	try {
		const data = await api.graph({
			query: `{
            fetchCareProActiveShift {
					${SHIFT_MODEL}
                }
            }`,
		});

		return data.fetchCareProActiveShift;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useFetchCareProActiveShiftQuery = () => {
	return useQuery({
		queryKey: ['fetchCareProActiveShift'],
		queryFn: () => {
			return fetchCareProActiveShift();
		},
	});
};
