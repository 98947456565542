import React, { useState } from 'react';

import styled from 'styled-components';
import { PHWhiteCon } from 'AppComp/provider/ProviderHomeDashboard';

import { ColumnFlex, RowFlex } from 'AppComp/styled/Basic';
import Title from 'AppComp/Title';
import Text from 'AppComp/Text';

import Colors from 'colors';
import { isMobile } from 'react-device-detect';

import { TOAST_SUCCESS_CONFIG } from 'constants';
import { toast } from 'react-toastify';

import FormButton from 'AppComp/FormButton';

import { Formik, Form, Field } from 'formik';

import { TextArea } from 'AppComp/styled/StyledTextInput';

import { FormItemsLength } from 'AppComp/provider/POnboard/OnboardViews';
import api from 'scripts/api';

import { SHIFT_MODEL } from 'reducers/graphqlModelTypes';

import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Icon from 'AppComp/Icon';
import AnimateHeight from 'react-animate-height';

const CustomFormButton = styled(FormButton)`
	border-radius: 50px;
	${({ valid }) =>
		!valid &&
		`
		background-color: white;
		border: 1px solid ${Colors.greenLightTeal};
		p{
			color: ${Colors.greenLightTeal};
		}
	`}

	${({ valid }) =>
		valid &&
		`
		background-color: ${Colors.greenLightTeal};
		color: white;
		p{
			color: white;
		}
	`}
`;

const ShiftNotes = (props) => {
	const [showNotes, setShowNotes] = useState(false);

	const { shift, handleUpdateLocalShift } = props;

	const handleUpdateNotes = (values, form) => {
		console.log('Personal notes - handleSubmit', values);

		//handleReject(values.reject_notes);
		handleUpdateShift({ notes: values.notes });
	};

	const handleUpdateShift = async (params) => {
		try {
			const updateShiftRes = await api.graph({
				query: `mutation {
					updateShift(
						shift_id: ${shift.id}
						shift_input: ${api.graphStringify(params)}
					) {
						${SHIFT_MODEL}
					}
				}`,
			});

			if (updateShiftRes.updateShift) {
				toast.success('Updated Shift', TOAST_SUCCESS_CONFIG);
				handleUpdateLocalShift(updateShiftRes.updateShift);

				return true;
			}
		} catch (err) {
			console.log('handleUpdateShift err', err);

			//throw new Error(err);
		}
	};

	return (
		<Formik
			onSubmit={handleUpdateNotes}
			initialValues={{
				notes: shift.notes,
			}}
			autoComplete={'off'}
			render={(formProps) => {
				console.log(formProps);
				console.log(formProps.values.notes !== shift.notes);
				return (
					<Form
						style={{
							width: '100%',
						}}
						noValidate
					>
						<PHWhiteCon
							style={{
								backgroundColor: 'white',
								height: 'fit-content',
								maxWidth: isMobile ? '100%' : '440px',
							}}
						>
							<RowFlex margin='0 0 00px 0' fullWidth alignCenter spaceBetween>
								<Title subtitle color={Colors.greenLightTeal} fontWeight='700'>
									Notes
								</Title>
								<div
									style={{
										cursor: 'pointer',
									}}
									onClick={() => setShowNotes(!showNotes)}
								>
									<Icon
										icon={showNotes ? faChevronDown : faChevronRight}
										size='2xl'
										color={Colors.secondary.blue}
									/>
								</div>
							</RowFlex>

							<AnimateHeight duration={500} height={showNotes ? 'auto' : '0%'}>
								<ColumnFlex
									style={{
										borderRadius: '16px',
										backgroundColor: Colors.lightGrey,
										padding: '10px 10px 10px 10px',
									}}
									fullWidth
									margin='10px 0 12px 0'
									alignCenter
								>
									<ColumnFlex alignCenter fullWidth justifyCenter>
										<FormItemsLength
											margin='0px 0 0 0'
											width={'100%'}
											style={{
												maxWidth: 'none',
											}}
										>
											<Field
												component={TextArea}
												name='notes'
												label=''
												type='text'
												rows='10'
												maxWidth='none'
											/>
										</FormItemsLength>
									</ColumnFlex>
									{formProps.values.notes !== shift.notes && (
										<CustomFormButton
											valid={true}
											style={{
												borderRadius: '32px',
												backgroundColor: Colors.greenLightTeal,
											}}
											margin='0 0 0 0 '
											backgroundColor={Colors.greenLightTeal}
											width={'160px'}
											height={'40px'}
											type='submit'
										>
											<Text
												style={{
													textAlign: 'center',
												}}
												bold
												color={Colors.red}
											>
												Update Notes
											</Text>
										</CustomFormButton>
									)}
								</ColumnFlex>
							</AnimateHeight>
						</PHWhiteCon>
					</Form>
				);
			}}
		/>
	);
};

export default ShiftNotes;
