import { useMutation } from '@tanstack/react-query';

import api from '../../../core/utility/graphQL';

const sendMessage = async ({ conversationId, text, careProId }) => {
	try {
		const query = careProId
			? `mutation {
					patientSendMessage(
						conversation_id: ${conversationId},
						text: "${text}",
						provider_id: ${careProId}
					)
				}`
			: `mutation {
					patientSendMessage(
						conversation_id: ${conversationId},
						text: "${text}"
					)
				}`;

		const data = await api.graph({ query });

		return data.patientSendMessage;
	} catch (err) {
		console.error(err);
		throw err;
	}
};

export const useSendMessageMutation = () => {
	return useMutation({
		mutationKey: ['sendMessage'],
		mutationFn: (values) => {
			return sendMessage(values);
		},
	});
};
