import React, { useState } from 'react';
import Title from '../../Title';
import { Formik, Form, Field } from 'formik';
import Colors from '../../../constants/colors';
import {
	MARGIN_TITLE,
	FormItemsLength,
	FORM_ITEM_LENGTH,
	OBFormButtonText,
	OBBasicCon,
} from './OnboardViews';
import { ColumnFlex } from '../../styled/Basic';
import { connect } from 'react-redux';

import Text from 'AppComp/Text';

import { validate5 } from './validation';
import StyledTextInput, { StrongPasswordTextInput } from 'AppComp/styled/StyledTextInput';
import Icon from 'AppComp/Icon';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import PageLoaderView from '../../PageLoaderView';
import api from '../../../scripts/api';
import { useEffect } from 'react';

const OB5UserInfo = (props) => {
	const [loading, _loading] = useState(false);
	const [err, _err] = useState(null);

	const { handleDataUpdate, userAccountInfo, setClientAccountOnboardStep } = props;

	const handleSubmit = (values, form) => {
		_loading(true);
		handleVerifyUser(values.phoneNumber, values.email, values.password);
	};

	const handleVerifyUser = async (phoneNumber, email, password) => {
		try {
			const newState = {
				password: password,
				phone: phoneNumber,
				email: email,
			};

			const verifyPhone = await api.graph({
				query: `mutation {
					verifyUserPhone(phone_number: "${phoneNumber}", email: "${email}")
				}`,
			});
			_loading(false);
			handleDataUpdate(newState, 3);
		} catch (err) {
			_loading(false);
			console.log('handleVerifyUser err', err);

			if (Array.isArray(err)) {
				_err(err[0].message);
			} else {
				_err('Something went wrong');
			}
		}
	};
	console.log('props.state', props.state);
	return (
		<OBBasicCon>
			<Icon size='3x' color={Colors.greenLightTeal} icon={faKey} />
			<Title
				style={{ textAlign: 'center' }}
				maxWidth={'320px'}
				margin={MARGIN_TITLE}
				subtitle
			>
				One Step Closer for care that's for you
			</Title>
			<Text nurseInfo gray></Text>
			<div style={{ marginBottom: '10px' }} />
			<Formik
				onSubmit={handleSubmit}
				autoComplete={'off'}
				validate={validate5}
				render={(formProps) => {
					return (
						<Form noValidate>
							<ColumnFlex alignCenter justifyCenter fullWidth fullHeight>
								<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
									<Field
										name='email'
										type='text'
										component={StyledTextInput}
										label='Email'
										backgroundColor={'white'}
									/>
								</FormItemsLength>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'items-start',
										justifyContent: 'flex-start',
										margin: '20px 0 0 0',
									}}
								>
									<Title
										style={{ textAlign: 'left' }}
										maxWidth={'320px'}
										margin={'0px 0 0 0'}
										subtitle
										padding={'12px 0 0 0'}
									>
										+1
									</Title>
									<FormItemsLength margin='0px 0 0 0' width={FORM_ITEM_LENGTH}>
										<Field
											name='phoneNumber'
											type='number'
											autocomplete='off'
											component={StyledTextInput}
											backgroundColor={'white'}
											label='Phone Number (US only)'
										/>
									</FormItemsLength>
								</div>

								<FormItemsLength margin='30px 0 0 0' width={FORM_ITEM_LENGTH}>
									<Field
										component={StrongPasswordTextInput}
										name='password'
										label='Password*'
										type='password'
										backgroundColor={'white'}
									/>
								</FormItemsLength>
								<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
									<Field
										component={StyledTextInput}
										name='confirm_password'
										label='Confirm Password*'
										type='password'
										backgroundColor={'white'}
									/>
								</FormItemsLength>

								{loading ? (
									<PageLoaderView errorMessage='Something seems to be wrong with our connection' />
								) : (
									<OBFormButtonText
										backgroundColor={Colors.teal}
										type='submit'
										valid={formProps.isValid}
										text='Next'
									/>
								)}
								{err && (
									<Text margin='20px 0 0 0 ' bold error>
										{err}
									</Text>
								)}
							</ColumnFlex>
						</Form>
					);
				}}
			/>
			<Text onClick={() => setClientAccountOnboardStep(3)} subtext pointer underline>
				Back
			</Text>
		</OBBasicCon>
	);
};

const mapStateToProps = ({ provider }) => {
	return {};
};

const mapDispatchToProps = (dispatch) => {
	//add reudcers in this format
	return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(OB5UserInfo);
