import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useFormik } from 'formik-latest';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import { z } from 'zod';

import { Input, ErrorMessage } from '../../../../../corev2/Forms';
import { PrimaryButton } from '../../../../../corev2/Buttons';

import {
	StyledDocumentCard,
	StyledDocumentTitle,
	StyledDocumentUploadArea,
	StyledUploadFilesText,
	StyledButtonContainer,
	StyledClearFileIcon,
} from './styles';

import { expirationDateValidationSchema } from './validation';

import { truncateText } from '../../../core/lib';

const UploadTile = (props) => {
	const { documentRequirement, onUpload, loading } = props;

	const [selectedFile, setSelectedFile] = useState(null);

	const getValidationSchema = (requiresExpiration) => {
		if (requiresExpiration) {
			return expirationDateValidationSchema;
		}
		return z.object({});
	};

	const formik = useFormik({
		initialValues: {
			expirationDate: '',
		},
		validationSchema: toFormikValidationSchema(
			getValidationSchema(documentRequirement.requires_expiration)
		),
		validateOnChange: true,
		validateOnBlur: true,
		onSubmit: (values) => {
			if (selectedFile) {
				onUpload(selectedFile, values.expirationDate);
			}
		},
	});

	const handleDrop = (acceptedFiles) => {
		if (acceptedFiles.length > 0) {
			setSelectedFile(acceptedFiles[0]);
		}
	};

	const handleClearFile = () => {
		setSelectedFile(null);
	};

	return (
		<StyledDocumentCard>
			<StyledDocumentTitle>{documentRequirement.name}</StyledDocumentTitle>

			{selectedFile && <StyledClearFileIcon onClick={handleClearFile}>x</StyledClearFileIcon>}

			<Dropzone onDrop={handleDrop}>
				{({ getRootProps, getInputProps }) => (
					<StyledDocumentUploadArea {...getRootProps()}>
						<Input {...getInputProps()} />
						<StyledUploadFilesText>
							{selectedFile
								? truncateText(selectedFile.name, 30)
								: 'Drag and drop file(s)'}
						</StyledUploadFilesText>
					</StyledDocumentUploadArea>
				)}
			</Dropzone>

			{documentRequirement.requires_expiration && (
				<form onSubmit={formik.handleSubmit}>
					<Input
						type='date'
						name='expirationDate'
						value={formik.values.expirationDate}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>

					{formik.touched.expirationDate && formik.errors.expirationDate && (
						<ErrorMessage>{formik.errors.expirationDate}</ErrorMessage>
					)}
				</form>
			)}

			{selectedFile && (
				<StyledButtonContainer>
					<PrimaryButton
						size='small'
						type='submit'
						disabled={loading || !formik.isValid || !selectedFile}
						onClick={formik.handleSubmit}
					>
						{loading ? 'Uploading...' : 'Upload'}
					</PrimaryButton>
				</StyledButtonContainer>
			)}
		</StyledDocumentCard>
	);
};

export default UploadTile;
