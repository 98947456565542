/*
 * the main purpose for this is to create a a way for them to log in later so there basic client information
 * we won't be able to incrementally save the info to the api until the end compared to how it was done with the carePlan
 * 1) user add their realtionship to client
 * 2) user adds basic info about client email phone, name,
 * 3) basic phone verfication
 * 4) the user will laster be perosnally verifed through some service and we'll add that here
 * 5) we move the user along
 */
import React, { useState, Fragment, useEffect } from 'react';

import OB1CareType from '../Onboard/OB1CareType';
import OB5UserInfo from '../Onboard/OB5UserInfo';
import OBPhoneConfirmation from '../Onboard/OBPhoneConfirmation';

import { STEP_PROGRESS_KEYS } from './ClientOnboardParent';
import OnboardSubNav from './OnboardSubnav';

/*
  USER_ONBOARD_ACCOUNT_INFO {
   email: string
   first_name: string
    last_name: string
    phone: string
    display_name: string,
    relationship_to_care_plan: string
  }
*/

const CLIENT_ACCOUNT_ONBOARD_SUB_NAV = [
	{
		id: 1,
		title: 'Personal',
		completed: false,
	},
	{
		id: 2,
		title: 'Client Info',
		completed: false,
	},
	{
		id: 3,
		title: 'Phone Confirmation',
		completed: false,
	},
];
const ClientAccountOnboard = (props) => {
	const { handleUpdateSectionProgress, renderCurrentStep } = props;

	const [accountSubNav, setAccountSubNav] = useState(CLIENT_ACCOUNT_ONBOARD_SUB_NAV);

	const [clientAccountOnboardStep, setClientAccountOnboardStep] = useState(1);
	const [userAccountInfo, setUserAccountInfo] = useState({
		email: null,
		password: null,
		first_name: null,
		last_name: null,
		phone: null,
		display_name: null,
		registry_id: 1,
	});

	useEffect(() => {
		// check specific keys too see which sub nav item has been completed

		// check if the user has completed the first step
		const _accountSubNav = accountSubNav;

		if (userAccountInfo.first_name && userAccountInfo.last_name) {
			_accountSubNav[0].completed = true;
		}

		if (userAccountInfo.email && userAccountInfo.password && userAccountInfo.phone) {
			_accountSubNav[1].completed = true;
		}

		setAccountSubNav(_accountSubNav);
	}),
		[userAccountInfo];
	const handleDataUpdate = (userAccountInfoData, step) => {
		console.log('userAccountInfoData', userAccountInfoData);
		console.log('userAccountInfo', userAccountInfo);
		setUserAccountInfo({ ...userAccountInfo, ...userAccountInfoData });
		if (step) {
			setClientAccountOnboardStep(step);

			switch (step) {
				case 2:
					handleUpdateSectionProgress(0.5, STEP_PROGRESS_KEYS.ACCOUNT);

					break;
				case 3:
					handleUpdateSectionProgress(0.8, STEP_PROGRESS_KEYS.ACCOUNT);

					break;

				default:
					break;
			}
		}
	};

	const renderView = () => {
		if (clientAccountOnboardStep === 1) {
			return (
				<OB1CareType
					userAccountInfo={userAccountInfo}
					handleDataUpdate={handleDataUpdate}
				/>
			);
		} else if (clientAccountOnboardStep === 2) {
			return (
				<OB5UserInfo
					setClientAccountOnboardStep={setClientAccountOnboardStep}
					userAccountInfo={userAccountInfo}
					handleDataUpdate={handleDataUpdate}
				/>
			);
		} else if (clientAccountOnboardStep === 3) {
			return (
				<OBPhoneConfirmation
					userAccountInfo={userAccountInfo}
					setClientAccountOnboardStep={setClientAccountOnboardStep}
					handleDataUpdate={handleDataUpdate}
					renderCurrentStep={renderCurrentStep}
				/>
			);
		}
	};

	const handleSubNavTileClick = (id) => {
		console.log('tile clicked id', id);
	};
	return (
		<>
			<OnboardSubNav
				currentStep={clientAccountOnboardStep}
				steps={accountSubNav}
				handleSubNavTileClick={handleSubNavTileClick}
				showArrowsBetween={true}
				descriptionText='Please Complete:'
			/>
			{renderView()}
		</>
	);
};

export default ClientAccountOnboard;
