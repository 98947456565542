import React, { useRef, useState } from 'react';
import styled from 'styled-components';

import { PatientImageView } from 'AppComp/provider/ProviderTimesheetView';

import { ColumnFlex, RowFlex } from 'AppComp/styled/Basic';
import api from 'scripts/api';

import Text from 'AppComp/Text';
import Button from 'AppComp/Button';

import { Formik, Form, Field } from 'formik';

import Icon from 'AppComp/Icon';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { device } from 'AppComp/styled/StyledMediaQuery';

import AutoSave from 'AppComp/AutoSave';
import {
	ActivityRadioInupt,
	CustomLengthCon,
} from 'AppComp/patient/CarePlanInfo/CarePlanInfoViews';

import { StarIcon } from 'AppComp/provider/ProviderTimesheetView';

import SignaturePad from 'react-signature-canvas';

import Colors from 'colors';

export const JobSelection = (props) => {
	const {
		client,
		carePlan,

		setClient,
		shift,
	} = props;

	return (
		<>
			<>
				{client !== null && (
					<RowFlex alignCenter center fullWidth>
						<PatientImageView
							onClick={() => setClient(null)}
							shift={shift}
							carePlan={carePlan}
						/>
					</RowFlex>
				)}
				{client === null && <p>needs ot be updated</p>}
			</>
		</>
	);
};

export const CarePlanActionSection = (props) => {
	return (
		<ColumnFlex
			fullWidth
			gap='10px'
			style={{
				backgroundColor: 'white',
				borderRadius: '16px',
				padding: '8px',
			}}
		>
			<Text bold padding='0 0 0 10px' largeText color={Colors.secondary.blue}>
				{props.shiftTitle}
			</Text>
			<RowFlex wrap gap='4px' fullWidth>
				{props.values.map((item, index) => {
					return (
						<CustomLengthCon key={index} width='auto'>
							<Field
								component={ActivityRadioInupt}
								name={`${props.shiftTitle}::${item}`}
								label={item}
								type='checkbox'
								disabled={props.disabled}
							/>
						</CustomLengthCon>
					);
				})}
			</RowFlex>
		</ColumnFlex>
	);
};

export const CarePlanActives = (props) => {
	//console.log('CarePlanActives', props);
	const isDisabled = props.shift.status == 'created' || props.shift.status == 'clocked_out';

	const renderSections = () => {
		const section = Object.keys(CARE_PLAN_ADLS_V2)
			.filter((key) => {
				if (CARE_PLAN_ADLS_V2[key].shiftTitle) {
					return true;
				} else {
					return false;
				}
			})
			.map((key) => CARE_PLAN_ADLS_V2[key]);

		//console.log('isDisabled', isDisabled);
		return section.map((item, index) => {
			return <CarePlanActionSection disabled={isDisabled} {...item} key={index} />;
		});
	};

	const handleSave = async (values) => {
		try {
			if (isDisabled) {
				return true;
			}
			//console.log('values', values);
			// convert all the values to an array

			//deep clone EMPTY_CARE_PLAN_ADLS without using json.parse

			const clone = { ...EMPTY_CARE_PLAN_ADLS };

			const valuesArray = Object.keys(values).filter((key) => {
				if (values[key] === true) {
					return true;
				} else {
					return false;
				}
			});

			// loop through the values to clean them up for the api save
			// const cleanValues = valuesArray.map((item) => {
			// 	const split = item.split('::');
			// 	const shiftTitle = split[0];
			// 	const value = split[1];

			// 	// find the shift title in CARE_PLAN_ADLS
			// 	const shift = Object.keys(CARE_PLAN_ADLS).find(
			// 		(key) => CARE_PLAN_ADLS[key].shiftTitle === shiftTitle
			// 	);

			// 	let keyThing = shift || 0;
			// 	clone[keyThing].values.push(value);
			// 	// now that we have the key we can
			// });
			const updateParams = {
				care_plan_options: valuesArray,
			};

			const updateShiftRes = await api.graph({
				query: `mutation {
					updateShift(
						shift_id: ${props.shift.id}
						shift_input: ${api.graphStringify(updateParams)}
					) {
						id
					}
				}`,
			});

			return true;
		} catch (err) {
			console.log('err', err);
			return false;
		}
	};

	const renderThing = (state) => {
		//console.log('state', state);
	};

	const renderInitalValues = () => {
		const initialValues = {};

		if (props.shift.care_plan_options) {
			const shiftValues = JSON.parse(props.shift.care_plan_options);

			shiftValues.forEach((item) => {
				initialValues[item] = true;
			});
		}

		//console.log('initialValues', initialValues);
		return initialValues;
	};
	return (
		<Formik initialValues={renderInitalValues()}>
			{(formikProps) => (
				<Form>
					{/* Your form inputs here */}
					<ColumnFlex padding='16px 0 ' fullWidth gap='10px'>
						{renderSections()}
					</ColumnFlex>

					<AutoSave onSave={handleSave} render={renderThing} />
				</Form>
			)}
		</Formik>
	);
};

export const EMPTY_CARE_PLAN_ADLS = {
	1: {
		shiftTitle: 'Personal Care',
		title: 'Activities willing to do:',
		values: [],
	},
	2: { title: 'Environment willing to work in:', values: [] },
	3: { title: 'Comfortable being in an environment with:', values: [] },
	4: {
		shiftTitle: 'Cooking',
		title: 'Meals I am able to cook:',
		values: [],
	},
	5: {
		shiftTitle: 'Transportation',
		title: 'Transportation:',
		values: [],
	},
	6: { title: 'Languages I speak:', values: [] },
	7: {
		shiftTitle: 'Assistance',
		title: 'I would feel comfortable assisting patients with:',
		values: [],
	},
};

export const CARE_PLAN_ADLS_V2 = {
	1: {
		shiftTitle: 'Ambulation',
		title: 'Activities willing to do:',
		values: ['Self', 'Standby Assist', 'Total Help'],
	},
	2: {
		shiftTitle: 'Bathing',
		title: 'Activities willing to do:',
		values: ['Self', 'Standby Assist', 'Total Help'],
	},
	3: {
		shiftTitle: 'Dressing',
		title: 'Activities willing to do:',
		values: ['Self', 'Standby Assist', 'Total Help'],
	},
	4: {
		shiftTitle: 'Grooming',
		title: 'Activities willing to do:',
		values: ['Self', 'Standby Assist', 'Total Help'],
	},
	5: {
		shiftTitle: 'Toileting',
		title: 'Activities willing to do:',
		values: ['Self', 'Standby Assist', 'Total Help'],
	},
	6: {
		shiftTitle: 'Transferring',
		title: 'Activities willing to do:',
		values: ['Self', 'Standby Assist', 'Total Help'],
	},
	7: {
		shiftTitle: 'Meal Preferences',
		title: 'Meals I am able to cook:',
		values: [
			'Chinese',
			'French',

			'Indian',
			'Italian',
			'Japanese',
			'Korean',
			'Mexican',
			'Spanish',

			'Vietnamese',
		],
	},
	8: {
		shiftTitle: 'Meal Restrictions',
		title: 'Meals I am able to cook:',
		values: [
			'Chinese',
			'French',
			'Indian',
			'Italian',
			'Japanese',
			'Korean',
			'Mexican',
			'Spanish',
			'Vietnamese',
		],
	},
	9: {
		shiftTitle: 'Errands',
		title: 'Meals I am able to cook:',
		values: ['Shopping', 'Groceries'],
	},
	10: {
		shiftTitle: "Doctor's appointments",
		title: "Doctor's appointments I can take:",
		values: ['Physician', 'Physiotherapist', 'Psychiatrist'],
	},
	11: {
		shiftTitle: 'Medication Reminders',
		title: "Doctor's appointments I can take:",
		values: ['Morning', 'Afternoon', 'Evening', 'Night'],
	},
	12: {
		shiftTitle: 'Household Services',
		title: "Doctor's appointments I can take:",
		values: ['Change bed linens', 'Laundry', 'Vacuum', 'Light housekeeping'],
	},
};

export const CARE_PLAN_ADLS = {
	1: {
		shiftTitle: 'Personal Care',
		title: 'Activities willing to do:',
		values: ['Bed bound', 'Dementia'],
	},
	2: { title: 'Environment willing to work in:', values: [] },
	3: { title: 'Comfortable being in an environment with:', values: [] },
	4: {
		shiftTitle: 'Cooking',
		title: 'Meals I am able to cook:',
		values: [
			'Chinese',
			'French',

			'Indian',
			'Italian',
			'Japanese',
			'Korean',
			'Mexican',
			'Spanish',

			'Vietnamese',
		],
	},
	5: {
		shiftTitle: 'Transportation',
		title: 'Transportation:',
		values: ['Car', 'Public Transportation', 'Walk', 'Bike', 'Wheelchair', 'Gurney', 'Other'],
	},
	6: { title: 'Languages I speak:', values: [] },
	7: {
		shiftTitle: 'Assistance',
		title: 'I would feel comfortable assisting patients with:',
		values: [
			'Bed bound',
			'Dementia',
			'Alzheimer’s',
			'Parkinson’s',
			'ALS',
			'Autism',
			'Stroke',
			'Heart Disease',
			'Pulmonary Disease',
			'Kidney Disease',
			'Liver Disease',
			'Blindness',
			'Deafness',
			'Paraplegic',
			'Quadriplegic',
			'Other',
		],
	},
};

export const CanvasThing = styled.div`
	height: 300px;
	width: 300px;
	margin: auto;
`;

function dataURItoBlob(dataURI, type = 'image/jpeg') {
	var binary = atob(dataURI.split(',')[1]);

	var arr = [];

	for (var i = 0; i < binary.length; i++) {
		arr.push(binary.charCodeAt(i));
	}

	return new Blob([new Uint8Array(arr)], { type });
}
export const ShiftSignatureCollection = (props) => {
	const { client_signature_url, rating } = props.shift;
	const [trimmedDataURL, setTrimmedDataURL] = useState(null);
	const sigPad = useRef({});

	const handleSave = () => {
		const urlData = sigPad.current.getTrimmedCanvas().toDataURL('image/jpeg');

		//console.log('urlData', urlData);
		setTrimmedDataURL(urlData);

		const identifyPoolId = 'us-east-1:66059173-f49e-413c-840a-3be0bd12aa8f';
		const bucketRegion = 'us-east-1';
		const bucketName = 'fyi-stage-bucket-sigs';

		const blobData = dataURItoBlob(urlData);

		AWS.config.update({
			region: bucketRegion,
			credentials: new AWS.CognitoIdentityCredentials({
				IdentityPoolId: identifyPoolId,
			}),
		});

		// shiftId::providerMatchId::providerId
		const signName = `${1}::${1}::${1}.jpeg`;

		const s3 = new AWS.S3({
			apiVersion: '2012-10-17',
			params: { Bucket: bucketName },
		});

		s3.upload(
			{
				Key: signName,
				Body: blobData,
				ACL: 'public-read',
				ContentType: 'image/jpeg',
			},
			handleUploadCallback
		);
	};

	const handleUploadCallback = (err, data) => {
		//once the call back is called we either should show an error or run handleSignatureUpdate to pass the info back to the parent and handle removing the signature pad
		//const { handleSignatureUpdate } = this.props;
		if (err) {
			console.log('somethign went wrong', err);
			//toast.error('Something went wrong, please try again', toastErrorConfig);
			// handleSignatureUpdate(
			// 	'https://www.signwell.com/assets/vip-signatures/courtney-love-signature-f4f978da27e809c02a68ad1eedceb8d51b0ec6834790f9effdc2db053495d83a.svg'
			// );
		} else {
			console.log('data', data);
			const { Location } = data;

			const updateParams = { client_signature_url: Location };

			props.handleUpdateShift(updateParams);
		}
	};

	const clear = () => {
		sigPad.current.clear();
	};
	//console.log('client_signature_url', client_signature_url);

	return (
		<>
			{client_signature_url !== null && (
				<RowFlex alignCenter center fullWidth>
					<Icon icon={faCheck} color={Colors.greenLightTeal} size='2x' />
					<Text bold padding='0 0 0 10px' largeText color={Colors.secondary.blue}>
						Collected
					</Text>
				</RowFlex>
			)}

			{client_signature_url === null && (
				<>
					<SignaturePad
						canvasProps={{ width: '400px', height: '400px' }}
						backgroundColor='white'
						ref={(ref) => {
							sigPad.current = ref;
						}}
					/>
					<ColumnFlex padding='12px 0' gap='10px'>
						<Button
							style={{
								borderRadius: '24px',
							}}
							onClick={() => handleSave()}
							margin='auto'
							valid={true}
							type='submit'
							backgroundColor={Colors.greenLightTeal}
							width={'160px'}
							height={'40px'}
						>
							<Text
								style={{
									textAlign: 'center',
								}}
								bold
								color={'white'}
								mediumText
							>
								Save
							</Text>
						</Button>
						<Button
							style={{
								borderRadius: '24px',
							}}
							margin='auto'
							valid={true}
							type='submit'
							backgroundColor={Colors.red}
							width={'160px'}
							height={'40px'}
							onClick={() => clear()}
						>
							<Text
								style={{
									textAlign: 'center',
								}}
								bold
								color={'white'}
								mediumText
							>
								Clear
							</Text>
						</Button>
					</ColumnFlex>
				</>
			)}
		</>
	);
};

const CollectFeedBack = ({ handleUpdateShift }) => {
	const [rating, setRating] = useState(0);

	const handleSave = () => {
		handleUpdateShift({ rating });
	};
	return (
		<>
			<Text bold padding='0 0 0 10px' largeText color={Colors.secondary.blue}>
				How Was Your Care?
			</Text>
			<RowFlex margin='12px 0' center wrap gap='4px' fullWidth>
				<StarIcon handleStarSelection={setRating} index={1} selectedIndex={rating} />
				<StarIcon handleStarSelection={setRating} index={2} selectedIndex={rating} />
				<StarIcon handleStarSelection={setRating} index={3} selectedIndex={rating} />
				<StarIcon handleStarSelection={setRating} index={4} selectedIndex={rating} />
				<StarIcon handleStarSelection={setRating} index={5} selectedIndex={rating} />
			</RowFlex>
			<Button
				style={{
					borderRadius: '24px',
				}}
				onClick={() => handleSave()}
				margin='auto'
				valid={true}
				type='submit'
				backgroundColor={Colors.greenLightTeal}
				width={'160px'}
				height={'40px'}
			>
				<Text
					style={{
						textAlign: 'center',
					}}
					bold
					color={'white'}
					mediumText
				>
					Submit
				</Text>
			</Button>
		</>
	);
};

export const StyledCareProTilesContainer = styled.div`
	display: inline-flex;
	flex-wrap: wrap;
	text-align: left;

	max-width: 804px;

	margin: auto;

	@media ${device.laptop} {
		> * {
			&:nth-child(even) {
				margin-right: 0px;
			}
		}
	}
`;

export const StyledCareProTileContainer = styled.div`
	width: 100%;
	padding: 24px 18px;
	background-color: ${Colors.theme.white};
	border: 1px solid ${Colors.primary.new_primary};
	border-radius: 16px;
	float: left;

	margin-right: 0px;
	margin-bottom: 16px;

	display: flex;
	flex-direction: column;

	@media ${device.tablet} {
		margin-right: 16px;
		flex-direction: row;
	}

	@media ${device.laptop} {
		width: 394px;
	}
`;

export const StyledCareProAvatarContainer = styled.div`
	width: 80px;
	height: 80px;
	min-width: 80px;
	border-radius: 50%;
	background-color: white;
	border: 1px solid ${Colors.primary.new_primary};

	margin: auto;

	@media ${device.tablet} {
		margin: 0;
	}
`;

export const StyledCareProPersonalInfoContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding-left: 8px;
	width: 100%;
	text-align: center;

	@media ${device.tablet} {
		text-align: left;
	}
`;

export const StyledCareProPersonalInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: auto;

	justify-content: space-between;
`;

export const StyledCareProPersonalInfo = styled.div`
	display: flex;
	flex-direction: column;

	margin-bottom: 0.25rem;
`;

export const StyledName = styled.span`
	font-size: 1.25rem;
	font-weight: 700;
	color: ${Colors.primary.new_primary};

	margin-bottom: 0.25rem;
`;

export const StyledRate = styled.span`
	color: ${Colors.primary.new_primary};

	font-size: 1.5rem;
	font-weight: 700;
`;

export const StyledHour = styled.span`
	font-size: 0.8rem;
`;

export const StyledLocation = styled.span`
	font-size: 0.8rem;
	color: ${Colors.primary.new_primary};

	margin-bottom: 0.25rem;
`;

export const StyledDescriptionContainer = styled.div`
	display: flex;
	flex-direction: column;

	font-size: 0.8rem;
`;

export const StyledGender = styled.div`
	color: ${Colors.primary.new_primary};

	margin-bottom: 0.5rem;
`;

export const StyledDescription = styled.div`
	color: ${Colors.primary.new_primary};
`;

export const StyledButtonContainer = styled.div`
	margin-top: 1rem;
`;

export const StyledButton = styled.button`
	width: 80px;
	height: 30px;

	margin-right: 0.5rem;

	background: ${({ isTransparent }) => (isTransparent ? 'inherit' : Colors.primary.new_primary)};
	border: 1px solid ${Colors.primary.new_primary};
	border-radius: 18px;

	font-size: 0.75rem;
	color: ${({ isTransparent }) => (isTransparent ? Colors.primary.new_primary : 'white')};

	@media ${device.tablet} {
		width: 100px;
		height: 40px;

		font-size: 0.875rem;
	}
`;

export const ClientTile = ({ shift, handleShiftSelection }) => {
	const { start_time, end_time, job } = shift;
	const { carePlan } = job;
	const {
		first_name,
		last_name,
		address_city,
		address_state,
		address_street,
		address_street_2,
		address_zip,
	} = carePlan;

	return (
		<StyledCareProTileContainer
			style={{
				cursor: 'pointer',
			}}
			onClick={() => handleShiftSelection(shift)}
		>
			<StyledCareProAvatarContainer />

			<StyledCareProPersonalInfoContainer>
				<StyledCareProPersonalInfoWrapper
					style={{
						margin: '0',
					}}
				>
					<StyledCareProPersonalInfo>
						<StyledName>{`${first_name} ${last_name}`}</StyledName>
					</StyledCareProPersonalInfo>
					{/* <StyledRate>{`${new Date(start_time).toLocaleDateString('en-GB', {
						day: '2-digit',
						month: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
						hour12: true,
					})} - ${new Date(end_time).toLocaleDateString('en-GB', {
						day: '2-digit',
						month: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
						hour12: true,
					})}`}</StyledRate> */}
					<StyledRate>{`${address_street} ${address_street_2}, ${address_city}, ${address_state} ${address_zip}`}</StyledRate>
				</StyledCareProPersonalInfoWrapper>
				<StyledLocation>
					{`${new Date(start_time).toLocaleDateString('en-GB', {
						day: '2-digit',
						month: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
						hour12: true,
					})} - ${new Date(end_time).toLocaleDateString('en-GB', {
						day: '2-digit',
						month: '2-digit',
						hour: '2-digit',
						minute: '2-digit',
						hour12: true,
					})}`}
				</StyledLocation>
				<StyledDescriptionContainer>
					{/* <StyledGender>
						{capitalizeFirstLetter(gender)} |{' '}
						{care_type === 'hourly' ? 'Hourly' : 'Live In'}
					</StyledGender> */}
					{/* <StyledDescription>{professional_statement}</StyledDescription> */}
				</StyledDescriptionContainer>
				{/* <StyledButtonContainer>
					<StyledButton onClick={() => onSelectCarePro(props)}>{buttonText}</StyledButton>
					<StyledButton isTransparent onClick={() => careProClickHandler(id)}>
						View
					</StyledButton>
				</StyledButtonContainer> */}
			</StyledCareProPersonalInfoContainer>
		</StyledCareProTileContainer>
	);
};
