import React from 'react';

import PageHeading from '../../../../core/components/PageHeading';
import PersonalInfoForm from '../../../components/PersonalInfoForm';

import { useOnboarding } from '../../../hooks/useOnboarding';

import { StyledPersonalInfoPage } from './styles';

const PersonalInfoPage = () => {
	const { onboardingState, mutateOnboardingState } = useOnboarding();

	const personalInfoFormHandler = (values) => {
		mutateOnboardingState('personalInfo', {
			...onboardingState.personalInfo,
			data: {
				firstName: values.firstName,
				lastName: values.lastName,
				displayName: values.displayName,
			},
			isCompleted: true,
		});
	};

	return (
		<StyledPersonalInfoPage>
			<PageHeading>Personal Information</PageHeading>
			<PersonalInfoForm
				initialValues={{
					firstName: onboardingState.personalInfo.data.firstName,
					lastName: onboardingState.personalInfo.data.lastName,
					displayName: onboardingState.personalInfo.data.displayName,
				}}
				personalInfoFormHandler={personalInfoFormHandler}
			/>
		</StyledPersonalInfoPage>
	);
};

export default PersonalInfoPage;
