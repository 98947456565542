import React, { Component, Fragment } from 'react';
import Colors from 'colors';
import { RowFlex } from 'basicStyles';
import { ModelContainer, AlternateSection } from 'AppComp/ModelOverlay';
import { Header, Button } from 'AppComp/EditScheduleView';
import { Formik, Form, Field } from 'formik';
import { isMobile } from 'react-device-detect';

import Text from 'AppComp/Text';

import { ColumnFlex } from '../../styled/Basic';

import { FormItemsLength } from 'AppComp/provider/POnboard/OnboardViews';
import StyledTextInput, { StyledTextSelectInputV2 } from 'AppComp/styled/StyledTextInput';
import { CustomFormButton } from 'AppComp/patient/PatientCardCollection/PatientCardCollectionViews';

import { validateOnboardFlowAdmin } from 'constants/validation';

const FORM_ITEM_LENGTH = '420px';

const ITEM_TYPE = [
	{
		value: null,
		label: '',
	},
	{
		value: 'document',
		label: 'Document',
	},
	{
		label: 'In Person Visit',
		value: 'in_person_visit',
	},

	{
		value: 'doctors_note',
		label: 'Doctors Note',
	},
	{
		value: 'file',
		label: 'File',
	},
];
const FlowStep = (props) => {
	const isLoading = false;
	const handleSubmit = (values, form) => {
		console.log('Personal - handleSubmit', values);
		const stepData = {
			title: values.title,
			description: values.description,
			type: values.type,
		};
		props.handleSubmitStep(stepData);
	};

	return (
		<ModelContainer>
			<Header text='Add Onboard Step' color={Colors.primary.primary} />
			<AlternateSection height='auto' padding='0 24px'>
				<Formik
					onSubmit={handleSubmit}
					autoComplete={'off'}
					validate={validateOnboardFlowAdmin}
					render={(formProps) => {
						console.log(formProps);
						return (
							<Form noValidate>
								<ColumnFlex alignCenter margin='20px 0 0 0' justifyCenter>
									<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
										<Field
											name='title'
											type='text'
											component={StyledTextInput}
											label='Title'
										/>
									</FormItemsLength>
									<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
										<Field
											name='description'
											type='text'
											component={StyledTextInput}
											label='Description'
										/>
									</FormItemsLength>
									<FormItemsLength margin='20px 0 0 0' width={FORM_ITEM_LENGTH}>
										<Field
											name='type'
											component={StyledTextSelectInputV2}
											options={ITEM_TYPE}
											label='Requirement Type'
										/>
									</FormItemsLength>
									{isLoading ? (
										<PageLoaderView errorMessage='Something went wrong plz contact dev monkeys' />
									) : (
										<CustomFormButton
											style={{
												borderRadius: '24px',
											}}
											valid={formProps.isValid}
											type='submit'
											margin='32px 8px 0 0px'
											backgroundColor={Colors.primary.primary}
											width={'240px'}
											height={isMobile ? '40px' : '60px'}
										>
											<Text
												style={{
													textAlign: 'center',
												}}
												bold
												color={
													formProps.isValid
														? 'white'
														: Colors.primary.primary
												}
												midLarge
											>
												Confirm
											</Text>
										</CustomFormButton>
									)}
								</ColumnFlex>
							</Form>
						);
					}}
				/>
			</AlternateSection>
		</ModelContainer>
	);
};

export default FlowStep;
