import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import Text from 'AppComp/Text';
import Colors from 'colors';

import { ColumnFlex, RowFlex } from 'basicStyles';

import {
	LandingImage,
	TestimonalText,
	LandingTextSection,
	Section,
	LandingTitle,
	LandingText,
	LeftAlignTextSection,
	TempImage,
	CustomRowSection,
	CarouselImage,
	CImage,
	FaceTiles,
	Navigation,
	BasicChildContainer,
	WelcomeButton,
	IntroText,
	HeartOne,
	IntroSubText,
	HeartTwo,
	LandingSubHeader,
	LandingSubText,
	SubTitle,
	SubText,
} from 'AppComp/patient/WelcomePage/WelcomePageViews';

export const ParentContainer = styled.div`
	flex: 1;
	overflow-y: scroll;
	width: 100%;
`;

const AdminWelcome = ({ toggleAdminOnboardOverlay }) => {
	return (
		<ParentContainer
			style={{
				minHeight: '100vh',
			}}
		>
			<Section height='100%' backgroundColor={Colors.secondary.white}>
				<BasicChildContainer>
					<ColumnFlex fullWidth fullHeight alignCenter>
						<RowFlex fullWidth alignCenter spaceBetween flex>
							{!isMobile && <LandingImage image_url='' />}
							<LandingTextSection>
								<ColumnFlex flex spaceBetween alignEnd>
									<ColumnFlex>
										<LandingTitle color={Colors.primary.primary}>
											Find Your
										</LandingTitle>
										<LandingTitle color={Colors.primary.primary}>
											Independence
										</LandingTitle>
									</ColumnFlex>

									<ColumnFlex>
										<LandingText color='#424242'>Admin Portal</LandingText>
									</ColumnFlex>

									<WelcomeButton onClick={() => toggleAdminOnboardOverlay()}>
										Login
									</WelcomeButton>
									<Text />
									<Text />
								</ColumnFlex>
							</LandingTextSection>
						</RowFlex>
					</ColumnFlex>
				</BasicChildContainer>
				<HeartOne />
			</Section>
		</ParentContainer>
	);
};

export default AdminWelcome;
