import React, { useState, useEffect } from 'react';

import DynamicFilter from 'AppComp/core/DynamicFilter';

import { filterParser } from 'AppComp/core/DynamicFilter';

// DEFINE FILTERS FOR A CLIENT'S OPTIONS FOR SETTING CARE PLAN ACTIONS

const filters = {
	type: {
		type: 'tags',
		name: 'type',
		label: 'Type',
		options: [
			{ name: 'Hourly', value: 'hourly', selected: false },
			{ name: 'Live In', value: 'live-in', selected: false },
		],
	},
	gender: {
		type: 'tags',
		name: 'gender',
		label: 'Gender',
		options: [
			{ name: 'Male', value: 'male', selected: false },
			{ name: 'Female', value: 'female', selected: false },
			{ name: 'No Preference', value: 'no-preference', selected: false },
		],
	},
	location: {
		type: 'drop-down',
		name: 'location',
		label: 'Location',
		options: {
			city: [
				{ name: 'City', value: 'City', selected: false },
				{ name: 'New York', value: 'New York', selected: false },
				{ name: 'San Francisco', value: 'San Francisco', selected: false },
			],
		},
	},
	// OPTIONAL DROP-DOWN LIST
	// price: {
	// 	type: 'drop-down',
	// 	name: 'price',
	// 	label: 'Price',
	// 	options: {
	// 		min: [
	// 			{ name: 'Min', value: 'Min', selected: false },
	// 			{ name: '1', value: 1, selected: false },
	// 			{ name: '2', value: 2, selected: false },
	// 		],
	// 		max: [
	// 			{ name: 'Max', value: 'Max', selected: false },
	// 			{ name: '1', value: 1, selected: false },
	// 			{ name: '2', value: 2, selected: false },
	// 		],
	// 	},
	// },
};

const CarePlanActionsFilter = (props) => {
	const { saveCarePlanActions } = props;

	const [actionValues, setActionValues] = useState({});
	const [carePlanActions, setCarePlanActions] = useState(filters);

	useEffect(() => {
		saveCarePlanActions(actionValues);
	}, [actionValues]);

	// Function to write custom parsing logic to handle use cases such as persisting to DB, and
	// sending saerch queries to backend to fetch data.

	const customParser = (filters) => console.log(filters);

	const carePlanActionsHandler = (name, option, selectValue) => {
		const { newFilters, json } = filterParser(
			customParser,
			carePlanActions,
			name,
			option,
			selectValue
		);

		setCarePlanActions(newFilters);
		setActionValues(json);
	};

	return <DynamicFilter filters={carePlanActions} filterHandler={carePlanActionsHandler} />;
};

export default CarePlanActionsFilter;
