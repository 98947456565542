import React from 'react';

import Wrapper from '../../Wrapper';

import Avatar from '../components/Avatar';
import Name from '../components/Name';
import Rating from '../../Rating';
import Button from '../components/Button';
import InformationCard from '../components/InformationCard';
import Pricing from '../components/Pricing';

import { capitalizeFirstLetter } from 'scripts';

import {
	StyledProfileContainer,
	StyledProfileWrapper,
	StyledPersonalInfoContainer,
	StyledPersonalInfoWrapper,
	StyledLocation,
	StyledGender,
	StyledDescription,
	StyledOfferButtonContainer,
	StyledReminderTextContainer,
	StyledReminderText,
	StyledInformationContainer,
	StyledInformationHeading,
	StyledTraitsContainer,
	StyledAbility,
	StyledTraitHeading,
	StyledTraitValue,
} from '../styles';

const CareProProfile = ({ carePro, chatHandler, traits, isMatched }) => {
	const {
		first_name,
		last_name,
		displayName,
		gender,
		care_type,
		professional_statement,
		averageReview,
		hourly_rate,
	} = carePro;

	return (
		<Wrapper>
			<StyledProfileContainer>
				<StyledProfileWrapper>
					<StyledPersonalInfoContainer>
						<Avatar />
						<StyledPersonalInfoWrapper>
							{!isMatched && <Name>{`${displayName}`}</Name>}
							{isMatched && <Name>{`${first_name} ${last_name}`}</Name>}
							<Rating rating={averageReview} />
							<StyledLocation>Lake Raymondport</StyledLocation>
							<StyledGender>
								{capitalizeFirstLetter(gender)} |{' '}
								{care_type === 'hourly' ? 'Hourly' : 'Live In'}
							</StyledGender>
						</StyledPersonalInfoWrapper>
					</StyledPersonalInfoContainer>
					<StyledDescription>
						{professional_statement ? professional_statement : 'N/A'}
					</StyledDescription>
					<Button
						style={{ width: '120px' }}
						isTransparent
						onClick={() => chatHandler(carePro.id)}
					>
						Chat
					</Button>
					<Pricing hourlyRate={hourly_rate} />
					<StyledOfferButtonContainer>
						<Button style={{ width: '170px', borderRadius: '22px' }}>Send Offer</Button>
					</StyledOfferButtonContainer>
					<StyledReminderTextContainer>
						<StyledReminderText>Make sure to confirm your schedule</StyledReminderText>
					</StyledReminderTextContainer>
				</StyledProfileWrapper>
				<StyledInformationContainer>
					<StyledInformationHeading>Information</StyledInformationHeading>
					<InformationCard
						heading='Professional Statement'
						information={professional_statement ? professional_statement : 'N/A'}
					/>
					<InformationCard
						heading='Certificate & Screenings'
						information='Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text
				Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text Lorem ipsum text'
					/>
					{traits.length > 0 && (
						<StyledTraitsContainer>
							<StyledInformationHeading>Care Abilities</StyledInformationHeading>

							{traits.map((trait) => (
								<StyledAbility>
									<StyledTraitHeading>{trait.trait}</StyledTraitHeading>
									<StyledTraitValue>{trait.value}</StyledTraitValue>
								</StyledAbility>
							))}
						</StyledTraitsContainer>
					)}
				</StyledInformationContainer>
			</StyledProfileContainer>
		</Wrapper>
	);
};

export default CareProProfile;
