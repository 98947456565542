import styled from 'styled-components';

export const StyledPaymentsPage = styled.div`
	width: 100%;

	text-align: center;

	position: relative;
`;

export const StyledHeadingContainer = styled.div`
	width: fit-content;
	margin: auto;
`;

export const StyledTableContainer = styled.div`
	position: relative;

	margin-top: 2rem;
`;
