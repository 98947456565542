import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import {
	StyledPayoutMethodWrapper,
	StyledPayoutMethod,
	StyledCardNumber,
	StyledExpiryDate,
	StyledDeleteButton,
} from './styles';

const PayoutMethodCard = ({
	id,
	bankName,
	last4,
	isDefault,
	updateDefaultPayoutMethodHandler,
	deletePayoutMethodHandler,
}) => {
	const [showDeleteButton, setShowDeleteButton] = useState(false);

	const showDeleteButtonHandler = () => setShowDeleteButton(true);
	const hideDeleteButtonHandler = () => setShowDeleteButton(false);

	return (
		<StyledPayoutMethodWrapper
			onMouseEnter={showDeleteButtonHandler}
			onMouseLeave={hideDeleteButtonHandler}
		>
			<StyledPayoutMethod
				isDefault={isDefault}
				onClick={() => updateDefaultPayoutMethodHandler(id)}
			>
				<StyledCardNumber>xxxx-xxxx-xxxx-{last4}</StyledCardNumber>
				<StyledExpiryDate>{bankName}</StyledExpiryDate>
				<StyledDeleteButton
					showDeleteButton={showDeleteButton}
					onClick={() => deletePayoutMethodHandler(id)}
				>
					<FontAwesomeIcon icon={faTrash} size='sm' />
				</StyledDeleteButton>
			</StyledPayoutMethod>
		</StyledPayoutMethodWrapper>
	);
};

export default PayoutMethodCard;
