import React from 'react';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import styled from 'styled-components';

export default function Notification(content, type, options) {
	switch (type) {
		case 'success':
			return toast.success(content, {
				position: 'top-right',
				...options,
			});

		case 'error':
			return toast.error(content, {
				position: 'top-right',
				...options,
			});

		case 'warn':
			return toast.warn(content, {
				position: 'top-right',
				...options,
			});

		case 'info':
			return toast.info(content, {
				position: 'top-right',
				...options,
			});

		default:
			return toast(content, {
				position: 'top-right',
				...options,
			});
	}
}

const StyledToastContainer = styled(ToastContainer)`
	&&&..Toastify__toast {
		margin-bottom: 0px !important;
	}
	&&&.Toastify__progress-bar {
		background-color: #219086 !important;
	}
`;
export const NotificationContainer = (props) => {
	return <StyledToastContainer {...props} />;
};
