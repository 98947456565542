import React, { Component, Fragment, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import Colors from 'colors';

import PortalNavigationHandler from './PortalNavigation/PortalNavigationHandler';
import MobileSideBar from './PortalNavigation/MobilePortalNavigationHandler';

import { PROVIDER_NAV_WIDTH } from 'constants';

import useWindowSize from 'scripts/useWindowSize';
import Text from 'AppComp/Text';
import Title from 'AppComp/Title';
import Icon from 'AppComp/Icon';

import { ColumnFlex, AppContainer, RowFlex, Avatar, BackDropBg } from 'basicStyles';

import { faBars } from '@fortawesome/free-solid-svg-icons';
const CustomRow = styled(RowFlex)`
	overflow: hidden;
`;

//Created this extra basiclaly copy of CustomRow cause i don't know what could be affected by the posion relative on desktop styles
const CustomRowMobile = styled(RowFlex)`
	position: relative;
	flex: 1;
`;

const HeaderNav = styled.nav`
	display: flex;
	height: 100px;
	width: 100%;
	z-index: 100;
	background-color: ${Colors.primary.primary};
`;
const logoURl = 'https://find-your-independents.s3.amazonaws.com/images/FYIlogo.png';

const ProviderHomeLayout = ({ THEME, children, history, loggedIn }) => {
	const page = history.location.pathname;

	const { width, height } = useWindowSize();

	//console.log('loggedin', loggedIn);
	const showNav = loggedIn || page === '/carepro-welcome' ? true : false;
	//console.log('showNav', showNav);

	const mobileNav = width < 1200;
	//console.log('mobileNav	', mobileNav, '', width, ' -', height);

	const [showMobileNav, setShowMobileNav] = useState(false);

	return (
		<ThemeProvider theme={THEME}>
			{mobileNav && (
				<HeaderNav>
					<RowFlex fullHeight fullWidth spaceBetween padding='0 20px' alignCenter>
						<RowFlex fullHeight center gap='8px'>
							<Avatar
								height={'60px'}
								width={'60px'}
								border='white'
								color={'white'}
								backgroundColor={'white'}
							>
								<div
									onClick={() => history.push('/carepro')}
									style={{
										backgroundImage: `url("${logoURl}")`,
										backgroundRepeat: 'no-repeat',
										height: '60px',
										width: '60px',
										backgroundSize: 'contain',
										backgroundPosition: 'left center',
										cursor: 'pointer',
									}}
								/>
							</Avatar>
							<Title
								style={{
									cursor: 'pointer',
								}}
								subtitle
								color={'white'}
								fontWeight='700'
								onClick={() => setShowMobileNav(true)}
							>
								Care Pro
							</Title>
						</RowFlex>
						<div
							style={{
								backgroundColor: '#EDF6FB',
								height: '50px',
								width: '50px',
								borderRadius: '16px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								cursor: 'pointer',
							}}
						>
							<Icon
								icon={faBars}
								size='2x'
								color={Colors.primary.primary}
								onClick={() => setShowMobileNav(true)}
							/>
						</div>
					</RowFlex>
					<MobileSideBar
						history={history}
						showMobileNav={showMobileNav}
						setShowMobileNav={setShowMobileNav}
					/>
				</HeaderNav>
			)}

			<AppContainer
				backgroundColor={'#2089d5'}
				style={{ flexDirection: 'row', flexWrap: 'nowrap' }}
			>
				{showNav && !mobileNav && (
					<>
						<PortalNavigationHandler providerPortal={true} history={history} />
						<div
							style={{
								width: PROVIDER_NAV_WIDTH,
								height: '100vh',
							}}
						/>
					</>
				)}

				<div
					style={{
						borderRadius: '12px',
						paddingLeft: showNav && !mobileNav ? '20px' : '0',
						backgroundColor: '#EEF6FC',

						minHeight: showNav && !mobileNav ? '100vh' : 'calc(100vh - 100px)',
						width:
							showNav && !mobileNav ? `calc(100vw - ${PROVIDER_NAV_WIDTH})` : '100vw',
					}}
				>
					{children}
				</div>
			</AppContainer>
		</ThemeProvider>
	);
};

export default ProviderHomeLayout;
